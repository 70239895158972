import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { color, globalStyles } from '../../theme';
import { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';

export const DatePickers = ({
  onDateChange,
  dateValue,
  title,
  required = false,
  variant = 'D20',
  width,
  error,
  containerStyles,
  maxDate,
  minDate,
  disabled = false,
  label,
  inputStyle,
  onOpen,
  ...rest
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (dateValue !== undefined) {
      setValue(dateValue);
    } else {
      setValue(null);
    }
  }, [dateValue]);

  const handleChange = (val) => {
    onDateChange ? onDateChange(val) : setValue(val);
  };

  const styles = {
    input: (style) => ({
      '& .MuiInputBase-input': {
        width: 'calc(100% - 20px)',
        fontSize: defaultValues.isResponsive ? '14px' : '16px',
        padding: defaultValues.isResponsive ? '9.5px 7px' : '13px 14px'
      },
      '& .MuiButtonBase-root': {
        padding: '5px'
      },
      backgroundColor: color.primaryBackground,
      width: 'calc(100% - 2px)',
      borderRadius: 1,
      border: '1px solid transparent',
      ...style
    }),
    container: (styles) => ({
      width: width || '100%',
      marginTop: '10px',
      ...styles
    }),
    demoContainer: {
      width: '100%',
      paddingTop: '5px',
      '&.MuiStack-root>.MuiTextField-root': {
        minWidth: '0px'
      }
    },
    asterisk: {
      color: color.errorText
    },
    errorStyle: {
      marginTop: '5px'
    },
    title: {
      ...globalStyles.text
    }
  };
  return (
    <Box sx={styles.container(containerStyles)}>
      {title && (
        <Text variant={variant} style={styles.title}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer sx={styles.demoContainer} components={['DatePicker']}>
          <DesktopDatePicker
            disabled={disabled}
            value={value}
            onChange={handleChange}
            sx={styles.input(inputStyle)}
            onOpen={onOpen}
            format="DD/MM/YYYY"
            maxDate={maxDate}
            minDate={minDate}
            label={label}
            {...rest}
          />
        </DemoContainer>
      </LocalizationProvider>
      {error && (
        <Text variant="fieldError" style={styles.errorStyle}>
          {error}
        </Text>
      )}
    </Box>
  );
};
