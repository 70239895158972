import { createSelector } from 'reselect';

export const getAiWorkerLoading = createSelector([(state) => state.aiWorker.isLoading], (d) => d);
export const getAiWorkerSuccessMsg = createSelector(
  [(state) => state.aiWorker.successMessage],
  (d) => d
);
export const getAiWorkerErrorMsg = createSelector(
  [(state) => state.aiWorker.errorMessage],
  (d) => d
);
export const getAIworkers = createSelector([(state) => state.aiWorker.aiWorkers], (d) => d);
export const getAIworkerCurrPage = createSelector(
  [(state) => state.aiWorker.currentPage],
  (d) => d
);
export const getAIworkerNextpage = createSelector([(state) => state.aiWorker.nextPage], (d) => d);
export const getAIworkerTotalPage = createSelector([(state) => state.aiWorker.totalPage], (d) => d);
export const getAIworkerParams = createSelector(
  [(state) => state.aiWorker.aiWorkerParams],
  (d) => d
);
export const getAIworkerCurrRender = createSelector(
  [(state) => state.aiWorker.currRender],
  (d) => d
);
export const getCurrAIWorker = createSelector([(state) => state.aiWorker.currAIWorker], (d) => d);
export const getVetSuggetions = createSelector(
  [(state) => state.aiWorker.unAssignedVets],
  (d) => d
);
export const getUnassignedCattles = createSelector(
  [(state) => state.aiWorker.unassignedCattles],
  (d) => d
);

export const getUnassCattleCurrPage = createSelector(
  [(state) => state.aiWorker.cattleCurrentPage],
  (d) => d
);
export const getUnassCattleNextpage = createSelector(
  [(state) => state.aiWorker.cattleNextPage],
  (d) => d
);
export const getUnassCattleTotalPage = createSelector(
  [(state) => state.aiWorker.cattleTotalPage],
  (d) => d
);

export const getUnassVetCurrPage = createSelector(
  [(state) => state.aiWorker.unAssVetCurrentPage],
  (d) => d
);
export const getUnassVetNextpage = createSelector(
  [(state) => state.aiWorker.unAssVetNextPage],
  (d) => d
);
export const getUnassVetTotalPage = createSelector(
  [(state) => state.aiWorker.unAssVetTotalPage],
  (d) => d
);

export const getUnassLabsCurrPage = createSelector(
  [(state) => state.aiWorker.labsCurrentPage],
  (d) => d
);
export const getUnassLabsNextpage = createSelector(
  [(state) => state.aiWorker.labsNextPage],
  (d) => d
);
export const getUnassLabsTotalPage = createSelector(
  [(state) => state.aiWorker.labsTotalPage],
  (d) => d
);

export const getUnassignedLabs = createSelector(
  [(state) => state.aiWorker.unassignedLabs],
  (d) => d
);

export const getGopalanChecked = createSelector([(state) => state.aiWorker.checked], (d) => d);
