/**
 * The various styles of fonts are defined in the <Text /> component.
 * How to add fonts -
 * Add fonts ttf file to the fonts folder in your assets folder
 * here user the font name from the added font files
 */

export const typography = {
  /**
   * The primary font.  Used in most places.
   */
  primary: 'NunitoSans_10pt-Regular',
  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: 'Milk Mango',

  /**
   * An alternate font used for paragraphs.
   */
  paragraph: 'OpenSans-Regular',

  /**
   * An alternate font used for perhaps Page Header, sub header and etc.
   */
  header: 'NunitoSans_10pt-Bold',
  secondaryHeader: 'NunitoSans_10pt-SemiBold'
};
