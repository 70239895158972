import { Typography } from '@mui/material';
import { typography } from '../../theme/fonts';
import { color } from '../../theme/color';
import { mergeAll, flatten } from 'ramda';
import { defaultValues } from '../../constants';
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
const lg = defaultValues.isLarge;

const BASE = {
  fontFamily: typography.primary,
  fontSize: '1.25rem',
  color: color.primaryText
};

const variants = {
  default: BASE,
  fieldError: {
    ...BASE,
    fontFamily: typography.primary,
    color: color.errorText,
    fontSize: res ? '0.625rem' : md ? '0.75rem' : '1rem',
    fontWeight: 400
  },
  boldMilkH96: {
    fontFamily: typography.secondary,
    fontSize: res ? '1.125rem' : md ? '5rem' : '6rem'
  },
  boldMilkH28: {
    fontFamily: typography.secondary,
    fontSize: '1.4rem'
  },
  boldH40: {
    ...BASE,
    fontSize: res ? '1.25rem' : '2.5rem',
    fontWeight: 600
  },
  boldH48: {
    ...BASE,
    fontSize: sm ? '1.375rem' : res ? '2rem' : md ? '2.5rem' : '3rem',
    fontWeight: 600
  },
  boldH32: {
    ...BASE,
    fontSize: sm ? '1.125rem' : res ? '1.25rem' : md ? '1.75rem' : '2rem',
    fontWeight: 600
  },
  boldH32Sec: {
    ...BASE,
    fontSize: res ? '1.25rem' : md ? '1.3rem' : '1.8rem',
    fontWeight: 600
  },
  BoldH24: {
    ...BASE,
    fontSize: sm ? '0.875rem' : res ? '1rem' : md ? '1.25rem' : '1.5rem',
    fontWeight: 600
  },
  BoldH22: {
    ...BASE,
    fontSize: sm ? '0.825rem' : res ? '1.125rem' : md ? '1rem' : '1.375rem',
    fontWeight: 600
  },
  BoldH22Sec: {
    ...BASE,
    fontSize: res ? '1.125rem' : md ? '1rem' : '1.375rem',
    fontWeight: 600
  },
  BoldH28: {
    ...BASE,
    fontSize: res ? '1.125rem' : md ? '1.375rem' : '1.625rem',
    fontWeight: 600
  },

  D22: {
    ...BASE,
    fontSize: res ? '1.125rem' : md ? '1rem' : '1.375rem'
  },
  D20: {
    ...BASE,
    fontSize: sm ? '0.825rem' : res ? '1rem' : lg ? '1rem' : '1.20rem'
  },
  D24: {
    ...BASE,
    fontSize: res ? '1rem' : md ? '1.25rem' : '1.5rem'
  },
  D18: {
    ...BASE,
    fontSize: md ? '0.875rem' : '1.125rem'
  },
  D16: {
    ...BASE,
    fontSize: res ? '0.625rem' : md ? '0.75rem' : '1rem'
  },
  D28: {
    ...BASE,
    fontSize: res ? '1.125rem' : md ? '1.375rem' : '1.75rem'
  },

  D18Open: {
    ...BASE,
    fontFamily: typography.paragraph,
    fontSize: md ? '0.875rem' : '1.125rem'
  },
  D22Open: {
    ...BASE,
    fontFamily: typography.paragraph,
    fontSize: res ? '1.125rem' : md ? '1rem' : '1.375rem'
  },
  D16Open: {
    ...BASE,
    fontFamily: typography.paragraph,
    fontSize: res ? '0.625rem' : md ? '0.75rem' : '1rem'
  },
  D10: {
    ...BASE,
    fontFamily: typography.paragraph,
    fontSize: '0.625rem'
  },
  D12: {
    ...BASE,
    fontFamily: typography.paragraph,
    fontSize: '0.75rem'
  },
  LinkD24: {
    ...BASE,
    color: color.link,
    fontSize: res ? '1rem' : md ? '1.25rem' : '1.5rem',
    fontWeight: 600
  },
  LightH32: {
    ...BASE,
    fontSize: sm ? '1.125rem' : res ? '1.25rem' : md ? '1.75rem' : '2rem',
    fontWeight: 600,
    color: color.secondaryText
  }
};

export const Text = (props) => {
  const { variant = 'default', style: styleOverride, children, ...rest } = props;

  const style = mergeAll(flatten([variants[variant] || variants.default, styleOverride]));

  return (
    <Typography sx={style} {...rest}>
      {children}
    </Typography>
  );
};
