import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  longWidth: res ? '100%' : 'calc(((100% - 10px)/3)*2)',
  childCont: {
    px: res ? 1 : 4
  },
  popUpContainer: {
    maxWidth: 'none',
    width: res ? '90%' : md ? '80%' : '70%'
  },
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },

  nameCont: {
    width: '80%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },

  btn: (isLast) => ({
    width: '30px',
    height: '30px',
    minWidth: 0,
    fontSize: '1.35rem',
    marginTop: '10px',
    padding: 0,
    visibility: isLast ? 'hidden' : 'none'
  })
};
