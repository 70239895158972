import { Box } from '@mui/material';
import {
  UIPhoneInputTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  },
  nameWidth: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)',
  phoneWidth: defaultValues.isResponsive ? '100%' : 'calc(100%/3)',
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"country state"
    "district taluk"
    "village post"
    `
      : `
    "country state district"
    "taluk village post"
    `,
    gridTemplateColumns: res ? 'calc(50% - 5px) calc(50% - 5px)' : '1fr 1fr 1fr',
    gap: '10px'
  },
  gridCountry: {
    gridArea: 'country'
  },
  gridState: {
    gridArea: 'state'
  },
  gridDistrict: {
    gridArea: 'district'
  },
  gridTaluk: {
    gridArea: 'taluk'
  },
  gridVillage: {
    gridArea: 'village'
  },
  gridPostcode: {
    gridArea: 'post'
  }
};

export const EmergencyForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setBtndisabled,
  handleAddAnother,
  stateList,
  districts,
  talukas,
  villages,
  isValid,
  btnDisabled,
  dirty,
  emergency
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="name"
          title={getTitles('T-10081')}
          placeHolder={getTitles('T-10384')}
          error={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
        />
        <UIPhoneInputTitle
          phoneValue={values.phone_number}
          onChange={(val) => {
            setFieldValue('phone_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          required={true}
          width={styles.phoneWidth}
        />
      </Box>

      <UITextInputWithTitle
        name="relation"
        title={getTitles('T-10365')}
        placeHolder={getTitles('T-10366')}
        error={touched.relation && errors.relation}
        value={values.relation}
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        width={styles.nameWidth}
      />

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="address_line_one"
          title={getTitles('T-10357')}
          placeHolder={getTitles('T-10361')}
          error={touched.address_line_one && errors.address_line_one}
          value={values.address_line_one}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <UITextInputWithTitle
          name="address_line_two"
          title={getTitles('T-10358')}
          placeHolder={getTitles('T-10362')}
          error={touched.address_line_two && errors.address_line_two}
          value={values.address_line_two}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>

      <div style={styles.gridCont}>
        <UISelect
          name="country_id"
          value={values.country_id}
          title={getTitles('T-10363')}
          options={defaultValues.countriesList}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          required={true}
          error={touched.country_id && errors.country_id}
          containerStyles={styles.gridCountry}
        />
        <UISelect
          name="state_id"
          value={values.state_id}
          title={getTitles('T-10070')}
          options={stateList}
          optionLabel="name"
          returnValue={'_id'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.state_id && errors.state_id}
          placeholder={getTitles('T-10350')}
          required={true}
          containerStyles={styles.gridState}
        />

        <UISelect
          name="district_id"
          value={values.district_id}
          title={getTitles('T-10069')}
          options={districts}
          optionLabel="name"
          returnValue={'_id'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.district_id && errors.district_id}
          placeholder={getTitles('T-10350')}
          required={true}
          containerStyles={styles.gridDistrict}
        />

        <UISelect
          name="taluka_id"
          title={getTitles('T-10071')}
          placeholder={getTitles('T-10350')}
          options={talukas}
          optionLabel="name"
          returnValue={'_id'}
          error={touched.taluka_id && errors.taluka_id}
          value={values.taluka_id}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          containerStyles={styles.gridTaluk}
        />
        <UISelect
          name="village_id"
          value={values.village_id}
          title={getTitles('T-10068')}
          options={villages}
          optionLabel="name"
          returnValue={'_id'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.village_id && errors.village_id}
          placeholder={getTitles('T-10350')}
          required={true}
          containerStyles={styles.gridVillage}
        />

        <UITextInputWithTitle
          name="post_code"
          title={getTitles('T-10359')}
          placeHolder={getTitles('T-10381')}
          error={touched.post_code && errors.post_code}
          value={values.post_code}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          maxLength={6}
          containerStyles={styles.gridPostcode}
        />
      </div>

      <UISaveAndAdd
        title={emergency === true ? getTitles('T-10062') : getTitles('T-10477')}
        onAddAnother={handleAddAnother}
        disabled={!isValid || !dirty || btnDisabled}
      />
    </Box>
  );
};
