import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, societyOperationalSchema } from '../../../../utils';
import {
  DatePickers,
  UIPhoneInputTitle,
  UIRadioButton,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormTitleCard } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSocietyRequest,
  updateScrollToTop,
  updateSocietyRequest
} from '../../../../services/redux/slices';
import { getScroll } from '../../../../services/redux/selectors';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    maxWidth: 'none',
    gridArea: 'phone',
    marginTop: '0px'
  },
  radioSubCont: {
    width: defaultValues.isResponsive ? '100%' : 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    gap: '10px'
  },
  checkboxIcon: {
    color: color.primaryText
  },
  divider: {
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: 'calc(((100%/3 )* 2) - 5px)'
  },
  namegridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"first middle"
    "last none"
    "phone phone"
    `
      : `
    "first middle last"
    "phone phone none"
    `,
    gridTemplateColumns: res ? globalStyles.gridRes : globalStyles.gridNormal,
    gap: '10px',
    marginTop: '10px'
  },
  membergridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
    "total active"
    "count count"
    `
      : `
    "total active count"
    `,
    gridTemplateColumns: res ? globalStyles.gridRes : globalStyles.gridNormal,
    gap: '10px',
    marginTop: '10px'
  },
  secretGridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"first middle"
    "last none"
    "phone phone"
    

    `
      : `
    "first middle last"
    "phone phone none"


    `,
    gridTemplateColumns: res ? globalStyles.gridRes : globalStyles.gridNormal,
    gap: '10px',
    marginTop: '10px'
  },
  dateCont: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    gap: '10px'
  }
};

export const OperationalDetailsForm = ({ societyId, societyData, onNext, onPrev, currRender }) => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);

  const [btnDisabled, setBtndisabled] = useState(true);
  const { own, rented } = defaultValues.operatedBuilding;
  const { add, edit, view } = defaultValues.actionType;

  const { values, errors, touched, setValues, handleBlur, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        total_member: '',
        active_member: '',
        employee_count: '',
        chairman_first_name: '',
        chairman_middle_name: '',
        chairman_last_name: '',
        chairman_phone_number: '',
        secretary_first_name: '',
        secretary_middle_name: '',
        secretary_last_name: '',
        secretary_phone_number: '',
        food_lic_number: '',
        food_lic_renewal_date: null,
        food_lic_expire_date: null,
        operated_building: { id: '' },
        is_idle_collection_center: null,
        pf_scheme_for_producers: null,
        cow_count: '',
        buffalo_count: '',
        calf_count: ''
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: societyOperationalSchema
    });

  useEffect(() => {
    if (societyData) {
      let newValues = {
        ...societyData,
        food_lic_renewal_date: societyData.food_lic_renewal_date
          ? dayjs(societyData.food_lic_renewal_date)
          : null,
        food_lic_expire_date: societyData.food_lic_expire_date
          ? dayjs(societyData.food_lic_expire_date)
          : null
      };
      setValues(newValues);
    }
  }, [societyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { step_number: defaultValues.societyRegisterSteps.operationalDetails };
    if (currRender === add || (currRender === edit && !societyData)) {
      payload = {
        ...payload,
        _id: societyId,
        data: { ...values, operated_building: values.operated_building?.id }
      };
      dispatch(addSocietyRequest(payload));
    } else if (currRender === edit) {
      payload = {
        ...payload,
        _id: societyId,
        operational_details: { ...values, operated_building: values.operated_building?.id }
      };
      dispatch(updateSocietyRequest(payload));
    }
    onNext();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };
  return (
    <Box sx={styles.container}>
      <FormTitleCard title={getTitles('T-10999')}>
        <Box sx={styles.membergridCont}>
          <UITextInputWithTitle
            name="total_member"
            title={getTitles('T-10737')}
            placeHolder={getTitles('T-10360')}
            error={touched.total_member && errors.total_member}
            value={values.total_member}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.totalMember}
            maxLength={10}
            allowOnlyNumbers={true}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="active_member"
            title={getTitles('T-10738')}
            placeHolder={getTitles('T-10360')}
            error={touched.active_member && errors.active_member}
            value={values.active_member}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.activeMember}
            maxLength={10}
            allowOnlyNumbers={true}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="employee_count"
            title={getTitles('T-10739')}
            placeHolder={getTitles('T-10360')}
            error={touched.employee_count && errors.employee_count}
            value={values.employee_count}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.empCount}
            maxLength={10}
            allowOnlyNumbers={true}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
          />
        </Box>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10735')}>
        <Box sx={styles.namegridCont}>
          <UITextInputWithTitle
            name="chairman_first_name"
            title={getTitles('T-10041')}
            placeHolder={getTitles('T-10047')}
            error={touched.chairman_first_name && errors.chairman_first_name}
            value={values.chairman_first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.gridFirstName}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="chairman_middle_name"
            title={getTitles('T-10337')}
            placeHolder={getTitles('T-10338')}
            error={touched.chairman_middle_name && errors.chairman_middle_name}
            value={values.chairman_middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridMiddlename}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="chairman_last_name"
            title={getTitles('T-10042')}
            placeHolder={getTitles('T-10048')}
            value={values.chairman_last_name}
            error={touched.chairman_last_name && errors.chairman_last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.gridLastname}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UIPhoneInputTitle
            phoneValue={values.chairman_phone_number}
            onChange={(val) => {
              setFieldValue('chairman_phone_number', val);
              setBtndisabled(val ? false : true);
            }}
            handleError={(isError) => setBtndisabled(isError)}
            required={true}
            containerStyles={styles.phone}
            disabled={currRender === view}
          />
        </Box>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10736')}>
        <Box sx={styles.secretGridCont}>
          <UITextInputWithTitle
            name="secretary_first_name"
            title={getTitles('T-10041')}
            placeHolder={getTitles('T-10047')}
            error={touched.secretary_first_name && errors.secretary_first_name}
            value={values.secretary_first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.gridFirstName}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="secretary_middle_name"
            title={getTitles('T-10337')}
            placeHolder={getTitles('T-10338')}
            error={touched.secretary_middle_name && errors.secretary_middle_name}
            value={values.secretary_middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridMiddlename}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="secretary_last_name"
            title={getTitles('T-10042')}
            placeHolder={getTitles('T-10048')}
            value={values.secretary_last_name}
            error={touched.secretary_last_name && errors.secretary_last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={globalStyles.gridLastname}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UIPhoneInputTitle
            phoneValue={values.secretary_phone_number}
            onChange={(val) => {
              setFieldValue('secretary_phone_number', val);
              setBtndisabled(val ? false : true);
            }}
            handleError={(isError) => setBtndisabled(isError)}
            required={true}
            containerStyles={styles.phone}
            disabled={currRender === view}
          />
        </Box>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10740')}>
        <UITextInputWithTitle
          name="food_lic_number"
          title={getTitles('T-10741')}
          placeHolder={getTitles('T-10360')}
          value={values.food_lic_number}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          maxLength={10}
          disabled={currRender === view}
        />

        <Box sx={styles.dateCont}>
          <DatePickers
            dateValue={values.food_lic_renewal_date}
            onDateChange={(val) =>
              handleChange({ target: { value: val, name: 'food_lic_renewal_date' } })
            }
            title={getTitles('T-10742')}
            error={errors.food_lic_renewal_date}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={values.food_lic_expire_date}
            onDateChange={(val) =>
              handleChange({ target: { value: val, name: 'food_lic_expire_date' } })
            }
            title={getTitles('T-10743')}
            error={errors.food_lic_expire_date}
            disabled={currRender === view}
          />
        </Box>

        <UITitleWithContent title={getTitles('T-10744')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10752')}
              checked={values.operated_building?.id === own}
              onClick={() => handleChange({ target: { name: 'operated_building.id', value: own } })}
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
            <UIRadioButton
              title={getTitles('T-10753')}
              checked={values.operated_building?.id === rented}
              onClick={() =>
                handleChange({ target: { name: 'operated_building.id', value: rented } })
              }
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10745')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.is_idle_collection_center}
              onClick={() =>
                handleChange({ target: { name: 'is_idle_collection_center', value: true } })
              }
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.is_idle_collection_center === false}
              onClick={() =>
                handleChange({ target: { name: 'is_idle_collection_center', value: false } })
              }
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10746')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.pf_scheme_for_producers}
              onClick={() =>
                handleChange({ target: { name: 'pf_scheme_for_producers', value: true } })
              }
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.pf_scheme_for_producers === false}
              onClick={() =>
                handleChange({ target: { name: 'pf_scheme_for_producers', value: false } })
              }
              containerStyle={{ width: styles.width }}
              disabled={currRender === view}
            />
          </Box>
        </UITitleWithContent>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10747')}>
        <Box sx={styles.namegridCont}>
          <UITextInputWithTitle
            name="cow_count"
            title={getTitles('T-10748')}
            placeHolder={getTitles('T-10360')}
            error={touched.cow_count && errors.cow_count}
            value={values.cow_count}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridFirstName}
            allowOnlyNumbers={true}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="buffalo_count"
            title={getTitles('T-10749')}
            placeHolder={getTitles('T-10360')}
            error={touched.buffalo_count && errors.buffalo_count}
            value={values.buffalo_count}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridMiddlename}
            allowOnlyNumbers={true}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="calf_count"
            title={getTitles('T-10750')}
            placeHolder={getTitles('T-10360')}
            value={values.calf_count}
            error={touched.calf_count && errors.calf_count}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridLastname}
            allowOnlyNumbers={true}
            disabled={currRender === view}
          />
        </Box>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={currRender !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
