import { defaultValues, getErrorMessage, SCREEN_PATH } from '../../constants';
import { Screen, SearchBarWithButton, Text, UILayout } from '../../ui-kits';
import { getFullName, getTitles, resetMessages } from '../../utils';
import { BasicProfile } from '../common/utility-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFarmerInfo,
  getFarmerInfoErrorMsg,
  getInsuranceCurPage,
  getInsuranceFarmerLoading,
  getInsuranceTotalPage
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import {
  getInsuranceFarmerRequest,
  resetInsuranceMsg,
  updateDetailsPg
} from '../../services/redux/slices';
import { styles } from './style';

export const ManageInsuranceFarmerTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const farmerDetails = useSelector(getFarmerInfo);
  const error = useSelector(getFarmerInfoErrorMsg);
  const isLoading = useSelector(getInsuranceFarmerLoading);
  const totalPages = useSelector(getInsuranceTotalPage);
  const currentPage = useSelector(getInsuranceCurPage);

  const [farmers, setFarmers] = useState([]);
  const [searchedTxt, setSearchedTxt] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    searchedTxt.length >= 2 ? setButtonDisable(false) : setButtonDisable(true);
  }, [searchedTxt]);

  useEffect(() => {
    if (farmerDetails) {
      setFarmers(farmerDetails);
    }
  }, [farmerDetails]);

  const handleButtonClick = () => {
    dispatch(getInsuranceFarmerRequest({ search_text: searchedTxt }));
  };

  useEffect(() => {
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetInsuranceMsg()));
  }, [error]);

  const handlePagination = (e, data) => {
    dispatch(getInsuranceFarmerRequest({ page: data }));
  };

  const tableData = farmers.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-11406'),
          values: (
            <Text style={styles.id} variant="D22">
              {item?.farmer_id}
            </Text>
          ),
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-10802'),
          columnWidth: styles.columnWidth,
          values: (
            <BasicProfile
              title={getFullName([item?.first_name, item?.middle_name, item?.last_name])}
              url={item?.id_photo}
              startIconStyle={styles.profileImg}
              textVariant="D22"
            />
          )
        },
        {
          title: getTitles('T-10440'),
          values: <Text variant="D22">{item?.phone_number}</Text>,
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-10734'),
          values: <Text variant="D22">{item?.village_name}</Text>,
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-10173'),
          columnWidth: styles.columnWidth,
          values: (
            <Text
              variant="D22"
              style={styles.cattleList}
              onClick={() => navigateToCattleList(item?.farmer_id)}>
              {getTitles('T-11412')}
            </Text>
          )
        }
      ];
    } else {
      return {
        title: (
          <BasicProfile
            title={getFullName([item?.first_name, item?.middle_name, item?.last_name])}
            url={item?.id_photo}
            startIconStyle={styles.profileImg}
            textVariant="D22"
          />
        ),
        details: [
          {
            subTitle: getTitles('T-10751'),
            action: <Text variant="D20">{item?.farmer_id}</Text>
          },
          {
            subTitle: getTitles('T-10440'),
            action: <Text variant="D20">{item?.phone_number}</Text>
          },
          {
            subTitle: getTitles('T-10734'),
            action: <Text variant="D20">{item?.village_name}</Text>
          },
          {
            subTitle: getTitles('T-10173'),
            action: (
              <Text
                variant="D20"
                style={styles.cattleList}
                onClick={() => navigateToCattleList(item?.farmer_id)}>
                {getTitles('T-11412')}
              </Text>
            )
          }
        ]
      };
    }
  });

  const navigateToCattleList = (id) => {
    dispatch(updateDetailsPg(true));
    navigate(`${SCREEN_PATH.MANAGE_INSURANCE_FARMER_CATTLES_LIST}/${id}`);
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10037')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 2 }}>
          {getTitles('T-10037')}
        </Text>
      )}
      <SearchBarWithButton
        searchedVlaue={(inputValue) => setSearchedTxt(inputValue)}
        disableButton={buttonDisable}
        buttonClicked={handleButtonClick}
        placeHolder={getTitles('T-11404')}
      />
      <UILayout
        tableItems={tableData}
        showNoData={!farmers.length > 0}
        showDataTable={!defaultValues.isResponsive}
        currPage={currentPage}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        handlePagination={handlePagination}
        message={getErrorMessage('E-10073')}
      />
    </Screen>
  );
};
