import { Box } from '@mui/material';
import {
  CollapsableData,
  DatePickers,
  NoData,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { useFormik } from 'formik';
import { employeeAccountsSchema, getTitles } from '../../../../utils';
import { useEffect, useRef, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import {
  getDistrictRequest,
  getStateRequest,
  getTalukRequest,
  getVillageRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill,
  updateEmployeeRequest
} from '../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../../constants';
import dayjs from 'dayjs';
import { ViewAccountDetails } from './view-account-details';
import {
  getDistrict,
  getDistrictNextPage,
  getTaluka,
  getTalukaNextPage,
  getVillage,
  getVillageNextPage
} from '../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  dateCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'end'
  },
  nameWidth: res ? '100%' : 'calc((100% - 10px)/3)',
  addressWidth: res ? '100%' : 'calc((100% - 10px)/2)',
  accountWidth: res ? '100%' : 'calc((100% - 30px)/3)',
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"country state"
    "district taluk"
    "village post"
    `
      : `
    "country state district"
    "taluk village post"
    `,
    gridTemplateColumns: res ? 'calc(50% - 5px) calc(50% - 5px)' : '1fr 1fr 1fr',
    gap: '10px'
  },
  gridCountry: {
    gridArea: 'country'
  },
  gridState: {
    gridArea: 'state'
  },
  gridDistrict: {
    gridArea: 'district'
  },
  gridTaluk: {
    gridArea: 'taluk'
  },
  gridVillage: {
    gridArea: 'village'
  },
  gridPostcode: {
    gridArea: 'post'
  }
};

export const EmployeeAccountDetails = ({
  employeeId,
  states,
  editable,
  sbAccount,
  pfAccount,
  insurance
}) => {
  const dispatch = useDispatch();

  const districts = useSelector(getDistrict);
  const talukas = useSelector(getTaluka);
  const villages = useSelector(getVillage);
  const districtNxtPg = useSelector(getDistrictNextPage);
  const villageNxtPge = useSelector(getVillageNextPage);
  const talukNxtPge = useSelector(getTalukaNextPage);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [open, setOpen] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [isEmp, setIsEmp] = useState(false);
  const [sbDisable, setSbDisable] = useState(false);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldTouched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      sb_account_number: '',
      bank_name: '',
      ifsc_code: '',
      branch_address_line_one: '',
      branch_address_line_two: '',
      country_id: '',
      state_id: '',
      district_id: '',
      taluka_id: '',
      village_id: '',
      post_code: '',
      pf_account_number: '',
      pf_account_details: '',
      insurance_policy_number: '',
      insurance_provider: '',
      insurance_policy_details: '',
      start_date: null,
      end_date: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeeAccountsSchema
  });

  useEffect(() => {
    setStateList(states);
  }, [states]);

  const previousOptions = useRef({
    district: districtsList?.length,
    village: villagesList?.length,
    taluka: talukasList?.length,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    if (editable) {
      if (districtsList?.length !== previousOptions?.district?.length) {
        deduplicateAndSet(districtsList, districts, setDistrictsLists);
      }
      if (talukasList?.length !== previousOptions?.taluka?.length) {
        deduplicateAndSet(talukasList, talukas, setTalukasList);
      }
      if (villagesList?.length !== previousOptions?.village?.length) {
        deduplicateAndSet(villagesList, villages, setVillagesList);
      }
      if (districtNxtPg !== previousOptions?.districtNxtPage && districtNxtPg !== null) {
        dispatch(getDistrictRequest({ state_id: values.state_id, page: districtNxtPg }));
      }
      if (talukNxtPge !== previousOptions?.talukaNextPage && talukNxtPge !== null) {
        dispatch(getTalukRequest({ district_id: values.district_id, page: talukNxtPge }));
      }

      if (villageNxtPge !== previousOptions?.villageNxtPage && villageNxtPge !== null) {
        dispatch(getVillageRequest({ taluka_id: values.taluka_id, page: villageNxtPge }));
      }

      previousOptions.current = {
        district: districtsList?.length,
        village: villagesList?.length,
        taluka: talukasList?.length,
        districtNxtPage: districtNxtPg,
        villageNxtPage: villageNxtPge,
        talukaNextPage: talukNxtPge
      };
    }
  }, [districts, talukas, villages, districtNxtPg, villageNxtPge, talukNxtPge]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems);
  };

  useEffect(() => {
    setSbDisable(values.sb_account_number ? false : true);
  }, [values.sb_account_number]);

  useEffect(() => {
    let newValues = { ...values };
    if (sbAccount) {
      setIsEmp(true);
      newValues = { ...newValues, ...sbAccount };
    }
    if (pfAccount) {
      setIsEmp(true);
      newValues = { ...newValues, ...pfAccount };
    }
    if (insurance) {
      setIsEmp(true);
      newValues = {
        ...newValues,
        ...insurance,
        start_date: dayjs(insurance.start_date),
        end_date: dayjs(insurance.end_date)
      };
    }
    setValues(newValues);

    sbAccount && dispatch(getDistrictRequest({ state_id: sbAccount.state_id, page: 1 }));

    sbAccount && dispatch(getTalukRequest({ district_id: sbAccount.district_id, page: 1 }));

    sbAccount && dispatch(getVillageRequest({ taluka_id: sbAccount.taluka_id, page: 1 }));
  }, [sbAccount, pfAccount, insurance, setValues, states]);

  const previousValues = useRef({
    state_id: values.state_id,
    district_id: values.district_id,
    taluka_id: values.taluka_id
  });

  useEffect(() => {
    const prev = previousValues.current;
    if (values.state_id !== prev.state_id && !isEmp) {
      dispatch(getDistrictRequest({ state_id: values.state_id, page: 1 }));
      setDistrictsLists([]);
      setTalukasList([]);
      setVillagesList([]);
      setFieldValue('district_id', '');
      setFieldValue('taluka_id', '');
      setFieldValue('village_id', '');
    }
    if (values.district_id !== prev.district_id && !isEmp) {
      dispatch(getTalukRequest({ district_id: values.district_id, page: 1 }));
      setTalukasList([]);
      setVillagesList([]);
      setFieldValue('taluka_id', '');
      setFieldValue('village_id', '');
    }
    if (values.taluka_id !== prev.taluka_id && !isEmp) {
      dispatch(getVillageRequest({ taluka_id: values.taluka_id, page: 1 }));
      setVillagesList([]);
      setFieldValue('village_id', '');
    }

    previousValues.current = {
      state_id: values.state_id,
      district_id: values.district_id,
      taluka_id: values.taluka_id
    };
  }, [values.state_id, values.district_id, values.taluka_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
    setIsEmp(false);
  };

  const handleSubmit = () => {
    let payload = {
      _id: employeeId,
      sb_account: {
        sb_account_number: values.sb_account_number,
        bank_name: values.bank_name,
        ifsc_code: values.ifsc_code,
        branch_address_line_one: values.branch_address_line_one,
        branch_address_line_two: values.branch_address_line_two,
        country_id: values.country_id,
        state_id: values.state_id,
        district_id: values.district_id,
        taluka_id: values.taluka_id,
        village_id: values.village_id,
        post_code: values.post_code
      },
      pf_account: {
        pf_account_number: values.pf_account_number,
        pf_account_details: values.pf_account_details
      },
      insurance: {
        insurance_policy_number: values.insurance_policy_number,
        insurance_provider: values.insurance_provider,
        insurance_policy_details: values.insurance_policy_details,
        start_date: values.start_date,
        end_date: values.end_date
      }
    };
    dispatch(updateEmployeeRequest(payload));
  };

  const handleAccountModal = (val) => {
    setOpen(val);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        <CollapsableData
          title={getTitles('T-10432')}
          isOpen={open === 1}
          isStatus={false}
          handleOpen={(val) => {
            handleAccountModal(val ? 1 : null);
            val && dispatch(setClearProcessStateDistTalVill());
            val && dispatch(setClearProcessPages());
            val && dispatch(getStateRequest({ page: 1 }));
          }}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="sb_account_number"
              title={getTitles('T-10370')}
              placeHolder={getTitles('T-10360')}
              error={touched.sb_account_number && errors.sb_account_number}
              value={values.sb_account_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              allowOnlyNumbers={true}
            />
            <UITextInputWithTitle
              name="bank_name"
              title={getTitles('T-10404')}
              placeHolder={getTitles('T-10384')}
              error={touched.bank_name && errors.bank_name}
              value={values.bank_name}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              disabled={sbDisable}
            />
            <UITextInputWithTitle
              name="ifsc_code"
              title={getTitles('T-10405')}
              placeHolder={getTitles('T-10406')}
              error={touched.ifsc_code && errors.ifsc_code}
              value={values.ifsc_code}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              disabled={sbDisable}
            />
          </Box>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="branch_address_line_one"
              title={getTitles('T-10430')}
              placeHolder={getTitles('T-10361')}
              error={touched.branch_address_line_one && errors.branch_address_line_one}
              value={values.branch_address_line_one}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.addressWidth}
              disabled={sbDisable}
            />
            <UITextInputWithTitle
              name="branch_address_line_two"
              title={getTitles('T-10431')}
              placeHolder={getTitles('T-10362')}
              error={touched.branch_address_line_two && errors.branch_address_line_two}
              value={values.branch_address_line_two}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.addressWidth}
              disabled={sbDisable}
            />
          </Box>
          <Box sx={styles.gridCont}>
            <UISelect
              name="country_id"
              value={values.country_id}
              title={getTitles('T-10363')}
              options={defaultValues.countriesList}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={getTitles('T-10350')}
              error={touched.country_id && errors.country_id}
              containerStyles={styles.gridCountry}
              disabled={sbDisable}
            />
            <UISelect
              name="state_id"
              value={values.state_id}
              title={getTitles('T-10070')}
              options={stateList}
              optionLabel="name"
              returnValue={'_id'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.state_id && errors.state_id}
              placeholder={getTitles('T-10350')}
              containerStyles={styles.gridState}
              disabled={sbDisable}
            />

            <UISelect
              name="district_id"
              value={values.district_id}
              title={getTitles('T-10069')}
              options={districtsList}
              optionLabel="name"
              returnValue={'_id'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.district_id && errors.district_id}
              placeholder={getTitles('T-10350')}
              containerStyles={styles.gridDistrict}
              disabled={sbDisable}
            />

            <UISelect
              name="taluka_id"
              title={getTitles('T-10071')}
              placeholder={getTitles('T-10350')}
              options={talukasList}
              optionLabel="name"
              returnValue={'_id'}
              error={touched.taluka_id && errors.taluka_id}
              value={values.taluka_id}
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={styles.gridTaluk}
              disabled={sbDisable}
            />
            <UISelect
              name="village_id"
              value={values.village_id}
              title={getTitles('T-10068')}
              options={villagesList}
              optionLabel="name"
              returnValue={'_id'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.village_id && errors.village_id}
              placeholder={getTitles('T-10350')}
              containerStyles={styles.gridVillage}
              disabled={sbDisable}
            />

            <UITextInputWithTitle
              name="post_code"
              title={getTitles('T-10359')}
              placeHolder={getTitles('T-10381')}
              error={touched.post_code && errors.post_code}
              value={values.post_code}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={6}
              containerStyles={styles.gridPostcode}
              disabled={sbDisable}
            />
          </Box>
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10433')}
          isOpen={open === 2}
          isStatus={false}
          handleOpen={(val) => handleAccountModal(val ? 2 : null)}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="pf_account_number"
              title={getTitles('T-10371')}
              placeHolder={getTitles('T-10413')}
              error={touched.pf_account_number && errors.pf_account_number}
              value={values.pf_account_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
            <UITextInputWithTitle
              name="pf_account_details"
              title={getTitles('T-10409')}
              placeHolder={getTitles('T-10373')}
              error={touched.pf_account_details && errors.pf_account_details}
              value={values.pf_account_details}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
          </Box>
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10434')}
          isOpen={open === 3}
          isStatus={false}
          handleOpen={(val) => handleAccountModal(val ? 3 : null)}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="insurance_policy_number"
              title={getTitles('T-10372')}
              placeHolder={getTitles('T-10414')}
              error={touched.insurance_policy_number && errors.insurance_policy_number}
              value={values.insurance_policy_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
              required={true}
              titleStyle={globalStyles.text}
            />

            <UITextInputWithTitle
              name="insurance_provider"
              title={getTitles('T-10655')}
              placeHolder={getTitles('T-10414')}
              error={touched.insurance_provider && errors.insurance_provider}
              value={values.insurance_provider}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
              required={true}
            />

            <UITextInputWithTitle
              name="insurance_policy_details"
              title={getTitles('T-10410')}
              placeHolder={getTitles('T-10373')}
              error={touched.insurance_policy_details && errors.insurance_policy_details}
              value={values.insurance_policy_details}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
          </Box>

          <Box sx={styles.nameCont}>
            <DatePickers
              dateValue={values.start_date}
              onDateChange={(val) => handleChange({ target: { value: val, name: 'start_date' } })}
              title={getTitles('T-10411')}
              width={styles.accountWidth}
              required={true}
              error={touched.start_date && errors.start_date}
              maxDate={values.end_date}
              onOpen={() => setFieldTouched('start_date', true)}
            />

            <DatePickers
              dateValue={values.end_date}
              onDateChange={(val) => handleChange({ target: { value: val, name: 'end_date' } })}
              title={getTitles('T-10412')}
              width={styles.accountWidth}
              required={true}
              minDate={values.start_date}
              error={touched.end_date && errors.end_date}
              onOpen={() => setFieldTouched('end_date', true)}
            />
          </Box>
        </CollapsableData>

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {pfAccount || insurance || sbAccount ? (
        <ViewAccountDetails
          sbAccount={sbAccount}
          pfAccount={pfAccount}
          insurance={insurance}
          stateList={states}
          districtsList={districtsList}
          talukasList={talukasList}
          villagesList={villagesList}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
