import errors from './errorMessages.json';
import { KEYS } from './keys';
import successMessages from './successMessages.json';

export const lang = {
  english: 'english',
  kannada: 'kannada',
  hindi: 'hindi',
  marathi: 'marathi'
};

export function getErrorMessage(code, varNameArr) {
  const currentLang = localStorage.getItem(KEYS.LANGUAGE) || lang.english;
  const errorMsg = errors[code] && errors[code].message[currentLang];

  if (errorMsg && errorMsg.indexOf('<') !== -1) {
    let updatedMsg = errorMsg;

    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedMsg = updatedMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',

          val
        );
      });
    }
    return updatedMsg;
  } else {
    return errorMsg;
  }
}

export function getSuccessMessage(code, varNameArr) {
  const currentLang = localStorage.getItem(KEYS.LANGUAGE) || lang.english;
  const successMsg = successMessages[code] && successMessages[code].message[currentLang];

  if (successMsg && successMsg.indexOf('<') !== -1) {
    let updatedSuccessMsg = successMsg;

    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedSuccessMsg = updatedSuccessMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',

          val
        );
      });
    }

    return updatedSuccessMsg;
  } else {
    return successMsg;
  }
}
