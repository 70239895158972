import { Box } from '@mui/material';
import { SearchBar } from '../search-bar';
import { color } from '../../theme';
import { UIRefresh } from '../refresh';
import { UISort } from '../sort';
import { UIFilter } from '../filter';
import { UIButton } from '../button';
import { UIDataTable } from '../data-table';
import { defaultValues } from '../../constants';
import { UIPagination } from '../pagination';
import { Text } from '../text';
import { NoData } from '../no-data';
import { CollapsableList } from '../collapsable-list';
import { SelectDropdown } from '../select-dropdown';
import { getTitles } from '../../utils';
import { UIBackButton } from '../back-button';
import { DetailsCard } from '../../components/common/cards/details-card';

const res = defaultValues.isResponsive;

export const UILayout = ({
  tableItems,
  tableTitle,
  handleSort,
  handleSearch,
  handleFilter,
  handleRefresh,
  handleAddButton,
  searchBarPlaceHolder,
  optionsForSorting = defaultValues.commonSortOptions, // default options for sorting
  optionsForFiltering = defaultValues.commonFilterOption, // default options filtering
  currentlyActiveSortOption, // make it null when refreshing to clear the active sort option
  currentlyActiveFilterOption, // to clear selected filter option , pass the returning value of handle filter and on refresh make it null
  isMultiSelectFilterOption = false,
  clearSearch,
  currPage,
  pageCount,
  handlePagination,
  showNoData = true,
  showPagination = false,
  message,
  showDataTable = true,
  options,
  selectedOption,
  selectValue,
  subMessage,
  onTitleClick,
  showProfile = false,
  isReadOnly,
  onlySearchAlphabets = false,
  onlySearchNumbers = false,
  disableSearch = false,
  handleBackButton = false,
  addBtnTitle = getTitles('T-10078'),
  handleSecondaryAdd,
  secondaryAddBtnTitle = getTitles('T-10479'),
  collapsable = true,
  addstartIcon,
  addBtnStyle,
  secondaryAddDisabled,
  currPageName,
  numFilter = false,
  customData,
  tableHeaderTextStyle
}) => {
  const styles = {
    container: {
      width: '100%',
      paddingTop: defaultValues.isResponsive ? 1 : 2,
      boxSizing: 'border-box'
    },
    subContainer: {
      mt: 1,
      p: defaultValues.isResponsive ? '20px 10px' : 2,
      backgroundColor: color.primaryBackground,
      borderRadius: 2,
      border: 1,
      borderColor: color.primaryBorder,
      minHeight: '60vh'
    },
    optionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2,
      marginTop: '10px'
    },
    options: { display: 'flex', alignItems: 'center' },
    btn: (style) => ({
      width: defaultValues.isResponsive ? '32px' : '200px',
      height: defaultValues.isResponsive ? '32px' : '48px',
      minWidth: defaultValues.isResponsive && 0,
      fontSize: res ? '1.35rem' : '1.3rem',
      ...style
    }),
    secAddbtn: {
      width: defaultValues.isResponsive ? '150px' : '250px',
      height: defaultValues.isResponsive ? '32px' : '48px'
    },
    pagination: { display: 'flex', justifyContent: 'center', mt: 4 }
  };

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mb: 1 }}>
          {currPageName}
        </Text>
      )}
      <Box sx={styles.options}>
        {handleSearch && (
          <SearchBar
            allowOnlyAlphabets={onlySearchAlphabets}
            allowOnlyNumbers={onlySearchNumbers}
            searchedVlaue={handleSearch}
            placeHolder={searchBarPlaceHolder}
            disableSearch={disableSearch}
            onRefresh={clearSearch}
          />
        )}
        {!defaultValues.isResponsive && options && (
          <SelectDropdown options={options} selOP={selectValue} selectedOption={selectedOption} />
        )}
      </Box>
      {customData && customData}
      <Box sx={styles.subContainer}>
        <Box sx={styles.optionsContainer}>
          <Box sx={styles.options}>
            {handleBackButton && !defaultValues.isResponsive && (
              <UIBackButton onClick={handleBackButton} />
            )}
            {handleRefresh && <UIRefresh handleRefresh={handleRefresh} />}
            {handleSort && (
              <UISort
                selectedOption={handleSort}
                dropdownOptions={optionsForSorting}
                activeOption={currentlyActiveSortOption}
              />
            )}
            {handleFilter && (
              <UIFilter
                onlyNum={numFilter}
                isMultiSelect={isMultiSelectFilterOption}
                options={optionsForFiltering}
                applyFilter={handleFilter}
                selectedOption={currentlyActiveFilterOption}
              />
            )}
            {tableTitle && <Text variant="BoldH28">{tableTitle}</Text>}
            {defaultValues.isResponsive && options && (
              <SelectDropdown
                options={options}
                selOP={selectValue}
                selectedOption={selectedOption}
              />
            )}
          </Box>
          <Box>
            {handleAddButton && (
              <UIButton
                title={defaultValues.isResponsive ? '+' : addBtnTitle}
                startIcon={addstartIcon}
                style={styles.btn(addBtnStyle)}
                onClick={handleAddButton}
                disabled={isReadOnly}
              />
            )}
          </Box>
        </Box>

        {showDataTable && (
          <UIDataTable tableRows={tableItems} headerTextStyle={tableHeaderTextStyle} />
        )}
        {!showDataTable && collapsable && (
          <CollapsableList
            contentArray={tableItems}
            onTitleClick={onTitleClick}
            showProfile={showProfile}
          />
        )}

        {!showDataTable && !collapsable && (
          <DetailsCard
            contentArray={tableItems}
            onTitleClick={onTitleClick}
            showProfile={showProfile}
          />
        )}
        {showNoData && <NoData message={message} subMsg={subMessage} />}
        {showPagination && (
          <Box sx={styles.pagination}>
            <UIPagination onChange={handlePagination} count={pageCount} currentPage={currPage} />
          </Box>
        )}
        {handleSecondaryAdd && (
          <Box sx={styles.pagination}>
            <UIButton
              title={secondaryAddBtnTitle}
              style={styles.secAddbtn}
              onClick={handleSecondaryAdd}
              disabled={isReadOnly || secondaryAddDisabled}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
