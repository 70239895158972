import { Box } from '@mui/material';

import { getTitles } from '../../../../utils';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { useEffect, useRef, useState } from 'react';
import { DragDrop, NoData, PopupModal, RenderFile, Text } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { deleteDocsRequest, uploadEmployeeDocsRequest } from '../../../../services/redux/slices';
import { ViewEmployeeDocs } from './view-employee-docs';
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px'
  },
  dragNdrop: {
    width: defaultValues.isResponsive ? 'calc(50% - 10px)' : 'calc(30% - 2px)'
  },
  editTextCont: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  modCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};

export const EmployeeDocuments = ({ employeeId, employeeData, editable }) => {
  const dispatch = useDispatch();
  const idRef = useRef();
  const agreementRef = useRef();
  const adharRef = useRef();
  const panRef = useRef();
  const drivingRef = useRef();
  const insuranceRef = useRef();
  const voterRef = useRef();
  const rationRef = useRef();
  const vehicleRef = useRef();
  const certificateRef = useRef();

  const [idSizePhoto, setIdSizePhoto] = useState(null);
  const [agreementCopy, setagreementCopy] = useState(null);
  const [adhaarCopy, setAdhaarCopy] = useState(null);
  const [pancardCopy, setPancardCopy] = useState(null);
  const [drivingLiscenceCopy, setDrivingLiscenceCopy] = useState(null);
  const [insuranceCopy, setInsuranceCopy] = useState(null);
  const [voterIdCopy, setVoterIdCopy] = useState(null);
  const [rationCopy, setRationCopy] = useState(null);
  const [vehicleCopy, setvehicleCopy] = useState(null);
  const [certificateCopy, setCertificateCopy] = useState(null);
  const [open, setOpen] = useState(null);
  const [modalBtnDisabled, setModalBtnDisabled] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(1);

  const extractDetails = (url) => {
    const arr = url.split('.');
    const title = arr[arr.length - 2].split('/');
    return {
      item: url,
      name: title[title.length - 1],
      type: arr[arr.length - 1],
      elemType: true
    };
  };

  useEffect(() => {
    if (employeeData) {
      setIdSizePhoto(employeeData.id_photo ? extractDetails(employeeData.id_photo) : null);

      setagreementCopy(
        employeeData.agreement_photo ? extractDetails(employeeData.agreement_photo) : null
      );
      setAdhaarCopy(employeeData.aadhaar_photo ? extractDetails(employeeData.aadhaar_photo) : null);
      setPancardCopy(
        employeeData.pan_card_photo ? extractDetails(employeeData.pan_card_photo) : null
      );

      setDrivingLiscenceCopy(
        employeeData.driving_license_photo
          ? extractDetails(employeeData.driving_license_photo)
          : null
      );
      setInsuranceCopy(
        employeeData.vehicle_insurance_photo
          ? extractDetails(employeeData.vehicle_insurance_photo)
          : null
      );
      setVoterIdCopy(
        employeeData.voter_id_photo ? extractDetails(employeeData.voter_id_photo) : null
      );
      setRationCopy(
        employeeData.ration_card_photo ? extractDetails(employeeData.ration_card_photo) : null
      );

      setvehicleCopy(
        employeeData.vehicle_rc_photo ? extractDetails(employeeData.vehicle_rc_photo) : null
      );
      setCertificateCopy(
        employeeData.certification_photo ? extractDetails(employeeData.certification_photo) : null
      );
    }
  }, [employeeData]);

  const handleEditClick = (item, ref, preview, category) => {
    setOpen({ item, ref, preview, category });
    setModalBtnDisabled(true);
  };

  const handleModalClose = () => {
    setOpen(null);
  };

  const renderFileUrl = (objFile) => {
    if (objFile && objFile.item && typeof objFile.item === 'string') {
      return objFile.item;
    } else if (
      objFile &&
      objFile.item &&
      objFile.item.item &&
      typeof objFile.item.item === 'string'
    ) {
      return objFile.item.item;
    } else {
      return objFile && objFile.preview && objFile.preview[0];
    }
  };

  const handleModalSave = (file, type) => {
    if (type === defaultValues.appLogicalTexts.idSize) {
      setIdSizePhoto(file);
    }
    if (type === defaultValues.appLogicalTexts.agreement) {
      setagreementCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.adhaar) {
      setAdhaarCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.pancard) {
      setPancardCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.drivingLic) {
      setDrivingLiscenceCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.insurance) {
      setInsuranceCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.voterId) {
      setVoterIdCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.ration) {
      setRationCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.vehicle) {
      setvehicleCopy(file);
    }
    if (type === defaultValues.appLogicalTexts.certificate) {
      setCertificateCopy(file);
    }
    setOpen(null);
  };

  const handleSubmit = () => {
    let payload = { _id: employeeId };
    if (idSizePhoto && !idSizePhoto.elemType) payload.id_photo = idSizePhoto;
    if (agreementCopy && !agreementCopy.elemType) payload.agreement_photo = agreementCopy;
    if (adhaarCopy && !adhaarCopy.elemType) payload.aadhaar_photo = adhaarCopy;
    if (pancardCopy && !pancardCopy.elemType) payload.pan_card_photo = pancardCopy;
    if (drivingLiscenceCopy && !drivingLiscenceCopy.elemType)
      payload.driving_license_photo = drivingLiscenceCopy;
    if (insuranceCopy && !insuranceCopy.elemType) payload.vehicle_insurance_photo = insuranceCopy;
    if (voterIdCopy && !voterIdCopy.elemType) payload.voter_id_photo = voterIdCopy;
    if (rationCopy && !rationCopy.elemType) payload.ration_card_photo = rationCopy;
    if (vehicleCopy && !vehicleCopy.elemType) payload.vehicle_rc_photo = vehicleCopy;
    if (certificateCopy && !certificateCopy.elemType) payload.certification_photo = certificateCopy;
    dispatch(uploadEmployeeDocsRequest(payload));
  };

  return (
    <Box sx={styles.container}>
      {editable ? (
        <Box sx={{ width: '100%' }}>
          <Box sx={styles.subCont}>
            <DragDrop
              inputRef={idRef}
              title={getTitles('T-10398')}
              fileData={idSizePhoto}
              onDeleteClick={() => dispatch(deleteDocsRequest({ _id: employeeId, id_photo: true }))}
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setIdSizePhoto(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(idSizePhoto, idRef, preview, defaultValues.appLogicalTexts.idSize)
              }
              required={true}
              showDelete={false}
            />
            <DragDrop
              inputRef={agreementRef}
              title={getTitles('T-10399')}
              fileData={agreementCopy}
              onDeleteClick={() =>
                employeeData && employeeData.agreement_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, agreement_photo: true }))
                  : setagreementCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setagreementCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(
                  agreementCopy,
                  agreementRef,
                  preview,
                  defaultValues.appLogicalTexts.agreement
                )
              }
            />
          </Box>

          <Box sx={styles.subCont}>
            <DragDrop
              inputRef={adharRef}
              title={getTitles('T-10400')}
              fileData={adhaarCopy}
              onDeleteClick={() =>
                dispatch(deleteDocsRequest({ _id: employeeId, aadhaar_photo: true }))
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setAdhaarCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(adhaarCopy, adharRef, preview, defaultValues.appLogicalTexts.adhaar)
              }
              required={true}
              showDelete={false}
            />
            <DragDrop
              inputRef={voterRef}
              title={getTitles('T-10445')}
              fileData={voterIdCopy}
              onDeleteClick={() =>
                employeeData.voter_id_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, voter_id_photo: true }))
                  : setVoterIdCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setVoterIdCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(
                  voterIdCopy,
                  voterRef,
                  preview,
                  defaultValues.appLogicalTexts.voterId
                )
              }
            />
          </Box>

          <Box sx={styles.subCont}>
            <DragDrop
              inputRef={rationRef}
              title={getTitles('T-10446')}
              fileData={rationCopy}
              onDeleteClick={() =>
                employeeData && employeeData.ration_card_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, ration_card_photo: true }))
                  : setRationCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setRationCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(
                  rationCopy,
                  rationRef,
                  preview,
                  defaultValues.appLogicalTexts.ration
                )
              }
            />
            <DragDrop
              inputRef={drivingRef}
              title={getTitles('T-10402')}
              fileData={drivingLiscenceCopy}
              onDeleteClick={() =>
                employeeData && employeeData.driving_license_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, driving_license_photo: true }))
                  : setDrivingLiscenceCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setDrivingLiscenceCopy(file);
                setModalBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(
                  drivingLiscenceCopy,
                  drivingRef,
                  preview,
                  defaultValues.appLogicalTexts.drivingLic
                )
              }
              titleStyle={globalStyles.text}
            />
          </Box>

          <Box sx={styles.subCont}>
            <DragDrop
              inputRef={panRef}
              title={getTitles('T-10401')}
              fileData={pancardCopy}
              onDeleteClick={() =>
                employeeData && employeeData.pan_card_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, pan_card_photo: true }))
                  : setPancardCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setPancardCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(pancardCopy, panRef, preview, defaultValues.appLogicalTexts.pancard)
              }
            />

            <DragDrop
              inputRef={insuranceRef}
              title={getTitles('T-10403')}
              fileData={insuranceCopy}
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setInsuranceCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onDeleteClick={() =>
                employeeData && employeeData.vehicle_insurance_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, vehicle_insurance_photo: true }))
                  : setInsuranceCopy(null)
              }
              onEditClick={(preview) =>
                handleEditClick(
                  insuranceCopy,
                  insuranceRef,
                  preview,
                  defaultValues.appLogicalTexts.insurance
                )
              }
              titleStyle={globalStyles.text}
            />
          </Box>

          <Box sx={styles.subCont}>
            <DragDrop
              inputRef={vehicleRef}
              title={getTitles('T-10662')}
              fileData={vehicleCopy}
              onDeleteClick={() =>
                employeeData && employeeData.vehicle_rc_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, vehicle_rc_photo: true }))
                  : setvehicleCopy(null)
              }
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setvehicleCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onEditClick={(preview) =>
                handleEditClick(
                  vehicleCopy,
                  vehicleRef,
                  preview,
                  defaultValues.appLogicalTexts.vehicle
                )
              }
            />

            <DragDrop
              inputRef={certificateRef}
              title={getTitles('T-10663')}
              fileData={certificateCopy}
              handleChange={(file, preview) => {
                open ? setOpen({ ...open, item: file, preview }) : setCertificateCopy(file);
                setModalBtnDisabled(false);
                setBtnDisabled(false);
              }}
              width={styles.dragNdrop.width}
              onDeleteClick={() =>
                employeeData && employeeData.certification_photo
                  ? dispatch(deleteDocsRequest({ _id: employeeId, certification_photo: true }))
                  : setCertificateCopy(null)
              }
              onEditClick={(preview) =>
                handleEditClick(
                  certificateCopy,
                  certificateRef,
                  preview,
                  defaultValues.appLogicalTexts.certificate
                )
              }
              titleStyle={globalStyles.text}
            />
          </Box>

          <ModalButtons
            onPrimaryBtnClick={handleSubmit}
            primaryBtnTitle={getTitles('T-10339')}
            containerStyle={globalStyles.mt(3)}
            disabled={!idSizePhoto || !adhaarCopy || btnDisabled}
          />
        </Box>
      ) : employeeData ? (
        <ViewEmployeeDocs
          employeeData={employeeData}
          handleView={(val) => handleEditClick(val, null, null, 'view')}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
      <PopupModal showModel={open} onClose={handleModalClose} title={getTitles('T-10393')}>
        <Box sx={styles.modCont}>
          <RenderFile
            filePath={renderFileUrl(open)}
            type={open && open.item && open.item.type && open.item.type}
          />
          {open && open.category !== 'view' && (
            <Box sx={styles.editTextCont}>
              <Text
                style={globalStyles.linkDesc}
                variant="D18"
                onClick={() => open.ref.current.click()}>
                {getTitles('T-10435')}
              </Text>
            </Box>
          )}

          {open && open.category !== 'view' && (
            <ModalButtons
              onPrimaryBtnClick={() => {
                handleModalSave(open.item, open.category);
              }}
              primaryBtnTitle={getTitles('T-10394')}
              containerStyle={globalStyles.mt(3)}
              disabled={modalBtnDisabled}
            />
          )}
        </Box>
      </PopupModal>
    </Box>
  );
};
