import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles } from '../../../../utils';
import {
  DatePickers,
  DragDrop,
  PopupModal,
  RenderFile,
  Text,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLaboratoryRequest,
  setLaboratoryCurrRender,
  updateDetailsPg,
  updateLaboratoryRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { getLaboratoryCurrPage, getScroll } from '../../../../services/redux/selectors';
import dayjs from 'dayjs';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },
  header: {
    width: '100%',
    display: 'flex',
    backgroundColor: color.tableHeading,
    p: 1,
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: `1px solid ${color.secondaryBorder}`,
    gap: '10px'
  },
  docSuperCont: {
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px',
    border: `1px solid ${color.secondaryBorder}`
  },
  docSubCont: {
    width: res ? '100%' : 'calc((100% - 10px)/3)'
  },
  editTextCont: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  modCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};

export const LaboratoryDocumentsForm = ({ laboratoryData, onPrev, laboratoryId }) => {
  const dispatch = useDispatch();
  const nablRef = useRef();
  const gcpRef = useRef();
  const shops_actRef = useRef();
  const clinical_actRef = useRef();
  const bwBodyRef = useRef();
  const sPolBodyRef = useRef();
  const nocRef = useRef();
  const mNocRef = useRef();
  const otherRef = useRef();

  const currRender = useSelector(getLaboratoryCurrPage);
  const scroll = useSelector(getScroll);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [open, setOpen] = useState(null);
  const [modalBtnDisabled, setModalBtnDisabled] = useState(false);

  const { add, edit, view } = defaultValues.actionType;
  const { nabl, gcp, shops_act, clinical_act, bwBody, sPolBody, noc, mNoc, other } =
    defaultValues.appLogicalTexts;

  const { values, errors, touched, setValues, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      nabl: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      gcp: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      shops_act: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      clinical_act: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      biomedical_waste_disposal_body: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      state_pollution_board: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      noc: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      municipality_noc: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      },
      other: {
        validity_start_date: null,
        validity_end_date: null,
        remarks: null,
        doc_url: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const extractDetails = (url) => {
    const arr = url.split('.');
    const title = arr[arr.length - 2].split('/');
    return {
      item: url,
      name: title[title.length - 1],
      type: arr[arr.length - 1],
      elemType: true
    };
  };

  const incomingValues = (laboratoryData, newValue, docName) => {
    let newValues = {
      ...newValue,
      [docName]: {
        ...laboratoryData[docName],
        validity_start_date:
          laboratoryData[docName].validity_start_date &&
          dayjs(laboratoryData[docName].validity_start_date),
        validity_end_date:
          laboratoryData[docName].validity_end_date &&
          dayjs(laboratoryData[docName].validity_end_date),
        doc_url: laboratoryData[docName].doc_url && extractDetails(laboratoryData[docName].doc_url)
      }
    };
    return newValues;
  };

  useEffect(() => {
    if (laboratoryData) {
      let newValues = { ...laboratoryData };
      if (laboratoryData.nabl) {
        newValues = { ...incomingValues(laboratoryData, newValues, 'nabl') };
      }

      if (laboratoryData.gcp) {
        newValues = { ...incomingValues(laboratoryData, newValues, 'gcp') };
      }

      if (laboratoryData.shops_act) {
        newValues = { ...incomingValues(laboratoryData, newValues, 'shops_act') };
      }

      if (laboratoryData.clinical_act) {
        newValues = { ...incomingValues(laboratoryData, newValues, 'clinical_act') };
      }
      if (laboratoryData.biomedical_waste_disposal_body) {
        newValues = {
          ...incomingValues(laboratoryData, newValues, 'biomedical_waste_disposal_body')
        };
      }

      if (laboratoryData.state_pollution_board) {
        newValues = {
          ...incomingValues(laboratoryData, newValues, 'state_pollution_board')
        };
      }

      if (laboratoryData.noc) {
        newValues = {
          ...incomingValues(laboratoryData, newValues, 'noc')
        };
      }

      if (laboratoryData.municipality_noc) {
        newValues = {
          ...incomingValues(laboratoryData, newValues, 'municipality_noc')
        };
      }
      if (laboratoryData.other) {
        newValues = {
          ...incomingValues(laboratoryData, newValues, 'other')
        };
      }

      setValues(newValues);
    }
  }, [laboratoryData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      registration_step: defaultValues.labRegisterSteps.documentDetails,
      _id: laboratoryId,
      documents: {
        ...values
      }
    };
    if (currRender === add) dispatch(addLaboratoryRequest(payload));
    if (currRender === edit) dispatch(updateLaboratoryRequest(payload));

    dispatch(setLaboratoryCurrRender(1));
    dispatch(updateDetailsPg(false));
    dispatch(updateScrollToTop(!scroll));
  };

  const handleEditClick = (item, ref, preview, category) => {
    setOpen({ item, ref, preview, category });
    setModalBtnDisabled(true);
  };

  const handleModalClose = () => {
    setOpen(null);
  };

  const renderFileUrl = (objFile) => {
    if (objFile && objFile.item && typeof objFile.item === 'string') {
      return objFile.item;
    } else if (
      objFile &&
      objFile.item &&
      objFile.item.item &&
      typeof objFile.item.item === 'string'
    ) {
      return objFile.item.item;
    } else {
      return objFile && objFile.preview && objFile.preview[0];
    }
  };

  const handleModalSave = (file, type) => {
    if (type === nabl) {
      setFieldValue('nabl.doc_url', file);
    }
    if (type === gcp) {
      setFieldValue('gcp.doc_url', file);
    }
    if (type === shops_act) {
      setFieldValue('shops_act.doc_url', file);
    }
    if (type === clinical_act) {
      setFieldValue('clinical_act.doc_url', file);
    }
    if (type === bwBody) {
      setFieldValue('biomedical_waste_disposal_body.doc_url', file);
    }
    if (type === sPolBody) {
      setFieldValue('state_pollution_board.doc_url', file);
    }
    if (type === noc) {
      setFieldValue('noc.doc_url', file);
    }
    if (type === mNoc) {
      setFieldValue('municipality_noc.doc_url', file);
    }
    if (type === other) {
      setFieldValue('other.doc_url', file);
    }

    setOpen(null);
  };

  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleDelete = (doc) => {
    setFieldValue(doc, '');
  };

  const header = [getTitles('T-11089'), getTitles('T-11090'), getTitles('T-10660')];

  return (
    <Box sx={styles.container}>
      {res && (
        <Text variant="BoldH22Sec" style={{ mb: 1 }}>
          {getTitles('T-11072')}
        </Text>
      )}
      {!res && (
        <Box sx={styles.header}>
          {header.map((item, idx) => (
            <Text variant="BoldH22Sec" key={idx} style={{ flex: 2 }}>
              {item}
            </Text>
          ))}
        </Box>
      )}

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11091')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.nabl && values.nabl.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'nabl.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
            maxDate={values.nabl && values.nabl.validity_end_date}
          />
          <DatePickers
            dateValue={values.nabl && values.nabl.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'nabl.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
            minDate={values.nabl && values.nabl.validity_start_date}
          />
          <DragDrop
            inputRef={nablRef}
            custom={true}
            disabled={currRender === view}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('nabl.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) => handleEditClick(values.nabl.doc_url, nablRef, preview, nabl)}
            onDeleteClick={() => handleDelete('nabl.doc_url')}
            fileData={values.nabl && values.nabl.doc_url}
            width={'calc(100% + 4px)'}
            showDelete={currRender !== view}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="nabl.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.nabl && values.nabl.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11092')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.gcp && values.gcp.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'gcp.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
            maxDate={values.gcp && values.gcp.validity_end_date}
          />
          <DatePickers
            dateValue={values.gcp && values.gcp.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'gcp.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
            minDate={values.gcp && values.gcp.validity_start_date}
          />
          <DragDrop
            inputRef={gcpRef}
            custom={true}
            disabled={currRender === view}
            handleChange={(file, preview) => {
              open ? setOpen({ ...open, item: file, preview }) : setFieldValue('gcp.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) => handleEditClick(values.gcp.doc_url, gcpRef, preview, gcp)}
            fileData={values.gcp && values.gcp.doc_url}
            onDeleteClick={() => handleDelete('gcp.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="gcp.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.gcp && values.gcp.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11093')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.shops_act && values.shops_act.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'shops_act.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
            maxDate={values.shops_act && values.shops_act.validity_end_date}
          />
          <DatePickers
            dateValue={values.shops_act && values.shops_act.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'shops_act.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
            minDate={values.shops_act && values.shops_act.validity_start_date}
          />
          <DragDrop
            inputRef={shops_actRef}
            custom={true}
            disabled={currRender === view}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('shops_act.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(values.shops_act.doc_url, shops_actRef, preview, shops_act)
            }
            fileData={values.shops_act && values.shops_act.doc_url}
            onDeleteClick={() => handleDelete('shops_act.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="shops_act.remarks"
            placeHolder={getTitles('T-10096')}
            error={touched.laboratory_name && errors.laboratory_name}
            value={values.shops_act && values.shops_act.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11094')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.clinical_act && values.clinical_act.validity_start_date}
            maxDate={values.clinical_act && values.clinical_act.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'clinical_act.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={values.clinical_act && values.clinical_act.validity_end_date}
            minDate={values.clinical_act && values.clinical_act.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'clinical_act.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            inputRef={clinical_actRef}
            custom={true}
            disabled={currRender === view}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('clinical_act.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(values.clinical_act.doc_url, clinical_actRef, preview, clinical_act)
            }
            fileData={values.clinical_act && values.clinical_act.doc_url}
            onDeleteClick={() => handleDelete('clinical_act.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="clinical_act.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.clinical_act && values.clinical_act.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11095')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={
              values.biomedical_waste_disposal_body &&
              values.biomedical_waste_disposal_body.validity_start_date
            }
            maxDate={
              values.biomedical_waste_disposal_body &&
              values.biomedical_waste_disposal_body.validity_end_date
            }
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'biomedical_waste_disposal_body.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={
              values.biomedical_waste_disposal_body &&
              values.biomedical_waste_disposal_body.validity_end_date
            }
            minDate={
              values.biomedical_waste_disposal_body &&
              values.biomedical_waste_disposal_body.validity_start_date
            }
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'biomedical_waste_disposal_body.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            custom={true}
            disabled={currRender === view}
            inputRef={bwBodyRef}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('biomedical_waste_disposal_body.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(
                values.biomedical_waste_disposal_body.doc_url,
                bwBodyRef,
                preview,
                bwBody
              )
            }
            fileData={
              values.biomedical_waste_disposal_body && values.biomedical_waste_disposal_body.doc_url
            }
            onDeleteClick={() => handleDelete('biomedical_waste_disposal_body.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="biomedical_waste_disposal_body.remarks"
            placeHolder={getTitles('T-10096')}
            value={
              values.biomedical_waste_disposal_body && values.biomedical_waste_disposal_body.remarks
            }
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11096')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={
              values.state_pollution_board && values.state_pollution_board.validity_start_date
            }
            maxDate={values.state_pollution_board && values.state_pollution_board.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'state_pollution_board.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={
              values.state_pollution_board && values.state_pollution_board.validity_end_date
            }
            minDate={
              values.state_pollution_board && values.state_pollution_board.validity_start_date
            }
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'state_pollution_board.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            custom={true}
            disabled={currRender === view}
            inputRef={sPolBodyRef}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('state_pollution_board.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(values.state_pollution_board.doc_url, sPolBodyRef, preview, sPolBody)
            }
            fileData={values.state_pollution_board && values.state_pollution_board.doc_url}
            onDeleteClick={() => handleDelete('state_pollution_board.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="state_pollution_board.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.state_pollution_board.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11097')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.noc && values.noc.validity_start_date}
            maxDate={values.noc && values.noc.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'noc.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={values.noc && values.noc.validity_end_date}
            minDate={values.noc && values.noc.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'noc.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            custom={true}
            disabled={currRender === view}
            inputRef={nocRef}
            handleChange={(file, preview) => {
              open ? setOpen({ ...open, item: file, preview }) : setFieldValue('noc.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) => handleEditClick(values.noc.doc_url, nocRef, preview, noc)}
            fileData={values.noc && values.noc.doc_url}
            width={'calc(100% + 4px)'}
            onDeleteClick={() => handleDelete('noc.doc_url')}
            showDelete={currRender !== view}
          />
        </Box>

        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="noc.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.noc && values.noc.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-11098')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.municipality_noc && values.municipality_noc.validity_start_date}
            maxDate={values.municipality_noc && values.municipality_noc.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'municipality_noc.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={values.municipality_noc && values.municipality_noc.validity_end_date}
            minDate={values.municipality_noc && values.municipality_noc.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'municipality_noc.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            custom={true}
            disabled={currRender === view}
            inputRef={mNocRef}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('municipality_noc.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(values.municipality_noc.doc_url, mNocRef, preview, mNoc)
            }
            fileData={values.municipality_noc && values.municipality_noc.doc_url}
            width={'calc(100% + 4px)'}
            onDeleteClick={() => handleDelete('municipality_noc.doc_url')}
            showDelete={currRender !== view}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="municipality_noc.remarks"
            placeHolder={getTitles('T-10096')}
            error={touched.municipality_noc && errors.municipality_noc}
            value={values.municipality_noc && values.municipality_noc.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <Box sx={styles.docSuperCont}>
        <Box sx={styles.docSubCont}>
          <Text variant="D20">{getTitles('T-10347')}</Text>
        </Box>
        <Box sx={styles.docSubCont}>
          <DatePickers
            dateValue={values.other && values.other.validity_start_date}
            maxDate={values.other && values.other.validity_end_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'other.validity_start_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11110')}
            disabled={currRender === view}
          />
          <DatePickers
            dateValue={values.other && values.other.validity_end_date}
            minDate={values.other && values.other.validity_start_date}
            onDateChange={(val) =>
              handleChange({
                target: { value: val, name: 'other.validity_end_date' }
              })
            }
            containerStyles={{ mt: 0 }}
            label={getTitles('T-11113')}
            disabled={currRender === view}
          />
          <DragDrop
            custom={true}
            disabled={currRender === view}
            inputRef={otherRef}
            handleChange={(file, preview) => {
              open
                ? setOpen({ ...open, item: file, preview })
                : setFieldValue('other.doc_url', file);
              setModalBtnDisabled(false);
              setBtndisabled(false);
            }}
            onEditClick={(preview) =>
              handleEditClick(values.other.doc_url, otherRef, preview, other)
            }
            fileData={values.other && values.other.doc_url}
            onDeleteClick={() => handleDelete('other.doc_url')}
            showDelete={currRender !== view}
            width={'calc(100% + 4px)'}
          />
        </Box>
        <Box sx={styles.docSubCont}>
          <UITextInputWithTitle
            name="other.remarks"
            placeHolder={getTitles('T-10096')}
            value={values.other && values.other.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            containerStyles={{ mt: 0.5 }}
            rows={res ? 3 : 6}
            disabled={currRender === view}
          />
        </Box>
      </Box>

      <PopupModal showModel={open} onClose={handleModalClose} title={getTitles('T-10393')}>
        <Box sx={styles.modCont}>
          <RenderFile
            filePath={renderFileUrl(open)}
            type={open && open.item && open.item.type && open.item.type}
          />
          {open && open.category !== 'view' && currRender !== view && (
            <Box sx={styles.editTextCont}>
              <Text
                style={globalStyles.linkDesc}
                variant="D18"
                onClick={() => open.ref.current.click()}>
                {getTitles('T-10435')}
              </Text>
            </Box>
          )}

          {open && open.category !== 'view' && (
            <ModalButtons
              onPrimaryBtnClick={() => {
                handleModalSave(open.item, open.category);
              }}
              primaryBtnTitle={getTitles('T-10394')}
              containerStyle={globalStyles.mt(3)}
              disabled={modalBtnDisabled}
            />
          )}
        </Box>
      </PopupModal>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
