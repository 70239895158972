import { Text } from '../../ui-kits';
import { Screen } from '../../ui-kits/screen';
import { getTitles } from '../../utils';

export const Dashboard = () => {
  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10037')}>
      <Text>Dashboard</Text>
    </Screen>
  );
};
