import { Avatar, Box } from '@mui/material';
import {
  Text,
  UIButton,
  UIPhoneInputTitle,
  UIRadioButton,
  UITextInputWithTitle
} from '../../../ui-kits';
import { AccountCircle, Badge, PhoneIphone } from '@mui/icons-material';
import { CallIcon, ConditionsIcon, LocationIcon } from '../../../assets/svg-components';
import { styles } from './styles';

import farmerIcon from '../../../assets/icons/farmerIcon.png';
import { getTitles } from '../../../utils';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { useState } from 'react';

export const CallerInfoCard = ({ caller }) => {
  const { member, nonMember, employee } = defaultValues.callCenterUserTypes;

  const [, setBtndisabled] = useState(false);

  const { values, handleChange, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      full_name: '',
      role: '',
      primary_mobile_number: '',
      secondary_mobile_number: '',
      location: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  return (
    <Box sx={styles.container}>
      {caller ? (
        <>
          <Avatar alt="caller_icon" src={farmerIcon} sx={styles.callerIcon} />
          <Box sx={styles.content}>
            <Box sx={{ ...styles.row, gap: '30px' }}>
              {[member, nonMember, undefined, null].includes(caller.user_type?.id) && (
                <UIRadioButton
                  title={getTitles('T-10715')}
                  checked={caller.user_type?.id === member}
                />
              )}
              {[nonMember, undefined, null].includes(caller.user_type?.id) && (
                <UIRadioButton
                  title={getTitles('T-10716')}
                  checked={caller.user_type?.id === nonMember}
                />
              )}
              {[employee, nonMember, undefined, null].includes(caller.user_type?.id) && (
                <UIRadioButton
                  title={getTitles('T-10715')}
                  checked={caller.user_type?.id === employee}
                />
              )}
            </Box>
            {[employee, member].includes(caller.user_type?.id) ? (
              <>
                <Box sx={styles.row}>
                  <AccountCircle sx={styles.icon} />
                  <Text variant="D20" sx={styles.text}>
                    {caller.name}
                  </Text>
                  <PhoneIphone sx={styles.icon} />
                  <Text variant="D20" sx={styles.text}>
                    {caller.phone_number}
                  </Text>
                  <CallIcon />
                </Box>
                <Box sx={styles.row} mt={1}>
                  <Badge sx={styles.icon} />
                  <Text variant="D20" sx={styles.text}>
                    {caller.role}
                  </Text>
                  <LocationIcon sx={{ color: color.errorText }} />
                  <Text variant="D20" sx={styles.text}>
                    {caller.location}
                  </Text>
                </Box>
              </>
            ) : (
              <Box>
                <UITextInputWithTitle
                  name="full_name"
                  label={getTitles('T-10439')}
                  placeHolder={getTitles('T-10384')}
                  value={values.full_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  containerStyles={styles.phone}
                />

                <UITextInputWithTitle
                  name="role"
                  label={getTitles('T-10455')}
                  placeHolder={getTitles('T-11074')}
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  containerStyles={styles.phone}
                />

                <Box sx={styles.phoneCont}>
                  <UIPhoneInputTitle
                    title={getTitles('T-10486')}
                    phoneValue={values.primary_mobile_number}
                    onChange={(val) => {
                      setFieldValue('primary_mobile_number', val);
                      setBtndisabled(val ? false : true);
                    }}
                    handleError={(isError) => setBtndisabled(isError)}
                    isLabel={true}
                  />
                  <UIPhoneInputTitle
                    title={getTitles('T-10487')}
                    phoneValue={values.secondary_mobile_number}
                    onChange={(val) => setFieldValue('secondary_mobile_number', val)}
                    handleError={(isError) => setBtndisabled(isError)}
                    isLabel={true}
                  />
                </Box>

                <UITextInputWithTitle
                  name="location"
                  label={getTitles('T-10734')}
                  placeHolder={getTitles('T-10408')}
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  containerStyles={styles.phone}
                />
              </Box>
            )}
            <UIButton title={getTitles('T-11365')} style={styles.btn} />
          </Box>
        </>
      ) : (
        <Box sx={styles.container} alignContent="center">
          <ConditionsIcon />
          <Text variant="LightH32">{getTitles('T-11246')}</Text>
        </Box>
      )}
    </Box>
  );
};
