import { defaultValues } from '../../constants';
import { color } from '../../theme';

const lg = defaultValues.isLarge;

export const Styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: color.secondary
  },

  logoContainer: (isCollapse) => ({
    py: 2,
    pl: isCollapse ? 1.3 : 4,
    backgroundColor: color.secondary
  }),

  logoStyle: { width: '80px', height: '80px', mb: 1 },
  menuTab: (isActive) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    boxSizing: 'border-box',
    backgroundColor: isActive ? color.palette.paleIvory : color.secondary,
    borderLeft: isActive ? `5px solid ${color.primaryText}` : `5px solid ${color.secondary}`
  }),
  iconStyle: (collapse) => ({
    marginRight: collapse ? 0 : 10,
    marginLeft: collapse ? 18 : 10,
    width: lg ? '24px' : '30px',
    height: lg ? '24px' : '30px',
    color: color.primaryText,
    cursor: 'pointer'
  }),
  logout: (collapse) => ({
    marginRight: 10,
    marginLeft: collapse ? 20 : 10,
    width: lg ? '20px' : '22px',
    height: lg ? '20px' : '22px',
    color: color.primaryText,
    cursor: 'pointer'
  }),
  menuTitle: {
    color: color.primaryText,
    textAlign: 'left',
    textTransform: 'none',
    cursor: 'pointer'
  },
  menuContainer: (isCall) => ({
    paddingTop: isCall ? '0px' : '0px',
    height: isCall ? 'calc(100% - 180px)' : 'calc(100% - 210px)',
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: color.secondary
  })
};
