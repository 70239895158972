import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { CheckBox, CheckCircle, Circle } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { defaultValues } from '../../constants';
import { Text } from '../text';
import { globalStyles } from '../../theme';

export const UICheckBox = ({
  onChange,
  checked,
  sx,
  isSquare = true,
  iconStyle,
  checkedIconStyle,
  variant = 'D20',
  titleStyle,
  title,
  containerStyle,
  ...rest
}) => {
  const styles = {
    checkbox: (style) => ({
      width: title ? '100%' : 'fit-content',
      display: 'flex',
      alignItems: 'center',
      ...style
    }),
    icon: {
      color: color.primaryBorder,
      fontSize: defaultValues.isResponsive ? '25px' : '35px',
      ...iconStyle
    },
    checkedIcon: {
      color: color.primary,
      fontSize: defaultValues.isResponsive ? '25px' : '35px',
      ...checkedIconStyle
    },
    title: (style) => ({
      ...globalStyles.text,
      ...style
    })
  };

  return (
    <Box sx={styles.checkbox(containerStyle)}>
      <Checkbox
        checked={checked}
        sx={{
          marginRight: defaultValues.isResponsive ? '4px' : '8px',
          padding: defaultValues.isResponsive ? '4px 4px 4px 0px' : '9px 9px 9px 0px',
          ...sx
        }}
        onChange={onChange}
        icon={
          isSquare ? (
            <CheckBoxOutlineBlankIcon style={styles.icon} />
          ) : (
            <Circle style={styles.icon} />
          )
        }
        checkedIcon={
          isSquare ? (
            <CheckBox style={styles.checkedIcon} />
          ) : (
            <CheckCircle style={styles.checkedIcon} />
          )
        }
        {...rest}
      />
      {title && (
        <Text variant={variant} style={styles.title(titleStyle)}>
          {title}
        </Text>
      )}
    </Box>
  );
};
