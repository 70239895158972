import { defaultValues } from '../../constants';
import { color } from '../../theme';

const isRes = defaultValues.isResponsive;

export const styles = {
  container: (style) => ({
    position: 'absolute',
    top: '50%',
    left: isRes ? '50%' : '70%',
    transform: isRes ? 'translate(-50%, -50%)' : 'translate(-70%, -50%)',
    minWidth: isRes ? '80%' : '50%',
    bgcolor: color.primaryBackground,
    borderRadius: '5px',
    boxShadow: 24,
    maxWidth: isRes ? '90%' : '60%',
    width: 'fit-content',
    border: 'none',
    padding: isRes ? 1 : 2,
    boxSizing: 'border-box',
    ...style
  }),
  titleContainer: {
    display: 'flex',
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    alignItems: 'center'
  },
  titleStyle: (position, style) => ({
    height: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: position,
    ...style
  }),
  closeBtnStyle: { boxSizing: 'border-box', cursor: 'pointer' },
  childContainer: (style, style2) => ({
    width: '100%',
    maxHeight: '60vh',
    overflow: 'auto',
    px: isRes ? 1 : 10,
    boxSizing: 'border-box',
    my: 2,
    ...style,
    ...style2
  })
};
