import { defaultValues, getErrorMessage } from '../../../constants';
import { getTitles } from '../../../utils/commonFunction';
import { Text, UILayout } from '../../../ui-kits';
import callIcon from '../../../assets/icons/call.png';
import { BasicProfile } from '../../common/utility-components';
import { Box } from '@mui/material';
import { color, globalStyles } from '../../../theme';
import { styles } from './styles';

export const CallHistory = ({ currTab }) => {
  const { allCallHis, callerCallHis } = defaultValues.callCenterCurrTabs;

  const allCallHistory = [{ id: 1 }, { id: 2 }];
  const callerCallHistory = [{ id: 1 }, { id: 2 }];

  const handleSearch = (data) => {
    data;
  };

  const allCallsListData = allCallHistory.map(() => [
    {
      title: getTitles('T-11239'),
      columnWidth: '7%',
      values: 'fgd'
    },
    {
      title: getTitles('T-11240'),
      values: 'sdffd'
    },
    {
      title: getTitles('T-11241'),
      values: 'sfdfd'
    },
    {
      title: getTitles('T-10440'),
      columnWidth: '16%',
      values: <BasicProfile title={'+919608968887'} url={callIcon} iconPositon="right" />
    },
    {
      title: getTitles('T-10734'),
      values: (
        <BasicProfile
          title={'thanbanur'}
          subTitle={'alapuzha'}
          subTitleVariant="D20"
          isIcon={false}
          subTitleStyle={{ color: color.primaryText }}
        />
      )
    },
    {
      title: getTitles('T-11242'),
      values: null
    },
    {
      title: getTitles('T-11243'),
      values: null
    },
    {
      title: getTitles('T-11244'),
      values: (
        <Text variant="D20" style={globalStyles.link}>
          {getTitles('T-10659')}
        </Text>
      )
    }
  ]);

  const callerCallsListData = callerCallHistory.map(() => [
    {
      title: getTitles('T-11239'),
      values: 'fgd'
    },
    {
      title: getTitles('T-11240'),
      values: 'sdffd'
    },
    {
      title: getTitles('T-10440'),
      values: <BasicProfile title={'+919608968887'} url={callIcon} iconPositon="right" />
    },
    {
      title: getTitles('T-11242'),
      values: '22-5-2024'
    },
    {
      title: getTitles('T-11243'),
      values: '2 hrs'
    },
    {
      title: getTitles('T-11244'),
      values: (
        <Text variant="D20" style={globalStyles.link}>
          {getTitles('T-10659')}
        </Text>
      )
    }
  ]);

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}

      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10145')}
        tableItems={
          currTab === allCallHis
            ? allCallsListData
            : currTab === callerCallHis
              ? callerCallsListData
              : []
        }
        showNoData={!allCallHistory.length > 0}
        message={getErrorMessage('E-10031')}
        handleSort={() => {}}
        handleFilter={() => {}}
        handleRefresh={() => {}}
      />
    </Box>
  );
};
