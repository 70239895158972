import { Box } from '@mui/material';
import { UIButton } from '../../../ui-kits';
import { defaultValues } from '../../../constants';
import { getTitles } from '../../../utils';

export const ModalButtons = ({
  onPrimaryBtnClick,
  disabled = false,
  secondaryBtnTitle = getTitles('T-10045'),
  primaryBtnTitle = getTitles('T-10063'),
  containerStyle,
  onSecondaryBtnClick,
  btnPositions = 'center',
  secondaryVariant = 'secondary'
}) => {
  const isRes = defaultValues.isResponsive;
  const styles = {
    container: (style) => ({
      display: 'flex',
      justifyContent: btnPositions,
      width: '100%',
      gap: '10px',
      marginTop: '40px',
      marginBottom: '10px',
      ...style
    }),
    btnStyle: {
      width: isRes ? '140px' : '275px',
      height: isRes ? '40px' : '50px',
      fontSize: isRes ? '0.825rem' : '1.375rem'
    }
  };

  return (
    <Box sx={styles.container(containerStyle)}>
      {onSecondaryBtnClick && (
        <UIButton
          title={secondaryBtnTitle}
          variant={secondaryVariant}
          style={styles.btnStyle}
          onClick={onSecondaryBtnClick}
        />
      )}
      {onPrimaryBtnClick && (
        <UIButton
          title={primaryBtnTitle}
          style={styles.btnStyle}
          onClick={onPrimaryBtnClick}
          disabled={disabled}
        />
      )}
    </Box>
  );
};
