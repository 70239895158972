import React, { useEffect, useRef, useState } from 'react';

import { defaultValues } from '../../constants';
import { style } from './styles';
import { Header } from '../../components';
import { Sidebar } from '../side-bar';
import { LoadingScreen } from '../loading-screen';
import { useSelector } from 'react-redux';
import { getMainMenu, getScroll } from '../../services/redux/selectors';

function UserScreenWithHeader(props) {
  return (
    <div style={style.container}>
      <LoadingScreen isLoading={props.isLoading} />

      <Header {...props} />
      {props.children}
    </div>
  );
}

function ScreenWithSidebar(props) {
  const scroll = useSelector(getScroll);
  const scrollableDivRef = useRef(null);
  const mainMenu = useSelector(getMainMenu);

  const [isCallCenter, setIsCallCenter] = useState(false);

  useEffect(() => {
    setIsCallCenter(mainMenu === 14);
  }, [mainMenu]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scroll]);

  return (
    <div style={style.sidebarContainer}>
      <LoadingScreen isLoading={props.isLoading} />
      {!defaultValues.isResponsive && (
        <div style={style.leftside(isCallCenter)}>
          <Sidebar />
        </div>
      )}
      <div style={style.subContainer(isCallCenter)}>
        <Header {...props} />
        <div style={style.childrenContainer} ref={scrollableDivRef}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

function UserScreenWithOutHeader(props) {
  return (
    <div style={style.container}>
      <LoadingScreen isLoading={props.isLoading} />
      {props.children}
    </div>
  );
}

export const Screen = (props) => {
  if (props.showHeader) {
    return <UserScreenWithHeader {...props} />;
  } else if (props.showSideBar) {
    return <ScreenWithSidebar {...props} />;
  } else {
    return <UserScreenWithOutHeader {...props} />;
  }
};
