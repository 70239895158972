import { Box } from '@mui/material';
import { PopupModal, Text, UIButton, UIChipButton, UIEditIcon, UISelect } from '../../../ui-kits';
import {
  getNameWithIdForEnum,
  getTitleWithId,
  getTitles,
  processRule,
  profileConditionSchema
} from '../../../utils';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { TaskDetails } from '../task-details';
import { useCallback, useEffect, useState } from 'react';
import { Conditions } from '../conditions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMasterList,
  getProfilingActionType,
  getProfilingData
} from '../../../services/redux/selectors/manageProccess';
import {
  addProfilingRequest,
  getMasterListRequest,
  setProfileActionType,
  updateProfileDetailsRequest
} from '../../../services/redux/slices';
import { UIDeleteIcon } from '../../../ui-kits/delete-icon-button';
import { ModalButtons } from '../../common/modal-buttons';
import dayjs from 'dayjs';
import { Styles } from './style';

export const CreateTask = () => {
  const { view, edit, add } = defaultValues.actionType;

  const { values, errors, touched, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      trigger_rule: '',
      rule_type: '',
      rules: [],
      tasks: []
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: profileConditionSchema
  });

  const dispatch = useDispatch();
  const profileData = useSelector(getProfilingData);
  const taskTypeList = useSelector(getMasterList);
  const actionType = useSelector(getProfilingActionType);
  const [isTrigered, setTrigered] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCondition, setSelectCondition] = useState(false);
  const [taskType, setTaskType] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    dispatch(getMasterListRequest({ master_id: 42 }));
  }, []);

  useEffect(() => {
    if (taskTypeList) setTaskType(taskTypeList);
  }, [taskTypeList]);

  useEffect(() => {
    if (profileData?.rules_details?.trigger_rule?.id) {
      setFieldValue('trigger_rule', profileData.rules_details.trigger_rule.id);
      setTrigered(true);
    } else {
      dispatch(setProfileActionType(add));
    }
    if (profileData?.rules_details?.rule_type) {
      setSelectCondition(true);
      const filteredRules = profileData.rules_details.rules.map(processRule);
      setFieldValue('rules', filteredRules);
    }
    if (profileData?.rules_details?.rule_type?.id) {
      setFieldValue('rule_type', profileData.rules_details.rule_type.id);
    }
    if (profileData?.rules_details?.tasks) {
      const transformedTasks = profileData.rules_details.tasks.map((task) => ({
        ...task,
        assign_role: task.assign_role.id,
        call_center_status: task?.call_center_status?.id,
        operate: task?.operate?.id,
        priority: task?.priority?.id,
        repeat: task?.repeat,
        task_status: task?.task_status?.id,
        task_type: task?.task_type?._id,
        trigger_at: task?.trigger_at?.id,
        notify_role: task?.notify_role.map((role) => ({
          id: role.id,
          name: getNameWithIdForEnum(defaultValues.roleOptions, role.id)
        })),
        remind_role: task?.remind_role.map((role) => ({
          id: role.id,
          name: getNameWithIdForEnum(defaultValues.roleOptions, role.id)
        })),
        remind_on: task?.remind_on.map((remind) => ({
          ...remind,
          remind_at: remind.remind_at?.id,
          timing: dayjs(remind.timing)
        }))
      }));
      setFieldValue('tasks', transformedTasks);
    }
  }, [profileData, setFieldValue, setTrigered, setSelectCondition]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtnDisabled(false);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setSelectedTask(null);
  };

  const handleConditions = useCallback(
    (data) => {
      setFieldValue('rule_type', data?.ruleType);
      setFieldValue('rules', data?.rules);
      setSelectCondition(true);
      if (actionType !== add) {
        handleSubmit(data?.rules, undefined, data?.ruleType);
      }
    },
    [setFieldValue, actionType, values]
  );

  const handleDeleteTask = (index) => {
    const updatedTasks = values.tasks.filter((_, taskIndex) => taskIndex !== index);
    setFieldValue('tasks', updatedTasks);
    if (actionType !== add) {
      setTimeout(() => handleSubmit(undefined, updatedTasks), 0);
    }
  };

  const handleViewTask = (task, index) => {
    setIsOpen(true);
    setSelectedTask(task);
    if (actionType !== add) {
      dispatch(setProfileActionType(view));
    } else {
      setSelectedTask(task);
      setSelectedTaskIndex(index);
    }
  };

  const handleEditTask = (task, index) => {
    setIsOpen(true);
    setSelectedTask(task);
    setSelectedTaskIndex(index);
    if (actionType !== add) {
      dispatch(setProfileActionType(edit));
    }
  };

  const handleSubmit = (
    updatedRules = values.rules,
    updatedTasks = values.tasks,
    ruleType = values.rule_type
  ) => {
    const payload = {
      _id: profileData._id,
      step: 2,
      rules_details: {
        ...values,
        rule_type: ruleType,
        rules: updatedRules,
        tasks: updatedTasks.map((item) => ({
          ...item,
          notify_role: item.notify_role.map((role) => role.id),
          remind_role: item.remind_role.map((role) => role.id)
        }))
      }
    };

    if (actionType === add) {
      dispatch(addProfilingRequest(payload));
    } else if (actionType === edit || actionType === view) {
      delete payload.step;
      dispatch(updateProfileDetailsRequest(payload));
      setBtnDisabled(true);
    }
  };

  const handleTaskData = useCallback(
    (data) => {
      let updatedTasks;
      if (selectedTaskIndex !== null) {
        updatedTasks = values.tasks.map((task, index) =>
          index === selectedTaskIndex ? data : task
        );
      } else {
        updatedTasks = [...values.tasks, data];
      }

      setFieldValue('tasks', updatedTasks);
      handleModalClose();
      setSelectedTaskIndex(null);
      if (actionType !== add) {
        setTimeout(() => handleSubmit(undefined, updatedTasks), 0);
      }
    },
    [selectedTaskIndex, values.tasks, actionType, setFieldValue, handleModalClose, handleSubmit]
  );

  return (
    <Box>
      {/* First Section */}
      <Box sx={Styles.container}>
        <Box sx={Styles.subContainer}>
          <Box sx={Styles.circleShape}>
            <Box component="span" sx={Styles.textColor}>
              {getTitles('T-11165')}
            </Box>
          </Box>
          <Box component="span" sx={Styles.hLineToType} />
          <Box component="span" sx={Styles.hArrow} />
        </Box>
        <Box sx={Styles.fieldContainer}>
          <Box sx={Styles.fieldWraper}>
            <Box sx={Styles.width}>
              {isTrigered && !isEdit ? (
                <>
                  <Text variant="D20">{getTitles('T-11164')}</Text>
                  <UIChipButton
                    title={getTitleWithId(defaultValues.ruleOptions, values.trigger_rule)}
                    showDelete={false}
                  />
                </>
              ) : (
                <>
                  <UISelect
                    name="trigger_rule"
                    title={getTitles('T-11164')}
                    options={defaultValues.ruleOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.trigger_rule && errors.trigger_rule}
                    value={values.trigger_rule}
                    placeholder={getTitles('T-10350')}
                  />
                  <UIButton
                    title={getTitles(actionType !== add ? 'T-10046' : 'T-10804')}
                    style={Styles.btn}
                    disabled={!values.trigger_rule || btnDisabled}
                    onClick={() => {
                      setIsEdit(false);
                      setTrigered(true);
                      if (actionType === edit || actionType === view) {
                        handleSubmit();
                      }
                    }}
                  />
                </>
              )}
            </Box>
            {isTrigered && !isEdit && <UIEditIcon onClick={() => setIsEdit(true)} />}
          </Box>
        </Box>
        {/* Connecting Line */}
        {isTrigered && (
          <>
            <Box component="span" sx={Styles.lineToFirstToSec} />
            <Box component="span" sx={Styles.vArrow} />
          </>
        )}
      </Box>

      {/* Second Section */}
      <Box sx={Styles.container}>
        {isTrigered && (
          <Box sx={Styles.secSectionContainer}>
            <Box sx={Styles.hLineSecondSection}>
              <Box component="span" sx={Styles.textInDiamondShape}>
                {getTitles('T-11174')}
              </Box>
            </Box>
            <Box component="span" sx={Styles.secondSecShape} />
            <Box component="span" sx={Styles.secSectionVarrow} />
          </Box>
        )}
        {isTrigered && <Conditions onData={handleConditions} />}
        {/* Connecting Line */}
        {selectedCondition && (
          <>
            <Box component="span" sx={Styles.lineToSecondToThird} />
            <Box component="span" sx={Styles.connectingArrow} />
          </>
        )}
      </Box>

      {/* third section */}
      {selectedCondition && (
        <Box sx={Styles.container}>
          <Box sx={Styles.subContainer}>
            <Box sx={Styles.taskCirle}>
              <Box component="span" sx={{ color: color.tertiaryText }}>
                {getTitles('T-11289')}
              </Box>
            </Box>
            <Box component="span" sx={Styles.taskConnectingLine} />
            <Box component="span" sx={Styles.taskConnectinArrow} />
          </Box>
          <Box sx={Styles.taskFieldContainer}>
            <Box sx={Styles.taskFieldWraper}>
              <Box sx={Styles.taskList}>
                <Text variant="BoldH24">{getTitles('T-11280')}</Text>

                <UIButton
                  title={getTitles(defaultValues.isResponsive ? 'T-10078' : 'T-11281')}
                  style={Styles.tskBtn}
                  onClick={() => {
                    setIsOpen(true);
                    if (actionType !== add) {
                      dispatch(setProfileActionType(edit));
                    }
                  }}
                />
              </Box>
              {values.tasks?.map((item, index) => (
                <Box key={index} sx={Styles.tskListCon}>
                  <Box sx={Styles.tstListSub} onClick={() => handleViewTask(item, index)}>
                    <Box sx={Styles.num}>{`${index + 1}.`}</Box>
                    <Box>
                      <Text variant="D24">{item?.task_name}</Text>
                      <Text variant="D18">{item?.description}</Text>
                    </Box>
                  </Box>
                  <Box sx={Styles.iconsCon}>
                    <UIEditIcon onClick={() => handleEditTask(item, index)} />
                    {values.tasks?.length > 1 && (
                      <UIDeleteIcon onClick={() => handleDeleteTask(index)} />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <PopupModal
        containerStyle={Styles.popModal}
        subContainerStyle={Styles.px}
        title={getTitles('T-11263')}
        onClose={handleModalClose}
        titlePosition="start"
        showModel={isOpen}>
        <TaskDetails taskType={taskType} data={handleTaskData} task={selectedTask} />
      </PopupModal>
      {actionType === add && values.tasks.length > 0 && (
        <ModalButtons onPrimaryBtnClick={() => handleSubmit()} />
      )}
    </Box>
  );
};
