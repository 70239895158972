import { Box } from '@mui/material';
import { color } from '../../theme';
import { Text } from '../text';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import Dangerous from '../../assets/icons/dangerous.png';
import Verified from '../../assets/icons/verified.png';
import { getTitles } from '../../utils';
import { defaultValues } from '../../constants';
import { UIButton } from '../button';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const lg = defaultValues.isLarge;
const sm = defaultValues.isMdSmall;

const styles = {
  container: {
    width: '100%'
  },
  cardCont: (stat, expand) => ({
    width: '100%',
    padding: res ? '5px' : '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    backgroundColor:
      stat && expand ? color.palette.lightGoldenrodYellow : color.secondaryBackground,
    display: 'flex',
    justifyContent: 'space-between'
  }),
  titleCont: (isDis) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: isDis ? 'default' : 'pointer',
    opacity: isDis ? '0.5' : '1'
  }),
  icon: (expand) => ({
    transform: expand ? `rotate(90deg)` : 'none',
    transition: 'transform 0.3s ease',
    fontSize: res ? '25px' : '32px'
  }),
  statusCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: res ? 'start' : 'space-between',
    width: 'fit-content',
    gap: '10px',
    marginRight: '10px'
  },
  statusIcon: (status) => ({
    height: res ? (!status ? '12px' : '14px') : !status ? '18px' : '20px',
    width: res ? (!status ? '12px' : '14px') : !status ? '18px' : '20px'
  }),
  childContainer: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    padding: res ? '7px' : md ? '10px 2%' : '10px 5%',
    boxSizing: 'border-box'
  },
  editBtn: {
    padding: res ? '5px' : '5px 20px',
    width: res ? 'fit-content' : '150px',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box',
    whiteSpace: 'nowrap'
  },
  status: {
    fontSize: sm ? '0.825rem' : res ? '1rem' : lg ? '1rem' : '1.20rem'
  }
};

export const CollapsableData = ({
  children,
  title,
  status,
  isOpen,
  isStatus = true,
  handleOpen = null,
  currRender,
  isEdit,
  handleEditClick,
  isReadOnly,
  disabled
}) => {
  const [expand, setExpand] = useState(false);

  const { edit } = defaultValues.actionType;
  useEffect(() => {
    if (isOpen !== undefined) {
      setExpand(isOpen);
    }
  }, [isOpen]);

  const handleExpand = () => {
    handleOpen ? handleOpen(!isOpen) : setExpand(!expand);
  };

  const getTitlename = (isEdit) => {
    if (defaultValues.isResponsive) return null;
    if (isEdit) return getTitles('T-10444');
    return getTitles('T-10084');
  };

  return (
    <Box sx={styles.container}>
      <Box style={styles.cardCont(isStatus, expand)}>
        <Box sx={styles.titleCont(disabled)} onClick={handleExpand}>
          <ChevronRightIcon sx={styles.icon(expand)} />
          <Text variant="BoldH24">{title}</Text>
        </Box>
        {isStatus && currRender !== edit && (
          <Box sx={styles.statusCont}>
            <img style={styles.statusIcon(status)} src={status ? Verified : Dangerous} />
            <Text variant="D20" style={styles.status}>
              {status ? getTitles('T-10303') : getTitles('T-10304')}
            </Text>
          </Box>
        )}

        {handleEditClick && currRender === edit && !isReadOnly && (
          <UIButton
            title={getTitlename(isEdit)}
            style={styles.editBtn}
            startIcon={!isEdit ? <EditIcon /> : <VisibilityIcon />}
            onClick={() => handleEditClick(!isEdit)}
          />
        )}
      </Box>
      {expand && <Box sx={styles.childContainer}>{children}</Box>}
    </Box>
  );
};
