import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { color } from '../../theme';
import { Text } from '../text';
import { defaultValues } from '../../constants';

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

export const UIMultipleSelectCheckmarks = ({
  options = [],
  placeholder,
  onChange,
  onBlur,
  displayLabel = 'title',
  title,
  required,
  variant = 'D20',
  onClear,
  error,
  preSelectedValue,
  containerStyles,
  showTitles = true,
  showCheckBox = true,
  showSelect = true,
  placeholderStyles,
  selectStyle,
  ...rest
}) => {
  const styles = {
    container: (style) => ({
      width: '100%',
      marginTop: '10px',
      ...style
    }),
    checkIcon: {
      '&.Mui-checked': {
        color: color.primary
      }
    },
    options: { '&.Mui-selected': { backgroundColor: color.primaryBackground } },
    title: {
      marginBottom: '5px'
    },
    asterisk: {
      color: color.errorText
    },
    placeholder: (style) => ({
      color: color.palette.lightGray,
      ...style
    }),
    select: (style) => ({
      height: defaultValues.isResponsive ? 40 : 55,
      ...style
    })
  };

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    if (onClear) {
      setSelectedOptions([]);
    }
  }, [onClear]);

  React.useEffect(() => {
    if (preSelectedValue) {
      setSelectedOptions(preSelectedValue);
    }
  }, [preSelectedValue]);

  const handleToggle = (option) => {
    const currentIndex = selectedOptions.findIndex((item) => item.value === option.value);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }
    setSelectedOptions(newSelectedOptions);
    onChange && onChange(newSelectedOptions);
  };

  return (
    <div style={styles.container(containerStyles)}>
      {title && (
        <Text variant={variant} style={styles.title}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      {showSelect && (
        <FormControl sx={{ width: '100%' }}>
          <Select
            multiple
            displayEmpty
            value={selectedOptions}
            input={<OutlinedInput />}
            onBlur={onBlur}
            sx={styles.select(selectStyle)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <div style={styles.placeholder(placeholderStyles)}>{placeholder}</div>;
              }
              return showTitles ? (
                selectedOptions.map((option) => option[displayLabel]).join(', ')
              ) : (
                <div style={styles.placeholder(placeholderStyles)}>{placeholder}</div>
              );
            }}
            MenuProps={MenuProps}
            {...rest}>
            {options &&
              options.length > 0 &&
              options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option}
                  sx={styles.options}
                  onClick={() => handleToggle(option)}>
                  {showCheckBox && (
                    <Checkbox
                      checked={selectedOptions.some((item) => item.value === option.value)}
                      sx={styles.checkIcon}
                    />
                  )}
                  <ListItemText primary={option[displayLabel]} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {error && <Text variant="fieldError">{error}</Text>}
    </div>
  );
};
