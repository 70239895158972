import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { UIButton } from '../button';

export const UIEditIcon = ({ onClick }) => {
  const styles = {
    editBtn: {
      width: '40px',
      height: '40px',
      padding: '5px',
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      '& .MuiButton-startIcon': {
        marginRight: '0px',
        marginLeft: '0px'
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  };
  return <UIButton startIcon={<CreateOutlinedIcon />} onClick={onClick} style={styles.editBtn} />;
};
