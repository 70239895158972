import { Switch, styled } from '@mui/material';
import { color } from '../../theme';
import { defaultValues } from '../../constants';
import doneIcon from '../../assets/icons/doneIcon.svg';
import closeIcon from '../../assets/icons/closeIcon.svg';
import warningIcon from '../../assets/icons/warningIcon.svg';

const res = defaultValues.isResponsive;

const doneIconUrl = `url('${doneIcon}')`;
const closeIconUrl = `url('${closeIcon}')`;
const warningIconUrl = `url('${warningIcon}')`;

export const UISwitch = styled(({ ...props }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, isReadOnly, showImg, pending }) => ({
  width: res ? 40 : 50,
  height: res ? 20 : 26,
  padding: 0,
  border: `1px solid ${color.secondaryBorder}`,
  borderRadius: 13,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: res ? '1px' : '1px 2px',
    transitionDuration: '300ms',
    color: color.errorText,
    cursor: isReadOnly ? 'default' : 'pointer',
    '&.Mui-checked': {
      transform: res ? 'translateX(19px)' : 'translateX(22px)',
      color: color.success,
      '& .MuiSwitch-thumb': {
        backgroundImage: showImg ? doneIconUrl : 'none'
      },
      '& + .MuiSwitch-track': {
        backgroundColor: color.secondaryBackground,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: res ? 17 : 22,
    height: res ? 17 : 22,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: pending ? warningIconUrl : showImg ? closeIconUrl : 'none'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: color.secondaryBackground,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
