import { createSelector } from '@reduxjs/toolkit';

export const getGovtOfficial = createSelector(
  [(state) => state.govtOfficial.govtOfficials],
  (d) => d
);
export const getGovtOfficialCurPage = createSelector(
  [(state) => state.govtOfficial.currentPage],
  (d) => d
);
export const getGovtOfficialNxtPage = createSelector(
  [(state) => state.govtOfficial.nextPage],
  (d) => d
);
export const getGovtOfficialTotalPage = createSelector(
  [(state) => state.govtOfficial.totalPage],
  (d) => d
);
export const getGovtOfficialLoading = createSelector(
  [(state) => state.govtOfficial.isLoading],
  (d) => d
);
export const getGovtOfficialData = createSelector(
  [(state) => state.govtOfficial.govtOfficialData],
  (d) => d
);
export const getGovtOfficialSuccessMsg = createSelector(
  [(state) => state.govtOfficial.successMessage],
  (d) => d
);
export const getGovtOfficialErrorMsg = createSelector(
  [(state) => state.govtOfficial.errorMessage],
  (d) => d
);
export const getGovtOfficialCurrRender = createSelector(
  [(state) => state.govtOfficial.currRender],
  (d) => d
);

export const getGovtOfficialParams = createSelector(
  [(state) => state.govtOfficial.govtOfficialParams],
  (d) => d
);

export const getGovtOfficialCurruserId = createSelector(
  [(state) => state.govtOfficial.currUserId],
  (d) => d
);
