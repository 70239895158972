import { defaultValues } from '../../constants';
import { color } from '../../theme';
import { saveAs } from 'file-saver';

const res = defaultValues.isResponsive;

export const RenderFile = ({ filePath, type }) => {
  const styles = {
    pdfView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      width: res ? '100%' : '400px',
      height: res ? '200px' : '300px',
      textAlign: 'center',
      maxWidth: '400px'
    },
    link: {
      color: color.link,
      cursor: 'pointer'
    },
    imgCont: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    img: { maxWidth: res ? '200px' : '300px', maxHeight: res ? '200px' : '300px' }
  };

  const handleDownload = (url) => {
    const pdfUrl = url;
    let arr = url.split('/');
    saveAs(pdfUrl, `${arr[arr.length - 1]}`);
  };
  const extension = typeof filePath === 'string' && filePath.split('.').pop().toLowerCase();
  const fileType = type || extension;

  switch (fileType) {
    case 'pdf':
    case 'application/pdf':
      return (
        <object
          data={filePath}
          style={styles.pdfView}
          type="application/pdf"
          width="100%"
          height="100%">
          <p>
            <u style={styles.link} onClick={() => handleDownload(filePath)}>
              click here
            </u>
            &nbsp;to view or download the file
          </p>
        </object>
      );
    case 'doc':
    case 'docx':
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
          width="100%"
          height="100%"
        />
      );
    case 'jpeg':
    case 'image/jpeg':
    case 'jpg':
    case 'image/jpg':
    case 'png':
    case 'image/png':
      return (
        <div style={styles.imgCont}>
          <img src={filePath} style={styles.img} alt="image not found" />
        </div>
      );
    default:
      return <p>Unsupported file format</p>;
  }
};
