import { defaultValues } from '../../constants';
import { color } from '../../theme';
const isRes = defaultValues.isResponsive;

export const styles = {
  columnWidth: '30%',
  btn: {
    padding: '5px 10px',
    height: isRes ? '30px' : '45px',
    width: isRes ? '30px' : '120px',
    minWidth: isRes && '40px'
  },
  cursor: { cursor: 'pointer' },
  container: {
    width: '100%',
    minHeight: '80vh',
    backgroundColor: color.secondaryBackground,
    py: isRes ? 1 : 2,
    boxSizing: 'border-box'
  },
  subContainer: {
    backgroundColor: color.primaryBackground,
    width: '100%',
    height: '100%',
    borderRadius: 1,
    p: isRes ? 1 : 3,
    boxSizing: 'border-box'
  },
  itemContainer: { display: 'flex', justifyContent: 'space-between' },
  title: { mb: isRes ? 1 : 3 },
  backBtnContainer: { display: 'flex', alignItems: 'start', mb: 4 },
  nameTxt: { color: color.secondaryText }
};
