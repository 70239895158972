/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Screen } from '../../ui-kits';

export const TermsConditions = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center'
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  return (
    <Screen showHeader={true}>
      <div style={styles.container}>
        <div style={styles.subContainer}>
          <h1>Terms & Conditions</h1>
          <p>Last updated: September 19, 2024</p>

          <p>
            These terms govern your use of our website and services, outlining your rights and
            responsibilities as a user. By accessing or using our site, you agree to comply with
            these terms, including any applicable laws and regulations. We reserve the right to
            modify these terms at any time, so please review them periodically. If you do not agree
            with any part of these terms, we kindly ask you not to use our services. Your continued
            use signifies your acceptance of any changes made.
          </p>
        </div>
      </div>
    </Screen>
  );
};
