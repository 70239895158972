export const styles = {
  container: {
    width: '100%'
  },
  profileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '5px'
  },
  tableHeaderTextStyle: {
    whiteSpace: 'none',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  }
};
