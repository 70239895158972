import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addAiToVetFailure,
  addAiToVetRequest,
  addAiToVetSuccess,
  assignLabToVetFailure,
  assignLabToVetRequest,
  assignLabToVetSuccess,
  getUnassignedAIFailure,
  getUnassignedAIRequest,
  getUnassignedAISuccess,
  getVetFailure,
  getVetRequest,
  getVetSuccess,
  updateAssignedAiFailure,
  updateAssignedAiRequest,
  updateAssignedAiSuccess,
  updateVetStatusFailure,
  updateVetStatusRequest,
  updateVetStatusSuccess
} from '../slices';
import {
  addAiToVetApi,
  assignLabToUserApi,
  getUnassignAiApi,
  getVetsApi,
  updateAssignedAiApi,
  updateVetStatusApi
} from '../../api';

function* getVets(action) {
  try {
    const response = yield getVetsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getVetSuccess(response.data));
    } else {
      yield put(getVetFailure(response.data));
    }
  } catch (error) {
    yield put(getVetFailure(error.response.data));
  }
}

function* getUnassignAi(action) {
  try {
    const response = yield getUnassignAiApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getUnassignedAISuccess(response.data));
    } else {
      yield put(getUnassignedAIFailure(response.data));
    }
  } catch (error) {
    yield put(getUnassignedAIFailure(error.response.data));
  }
}

function* addAiToVet(action) {
  try {
    const response = yield addAiToVetApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addAiToVetSuccess(response.data));
    } else {
      yield put(addAiToVetFailure(response.data));
    }
  } catch (error) {
    yield put(addAiToVetFailure(error.response.data));
  }
}

function* updateAssignedAi(action) {
  try {
    const response = yield updateAssignedAiApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateAssignedAiSuccess(response.data));
    } else {
      yield put(updateAssignedAiFailure(response.data));
    }
  } catch (error) {
    yield put(updateAssignedAiFailure(error.response.data));
  }
}

function* updateVetStatus(action) {
  try {
    const response = yield updateVetStatusApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateVetStatusSuccess(response.data));
    } else {
      yield put(updateVetStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateVetStatusFailure(error.response.data));
  }
}

function* assignLabToVet(action) {
  try {
    const response = yield assignLabToUserApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(assignLabToVetSuccess(response.data));
    } else {
      yield put(assignLabToVetFailure(response.data));
    }
  } catch (error) {
    yield put(assignLabToVetFailure(error.response.data));
  }
}
export function* vetSaga() {
  yield takeLatest(getVetRequest.type, getVets);
  yield takeLatest(getUnassignedAIRequest.type, getUnassignAi);
  yield takeLatest(addAiToVetRequest.type, addAiToVet);
  yield takeLatest(updateAssignedAiRequest.type, updateAssignedAi);
  yield takeLatest(updateVetStatusRequest.type, updateVetStatus);
  yield takeLatest(assignLabToVetRequest.type, assignLabToVet);
}
