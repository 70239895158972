/* eslint-disable no-unused-vars */
import { defaultValues } from '../../constants';
import { color } from '../../theme';

const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

export const Styles = {
  container: (isRes) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isRes ? '85%' : md ? '35%' : '45%',
    height: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: sm ? 2 : 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1000
  }),
  buttonContainer: (type) => ({
    mt: sm ? 3 : 5,
    mb: sm ? 0 : 1,
    width: sm ? '100%' : '90%',
    display: 'flex',
    justifyContent: 'space-evenly'
  }),
  title: { textAlign: 'center', mt: 5 },
  subTitle: { textAlign: 'center', color: color.secondaryText },
  imageStyle: {
    width: '80px',
    height: '80px'
  },

  cancelBtn: { width: '100%', ml: sm ? 0 : 3, mr: sm ? 1 : 3, fontWeight: 600 },
  successBtn: {
    width: '100%',
    mr: sm ? 0 : 3,
    backgroundColor: color.secondary,
    color: color.primaryText,
    fontWeight: 600,
    '&:hover': {
      bgcolor: color.secondary
    }
  },
  ratingStar: {
    '& .MuiRating-icon': {
      fontSize: sm ? '45px' : md ? '50px' : '60px'
    }
  },
  modal: {
    '& .MuiModal-backdrop': {
      backgroundColor: color.backdrop
    }
  }
};
