import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewEmploymentDetails = ({ employeeData, departments, designations }) => {
  return (
    <Box sx={styles.container}>
      {employeeData && (
        <>
          <ViewData
            title={getTitles('T-10310')}
            subTitle={
              departments &&
              departments.length > 0 &&
              departments.find((ele) => ele._id === employeeData.department_id).name
            }
          />
          <ViewData
            title={getTitles('T-10050')}
            subTitle={
              designations &&
              designations.length > 0 &&
              designations.find((ele) => ele._id === employeeData.designation_id).name
            }
          />
          <ViewData
            title={getTitles('T-10378')}
            subTitle={getFormatDate(employeeData.date_joining)}
          />
          <ViewData
            title={getTitles('T-10379')}
            subTitle={employeeData.date_relieving && getFormatDate(employeeData.date_relieving)}
          />
          <ViewData title={getTitles('T-10419')} subTitle={employeeData.salary} />
        </>
      )}
    </Box>
  );
};
