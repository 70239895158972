export const SCREEN_PATH = {
  HOME: '/',
  FALLBACK: '*/*',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  MANAGE_ADMIN: '/manage-admin',
  PROFILE: '/profile-settings',
  MANAGE_STATE: '/manage-state',
  MANAGE_DISTRICT: '/manage-district',
  MANAGE_TALUK: '/manage-taluk',
  MANAGE_VILLAGE: '/manage-village',
  ADD_ADMIN: '/manage-admin/add-admin',
  ADMIN_DETAILS: '/manage-admin/admin-details',
  MANAGE_PROCESS: '/manage-process',
  MANAGE_EMPLOYEE: '/manage-employee',
  MANAGE_FARMER: '/manage-farmer',
  REGISTER_FARMER: '/register-farmer',
  MANAGE_VET: '/manage-vet',
  MANAGE_AI_WORKER: '/manage-ai-worker',
  MANAGE_SOCIETY: '/manage-society',
  MANAGE_INSURANCE: '/manage-insurance',
  MANAGE_INSURANCE_FARMERS_INFO: '/farmers-cattles-info',
  MANAGE_INSURANCE_FARMER_CATTLES_LIST: '/cattles-list',
  MANAGE_INSURANCE_CATTLE_DETAILS: '/cattle-info',
  MANAGE_LABORATORY: '/manage-laboratory',
  MANAGE_CATTLE: '/manage-farmer/cattles',
  REGISTER_CATTLE: '/manage-farmer/register-cattles',
  GOVT_OFFICIAL: '/goverment-officials',
  ASSOCIATED_FARMER: '/society/farmers',
  CALL_CENTER: '/call-center',
  CATTLE_TASK_DETAILS_CALL_CENTER: '/call-center/cattle-task-details',
  POLICY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  ABOUT_US: '/about',
  CONTACT_US: '/contact',
  DISCLAIMER: '/disclaimer',
  SHIPPING_POLICY: '/shipping-policy',
  MANAGE_VILLAGE_FOR_VILLAGE_MANAGER: '/manage-villages',
  MANAGE_INVENTORY: '/manage-inventory',
  TASKS: '/tasks',
  ECOMMERCE: '/e-commerce',
  ACTIVITY: 'activity',
  PRODUCT_DETAILS: 'product-details',
  ID: '/:id',
  LAB_FARMER: '/lab-farmer',
  LAB_FARMER_CATTLE_LIST: '/lab-farmer/cattle-list',
  LAB_FARMER_CATTLE_DETAILS: '/lab-farmer/cattle-list/cattle-details',
  LAB_FARMER_CATTLE_REPORTS: '/lab-farmer/cattle-list/cattle-details/reports',
  SHOPPING_CART: 'shopping-cart',
  MANAGE_CALL_CENTER: '/manage-call-center',
  MY_ORDERS: 'my-orders'
};
