import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styles } from './style';
import { Text } from '../text';
import CloseIcon from '@mui/icons-material/Close';

export const PopupModal = ({
  showModel,
  onClose,
  title,
  children,
  titleStyle,
  headerMiddleChildren,
  childContStyle,
  titlePosition = 'center',
  subContainerStyle,
  containerStyle
}) => {
  return (
    <Modal open={showModel} onClose={onClose}>
      <Box sx={styles.container(containerStyle)}>
        <Box sx={styles.titleContainer}>
          <Box sx={styles.titleStyle(titlePosition, titleStyle)}>
            <Text variant="boldH32">{title}</Text>
          </Box>

          {headerMiddleChildren}

          <Box sx={styles.closeBtnStyle} onClick={onClose}>
            <CloseIcon fontSize="large" />
          </Box>
        </Box>
        <Box sx={styles.childContainer(subContainerStyle, childContStyle)}>{children}</Box>
      </Box>
    </Modal>
  );
};
