import { Box, Divider } from '@mui/material';
import {
  Text,
  UIChipButton,
  UIRadioButtonWithTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import { filterSelectedValue, getTitles, getTitlesByValue, resetMessages } from '../../../utils';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { ModalButtons } from '../../common/modal-buttons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionType,
  getFarmerData,
  getFarmerErrorMsg,
  getFarmerSuccessMsg
} from '../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  addFarmerRequest,
  resetFarmerMsg,
  updateFarmerRequest
} from '../../../services/redux/slices';
import { Styles } from '../style';

export const ProductionDetails = ({ onNext, onPrev, setNext }) => {
  const dispatch = useDispatch();
  const { view, edit, viewOnly } = defaultValues.actionType;

  const farmerData = useSelector(getFarmerData);
  const success = useSelector(getFarmerSuccessMsg);
  const error = useSelector(getFarmerErrorMsg);
  const actionType = useSelector(getActionType);

  const [btnDisable, setBtnDisable] = useState(true);
  const [filteredCattleCapacity, setFilteredCattleCapacity] = useState([]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      cattle_capacity: [],
      have_mukta_gota: null,
      cattle_details: {
        cow: {
          cattle_count: null,
          calf_count: null,
          avg_quantity: null,
          avg_fat: null,
          avg_snf: null
        },
        buffalos: {
          cattle_count: null,
          calf_count: null,
          avg_quantity: null,
          avg_fat: null,
          avg_snf: null
        }
      },
      avg_milk_produced: null,
      milk_usage: {
        self_consumption: null,
        diary: null,
        society: null,
        milk_man: null,
        users: null,
        hotels: null,
        sweet_marts: null
      },
      avg_expenses_per_month: null,
      avg_income_per_month: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const handleChange = (e) => {
    setBtnDisable(false);
    const { name, value } = e.target;

    if (name === 'cattle_capacity') {
      if (!values.cattle_capacity?.includes(value)) {
        setFieldValue(name, [...values.cattle_capacity, { id: value }]);
      }
    } else {
      setFieldValue(name, value);
    }
  };

  const handleDelete = (itemToDelete) => {
    const newCattleCapacity = values.cattle_capacity.filter((item) => item !== itemToDelete);
    setFieldValue('cattle_capacity', newCattleCapacity);
  };

  useEffect(() => {
    setFilteredCattleCapacity(
      filterSelectedValue(defaultValues.cattleCapacity, values.cattle_capacity, 'id', 'value')
    );
  }, [values.cattle_capacity]);

  useEffect(() => {
    dispatch(resetFarmerMsg());

    if (farmerData) {
      setFieldValue('_id', farmerData._id);
      if (farmerData.production_details) {
        setFieldValue('cattle_capacity', farmerData.production_details.cattle_capacity);
        setFieldValue('have_mukta_gota', farmerData.production_details.have_mukta_gota);
        setFieldValue('avg_milk_produced', farmerData.production_details.avg_milk_produced);
        setFieldValue(
          'avg_expenses_per_month',
          farmerData.production_details.avg_expenses_per_month
        );
        setFieldValue('avg_income_per_month', farmerData.production_details.avg_income_per_month);

        if (farmerData.production_details.cattle_details) {
          if (farmerData.production_details.cattle_details.cow) {
            setFieldValue(
              'cattle_details.cow.cattle_count',
              farmerData.production_details.cattle_details.cow.cattle_count
            );
            setFieldValue(
              'cattle_details.cow.calf_count',
              farmerData.production_details.cattle_details.cow.calf_count
            );
            setFieldValue(
              'cattle_details.cow.avg_quantity',
              farmerData.production_details.cattle_details.cow.avg_quantity
            );
            setFieldValue(
              'cattle_details.cow.avg_fat',
              farmerData.production_details.cattle_details.cow.avg_fat
            );
            setFieldValue(
              'cattle_details.cow.avg_snf',
              farmerData.production_details.cattle_details.cow.avg_snf
            );
          }

          if (farmerData.production_details.cattle_details.buffalos) {
            setFieldValue(
              'cattle_details.buffalos.cattle_count',
              farmerData.production_details.cattle_details.buffalos.cattle_count
            );
            setFieldValue(
              'cattle_details.buffalos.calf_count',
              farmerData.production_details.cattle_details.buffalos.calf_count
            );
            setFieldValue(
              'cattle_details.buffalos.avg_quantity',
              farmerData.production_details.cattle_details.buffalos.avg_quantity
            );
            setFieldValue(
              'cattle_details.buffalos.avg_fat',
              farmerData.production_details.cattle_details.buffalos.avg_fat
            );
            setFieldValue(
              'cattle_details.buffalos.avg_snf',
              farmerData.production_details.cattle_details.buffalos.avg_snf
            );
          }

          if (farmerData.production_details.milk_usage) {
            setFieldValue(
              'milk_usage.self_consumption',
              farmerData.production_details.milk_usage.self_consumption
            );

            setFieldValue('milk_usage.diary', farmerData.production_details.milk_usage.diary);
            setFieldValue('milk_usage.society', farmerData.production_details.milk_usage.society);
            setFieldValue('milk_usage.milk_man', farmerData.production_details.milk_usage.milk_man);
            setFieldValue('milk_usage.users', farmerData.production_details.milk_usage.users);
            setFieldValue('milk_usage.hotels', farmerData.production_details.milk_usage.hotels);
            setFieldValue(
              'milk_usage.sweet_marts',
              farmerData.production_details.milk_usage.sweet_marts
            );
          }
        }
      }
    }
  }, [farmerData]);

  useEffect(() => {
    if (success === 'S-10042') {
      onNext();
    }
    if (success === 'S-10044') {
      toast.success(getSuccessMessage(success));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetFarmerMsg()));
  }, [success, error]);

  const handleSubmit = () => {
    if (actionType === edit) {
      let _id = farmerData._id;
      const production_details = {
        ...values,
        avg_milk_produced: parseFloat(values.avg_milk_produced),
        cattle_details: {
          ...values.cattle_details,
          cow: {
            ...values.cattle_details?.cow,
            cattle_count: parseInt(values.cattle_details?.cow?.cattle_count),
            calf_count: parseInt(values.cattle_details?.cow?.calf_count),
            avg_quantity: parseFloat(values.cattle_details?.cow?.avg_quantity),
            avg_fat: parseFloat(values.cattle_details?.cow?.avg_fat),
            avg_snf: parseFloat(values.cattle_details?.cow?.avg_snf)
          },
          buffalos: {
            ...values.cattle_details?.buffalos,
            cattle_count: parseInt(values.cattle_details?.buffalos?.cattle_count),
            calf_count: parseInt(values.cattle_details?.buffalos?.calf_count),
            avg_quantity: parseFloat(values.cattle_details?.buffalos?.avg_quantity),
            avg_fat: parseFloat(values.cattle_details?.buffalos?.avg_fat),
            avg_snf: parseFloat(values.cattle_details?.buffalos?.avg_snf)
          }
        },
        milk_usage: {
          ...values.milk_usage,
          self_consumption: parseFloat(values.milk_usage?.self_consumption),
          diary: parseFloat(values.milk_usage?.diary),
          society: parseFloat(values.milk_usage?.society),
          milk_man: parseFloat(values.milk_usage?.milk_man),
          users: parseFloat(values.milk_usage?.users),
          hotels: parseFloat(values.milk_usage?.hotels),
          sweet_marts: parseFloat(values.milk_usage?.sweet_marts)
        },
        avg_expenses_per_month: parseFloat(values.avg_expenses_per_month),
        avg_income_per_month: parseFloat(values.avg_income_per_month),
        cattle_capacity: values.cattle_capacity?.map((item) => item.id)
      };
      dispatch(updateFarmerRequest({ _id, production_details }));
    } else {
      const payload = {};
      payload.data = {
        ...values,
        _id: farmerData?._id,
        avg_milk_produced: parseFloat(values.avg_milk_produced),
        cattle_capacity: values.cattle_capacity?.map((item) => item.id),
        cattle_details: {
          ...values.cattle_details,
          cow: {
            ...values.cattle_details?.cow,
            cattle_count: parseInt(values.cattle_details?.cow?.cattle_count),
            calf_count: parseInt(values.cattle_details?.cow?.calf_count),
            avg_quantity: parseFloat(values.cattle_details?.cow?.avg_quantity),
            avg_fat: parseFloat(values.cattle_details?.cow?.avg_fat),
            avg_snf: parseFloat(values.cattle_details?.cow?.avg_snf)
          },
          buffalos: {
            ...values.cattle_details?.buffalos,
            cattle_count: parseInt(values.cattle_details?.buffalos?.cattle_count),
            calf_count: parseInt(values.cattle_details?.buffalos?.calf_count),
            avg_quantity: parseFloat(values.cattle_details?.buffalos?.avg_quantity),
            avg_fat: parseFloat(values.cattle_details?.buffalos?.avg_fat),
            avg_snf: parseFloat(values.cattle_details?.buffalos?.avg_snf)
          }
        },
        milk_usage: {
          ...values.milk_usage,
          self_consumption: parseFloat(values.milk_usage?.self_consumption),
          diary: parseFloat(values.milk_usage?.diary),
          society: parseFloat(values.milk_usage?.society),
          milk_man: parseFloat(values.milk_usage?.milk_man),
          users: parseFloat(values.milk_usage?.users),
          hotels: parseFloat(values.milk_usage?.hotels),
          sweet_marts: parseFloat(values.milk_usage?.sweet_marts)
        },
        avg_expenses_per_month: parseFloat(values.avg_expenses_per_month),
        avg_income_per_month: parseFloat(values.avg_income_per_month)
      };
      payload.registration_step = 3;
      dispatch(addFarmerRequest(payload));
    }
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.qtrContainer}>
        {actionType !== view && (
          <UISelect
            name="cattle_capacity"
            value={''}
            title={getTitles('T-10536')}
            options={filteredCattleCapacity}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            placeholder={getTitles('T-10350')}
            containerStyles={Styles.prodContainer}
          />
        )}
        {actionType === view && values.cattle_capacity.length > 0 && (
          <Text variant="D20" style={Styles.chipTitle}>
            {getTitles('T-10536')}
          </Text>
        )}
        {values.cattle_capacity &&
          values.cattle_capacity.map((item, index) => (
            <UIChipButton
              showDelete={actionType !== view}
              title={getTitlesByValue([item.id], defaultValues.cattleCapacity)}
              key={index}
              onDelete={() => handleDelete(item)}
            />
          ))}
        <UIRadioButtonWithTitle
          title={getTitles('T-10510')}
          onCheck={(val) => {
            setFieldValue('have_mukta_gota', val);
            setBtnDisable(false);
          }}
          values={defaultValues.personalInsurane}
          checkedValue={values.have_mukta_gota}
          disabled={actionType === view || actionType === viewOnly}
        />
        <Text variant="BoldH22Sec">{getTitles('T-11199')}</Text>
        <Box sx={Styles.xs65Two}>
          <UITextInputWithTitle
            name="cattle_details.cow.cattle_count"
            value={values.cattle_details.cow.cattle_count || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10511')}
            placeHolder={getTitles('T-10360')}
            allowOnlyNumbers
          />
          <UITextInputWithTitle
            name="cattle_details.cow.calf_count"
            value={values.cattle_details.cow.calf_count || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10512')}
            placeHolder={getTitles('T-10360')}
            allowOnlyNumbers
          />
        </Box>
        <Text variant="BoldH22Sec" style={Styles.mt}>
          {getTitles('T-11200')}
        </Text>
        <Box sx={Styles.xs65Two}>
          <UITextInputWithTitle
            name="cattle_details.buffalos.cattle_count"
            value={values.cattle_details.buffalos.cattle_count || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10513')}
            placeHolder={getTitles('T-10360')}
            allowOnlyNumbers
          />
          <UITextInputWithTitle
            name="cattle_details.buffalos.calf_count"
            value={values.cattle_details.buffalos.calf_count || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10514')}
            placeHolder={getTitles('T-10360')}
            allowOnlyNumbers
          />
        </Box>
        <UITextInputWithTitle
          name="avg_milk_produced"
          value={values.avg_milk_produced || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10515')}
          placeHolder={getTitles('T-10360')}
          containerStyles={Styles.milAvgContainer}
          allowDecimal={true}
        />
        <Text variant="BoldH22" style={Styles.text}>
          {getTitles('T-10516')}
        </Text>
        <Box sx={Styles.commonColumn}>
          <UITextInputWithTitle
            name="cattle_details.cow.avg_quantity"
            value={values.cattle_details.cow.avg_quantity || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10517')}
            placeHolder={getTitles('T-10671')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="cattle_details.cow.avg_fat"
            value={values.cattle_details.cow.avg_fat || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10518')}
            placeHolder={getTitles('T-10670')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="cattle_details.cow.avg_snf"
            value={values.cattle_details.cow.avg_snf || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10519')}
            placeHolder={getTitles('T-10669')}
            allowDecimal={true}
          />
        </Box>
        <Text variant="BoldH22" style={Styles.text}>
          {getTitles('T-10523')}
        </Text>
        <Box sx={Styles.commonColumn}>
          <UITextInputWithTitle
            name="cattle_details.buffalos.avg_quantity"
            value={values.cattle_details.buffalos.avg_quantity || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10517')}
            placeHolder={getTitles('T-10671')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="cattle_details.buffalos.avg_fat"
            value={values.cattle_details.buffalos.avg_fat || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10518')}
            placeHolder={getTitles('T-10670')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="cattle_details.buffalos.avg_snf"
            value={values.cattle_details.buffalos.avg_snf || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10519')}
            placeHolder={getTitles('T-10669')}
            allowDecimal={true}
          />
        </Box>
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10524')}
        </Text>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.xs65Two}>
          <UITextInputWithTitle
            name="milk_usage.self_consumption"
            value={values.milk_usage.self_consumption || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10525')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.diary"
            value={values.milk_usage.diary || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10526')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.society"
            value={values.milk_usage.society || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10527')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.milk_man"
            value={values.milk_usage.milk_man || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10528')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.users"
            value={values.milk_usage.users || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10529')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.hotels"
            value={values.milk_usage.hotels || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10530')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="milk_usage.sweet_marts"
            value={values.milk_usage.sweet_marts || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10531')}
            placeHolder={getTitles('T-11050')}
            allowDecimal={true}
          />
        </Box>
        <UITextInputWithTitle
          value={values.avg_income_per_month || ''}
          name="avg_income_per_month"
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10532')}
          placeHolder={getTitles('T-10667')}
          containerStyles={Styles.prodContainer}
          allowDecimal={true}
        />{' '}
        <UITextInputWithTitle
          name="avg_expenses_per_month"
          value={values.avg_expenses_per_month || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10534')}
          placeHolder={getTitles('T-10668')}
          containerStyles={Styles.prodContainer}
          allowDecimal={true}
        />
      </Box>

      <ModalButtons
        btnPositions={defaultValues.isResponsive ? 'center' : 'start'}
        disabled={actionType === edit ? btnDisable : false}
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={() => {
          if (actionType === viewOnly) {
            onPrev();
          } else {
            onPrev();
          }
        }}
        onPrimaryBtnClick={
          actionType === view || actionType === viewOnly ? () => setNext(3) : handleSubmit
        }
        primaryBtnTitle={
          actionType === edit
            ? getTitles('T-10733')
            : actionType === view || actionType === viewOnly
              ? getTitles('T-10804')
              : getTitles('T-10339')
        }
      />
    </Box>
  );
};
