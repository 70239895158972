export const admin_role = Object.freeze({
  process_manager: 1,
  cattle_manager: 2,
  farmer_manager: 3,
  society_manager: 4,
  para_vet_manager: 5,
  vet_manager: 6,
  call_center_manager: 7,
  laboratory_manager: 8,
  inventory_manager: 9,
  e_commerce_manager: 10,
  govt_Office_manager: 11,
  insurance_manager: 12,
  employee_manager: 13
});
