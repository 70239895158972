import { Backdrop, CircularProgress } from '@mui/material';
import { Text } from '../text';
import { palette } from '../../theme/palette';
import { getTitles } from '../../utils';
import { color } from '../../theme';

export const LoadingScreen = ({ isLoading }) => {
  const styles = {
    backdrop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: palette.disableBackground,
      zIndex: (theme) => theme.zIndex.drawer + 1
    },
    text: { color: color.primaryBackground, marginTop: '15px' },
    progress: { color: color.primaryBackground }
  };
  return (
    <Backdrop sx={styles.backdrop} open={isLoading}>
      <CircularProgress color="inherit" sx={styles.progress} size={60} />
      <Text variant="title" style={styles.text}>
        {getTitles('T-10039')}
      </Text>
    </Backdrop>
  );
};
