import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme';

const styles = {
  container: (style) => ({
    width: '100%',
    display: 'flex',
    ...style
  }),
  title: {
    width: '50%'
  },
  subTitle: (sub, title) => ({
    color: sub && color.link,
    textDecoration: sub ? 'underline' : 'none',
    cursor: sub ? 'pointer' : 'text',
    overflowWrap: 'anywhere',
    marginLeft: title ? '0px' : '20px'
  }),
  subCont: {
    width: '100%'
  },
  bullets: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  subsuper: {
    width: '50%',
    display: 'flex'
  }
};

export const ViewData = ({
  title,
  subTitle,
  handleSubtitle,
  bullets,
  containerStyle,
  isSubTitle = true
}) => {
  return (
    <Box sx={styles.container(containerStyle)}>
      {title && typeof title === 'string' ? (
        <Text variant="BoldH24" style={styles.title}>
          {bullets && <span style={styles.bullets}>•</span>}
          {title}
        </Text>
      ) : (
        title && title
      )}

      {isSubTitle && (
        <Box sx={styles.subsuper}>
          <Text variant="D24">: &nbsp;</Text>
          <Text
            variant="D24"
            style={styles.subTitle(handleSubtitle, subTitle)}
            onClick={() => handleSubtitle && handleSubtitle()}>
            {subTitle ? subTitle : '-'}
          </Text>
        </Box>
      )}
    </Box>
  );
};
