import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

export const styles = {
  name: {
    textDecoration: 'underline',
    ml: 1,
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    '&:hover': {
      color: color.link
    }
  },
  container: {
    backgroundColor: color.primaryBackground,
    alignItems: 'center',
    border: 1,
    borderRadius: '12px',
    borderColor: color.secondaryBorder
  },
  grid: { padding: 2, justifyContent: 'center' },
  divider: (cW) => ({
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: cW ? cW : defaultValues.isResponsive ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
    mb: defaultValues.isResponsive ? 2 : 2.5,
    mt: defaultValues.isResponsive ? 0.2 : 0.7
  }),
  button: {
    width: '18%',
    height: '60px',
    mr: 2,
    fontSize: '1.125rem'
  },
  modalButton: {
    width: '20%',
    height: '50px',
    fontSize: '1.25rem'
  },
  modalTextInput: {
    width: '80%',
    height: '60px'
  }
};
