import { Box } from '@mui/material';
import { BasicProfile } from '../../utility-components';
import callIcon from '../../../../assets/icons/call.png';
import distance from '../../../../assets/icons/distance.png';
import { globalStyles } from '../../../../theme';
import { Text } from '../../../../ui-kits';
import { styles } from './styles';

export const TaskInfoCard = ({ containerStyles, icon, heading, title1, title2, address }) => {
  return (
    <Box sx={styles.container(containerStyles)}>
      {icon && <img src={icon} style={styles.icon} />}
      <Box sx={styles.contentCont}>
        {heading && (
          <BasicProfile
            title={heading}
            url={callIcon}
            iconPositon="right"
            titleStyle={{ fontWeight: 600 }}
          />
        )}
        {title1 && <BasicProfile title={title1} isIcon={false} />}
        {title2 && <BasicProfile title={title2} isIcon={false} />}
        {address && (
          <Box sx={styles.locCont}>
            <img src={distance} style={styles.locIcon} />
            <Text variant="D20" style={globalStyles.text}>
              {address}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
