import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const isRes = defaultValues.isResponsive;

export const Styles = {
  container: {
    backgroundColor: color.secondaryBackground,
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  dashboardContainer: {
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    mr: !isRes && 1,
    px: isRes ? '2px' : 3,
    py: 2,
    flex: 1,
    width: '100%',
    overflowX: 'auto',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none'
  },
  optionCont: { display: 'flex' },
  titleStyle: { my: '20px', lineHeight: '40px' },
  chartCont: { display: 'flex', gap: isRes ? '2px' : '20px', width: '100%', flexWrap: 'wrap' },
  tableOptionCont: {
    my: 1,
    display: 'flex',
    flexDirection: isRes ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
    gap: '10px'
  },
  btn: { height: '48px', width: isRes ? '150px' : '200px' },
  resGpCont: { width: '150px' },
  subBtn: { height: '40px' },
  tableCont: { overflowX: 'auto', width: '100%', my: 1 },
  tableSubCont: { width: 'fit-content', minWidth: '100%' },
  groupByCont: {
    width: '310px',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    p: 1,
    overflowY: 'scroll'
  },
  paginationCont: { display: 'flex', justifyContent: 'center' },
  tableTitle: {
    textOverflow: 'ellipsis',
    width: '200px',
    overflowWrap: 'break-word'
  }
};
