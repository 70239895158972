import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const isRes = defaultValues.isResponsive;

export const Styles = {
  container: {
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    width: isRes ? '100%' : '85%',
    alignItems: 'start',
    position: 'relative'
  },
  subContainer: { display: 'flex', alignItems: 'center' },
  circleShape: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: color.secondaryCardBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: '100'
  },
  textColor: {
    color: color.tertiaryText
  },
  hLineToType: {
    position: 'absolute',
    left: isRes ? '50px' : '100px',
    top: isRes ? '125px' : '50px',
    width: isRes ? '2px' : '50px',
    height: isRes ? '50px' : '2px',
    backgroundColor: color.secondaryCardBackground,
    transform: 'translateY(-50%)'
  },
  hArrow: {
    position: 'absolute',
    left: isRes ? '47px' : '150px',
    top: isRes ? '150px' : '50px',
    width: '0',
    height: '0',
    borderLeft: isRes ? '5px solid transparent' : `10px solid ${color.secondaryCardBackground}`,
    borderTop: isRes ? `10px solid ${color.secondaryCardBackground}` : '5px solid transparent',
    borderBottom: !isRes && '5px solid transparent',
    borderRight: isRes && '5px solid transparent',
    transform: 'translateY(-50%)'
  },
  fieldContainer: {
    width: isRes ? '100%' : 'calc(100% - 160px)',
    paddingLeft: !isRes && '60px',
    paddingTop: isRes && '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 8
  },
  fieldWraper: {
    border: `1px solid ${color.primaryBorder}`,
    minHeight: '100px',
    p: 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  btn: {
    width: isRes ? '100px' : '150px',
    height: isRes ? '40px' : '48px',
    my: 2
  },
  lineToFirstToSec: {
    position: 'absolute',
    bottom: 0,
    left: '50px',
    width: '2px',
    height: isRes ? '64px' : '100%',
    backgroundColor: color.secondaryCardBackground,
    transform: 'translateX(-50%)'
  },
  vArrow: {
    position: 'absolute',
    bottom: isRes ? 0 : '12px',
    left: '50px',
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `10px solid ${color.secondaryCardBackground}`,
    transform: 'translateX(-50%)'
  },
  secSectionContainer: { display: 'flex', alignItems: 'center', mb: 8 },
  hLineSecondSection: {
    width: '85px',
    height: '85px',
    backgroundColor: color.secondaryCardBackground,
    transform: 'rotate(45deg)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '8px',
    mt: isRes && 2,
    zIndex: '100'
  },
  textInDiamondShape: {
    transform: 'rotate(-45deg)',
    color: color.tertiaryText,
    textAlign: 'center',
    position: 'absolute'
  },
  secondSecShape: {
    position: 'absolute',
    left: isRes ? '50px' : '100px',
    top: isRes ? '142px' : '43px',
    width: isRes ? '2px' : '50px',
    height: isRes ? '50px' : '2px',
    backgroundColor: color.secondaryCardBackground,
    transform: 'translateY(-50%)'
  },
  secSectionVarrow: {
    position: 'absolute',
    left: isRes ? '47px' : '150px',
    top: isRes ? '165px' : '43px',
    width: '0',
    height: '0',
    borderLeft: isRes ? '5px solid transparent' : `10px solid ${color.secondaryCardBackground}`,
    borderTop: isRes ? `10px solid ${color.secondaryCardBackground}` : '5px solid transparent',
    borderBottom: !isRes && '5px solid transparent',
    borderRight: isRes && '5px solid transparent',
    transform: 'translateY(-50%)'
  },
  lineToSecondToThird: {
    position: 'absolute',
    bottom: isRes ? '-30px' : 0,
    height: isRes ? '30px' : '100%',
    left: '50px',
    width: '2px',
    backgroundColor: color.secondaryCardBackground,
    transform: 'translateX(-50%)'
  },
  connectingArrow: {
    position: 'absolute',
    bottom: isRes ? '-40px' : 0,
    left: '50px',
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `10px solid ${color.secondaryCardBackground}`,
    transform: 'translateX(-50%)'
  },
  taskCirle: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: color.secondaryCardBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    mt: isRes && 5
  },
  taskConnectingLine: {
    position: 'absolute',
    left: isRes ? '50px' : '100px',
    top: isRes ? '160px' : '50px',
    width: isRes ? '2px' : '50px',
    height: isRes ? '50px' : '2px',
    backgroundColor: color.secondaryCardBackground,
    transform: 'translateY(-50%)'
  },
  taskConnectinArrow: {
    position: 'absolute',
    left: isRes ? '47px' : '150px',
    top: isRes ? '185px' : '50px',
    width: '0',
    height: '0',
    borderLeft: isRes ? '5px solid transparent' : `10px solid ${color.secondaryCardBackground}`,
    borderTop: isRes ? `10px solid ${color.secondaryCardBackground}` : '5px solid transparent',
    borderBottom: !isRes && '5px solid transparent',
    borderRight: isRes && '5px solid transparent',
    transform: 'translateY(-50%)'
  },
  taskFieldContainer: {
    width: isRes ? '100%' : 'calc(100% - 160px)',
    paddingLeft: !isRes && '60px',
    paddingTop: isRes && '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  taskFieldWraper: {
    border: `1px solid ${color.primaryBorder}`,
    minHeight: '100px',
    p: 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    width: '100%'
  },
  taskList: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  tskBtn: { width: isRes ? '100px' : '165px', height: isRes ? '32px' : '48px' },
  tskListCon: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: '4px' },
  tstListSub: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
  num: {
    width: '40px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: color.secondaryBackground,
    border: `1px solid ${color.primaryBorder}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mr: 1
  },
  iconsCon: {
    display: 'flex',
    justifyContent: 'space-between',
    width: defaultValues.isMobile ? '32%' : '12%'
  },
  width: { width: '90%' },
  popModal: {
    minWidth: defaultValues.isResponsive ? '80%' : '75%',
    left: defaultValues.isResponsive ? '50%' : '75%'
  },
  px: { px: 1 }
};
