import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addSocietyFailure,
  addSocietyRequest,
  addSocietySuccess,
  getAssociatedFarmerFailure,
  getAssociatedFarmerRequest,
  getAssociatedFarmerSuccess,
  getMilkSuppliedFailure,
  getMilkSuppliedRequest,
  getMilkSuppliedSuccess,
  getSocietyDetailsFailure,
  getSocietyDetailsRequest,
  getSocietyDetailsSuccess,
  getSocietyFailure,
  getSocietyRequest,
  getSocietySuccess,
  updateSocietyFailure,
  updateSocietyRequest,
  updateSocietySuccess
} from '../slices';
import {
  addSocietyAPI,
  getAsscociatedFarmerAPI,
  getMasterListAPI,
  getSocietyAPI,
  getSocietyDetailsAPI,
  updateSocietyAPI
} from '../../api';

function* addSociety(action) {
  try {
    const response = yield addSocietyAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addSocietySuccess(response.data));
    } else {
      yield put(addSocietyFailure(response.data));
    }
  } catch (error) {
    yield put(addSocietyFailure(error.response.data));
  }
}

function* updateSociety(action) {
  try {
    const response = yield updateSocietyAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateSocietySuccess(response.data));
    } else {
      yield put(updateSocietyFailure(response.data));
    }
  } catch (error) {
    yield put(updateSocietyFailure(error.response.data));
  }
}

function* getSociety(action) {
  try {
    const response = yield getSocietyAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getSocietySuccess(response.data));
    } else {
      yield put(getSocietyFailure(response.data));
    }
  } catch (error) {
    yield put(getSocietyFailure(error.response.data));
  }
}

function* getSocietyDetails(action) {
  try {
    const response = yield getSocietyDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getSocietyDetailsSuccess(response.data));
    } else {
      yield put(getSocietyDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getSocietyDetailsFailure(error.response.data));
  }
}

function* getMilkSupplied(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getMilkSuppliedSuccess(response.data));
    } else {
      yield put(getMilkSuppliedFailure(response.data));
    }
  } catch (error) {
    yield put(getMilkSuppliedFailure(error.response.data));
  }
}

function* getAssociatedFarmer(action) {
  try {
    const response = yield getAsscociatedFarmerAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getAssociatedFarmerSuccess(response.data));
    } else {
      yield put(getAssociatedFarmerFailure(response.data));
    }
  } catch (error) {
    yield put(getAssociatedFarmerFailure(error.response.data));
  }
}

export function* societySaga() {
  yield takeLatest(addSocietyRequest.type, addSociety);
  yield takeLatest(updateSocietyRequest.type, updateSociety);
  yield takeLatest(getSocietyRequest.type, getSociety);
  yield takeLatest(getSocietyDetailsRequest.type, getSocietyDetails);
  yield takeLatest(getMilkSuppliedRequest.type, getMilkSupplied);
  yield takeLatest(getAssociatedFarmerRequest.type, getAssociatedFarmer);
}
