/*
 * Add all the default values here which will be used in code
 * This may include page headers, dropdown options,
 * menu Options, contact details etc.
 */
import facebook from '../assets/icons/facebook-1.png';
import twitter from '../assets/icons/twitter.png';
import instagram from '../assets/icons/instagram.png';
import linkedin from '../assets/icons/linkedin.png';
import profile from '../assets/icons/profile.png';
import settings from '../assets/icons/settings.png';
import dashboard from '../assets/icons/dashboard.png';
import mTicket from '../assets/icons/ticket.png';
import mProcess from '../assets/icons/process.png';
import mFarmer from '../assets/icons/farmer.png';
import mSociety from '../assets/icons/society.png';
import mVet from '../assets/icons/vet.png';
import mCallCenter from '../assets/icons/call-center.png';
import mParavet from '../assets/icons/paravet.png';
import mInventory from '../assets/icons/inventory.png';
import mLaboratory from '../assets/icons/laborotary.png';
import mInsurance from '../assets/icons/insurance.png';
import govtOfficer from '../assets/icons/govt.png';
import mEmployee from '../assets/icons/employees.png';

import { getTitles } from '../utils/commonFunction';
import { SCREEN_PATH } from '../navigation/routes';

export const defaultValues = {
  isResponsive: window.innerWidth < 900,
  isMobile: window.innerWidth < 600,
  isMedium: window.innerWidth < 1025,
  isLarge: window.innerWidth < 1441,
  isXSmall: window.innerWidth < 281,
  isMdSmall: window.innerWidth < 400,

  noAuthToolbar: [
    { title: getTitles('T-10004'), url: '/' },
    { title: getTitles('T-10005'), url: '/' },
    { title: getTitles('T-10006'), url: '/' },
    { title: getTitles('T-10007'), url: '/' }
  ],

  appLogicalTexts: {
    login: 'login',
    phone: 'phone',
    language: 'language',
    authToken: 'authToken',
    userLogout: 'USER_LOGOUT',
    first_name: 'first_name',
    last_name: 'last_name',
    email_id: 'email_id',
    phone_number: 'phone_number',
    middle_name: 'middle_name',
    title: 'title',
    districts: 'districts',
    district: 'district',
    taluka: 'taluka',
    states: 'states',
    villages: 'villages',
    village: 'village',
    designation: 'designation',
    admin_roles: 'admin_roles',
    unknownError: 'E-10001',
    user_id: 'user_id',
    idSize: 'idSize',
    agreement: 'agreement',
    adhaar: 'adhaar',
    pancard: 'pancard',
    drivingLic: 'drivingLic',
    insurance: 'insurance',
    ration: 'ration',
    voterId: 'voterId',
    vehicle: 'vehicle',
    certificate: 'certificate',
    vet: 'vet',
    cattles: 'cattles',
    gopalan: 'gopalan',
    labs: 'labs',
    pathologist: 'pathologist',
    lab_tech: 'labTech',
    supportEmp: 'suppEmp',
    nabl: 'nabl',
    gcp: 'gcp',
    shops_act: 'shops_act',
    clinical_act: 'clinical_act',
    bwBody: 'bwBody',
    sPolBody: 'sPolBody',
    noc: 'noc',
    mNoc: 'mNoc',
    other: 'other',
    member: 'member',
    nonMember: 'non-member',
    employee: 'employee',
    laboratory: 'laboratory',
    society: 'society',
    govtOffice: 'govtOffice',
    process: 'process'
  },

  footer: {
    support: [
      { title: getTitles('T-10021'), url: '/' },
      { title: getTitles('T-10022'), url: '/' },
      { title: getTitles('T-10007'), url: '/' },
      { title: getTitles('T-10023'), url: SCREEN_PATH.POLICY },
      { title: getTitles('T-11391'), url: SCREEN_PATH.DISCLAIMER },
      { title: getTitles('T-11392'), url: SCREEN_PATH.SHIPPING_POLICY }
    ],
    services: [
      { title: getTitles('T-10024'), url: '/' },
      { title: getTitles('T-10025'), url: '/' },
      { title: getTitles('T-10026'), url: '/' },
      { title: getTitles('T-10026'), url: '/' },
      { title: getTitles('T-10026'), url: '/' }
    ],
    home: [
      { title: getTitles('T-10028'), url: '/' },
      { title: getTitles('T-10029'), url: '/' }
    ],
    social: [
      { icon: facebook, url: '/' },
      { icon: twitter, url: '/' },
      { icon: instagram, url: '/' },
      { icon: linkedin, url: '/' }
    ],
    phone: '+91 9000000000',
    email: 'care@mygopalan.com'
  },

  otpInput: {
    resendMsg: (time) => `${getTitles('T-10111')} ${time} ${getTitles('T-10112')}`
  },
  appLanguages: [
    { title: 'English', value: 'english' },
    { title: 'हिंदी', value: 'hindi' },
    { title: 'ಕನ್ನಡ', value: 'kannada' },
    { title: 'मराठी', value: 'marathi' }
  ],

  superAdminMenu: [
    {
      id: 1,
      icon: dashboard,
      menu: getTitles('T-10037'),
      route: SCREEN_PATH.DASHBOARD
    },
    { id: 2, icon: mTicket, menu: getTitles('T-10114'), role_id: [1, 3, 5, 6, 8, 11, 12, 13] },

    {
      id: 3,
      icon: mEmployee,
      menu: getTitles('T-10323'),
      route: SCREEN_PATH.MANAGE_EMPLOYEE,
      role_id: [13],
      type: 'employee'
    },
    {
      id: 4,
      icon: mProcess,
      menu: getTitles('T-10115'),
      route: SCREEN_PATH.MANAGE_PROCESS,
      role_id: [1],
      type: 'process'
    },
    {
      id: 5,
      icon: mFarmer,
      menu: getTitles('T-10117'),
      role_id: [3],
      route: SCREEN_PATH.MANAGE_FARMER
    },
    {
      id: 6,
      icon: mSociety,
      menu: getTitles('T-10118'),
      role_id: [4],
      route: SCREEN_PATH.MANAGE_SOCIETY,
      type: 'society'
    },

    { id: 7, icon: mCallCenter, menu: getTitles('T-10119'), role_id: [7] },
    {
      id: 8,
      icon: mParavet,
      menu: getTitles('T-10120'),
      role_id: [5],
      route: SCREEN_PATH.MANAGE_AI_WORKER,
      type: 'gopalan'
    },
    {
      id: 9,
      icon: mVet,
      menu: getTitles('T-10121'),
      role_id: [6],
      route: SCREEN_PATH.MANAGE_VET,
      type: 'vet'
    },
    { id: 10, icon: mInventory, menu: getTitles('T-10122'), role_id: [9] },
    {
      id: 11,
      icon: mLaboratory,
      menu: getTitles('T-10123'),
      role_id: [8],
      route: SCREEN_PATH.MANAGE_LABORATORY,
      type: 'laboratory'
    },
    {
      id: 12,
      icon: mInsurance,
      menu: getTitles('T-10124'),
      role_id: [12],
      route: SCREEN_PATH.MANAGE_INSURANCE,
      type: 'insurance'
    },
    {
      id: 13,
      icon: govtOfficer,
      menu: getTitles('T-10125'),
      role_id: [11],
      route: SCREEN_PATH.GOVT_OFFICIAL,
      type: 'govtOffice'
    },
    {
      id: 14,
      icon: mCallCenter,
      menu: getTitles('T-11235'),
      role_id: [17],
      route: SCREEN_PATH.CALL_CENTER
    },

    {
      id: 15,
      icon: profile,
      menu: getTitles('T-10126'),
      route: SCREEN_PATH.PROFILE
    },
    {
      id: 16,
      icon: mProcess,
      menu: getTitles('T-11383'),
      role_id: [17]
    },
    { id: 17, icon: settings, menu: getTitles('T-10127'), role_id: [17] }
  ],

  userRole: {
    superAdmin: 1,
    employee: 2,
    farmer: 3,
    vet: 4,
    aiWorker: 5,
    laboratory: 6,
    callCanterAdmin: 7,
    callCenterAgent: 8,
    govtOfficer: 9,
    Insurance: 10,
    ECommerceUsers: 11
  },
  userRoles: [
    { id: 1, role: 'Super Admin' },
    { id: 2, role: 'Admin' },
    { id: 3, role: 'Farmer' },
    { id: 4, role: 'Vet Doctor' },
    { id: 5, role: 'Para Vet' },
    { id: 6, role: 'Laboratory' },
    { id: 7, role: 'Call Center Admin' },
    { id: 8, role: 'Call Center Agent' },
    { id: 9, role: 'Govt Officials' },
    { id: 10, role: 'Insurance' },
    { id: 11, role: 'E-commerce Users' }
  ],
  employeeRol: {
    processManager: 1,
    cattleManager: 2,
    farmerManager: 3,
    societyManager: 4,
    paravetManager: 5,
    vetManager: 6,
    callCenterManager: 7,
    laboratoryManager: 8,
    inventoryManager: 9,
    eCommerceManager: 10,
    govtOfficeManager: 11,
    insuranceManager: 12,
    employeeManager: 13,
    vetDoctor: 14,
    aiWorker: 15,
    callCenterAdmin: 16,
    callCenterAgents: 17,
    laboratoryIncharge: 18,
    villageManager: 19
  },
  employeePermissions: [
    {
      title: getTitles('T-10128'),
      value: 1
    },
    {
      title: getTitles('T-10130'),
      value: 3
    },
    {
      title: getTitles('T-10131'),
      value: 4
    },
    {
      title: getTitles('T-10132'),
      value: 5
    },
    {
      title: getTitles('T-10133'),
      value: 6
    },
    {
      title: getTitles('T-10134'),
      value: 7
    },
    {
      title: getTitles('T-10135'),
      value: 8
    },
    {
      title: getTitles('T-10136'),
      value: 9
    },
    {
      title: getTitles('T-10137'),
      value: 10
    },
    {
      title: getTitles('T-10138'),
      value: 11
    },
    {
      title: getTitles('T-10139'),
      value: 12
    },
    {
      title: getTitles('T-10420'),
      value: 13
    },
    {
      value: 14,
      title: getTitles('T-10467')
    },
    {
      value: 15,
      title: getTitles('T-10468')
    },
    {
      value: 16,
      title: getTitles('T-10469')
    },
    {
      value: 17,
      title: getTitles('T-10470')
    },
    {
      value: 18,
      title: getTitles('T-10471')
    },
    {
      value: 19,
      title: getTitles('T-11386')
    }
  ],
  commonFilterOption: [
    {
      filterType: getTitles('T-10103'),
      value: 'is_active',
      option: [
        { name: getTitles('T-10107'), value: 'true' },
        { name: getTitles('T-10108'), value: 'false' }
      ]
    }
  ],

  farmerFilterOption: [
    {
      filterType: getTitles('T-10103'),
      value: 'approval_status',
      option: [
        { name: getTitles('T-10683'), value: 1 },
        { name: getTitles('T-10684'), value: 2 },
        { name: getTitles('T-10685'), value: 3 }
      ]
    }
  ],
  societyFilterOption: [
    {
      filterType: getTitles('T-10103'),
      value: 'is_active',
      option: [
        { name: getTitles('T-10107'), value: 'true' },
        { name: getTitles('T-10108'), value: 'false' }
      ]
    },
    {
      filterType: getTitles('T-10718'),
      value: 'society_type',
      option: [
        { name: getTitles('T-10719'), value: '1' },
        { name: getTitles('T-10720'), value: '2' },
        { name: getTitles('T-10721'), value: '3' }
      ]
    }
  ],

  employeeFilterOption: [
    {
      filterType: getTitles('T-10103'),
      value: 'platform_access',
      option: [
        { name: getTitles('T-10107'), value: 'true' },
        { name: getTitles('T-10108'), value: 'false' }
      ]
    }
  ],

  commonSortOptions: [
    { key: getTitles('T-10140'), value: [{ first_name: 'asc' }] },
    { key: getTitles('T-10141'), value: [{ first_name: 'desc' }] },
    { key: getTitles('T-10142'), value: [{ created_at: 'desc' }] },
    { key: getTitles('T-10143'), value: [{ created_at: 'asc' }] }
  ],
  manageProcess: {},
  master_type: [
    { id: 1, type: getTitles('T-10173') },
    { id: 2, type: getTitles('T-10174') },
    { id: 3, type: getTitles('T-10175') },
    { id: 4, type: getTitles('T-10176') },
    { id: 5, type: getTitles('T-10177') },
    { id: 6, type: getTitles('T-10178') },
    { id: 7, type: getTitles('T-10179') },
    { id: 8, type: getTitles('T-10180') },
    { id: 9, type: getTitles('T-10181') },
    { id: 10, type: getTitles('T-10182') },
    { id: 11, type: getTitles('T-10183') },
    { id: 12, type: getTitles('T-10184') },
    { id: 13, type: getTitles('T-10185') },
    { id: 14, type: getTitles('T-10186') },
    { id: 15, type: getTitles('T-10187') },
    { id: 16, type: getTitles('T-10106') },
    { id: 17, type: getTitles('T-10091') },
    { id: 18, type: getTitles('T-10309') },
    { id: 19, type: getTitles('T-10310') },
    { id: 20, type: getTitles('T-10841') },
    { id: 21, type: getTitles('T-10821') },
    { id: 22, type: getTitles('T-10826') },
    { id: 23, type: getTitles('T-10859') },
    { id: 24, type: getTitles('T-10827') },
    { id: 25, type: getTitles('T-10828') },
    { id: 26, type: getTitles('T-10829') },
    { id: 27, type: getTitles('T-10830') },
    { id: 28, type: getTitles('T-10831') },
    { id: 29, type: getTitles('T-10832') },
    { id: 30, type: getTitles('T-10833') },
    { id: 31, type: getTitles('T-10451') },
    { id: 32, type: getTitles('T-10834') },
    { id: 33, type: getTitles('T-10835') },
    { id: 34, type: getTitles('T-10836') },
    { id: 35, type: getTitles('T-10837') },
    { id: 36, type: getTitles('T-11219') },
    { id: 37, type: getTitles('T-11225') },
    { id: 38, type: getTitles('T-11106') },
    { id: 39, type: getTitles('T-11121') },
    { id: 40, type: getTitles('T-11125') },
    { id: 41, type: getTitles('T-10499') },
    { id: 42, type: getTitles('T-11248') }
  ],
  masterTypeLabels: [
    {},
    {
      id: 1,
      searchPlaceholder: getTitles('T-10189'),
      addTitle: getTitles('T-10190'),
      editTitle: getTitles('T-10191'),
      name: getTitles('T-10192'),
      placeholder: getTitles('T-10193'),
      title: getTitles('T-10194')
    },
    {
      id: 2,
      searchPlaceholder: getTitles('T-10195'),
      addTitle: getTitles('T-10196'),
      editTitle: getTitles('T-10197'),
      name: getTitles('T-10198'),
      placeholder: getTitles('T-10199'),
      title: getTitles('T-10200')
    },
    {
      id: 3,
      searchPlaceholder: getTitles('T-10201'),
      addTitle: getTitles('T-10202'),
      editTitle: getTitles('T-10203'),
      name: getTitles('T-10204'),
      placeholder: getTitles('T-10205'),
      title: getTitles('T-10206')
    },
    {
      id: 4,
      searchPlaceholder: getTitles('T-10207'),
      addTitle: getTitles('T-10208'),
      editTitle: getTitles('T-10209'),
      name: getTitles('T-10210'),
      placeholder: getTitles('T-10211'),
      title: getTitles('T-10212')
    },
    {
      id: 5,
      searchPlaceholder: getTitles('T-10213'),
      addTitle: getTitles('T-10214'),
      editTitle: getTitles('T-10215'),
      name: getTitles('T-10216'),
      placeholder: getTitles('T-10217'),
      title: getTitles('T-10218')
    },
    {
      id: 6,
      searchPlaceholder: getTitles('T-10219'),
      addTitle: getTitles('T-10220'),
      editTitle: getTitles('T-10221'),
      name: getTitles('T-10178'),
      placeholder: getTitles('T-10223'),
      title: getTitles('T-10224')
    },
    {
      id: 7,
      searchPlaceholder: getTitles('T-10225'),
      addTitle: getTitles('T-10226'),
      editTitle: getTitles('T-10227'),
      name: getTitles('T-10228'),
      placeholder: getTitles('T-10229'),
      title: getTitles('T-10230')
    },
    {
      id: 8,
      searchPlaceholder: getTitles('T-10231'),
      addTitle: getTitles('T-10232'),
      editTitle: getTitles('T-10233'),
      name: getTitles('T-10180'),
      placeholder: getTitles('T-10234'),
      title: getTitles('T-10235')
    },
    {
      id: 9,
      searchPlaceholder: getTitles('T-10236'),
      addTitle: getTitles('T-10237'),
      editTitle: getTitles('T-10238'),
      name: getTitles('T-10181'),
      placeholder: getTitles('T-10240'),
      title: getTitles('T-10241')
    },
    {
      id: 10,
      searchPlaceholder: getTitles('T-10242'),
      addTitle: getTitles('T-10243'),
      editTitle: getTitles('T-10244'),
      name: getTitles('T-10182'),
      placeholder: getTitles('T-10246'),
      title: getTitles('T-10247')
    },
    {
      id: 11,
      searchPlaceholder: getTitles('T-10248'),
      addTitle: getTitles('T-10249'),
      editTitle: getTitles('T-10250'),
      name: getTitles('T-10183'),
      placeholder: getTitles('T-10252'),
      title: getTitles('T-10253')
    },
    {
      id: 12,
      searchPlaceholder: getTitles('T-10254'),
      addTitle: getTitles('T-10255'),
      editTitle: getTitles('T-10256'),
      name: getTitles('T-10257'),
      placeholder: getTitles('T-10258'),
      title: getTitles('T-10259')
    },
    {
      id: 13,
      searchPlaceholder: getTitles('T-10260'),
      addTitle: getTitles('T-10261'),
      editTitle: getTitles('T-10262'),
      name: getTitles('T-10185'),
      placeholder: getTitles('T-10264'),
      title: getTitles('T-10265')
    },
    {
      id: 14,
      searchPlaceholder: getTitles('T-10266'),
      addTitle: getTitles('T-10267'),
      editTitle: getTitles('T-10268'),
      name: getTitles('T-10269'),
      placeholder: getTitles('T-10270'),
      title: getTitles('T-10271')
    },
    {
      id: 15,
      searchPlaceholder: getTitles('T-10272'),
      addTitle: getTitles('T-10273'),
      editTitle: getTitles('T-10274'),
      name: getTitles('T-10187'),
      placeholder: getTitles('T-10276'),
      title: getTitles('T-10277')
    },
    {
      id: 16,
      searchPlaceholder: getTitles('T-10278'),
      addTitle: getTitles('T-10279'),
      editTitle: getTitles('T-10280'),
      name: getTitles('T-10106'),
      placeholder: getTitles('T-10222'),
      title: getTitles('T-10239')
    },
    {
      id: 17,
      searchPlaceholder: getTitles('T-10245'),
      addTitle: getTitles('T-10251'),
      editTitle: getTitles('T-10263'),
      name: getTitles('T-10091'),
      placeholder: getTitles('T-10281'),
      title: getTitles('T-10275')
    },
    {
      id: 18,
      searchPlaceholder: getTitles('T-10311'),
      addTitle: getTitles('T-10312'),
      editTitle: getTitles('T-10313'),
      name: getTitles('T-10314'),
      placeholder: getTitles('T-10315'),
      title: getTitles('T-10316')
    },
    {
      id: 19,
      searchPlaceholder: getTitles('T-10317'),
      addTitle: getTitles('T-10318'),
      editTitle: getTitles('T-10319'),
      name: getTitles('T-10320'),
      placeholder: getTitles('T-10321'),
      title: getTitles('T-10322')
    },
    {
      id: 20,
      searchPlaceholder: getTitles('T-10838'),
      addTitle: getTitles('T-10839'),
      editTitle: getTitles('T-10840'),
      name: getTitles('T-10841'),
      placeholder: getTitles('T-10842'),
      title: getTitles('T-10843')
    },

    {
      id: 21,
      searchPlaceholder: getTitles('T-10844'),
      addTitle: getTitles('T-10845'),
      editTitle: getTitles('T-10846'),
      name: getTitles('T-10847'),
      placeholder: getTitles('T-10848'),
      title: getTitles('T-10849')
    },
    {
      id: 22,
      searchPlaceholder: getTitles('T-10850'),
      addTitle: getTitles('T-10851'),
      editTitle: getTitles('T-10852'),
      name: getTitles('T-10853'),
      placeholder: getTitles('T-10854'),
      title: getTitles('T-10855')
    },
    {
      id: 23,
      searchPlaceholder: getTitles('T-10856'),
      addTitle: getTitles('T-10857'),
      editTitle: getTitles('T-10858'),
      name: getTitles('T-10859'),
      placeholder: getTitles('T-10860'),
      title: getTitles('T-10861')
    },
    {
      id: 24,
      searchPlaceholder: getTitles('T-10862'),
      addTitle: getTitles('T-10863'),
      editTitle: getTitles('T-10864'),
      name: getTitles('T-10865'),
      placeholder: getTitles('T-10866'),
      title: getTitles('T-10867')
    },
    {
      id: 25,
      searchPlaceholder: getTitles('T-10868'),
      addTitle: getTitles('T-10869'),
      editTitle: getTitles('T-10870'),
      name: getTitles('T-10871'),
      placeholder: getTitles('T-10872'),
      title: getTitles('T-10873')
    },
    {
      id: 26,
      searchPlaceholder: getTitles('T-10874'),
      addTitle: getTitles('T-10875'),
      editTitle: getTitles('T-10876'),
      name: getTitles('T-10877'),
      placeholder: getTitles('T-10878'),
      title: getTitles('T-10879')
    },
    {
      id: 27,
      searchPlaceholder: getTitles('T-10880'),
      addTitle: getTitles('T-10881'),
      editTitle: getTitles('T-10882'),
      name: getTitles('T-10883'),
      placeholder: getTitles('T-10884'),
      title: getTitles('T-10885')
    },
    {
      id: 28,
      searchPlaceholder: getTitles('T-10886'),
      addTitle: getTitles('T-10887'),
      editTitle: getTitles('T-10888'),
      name: getTitles('T-10889'),
      placeholder: getTitles('T-10890'),
      title: getTitles('T-10891')
    },
    {
      id: 29,
      searchPlaceholder: getTitles('T-10892'),
      addTitle: getTitles('T-10893'),
      editTitle: getTitles('T-10894'),
      name: getTitles('T-10895'),
      placeholder: getTitles('T-10896'),
      title: getTitles('T-10897')
    },
    {
      id: 30,
      searchPlaceholder: getTitles('T-10898'),
      addTitle: getTitles('T-10899'),
      editTitle: getTitles('T-10900'),
      name: getTitles('T-10901'),
      placeholder: getTitles('T-10902'),
      title: getTitles('T-10903')
    },
    {
      id: 31,
      searchPlaceholder: getTitles('T-10904'),
      addTitle: getTitles('T-10905'),
      editTitle: getTitles('T-10906'),
      name: getTitles('T-10907'),
      placeholder: getTitles('T-10908'),
      title: getTitles('T-10909')
    },
    {
      id: 32,
      searchPlaceholder: getTitles('T-10910'),
      addTitle: getTitles('T-10911'),
      editTitle: getTitles('T-10912'),
      name: getTitles('T-10913'),
      placeholder: getTitles('T-10914'),
      title: getTitles('T-10915')
    },
    {
      id: 33,
      searchPlaceholder: getTitles('T-10916'),
      addTitle: getTitles('T-10917'),
      editTitle: getTitles('T-10918'),
      name: getTitles('T-10919'),
      placeholder: getTitles('T-10920'),
      title: getTitles('T-10921')
    },
    {
      id: 34,
      searchPlaceholder: getTitles('T-10922'),
      addTitle: getTitles('T-10923'),
      editTitle: getTitles('T-10924'),
      name: getTitles('T-10925'),
      placeholder: getTitles('T-10926'),
      title: getTitles('T-10927')
    },
    {
      id: 35,
      searchPlaceholder: getTitles('T-10928'),
      addTitle: getTitles('T-10929'),
      editTitle: getTitles('T-10930'),
      name: getTitles('T-10931'),
      placeholder: getTitles('T-10932'),
      title: getTitles('T-10933')
    },
    {
      id: 36,
      searchPlaceholder: getTitles('T-11216'),
      addTitle: getTitles('T-11217'),
      editTitle: getTitles('T-11218'),
      name: getTitles('T-11219'),
      placeholder: getTitles('T-11220'),
      title: getTitles('T-11221')
    },
    {
      id: 37,
      searchPlaceholder: getTitles('T-11222'),
      addTitle: getTitles('T-11223'),
      editTitle: getTitles('T-11224'),
      name: getTitles('T-11225'),
      placeholder: getTitles('T-11226'),
      title: getTitles('T-11227')
    },
    {
      id: 38,
      searchPlaceholder: getTitles('T-11107'),
      addTitle: getTitles('T-11108'),
      editTitle: getTitles('T-11109'),
      name: getTitles('T-11106'),
      placeholder: getTitles('T-11111'),
      title: getTitles('T-11112')
    },
    {
      id: 39,
      searchPlaceholder: getTitles('T-11118'),
      addTitle: getTitles('T-11119'),
      editTitle: getTitles('T-11120'),
      name: getTitles('T-11121'),
      placeholder: getTitles('T-11122'),
      title: getTitles('T-11123')
    },
    {
      id: 40,
      searchPlaceholder: getTitles('T-11126'),
      addTitle: getTitles('T-11127'),
      editTitle: getTitles('T-11128'),
      name: getTitles('T-11129'),
      placeholder: getTitles('T-11130'),
      title: getTitles('T-11131')
    },
    {
      id: 41,
      searchPlaceholder: getTitles('T-11132'),
      addTitle: getTitles('T-11133'),
      editTitle: getTitles('T-11134'),
      name: getTitles('T-11135'),
      placeholder: getTitles('T-11136'),
      title: getTitles('T-11137')
    },
    {
      id: 42,
      searchPlaceholder: getTitles('T-11370'),
      addTitle: getTitles('T-11371'),
      editTitle: getTitles('T-11372'),
      name: getTitles('T-11248'),
      placeholder: getTitles('T-11373'),
      title: getTitles('T-11369')
    }
  ],
  masterListType: {
    diseaseGroup: 16,
    diseaseType: 17,
    department: 19,
    designation: 18,
    milk_supplied: 32
  },
  confirmationModel: {
    subTitle: getTitles('T-10284'),
    changeAdminStatus: {
      title: (status) => (status ? getTitles('T-10282') : getTitles('T-10283')),
      subtitles: getTitles('T-10284')
    },
    changeLanguageStatus: {
      title: (status) => (status ? getTitles('T-10285') : getTitles('T-10286')),
      subtitles: getTitles('T-10284')
    },
    changeDiseaseStatus: {
      title: (status) => (status ? getTitles('T-10287') : getTitles('T-10288')),
      subtitles: getTitles('T-10284')
    },
    changeMasterListStatus: {
      title: (status) => (status ? getTitles('T-10289') : getTitles('T-10290')),
      subtitles: getTitles('T-10284')
    },
    changeStateStatus: {
      title: (status) => (status ? getTitles('T-10291') : getTitles('T-10292')),
      subtitles: getTitles('T-10284')
    },
    changeDistrictStatus: {
      title: (status) => (status ? getTitles('T-10293') : getTitles('T-10294')),
      subtitles: getTitles('T-10284')
    },
    changeTalukStatus: {
      title: (status) => (status ? getTitles('T-10295') : getTitles('T-10296')),
      subtitles: getTitles('T-10284')
    },
    changeVillageStatus: {
      title: (status) => (status ? getTitles('T-10297') : getTitles('T-10298')),
      subtitles: getTitles('T-10284')
    },
    changeEmployeeStatus: {
      title: (status) => (status ? getTitles('T-10328') : getTitles('T-10329')),
      subtitles: getTitles('T-10284')
    },
    changeVaccineStatus: {
      title: (status) => (status ? getTitles('T-10466') : getTitles('T-10465')),
      subtitles: getTitles('T-10284')
    },
    changeVetStatus: {
      title: (status) => (status ? getTitles('T-10485') : getTitles('T-10484')),
      subtitles: getTitles('T-10284')
    },
    changeAIWorkerStatus: {
      title: (status) => (status ? getTitles('T-10588') : getTitles('T-10587')),
      subtitles: getTitles('T-10284')
    },
    changeFarmerApprovalStatus: {
      title: (status) =>
        status === defaultValues.approvalStatus.approved
          ? getTitles('T-10730')
          : getTitles('T-10731'),
      subtitles: getTitles('T-10284')
    },
    changeSocietyApprovalStatus: {
      title: (status) =>
        status === defaultValues.approvalStatus.approved
          ? getTitles('T-11000')
          : getTitles('T-11001'),
      subtitles: getTitles('T-10284')
    },
    changeSocietyStatus: {
      title: (status) => (status ? getTitles('T-11002') : getTitles('T-11003')),
      subtitles: getTitles('T-10284')
    },
    changeLaboratoryStatus: {
      title: (status) => (status ? getTitles('T-11104') : getTitles('T-11105'))
    },
    changeCattleApprovalStatus: {
      title: (status) => (status === 2 ? getTitles('T-11063') : getTitles('T-11064')),
      subtitles: getTitles('T-10284')
    },
    changeCattleStatus: {
      title: (status) => (status ? getTitles('T-11065') : getTitles('T-11066')),
      subtitles: getTitles('T-10284')
    },
    changeInsuranceStatus: {
      title: (status) => (status ? getTitles('T-11144') : getTitles('T-11145'))
    },
    changeGovtOfficialStatus: {
      title: (status) => (status ? getTitles('T-11152') : getTitles('T-11153'))
    },
    changeProfilingStatus: {
      title: (status) => (status ? getTitles('T-11356') : getTitles('T-11357')),
      subtitles: getTitles('T-10284')
    }
  },

  genderOptions: [
    { value: '1', title: getTitles('T-10345') },
    { value: '2', title: getTitles('T-10346') },
    { value: '3', title: getTitles('T-10347') }
  ],
  maritalStatus: [
    { value: '1', title: getTitles('T-10474') },
    { value: '2', title: getTitles('T-10348') },
    { value: '3', title: getTitles('T-10475') },
    { value: '4', title: getTitles('T-10476') }
  ],
  modeOfEmployment: [
    { value: '1', title: getTitles('T-10388') },
    { value: '2', title: getTitles('T-10389') },
    { value: '3', title: getTitles('T-10390') }
  ],
  employmentSector: [
    { value: '1', title: getTitles('T-10391') },
    { value: '2', title: getTitles('T-10392') }
  ],
  countriesList: [{ value: 'India', title: getTitles('T-10417') }],
  nationalityOptions: [{ value: 'Indian', title: getTitles('T-10418') }],

  personalInsurane: [
    { title: getTitles('T-10705'), value: true },
    { title: getTitles('T-10706'), value: false }
  ],

  bpl: [
    {
      id: 1,
      name: getTitles('T-10591')
    },
    {
      id: 2,
      name: getTitles('T-10592')
    }
  ],
  communicationMode: [
    {
      value: 1,
      title: getTitles('T-10593')
    },
    {
      value: 2,
      title: getTitles('T-10594')
    },
    {
      value: 3,
      title: getTitles('T-10595')
    }
  ],
  orderDeliveryTime: [
    { value: 1, title: getTitles('T-10596') },
    { value: 2, title: getTitles('T-10597') },
    { value: 3, title: getTitles('T-10598') },
    { value: 4, title: getTitles('T-10599') },
    { value: 5, title: getTitles('T-10600') }
  ],
  chopperType: [
    { value: 1, title: getTitles('T-10601') },
    { value: 2, title: getTitles('T-10602') }
  ],
  reasonsOfMineralMixtureBrand: [
    { value: 1, title: getTitles('T-10603') },
    { value: 2, title: getTitles('T-10604') },
    { value: 3, title: getTitles('T-10605') },
    { value: 4, title: getTitles('T-10606') }
  ],
  preferredOrderMode: [
    { value: 1, title: getTitles('T-10607') },
    { value: 2, title: getTitles('T-10608') },
    { value: 3, title: getTitles('T-10609') },
    { value: 4, title: getTitles('T-10610') }
  ],
  breedingService: [
    { value: 1, title: getTitles('T-10611') },
    { value: 2, title: getTitles('T-10612') }
  ],
  aiProvider: [
    { value: 1, title: getTitles('T-10613') },
    { value: 2, title: getTitles('T-10614') },
    { value: 3, title: getTitles('T-10615') },
    { value: 4, title: getTitles('T-10616') }
  ],
  reasonsForOtherAi: [
    { value: 1, title: getTitles('T-10617') },
    { value: 2, title: getTitles('T-10618') },
    { value: 3, title: getTitles('T-10619') },
    { value: 4, title: getTitles('T-10620') }
  ],
  semenBreedingRecommendation: [
    { value: 1, title: getTitles('T-10621') },
    { value: 2, title: getTitles('T-10622') },
    { value: 3, title: getTitles('T-10623') }
  ],
  cattleCapacity: [
    { value: 1, title: getTitles('T-10624') },
    { value: 2, title: getTitles('T-10625') },
    { value: 3, title: getTitles('T-10626') },
    { value: 4, title: getTitles('T-10627') },
    { value: 5, title: getTitles('T-10628') },
    { value: 6, title: getTitles('T-10629') },
    { value: 7, title: getTitles('T-10630') }
  ],
  cropsType: [
    { value: 1, title: getTitles('T-10631') },
    { value: 2, title: getTitles('T-10632') }
  ],
  relationship: [
    { value: 1, title: getTitles('T-10633') },
    { value: 2, title: getTitles('T-10634') },
    { value: 3, title: getTitles('T-10635') },
    { value: 4, title: getTitles('T-10636') },
    { value: 5, title: getTitles('T-10637') },
    { value: 6, title: getTitles('T-10638') },
    { value: 7, title: getTitles('T-10639') }
  ],
  actionType: {
    view: 'view',
    edit: 'edit',
    add: 'add',
    remove: 'remove',
    viewOnly: 'viewOnly'
  },
  mListIds: {
    cattle: 1,
    calf: 2,
    calfBreeds: 3,
    buffaloBreeds: 4,
    horn: 5,
    color: 6,
    forehead: 7,
    award: 8,
    sourceOfFodder: 9,
    quantityUnits: 10,
    sourceOfWater: 11,
    foodBrands: 12,
    substituteFood: 13,
    mineralMixtureBrands: 14,
    cattleSourceWater: 15,
    diseaseGroup: 16,
    diseaseType: 17,
    designation: 18,
    department: 19,
    rationCardColor: 20,
    crops: 21,
    agricultureSourceWater: 22,
    feedBrand: 23,
    reasonsForOtherFeedBrand: 24,
    feedAlternatives: 25,
    reasonsForOtherMineralMixtureBrand: 26,
    preferredModeOfOrderingFeeds: 27,
    heatSymptomsQuestion: 28,
    aiWorkerExperience: 29,
    training: 30,
    occupation: 31,
    milkSupplied: 32,
    tailSwitch: 33,
    fodderType: 34,
    cattleBreeds: 35,
    aiCenterRunner: 36,
    sourceOfIrrigationWater: 37,
    labTests: 38,
    insuranceCompany: 39,
    subscription: 40,
    policyProvider: 41
  },
  permissionsIds: {
    ProcessManager: 1,
    CattleManager: 2,
    FarmerManager: 3,
    SocietyManager: 4,
    AIWorkersManager: 5,
    VetManager: 6,
    CallCenterManager: 7,
    LaboratoryManager: 8,
    InventoryManager: 9,
    EcommerceManager: 10,
    GovtOfficeManager: 11,
    InsuranceManager: 12,
    EmployeeManager: 13,
    VetDoctor: 14,
    ParaVet: 15,
    CallCenterAdmin: 16,
    CallCenterAgents: 17,
    LaboratoryIncharge: 18
  },
  approvalStatus: {
    pending: 1,
    approved: 2,
    rejected: 3
  },

  educationProgram: [
    { value: '1', title: getTitles('T-10389') },
    { value: '2', title: getTitles('T-10666') }
  ],

  relationshipOptions: [
    { value: '1', title: getTitles('T-10698') },
    { value: '2', title: getTitles('T-10699') },
    { value: '3', title: getTitles('T-10700') },
    { value: '4', title: getTitles('T-10701') },
    { value: '5', title: getTitles('T-10702') },
    { value: '6', title: getTitles('T-10703') },
    { value: '7', title: getTitles('T-10704') }
  ],

  insuredOptions: [
    { value: true, title: getTitles('T-10705') },
    { value: false, title: getTitles('T-10706') }
  ],
  societyTypes: [
    { value: 1, title: getTitles('T-10719') },
    { value: 2, title: getTitles('T-10720') },
    { value: 3, title: getTitles('T-10721') }
  ],
  preferedContact: {
    whatsApp: 1,
    app: 2,
    phone: 3
  },
  societyStatus: {
    member: 1,
    nonMember: 2,
    proposed: 3
  },
  societyType: {
    general: 1,
    women: 2,
    branch: 3
  },
  operatedBuilding: {
    own: 1,
    rented: 2
  },
  societyRegisterSteps: {
    societyDetails: 1,
    operationalDetails: 2,
    milkAndFeedDetails: 3,
    additionalDetails: 4
  },
  farmerSortOptions: [
    { key: getTitles('T-10140'), value: 'asc' },
    { key: getTitles('T-10141'), value: 'desc' },
    { key: getTitles('T-10142'), value: 'latest' },
    { key: getTitles('T-10143'), value: 'oldest' }
  ],
  modeOfPayment: [
    { value: 1, title: getTitles('T-11057') },
    { value: 2, title: getTitles('T-11056') },
    { value: 3, title: getTitles('T-11055') },
    { value: 4, title: getTitles('T-11054') },
    { value: 5, title: getTitles('T-11053') }
  ],
  stateCow: [
    { value: 1, title: getTitles('T-11058') },
    { value: 2, title: getTitles('T-11059') },
    { value: 3, title: getTitles('T-11060') }
  ],
  mobileNetworks: [
    { value: 1, title: getTitles('T-10820') },
    { value: 2, title: getTitles('T-10822') },
    { value: 3, title: getTitles('T-10823') },
    { value: 4, title: getTitles('T-10824') }
  ],
  dairyRunners: [
    { value: 1, title: getTitles('T-10391') },
    { value: 2, title: getTitles('T-10951') },
    { value: 3, title: getTitles('T-10392') },
    { value: 4, title: getTitles('T-10615') }
  ],
  villageRegisterSteps: {
    villageDetails: 1,
    aiCenterDetails: 2,
    additionalDetails: 3
  },
  aiworkerScreen: {
    viewAiScr: 1,
    assVetScr: 2,
    unassVetScr: 3,
    assCattleScr: 4,
    unAssCatScr: 5,
    assignedLabScr: 6,
    unassignedLabScr: 7
  },
  vetScreen: {
    viewVetScr: 1,
    assignedGopalanScr: 2,
    unassignedGopalanScr: 3,
    assignedLabScr: 4,
    unassignedLabScr: 5
  },
  labRegisterSteps: {
    labDetails: 1,
    employeeDetails: 2,
    documentDetails: 3
  },
  cattleSortOptions: [
    { key: getTitles('T-10140'), value: 'asc' },
    { key: getTitles('T-10141'), value: 'desc' },
    { key: getTitles('T-10142'), value: 'latest' },
    { key: getTitles('T-10143'), value: 'oldest' }
  ],
  memberReasonsForCallOptions: [
    { value: 1, title: getTitles('T-10024') },
    { value: 2, title: getTitles('T-11273') },
    { value: 3, title: getTitles('T-10610') }
  ],
  memberCattleConcernsOptions: [
    { value: 1, title: getTitles('T-10612') },
    { value: 2, title: getTitles('T-11274') },
    { value: 3, title: getTitles('T-11275') },
    { value: 4, title: getTitles('T-11276') },
    { value: 5, title: getTitles('T-10610') }
  ],
  reasonForCallValues: {
    cattleMan: 1,
    admin: 2,
    reasonsOthers: 3
  },
  memberConcernsValues: {
    ai: 1,
    vo: 2,
    addCattle: 3,
    delCattle: 4,
    others: 5
  },
  memberAdminConcernsOptions: [
    { value: 1, title: getTitles('T-10833') },
    { value: 2, title: getTitles('T-11283') },
    { value: 3, title: getTitles('T-11284') },
    { value: 4, title: getTitles('T-11285') },
    { value: 5, title: getTitles('T-11286') },
    { value: 6, title: getTitles('T-11287') },
    { value: 7, title: getTitles('T-11288') },
    { value: 8, title: getTitles('T-10610') }
  ],
  nonMemberReasonsForCallOptions: [
    { value: 1, title: getTitles('T-11301') },
    { value: 2, title: getTitles('T-10610') }
  ],
  nonMemberReasonForCallValues: {
    enquiry: 1,
    others: 2
  },
  callCenterCurrTabs: {
    myTask: 0,
    allTask: 1,
    allCallHis: 2,
    callerTask: 3,
    callerCallHis: 4
  },
  actionFlowOptions: [
    { value: 1, title: getTitles('T-10173') },
    { value: 2, title: getTitles('T-11162') },
    { value: 3, title: getTitles('T-11163') },
    { value: 4, title: getTitles('T-11161') },
    { value: 5, title: getTitles('T-10951') }
  ],
  ruleOptions: [
    { value: 1, title: getTitles('T-11170') },
    { value: 2, title: getTitles('T-10084') },
    { value: 3, title: getTitles('T-11171') }
  ],
  ruleType: [
    { title: getTitles('T-11186'), value: 1 },
    { title: getTitles('T-11187'), value: 2 }
  ],
  conditionSubject: [
    { title: getTitles('T-11102'), type: 'date', value: 1 },
    { title: getTitles('T-10192'), type: 'text', value: 2 },
    { title: getTitles('T-10344'), type: 'date', value: 3 },
    { title: getTitles('T-10450'), type: 'number', value: 4 },
    { title: getTitles('T-11175'), type: 'number', value: 5 },
    { title: getTitles('T-11176'), type: 'text', value: 6 },
    { title: getTitles('T-11177'), type: 'date', value: 7 },
    { title: getTitles('T-11178'), type: 'date', value: 8 },
    { title: getTitles('T-11041'), type: 'text', value: 9 },
    { title: getTitles('T-11179'), type: 'text', value: 10 },
    { title: getTitles('T-11125'), type: 'text', value: 11 },
    { title: getTitles('T-10836'), type: 'text', value: 12 },
    { title: getTitles('T-11366'), type: 'number', value: 13 },
    { title: getTitles('T-11180'), type: 'text', value: 14 },
    { title: getTitles('T-11181'), type: 'number', value: 15 },
    { title: getTitles('T-11182'), type: 'number', value: 16 },
    { title: getTitles('T-11183'), type: 'date', value: 17 },
    { title: getTitles('T-11014'), type: 'text', value: 18 },
    { title: getTitles('T-10734'), type: 'text', value: 19 },
    { title: getTitles('T-11121'), type: 'text', value: 20 },
    { title: getTitles('T-11184'), type: 'date', value: 21 }
  ],

  conditionSubjectFarmer: [
    {
      value: 1,
      title: getTitles('T-11326'),
      type: 'date'
    },
    {
      value: 2,
      title: getTitles('T-10488'),
      type: 'text'
    },
    {
      value: 3,
      title: getTitles('T-10489'),
      type: 'text'
    },
    {
      value: 4,
      title: getTitles('T-10490'),
      type: 'text'
    },
    {
      value: 5,
      title: getTitles('T-10451'),
      type: 'text'
    },
    {
      value: 6,
      title: getTitles('T-10491'),
      type: 'text'
    },
    {
      value: 7,
      title: getTitles('T-10492'),
      type: 'boolean'
    },
    {
      value: 8,
      title: getTitles('T-10493'),
      type: 'text'
    },
    {
      value: 9,
      title: getTitles('T-11327'),
      type: 'text'
    },
    {
      value: 10,
      title: getTitles('T-10734'),
      type: 'text'
    },
    {
      value: 11,
      title: getTitles('T-11328'),
      type: 'boolean'
    },
    {
      value: 12,
      title: getTitles('T-10536'),
      type: 'number'
    },
    {
      value: 13,
      title: getTitles('T-11329'),
      type: 'boolean'
    }
  ],

  conditionSubjectEmployee: [
    {
      value: 1,
      title: getTitles('T-11326'),
      type: 'date'
    },
    {
      value: 2,
      title: getTitles('T-10375'),
      type: 'number'
    },
    {
      value: 3,
      title: getTitles('T-10734'),
      type: 'text'
    },
    {
      value: 4,
      title: getTitles('T-10050'),
      type: 'text'
    },
    {
      value: 5,
      title: getTitles('T-10320'),
      type: 'text'
    },
    {
      value: 6,
      title: getTitles('T-10378'),
      type: 'date'
    },
    {
      value: 7,
      title: getTitles('T-10455'),
      type: 'text'
    },
    {
      value: 8,
      title: getTitles('T-11172'),
      type: 'boolean'
    },
    {
      value: 9,
      title: getTitles('T-11330'),
      type: 'boolean'
    },
    {
      value: 10,
      title: getTitles('T-10456'),
      type: 'text'
    },
    {
      value: 11,
      title: getTitles('T-10463'),
      type: 'date'
    }
  ],
  conditionSubjectLab: [
    {
      value: 1,
      title: getTitles('T-11326'),
      type: 'date'
    },
    {
      value: 2,
      title: getTitles('T-11078'),
      type: 'text'
    },
    {
      value: 3,
      title: getTitles('T-11331'),
      type: 'text'
    },
    {
      value: 4,
      title: getTitles('T-11332'),
      type: 'date'
    },
    {
      value: 5,
      title: getTitles('T-11333'),
      type: 'date'
    },
    {
      value: 6,
      title: getTitles('T-11374'),
      type: 'date'
    },
    {
      value: 7,
      title: getTitles('T-11375'),
      type: 'date'
    },
    {
      value: 8,
      title: getTitles('T-11376'),
      type: 'date'
    },
    {
      value: 9,
      title: getTitles('T-11377'),
      type: 'date'
    },
    {
      value: 10,
      title: getTitles('T-11378'),
      type: 'date'
    },
    {
      value: 11,
      title: getTitles('T-11379'),
      type: 'date'
    },
    {
      value: 12,
      title: getTitles('T-11380'),
      type: 'date'
    },
    {
      value: 13,
      title: getTitles('T-11381'),
      type: 'date'
    },
    {
      value: 14,
      title: getTitles('T-11334'),
      type: 'date'
    }
  ],
  conditionSubjectSociety: [
    {
      value: 1,
      title: getTitles('T-11326'),
      type: 'date'
    },
    {
      value: 2,
      title: getTitles('T-10714'),
      type: 'text'
    },
    {
      value: 3,
      title: getTitles('T-10718'),
      type: 'text'
    },
    {
      value: 4,
      title: getTitles('T-11327'),
      type: 'text'
    },
    {
      value: 5,
      title: getTitles('T-10734'),
      type: 'text'
    },
    {
      value: 6,
      title: getTitles('T-10737'),
      type: 'number'
    },
    {
      value: 7,
      title: getTitles('T-10738'),
      type: 'number'
    },
    {
      value: 8,
      title: getTitles('T-11335'),
      type: 'number'
    },
    {
      value: 9,
      title: getTitles('T-10779'),
      type: 'boolean'
    },
    {
      value: 10,
      title: getTitles('T-11336'),
      type: 'date'
    },
    {
      value: 11,
      title: getTitles('T-11337'),
      type: 'date'
    },
    {
      value: 12,
      title: getTitles('T-10746'),
      type: 'boolean'
    },
    {
      value: 13,
      title: getTitles('T-10747'),
      type: 'number'
    }
  ],
  conditionForNumber: [
    { title: '=', value: 1 },
    { title: '!=', value: 2 },
    { title: '>', value: 3 },
    { title: '<', value: 4 },
    { title: '>=', value: 5 },
    { title: '<=', value: 6 },
    { title: getTitles('T-11190'), value: 7 },
    { title: getTitles('T-11191'), value: 8 }
  ],
  conditionForBoolean: [{ title: '=', value: 1 }],
  conditionsForString: [
    {
      title: getTitles('T-11192'),
      value: 1
    },
    {
      title: getTitles('T-11193'),
      value: 2
    },
    {
      title: getTitles('T-11194'),
      value: 3
    },
    {
      title: getTitles('T-11195'),
      value: 4
    },
    {
      title: getTitles('T-11196'),
      value: 5
    }
  ],
  conditionForDate: [
    { title: '=', value: 1 },
    { title: '!=', value: 2 },
    { title: '>', value: 3 },
    { title: '<', value: 4 },
    { title: '>=', value: 5 },
    { title: '<=', value: 6 },
    { title: getTitles('T-11253'), value: 7 },
    { title: getTitles('T-11254'), value: 8 },
    { title: getTitles('T-11255'), value: 9 },
    { title: getTitles('T-11256'), value: 10 },
    { title: getTitles('T-11257'), value: 11 },
    { title: getTitles('T-11258'), value: 12 },
    { title: getTitles('T-11259'), value: 13 },
    { title: getTitles('T-11260'), value: 14 }
  ],
  cattleTriggerType: [
    { title: getTitles('T-10344'), value: 1 },
    { title: getTitles('T-11177'), value: 2 },
    { title: getTitles('T-11178'), value: 3 },
    { title: getTitles('T-11184'), value: 4 },
    { title: getTitles('T-11296'), value: 5 },
    { title: getTitles('T-11183'), value: 6 }
  ],
  farmerTriggerType: [{ title: getTitles('T-11326'), value: 1 }],
  employeeTriggerType: [
    { title: getTitles('T-11326'), value: 1 },
    { title: getTitles('T-10378'), value: 2 },
    { title: getTitles('T-10463'), value: 3 }
  ],
  labTriggerType: [
    { title: getTitles('T-11326'), value: 1 },
    { title: getTitles('T-11332'), value: 2 },
    { title: getTitles('T-11333'), value: 3 },
    { title: getTitles('T-11374'), value: 4 },
    { title: getTitles('T-11375'), value: 5 },
    { title: getTitles('T-11376'), value: 6 },
    { title: getTitles('T-11377'), value: 7 },
    { title: getTitles('T-11378'), value: 8 },
    { title: getTitles('T-11379'), value: 9 },
    { title: getTitles('T-11380'), value: 10 },
    { title: getTitles('T-11381'), value: 11 },
    { title: getTitles('T-11334'), value: 12 }
  ],
  societyTriggerType: [
    { title: getTitles('T-11326'), value: 1 },
    { title: getTitles('T-11354'), value: 2 },
    { title: getTitles('T-11355'), value: 3 }
  ],
  taskOperateType: [
    {
      title: getTitles('T-11297'),
      value: 1
    },
    {
      title: getTitles('T-11298'),
      value: 2
    }
  ],
  repeatationOptions: [
    {
      title: getTitles('T-11305'),
      value: 1
    },
    {
      title: getTitles('T-11306'),
      value: 2
    },
    {
      title: getTitles('T-11307'),
      value: 3
    }
  ],
  taskRepeatValue: [
    {
      value: true,
      title: getTitles('T-10705')
    },
    {
      value: false,
      title: getTitles('T-10706')
    }
  ],
  remindAtOptions: [
    {
      title: getTitles('T-11338'),
      value: 1
    },
    {
      title: getTitles('T-11339'),
      value: 2
    },
    {
      title: getTitles('T-11340'),
      value: 3
    }
  ],
  roleOptions: [
    { name: getTitles('T-11309'), id: 1 },
    { name: getTitles('T-11310'), id: 2 },
    { name: getTitles('T-11311'), id: 3 },
    { name: getTitles('T-11162'), id: 4 },
    { name: getTitles('T-10951'), id: 5 },
    { name: getTitles('T-11312'), id: 6 },
    { name: getTitles('T-11313'), id: 7 },
    { name: getTitles('T-10128'), id: 8 },
    { name: getTitles('T-10130'), id: 10 },
    { name: getTitles('T-10131'), id: 11 },
    { name: getTitles('T-11314'), id: 12 },
    { name: getTitles('T-10133'), id: 13 },
    { name: getTitles('T-10134'), id: 14 },
    { name: getTitles('T-10135'), id: 15 },
    { name: getTitles('T-10136'), id: 16 },
    { name: getTitles('T-10137'), id: 17 },
    { name: getTitles('T-10138'), id: 18 },
    { name: getTitles('T-10139'), id: 19 },
    { name: getTitles('T-10420'), id: 20 },
    { name: getTitles('T-10469'), id: 23 },
    { name: getTitles('T-10470'), id: 24 },
    { name: getTitles('T-10471'), id: 25 },
    { name: getTitles('T-11386'), id: 26 }
  ],
  callCenterStatus: [
    {
      title: getTitles('T-11349'),
      value: 1
    },
    {
      title: getTitles('T-11350'),
      value: 2
    },
    {
      title: getTitles('T-11317'),
      value: 3
    },
    {
      title: getTitles('T-11351'),
      value: 4
    },
    {
      title: getTitles('T-11352'),
      value: 5
    }
  ],
  taskStatusOptions: [
    {
      title: getTitles('T-11316'),
      value: 1
    },
    {
      title: getTitles('T-11317'),
      value: 2
    },
    {
      title: getTitles('T-11353'),
      value: 3
    }
  ],
  callStatus: {
    inactive: 'inactive',
    outgoing: 'outgoing',
    incoming: 'incoming',
    accepted: 'accepted'
  },
  callActions: {
    accept: 'Accept',
    reject: 'Reject',
    endcall: 'End Call'
  },
  callCenterSearchRoles: [
    { value: 1, title: getTitles('T-10468') },
    { value: 2, title: getTitles('T-11291') },
    { value: 3, title: getTitles('T-10951') },
    { value: 4, title: getTitles('T-10467') },
    { value: 5, title: getTitles('T-11359') },
    { value: 6, title: getTitles('T-11360') }
  ],
  callCenterUserTypes: {
    member: 1,
    nonMember: 2,
    employee: 3
  },
  conditionDate: 'condition_date',
  conditionBoolean: 'condition_boolean',
  conditionNumber: 'condition_number',
  conditionString: 'condition_string'
};
