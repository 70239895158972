/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Screen } from '../../ui-kits';

export const AboutUs = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center'
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  return (
    <Screen showHeader={true}>
      <div style={styles.container}>
        <div style={styles.subContainer}>
          <h1>About Us</h1>
          <p>Last updated: September 19, 2024</p>

          <p>
            Welcome to our <strong>MyGopalan</strong> Company! We are dedicated to providing
            innovative solutions that enhance the lives of our customers. Founded on the principles
            of quality and integrity, our team is passionate about delivering exceptional products
            and services tailored to meet your needs. With years of experience in the industry, we
            pride ourselves on our commitment to excellence and customer satisfaction. Join us on
            our journey as we continue to grow and inspire, making a positive impact in the
            community and beyond.
          </p>
        </div>
      </div>
    </Screen>
  );
};
