import { defaultValues } from '../../constants';
import { color } from '../../theme';

const [res, mob] = [defaultValues.isResponsive, defaultValues.isMobile];
export const styles = {
  container: {
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px'
  },
  img: {
    height: '132px',
    width: '132px',
    maxHeight: { xs: '120px', md: '125px' },
    maxWidth: { xs: '120px', md: '125px' }
  },
  searchBar: {
    searchBarContainer: {
      display: 'flex',
      border: `1px solid ${color.primaryBorder}`,
      width: mob ? '100%' : '728px',
      margin: '20px 0',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      borderRadius: res ? '4px' : '8px',
      padding: res ? '8px 0px 8px 12px' : '4px 16px',
      boxSizing: 'border-box',
      backgroundColor: color.primaryBackground,
      height: res ? '40px' : '48px'
    }
  },
  searchButton: {
    width: res ? '159px' : '187px',
    height: res ? '40px' : '60px'
  },
  searchIcon: {
    width: res ? '20px' : '28px',
    height: res ? '20px' : '28px'
  }
};
