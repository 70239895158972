import { defaultValues } from '../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const lg = defaultValues.isLarge;

export const profileStyles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    padding: '2% 3%',
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  section1: {
    width: res ? '100%' : '40%',
    display: 'flex',
    justifyContent: res ? 'center' : 'left'
  },
  section2: {
    width: res ? '100%' : '60%',
    height: res ? 'fit-content' : '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: md ? 'none' : lg && 'auto'
  },
  nameStyle: {
    width: res ? '100%' : 'calc(50% - 5px)'
  },
  nameCont: {
    display: 'flex',
    gap: '10px'
  },
  phoneCont: {
    maxWidth: '100%'
  },
  btn: {
    minWidth: '300px',
    width: res && '100%',
    padding: '5px 50px',
    maxWidth: 'none',
    marginTop: '20px'
  },
  btnsCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    gap: '20px',
    marginTop: 'auto',
    marginBottom: '10px'
  }
};
