import { Box } from '@mui/material';
import { BasicProfile } from '../../utility-components';
import callIcon from '../../../../assets/icons/call.png';
import distance from '../../../../assets/icons/distance.png';
import { globalStyles } from '../../../../theme';
import { Text } from '../../../../ui-kits';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';

export const TaskInfoCard = ({ containerStyles, icon, heading, details }) => {
  const [showArray, setShowArray] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(details)) setShowArray(true);
  }, [details]);

  const handleOpen = () => {
    if (details?.length > 1) setOpen(!open);
  };

  if (!details) {
    return;
  }

  return (
    <Box sx={styles.container(containerStyles)}>
      {icon && <img src={icon} style={styles.icon} />}
      <Box sx={styles.contentCont}>
        {!showArray ? (
          <>
            {heading && (
              <BasicProfile
                title={heading}
                url={callIcon}
                iconPositon="right"
                titleStyle={{ fontWeight: 600 }}
              />
            )}
            {details?.name && <BasicProfile title={details.name} isIcon={false} />}
            {details?.phone_number && <BasicProfile title={details.phone_number} isIcon={false} />}
            {details?.location && (
              <Box sx={styles.locCont}>
                <img src={distance} style={styles.locIcon} />
                <Text variant="D20" style={globalStyles.text}>
                  {details.location}
                </Text>
              </Box>
            )}
          </>
        ) : (
          <>
            {heading && (
              <BasicProfile
                title={heading}
                customRightIcon={
                  <ArrowDropDownCircleOutlinedIcon
                    sx={styles.arrow(open, details?.length > 1)}
                    onClick={handleOpen}
                  />
                }
                iconPositon="right"
                titleStyle={{ fontWeight: 600 }}
              />
            )}
            {details
              .map((item, idx) => (
                <Box key={idx} sx={styles.subCont}>
                  {item.lab_name && <BasicProfile title={item.lab_name} isIcon={false} />}
                  {item.phone_number && (
                    <BasicProfile title={item.phone_number} url={callIcon} iconPositon="right" />
                  )}
                  {item.location && (
                    <Box sx={styles.locCont}>
                      <img src={distance} style={styles.locIcon} />
                      <Text variant="D20" style={globalStyles.text}>
                        {item.location}
                      </Text>
                    </Box>
                  )}
                </Box>
              ))
              .slice(0, open ? details?.length : 1)}
          </>
        )}
      </Box>
    </Box>
  );
};
