import { Box } from '@mui/material';

import { employeePreviousemploymentSchema, getTitles } from '../../../../utils';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { CollapseDropView, NoData } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { PreviousEmploymentForm } from './employment-form';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDistrictRequest,
  getStateRequest,
  getTalukRequest,
  getVillageRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill,
  updateEmployeeRequest
} from '../../../../services/redux/slices';
import { ViewPreviousEmployment } from './view-previous-employment';
import {
  getDistrict,
  getDistrictNextPage,
  getTaluka,
  getTalukaNextPage,
  getVillage,
  getVillageNextPage
} from '../../../../services/redux/selectors/manageProccess';
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  }
};

export const EmployeePreviousEmployment = ({
  states,
  employeeData,
  employeeId,
  editable,
  handleContinue
}) => {
  const dispatch = useDispatch();

  const districts = useSelector(getDistrict);
  const talukas = useSelector(getTaluka);
  const villages = useSelector(getVillage);
  const districtNxtPg = useSelector(getDistrictNextPage);
  const villageNxtPge = useSelector(getVillageNextPage);
  const talukNxtPge = useSelector(getTalukaNextPage);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [employment, setEmployment] = useState(true);
  const [previousEmployment, setPreviousEmployment] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [isEmp, setIsEmp] = useState(false);
  const [showOnTitle, setShowOnTitle] = useState(false);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid,
    setValues,
    resetForm
  } = useFormik({
    initialValues: {
      employer_name: '',
      employer_mobile_number: '',
      company_name: '',
      address_line_one: '',
      address_line_two: '',
      country_id: '',
      state_id: '',
      district_id: '',
      taluka_id: '',
      village_id: '',
      post_code: '',
      date_joining: null,
      date_relieving: null,
      last_salary: '',
      designation: '',
      mode_employment: '',
      employment_sector: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeePreviousemploymentSchema
  });

  useEffect(() => {
    setStateList(states);
  }, [states]);
  const previousOptions = useRef({
    district: districtsList?.length,
    village: villagesList?.length,
    taluka: talukasList?.length,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    if (editable) {
      if (districtsList?.length !== previousOptions?.district?.length) {
        deduplicateAndSet(districtsList, districts, setDistrictsLists);
      }
      if (talukasList?.length !== previousOptions?.taluka?.length) {
        deduplicateAndSet(talukasList, talukas, setTalukasList);
      }
      if (villagesList?.length !== previousOptions?.village?.length) {
        deduplicateAndSet(villagesList, villages, setVillagesList);
      }
      if (districtNxtPg !== previousOptions?.districtNxtPage && districtNxtPg !== null) {
        dispatch(getDistrictRequest({ state_id: values.state_id, page: districtNxtPg }));
      }
      if (talukNxtPge !== previousOptions?.talukaNextPage && talukNxtPge !== null) {
        dispatch(getTalukRequest({ district_id: values.district_id, page: talukNxtPge }));
      }

      if (villageNxtPge !== previousOptions?.villageNxtPage && villageNxtPge !== null) {
        dispatch(getVillageRequest({ taluka_id: values.taluka_id, page: villageNxtPge }));
      }

      previousOptions.current = {
        district: districtsList?.length,
        village: villagesList?.length,
        taluka: talukasList?.length,
        districtNxtPage: districtNxtPg,
        villageNxtPage: villageNxtPge,
        talukaNextPage: talukNxtPge
      };
    }
  }, [districts, talukas, villages, districtNxtPg, villageNxtPge, talukNxtPge]);

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      setPreviousEmployment(employeeData);
    }
  }, [employeeData]);

  const previousValues = useRef({
    state_id: values.state_id,
    district_id: values.district_id,
    taluka_id: values.taluka_id
  });

  useEffect(() => {
    const prev = previousValues.current;
    if (values.state_id !== prev.state_id && !isEmp) {
      dispatch(getDistrictRequest({ state_id: values.state_id, page: 1 }));

      if (!showOnTitle) {
        setTalukasList([]);
        setVillagesList([]);
        setDistrictsLists([]);
        setFieldValue('district_id', '');
        setFieldValue('taluka_id', '');
        setFieldValue('village_id', '');
      }
    }
    if ((values.district_id !== prev.district_id && !isEmp) || showOnTitle) {
      dispatch(getTalukRequest({ district_id: values.district_id, page: 1 }));

      if (!showOnTitle) {
        setVillagesList([]);
        setTalukasList([]);
        setFieldValue('taluka_id', '');
        setFieldValue('village_id', '');
      }
    }
    if ((values.taluka_id !== prev.taluka_id && !isEmp) || showOnTitle) {
      dispatch(getVillageRequest({ taluka_id: values.taluka_id, page: 1 }));

      if (!showOnTitle) {
        setVillagesList([]);
        setFieldValue('village_id', '');
      }
    }

    previousValues.current = {
      state_id: values.state_id,
      district_id: values.district_id,
      taluka_id: values.taluka_id
    };
  }, [values.state_id, values.district_id, values.taluka_id]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems);
  };

  const handleSubmit = () => {
    let payload = { _id: employeeId, previous_employment: previousEmployment };
    const obj = { ...values };
    if (employment === true) {
      payload.previous_employment = [...previousEmployment, obj];
    } else {
      const temp = previousEmployment.map((item, idx) => (idx === employment ? obj : item));
      payload.previous_employment = temp;
    }
    dispatch(updateEmployeeRequest(payload));
    resetForm();
    setEmployment(true);
  };

  const handleAddAnother = () => {
    handleContinue();
    setIsEmp(false);
    handleSubmit();
  };

  const handleTitleClick = (item, idx) => {
    setIsEmp(false);
    setShowOnTitle(true);
    setBtndisabled(true);
    setValues({
      ...item,
      country_id: item.country_id || '',
      state_id: item.state_id || '',
      district_id: item.district_id || '',
      taluka_id: item.taluka_id || '',
      village_id: item.village_id || '',
      mode_employment: item.mode_employment || '',
      employment_sector: item.employment_sector || '',
      date_joining: dayjs(item.date_joining),
      date_relieving: dayjs(item.date_relieving)
    });
    previousValues.current = {
      state_id: '',
      district_id: '',
      taluka_id: ''
    };
    setDistrictsLists([]);
    setVillagesList([]);
    setTalukasList([]);
    dispatch(setClearProcessStateDistTalVill());
    dispatch(setClearProcessPages());
    dispatch(getStateRequest({ page: 1 }));
    if (employment === idx) {
      resetForm();
      return setEmployment(true);
    }
    setEmployment(idx);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setIsEmp(false);
    setBtndisabled(false);
    setShowOnTitle(false);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        {previousEmployment.length > 0 &&
          previousEmployment.map((item, idx) => (
            <Box key={idx}>
              <CollapseDropView
                title={item.employer_name}
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={employment === idx}
              />
              {employment === idx && (
                <PreviousEmploymentForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  handleAddAnother={handleAddAnother}
                  stateList={stateList}
                  districts={districtsList}
                  villages={villagesList}
                  talukas={talukasList}
                  isValid={isValid}
                  dirty={dirty}
                  btnDisabled={btnDisabled}
                  employment={employment}
                />
              )}
            </Box>
          ))}
        {employment === true && (
          <PreviousEmploymentForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setBtndisabled={setBtndisabled}
            handleAddAnother={handleAddAnother}
            stateList={stateList}
            districts={districtsList}
            villages={villagesList}
            talukas={talukasList}
            isValid={isValid}
            dirty={dirty}
            btnDisabled={btnDisabled}
            employment={employment}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          containerStyle={globalStyles.mt(3)}
          disabled={!isValid || !dirty || btnDisabled}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData && employeeData.length > 0 ? (
        <ViewPreviousEmployment employeeData={employeeData} stateList={states} />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
