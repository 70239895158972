import { Box, Divider } from '@mui/material';
import { Text } from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { defaultValues } from '../../../../constants';

const styles = {
  divider: (cW) => ({
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: cW ? cW : defaultValues.isResponsive ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
    mb: defaultValues.isResponsive ? 2 : 2.5,
    mt: defaultValues.isResponsive ? 0.2 : 0.7
  }),
  statesCont: {
    width: '100%',
    mt: defaultValues.isResponsive ? 2 : 4
  }
};
export const FormTitleCard = ({ title, children, dividerWidth }) => {
  return (
    <Box sx={styles.statesCont}>
      <Box sx={globalStyles.assignCont}>
        <Text variant="BoldH28">{title}</Text>
      </Box>
      <Divider sx={styles.divider(dividerWidth)} />
      {children}
    </Box>
  );
};
