import { defaultValues } from '../../constants';
import { color, globalStyles } from '../../theme';
const lg = defaultValues.isLarge;
const md = defaultValues.isMedium;
const res = defaultValues.isResponsive;

export const HomeStyles = {
  container: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: color.primaryBackground
  },
  introCont: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    width: '100%',
    padding: '0% 3%',
    boxSizing: 'border-box',
    marginTop: res ? '20px' : '70px',
    borderBottom: `1px solid ${color.primaryBorder}`,
    paddingBottom: '30px'
  },
  introSubCont: {
    width: res ? '100%' : '60%',
    display: res && 'flex',
    flexDirection: res && 'column'
  },
  myGopCont: {
    width: '100%',
    position: 'relative'
  },
  introText1: {
    color: color.primary,
    position: 'sticky',
    zIndex: 2
  },
  introText1backIcon: {
    width: res ? '150px' : md ? '200px' : '260px',
    height: res ? '60px' : md ? '90px' : '110px',
    position: 'absolute',
    top: res ? 0 : 10,
    left: res ? -8 : '-20px',
    zIndex: 0
  },
  desc1: {
    color: color.palette.darkBrown
  },
  contactBtn: {
    ...globalStyles.D22,
    ...globalStyles.mt(3),
    ...globalStyles.pl(5),
    ...globalStyles.pr(5),
    mr: res && 'auto',
    ml: res && 'auto'
  },
  cattleCont: {
    width: res ? '60%' : '100%',
    mt: res ? 2 : 0,
    maxWidth: '560px',
    position: 'relative'
  },
  cowSuperCont: { width: res ? '100%' : '40%', display: 'flex', justifyContent: 'end' },
  cow1: {
    width: '100%',
    height: '100%',
    maxHeight: md ? '350px' : lg ? '400px' : '500px',
    position: 'sticky',
    zIndex: 2
  },
  vector8: {
    width: '40%',
    maxHeight: md ? '180px' : '200px',
    position: 'absolute',
    left: '0%',
    top: md ? '30%' : lg ? '36%' : '42%',
    zIndex: 0
  },
  vector10: {
    width: '30%',
    maxHeight: md ? '200px' : '250px',
    position: 'absolute',
    right: 'calc(1.5 * -10%)',
    top: '-4%',
    zIndex: 0
  },
  benefits: {
    width: '100%',
    ...globalStyles.flexColXCenter,
    ...globalStyles.mt(3)
  },
  head1: {
    color: color.palette.darkBrown
  },
  desc3: {
    width: '80%',
    textAlign: 'center',
    mb: 3,
    mt: 1
  },
  BlistCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    alignItems: res && 'center',
    gap: '10px',
    padding: '0% 3%',
    boxSizing: 'border-box',
    mt: res ? 0 : lg && 4
  },
  bImgCont: {
    width: res ? '50%' : '30%',
    textAlign: 'center',
    position: 'relative'
  },
  BCowImg: {
    width: '100%',
    maxWidth: lg ? '350px' : '450px',
    maxHeight: lg ? '450px' : '550px',
    position: res ? 'static' : 'absolute',
    left: 0
  },
  BriefCont: {
    width: res ? '100%' : '60%',
    ...globalStyles.flexColYCenter,
    minHeight: md ? '270px' : lg ? '300px' : '400px'
  },
  serviceCont: {
    width: '100%',
    height: res ? '50px' : '300px'
  },
  downloadCont: {
    width: '100%',
    backgroundColor: color.palette.paleYellow,
    display: 'flex',
    padding: '0% 3% 3% 3%',
    boxSizing: 'border-box',
    minHeight: res ? 'fit-content' : '300px'
  },
  downloadCImgSuperCont: {
    width: '40%'
  },
  downloadCImgCont: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  downCow: {
    width: '100%',
    maxWidth: lg ? '350px' : '500px',
    maxHeight: lg ? '350px' : '450px',
    position: 'absolute',
    top: res ? -30 : lg ? -60 : -90
  },
  downloadSubCont: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: res ? 'start' : 'center'
  },
  downloadBtn2: {
    maxWidth: '300px',
    cursor: 'pointer'
  },
  textJustify: {
    textAlign: 'justify'
  }
};
