import { Box, Divider } from '@mui/material';
import { useFormik } from 'formik';
import { getTitles, removeFalsyValues } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { color, globalStyles } from '../../../../theme';
import { useDispatch } from 'react-redux';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import {
  CollapseDropView,
  DatePickers,
  NoData,
  Text,
  UIPhoneInputTitle,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { EducationalForm } from './educational-form';
import { ViewEducationalDetails } from './view-educational-details';
import dayjs from 'dayjs';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  },
  statesCont: {
    width: '100%',
    marginTop: '10px'
  },
  divider: {
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: 'calc(((100%/3 )* 2) - 5px)'
  },
  durationCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  courseCont: {
    width: res ? '100%' : '80%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  nameWidth: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)'
};

export const EducationalDetails = ({ employeeData, employeeId, editable, handleContinue }) => {
  const dispatch = useDispatch();
  const [btnDisabled, setBtndisabled] = useState(true);

  const [education, setEducation] = useState(true);
  const [educationDetails, setEducationDetails] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    resetForm,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      qualification: '',
      institution_name: '',
      university_name: '',
      student_id_or_registration_number: '',
      percentage: '',
      program: '',
      start_date: null,
      end_date: null,
      institution_city: '',
      university_city: '',
      state_reasons_gap: '',
      name: '',
      contact_number: '',
      duration: '',
      date_of_renewal: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    let newVal = { ...values };
    if (employeeData && employeeData.education && employeeData.education.length > 0) {
      setEducationDetails(employeeData.education);
    }
    if (employeeData && employeeData.state_reasons_gap) {
      newVal = { ...newVal, state_reasons_gap: employeeData.state_reasons_gap };
    }
    if (employeeData && employeeData.course_details) {
      newVal = {
        ...newVal,
        ...employeeData.course_details,
        date_of_renewal:
          employeeData.course_details &&
          employeeData.course_details.date_of_renewal &&
          dayjs(employeeData.course_details.date_of_renewal)
      };
    }
    setValues(newVal);
  }, [employeeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      _id: employeeId,
      education_details: {
        education: educationDetails,
        course_details: {
          name: values.name,
          contact_number: values.contact_number,
          duration: values.duration,
          date_of_renewal: values.date_of_renewal
        },
        state_reasons_gap: values.state_reasons_gap
      }
    };
    let obj = { ...values };
    delete obj.state_reasons_gap;
    delete obj.name;
    delete obj.contact_number;
    delete obj.duration;
    delete obj.date_of_renewal;
    delete obj._id;

    obj = removeFalsyValues(obj);

    if (education === true && Object.values(obj).length > 0) {
      payload.education_details.education = [...educationDetails, obj];
    } else {
      const temp = educationDetails.map((item, idx) => (idx === education ? obj : item));
      payload.education_details.education = temp;
    }

    dispatch(updateEmployeeRequest(payload));
    resetForm();
    setBtndisabled(true);
  };

  const handleAddAnother = () => {
    handleContinue();
    handleSubmit();
    setEducation(true);
  };

  const handleTitleClick = (item, idx) => {
    setValues({
      ...values,
      ...item,
      qualification: item.qualification,
      institution_name: item.institution_name,
      institution_city: item.institution_city,
      university_name: item.university_name,
      university_city: item.university_city,
      student_id_or_registration_number: item.student_id_or_registration_number,
      percentage: item.percentage,
      program: item.program ? item.program : '',
      start_date: item.start_date && dayjs(item.start_date),
      end_date: item.end_date && dayjs(item.end_date)
    });
    if (education === idx) {
      resetForm({
        values: {
          state_reasons_gap: values.state_reasons_gap,
          name: values.name,
          contact_number: values.contact_number,
          duration: values.duration,
          date_of_renewal: values.date_of_renewal
        }
      });
      return setEducation(true);
    }
    setEducation(idx);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        {educationDetails.length > 0 &&
          educationDetails.map((item, idx) => (
            <Box key={idx}>
              <CollapseDropView
                title={item.qualification}
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={education === idx}
              />

              {education === idx && (
                <EducationalForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  handleAddAnother={handleAddAnother}
                  isValid={isValid}
                  dirty={dirty}
                  btnDisabled={btnDisabled}
                  education={education}
                />
              )}
            </Box>
          ))}
        {education === true && (
          <EducationalForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setBtndisabled={setBtndisabled}
            handleAddAnother={handleAddAnother}
            isValid={isValid}
            dirty={dirty}
            btnDisabled={btnDisabled}
            education={education}
          />
        )}

        <UITextInputWithTitle
          name="state_reasons_gap"
          title={getTitles('T-10650')}
          placeHolder={getTitles('T-10373')}
          value={values.state_reasons_gap}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={globalStyles.mt(1)}
          titleStyle={globalStyles.text}
          width={styles.nameWidth}
        />

        <Box sx={styles.statesCont}>
          <Text variant="BoldH28">{getTitles('T-10653')}</Text>
          <Divider sx={styles.divider} />
          <Box sx={styles.durationCont}>
            <UITextInputWithTitle
              name="name"
              title={getTitles('T-10460')}
              placeHolder={getTitles('T-10384')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              titleStyle={globalStyles.text}
              containerStyles={{ width: styles.nameWidth }}
            />
            <UIPhoneInputTitle
              name="contact_number"
              phoneValue={values.contact_number}
              onChange={(val) => {
                setFieldValue('contact_number', val);
                setBtndisabled(val ? false : true);
              }}
              handleError={(isError) => setBtndisabled(isError)}
              onBlur={handleBlur}
              containerStyles={{ width: styles.nameWidth }}
            />
          </Box>
          <Box sx={styles.durationCont}>
            <UITextInputWithTitle
              name="duration"
              title={getTitles('T-10462')}
              placeHolder={getTitles('T-10464')}
              value={values.duration}
              onChange={handleChange}
              onBlur={handleBlur}
              titleStyle={globalStyles.text}
              containerStyles={{ width: styles.nameWidth }}
            />

            <DatePickers
              dateValue={values.date_of_renewal}
              onDateChange={(val) => {
                handleChange({ target: { value: val, name: 'date_of_renewal' } });
                setBtndisabled(true);
              }}
              title={getTitles('T-10463')}
              error={errors.date_of_renewal}
              containerStyles={{ width: styles.nameWidth }}
            />
          </Box>
        </Box>

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData ? (
        <ViewEducationalDetails employeeData={employeeData} />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
