import { Box, Slider } from '@mui/material';
import { Text, UIButton, UIRadioButton, UITextInputWithTitle } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { getTitles } from '../../../../utils';
import { styles } from '../e-com-activity/styles';

export const EcomFilters = ({
  category,
  setCategory = () => {},
  priceRange,
  minPrice,
  handleChange,
  maxPrice,
  setMaxPrice,
  setMinPrice,
  onApplyClick
}) => {
  return (
    <Box sx={styles.filterCont}>
      <Text variant="BoldH22">{getTitles('T-11655')}</Text>
      <Box sx={styles.filterSubCont}>
        <Box sx={styles.common}>
          <Text variant="BoldH22">{getTitles('T-10180')?.toUpperCase()}</Text>
          {defaultValues.materialGroupOptions.map((item, idx) => (
            <UIRadioButton
              key={idx}
              title={item.name}
              titleStyle={styles.radioTitle}
              checked={category.includes(item.id)}
              onChange={() => setCategory([item.id])}
            />
          ))}
        </Box>

        <Box sx={styles.common}>
          <Text variant="BoldH22">{getTitles('T-11607')?.toUpperCase()}</Text>
          <Slider
            getAriaLabel={() => 'Temperature range'}
            value={priceRange}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={10000}
            color="success"
          />
          <Box sx={styles.minMaxCont}>
            <UITextInputWithTitle
              title={getTitles('T-11612')}
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
            />
            <UITextInputWithTitle
              title={getTitles('T-11613')}
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          </Box>
        </Box>
        <UIButton title={getTitles('T-11654')} style={styles.btn} onClick={onApplyClick} />
      </Box>
    </Box>
  );
};
