import { Box } from '@mui/material';
import { DatePickers, UISaveAndAdd, UISelect, UITextInputWithTitle } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';
import { useEffect, useState } from 'react';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  },
  nameWidth: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)',
  phoneWidth: defaultValues.isResponsive ? '100%' : 'calc(100%/3)',
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"reg reg"
    "percent percent"
    "program start"
    "end none"
    `
      : `
    "reg percent program"
    "start end none"
    `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc(100%/3 - 6px) calc(100%/3 - 6px) calc(100%/3 - 9px)',
    gap: '10px'
  },
  reg: {
    gridArea: 'reg'
  },
  percent: {
    gridArea: 'percent'
  },
  program: {
    gridArea: 'program'
  },
  startDate: {
    gridArea: 'start'
  },
  endDate: {
    gridArea: 'end'
  },
  reason: {
    gridArea: 'reason'
  }
};

export const EducationalForm = ({
  values,
  handleChange,
  handleBlur,
  handleAddAnother,
  isValid,
  btnDisabled,
  dirty,
  education
}) => {
  const [sbDisable, setSbDisable] = useState(false);

  useEffect(() => {
    setSbDisable(values.qualification ? false : true);
  }, [values.qualification]);

  return (
    <Box sx={styles.container}>
      <UITextInputWithTitle
        name="qualification"
        title={getTitles('T-10644')}
        placeHolder={getTitles('T-10649')}
        value={values.qualification}
        onChange={handleChange}
        onBlur={handleBlur}
        width={styles.nameWidth}
        allowOnlyAlphabets={true}
      />

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="institution_name"
          title={getTitles('T-10645')}
          placeHolder={getTitles('T-10384')}
          value={values.institution_name}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
          disabled={sbDisable}
        />
        <UITextInputWithTitle
          name="institution_city"
          title={getTitles('T-10661')}
          placeHolder={getTitles('T-10384')}
          value={values.institution_city}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
          disabled={sbDisable}
        />
      </Box>

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="university_name"
          title={getTitles('T-10646')}
          placeHolder={getTitles('T-10384')}
          value={values.university_name}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
          disabled={sbDisable}
        />

        <UITextInputWithTitle
          name="university_city"
          title={getTitles('T-10661')}
          placeHolder={getTitles('T-10384')}
          value={values.university_city}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
          disabled={sbDisable}
        />
      </Box>

      <Box sx={styles.gridCont}>
        <UITextInputWithTitle
          name="student_id_or_registration_number"
          title={getTitles('T-10478')}
          placeHolder={getTitles('T-10360')}
          value={values.student_id_or_registration_number}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.reg}
          disabled={sbDisable}
        />
        <UITextInputWithTitle
          name="percentage"
          title={getTitles('T-10647')}
          placeHolder={getTitles('T-10360')}
          value={values.percentage}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.percent}
          disabled={sbDisable}
        />
        <UISelect
          name="program"
          value={values.program}
          title={getTitles('T-10648')}
          options={defaultValues.educationProgram}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          containerStyles={styles.program}
          disabled={sbDisable}
        />

        <DatePickers
          dateValue={values.start_date}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'start_date' } })}
          title={getTitles('T-10651')}
          maxDate={values.end_date}
          containerStyles={styles.startDate}
          disabled={sbDisable}
        />

        <DatePickers
          dateValue={values.end_date}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'end_date' } })}
          title={getTitles('T-10652')}
          minDate={values.start_date}
          containerStyles={styles.endDate}
          disabled={sbDisable}
        />
      </Box>

      <UISaveAndAdd
        title={education === true ? getTitles('T-10062') : getTitles('T-10477')}
        onAddAnother={handleAddAnother}
        disabled={!isValid || !dirty || btnDisabled || sbDisable}
      />
    </Box>
  );
};
