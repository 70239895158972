import { Box } from '@mui/material';
import { color } from '../../theme/color';
import { Text } from '../text';
import PhoneInput from 'react-phone-number-input';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.css';
import { getErrorMessage } from '../../constants/getMessage';
import { getTitles } from '../../utils';

export const UIPhoneInputTitle = ({
  onBlur,
  onTouched,
  phoneValue,
  onChange,
  handleError,
  disabled,
  style = {},
  variant = 'D20',
  containerStyles,
  required = false,
  validate = true,
  title = getTitles('T-10003'),
  placeholder = getTitles('T-10640'),
  width,
  countryCallingCodeEditable = false,
  onBlurErrorMsg,
  isLabel,
  ...rest
}) => {
  const defaultStyles = {
    inputContainer: (containerStyles, isLabel) => ({
      width: width || '100%',
      maxWidth: '600px',
      marginTop: '10px',
      position: isLabel ? 'relative' : 'static',
      ...containerStyles
    }),
    title: (isLabel) => ({
      marginBottom: '5px',
      position: isLabel ? 'absolute' : 'static',
      top: -10,
      left: 10,
      backgroundColor: color.primaryBackground,
      color: isLabel ? color.palette.royalBlue : color.primaryText,
      zIndex: 2,
      p: isLabel ? '1px 2px' : 'none',
      boxSizing: 'border-box',
      opacity: isLabel ? 0.9 : 1
    }),
    input: {
      boxShadow: 0,
      width: '100%',
      borderRadius: '8px'
    },
    asterisk: {
      color: color.errorText
    },
    errorStyle: {
      marginTop: '5px'
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };
  const [value, setValue] = useState('');
  const [telErrMsg, setTelErrMsg] = useState('');

  useEffect(() => {
    if (phoneValue !== null) {
      setValue(phoneValue);
    }
  }, [phoneValue]);

  const handleTelephoneChange = (value) => {
    setValue(value);
    onChange(value);

    if (value !== undefined) {
      if (isValidPhoneNumber(value)) {
        setTelErrMsg('');
        handleError && handleError(false);
      } else {
        setTelErrMsg(getErrorMessage('E-10003'));
        handleError && handleError(true);
      }
    } else {
      setTelErrMsg('');
      handleError && handleError(true);
    }
  };

  const handlePhoneBlur = (e) => {
    if (!value || value.length === 0) {
      required && setTelErrMsg(getErrorMessage(onBlurErrorMsg || 'E-10017'));
    }
    onBlur && onBlur(e);
  };

  return (
    <Box sx={styles.inputContainer(containerStyles, isLabel)}>
      <Text variant={isLabel ? 'D12' : variant} style={styles.title(isLabel)}>
        {title}
        {required && <span style={styles.asterisk}>*</span>}
      </Text>
      <PhoneInput
        value={value}
        international={true}
        withCountryCallingCode={true}
        countryCallingCodeEditable={countryCallingCodeEditable}
        className={`phone ${disabled ? 'disabled' : telErrMsg ? 'error' : ''}`}
        defaultCountry="IN"
        disabled={disabled}
        onChange={handleTelephoneChange}
        touched={onTouched}
        onBlur={handlePhoneBlur}
        placeholder={placeholder}
        {...rest}
      />
      {validate && telErrMsg && (
        <Text variant="fieldError" style={styles.errorStyle}>
          {telErrMsg}
        </Text>
      )}
    </Box>
  );
};
