import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../constants';
import { Text } from '../text';
import { getTitles } from '../../utils';
import { color, globalStyles } from '../../theme';
import Image from '../../assets/icons/backup.png';
import Delete from '../../assets/icons/delete.png';

const styles = {
  container: (width) => ({
    width: width || '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }),
  dropText: {
    color: color.secondaryText
  },
  subCont: (val, custom, disabled) => ({
    width: 'calc(100% - 8px)',
    minHeight: custom ? '50px' : '100px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: custom ? 'row' : 'column',
    gap: '10px',
    cursor: !val ? (disabled ? 'not-allowed' : 'pointer') : 'default',
    border: custom ? '2px dashed' : '4px dashed',
    borderColor: disabled ? color.disabled : color.primary,
    backgroundColor: disabled ? color.disabledBackground : color.secondaryBackground,
    position: 'relative',
    borderRadius: custom ? '5px' : 'none'
  }),
  iconCont: (custom) => ({
    width: custom ? 'auto' : '100%',
    justifyContent: 'center',
    display: 'flex',
    gap: '20px'
  }),
  icon: {
    width: '25px',
    height: '20px'
  },
  icon2: {
    width: '20px',
    height: '20px',
    cursor: 'pointer'
  },
  title: (style) => ({
    marginTop: '10px',
    marginBottom: '10px',
    ...style
  }),
  editText: {
    textDecoration: 'underline',
    textOverflow: 'ellipsis',
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
    cursor: 'pointer'
  },
  deleteIconCont: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  asterisk: {
    color: color.errorText
  }
};

export const DragDrop = ({
  title,
  handleChange,
  fileData,
  onEditClick = () => {},
  onDeleteClick,
  fileTypes = ['jpg', 'png', 'jpeg', 'jfif', 'pdf'],
  width,
  inputRef,
  required,
  variant = 'D20',
  showDelete = true,
  titleStyle,
  custom = false,
  disabled = false
}) => {
  const [value, setValue] = useState(null);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    setValue(fileData);
  }, [fileData]);

  const handleFileChange = (file) => {
    if (disabled) return;

    if (file.size > 5000000) {
      return toast.error(getErrorMessage('E-10019'));
    }
    const image = URL.createObjectURL(file);
    setPreview([`${image}`]);
    handleChange ? handleChange(file, [`${image}`]) : setValue(file);
  };

  return (
    <div style={styles.container(width)}>
      {title && (
        <Text variant={variant} style={styles.title(titleStyle)}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/*,application/pdf"
        onChange={(e) => handleFileChange(e.target.files[0])}
        ref={inputRef}
        disabled={disabled}
      />
      {!value ? (
        <FileUploader
          handleChange={handleFileChange}
          name="file"
          file={value}
          types={fileTypes}
          disabled={disabled}>
          <Box sx={styles.subCont(value, custom, disabled)}>
            {!value && (
              <>
                <Box sx={styles.iconCont(custom)}>
                  <img src={Image} style={styles.icon} />
                </Box>
                <Text style={styles.dropText} variant="D16">
                  {getTitles('T-10305')}
                </Text>
                {!custom && (
                  <Text style={styles.dropText} variant="D16">
                    {getTitles('T-10306')}
                  </Text>
                )}
              </>
            )}
          </Box>
        </FileUploader>
      ) : (
        <Box sx={styles.subCont(value, custom, disabled)}>
          <Box sx={styles.deleteIconCont}>
            {showDelete && (
              <img
                src={Delete}
                style={styles.icon2}
                onClick={() => {
                  onDeleteClick ? onDeleteClick() : setValue(null);
                }}
              />
            )}
          </Box>
          <Text
            style={{ ...globalStyles.link, ...styles.editText }}
            variant="D22"
            onClick={() => onEditClick(preview)}>
            {value.name ? value.name : value}
          </Text>
        </Box>
      )}
    </div>
  );
};
