import { defaultValues } from '../../constants';
import { UploadPicture, UIButton, UIPhoneInputTitle, UITextInputWithTitle } from '../../ui-kits';
import { Box } from '@mui/material';
import { UIContainer } from '../../ui-kits/container';
import { Screen } from '../../ui-kits/screen';
import { profileStyles } from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProfileRequest,
  profilePicChangeRequest,
  profilePicDeleteRequest,
  resetAuthMessages
} from '../../services/redux/slices';
import {
  authLoading,
  getAuthErrorMessage,
  getAuthSuccessMessage,
  getUserData
} from '../../services/redux/selectors';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { getErrorMessage, getSuccessMessage } from '../../constants/getMessage';
import { getTitles, resetMessages } from '../../utils/commonFunction';
import { profileSchema } from '../../utils/schemas';

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const profileData = useSelector(getUserData);
  const isLoading = useSelector(authLoading);
  const successMessage = useSelector(getAuthSuccessMessage);
  const errorMessage = useSelector(getAuthErrorMessage);

  const [btnDisabled, setBtndisabled] = useState(true);

  const { values, handleChange, errors, touched, handleBlur, setFieldValue, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      phone_number: '',
      middle_name: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: profileSchema
  });

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      setBtndisabled(true);
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (profileData) {
      setFieldValue(defaultValues.appLogicalTexts.first_name, profileData.first_name);
      setFieldValue(defaultValues.appLogicalTexts.last_name, profileData.last_name);
      setFieldValue(defaultValues.appLogicalTexts.email_id, profileData.email_id || '');
      setFieldValue(defaultValues.appLogicalTexts.phone_number, profileData.phone_number);
      setFieldValue(defaultValues.appLogicalTexts.middle_name, profileData.middle_name);
    }
  }, [profileData]);

  const handleProfileClick = () => {
    const payload = { ...values };
    delete payload.phone_number;
    dispatch(editProfileRequest(payload));
  };

  const handlePropicChange = (file) => {
    const payload = { photo_url: file };
    dispatch(profilePicChangeRequest(payload));
  };

  const handlePropicDelete = () => {
    dispatch(profilePicDeleteRequest());
  };

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10040')} isLoading={isLoading}>
      <UIContainer>
        <Box sx={profileStyles.container}>
          <Box sx={profileStyles.section1}>
            <UploadPicture
              profilePicture={
                profileData?.photo_url &&
                `${profileData?.photo_url}?timestamp=${new Date().getTime()}`
              }
              changedImage={handlePropicChange}
              onProPicDelete={handlePropicDelete}
            />
          </Box>
          <Box sx={profileStyles.section2}>
            <Box sx={profileStyles.nameCont}>
              <UITextInputWithTitle
                name="first_name"
                required
                value={values.first_name}
                onChange={(val) => {
                  handleChange(val);
                  setBtndisabled(false);
                }}
                maxLength={10}
                onBlur={handleBlur}
                error={touched.first_name && errors.first_name}
                variant="D22"
                title={getTitles('T-10041')}
                containerStyles={profileStyles.nameStyle}
                placeHolder={getTitles('T-10047')}
                allowOnlyAlphabets
              />

              <UITextInputWithTitle
                name="middle_name"
                value={values.middle_name}
                onChange={(val) => {
                  handleChange(val);
                  setBtndisabled(false);
                }}
                maxLength={10}
                onBlur={handleBlur}
                error={touched.middle_name && errors.middle_name}
                variant="D22"
                title={getTitles('T-10337')}
                containerStyles={profileStyles.nameStyle}
                placeHolder={getTitles('T-10338')}
                allowOnlyAlphabets
              />
            </Box>
            <UITextInputWithTitle
              name="last_name"
              required
              value={values.last_name}
              onChange={(val) => {
                handleChange(val);
                setBtndisabled(false);
              }}
              maxLength={10}
              onBlur={handleBlur}
              error={touched.last_name && errors.last_name}
              variant="D22"
              title={getTitles('T-10042')}
              containerStyles={profileStyles.nameStyle}
              placeHolder={getTitles('T-10048')}
              allowOnlyAlphabets
            />
            <UIPhoneInputTitle
              required
              variant="D22"
              title={getTitles('T-10003')}
              containerStyles={profileStyles.phoneCont}
              disabled
              phoneValue={values.phone_number}
            />
            <UITextInputWithTitle
              name="email_id"
              value={values.email_id}
              onChange={(val) => {
                setBtndisabled(false);
                handleChange(val);
              }}
              onBlur={handleBlur}
              error={touched.email_id && errors.email_id}
              variant="D22"
              title={getTitles('T-10044')}
              placeHolder={getTitles('T-10049')}
            />

            <Box sx={profileStyles.btnsCont}>
              <UIButton
                title={getTitles('T-10046')}
                style={profileStyles.btn}
                disabled={!isValid || btnDisabled}
                onClick={handleProfileClick}
              />
            </Box>
          </Box>
        </Box>
      </UIContainer>
    </Screen>
  );
};
