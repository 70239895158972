import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addGovtOfficialFailure,
  addGovtOfficialRequest,
  addGovtOfficialSuccess,
  getGovtOfficialFailure,
  getGovtOfficialSuccess,
  updateGovtOfficialFailure,
  updateGovtOfficialRequest,
  updateGovtOfficialSuccess,
  getGovtOfficialsRequest,
  getGovtOfficialDetailsSuccess,
  getGovtOfficialDetailsFailure,
  getGovtOfficialDetailsRequest
} from '../slices';
import {
  addGovtOfficialAPI,
  getGovtOfficialAPI,
  getGovtOfficialDetailsAPI,
  updateGovtOfficialAPI
} from '../../api';

function* addGovtOfficial(action) {
  try {
    const response = yield addGovtOfficialAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addGovtOfficialSuccess(response.data));
    } else {
      yield put(addGovtOfficialFailure(response.data));
    }
  } catch (error) {
    yield put(addGovtOfficialFailure(error.response.data));
  }
}

function* updateGovtOfficial(action) {
  try {
    const response = yield updateGovtOfficialAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateGovtOfficialSuccess(response.data));
    } else {
      yield put(updateGovtOfficialFailure(response.data));
    }
  } catch (error) {
    yield put(updateGovtOfficialFailure(error.response.data));
  }
}

function* getGovtOfficial(action) {
  try {
    const response = yield getGovtOfficialAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getGovtOfficialSuccess(response.data));
    } else {
      yield put(getGovtOfficialFailure(response.data));
    }
  } catch (error) {
    yield put(getGovtOfficialFailure(error.response.data));
  }
}

function* getGovtOfficialdetails(action) {
  try {
    const response = yield getGovtOfficialDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getGovtOfficialDetailsSuccess(response.data));
    } else {
      yield put(getGovtOfficialDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getGovtOfficialDetailsFailure(error.response.data));
  }
}

export function* govtOfficialSaga() {
  yield takeLatest(addGovtOfficialRequest.type, addGovtOfficial);
  yield takeLatest(updateGovtOfficialRequest.type, updateGovtOfficial);
  yield takeLatest(getGovtOfficialsRequest.type, getGovtOfficial);
  yield takeLatest(getGovtOfficialDetailsRequest.type, getGovtOfficialdetails);
}
