import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, handleMultiDelete, villageDetailsSchema } from '../../../../utils';
import {
  CollapseDropView,
  EditButton,
  Text,
  UIButton,
  UIChipButton,
  UIDataTable,
  UIMultipleSelectCheckmarks,
  UIRadioButton,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVillageRequest,
  updateScrollToTop,
  updateVillageCurrPage,
  updateVillageRequest
} from '../../../../services/redux/slices';
import { ModalButtons } from '../../../common/modal-buttons';
import { FormTitleCard, TableCard } from '../../../common/cards';
import { getScroll } from '../../../../services/redux/selectors';
import { Delete } from '@mui/icons-material';
import { getSuccessMsg } from '../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },
  radioSubCont: {
    width: defaultValues.isResponsive ? '30%' : 'calc((100% + 5px)/3)',
    display: 'flex',
    gap: '10px'
  },

  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "state district"
      "taluk village"
      "route count"
        `
      : `
        "state district taluk"
        "village route count"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  },
  resInputCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  resInputSup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  rowValueContainerStyle: {
    border: `1px solid transparent`,
    gap: '10px'
  },
  valuesSubStyle: {
    padding: '0px'
  },
  headerContStyle: {
    gap: '10px'
  },
  inputCont: {
    marginTop: '0px'
  },
  genderInput: {
    marginTop: '0px'
  },

  detailsCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  editBtn: {
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    width: 'fit-content',
    '& .MuiButton-startIcon': {
      marginRight: '0px',
      marginLeft: '0px'
    },
    padding: '5px',
    boxSizing: 'border-box',
    height: '100%'
  },
  editIcon: {
    cursor: 'pointer',
    width: defaultValues.isResponsive ? '20px' : defaultValues.isMedium ? '23px' : '26px',
    height: defaultValues.isResponsive ? '20px' : defaultValues.isMedium ? '23px' : '26px'
  },
  toolsCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '10px'
  },
  collapseCont: {
    backgroundColor: color.palette.lightGoldenrodYellow,
    padding: 1,
    boxSizing: 'border-box',
    borderRadius: '4px'
  }
};

export const VillageDetailsForm = ({
  societyList,
  currRender,
  villageData,
  villageId,
  state,
  district,
  taluka,
  talukaId,
  id,
  onNext
}) => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);
  const successMsg = useSelector(getSuccessMsg);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [sbDisabled, setSbdisabled] = useState(true);
  const [isFam, setIsFam] = useState(true);
  const [milkCollectionCenter, setMilkCollectionCenter] = useState([]);
  const { add, edit, view } = defaultValues.actionType;

  const { values, errors, touched, setValues, handleBlur, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        village: '',
        village_code: '',
        taluka_id: '',
        pincode: '',
        milk_collection_centers: {
          _id: '',
          name: '',
          type: '',
          capacity: '',
          remark: '',
          society_code: ''
        },
        nearest_dairy: '',
        average_population: '',
        has_electricity: null,
        tar_road: null,
        nearest_tar_road_distance: '',
        has_mobile_network: null,
        available_mobile_networks: [],
        has_postal_service: null
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: villageDetailsSchema
    });
  useEffect(() => {
    if (['S-10054', 'S-10055'].includes(successMsg) && !btnDisabled) {
      onNext();
      dispatch(updateScrollToTop(!scroll));
    }
  }, [successMsg]);

  useEffect(() => {
    if (villageData) {
      let newValues = { ...villageData };
      if (villageData.available_mobile_networks) {
        newValues = {
          ...newValues,
          available_mobile_networks:
            villageData.available_mobile_networks.length > 0
              ? defaultValues.mobileNetworks
                  .filter((item) => villageData.available_mobile_networks.includes(item.value))
                  .map((ele) => ({ value: ele.value, title: ele.title }))
              : [],
          milk_collection_centers: {
            name: '',
            type: '',
            capacity: '',
            remark: '',
            society_code: ''
          }
        };
      }
      if (villageData.milk_collection_centers) {
        setMilkCollectionCenter(villageData.milk_collection_centers);
      }
      setValues(newValues);
    }
  }, [villageData]);

  useEffect(() => {
    if (values.milk_collection_centers?._id) {
      const data =
        societyList &&
        societyList.length > 0 &&
        societyList.find((item) => item._id === values.milk_collection_centers._id);
      if (data) {
        setFieldValue(
          'milk_collection_centers.type',
          defaultValues.societyTypes.find((item) => item.value === data.society_type).title
        );
        setFieldValue('milk_collection_centers.society_code', data.society_code);
      } else {
        setFieldValue('milk_collection_centers.type', '');
        setFieldValue('milk_collection_centers.society_code', '');
      }
      setSbdisabled(false);
    } else {
      setSbdisabled(true);
      setFieldValue('milk_collection_centers.type', '');
      setFieldValue('milk_collection_centers.society_code', '');
    }
  }, [values.milk_collection_centers?._id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (currRender !== view) {
      setFieldValue(name, value);
      setBtndisabled(false);
    }
  };

  const handleEditMilkCenter = (item, idx) => {
    setValues({
      ...values,
      milk_collection_centers: {
        ...item
      }
    });
    if (isFam === idx) {
      setFieldValue('milk_collection_centers._id', '');

      setFieldValue('milk_collection_centers.name', '');
      setFieldValue('milk_collection_centers.type', '');
      setFieldValue('milk_collection_centers.capacity', '');
      setFieldValue('milk_collection_centers.remark', '');
      setFieldValue('milk_collection_centers.society_code', '');

      return setIsFam(true);
    }
    setIsFam(idx);
  };

  const handleSubmit = () => {
    let payload = {
      step: defaultValues.villageRegisterSteps.villageDetails,
      village_details: {
        ...values,
        milk_collection_centers: milkCollectionCenter,
        available_mobile_networks:
          values.available_mobile_networks?.length > 0 &&
          values.available_mobile_networks.map((item) => item.value)
      }
    };
    if (currRender === add) {
      payload = {
        ...payload,
        village_details: {
          ...payload.village_details,
          taluka_id: talukaId
        }
      };
      dispatch(addVillageRequest(payload));
    }
    if (currRender === edit) {
      payload = {
        ...payload,
        village_id: villageId,
        _id: id
      };
      dispatch(updateVillageRequest(payload));
    }
    if (currRender === view) {
      onNext();
      dispatch(updateScrollToTop(!scroll));
    }
  };

  const handleSaveAndAddAnother = () => {
    const obj = {
      ...values.milk_collection_centers
    };

    if (isFam === true) {
      setMilkCollectionCenter([...milkCollectionCenter, obj]);
    } else {
      const temp = milkCollectionCenter.map((item, idx) => (idx === isFam ? obj : item));
      setMilkCollectionCenter(temp);
    }
    setFieldValue('milk_collection_centers._id', '');
    setFieldValue('milk_collection_centers.name', '');
    setFieldValue('milk_collection_centers.type', '');
    setFieldValue('milk_collection_centers.capacity', '');
    setFieldValue('milk_collection_centers.remark', '');
    setFieldValue('milk_collection_centers.society_code', '');
    setIsFam(true);
  };

  const handleDelete = (item, idx) => {
    const final = milkCollectionCenter.filter((ele, index) => index !== idx);
    setMilkCollectionCenter(final);
    setBtndisabled(false);
  };

  const getMilkCenterDetails = (item, idx) => {
    return [
      {
        values: item.name
      },
      {
        values: item.type
      },
      {
        values: item.capacity
      },
      {
        values: item.remark
      },
      {
        values: item.society_code
      },
      {
        columnWidth: defaultValues.isMedium ? '15%' : '10%',
        values: (
          <Box sx={styles.toolsCont}>
            <UIButton
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => handleEditMilkCenter(item, idx)}
              disabled={currRender === view}
            />
            <UIButton
              startIcon={<Delete sx={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => handleDelete(item, idx)}
              disabled={currRender === view}
            />
          </Box>
        )
      }
    ];
  };

  const tableData = [
    [
      {
        title: getTitles('T-10081'),
        values: (
          <UISelect
            name="milk_collection_centers._id"
            variant="D22"
            options={
              societyList &&
              societyList.length > 0 &&
              societyList.map((item) => ({ value: item._id, title: item.name }))
            }
            placeholder={getTitles('T-10350')}
            value={values.milk_collection_centers && values.milk_collection_centers._id}
            onChange={(e) => {
              handleChange(e);
              societyList?.length > 0 &&
                setFieldValue(
                  'milk_collection_centers.name',
                  societyList.find((el) => el._id === e.target.value)?.name
                );
            }}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            inputStyle={styles.genderInput}
            disabled={currRender === view}
          />
        )
      },
      {
        title: getTitles('T-10808'),
        values: (
          <UITextInputWithTitle
            name="milk_collection_centers.type"
            variant="D22"
            value={values.milk_collection_centers && values.milk_collection_centers.type}
            onChange={handleChange}
            containerStyles={styles.inputCont}
            disabled={true}
          />
        )
      },
      {
        title: getTitles('T-10809'),
        values: (
          <UITextInputWithTitle
            name="milk_collection_centers.capacity"
            variant="D22"
            value={values.milk_collection_centers && values.milk_collection_centers.capacity}
            onChange={handleChange}
            containerStyles={styles.inputCont}
            disabled={currRender === view}
          />
        )
      },
      {
        title: getTitles('T-10660'),
        values: (
          <UITextInputWithTitle
            name="milk_collection_centers.remark"
            variant="D22"
            value={values.milk_collection_centers && values.milk_collection_centers.remark}
            onChange={handleChange}
            containerStyles={styles.inputCont}
            disabled={currRender === view}
          />
        )
      },
      {
        title: getTitles('T-10709'),
        values: (
          <UITextInputWithTitle
            name="milk_collection_centers.society_code"
            variant="D22"
            value={values.milk_collection_centers && values.milk_collection_centers.society_code}
            onChange={handleChange}
            containerStyles={styles.inputCont}
            disabled={true}
          />
        )
      }
    ]
  ];

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && <Text variant="BoldH22Sec">{getTitles('T-11036')}</Text>}
      <Box sx={styles.gridCont}>
        <UITextInputWithTitle
          value={state}
          containerStyles={globalStyles.gridState}
          title={getTitles('T-10070')}
          placeholder={getTitles('T-10350')}
          disabled={true}
        />

        <UITextInputWithTitle
          value={district}
          containerStyles={globalStyles.gridDistrict}
          title={getTitles('T-10069')}
          placeholder={getTitles('T-10350')}
          disabled={true}
        />

        <UITextInputWithTitle
          title={getTitles('T-10071')}
          placeholder={getTitles('T-10350')}
          containerStyles={globalStyles.gridTaluk}
          value={taluka}
          disabled={true}
        />
        <UITextInputWithTitle
          name="village"
          containerStyles={globalStyles.gridVillage}
          value={values.village}
          title={getTitles('T-10068')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          required={true}
          disabled={currRender === view}
          error={touched.village && errors.village}
        />

        <UITextInputWithTitle
          name="village_code"
          containerStyles={globalStyles.route}
          value={values.village_code || ''}
          title={getTitles('T-10806')}
          allowOnlyNumbers={true}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10642')}
          required={true}
          disabled={currRender === view}
          error={touched.village_code && errors.village_code}
        />

        <UITextInputWithTitle
          name="pincode"
          containerStyles={globalStyles.empCount}
          value={values.pincode}
          title={getTitles('T-10359')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10381')}
          required={true}
          error={touched.pincode && errors.pincode}
          maxLength={6}
          disabled={currRender === view}
        />
      </Box>

      <FormTitleCard title={getTitles('T-10807')}>
        {isFam === true && (
          <Box sx={{ width: '100%', mt: 3 }}>
            {!defaultValues.isResponsive ? (
              <>
                {currRender !== view && (
                  <UIDataTable
                    tableRows={tableData}
                    rowValueContainerStyle={styles.rowValueContainerStyle}
                    valuesSubStyle={styles.valuesSubStyle}
                    headerContStyle={styles.headerContStyle}
                  />
                )}
              </>
            ) : (
              <Box sx={styles.resInputSup}>
                {currRender !== view &&
                  tableData[0].map((item, idx) => (
                    <Box key={idx} sx={styles.resInputCont}>
                      <Text style={{ width: '50%' }} variant="D20">
                        {item.title}
                      </Text>
                      <Box style={{ width: '50%' }}>{item.values}</Box>
                    </Box>
                  ))}
              </Box>
            )}

            {currRender !== view && (
              <UISaveAndAdd
                onAddAnother={handleSaveAndAddAnother}
                title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
                disabled={!isValid || !dirty || sbDisabled}
              />
            )}
          </Box>
        )}
      </FormTitleCard>

      {!defaultValues.isResponsive ? (
        <Box sx={styles.detailsCont}>
          {milkCollectionCenter &&
            milkCollectionCenter.length > 0 &&
            milkCollectionCenter.map((item, idx) => (
              <Box key={idx} sx={{ width: '100%' }}>
                <TableCard data={getMilkCenterDetails(item, idx)} isHeader={false} />

                {isFam === idx && (
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <UIDataTable
                      tableRows={tableData}
                      rowValueContainerStyle={styles.rowValueContainerStyle}
                      valuesSubStyle={styles.valuesSubStyle}
                      headerContStyle={styles.headerContStyle}
                    />

                    {currRender !== view && (
                      <UISaveAndAdd
                        onAddAnother={handleSaveAndAddAnother}
                        title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
                        disabled={!isValid || !dirty || sbDisabled}
                      />
                    )}
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      ) : (
        <Box sx={styles.detailsCont}>
          {milkCollectionCenter.map((item, idx) => (
            <Box key={idx} sx={{ width: '100%' }}>
              <CollapseDropView
                title={item.name && societyList.find((elem) => elem._id === item.name).name}
                handleSubTitleClick={() => {
                  handleEditMilkCenter(item, idx);
                }}
                isSubtitle={false}
                open={isFam === idx}
                containerStyle={styles.collapseCont}
              />
              {isFam === idx && (
                <Box sx={{ ...styles.resInputSup, mt: 1 }}>
                  {tableData[0].map((item, idx) => (
                    <Box key={idx} sx={styles.resInputCont}>
                      <Text style={{ width: '50%' }} variant="D20">
                        {item.title}
                      </Text>
                      <Box style={{ width: '50%' }}>{item.values}</Box>
                    </Box>
                  ))}
                  {currRender !== view && (
                    <UISaveAndAdd
                      onAddAnother={handleSaveAndAddAnother}
                      title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
                      disabled={!isValid || !dirty || sbDisabled}
                    />
                  )}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}

      <UITextInputWithTitle
        name="nearest_dairy"
        title={getTitles('T-10810')}
        placeHolder={getTitles('T-10811')}
        value={values.nearest_dairy}
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyles={styles.phone}
        disabled={currRender === view}
      />

      <UITextInputWithTitle
        name="average_population"
        title={getTitles('T-10812')}
        placeHolder={getTitles('T-10813')}
        value={values.average_population}
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyles={styles.phone}
        disabled={currRender === view}
        allowOnlyNumbers={true}
      />

      <UITitleWithContent title={getTitles('T-10814')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.has_electricity}
            onClick={() => handleChange({ target: { name: 'has_electricity', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.has_electricity === false}
            onClick={() => handleChange({ target: { name: 'has_electricity', value: false } })}
          />
        </Box>
      </UITitleWithContent>

      <UITitleWithContent title={getTitles('T-10815')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.tar_road}
            onClick={() => handleChange({ target: { name: 'tar_road', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.tar_road === false}
            onClick={() => handleChange({ target: { name: 'tar_road', value: false } })}
          />
        </Box>
      </UITitleWithContent>

      <UITextInputWithTitle
        name="nearest_tar_road_distance"
        title={getTitles('T-10816')}
        placeHolder={getTitles('T-10952')}
        value={values.nearest_tar_road_distance}
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyles={styles.phone}
        allowOnlyNumbers={true}
        disabled={currRender === view}
      />

      <UITitleWithContent title={getTitles('T-10817')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.has_mobile_network}
            onClick={() => handleChange({ target: { name: 'has_mobile_network', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.has_mobile_network === false}
            onClick={() => {
              handleChange({ target: { name: 'has_mobile_network', value: false } });
              setFieldValue('available_mobile_networks', []);
            }}
          />
        </Box>
      </UITitleWithContent>

      {values.has_mobile_network && (
        <UIMultipleSelectCheckmarks
          name="available_mobile_networks"
          value={values.available_mobile_networks}
          title={getTitles('T-10818')}
          options={defaultValues.mobileNetworks}
          onChange={(val) =>
            handleChange({ target: { name: 'available_mobile_networks', value: val } })
          }
          placeholder={getTitles('T-10350')}
          preSelectedValue={values.available_mobile_networks}
          containerStyles={styles.phone}
          disabled={currRender === view}
          showSelect={currRender !== view}
          showTitles={false}
        />
      )}

      {values.available_mobile_networks?.length > 0 &&
        values.available_mobile_networks.map((item, idx) => (
          <UIChipButton
            key={idx}
            title={item.title}
            onDelete={() => {
              setFieldValue(
                'available_mobile_networks',
                handleMultiDelete(idx, values.available_mobile_networks)
              );
              setBtndisabled(false);
            }}
            showDelete={currRender !== view}
          />
        ))}

      <UITitleWithContent title={getTitles('T-10819')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.has_postal_service}
            onClick={() => handleChange({ target: { name: 'has_postal_service', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.has_postal_service === false}
            onClick={() => handleChange({ target: { name: 'has_postal_service', value: false } })}
          />
        </Box>
      </UITitleWithContent>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10045')}
        onSecondaryBtnClick={() => dispatch(updateVillageCurrPage(1))}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={currRender !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
