import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { useEffect, useRef, useState } from 'react';
import {
  getDistrictRequest,
  getTalukRequest,
  getVillageRequest
} from '../../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDistrict,
  getDistrictNextPage,
  getTaluka,
  getTalukaNextPage,
  getVillage,
  getVillageNextPage
} from '../../../../../services/redux/selectors/manageProccess';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewAccountDetails = ({ pfAccount, sbAccount, insurance, stateList }) => {
  const dispatch = useDispatch();

  const districts = useSelector(getDistrict);
  const talukas = useSelector(getTaluka);
  const villages = useSelector(getVillage);
  const districtNxtPg = useSelector(getDistrictNextPage);
  const villageNxtPge = useSelector(getVillageNextPage);
  const talukNxtPge = useSelector(getTalukaNextPage);

  const [districtList, setDistrictsList] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);

  const previousOptions = useRef({
    district: districtList?.length,
    village: villagesList?.length,
    taluka: talukasList?.length,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    if (sbAccount && stateList && stateList.length > 0) {
      dispatch(getDistrictRequest({ state_id: sbAccount?.state_id, page: 1 }));
      dispatch(getTalukRequest({ district_id: sbAccount?.district_id, page: 1 }));
      dispatch(getVillageRequest({ taluka_id: sbAccount?.taluka_id, page: 1 }));
    }
  }, [sbAccount, stateList]);

  useEffect(() => {
    if (districtList?.length !== previousOptions?.district?.length) {
      deduplicateAndSet(districtList, districts, setDistrictsList);
    }
    if (talukasList?.length !== previousOptions?.taluka?.length) {
      deduplicateAndSet(talukasList, talukas, setTalukasList);
    }
    if (villagesList?.length !== previousOptions?.village?.length) {
      deduplicateAndSet(villagesList, villages, setVillagesList);
    }
    if (districtNxtPg !== previousOptions?.districtNxtPage && districtNxtPg !== null) {
      dispatch(getDistrictRequest({ state_id: sbAccount?.state_id, page: districtNxtPg }));
    }
    if (talukNxtPge !== previousOptions?.talukaNextPage && talukNxtPge !== null) {
      dispatch(getTalukRequest({ district_id: sbAccount?.district_id, page: talukNxtPge }));
    }

    if (villageNxtPge !== previousOptions?.villageNxtPage && villageNxtPge !== null) {
      dispatch(getVillageRequest({ taluka_id: sbAccount?.taluka_id, page: villageNxtPge }));
    }

    previousOptions.current = {
      district: districtList?.length,
      village: villagesList?.length,
      taluka: talukasList?.length,
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districts, talukas, villages, districtNxtPg, villageNxtPge, talukNxtPge]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems);
  };

  return (
    <Box sx={styles.container}>
      {sbAccount && (
        <>
          <ViewData title={getTitles('T-10370')} subTitle={sbAccount.sb_account_number} />
          <ViewData title={getTitles('T-10404')} subTitle={sbAccount.bank_name} bullets={true} />
          <ViewData title={getTitles('T-10405')} subTitle={sbAccount.ifsc_code} bullets={true} />
          <ViewData
            title={getTitles('T-10407')}
            subTitle={`${sbAccount.branch_address_line_one ? [sbAccount.branch_address_line_one, sbAccount.branch_address_line_two].join(' ') : sbAccount.branch_address_line_two}`}
            bullets={true}
          />
          <ViewData title={getTitles('T-10363')} subTitle={sbAccount.country_id} bullets={true} />
          <ViewData
            title={getTitles('T-10070')}
            subTitle={stateList?.find((item) => item._id === sbAccount?.state_id)?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10069')}
            subTitle={districtList?.find((elem) => elem._id === sbAccount?.district_id)?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10071')}
            subTitle={talukasList?.find((elem) => elem._id === sbAccount?.taluka_id)?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10068')}
            subTitle={villagesList?.find((elem) => elem._id === sbAccount?.village_id)?.name}
            bullets={true}
          />
          <ViewData title={getTitles('T-10359')} subTitle={sbAccount.post_code} bullets={true} />
        </>
      )}
      {pfAccount && (
        <>
          <ViewData title={getTitles('T-10371')} subTitle={pfAccount.pf_account_number} />
          <ViewData
            title={getTitles('T-10409')}
            subTitle={pfAccount.pf_account_details}
            bullets={true}
          />
        </>
      )}

      {insurance && (
        <>
          <ViewData title={getTitles('T-10372')} subTitle={insurance.insurance_policy_number} />

          <ViewData
            title={getTitles('T-10655')}
            subTitle={insurance.insurance_provider}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10410')}
            subTitle={insurance.insurance_policy_details}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10411')}
            subTitle={getFormatDate(insurance.start_date)}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10412')}
            subTitle={getFormatDate(insurance.end_date)}
            bullets={true}
          />
        </>
      )}
    </Box>
  );
};
