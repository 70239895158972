import { Routes, Route, useLocation } from 'react-router-dom';
import { defaultValues, SCREEN_PATH } from '../constants';
import {
  Home,
  LoginPage,
  ManageState,
  ManageProcessPage,
  ProfilePage,
  ManageEmployee,
  ManageFarmer,
  RegisterFarmer,
  ManageAIworker,
  ManageVet,
  ManageSociety,
  ManageLaboratory,
  ManageCattle,
  CattleRegistration,
  ManageGovtOfficial,
  CallCenter,
  PrivacyPolicy,
  Disclaimer,
  ShippingPolicy
} from '../pages';

import { Dashboard } from '../pages/dashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authStatus, getMainMenu, getUserData } from '../services/redux/selectors';
import { AssociatedFarmers, ManageDistrict, ManageTaluk, ManageVillage } from '../components';
import { updateMainMenu } from '../services/redux/slices';
import { Fallback } from '../pages/fallback-404';
import { ManageInsurance } from '../pages/manage-insurance';

export default function RootNavigation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(authStatus);
  const mainMenu = useSelector(getMainMenu);
  const userData = useSelector(getUserData);
  const roles = [defaultValues.userRole.superAdmin, defaultValues.userRole.employee];

  const currentRoute = (main) => {
    if (
      (main === 5 &&
        [
          SCREEN_PATH.REGISTER_FARMER,
          SCREEN_PATH.MANAGE_CATTLE,
          SCREEN_PATH.REGISTER_CATTLE
        ].includes(location.pathname)) ||
      (main === 4 &&
        [
          SCREEN_PATH.MANAGE_VILLAGE,
          SCREEN_PATH.MANAGE_TALUK,
          SCREEN_PATH.MANAGE_DISTRICT
        ].includes(location.pathname))
    )
      return 1;
  };

  useEffect(() => {
    if (isAuthenticated && roles.includes(userData.user_role)) {
      if (mainMenu) {
        if (currentRoute(mainMenu)) {
          return;
        }
        navigate(defaultValues.superAdminMenu[mainMenu - 1].route);
      } else {
        dispatch(updateMainMenu({ id: 1 }));
        navigate(defaultValues.superAdminMenu[0].route);
      }
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path={SCREEN_PATH.DASHBOARD} element={<Dashboard />} />
        <Route path={SCREEN_PATH.PROFILE} element={<ProfilePage />} />
        <Route path={SCREEN_PATH.MANAGE_STATE} element={<ManageState />} />
        <Route path={SCREEN_PATH.MANAGE_DISTRICT} element={<ManageDistrict />} />
        <Route path={SCREEN_PATH.MANAGE_TALUK} element={<ManageTaluk />} />
        <Route path={SCREEN_PATH.MANAGE_VILLAGE} element={<ManageVillage />} />
        <Route path={SCREEN_PATH.MANAGE_PROCESS} element={<ManageProcessPage />} />
        <Route path={SCREEN_PATH.MANAGE_EMPLOYEE} element={<ManageEmployee />} />
        <Route path={SCREEN_PATH.MANAGE_VET} element={<ManageVet />} />
        <Route path={SCREEN_PATH.MANAGE_AI_WORKER} element={<ManageAIworker />} />
        <Route path={SCREEN_PATH.MANAGE_SOCIETY} element={<ManageSociety />} />
        <Route exact path={SCREEN_PATH.MANAGE_FARMER} element={<ManageFarmer />} />
        <Route exact path={SCREEN_PATH.REGISTER_FARMER} element={<RegisterFarmer />} />
        <Route exact path={SCREEN_PATH.MANAGE_INSURANCE} element={<ManageInsurance />} />
        <Route exact path={SCREEN_PATH.MANAGE_LABORATORY} element={<ManageLaboratory />} />
        <Route exact path={SCREEN_PATH.MANAGE_CATTLE} element={<ManageCattle />} />
        <Route exact path={SCREEN_PATH.REGISTER_CATTLE} element={<CattleRegistration />} />
        <Route exact path={SCREEN_PATH.GOVT_OFFICIAL} element={<ManageGovtOfficial />} />
        <Route exact path={SCREEN_PATH.ASSOCIATED_FARMER} element={<AssociatedFarmers />} />
        <Route exact path={SCREEN_PATH.CALL_CENTER} element={<CallCenter />} />
        <Route exact path={SCREEN_PATH.FALLBACK} element={<Fallback />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path={SCREEN_PATH.HOME} element={<Home />} />
        <Route path={SCREEN_PATH.LOGIN} element={<LoginPage />} />
        <Route exact path={SCREEN_PATH.FALLBACK} element={<Fallback />} />
        <Route exact path={SCREEN_PATH.POLICY} element={<PrivacyPolicy />} />
        <Route exact path={SCREEN_PATH.DISCLAIMER} element={<Disclaimer />} />
        <Route exact path={SCREEN_PATH.SHIPPING_POLICY} element={<ShippingPolicy />} />
      </Routes>
    );
  }
}
