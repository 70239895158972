import { Box } from '@mui/material';
import {
  DatePickers,
  Text,
  UIButton,
  UIRadioButton,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { TaskInfoCard } from '../../../common';
import { getTitles } from '../../../../utils';
import { defaultValues } from '../../../../constants';
import { styles } from './styles';

export const CallCenterCommonField = ({
  showModel,
  info,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  handleRemove,
  handleAddAnother
}) => {
  const { add, edit, view } = defaultValues.actionType;
  return (
    <Box sx={styles.container}>
      {[edit, view].includes(showModel?.mode) && info?.length > 0 && (
        <Box sx={styles.detailsCont}>
          {info.map((item, idx) => (
            <TaskInfoCard
              key={idx}
              containerStyles={styles.cardCont}
              title1={item.title1}
              title2={item.title2}
              address={item.address}
              icon={item.icon}
              heading={item.heading}
            />
          ))}
        </Box>
      )}
      <Box sx={styles.nameCont}>
        {[edit, view].includes(showModel?.mode) && (
          <UITextInputWithTitle
            name="task_id"
            value={values.task_id}
            title={getTitles('T-11278')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            disabled={showModel?.mode === view}
            inputStyle={styles.input}
          />
        )}
        <UITextInputWithTitle
          name="task_name"
          value={values.task_name}
          title={getTitles('T-11264')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          error={touched.name && errors.name}
          width={styles.width}
          disabled={showModel?.mode === view}
          inputStyle={styles.input}
        />

        {showModel?.mode === add && (
          <UISelect
            name="task_type"
            value={values.task_type}
            title={getTitles('T-11248')}
            options={defaultValues.genderOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        )}
        <UISelect
          name="priority"
          value={values.priority}
          title={getTitles('T-11266')}
          options={defaultValues.genderOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
        />
      </Box>

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="description"
          value={values.description}
          title={getTitles('T-10109')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          width={styles.longWidth}
          disabled={showModel?.mode === view}
          rows={2}
        />
        <DatePickers
          dateValue={showModel?.mode === add ? values.due_date : values.created_date}
          onDateChange={(val) =>
            handleChange({
              target: { value: val, name: showModel?.mode === add ? 'due_date' : 'created_date' }
            })
          }
          title={showModel?.mode === add ? getTitles('T-11251') : getTitles('T-11282')}
          width={styles.width}
          disabled={showModel?.mode === view}
          containerStyles={styles.dateCont}
          inputStyle={styles.dateInput}
        />
      </Box>

      {[edit, view].includes(showModel?.mode) && (
        <Box sx={styles.nameCont}>
          <UISelect
            name="task_type"
            value={values.task_type}
            title={getTitles('T-11248')}
            options={defaultValues.genderOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
          <UISelect
            name="task_status"
            value={values.task_status}
            title={getTitles('T-11279')}
            options={defaultValues.genderOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
          <UISelect
            name="call_center_status"
            value={values.call_center_status}
            title={getTitles('T-11249')}
            options={defaultValues.genderOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        </Box>
      )}

      <Box sx={styles.nameCont}>
        {[edit, view].includes(showModel?.mode) && (
          <DatePickers
            dateValue={values.due_date}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'due_date' } })}
            title={getTitles('T-11251')}
            width={styles.width}
            disabled={showModel?.mode === view}
            containerStyles={styles.dateCont}
            inputStyle={styles.dateInput}
          />
        )}
        <UITitleWithContent title={getTitles('T-11267')} containerStyle={{ width: styles.width }}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.repeat === 1}
              onClick={() => handleChange({ target: { name: 'repeat', value: 1 } })}
              disabled={showModel?.mode === view}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.repeat === 2}
              onClick={() =>
                handleChange({
                  target: { name: 'repeat', value: 2 }
                })
              }
              disabled={showModel?.mode === view}
            />
          </Box>
        </UITitleWithContent>

        {values.repeat === 1 && (
          <UITextInputWithTitle
            name="no_of_days"
            value={values.no_of_days}
            title={getTitles('T-11268')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            disabled={showModel?.mode === view}
            inputStyle={styles.input}
          />
        )}
      </Box>

      <Box sx={styles.nameCont}>
        <UISelect
          name="assign_to"
          value={values.assign_to}
          title={getTitles('T-11265')}
          options={defaultValues.genderOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
        />
        <UISelect
          name="notify_to"
          value={values.notify_to}
          title={getTitles('T-11262')}
          options={defaultValues.genderOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
        />
        <UISelect
          name="remind_to"
          value={values.remind_to}
          title={getTitles('T-11271')}
          options={defaultValues.genderOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
        />
      </Box>

      <Box sx={styles.remaindSub}>
        <Text variant="D20" style={{ mt: 1 }}>
          {getTitles('T-11272')}
        </Text>
        {values.remind_on?.map((item, idx) => (
          <Box key={idx} sx={styles.remindCont}>
            <UITextInputWithTitle
              name={`remind_on[${idx}].days`}
              value={item.days}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={getTitles('T-10384')}
              width={styles.width}
              disabled={showModel?.mode === view}
              inputStyle={styles.input}
            />
            <UISelect
              name={`remind_on[${idx}].remind_at`}
              value={item.remind_at}
              options={defaultValues.genderOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={getTitles('T-10350')}
              width={styles.width}
              inputStyle={styles.remindInput}
            />
            <UISelect
              name={`remind_on[${idx}].timing`}
              value={item.timing}
              options={defaultValues.genderOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={getTitles('T-10350')}
              width={styles.width}
              inputStyle={styles.remindInput}
            />
            <UIButton
              title={'-'}
              style={styles.btn(idx === values.remind_on?.length - 1)}
              onClick={() => handleRemove(idx)}
            />
          </Box>
        ))}
        <UISaveAndAdd onAddAnother={handleAddAnother} />
      </Box>
    </Box>
  );
};
