import { Routes, Route, useLocation } from 'react-router-dom';
import { defaultValues, SCREEN_PATH } from '../constants';
import {
  Home,
  LoginPage,
  ManageState,
  ManageProcessPage,
  ProfilePage,
  ManageEmployee,
  ManageFarmer,
  RegisterFarmer,
  ManageAIworker,
  ManageVet,
  ManageSociety,
  ManageLaboratory,
  ManageCattle,
  CattleRegistration,
  ManageGovtOfficial,
  CallCenter,
  PrivacyPolicy,
  Disclaimer,
  ShippingPolicy,
  ManageVillageForVillageManager,
  ManageInventory,
  ManageTasksCommon,
  EcommerceHome,
  LabFarmer,
  TermsConditions,
  AboutUs,
  ContactUs
} from '../pages';

import { Dashboard } from '../pages/dashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authStatus, getEcomProfile, getMainMenu, getUserData } from '../services/redux/selectors';
import {
  AssociatedFarmers,
  CattleTaskDetailsCallCenter,
  ManageDistrict,
  ManageTaluk,
  ManageVillage,
  ManageInsuranceCattleDetails,
  ManageInsuranceCattleTable,
  ManageInsuranceFarmerTable,
  EcomMain,
  EcomActivity,
  EcomProductDetails,
  ShoppingCart,
  EcomMyOrders
} from '../components';
import { updateMainMenu } from '../services/redux/slices';
import { Fallback } from '../pages/fallback-404';
import { ManageInsurance } from '../pages/manage-insurance';
import {
  LabFarmerCattleTable,
  LabInchargeCattleDetails,
  LabInchargeLabReports
} from '../components/HOC/lab-farmer';
import { ManageCallCenterAgents } from '../pages/manage-call-center';

export default function RootNavigation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(authStatus);
  const isEcomProfile = useSelector(getEcomProfile);
  const mainMenu = useSelector(getMainMenu);
  const userData = useSelector(getUserData);
  const roles = [
    defaultValues.userRole.superAdmin,
    defaultValues.userRole.employee,
    defaultValues.userRole.insurance,
    defaultValues.userRole.govtOfficials
  ];

  const currentRoute = (main) => {
    if (
      (main === 5 &&
        [
          SCREEN_PATH.REGISTER_FARMER,
          SCREEN_PATH.MANAGE_CATTLE,
          SCREEN_PATH.REGISTER_CATTLE
        ].includes(location.pathname)) ||
      ([4, 16].includes(main) &&
        [
          SCREEN_PATH.MANAGE_VILLAGE,
          SCREEN_PATH.MANAGE_TALUK,
          SCREEN_PATH.MANAGE_DISTRICT
        ].includes(location.pathname)) ||
      (main === 14 && [SCREEN_PATH.CATTLE_TASK_DETAILS_CALL_CENTER].includes(location.pathname)) ||
      location.pathname.includes(SCREEN_PATH.ECOMMERCE) ||
      (isEcomProfile && location.pathname.includes(SCREEN_PATH.PROFILE))
    )
      return 1;
  };

  useEffect(() => {
    if (isAuthenticated && roles.includes(userData?.user_role)) {
      if (mainMenu) {
        if (currentRoute(mainMenu)) {
          return;
        }
        navigate(
          defaultValues
            .superAdminMenu(userData?.user_role === 1)
            .find((item) => item.id === mainMenu)?.route
        );
      } else {
        dispatch(updateMainMenu({ id: 1 }));
        navigate(defaultValues.superAdminMenu(userData?.user_role === 1)[0].route);
      }
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path={SCREEN_PATH.DASHBOARD} element={<Dashboard />} />
        <Route path={SCREEN_PATH.PROFILE} element={<ProfilePage />} />
        <Route path={SCREEN_PATH.MANAGE_STATE} element={<ManageState />} />
        <Route path={SCREEN_PATH.MANAGE_DISTRICT} element={<ManageDistrict />} />
        <Route path={SCREEN_PATH.MANAGE_TALUK} element={<ManageTaluk />} />
        <Route path={SCREEN_PATH.MANAGE_VILLAGE} element={<ManageVillage />} />
        <Route path={SCREEN_PATH.MANAGE_PROCESS} element={<ManageProcessPage />} />
        <Route path={SCREEN_PATH.MANAGE_EMPLOYEE} element={<ManageEmployee />} />
        <Route path={SCREEN_PATH.MANAGE_VET} element={<ManageVet />} />
        <Route path={SCREEN_PATH.MANAGE_AI_WORKER} element={<ManageAIworker />} />
        <Route path={SCREEN_PATH.MANAGE_SOCIETY} element={<ManageSociety />} />
        <Route exact path={SCREEN_PATH.MANAGE_FARMER} element={<ManageFarmer />} />
        <Route exact path={SCREEN_PATH.REGISTER_FARMER} element={<RegisterFarmer />} />
        <Route exact path={SCREEN_PATH.MANAGE_INSURANCE} element={<ManageInsurance />} />
        <Route
          exact
          path={SCREEN_PATH.MANAGE_INSURANCE_FARMERS_INFO}
          element={<ManageInsuranceFarmerTable />}
        />
        <Route
          path={`${SCREEN_PATH.MANAGE_INSURANCE_FARMER_CATTLES_LIST}/:farmer_id`}
          element={<ManageInsuranceCattleTable />}
        />
        <Route
          path={`${SCREEN_PATH.MANAGE_INSURANCE_CATTLE_DETAILS}/:farmer_id/:cattle_id`}
          element={<ManageInsuranceCattleDetails />}
        />
        <Route exact path={SCREEN_PATH.MANAGE_LABORATORY} element={<ManageLaboratory />} />
        <Route exact path={SCREEN_PATH.MANAGE_CATTLE} element={<ManageCattle />} />
        <Route exact path={SCREEN_PATH.REGISTER_CATTLE} element={<CattleRegistration />} />
        <Route exact path={SCREEN_PATH.GOVT_OFFICIAL} element={<ManageGovtOfficial />} />
        <Route exact path={SCREEN_PATH.ASSOCIATED_FARMER} element={<AssociatedFarmers />} />
        <Route exact path={SCREEN_PATH.CALL_CENTER} element={<CallCenter />} />
        <Route
          exact
          path={SCREEN_PATH.CATTLE_TASK_DETAILS_CALL_CENTER}
          element={<CattleTaskDetailsCallCenter />}
        />
        <Route
          exact
          path={SCREEN_PATH.MANAGE_VILLAGE_FOR_VILLAGE_MANAGER}
          element={<ManageVillageForVillageManager />}
        />
        <Route exact path={SCREEN_PATH.MANAGE_INVENTORY} element={<ManageInventory />} />
        <Route exact path={SCREEN_PATH.TASKS} element={<ManageTasksCommon />} />
        <Route exact path={`${SCREEN_PATH.ECOMMERCE}`} element={<EcommerceHome />}>
          <Route index element={<EcomMain />} />
          <Route path={SCREEN_PATH.ACTIVITY} element={<EcomActivity />} />
          <Route
            path={`${SCREEN_PATH.PRODUCT_DETAILS}${SCREEN_PATH.ID}`}
            element={<EcomProductDetails />}
          />
          <Route path={SCREEN_PATH.SHOPPING_CART} element={<ShoppingCart />} />
          <Route path={SCREEN_PATH.MY_ORDERS} element={<EcomMyOrders />} />
        </Route>
        <Route exact path={SCREEN_PATH.LAB_FARMER} element={<LabFarmer />} />
        <Route
          path={`${SCREEN_PATH.LAB_FARMER_CATTLE_LIST}/:farmer_id`}
          element={<LabFarmerCattleTable />}
        />
        <Route
          path={`${SCREEN_PATH.LAB_FARMER_CATTLE_DETAILS}/:cattle_id`}
          element={<LabInchargeCattleDetails />}
        />
        <Route
          path={`${SCREEN_PATH.LAB_FARMER_CATTLE_REPORTS}/:cattle_id`}
          element={<LabInchargeLabReports />}
        />
        <Route path={SCREEN_PATH.MANAGE_CALL_CENTER} element={<ManageCallCenterAgents />} />

        <Route exact path={SCREEN_PATH.FALLBACK} element={<Fallback />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path={SCREEN_PATH.HOME} element={<Home />} />
        <Route path={SCREEN_PATH.LOGIN} element={<LoginPage />} />
        <Route exact path={SCREEN_PATH.FALLBACK} element={<Fallback />} />
        <Route exact path={SCREEN_PATH.POLICY} element={<PrivacyPolicy />} />
        <Route exact path={SCREEN_PATH.TERMS_CONDITIONS} element={<TermsConditions />} />
        <Route exact path={SCREEN_PATH.ABOUT_US} element={<AboutUs />} />
        <Route exact path={SCREEN_PATH.CONTACT_US} element={<ContactUs />} />
        <Route exact path={SCREEN_PATH.DISCLAIMER} element={<Disclaimer />} />
        <Route exact path={SCREEN_PATH.SHIPPING_POLICY} element={<ShippingPolicy />} />
        <Route path={`${SCREEN_PATH.ECOMMERCE}`} element={<EcommerceHome />}>
          <Route index element={<EcomMain />} />
          <Route path={SCREEN_PATH.ACTIVITY} element={<EcomActivity />} />
          <Route
            path={`${SCREEN_PATH.PRODUCT_DETAILS}${SCREEN_PATH.ID}`}
            element={<EcomProductDetails />}
          />

          <Route path={SCREEN_PATH.SHOPPING_CART} element={<ShoppingCart />} />
          <Route path={SCREEN_PATH.MY_ORDERS} element={<EcomMyOrders />} />
        </Route>
      </Routes>
    );
  }
}
