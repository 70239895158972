import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme';

const styles = {
  container: (style) => ({
    width: '100%',
    marginTop: '10px',
    ...style
  }),
  asterisk: {
    color: color.errorText
  }
};
export const UITitleWithContent = ({
  title,
  children,
  required,
  error,
  containerStyle,
  variant = 'D20'
}) => {
  return (
    <Box sx={styles.container(containerStyle)}>
      {title && (
        <Text variant={variant}>
          {title} {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      {children}
      {error && <Text variant="fieldError">{error}</Text>}
    </Box>
  );
};
