import { Box } from '@mui/material';
import { Text } from '../../../../ui-kits';
import { color } from '../../../../theme';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../../../constants';
import { getTitles } from '../../../../utils';

export const UICheckboxChip = ({ value, title, onChange, children, containerStyles }) => {
  const styles = {
    container: (styles) => ({
      width: '100%',
      padding: defaultValues.isResponsive ? '5px' : '10px',
      boxSizing: 'border-box',
      border: `1px solid ${color.primaryBorder}`,
      borderRadius: '5px',
      margin: '10px 0px',
      ...styles
    }),
    subSuper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px'
    },
    subCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    },
    checkIcon: {
      '&.Mui-checked': {
        color: color.primary
      }
    },
    children: {
      width: '100%'
    }
  };

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    onChange(e);
  };

  return (
    <Box sx={styles.container(containerStyles)}>
      <Box sx={styles.subSuper}>
        <Text variant="D20">{title}</Text>
        <Box sx={styles.subCont}>
          <Checkbox checked={checked} onChange={handleChange} sx={styles.checkIcon} />
          <Text variant="D20">{getTitles('T-10080')}</Text>
        </Box>
      </Box>
      <Box sx={styles.children}>{children}</Box>
    </Box>
  );
};
