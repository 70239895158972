import { put, takeLatest } from 'redux-saga/effects';
import {
  ecomLoginFailure,
  ecomLoginRequest,
  ecomLoginSuccess,
  ecomResendOtpFailure,
  ecomResendOtpRequest,
  ecomResendOtpSuccess,
  ecomVerifyLoginFailure,
  ecomVerifyLoginRequest,
  ecomVerifyLoginSuccess,
  editProfileFailure,
  editProfileRequest,
  editProfileSuccess,
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  profilePicChangeFailure,
  profilePicChangeRequest,
  profilePicChangeSuccess,
  profilePicDeleteFailure,
  profilePicDeleteRequest,
  profilePicDeleteSuccess,
  resendOtpFailure,
  resendOtpRequest,
  resendOtpSuccess,
  verifyLoginFailure,
  verifyLoginRequest,
  verifyLoginSuccess
} from '../slices/authSlice';
import {
  changeProPicApi,
  deleteProPicApi,
  ecomLoginAPI,
  ecomResendOtpAPI,
  ecomVerifyLoginAPI,
  editProfileAPI,
  getProfileAPI,
  loginAPI,
  resendOtpAPI,
  verifyLoginAPI
} from '../../api';
import { KEYS } from '../../../constants';

function* login(action) {
  try {
    const response = yield loginAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(loginSuccess(response.data));
    } else {
      yield put(loginFailure(response.data));
    }
  } catch (error) {
    yield put(loginFailure(error.response.data));
  }
}

function* verifyLogin(action) {
  try {
    const response = yield verifyLoginAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(verifyLoginSuccess(response.data));
    } else {
      yield put(verifyLoginFailure(response.data));
    }
  } catch (error) {
    yield put(verifyLoginFailure(error.response.data));
  }
}

function* resendOtp(action) {
  try {
    const response = yield resendOtpAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(resendOtpSuccess(response.data));
    } else {
      yield put(resendOtpFailure(response.data));
    }
  } catch (error) {
    yield put(resendOtpFailure(error.response.data));
  }
}

function* getProfile() {
  try {
    const response = yield getProfileAPI();
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getProfileSuccess(response.data));
    } else {
      yield put(getProfileFailure(response.data));
    }
  } catch (error) {
    yield put(getProfileFailure(error.response.data));
  }
}

function* editProfile(action) {
  try {
    const response = yield editProfileAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(editProfileSuccess(response.data));
    } else {
      yield put(editProfileFailure(response.data));
    }
  } catch (error) {
    yield put(editProfileFailure(error.response.data));
  }
}

function* changeProPic(action) {
  try {
    const response = yield changeProPicApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(profilePicChangeSuccess(response.data));
    } else {
      yield put(profilePicChangeFailure(response.data));
    }
  } catch (error) {
    yield put(profilePicChangeFailure(error.response.data));
  }
}

function* deleteProPic() {
  try {
    const response = yield deleteProPicApi();
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(profilePicDeleteSuccess(response.data));
    } else {
      yield put(profilePicDeleteFailure(response.data));
    }
  } catch (error) {
    yield put(profilePicChangeFailure(error.response.data));
  }
}

function* ecomLogin(action) {
  try {
    const response = yield ecomLoginAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(ecomLoginSuccess(response.data));
    } else {
      yield put(ecomLoginFailure(response.data));
    }
  } catch (error) {
    yield put(ecomLoginFailure(error.response.data));
  }
}

function* ecomResendOtp(action) {
  try {
    const response = yield ecomResendOtpAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(ecomResendOtpSuccess(response.data));
    } else {
      yield put(ecomResendOtpFailure(response.data));
    }
  } catch (error) {
    yield put(ecomResendOtpFailure(error.response.data));
  }
}

function* ecomVerifyLogin(action) {
  try {
    const response = yield ecomVerifyLoginAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(ecomVerifyLoginSuccess(response.data));
    } else {
      yield put(ecomVerifyLoginFailure(response.data));
    }
  } catch (error) {
    yield put(ecomVerifyLoginFailure(error.response.data));
  }
}

export function* authSaga() {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(verifyLoginRequest.type, verifyLogin);
  yield takeLatest(getProfileRequest.type, getProfile);
  yield takeLatest(editProfileRequest.type, editProfile);
  yield takeLatest(profilePicChangeRequest.type, changeProPic);
  yield takeLatest(profilePicDeleteRequest.type, deleteProPic);
  yield takeLatest(resendOtpRequest.type, resendOtp);
  yield takeLatest(ecomLoginRequest.type, ecomLogin);
  yield takeLatest(ecomResendOtpRequest.type, ecomResendOtp);
  yield takeLatest(ecomVerifyLoginRequest.type, ecomVerifyLogin);
}
