import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme';
import { defaultValues } from '../../constants';

//demo data for tabs
// const demoTab = [
//   { id: 1, title: 'Manage State' },
// ];

export const UITabs = ({ getSelectedTab, style, tabItems }) => {
  const [selectedTab, setSelectedTab] = useState(tabItems && tabItems[0].id);

  const handleTabClick = (itemId) => {
    setSelectedTab(itemId);
    getSelectedTab && getSelectedTab(itemId);
  };

  const defaultStyles = {
    container: {
      width: '100%',
      backgroundColor: color.primaryBackground,
      borderRadius: 2,
      display: 'flex',
      overflowX: 'auto',
      scrollBehavior: 'smooth',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      marginTop: '20px'
    },
    tabContainer: (id) => ({
      minWidth: defaultValues.isResponsive ? '170px' : '250px',
      px: 1,
      backgroundColor: selectedTab === id ? color.primary : color.primaryBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: defaultValues.isResponsive ? '40px' : '50px',
      cursor: 'pointer'
    }),
    textStyle: (id) => ({
      fontWeight: 'bold',
      color: selectedTab === id ? color.tertiaryText : color.primaryText
    })
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  return (
    tabItems && (
      <Box sx={styles.container}>
        {tabItems.map((item) => (
          <Box
            key={item.id}
            sx={styles.tabContainer(item.id)}
            onClick={() => handleTabClick(item.id)}>
            <Text variant="D24" style={styles.textStyle(item.id)}>
              {item.title}
            </Text>
          </Box>
        ))}
      </Box>
    )
  );
};

export default UITabs;
