import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const res = defaultValues.isResponsive;
const sm = defaultValues.isMdSmall;
const md = defaultValues.isMedium;
const lg = defaultValues.isLarge;

export const HeaderStyles = {
  container: (isAuthenticated) => ({
    background: isAuthenticated
      ? res
        ? color.secondary
        : color.secondaryBackground
      : color.secondary,
    width: '100%',
    height: res ? '60px' : '100px',
    padding: '0% 2%',
    boxSizing: 'border-box'
  }),
  subContainer: (isAuthenticated) => ({
    padding: isAuthenticated ? '0% 1%' : '0%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    boxShadow: isAuthenticated && 3,
    height: '100%'
  }),

  logoStyles: {
    width: '20%',
    maxWidth: res ? '40px' : md ? '50px' : lg ? '60px' : '80px',
    maxHeight: res ? '40px' : md ? '50px' : lg ? '60px' : '80px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '2%'
  },
  toolbar: (isAuthenticated) => ({
    display: 'flex',
    gap: '10px',
    paddingRight: !isAuthenticated && '24px',
    height: '100%'
  }),
  toolOptionsText: {
    color: color.primaryText,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  toolCont: {
    padding: '2%',
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  toolSuperCont: {
    flex: 1,
    display: 'flex',
    justifyContent: 'right'
  },
  loginBtn: {
    fontSize: sm ? '14px' : res ? '16px' : md ? '20px' : '24px',
    padding: sm ? '3px 20px' : '5px 50px',
    boxSizing: 'border-box'
  },
  autoStyle: {
    '& .MuiInputBase-root': {
      paddingRight: '23px !important',
      padding: '2px !important'
    }
  },
  autoCont: {
    display: res ? 'none' : 'block',
    width: res ? '120px' : '130px',
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    paddingRight: 0
  },
  languageCont: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: res ? '0px' : '4px',
    boxSizing: 'border-box',
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    backgroundColor: res ? color.secondary : color.primaryBackground
  },
  paperBox: {
    paddingRight: '0px'
  },
  textInp: {
    '& fieldset': { border: 'none' }
  },
  langImg: {
    width: '24px',
    height: '24px'
  },
  badge: {
    mr: 1,
    '& .MuiBadge-badge': {
      right: 10,
      top: 5,
      padding: '0 4px',
      fontSize: '10px',
      backgroundColor: color.secondary,
      border: res ? '1px solid' : 'none'
    }
  },
  avatar: {
    height: res ? '24px' : '32px',
    width: res ? '24px' : '32px'
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    backgroundColor: res ? color.secondary : color.primaryBackground,
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    padding: '0px 5px'
  },
  profileSuperCont: {
    display: 'flex',
    gap: res ? '0px' : '10px'
  },
  dialogueCont: {
    position: 'absolute',
    top: '2%',
    right: '10%',
    bottom: 'unset',
    left: 'unset',
    padding: 0
  },
  dialogue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: 0,
    maxHeight: '300px',
    width: 'fit-content',
    overflow: 'auto'
  },
  dBtn: {
    width: '100%',
    color: color.primaryText,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: sm ? '0.875' : res ? '1rem' : '1.25rem',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '0px',
    padding: '5px 30px',
    boxSizing: 'border-box',
    maxWidth: '300px'
  },
  drawer: {
    '& .MuiPaper-root': {
      width: '250px'
    }
  }
};
