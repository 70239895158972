import { useEffect, useMemo, useState } from 'react';
import { defaultValues } from '../../../constants';
import {
  DatePickers,
  Text,
  UIButton,
  UIEditIcon,
  UIminusDeleteButton,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import { formatDates, getTitles, getTitlesByValue, processRule } from '../../../utils';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  getProfilingActionType,
  getProfilingData
} from '../../../services/redux/selectors/manageProccess';
import { Styles } from './styles';

export const Conditions = ({ onData }) => {
  const { values, errors, touched, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      rule_type: 2,
      rules: {
        subject: '',
        condition_number: '',
        condition_number_min_value: '',
        condition_number_max_value: '',
        condition_string: '',
        condition_string_value: '',
        condition_date: '',
        condition_date_start_value: null,
        condition_date_end_value: null,
        condition_date_days_value: '',
        condition_boolean: '',
        condition_boolean_value: ''
      }
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });
  const { add } = defaultValues.actionType;

  const actionType = useSelector(getProfilingActionType);
  const profile = useSelector(getProfilingData);

  const [subjects, setSubjects] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [conditionType, setConditionType] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);
  const [selectedCondition, setSelectCondition] = useState(false);
  const [rules, setRules] = useState([]);
  const [isModified, setIsModified] = useState(false);

  const subjectOptions = {
    conditionSubject: defaultValues.conditionSubject,
    conditionSubjectFarmer: defaultValues.conditionSubjectFarmer,
    conditionSubjectEmployee: defaultValues.conditionSubjectEmployee,
    conditionSubjectLab: defaultValues.conditionSubjectLab,
    conditionSubjectSociety: defaultValues.conditionSubjectSociety
  };
  const subjectMapping = useMemo(
    () => ({
      1: subjectOptions.conditionSubject,
      2: subjectOptions.conditionSubjectFarmer,
      3: subjectOptions.conditionSubjectEmployee,
      4: subjectOptions.conditionSubjectLab,
      5: subjectOptions.conditionSubjectSociety
    }),
    [subjectOptions]
  );
  useEffect(() => {
    const selectedProfileId = profile?.flow_type?.id;
    const subjects = subjectMapping[selectedProfileId] || defaultValues.conditionSubject;
    setSubjects(subjects);
  }, [profile?.flow_type, subjectMapping]);

  useEffect(() => {
    if (profile?.rules_details?.rules) {
      const filteredRules = profile.rules_details.rules.map(processRule);
      setRules(filteredRules);
    }

    if (profile?.rules_details?.rule_type?.id) {
      setFieldValue('rule_type', profile.rules_details.rule_type.id);
      setSelectCondition(true);
    }
  }, [profile]);

  const conditionMappings = {
    text: {
      options: defaultValues.conditionsForString,
      type: defaultValues.conditionString,
      value: 'condition_string_value'
    },
    number: {
      options: defaultValues.conditionForNumber,
      type: defaultValues.conditionNumber,
      value: 'condition_number_min_value'
    },
    date: {
      options: defaultValues.conditionForDate,
      type: defaultValues.conditionDate,
      value: 'condition_date_start_value'
    },
    boolean: {
      options: defaultValues.conditionForBoolean,
      type: defaultValues.conditionBoolean,
      value: 'condition_boolean_value'
    }
  };

  useEffect(() => {
    const subjectId = values.rules?.subject;
    const subject = subjects?.find((item) => item.value === subjectId);

    if (subject) {
      const { options, type: conditionType, value } = conditionMappings[subject.type] || {};
      if (options && conditionType) {
        setConditionOptions(options);
        setConditionType(conditionType);
        setConditionValue(value);
      }
    }
  }, [values.rules?.subject, subjects]);

  const handleCondition = (e) => {
    const { name, value } = e.target;
    const processedValue = conditionType === defaultValues.conditionNumber ? Number(value) : value;
    if (name === 'rules.subject') {
      setFieldValue('rules', {
        condition_number: '',
        condition_number_min_value: '',
        condition_number_max_value: '',
        condition_string: '',
        condition_string_value: '',
        condition_date: '',
        condition_date_start_value: null,
        condition_date_end_value: null,
        condition_date_days_value: '',
        condition_boolean: '',
        condition_boolean_value: ''
      });
    }
    if (name === `rules.${conditionType}`) {
      setFieldValue('rules', {
        ...values.rules,
        condition_number_min_value: '',
        condition_number_max_value: '',
        condition_string_value: '',
        condition_date_start_value: null,
        condition_date_end_value: null,
        condition_date_days_value: '',
        condition_boolean_value: ''
      });
    }

    setFieldValue(name, processedValue);
  };

  const addRule = () => {
    let filteredRules = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(values.rules).filter(([key, value]) => value !== '' && value !== null)
    );

    setFieldValue('rules', {
      subject: '',
      condition_number: '',
      condition_number_min_value: '',
      condition_number_max_value: '',
      condition_string: '',
      condition_string_value: '',
      condition_date: '',
      condition_date_start_value: null,
      condition_date_end_value: null,
      condition_date_days_value: '',
      condition_boolean: '',
      condition_boolean_value: ''
    });

    return filteredRules;
  };

  const handleAddRule = () => {
    const newRule = addRule();
    setRules((prev) => [...prev, newRule]);
  };

  const handleNext = () => {
    const newRule = addRule();
    if (newRule && Object.keys(newRule).length > 0) {
      const updatedRules = [...rules, newRule];
      setRules(updatedRules);
      onData({ ruleType: values.rule_type, rules: updatedRules });
    } else {
      onData({ ruleType: values.rule_type, rules: [] });
    }
    setIsModified(false);
    setSelectCondition(true);
  };

  const handleDeleteCondition = (index) => {
    setRules((prevRules) => {
      const updatedRules = prevRules.filter((_, i) => i !== index);
      setIsModified(true);
      return updatedRules;
    });
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.subContainer}>
        {selectedCondition ? (
          <Box sx={Styles.conditionFlex}>
            <Box>
              {rules.length > 0 ? (
                rules.map((item, index) => {
                  const keys = Object.keys(item);
                  const conditionKey = keys.find(
                    (key) => key.startsWith('condition_') && !key.endsWith('_value')
                  );
                  const conditionValueKey = keys.find(
                    (key) => key.startsWith('condition_') && key.endsWith('_value')
                  );
                  const boolean = keys.find((key) => key.endsWith('_boolean_value'));
                  const additionalValue = keys.find(
                    (key) => key.endsWith('_number_max_value') || key.endsWith('_date_days_value')
                  );

                  return (
                    <Box key={index} sx={Styles.selectedConditionContainer}>
                      <Box sx={Styles.num}>{index + 1}</Box>
                      <Text variant="D20">
                        {`${getTitlesByValue([item?.subject], subjects)} ${getTitlesByValue(
                          [item[conditionKey]],
                          conditionKey === defaultValues.conditionString
                            ? defaultValues.conditionsForString
                            : conditionKey === defaultValues.conditionNumber
                              ? defaultValues.conditionForNumber
                              : defaultValues.conditionForDate
                        )} ${
                          keys.includes('condition_date_start_value')
                            ? formatDates(item['condition_date_start_value'])
                            : item[conditionValueKey || boolean]
                        }${
                          keys.includes('condition_date_end_value')
                            ? ` to ${formatDates(item['condition_date_end_value'])}`
                            : item[additionalValue]
                              ? ` to ${item[additionalValue]}`
                              : ''
                        }`}
                      </Text>
                    </Box>
                  );
                })
              ) : (
                <Text variant="D20">{getTitles('T-11186')}</Text>
              )}
            </Box>
            <UIEditIcon
              onClick={() => {
                setSelectCondition(false);
              }}
            />
          </Box>
        ) : (
          <>
            <UIRadioButtonWithTitle
              title={getTitles('T-11185')}
              values={defaultValues.ruleType}
              checkedValue={values.rule_type}
              onCheck={(val) => {
                setFieldValue('rule_type', val);
                setRules([]);
              }}
            />
            {values.rule_type === 2 && (
              <>
                {rules.map((item, index) => {
                  const keys = Object.keys(item);
                  const conditionKey = keys.find(
                    (key) => key.startsWith('condition_') && !key.endsWith('_value')
                  );

                  const conditionValueKey = keys.find(
                    (key) => key.startsWith('condition_') && key.endsWith('_value')
                  );
                  const boolean = keys.find((key) => key.endsWith('_boolean_value'));
                  const additionalValue = keys.find(
                    (key) => key.endsWith('_number_max_value') || key.endsWith('_date_days_value')
                  );

                  return (
                    <Box sx={Styles.conditionContainer} key={index}>
                      <UITextInputWithTitle
                        value={getTitlesByValue([item.subject], subjects)}
                        containerStyles={Styles.commonField}
                        disabled={true}
                      />
                      <UITextInputWithTitle
                        value={getTitlesByValue(
                          [item[conditionKey]],
                          conditionKey === defaultValues.conditionString
                            ? defaultValues.conditionsForString
                            : conditionKey === defaultValues.conditionNumber
                              ? defaultValues.conditionForNumber
                              : defaultValues.conditionForDate
                        )}
                        containerStyles={Styles.commonField}
                        disabled={true}
                      />
                      <Box sx={Styles.commonField}>
                        <UITextInputWithTitle
                          value={
                            keys.includes('condition_date_start_value')
                              ? formatDates(item['condition_date_start_value'])
                              : item[conditionValueKey || boolean]
                          }
                          disabled={true}
                        />
                        {keys.length > 3 && (
                          <UITextInputWithTitle
                            value={
                              keys.includes('condition_date_end_value')
                                ? formatDates(item['condition_date_end_value'])
                                : item[additionalValue]
                            }
                            disabled={true}
                          />
                        )}
                      </Box>

                      {rules.length > 1 && (
                        <Box sx={Styles.deleteBtn}>
                          <UIminusDeleteButton onClick={() => handleDeleteCondition(index)} />
                        </Box>
                      )}
                    </Box>
                  );
                })}
                <Box sx={Styles.fieldsContainer}>
                  <UISelect
                    options={subjects}
                    name="rules.subject"
                    value={values.rules?.subject}
                    onChange={handleCondition}
                    placeholder={getTitles('T-11188')}
                    error={touched.rules?.subject && errors.rules?.subject}
                    onBlur={handleBlur}
                    containerStyles={Styles.commonField}
                  />
                  <UISelect
                    options={conditionOptions}
                    name={`rules.${conditionType}`}
                    value={values.rules[conditionType]}
                    containerStyles={Styles.commonField}
                    onChange={handleCondition}
                  />
                  {conditionType !== defaultValues.conditionDate ? (
                    <>
                      {conditionType === defaultValues.conditionBoolean ? (
                        <UISelect
                          options={defaultValues.personalInsurane}
                          name="rules.condition_boolean_value"
                          value={values.rules.condition_boolean_value}
                          containerStyles={Styles.commonField}
                          onChange={handleCondition}
                        />
                      ) : (
                        <Box sx={Styles.commonField}>
                          <UITextInputWithTitle
                            name={`rules.${conditionValue}`}
                            value={values.rules[conditionValue]}
                            onChange={handleCondition}
                            containerStyles={Styles.fieldM}
                            allowOnlyNumbers={conditionType === defaultValues.conditionNumber}
                          />
                          {(values.rules?.condition_number === 7 ||
                            values.rules?.condition_number === 8) && (
                            <UITextInputWithTitle
                              name="rules.condition_number_max_value"
                              value={values.rules?.condition_number_max_value}
                              onChange={handleCondition}
                              containerStyles={Styles.fieldM}
                              allowOnlyNumbers={true}
                            />
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box sx={Styles.commonField}>
                      <DatePickers
                        dateValue={values.rules?.condition_date_start_value}
                        onDateChange={(val) =>
                          handleCondition({
                            target: { value: val, name: 'rules.condition_date_start_value' }
                          })
                        }
                        maxDate={values.rules?.condition_date_end_value}
                      />

                      {(values.rules?.condition_date === 7 ||
                        values.rules?.condition_date === 8) && (
                        <DatePickers
                          dateValue={values.rules?.condition_date_end_value}
                          onDateChange={(val) =>
                            handleCondition({
                              target: { value: val, name: 'rules.condition_date_end_value' }
                            })
                          }
                          minDate={values.rules?.condition_date_start_value}
                        />
                      )}

                      {values.rules?.condition_date > 10 && (
                        <UITextInputWithTitle
                          name="rules.condition_date_days_value"
                          onChange={handleCondition}
                          value={values.rules?.condition_date_days_value}
                          containerStyles={Styles.fieldM}
                          allowOnlyNumbers={true}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <UISaveAndAdd
                  title={getTitles('T-11277')}
                  onAddAnother={handleAddRule}
                  disabled={
                    !values.rules?.subject ||
                    !values.rules[conditionType] ||
                    !values.rules[conditionValue] ||
                    ((values.rules[conditionType] === 7 ||
                      values.rules[conditionType] === 8 ||
                      values.rules[conditionType] > 10) &&
                      !(
                        values.rules.condition_date_days_value ||
                        values.rules.condition_date_end_value ||
                        values.rules.condition_number_max_value
                      ))
                  }
                />
              </>
            )}
            <UIButton
              title={getTitles(actionType !== add ? 'T-10046' : 'T-10804')}
              style={Styles.btn}
              disabled={
                !isModified &&
                (!values.rule_type ||
                  (values.rule_type === 2 &&
                    (!values.rules?.subject ||
                      !values.rules[conditionType] ||
                      !values.rules[conditionValue] ||
                      ((values.rules[conditionType] === 7 ||
                        values.rules[conditionType] === 8 ||
                        values.rules[conditionType] > 10) &&
                        !(
                          values.rules.condition_date_days_value ||
                          values.rules.condition_date_end_value ||
                          values.rules.condition_number_max_value
                        )))))
              }
              onClick={handleNext}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
