import { defaultValues, getErrorMessage } from '../../../constants';
import { getTitles } from '../../../utils/commonFunction';
import { Text, UILayout } from '../../../ui-kits';
import callIcon from '../../../assets/icons/call.png';
import high from '../../../assets/icons/high.png';
import low from '../../../assets/icons/low.png';
import medium from '../../../assets/icons/medium.png';
import pending from '../../../assets/icons/pending.png';
import close from '../../../assets/icons/close.png';
import open from '../../../assets/icons/open.png';
import { BasicProfile } from '../../common/utility-components';
import { Box } from '@mui/material';
import { color } from '../../../theme';
import { StatusCard } from '../../common';
import { styles } from './styles';

export const TasksCallcenter = ({ currTab }) => {
  const { callerTask, myTask, allTask } = defaultValues.callCenterCurrTabs;

  const callerTaskList = [
    { id: 1, status: 1, priority: 1 },
    { id: 2, status: 2, priority: 2 },
    { id: 3, status: 3, priority: 3 }
  ];
  const myTaskList = [
    { id: 1, status: 1, priority: 1 },
    { id: 2, status: 2, priority: 2 },
    { id: 3, status: 3, priority: 3 }
  ];
  const allTaskList = [
    { id: 1, status: 1, priority: 1 },
    { id: 2, status: 2, priority: 2 },
    { id: 3, status: 3, priority: 3 }
  ];

  const handleSearch = (data) => {
    data;
  };

  const callerTasksData = callerTaskList.map((item) => [
    {
      title: getTitles('T-11245'),
      columnWidth: '8%',
      headerTextStyle: styles.tableHeaderTextStyle,
      values: 'fgd',
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11249'),
      values: 'sfdfd',
      headerTextStyle: styles.tableHeaderTextStyle,
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11247'),
      columnWidth: '10%',
      values: (
        <StatusCard
          icon1={
            item.status === 1 ? open : item.status === 2 ? pending : item.status === 3 && close
          }
          icon2={
            item.status === 3
              ? null
              : item.priority === 1
                ? high
                : item.priority === 2
                  ? medium
                  : item.priority === 3 && low
          }
          title1={
            item.status === 1
              ? getTitles('T-11316')
              : item.status === 2
                ? getTitles('T-11317')
                : item.status === 3 && getTitles('T-11103')
          }
          title2={
            item.status === 3
              ? null
              : item.priority === 1
                ? getTitles('T-11305')
                : item.priority === 2
                  ? getTitles('T-11306')
                  : item.priority === 3 && getTitles('T-11307')
          }
        />
      ),
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11248'),
      values: 'sdffd',
      headerTextStyle: styles.tableHeaderTextStyle,
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11246'),
      values: (
        <Box sx={{ width: '100%' }}>
          <BasicProfile
            title={'thanbanur'}
            subTitle={'alapuzha'}
            subTitleVariant="D20"
            isIcon={false}
            subTitleStyle={{ color: color.primaryText }}
          />
          <BasicProfile title={'+919608968887'} url={callIcon} iconPositon="right" />
          <BasicProfile title={'Taluka, village'} isIcon={false} />
        </Box>
      ),
      columnWidth: '16%'
    },
    {
      title: getTitles('T-11252'),
      values: <BasicProfile title={'Abhirami'} isIcon={false} titleStyle={styles.center} />,
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11282'),
      values: <BasicProfile title={'12-03-2024'} isIcon={false} titleStyle={styles.center} />,
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11251'),
      values: '22-12-2025',
      valueTextStyle: styles.center,
      headerTextStyle: styles.tableHeaderTextStyle
    }
  ]);

  const myTasksData = myTaskList.map((item) => [
    {
      title: getTitles('T-11245'),
      headerTextStyle: styles.tableHeaderTextStyle,
      values: 'fgd',
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11249'),
      values: 'sfdfd',
      headerTextStyle: styles.tableHeaderTextStyle,
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11247'),
      values: (
        <StatusCard
          icon1={
            item.status === 1 ? open : item.status === 2 ? pending : item.status === 3 && close
          }
          icon2={
            item.status === 3
              ? null
              : item.priority === 1
                ? high
                : item.priority === 2
                  ? medium
                  : item.priority === 3 && low
          }
          title1={
            item.status === 1
              ? getTitles('T-11316')
              : item.status === 2
                ? getTitles('T-11317')
                : item.status === 3 && getTitles('T-11103')
          }
          title2={
            item.status === 3
              ? null
              : item.priority === 1
                ? getTitles('T-11305')
                : item.priority === 2
                  ? getTitles('T-11306')
                  : item.priority === 3 && getTitles('T-11307')
          }
        />
      ),
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11250'),
      values: <BasicProfile title={'12-03-2024'} isIcon={false} titleStyle={styles.center} />,
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11251'),
      values: '22-12-2025',
      valueTextStyle: styles.center,
      headerTextStyle: styles.tableHeaderTextStyle
    }
  ]);

  const allTasksData = allTaskList.map((item) => [
    {
      title: getTitles('T-11245'),
      columnWidth: '8%',
      headerTextStyle: styles.tableHeaderTextStyle,
      values: 'fgd'
    },

    {
      title: getTitles('T-11249'),
      values: 'sfdfd',
      headerTextStyle: styles.tableHeaderTextStyle,
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11247'),
      columnWidth: '10%',
      values: (
        <StatusCard
          icon1={
            item.status === 1 ? open : item.status === 2 ? pending : item.status === 3 && close
          }
          icon2={
            item.status === 3
              ? null
              : item.priority === 1
                ? high
                : item.priority === 2
                  ? medium
                  : item.priority === 3 && low
          }
          title1={
            item.status === 1
              ? getTitles('T-11316')
              : item.status === 2
                ? getTitles('T-11317')
                : item.status === 3 && getTitles('T-11103')
          }
          title2={
            item.status === 3
              ? null
              : item.priority === 1
                ? getTitles('T-11305')
                : item.priority === 2
                  ? getTitles('T-11306')
                  : item.priority === 3 && getTitles('T-11307')
          }
        />
      ),
      headerTextStyle: styles.tableHeaderTextStyle
    },
    {
      title: getTitles('T-11248'),
      values: 'sdffd',
      headerTextStyle: styles.tableHeaderTextStyle,
      valueTextStyle: styles.center
    },
    {
      title: getTitles('T-11308'),
      values: (
        <Box sx={{ width: '100%' }}>
          <BasicProfile title={'thanbanur'} isIcon={false} />
          <BasicProfile title={'+919608968887'} url={callIcon} iconPositon="right" />
          <BasicProfile title={'Taluka, village'} isIcon={false} />
        </Box>
      ),
      columnWidth: '16%'
    },
    {
      title: getTitles('T-11246'),
      values: (
        <Box sx={{ width: '100%' }}>
          <BasicProfile
            title={'thanbanur'}
            subTitle={'alapuzha'}
            subTitleVariant="D20"
            isIcon={false}
            subTitleStyle={{ color: color.primaryText }}
          />
          <BasicProfile title={'+919608968887'} url={callIcon} iconPositon="right" />
          <BasicProfile title={'Taluka, village'} isIcon={false} />
        </Box>
      ),
      columnWidth: '16%'
    },
    {
      title: getTitles('T-11261'),
      values: (
        <BasicProfile
          title={'12-03-2024'}
          subTitle={'Abhirami'}
          subTitleVariant="D20"
          subTitleStyle={{ color: color.primaryText, ...styles.center }}
          isIcon={false}
          titleStyle={styles.center}
        />
      ),
      headerTextStyle: styles.tableHeaderTextStyle
    },

    {
      title: getTitles('T-11251'),
      values: '22-12-2025',
      valueTextStyle: styles.center,
      headerTextStyle: styles.tableHeaderTextStyle
    }
  ]);

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}

      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10145')}
        tableItems={
          currTab === myTask
            ? myTasksData
            : currTab === allTask
              ? allTasksData
              : currTab === callerTask && callerTasksData
        }
        showNoData={!callerTaskList.length > 0}
        message={getErrorMessage('E-10031')}
        handleSort={() => {}}
        handleFilter={() => {}}
        handleRefresh={() => {}}
        tableHeaderTextStyle={styles.tableHeaderTextStyle}
      />
    </Box>
  );
};
