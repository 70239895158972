import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  vetList: [],
  currentPage: null,
  nextPage: null,
  totalPage: null,
  vetParams: null,
  currRender: defaultValues.vetScreen.viewVetScr,
  unassignedAiworker: [],
  unassCurrentPage: null,
  unassNextPage: null,
  unassTotalPage: null,
  currAiWorkerList: [],
  currVet: null
};

const vetSlice = createSlice({
  initialState,
  name: 'vet',
  reducers: {
    resetVet(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.vetList = [];
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.vetParams = null;
      state.currRender = defaultValues.vetScreen.viewVetScr;
      state.unassignedAiworker = [];
      state.unassCurrentPage = null;
      state.unassNextPage = null;
      state.unassTotalPage = null;
      state.currAiWorkerList = [];
      state.currVet = null;
    },
    resetVetMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    updateVetCurrRender(state, action) {
      state.currRender = action.payload;
    },
    updateVetParams(state, action) {
      state.vetParams = action.payload;
    },
    updateCurrVet(state, action) {
      state.currVet = action.payload;
    },
    getVetRequest(state) {
      state.isLoading = true;
    },
    getVetSuccess(state, action) {
      state.isLoading = false;
      state.vetList = action.payload.data.vets;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getVetFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.vetList = [];
    },
    getUnassignedAIRequest(state) {
      state.isLoading = true;
    },
    getUnassignedAISuccess(state, action) {
      state.isLoading = false;
      state.unassignedAiworker = action.payload.data.ai_workers;
      state.unassCurrentPage = action.payload.data.current_page;
      state.unassNextPage = action.payload.data.next_page;
      state.unassTotalPage = action.payload.data.total_page;
    },
    getUnassignedAIFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.vetList = [];
    },
    addAiToVetRequest(state) {
      state.isLoading = true;
    },
    addAiToVetSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currVet = { ...state.currVet, ai_workers: action.payload.data.ai_workers };
    },
    addAiToVetFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateAssignedAiRequest(state) {
      state.isLoading = true;
    },
    updateAssignedAiSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currVet = { ...state.currVet, ai_workers: action.payload.data.ai_workers };
    },
    updateAssignedAiFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateVetStatusRequest(state) {
      state.isLoading = true;
    },
    updateVetStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateVetStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    assignLabToVetRequest(state) {
      state.isLoading = true;
    },
    assignLabToVetSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    assignLabToVetFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetVetMessages,
  updateVetCurrRender,
  getVetFailure,
  getVetRequest,
  getVetSuccess,
  getUnassignedAIFailure,
  getUnassignedAIRequest,
  getUnassignedAISuccess,
  addAiToVetFailure,
  addAiToVetRequest,
  addAiToVetSuccess,
  updateAssignedAiFailure,
  updateAssignedAiRequest,
  updateAssignedAiSuccess,
  updateVetParams,
  updateVetStatusFailure,
  updateVetStatusRequest,
  updateVetStatusSuccess,
  updateCurrVet,
  assignLabToVetFailure,
  assignLabToVetRequest,
  assignLabToVetSuccess,
  resetVet
} = vetSlice.actions;

export default vetSlice.reducer;
