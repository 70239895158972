import { defaultValues } from '../../constants';
import { color } from '../../theme';
const res = defaultValues.isResponsive;
const lg = defaultValues.isLarge;

export const style = {
  container: {
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: color.secondaryBackground
  },
  sidebarContainer: {
    width: '100%',
    height: window.innerHeight,
    display: 'flex',
    position: 'fixed',
    backgroundColor: color.secondaryBackground
  },
  leftside: (isCall) => ({
    width: isCall ? '100px' : lg ? '250px' : '20%'
  }),
  subContainer: (isCall) => ({
    width: isCall ? 'calc(100% - 100px)' : res ? '100%' : lg ? 'calc(100% - 250px)' : '80%'
  }),
  subChildCont: {
    width: res ? '100%' : lg ? 'calc(100% - 250px)' : '80%',
    padding: '0% 2%',
    boxSizing: 'border-box'
  },
  childrenContainer: {
    width: '100%',
    padding: '0% 2%',
    boxSizing: 'border-box',
    height: res ? 'calc(100% - 70px)' : 'calc(100% - 110px)',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none'
  },
  detailsSubContainer: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  detailsChildContainer: {
    padding: '2% 4%',
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground
  }
};
