import { PopupModal, UIAutocompleted, UISelect, UITextInputWithTitle } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import { useState } from 'react';
import { defaultValues } from '../../../../constants';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';
import mFarmer from '../../../../assets/icons/farmer.png';
import mVet from '../../../../assets/icons/vet.png';
import mLaboratory from '../../../../assets/icons/laborotary.png';
import mParavet from '../../../../assets/icons/paravet.png';
import { CallCenterCommonField } from '../common-form';
import { styles } from './styles';

export const TaskCallCenterModal = ({ showModel, onClose }) => {
  const [btnDisabled, setBtndisabled] = useState(true);

  const { member, nonMember, employee } = defaultValues.callCenterUserTypes;
  const { add, edit, view } = defaultValues.actionType;
  const { cattleMan, admin, reasonsOthers } = defaultValues.reasonForCallValues;
  const { ai, vo, delCattle } = defaultValues.memberConcernsValues;
  const { others, enquiry } = defaultValues.nonMemberReasonForCallValues;

  let timer;

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      task_name: null,
      description: '',
      task_type: '',
      task_status: '',
      due_date: null,
      priority: '',
      call_center_status: '',
      repeat: '',
      no_of_days: '',
      assign_to: '',
      notify_to: '',
      remind_to: '',
      remind_on: [
        {
          days: '',
          remind_at: '',
          timing: ''
        }
      ],
      reason_for_call: '',
      concern: '',
      cattle_id: '',
      details: '',
      no_of_cattles: '',
      time_to_visit: '',
      timing: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {};

  const handleAddAnother = () => {
    setValues({
      ...values,
      remind_on: [...values.remind_on, { days: '', remind_at: '', timing: '' }]
    });
  };

  const handleRemove = (idx) => {
    const newRemindDate = values.remind_on.filter((_, i) => i !== idx);
    setFieldValue('remind_on', newRemindDate);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const searchList = (val) => {
    let payload = { search_text: val, page: 1 };
    payload;
  };

  const info = [
    {
      heading: getTitles('T-11291'),
      title1: 'farmer name',
      title2: '+9145884255652',
      address: 'village,taluk',
      icon: mFarmer
    },
    {
      heading: getTitles('T-11292'),
      title1: 'farmer name',
      title2: '+9145884255652',
      address: 'village,taluk',
      icon: mParavet
    },
    {
      heading: getTitles('T-11293'),
      title1: 'farmer name',
      title2: '+9145884255652',
      address: 'village,taluk',
      icon: mVet
    },
    {
      heading: getTitles('T-11294'),
      title1: 'farmer name',
      title2: '+9145884255652',
      address: 'village,taluk',
      icon: mLaboratory
    },
    {
      heading: getTitles('T-11295'),
      title1: 'farmer name',
      title2: '+9145884255652',
      address: 'village,taluk',
      icon: mLaboratory
    }
  ];

  return (
    <PopupModal
      showModel={showModel}
      title={
        showModel?.mode === add
          ? getTitles('T-11263')
          : showModel?.mode === view
            ? getTitles('T-11299')
            : getTitles('T-11300')
      }
      onClose={handleClose}
      childContStyle={styles.childCont}
      containerStyle={styles.popUpContainer}>
      <CallCenterCommonField
        showModel={showModel}
        info={[employee, member].includes(showModel?.type) && info}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        handleRemove={handleRemove}
        handleAddAnother={handleAddAnother}
      />

      <Box sx={styles.nameCont}>
        {[member, nonMember].includes(showModel?.type) && (
          <UISelect
            name="reason_for_call"
            value={values.reason_for_call}
            title={getTitles('T-11269')}
            options={
              showModel?.type === member
                ? defaultValues.memberReasonsForCallOptions
                : showModel?.type === nonMember && defaultValues.nonMemberReasonsForCallOptions
            }
            onChange={(e) => {
              handleChange(e);
              setFieldValue('concern', '');
            }}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        )}
        {((showModel?.type === member && [cattleMan, admin].includes(values.reason_for_call)) ||
          showModel?.type === employee) && (
          <UISelect
            name="concern"
            value={values.concern}
            title={getTitles('T-11270')}
            options={
              showModel?.type === member && values.reason_for_call === cattleMan
                ? defaultValues.memberCattleConcernsOptions
                : showModel?.type === member && values.reason_for_call === admin
                  ? defaultValues.memberAdminConcernsOptions
                  : []
            }
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        )}
        {((values.reason_for_call === cattleMan && [ai, vo, delCattle].includes(values.concern)) ||
          showModel?.type === employee) && (
          <UIAutocompleted
            name="cattle_id"
            title={getTitles('T-10984')}
            textInputStyles={styles.autoInput}
            containerStyle={styles.autoCont}
            titleStyle={{ mb: '4px' }}
            options={[]}
            optionLabel={(item) => item.name || ''}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            onTyping={(val) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                if (val.length > 2) {
                  searchList(val);
                }
              }, 500);
            }}
            onBlur={handleBlur}
            placeHolder={getTitles('T-10077')}
            selectedValue={(val) => {
              searchList(val);
              setBtndisabled(false);
            }}
            preSelectedValue={values.cattle_id?.name}
            width={styles.width}
            disabled={showModel?.mode === view}
          />
        )}
        {((showModel?.type === nonMember && values.reason_for_call === others) ||
          (showModel?.type === member && values.reason_for_call === reasonsOthers)) && (
          <UITextInputWithTitle
            name="details"
            value={values.details}
            title={getTitles('T-10659')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.longWidth}
            disabled={showModel?.mode === view}
            rows={2}
          />
        )}
      </Box>

      {showModel?.type === member && [admin].includes(values.reason_for_call) && (
        <UITextInputWithTitle
          name="details"
          value={values.details}
          title={getTitles('T-10659')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          width={styles.width}
          disabled={showModel?.mode === view}
          rows={2}
        />
      )}

      {showModel?.type === nonMember && values.reason_for_call === enquiry && (
        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="no_of_cattles"
            value={values.no_of_cattles}
            title={getTitles('T-11302')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
          <UITextInputWithTitle
            name="time_to_visit"
            value={values.time_to_visit}
            title={getTitles('T-11303')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
          <UITextInputWithTitle
            name="timing"
            value={values.timing}
            title={getTitles('T-10569')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        </Box>
      )}

      <ModalButtons
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={showModel?.mode === edit ? getTitles('T-10046') : getTitles('T-10394')}
        disabled={showModel?.mode !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </PopupModal>
  );
};
