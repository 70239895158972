import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  insurances: [],
  insuranceData: null,
  currRender: 1,
  insuranceCompany: [],
  insuranceParams: null
};

const insuranceSlice = createSlice({
  initialState,
  name: 'insurance',
  reducers: {
    resetInsurance(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.insurances = [];
      state.insuranceData = null;
      state.currRender = 1;
      state.insuranceCompany = [];
      state.insuranceParams = null;
    },
    addInsuranceRequest(state) {
      state.isLoading = true;
    },
    addInsuranceSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addInsuranceFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getInsurancesRequest(state) {
      state.isLoading = true;
    },
    getInsuranceSuccess(state, action) {
      state.isLoading = false;
      state.insurances = action.payload.data.insurance;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getInsuranceFailure(state) {
      state.isLoading = false;
      state.insurances = [];
    },
    updateInsuranceRequest(state) {
      state.isLoading = true;
    },
    updateInsuranceSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateInsuranceFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    getCompanyRequest(state) {
      state.isLoading = true;
    },
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.insuranceCompany = action.payload.data;
    },
    getCompanyFailure(state, action) {
      state.isLoading = false;
      state.insuranceCompany = [];
      state.errorMessage = action.payload.status_code;
    },
    setInsuranceData(state, action) {
      state.insuranceData = action.payload;
    },
    setInsuranceCurrentRender(state, action) {
      state.currRender = action.payload;
    },
    setInsuranceParams(state, action) {
      state.insuranceParams = action.payload;
    },
    resetInsuranceMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    }
  }
});

export const {
  resetInsuranceMsg,
  setInsuranceData,
  setInsuranceParams,
  setInsuranceCurrentRender,
  addInsuranceFailure,
  addInsuranceRequest,
  addInsuranceSuccess,
  getInsuranceFailure,
  getInsurancesRequest,
  getInsuranceSuccess,
  updateInsuranceFailure,
  updateInsuranceRequest,
  updateInsuranceSuccess,
  getCompanyFailure,
  getCompanyRequest,
  getCompanySuccess,
  resetInsurance
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
