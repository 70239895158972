import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const isRes = defaultValues.isResponsive;

export const styles = {
  container: { display: 'flex', alignItems: 'center', mt: 1 },
  labelContainer: { width: '15%' },
  fieldContainer: { width: isRes ? '100%' : '60%' },
  btn: { width: isRes ? '150px' : '275px', height: isRes ? '40px' : '48px' },
  threeFieldContainer: {
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    width: isRes ? '100%' : '80%',
    gap: isRes ? '0px' : '20px'
  },
  threeFieldUISelect: { width: isRes ? '100%' : '45%' },
  operateCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    mt: isRes && -1
  },
  uiSelectSub: { width: isRes ? '50%' : '45%' },
  textField: { marginTop: '15px', width: isRes ? '50%' : '45%' },
  commonField: { width: isRes ? '100%' : '30%' },
  fieldsWraper: {
    width: isRes ? '100%' : '30%',
    display: 'flex',
    justifyContent: !isRes && 'space-between',
    alignItems: !isRes && 'center',
    flexDirection: isRes ? 'column' : 'row'
  },
  fullScre: {
    width: isRes ? '100%' : '30%'
  },
  taskStatus: {
    display: 'flex',
    width: isRes ? '100%' : '80%',
    gap: isRes ? '10px' : '20px',
    alignItems: 'center'
  },
  statusField: { width: isRes ? '50%' : '30%' },
  statueCont: {
    width: isRes ? '50%' : '45%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  taskStatusField: { width: isRes ? '100%' : '44%' },
  dueDataContainer: { width: isRes ? '100%' : '80%' },
  dueDataFieldWraper: {
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    width: '100%',
    gap: '20px'
  },
  dueOtherFieldWraper: { width: '100%', display: 'flex', gap: '10px' },
  remindAtWraper: {
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row',
    width: '100%',
    gap: '20px'
  },
  asterisk: {
    color: color.errorText
  }
};
