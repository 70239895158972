import { createSelector } from '@reduxjs/toolkit';
export const getCattleLoading = createSelector([(state) => state.cattle.isLoading], (d) => d);
export const getCattleSuccess = createSelector([(state) => state.cattle.successMessage], (d) => d);
export const getCattleError = createSelector([(state) => state.cattle.errorMessage], (d) => d);
export const getCattleList = createSelector([(state) => state.cattle.cattles], (d) => d);
export const getReports = createSelector([(state) => state.cattle.reports], (d) => d);
export const getCattleNext = createSelector([(state) => state.cattle.cattleNextPage], (d) => d);
export const getCattleCurr = createSelector([(state) => state.cattle.cattleCurrPage], (d) => d);
export const getCattleTotal = createSelector([(state) => state.cattle.cattleTotalPage], (d) => d);
export const getCattleData = createSelector([(state) => state.cattle.cattleData], (d) => d);
export const getCattleCompleteSteps = createSelector(
  [(state) => state.cattle.completedCattleStep],
  (d) => d
);
export const getCattleFarmer = createSelector([(state) => state.cattle.farmerDetails], (d) => d);
export const getCattleActive = createSelector([(state) => state.cattle.activeCattlePage], (d) => d);
export const getCattleActionType = createSelector([(state) => state.cattle.type], (d) => d);
export const getCattleActiveStep = createSelector([(state) => state.cattle.activeStep], (d) => d);
