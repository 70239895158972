import { defaultValues } from '../constants';
import { color } from './color';

export const globalStyles = {
  divider: {
    backgroundColor: color.tertiaryText,
    width: '100%'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
    justifyContent: 'center'
  },
  flexColXCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexColYCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex'
  },
  pl: (pad) => ({
    paddingLeft: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  pr: (pad) => ({
    paddingRight: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  pb: (pad) => ({
    paddingBottom: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  pt: (pad) => ({
    paddingTop: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  p: (pad) => ({
    padding: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),

  ml: (pad) => ({
    marginLeft: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  mr: (pad) => ({
    marginRight: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  mb: (pad) => ({
    marginBottom: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),
  mt: (pad) => ({
    marginTop: `${15 * pad}px`,
    boxSizing: 'border-box'
  }),

  textAlignCenter: {
    textAlign: 'center'
  },
  saveAndAddBtn: {
    backgroundColor: color.link,
    borderRadius: '50%',
    color: color.primaryBackground,
    fontSize: defaultValues.isResponsive ? 15 : defaultValues.isMedium ? 17 : 20
  },
  saveAndAddText: {
    color: color.link
  },
  saveAndAddBtnCont: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    gap: '10px'
  },
  title: {
    color: color.link,
    cursor: 'pointer',
    textDecoration: 'underline',
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  link: {
    color: color.link,
    textDecoration: 'underline'
  },
  linkDesc: {
    color: color.link,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  text: {
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  statesCont: {
    width: '100%',
    marginTop: '10px'
  },
  assignCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  gridRes: 'calc(50% - 5px) calc(50% - 5px)',
  gridNormal: 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
  gridCountry: {
    gridArea: 'country',
    marginTop: '0px'
  },
  gridState: {
    gridArea: 'state',
    marginTop: '0px'
  },
  gridDistrict: {
    gridArea: 'district',
    marginTop: '0px'
  },
  gridTaluk: {
    gridArea: 'taluk',
    marginTop: '0px'
  },
  gridVillage: {
    gridArea: 'village',
    marginTop: '0px'
  },
  gridPostcode: {
    gridArea: 'post',
    marginTop: '0px'
  },
  gridFirstName: {
    gridArea: 'first',
    marginTop: '0px'
  },
  gridMiddlename: {
    gridArea: 'middle',
    marginTop: '0px'
  },
  gridLastname: {
    gridArea: 'last',
    marginTop: '0px'
  },
  totalMember: {
    gridArea: 'total',
    marginTop: '0px'
  },
  activeMember: {
    gridArea: 'active',
    marginTop: '0px'
  },
  empCount: {
    gridArea: 'count',
    marginTop: '0px'
  },
  route: {
    gridArea: 'route',
    marginTop: '0px'
  }
};
