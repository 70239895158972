import { Box } from '@mui/material';
import { DatePickers, NoData, UITextInputWithTitle } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { defaultValues } from '../../../../constants';
import { ViewVehicleDetails } from './view-vehicle';
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  width: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)',
  widthYears: defaultValues.isResponsive ? '100%' : 'calc((100% + 10px)/3)',
  dateCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  dateWidth: defaultValues.isResponsive ? '50%' : 'calc((100% - 10px)/3)'
};

export const EmployeeVehicleDetails = ({ employeeData, employeeId, editable }) => {
  const dispatch = useDispatch();
  const [btnDisabled, setBtndisabled] = useState(true);

  const { values, setValues, handleBlur, setFieldValue, dirty, isValid } = useFormik({
    initialValues: {
      vehicle_number: '',
      insurance_policy_number: '',
      insurance_provider: '',
      details: '',
      start_date: null,
      end_date: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    if (employeeData) {
      let newValues = {
        ...employeeData,
        start_date: employeeData.start_date && dayjs(employeeData.start_date),
        end_date: employeeData.end_date && dayjs(employeeData.end_date)
      };

      setValues(newValues);
    }
  }, [employeeData, setValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { _id: employeeId, vehicle_details: { ...values } };
    dispatch(updateEmployeeRequest(payload));
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        <UITextInputWithTitle
          name="vehicle_number"
          title={getTitles('T-10654')}
          placeHolder={getTitles('T-10360')}
          value={values.vehicle_number}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
        />

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="insurance_policy_number"
            title={getTitles('T-10372')}
            placeHolder={getTitles('T-10360')}
            value={values.insurance_policy_number}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
          />

          <UITextInputWithTitle
            name="insurance_provider"
            title={getTitles('T-10655')}
            placeHolder={getTitles('T-10384')}
            value={values.insurance_provider}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
          />

          <UITextInputWithTitle
            name="details"
            title={getTitles('T-10656')}
            placeHolder={getTitles('T-10373')}
            value={values.details}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
          />
        </Box>

        <Box sx={styles.dateCont}>
          <DatePickers
            dateValue={values.start_date}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'start_date' } })}
            title={getTitles('T-10651')}
            width={styles.dateWidth}
            maxDate={values.end_date}
          />

          <DatePickers
            dateValue={values.end_date}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'end_date' } })}
            title={getTitles('T-10652')}
            width={styles.dateWidth}
            minDate={values.start_date}
          />
        </Box>

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData ? (
        <ViewVehicleDetails employeeData={employeeData} />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
