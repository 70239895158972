import { Avatar, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Text } from '../text';
import { SCREEN_PATH, defaultValues } from '../../constants';
import img from '../../assets/images/logo.png';
import logout from '../../assets/icons/logout.png';
import { Styles } from './style';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateMainMenu, userLogout } from '../../services/redux/slices/authSlice';
import { getMainMenu, getUserData } from '../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { getTitles } from '../../utils';
import {
  resetEmployee,
  resetGopalan,
  resetGovtOffice,
  resetInsurance,
  resetLaboratory,
  resetProcess,
  resetSociety,
  resetVet
} from '../../services/redux/slices';

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);

  const mainMenu = useSelector(getMainMenu);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [menuItems, setMenuItems] = useState([]);
  const [isCallCenter, setIsCallCenter] = useState(false);

  const { laboratory, society, employee, insurance, govtOffice, process, gopalan, vet } =
    defaultValues.appLogicalTexts;

  useEffect(() => {
    if (mainMenu && mainMenu !== selectedMenu) {
      setSelectedMenu(mainMenu);
    }
    setIsCallCenter(mainMenu === 14);
  }, [mainMenu]);

  useEffect(() => {
    const menus = defaultValues.superAdminMenu;

    switch (userData.user_role) {
      // user is super Admin, showing all menu
      case defaultValues.userRole.superAdmin:
        setMenuItems(menus.filter((item) => !item.role_id || !item.role_id.includes(17)));
        break;

      // user is Admin, then filter as per permission have
      case defaultValues.userRole.employee: {
        const permissionIds = userData.employee_permissions.map((perm) => perm.permission_id);
        const filteredMenu = menus.filter((menuItem) => {
          if (Array.isArray(menuItem.role_id)) {
            return menuItem.role_id.some((role) => permissionIds.includes(role));
          } else if (menuItem.role_id === undefined) {
            return menuItem;
          } else {
            return permissionIds.includes(menuItem.role_id);
          }
        });
        setMenuItems(filteredMenu);
        break;
      }

      default:
        setMenuItems(menus.filter(({ role_id }) => role_id === undefined));
    }
  }, [userData]);

  const resetStateBasedOnMenu = (menuType) => {
    switch (menuType) {
      case laboratory:
        dispatch(resetLaboratory());
        break;
      case society:
        dispatch(resetSociety());
        break;
      case employee:
        dispatch(resetEmployee());
        dispatch(resetProcess());
        break;
      case insurance:
        dispatch(resetInsurance());
        break;
      case govtOffice:
        dispatch(resetGovtOffice());
        dispatch(resetProcess());
        break;
      case process:
        dispatch(resetProcess());
        break;
      case gopalan:
        dispatch(resetGopalan());
        break;
      case vet:
        dispatch(resetVet());
        break;
      default:
        break;
    }
  };

  const handleMenuClick = (item) => {
    dispatch(updateMainMenu(item));
    mainMenu !== item.id && resetStateBasedOnMenu(item.type);
    if (item.route) navigate(item.route);
  };

  const handleLogoutClick = () => {
    navigate(SCREEN_PATH.HOME);
    dispatch(userLogout());
    dispatch({ type: defaultValues.appLogicalTexts.userLogout });
  };

  return (
    <Box sx={Styles.container}>
      {!isCallCenter && (
        <Box sx={Styles.logoContainer}>
          <Avatar alt={'logo'} src={img} sx={Styles.logoStyle} />
          <Text variant="boldMilkH28">{getTitles('T-10008')}</Text>
        </Box>
      )}
      <Box sx={Styles.menuContainer(isCallCenter)}>
        {menuItems.length > 0 &&
          menuItems.map((item) => (
            <Box key={item.id}>
              <Box
                sx={Styles.menuTab(selectedMenu === item.id)}
                onClick={() => handleMenuClick(item)}>
                <img src={item.icon} alt="icons" style={Styles.iconStyle(isCallCenter)} />
                {!isCallCenter && (
                  <Text variant="D20" style={Styles.menuTitle}>
                    {item.menu}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
      </Box>

      <Box sx={Styles.menuTab()} onClick={() => handleLogoutClick()}>
        <img src={logout} alt="icons" style={Styles.logout} />
        {!isCallCenter && (
          <Text variant="D20" style={Styles.menuTitle}>
            {getTitles('T-10036')}
          </Text>
        )}
      </Box>
    </Box>
  );
};
