import { Box } from '@mui/material';
import { VillageDetailsForm } from '../village-details-form';
import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMasterListWiseRequest,
  getSocietyRequest,
  setVillageActiveStep,
  setVillageIsInit,
  updateDetailsPg,
  updateScrollToTop,
  updateVillageCurrPage
} from '../../../../services/redux/slices';
import { VillageAICenterDetailsForm } from '../village-ai-center details-form';
import { EditButton, Text, UIButton, UIStepper } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import { VillageAdditionalDetailsForm } from '../village-addition-details-form';
import { useEffect, useState } from 'react';
import {
  getMasterLists,
  getVillageActiveStep,
  getVillageData,
  getVillageIsInit,
  getVillageSteps
} from '../../../../services/redux/selectors/manageProccess';
import {
  getDetailsPg,
  getScroll,
  getSocietyNextpage,
  getSocietys
} from '../../../../services/redux/selectors';

const styles = {
  container: {
    width: '100%',
    padding: defaultValues.isResponsive ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    marginTop: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  editIcon: {
    cursor: 'pointer',
    width: defaultValues.isResponsive ? '20px' : defaultValues.isMedium ? '23px' : '26px',
    height: defaultValues.isResponsive ? '20px' : defaultValues.isMedium ? '23px' : '26px'
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  }
};

export const AddEditVillage = ({ currRender, state, district, taluka, talukaId, isReadOnly }) => {
  const dispatch = useDispatch();
  const villageSteps = useSelector(getVillageSteps);
  const nextPage = useSelector(getSocietyNextpage);
  const societyList = useSelector(getSocietys);
  const villageData = useSelector(getVillageData);
  const scroll = useSelector(getScroll);
  const masterLists = useSelector(getMasterLists);
  const detailsPg = useSelector(getDetailsPg);
  const activeStep = useSelector(getVillageActiveStep);
  const isInit = useSelector(getVillageIsInit);

  const [society, setSociety] = useState([]);
  const { sourceOfWater, aiCenterRunner, sourceOfIrrigationWater, crops } = defaultValues.mListIds;

  const titles = [getTitles('T-11036'), getTitles('T-11037'), getTitles('T-10997')];

  const steps = defaultValues.isResponsive ? ['', '', ''] : titles;

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    if (villageSteps && villageSteps < 3) {
      dispatch(setVillageActiveStep(villageSteps));
    } else if (activeStep > 0 || (activeStep === 0 && isInit)) {
      dispatch(setVillageActiveStep(activeStep));
      return;
    } else {
      dispatch(setVillageActiveStep(0));
    }
  }, [villageSteps]);

  useEffect(() => {
    dispatch(getSocietyRequest({ page: 1 }));
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([sourceOfWater, aiCenterRunner, sourceOfIrrigationWater, crops])
      })
    );
    dispatch(updateScrollToTop(!scroll));
  }, []);

  useEffect(() => {
    let temp = [...society, ...societyList];

    const unique = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setSociety(unique);
  }, [societyList]);

  useEffect(() => {
    if (nextPage !== null) dispatch(getSocietyRequest({ page: nextPage }));
  }, [nextPage]);

  const handleNextStep = (step = 1) => {
    dispatch(setVillageActiveStep(activeStep + step));
  };

  const handlePrevStep = (step = 1) => {
    dispatch(setVillageActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  const handleBackButton = () => {
    if (activeStep === 0 && currRender !== 1) {
      dispatch(updateVillageCurrPage(1));
      dispatch(setVillageIsInit(false));
    } else {
      activeStep === 1 && dispatch(setVillageIsInit(true));
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  const getMasterListData = (listId) => {
    if (masterLists && masterLists.length > 0) {
      const mData = masterLists.find((ele) => ele.master_id === listId);
      return mData ? mData.data : [];
    }
    return [];
  };

  return (
    <Box sx={styles.container}>
      {!defaultValues.isResponsive && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton onClick={handleBackButton} />
          <Text variant="boldH32Sec">{getTitles('T-10949')}</Text>
          {currRender === defaultValues.actionType.view && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(updateVillageCurrPage(defaultValues.actionType.edit))}
            />
          )}
        </Box>
      )}
      <Box sx={{ width: '100%', display: 'flex' }}>
        {currRender === defaultValues.actionType.view &&
          defaultValues.isResponsive &&
          !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(updateVillageCurrPage(defaultValues.actionType.edit))}
            />
          )}
      </Box>
      <Box sx={{ mt: 2, mb: 3 }}>
        <UIStepper
          steps={steps}
          activeStep={activeStep}
          onStepperClick={(index) => {
            if (index <= villageSteps) {
              dispatch(setVillageActiveStep(index));
            }
            dispatch(setVillageIsInit(index === 0));
          }}
        />
      </Box>

      {activeStep === 0 && (
        <VillageDetailsForm
          state={state}
          district={district}
          taluka={taluka}
          societyList={society}
          currRender={currRender}
          villageData={villageData && villageData.village_details}
          villageId={villageData && villageData.village_id}
          id={villageData && villageData._id}
          talukaId={talukaId}
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
        />
      )}
      {activeStep === 1 && (
        <VillageAICenterDetailsForm
          currRender={currRender}
          villageData={villageData && villageData.ai_center_details}
          id={villageData && villageData._id}
          villageId={villageData && villageData.village_id}
          villageFullData={villageData}
          aiCenterRunner={getMasterListData(aiCenterRunner)}
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
        />
      )}
      {activeStep === 2 && (
        <VillageAdditionalDetailsForm
          currRender={currRender}
          villageData={villageData && villageData.additional_details}
          id={villageData && villageData._id}
          villageFullData={villageData}
          villageId={villageData && villageData.village_id}
          sourceOfWater={getMasterListData(sourceOfWater)}
          sourceOfIrrigationWater={getMasterListData(sourceOfIrrigationWater)}
          majorCrops={getMasterListData(crops)}
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
        />
      )}
    </Box>
  );
};
