import { Box } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';
import { Styles } from './style';
import img from '../../assets/icons/refres.png';
import { getTitles } from '../../utils';

export const UIRefresh = ({ handleRefresh }) => {
  return (
    <Box style={Styles.toolsIconContainer} onClick={handleRefresh}>
      <img style={Styles.toolsIcons} src={img} />
      {!defaultValues.isResponsive && <Text variant="D20">{getTitles('T-10074')}</Text>}
    </Box>
  );
};
