import React from 'react';
import { Box } from '@mui/material';
import { defaultValues } from '../../../constants/defaultValues';
import { footerStyles } from './styles';
import { CommonFooter } from './common-footer';
import { EcommerceFooter } from './ecommerce-footer';

export const Footer = ({ type, containerStyle }) => {
  const { eCommerce } = defaultValues.appLogicalTexts;
  return (
    <Box sx={footerStyles.container(type === eCommerce, containerStyle)}>
      {type === eCommerce ? <EcommerceFooter /> : <CommonFooter type={type} />}
    </Box>
  );
};
