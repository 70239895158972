export const SCREEN_PATH = {
  HOME: '/',
  FALLBACK: '*/*',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  MANAGE_ADMIN: '/manage-admin',
  PROFILE: '/profile-settings',
  MANAGE_STATE: '/manage-state',
  MANAGE_DISTRICT: '/manage-district',
  MANAGE_TALUK: '/manage-taluk',
  MANAGE_VILLAGE: '/manage-village',
  ADD_ADMIN: '/manage-admin/add-admin',
  ADMIN_DETAILS: '/manage-admin/admin-details',
  MANAGE_PROCESS: '/manage-process',
  MANAGE_EMPLOYEE: '/manage-employee',
  MANAGE_FARMER: '/manage-farmer',
  REGISTER_FARMER: '/register-farmer',
  MANAGE_VET: '/manage-vet',
  MANAGE_AI_WORKER: '/manage-ai-worker',
  MANAGE_SOCIETY: '/manage-society',
  MANAGE_INSURANCE: '/manage-insurance',
  MANAGE_LABORATORY: '/manage-laboratory',
  MANAGE_CATTLE: '/manage-farmer/cattles',
  REGISTER_CATTLE: '/manage-farmer/register-cattles',
  GOVT_OFFICIAL: '/goverment-officials',
  ASSOCIATED_FARMER: '/society/farmers',
  CALL_CENTER: '/call-center',
  POLICY: '/privacy-policy',
  DISCLAIMER: '/disclaimer',
  SHIPPING_POLICY: '/shipping-policy'
};
