import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  religion: [],
  farmers: [],
  farmerData: {},
  type: null,
  activePage: null,
  completedStep: null,
  activeStep: 0,
  farmerDistrict: [],
  farmerTaluka: [],
  farmerVillage: [],
  farmerDistrictNxtPage: null,
  farmerTalukaNxtPage: null,
  farmerVillageNxtPage: null
};

const farmerSlice = createSlice({
  initialState,
  name: 'farmer',
  reducers: {
    setFarmerActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setFarmerCompletedStep(state, action) {
      state.completedStep = action.payload;
    },
    setFarmerData(state, action) {
      state.farmerData = action.payload;
    },
    getReligionRequest(state) {
      state.isLoading = true;
      state.errorMessage = null;
      state.successMessage = null;
    },
    getReligionSuccess(state, action) {
      state.isLoading = false;
      state.religion = action.payload.data;
    },
    getReligionFailure(state, action) {
      state.isLoading = false;
      state.religion = [];
      state.errorMessage = action.payload.status_code;
    },
    addFarmerRequest(state) {
      state.isLoading = true;
    },
    addFarmerSuccess(state, action) {
      state.isLoading = false;
      state.farmerData = { ...state.farmerData, ...action.payload.data };
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
      state.completedStep = action.payload.data.registration_step;
    },
    addFarmerFailure(state, action) {
      state.errorMessage = action.payload.status_code;
      state.isLoading = false;
    },
    getFarmersRequest(state) {
      state.isLoading = true;
      state.errorMessage = null;
      state.successMessage = null;
      state.farmerData = {};
      state.completedStep = null;
    },
    getFarmerSuccess(state, action) {
      state.isLoading = false;
      state.farmers = action.payload.data.farmers;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
      state.farmerData = null;
      state.activePage = 0;
      state.activeStep = 0;
    },
    getFarmerFailure(state) {
      state.isLoading = false;
      state.farmers = [];
    },
    uploadFarmerDocRequest(state) {
      state.isLoading = true;
    },
    uploadFarmerDocSuccess(state, action) {
      state.isLoading = false;
      state.farmerData.identification_details = action.payload.data.identification_details;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
      state.completedStep = action.payload.data.registration_step;
    },
    uploadFarmerDocFailure(state) {
      state.isLoading = false;
      state.farmers = [];
    },
    updateFarmerRequest(state) {
      state.isLoading = true;
    },
    updateFarmerSuccess(state, action) {
      state.isLoading = false;
      state.farmerData = action.payload.data;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
    },
    updateFarmerFailure(state) {
      state.isLoading = false;
    },
    getFarmerDetailsRequest(state) {
      state.isLoading = true;
    },
    getFarmerDetailsSuccess(state, action) {
      state.isLoading = false;
      state.farmerData = action.payload.data;
      state.completedStep = action.payload.data.registration_step;
    },
    getFarmerDetailsFailure(state, action) {
      state.isLoading = false;
      state.farmerData = {};
      state.errorMessage = action.payload.status_code;
    },

    setActionType(state, action) {
      state.type = action.payload;
    },
    setCurrentActiveFarmerPage(state, action) {
      state.activePage = action.payload;
    },
    resetFarmerMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    getFarmerDistrictRequest(state) {
      state.isLoading = true;
    },
    getFarmerDistrictSuccess(state, action) {
      state.isLoading = false;
      state.farmerDistrictNxtPage = action.payload.data.next_page;
      state.errorMessage = null;
      state.farmerDistrict = action.payload.data.district;
    },
    getFarmerDistrictFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.successMessage = null;
    },
    getFarmerTalukRequest(state) {
      state.isLoading = true;
    },
    getFarmerTalukSuccess(state, action) {
      state.isLoading = false;
      state.farmerTalukaNxtPage = action.payload.data.next_page;
      state.errorMessage = null;
      state.farmerTaluka = action.payload.data.taluka;
    },
    getFarmerTalukFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.successMessage = null;
    },
    getFarmerVillageRequest(state) {
      state.isLoading = true;
    },
    getFarmerVillageSuccess(state, action) {
      state.isLoading = false;
      state.farmerVillageNxtPage = action.payload.data.next_page;
      state.errorMessage = null;
      state.farmerVillage = action.payload.data.village;
    },
    getFarmerVillageFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.successMessage = null;
    }
  }
});

export const {
  getReligionFailure,
  getReligionRequest,
  getReligionSuccess,
  addFarmerFailure,
  addFarmerRequest,
  addFarmerSuccess,
  getFarmerFailure,
  getFarmersRequest,
  getFarmerSuccess,
  updateFarmerFailure,
  updateFarmerRequest,
  setCurrentActiveFarmerPage,
  updateFarmerSuccess,
  uploadFarmerDocFailure,
  uploadFarmerDocRequest,
  uploadFarmerDocSuccess,
  getFarmerDetailsRequest,
  getFarmerDetailsSuccess,
  getFarmerDetailsFailure,
  resetFarmerMsg,
  setActionType,
  setFarmerActiveStep,
  setFarmerData,
  setFarmerCompletedStep,
  getFarmerDistrictFailure,
  getFarmerDistrictRequest,
  getFarmerDistrictSuccess,
  getFarmerTalukFailure,
  getFarmerTalukRequest,
  getFarmerTalukSuccess,
  getFarmerVillageFailure,
  getFarmerVillageRequest,
  getFarmerVillageSuccess
} = farmerSlice.actions;

export default farmerSlice.reducer;
