import { defaultValues } from '../../constants';
import { color } from '../../theme';

const lg = defaultValues.isLarge;

export const Styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: color.secondary
  },

  logoContainer: { py: 2, pl: 4, backgroundColor: color.secondary },

  logoStyle: { width: '80px', height: '80px', mb: 1 },
  menuTab: (isActive) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    boxSizing: 'border-box',
    backgroundColor: isActive ? color.palette.paleIvory : color.secondary,
    borderLeft: isActive ? `5px solid ${color.primaryText}` : `5px solid ${color.secondary}`
  }),
  iconStyle: (isCall) => ({
    marginRight: isCall ? 0 : 10,
    marginLeft: 10,
    width: lg ? '24px' : '30px',
    height: lg ? '24px' : '30px',
    color: color.primaryText,
    cursor: 'pointer'
  }),
  logout: {
    marginRight: 10,
    marginLeft: 10,
    width: lg ? '20px' : '22px',
    height: lg ? '20px' : '22px',
    color: color.primaryText,
    cursor: 'pointer'
  },
  menuTitle: {
    color: color.primaryText,
    textAlign: 'left',
    textTransform: 'none',
    cursor: 'pointer'
  },
  menuContainer: (isCall) => ({
    paddingTop: isCall ? '150px' : '0px',
    height: 'calc(100% - 210px)',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    backgroundColor: color.secondary
  })
};
