import { Box, IconButton } from '@mui/material';
import { UIBackButton } from '../../../ui-kits/back-button';
import { color } from '../../../theme';

import { SCREEN_PATH, defaultValues } from '../../../constants';
import {
  AdditionalDetails,
  CattleDescription,
  CattleDetails,
  UploadPhotos
} from '../../../components';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { checkReadOnly, getTitles } from '../../../utils';
import { Screen, Text, UIButton, UIStepper } from '../../../ui-kits';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDiseaseRequest,
  getMasterListWiseRequest,
  getVaccineRequest,
  setCattleActionType,
  setCattleActiveStep,
  updateDetailsPg
} from '../../../services/redux/slices';
import {
  getCattleActionType,
  getCattleActiveStep,
  getCattleCompleteSteps,
  getDetailsPg,
  getUserData
} from '../../../services/redux/selectors';
import {
  getDiseasesList,
  getStateNextPage,
  getVaccineCurPage,
  getVannices
} from '../../../services/redux/selectors/manageProccess';

export const CattleRegistration = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { view, edit } = defaultValues.actionType;

  const actionType = useSelector(getCattleActionType);
  const userData = useSelector(getUserData);
  const diseaseNextPage = useSelector(getStateNextPage);
  const vaccineNextPage = useSelector(getVaccineCurPage);
  const diseaseList = useSelector(getDiseasesList);
  const vaccineList = useSelector(getVannices);
  const detailsPg = useSelector(getDetailsPg);
  const cattleSteps = useSelector(getCattleCompleteSteps);
  const activeStep = useSelector(getCattleActiveStep);

  const [isReadOnly, setIsReadOnly] = useState(null);
  const [vaccines, setVaccines] = useState([]);
  const [diseases, setDisease] = useState([]);

  const titles = [
    getTitles('T-10979'),
    getTitles('T-10980'),
    getTitles('T-10981'),
    getTitles('T-10982')
  ];

  const steps = titles;

  const handleNextStep = (step = 1) => {
    dispatch(setCattleActiveStep(activeStep + step));
  };

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  const handlePrevStep = (step = 1) => {
    dispatch(setCattleActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.farmerManager));
  }, [userData]);

  useEffect(() => {
    if (actionType === edit && cattleSteps && cattleSteps < 4) {
      dispatch(setCattleActiveStep(cattleSteps));
      dispatch(setCattleActionType(null));
    }
  }, [cattleSteps]);

  useEffect(() => {
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([
          defaultValues.mListIds.cattle,
          defaultValues.mListIds.cattleBreeds,
          defaultValues.mListIds.horn,
          defaultValues.mListIds.color,
          defaultValues.mListIds.tailSwitch,
          defaultValues.mListIds.fodderType,
          defaultValues.mListIds.subscription,
          defaultValues.mListIds.policyProvider
        ])
      })
    );
    dispatch(getVaccineRequest());
    dispatch(getDiseaseRequest());
  }, []);

  useEffect(() => {
    if (diseaseNextPage !== null) dispatch(getDiseaseRequest({ page: diseaseNextPage }));
  }, [diseaseNextPage]);

  useEffect(() => {
    if (vaccineNextPage !== null) dispatch(getDiseaseRequest({ page: vaccineNextPage }));
  }, [vaccineNextPage]);

  useEffect(() => {
    let temp = [...vaccines, ...vaccineList];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setVaccines(uniqueStates);
  }, [vaccineList]);

  useEffect(() => {
    let temp = [...diseases, ...diseaseList];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setDisease(uniqueStates);
  }, [diseaseList]);

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CattleDescription
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setCattleActiveStep(val))}
          />
        );
      case 1:
        return (
          <UploadPhotos
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setCattleActiveStep(val))}
          />
        );
      case 2:
        return (
          <CattleDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setCattleActiveStep(val))}
            disease={diseases}
            vaccines={vaccines}
          />
        );
      case 3:
        return (
          <AdditionalDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setCattleActiveStep(val))}
          />
        );

      default:
        return (
          <CattleDescription
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setCattleActiveStep(val))}
          />
        );
    }
  };

  const handleBackButton = () => {
    if (activeStep === 0) {
      navigation(SCREEN_PATH.MANAGE_CATTLE);
    } else {
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  const styles = {
    container: {
      width: '100%',
      minHeight: '100%',
      backgroundColor: color.primaryBackground,
      borderRadius: 1
    },
    subContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      p: defaultValues.isResponsive ? 1 : 2
    },
    icon: {
      width: '35px',
      height: '35px',
      borderRadius: '5px',
      backgroundColor: color.primary,
      minWidth: 'unset',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: color.tertiaryText,
      mr: 1,
      '&:hover': {
        backgroundColor: color.primary,
        color: color.tertiaryText
      }
    },
    btn: {
      padding: '5px 20px',
      height: defaultValues.isResponsive ? '35px' : '45px',
      width: defaultValues.isResponsive ? '80px' : '150px'
    },
    flex: { display: 'flex', alignItems: 'center' }
  };

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10117')}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          {defaultValues.isResponsive ? (
            <Text variant="boldH32Sec" style={{ mb: 1 }}>
              {getTitles('T-11102')}
            </Text>
          ) : (
            <Box sx={styles.flex}>
              <UIBackButton onClick={handleBackButton} />
              <Text variant="boldH32Sec">{getTitles('T-11102')}</Text>
            </Box>
          )}

          {actionType === view && !isReadOnly && (
            <>
              {defaultValues.isResponsive ? (
                <IconButton onClick={() => dispatch(setCattleActionType(edit))} sx={styles.icon}>
                  <EditIcon />
                </IconButton>
              ) : (
                <UIButton
                  title={getTitles('T-10084')}
                  style={styles.btn}
                  startIcon={<EditIcon />}
                  onClick={() => dispatch(setCattleActionType(edit))}
                />
              )}
            </>
          )}
        </Box>
        <Box sx={{ mt: 2, mb: 3 }}>
          <UIStepper
            steps={steps}
            activeStep={activeStep}
            onStepperClick={(index) => {
              if (index <= cattleSteps) {
                if (index < cattleSteps) {
                  dispatch(setCattleActionType(view));
                  dispatch(setCattleActiveStep(index));
                } else {
                  dispatch(setCattleActionType(null));
                  dispatch(setCattleActiveStep(index));
                }
              }
            }}
          />
        </Box>

        {renderStepContent(activeStep)}
      </Box>
    </Screen>
  );
};
