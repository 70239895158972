import { Box, Divider } from '@mui/material';
import { Text, UIAutocompleted, UISelect, UITextInputWithTitle } from '../../../ui-kits';
import { Styles } from '../../../components/farmer-registration/style';
import { getTitles, getUniqueAndSortedData } from '../../../utils';
import { useEffect, useState } from 'react';
import { defaultValues, KEYS } from '../../../constants';
import { getDistrictAPI, getStateAPI, getTalukAPI, getVillageAPI } from '../../../services/api';

export const Address = ({
  type = 'address',
  title,
  objects,
  actionType,
  permissionId,
  showAssignedLocation = false,
  showPostCode = true,
  onAddressOneChange,
  onAddressTwoChange,
  onPostCodeChange,
  onCountrySelected,
  onStateSelected,
  onDistrictSelected,
  onTalukaSelected,
  onVillageSelected,
  onPostCodeBlurred,
  postCodeError,
  onCountryBlurred,
  countryCodeError,
  onStateBlurred,
  stateCodeError,
  onDistrictBlurred,
  districtCodeError,
  onTalukaBlurred,
  talukaCodeError,
  onVillageBlurred,
  villageCodeError,
  onAddressOneBlurred,
  addressOneErrorCode,
  isCountryRequired = false,
  isStateRequired = false,
  isDistrictRequired = false,
  isTalukaRequired = false,
  isVillageRequired = false,
  isPostCodeRequired = false,
  addressOneRequired = false,
  divider = true,
  addressOneDisabled = false,
  addressTwoDisabled = false,
  countryDisabled = false,
  stateDisabled = false,
  districtDisabled = false,
  talukaDisabled = false,
  villageDisabled = false,
  postCodeDisabled = false
}) => {
  let timer;
  const { view, viewOnly } = defaultValues.actionType;

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [state_id, setStateId] = useState(null);
  const [district_id, setDistrictId] = useState(null);
  const [taluka_id, setTalukaId] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    let params;
    !showAssignedLocation
      ? (params = { page: 1, filter: JSON.stringify([{ is_active: true }]) })
      : (params = {
          page: 1,
          type: 2,
          permission_id: permissionId,
          filter: JSON.stringify([{ is_active: true }])
        });
    getStateAPI(params).then((res) => {
      if (res.data.status === KEYS.SUCCESS_KEY) {
        setStateList(res.data.data.states);
        setNextPage(res.data.data.next_page);
      }
    });
  }, [showAssignedLocation]);

  useEffect(() => {
    if (nextPage !== null) {
      let params;
      !showAssignedLocation
        ? (params = { page: nextPage, filter: JSON.stringify([{ is_active: true }]) })
        : (params = {
            page: nextPage,
            type: 2,
            permission_id: permissionId,
            filter: JSON.stringify([{ is_active: true }])
          });
      getStateAPI(params).then((res) => {
        if (res.data.status === KEYS.SUCCESS_KEY) {
          setStateList(getUniqueAndSortedData(res.data.data.states));
          setNextPage(res.data.data.next_page);
        }
      });
    }
  }, [nextPage, showAssignedLocation]);

  useEffect(() => {
    setStateId(objects?.state_id?._id || objects?.state_id || null);
    setDistrictId(objects?.district_id?._id || objects?.district_id || null);
    setTalukaId(objects?.taluka_id?._id || objects?.taluka_id || null);
  }, [objects]);

  return (
    <Box>
      <Text variant="BoldH28" style={Styles.text}>
        {title}
      </Text>
      {divider && <Divider sx={Styles.divider} />}
      {type === 'address' && (
        <Box sx={Styles.halfWidthContainer}>
          <UITextInputWithTitle
            required={addressOneRequired}
            title={getTitles('T-10357')}
            onChange={onAddressOneChange}
            value={objects.address_line_one}
            disabled={actionType === view || actionType === viewOnly || addressOneDisabled}
            placeHolder={getTitles('T-10361')}
            onBlur={onAddressOneBlurred}
            error={addressOneErrorCode}
          />
          <UITextInputWithTitle
            value={objects.address_line_two}
            onChange={onAddressTwoChange}
            disabled={actionType === view || actionType === viewOnly || addressTwoDisabled}
            title={getTitles('T-10358')}
            placeHolder={getTitles('T-10362')}
          />
        </Box>
      )}
      <Box sx={Styles.commonColumn}>
        <UISelect
          required={isCountryRequired}
          value={objects.country_id || objects.country || ''}
          onChange={onCountrySelected}
          disabled={countryDisabled}
          readOnly={actionType === view || actionType === viewOnly}
          options={defaultValues.countriesList}
          title={getTitles('T-10363')}
          placeholder={getTitles('T-10350')}
          onBlur={onCountryBlurred}
          error={countryCodeError}
        />

        <UIAutocompleted
          required={isStateRequired}
          containerStyle={Styles.autoCompleteContainer}
          titleStyle={Styles.autoText}
          placeHolder={getTitles('T-10350')}
          title={getTitles('T-10070')}
          options={stateList?.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          onChange={(val) => {
            setStateId(val?._id);
            onStateSelected(val);
            setDistrictList([]);
            onDistrictSelected('');
            onTalukaSelected('');
            onVillageSelected('');
            if (showPostCode) {
              onPostCodeChange({ target: { value: '' } });
            }
          }}
          disabled={stateDisabled}
          readOnly={actionType === view || actionType === viewOnly}
          preSelectedValue={objects.state_id?._id && objects.state_id}
          onBlur={onStateBlurred}
          error={stateCodeError}
        />

        <UIAutocompleted
          required={isDistrictRequired}
          containerStyle={Styles.autoCompleteContainer}
          titleStyle={Styles.autoText}
          options={districtList?.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          title={getTitles('T-10069')}
          placeHolder={getTitles('T-10350')}
          onChange={(val) => {
            setDistrictId(val?._id);
            onDistrictSelected(val);
            setTalukaList([]);
            onTalukaSelected('');
            onVillageSelected('');
            if (showPostCode) {
              onPostCodeChange({ target: { value: '' } });
            }
          }}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                let params;
                !showAssignedLocation
                  ? (params = {
                      state_id: [state_id],
                      search_text: val,
                      filter: JSON.stringify([{ is_active: true }])
                    })
                  : (params = {
                      state_id: [state_id],
                      search_text: val,
                      type: 2,
                      permission_id: permissionId,
                      filter: JSON.stringify([{ is_active: true }])
                    });
                getDistrictAPI(params).then((res) => {
                  if (res.data.status === KEYS.SUCCESS_KEY) {
                    setDistrictList(res.data.data.district);
                  }
                });
              }
            }, 500);
          }}
          disabled={districtDisabled}
          preSelectedValue={objects.district_id?._id && objects.district_id}
          onBlur={onDistrictBlurred}
          error={districtCodeError}
          readOnly={actionType === view || actionType === viewOnly}
        />

        <UIAutocompleted
          required={isTalukaRequired}
          containerStyle={Styles.autoCompleteContainer}
          titleStyle={Styles.autoText}
          options={talukaList?.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          title={getTitles('T-10071')}
          placeHolder={getTitles('T-10350')}
          onChange={(val) => {
            setTalukaId(val?._id);
            onTalukaSelected(val);
            setVillageList([]);
            onVillageSelected('');
            if (showPostCode) {
              onPostCodeChange({ target: { value: '' } });
            }
          }}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                let params;
                !showAssignedLocation
                  ? (params = {
                      district_id: [district_id],
                      search_text: val,
                      filter: JSON.stringify([{ is_active: true }])
                    })
                  : (params = {
                      district_id: [district_id],
                      search_text: val,
                      type: 2,
                      permission_id: permissionId,
                      filter: JSON.stringify([{ is_active: true }])
                    });
                getTalukAPI(params).then((res) => {
                  if (res.data.status === KEYS.SUCCESS_KEY) {
                    setTalukaList(res.data.data.taluka);
                  }
                });
              }
            }, 500);
          }}
          disabled={talukaDisabled}
          readOnly={actionType === view || actionType === viewOnly}
          preSelectedValue={objects.taluka_id?._id && objects.taluka_id}
          onBlur={onTalukaBlurred}
          error={talukaCodeError}
        />

        <UIAutocompleted
          required={isVillageRequired}
          containerStyle={Styles.autoCompleteContainer}
          titleStyle={Styles.autoText}
          options={villageList?.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          title={getTitles('T-10068')}
          placeHolder={getTitles('T-10350')}
          onChange={(val) => {
            setTalukaId(val?._id);
            onVillageSelected(val);
            if (showPostCode) {
              onPostCodeChange({ target: { value: '' } });
            }
          }}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                let params;
                !showAssignedLocation
                  ? (params = {
                      taluka_id: [taluka_id],
                      search_text: val,
                      filter: JSON.stringify([{ is_active: true }])
                    })
                  : (params = {
                      taluka_id: [taluka_id],
                      search_text: val,
                      type: 2,
                      permission_id: permissionId,
                      filter: JSON.stringify([{ is_active: true }])
                    });
                getVillageAPI(params).then((res) => {
                  if (res.data.status === KEYS.SUCCESS_KEY) {
                    setVillageList(res.data.data.village);
                  }
                });
              }
            }, 500);
          }}
          disabled={villageDisabled}
          readOnly={actionType === view || actionType === viewOnly}
          preSelectedValue={objects.village_id?._id && objects.village_id}
          onBlur={onVillageBlurred}
          error={villageCodeError}
        />
        {showPostCode && (
          <UITextInputWithTitle
            required={isPostCodeRequired}
            value={objects.post_code || objects.pincode || ''}
            onChange={onPostCodeChange}
            disabled={postCodeDisabled}
            readOnly={actionType === view || actionType === viewOnly}
            title={getTitles('T-10359')}
            placeHolder={getTitles('T-10381')}
            maxLength={6}
            allowOnlyNumbers={true}
            onBlur={onPostCodeBlurred}
            error={postCodeError}
          />
        )}
      </Box>
    </Box>
  );
};
