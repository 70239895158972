import { Box, Divider, Grid } from '@mui/material';
import { CallStatusDropdown, SearchBar, Text, UIButton } from '../../../ui-kits';
import { useState } from 'react';
import { styles } from './styles';
import { defaultValues } from '../../../constants';
import { getTitles } from '../../../utils';
import { AdvancedSearchModal, CallBox, CallerInfoCard } from '..';
import { globalStyles } from '../../../theme';

/**
 * Display box that shows current status of call and chosen member
 * @param {object} call (this is temporary functionality and may be changed as necessary) contains information about any ongoing call - if no ongoing call, should return an object with status 'inactive'
 * @returns Call Display Box
 */
export const CallInfoBox = ({ callState, setCallState, caller, handleCreateTaskModal }) => {
  const [open, setOpen] = useState(false);
  const [filterSearchVal, setFilterSearchVal] = useState('');

  const { member, employee } = defaultValues.callCenterUserTypes;
  const { add } = defaultValues.actionType;

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSearch = (e) => {
    setFilterSearchVal(e.target.value);
  };

  const handleCallState = (action) => {
    setCallState(action);
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2} sx={styles.grid}>
        <Grid item xs={12} md={9}>
          <Grid container display="flex" alignItems="center" gap="10px">
            <Grid item width={'70%'}>
              <SearchBar
                allowOnlyAlphabets={false}
                allowOnlyNumbers={false}
                searchedVlaue={() => {}}
                placeHolder={getTitles('T-11005')}
                disableSearch={false}
                onRefresh={() => {}}
              />
            </Grid>
            <Grid item>
              <Text
                variant="secondary"
                style={{ ...globalStyles.linkDesc }}
                onClick={handleModalOpen}>
                {getTitles('T-11343')}
              </Text>
            </Grid>
          </Grid>
          <Divider sx={styles.divider('100%')} />
          <CallerInfoCard caller={caller} />

          {[member, employee].includes(caller?.user_type?.id) && (
            <>
              <Box mt={2}>
                <UIButton title={getTitles('T-10659')} variant="secondary" style={styles.button} />
                <UIButton title={getTitles('T-11345')} variant="secondary" style={styles.button} />
                <UIButton title={getTitles('T-11346')} variant="secondary" style={styles.button} />
                <UIButton title={getTitles('T-11347')} style={styles.button} />
                <UIButton
                  title={getTitles('T-11348')}
                  style={styles.button}
                  onClick={() => handleCreateTaskModal({ type: caller?.user_type?.id, mode: add })}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3} display="flex" flexDirection="column">
          <CallStatusDropdown />
          <CallBox
            type={callState}
            phone_number={callState && caller?.phone_number}
            handleCallState={handleCallState}
          />
        </Grid>
      </Grid>

      <AdvancedSearchModal
        open={open}
        handleModalClose={handleModalClose}
        handleSearch={handleSearch}
        filterSearchVal={filterSearchVal}
      />
    </Box>
  );
};
