import { Box } from '@mui/material';

import { defaultValues } from '../../../constants';
import { UIBackButton } from '../../../ui-kits/back-button';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMilkSuppliedRequest,
  getStateRequest,
  setSocietyActiveStep,
  setSocietyIsInit,
  updateDetailsPg,
  updateSocietyCurrentRender
} from '../../../services/redux/slices';
import { useEffect, useState } from 'react';
import { getStateNextPage, getStates } from '../../../services/redux/selectors/manageProccess';
import {
  MilkDetailsForm,
  OperationalDetailsForm,
  SocietyAdditionalDetailsForm,
  SocietyDetailsForm
} from '../../../components/HOC/society';
import { color } from '../../../theme';
import {
  getCurrSocietyData,
  getDetailsPg,
  getSocietyActiveStep,
  getSocietyCompletedStep,
  getSocietyIsInit
} from '../../../services/redux/selectors';
import { EditButton, Text, UIButton, UIStepper } from '../../../ui-kits';
import { getTitles } from '../../../utils';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: {
    width: '100%',
    padding: res ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    mt: 2
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    position: 'relative'
  },
  backBtn: {
    position: 'absolute',
    left: md ? -45 : -55
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px',
    marginRight: 0
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  }
};

export const AddEditSociety = ({ currRender, isReadOnly }) => {
  const dispatch = useDispatch();

  const nextPage = useSelector(getStateNextPage);
  const states = useSelector(getStates);
  const societyData = useSelector(getCurrSocietyData);
  const societySteps = useSelector(getSocietyCompletedStep);
  const detailsPg = useSelector(getDetailsPg);
  const activeStep = useSelector(getSocietyActiveStep);
  const isInit = useSelector(getSocietyIsInit);

  const [stateList, setStateList] = useState([]);
  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    dispatch(getStateRequest({ page: 1 }));
    dispatch(getMilkSuppliedRequest({ master_id: defaultValues.masterListType.milk_supplied }));
  }, []);

  useEffect(() => {
    if (activeStep > 0 || (activeStep === 0 && isInit)) {
      dispatch(setSocietyActiveStep(activeStep));
      return;
    }
    if (societySteps && societySteps < 4) {
      dispatch(setSocietyActiveStep(societySteps));
    } else {
      dispatch(setSocietyActiveStep(0));
    }
  }, [societySteps]);

  useEffect(() => {
    let temp = [...stateList, ...states];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setStateList(uniqueStates);
  }, [states]);

  useEffect(() => {
    if (nextPage !== null) dispatch(getStateRequest({ page: nextPage }));
  }, [nextPage]);

  const titles = [
    getTitles('T-10994'),
    getTitles('T-10995'),
    getTitles('T-10996'),
    getTitles('T-10997')
  ];

  const steps = defaultValues.isResponsive ? ['', '', '', ''] : titles;

  const handleNextStep = (step = 1) => {
    dispatch(setSocietyActiveStep(activeStep + step));
  };

  const handlePrevStep = (step = 1) => {
    dispatch(setSocietyActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  const handleBackButton = () => {
    if (activeStep === 0 && currRender !== 1) {
      dispatch(updateSocietyCurrentRender(1));
      dispatch(setSocietyIsInit(false));
    } else {
      activeStep === 0 && dispatch(setSocietyIsInit(true));
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  return (
    <Box sx={styles.container}>
      {!defaultValues.isResponsive && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton onClick={handleBackButton} style={styles.backBtn} />
          <Text variant="boldH32Sec">
            {currRender === add
              ? getTitles('T-10778')
              : currRender === edit
                ? getTitles('T-11205')
                : getTitles('T-10994')}
          </Text>
          {currRender === view && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(updateSocietyCurrentRender(edit))}
            />
          )}
        </Box>
      )}

      <Box sx={{ mt: 2, mb: 3 }}>
        <UIStepper
          steps={steps}
          activeStep={activeStep}
          onStepperClick={(index) => {
            if (index <= societySteps) {
              dispatch(setSocietyActiveStep(index));
            }
            dispatch(setSocietyIsInit(index === 0));
          }}
        />
      </Box>
      {activeStep === 0 && (
        <SocietyDetailsForm
          states={stateList}
          societyData={societyData && societyData.society_details}
          societyId={societyData && societyData._id}
          onNext={() => handleNextStep(1)}
          currRender={currRender}
        />
      )}
      {activeStep === 1 && (
        <OperationalDetailsForm
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
          societyData={societyData && societyData.operational_details}
          societyId={societyData && societyData._id}
          currRender={currRender}
        />
      )}
      {activeStep === 2 && (
        <MilkDetailsForm
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
          societyData={societyData && societyData.milk_feed_details}
          societyId={societyData && societyData._id}
          currRender={currRender}
        />
      )}
      {activeStep === 3 && (
        <SocietyAdditionalDetailsForm
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
          societyData={societyData && societyData.additional_details}
          societyId={societyData && societyData._id}
          currRender={currRender}
        />
      )}
    </Box>
  );
};
