import { Box } from '@mui/material';
import { defaultValues } from '../../constants';
import { color } from '../../theme';
import { Text } from '../text';
import AddIcon from '@mui/icons-material/Add';
import { getTitles } from '../../utils';

export const UISaveAndAdd = ({
  onAddAnother = () => {},
  title = getTitles('T-10062'),
  disabled
}) => {
  const styles = {
    saveAndAddText: {
      color: disabled ? color.secondaryText : color.link,
      cursor: disabled ? 'default' : 'pointer'
    },
    saveAndAddBtnCont: {
      display: 'flex',
      width: 'fit-content',
      alignItems: 'center',
      margin: '10px 0px',
      gap: '10px',
      cursor: disabled ? 'default' : 'pointer'
    },
    saveAndAddBtn: {
      backgroundColor: disabled ? color.secondaryText : color.link,
      borderRadius: '50%',
      color: color.primaryBackground,
      fontSize: defaultValues.isResponsive ? 15 : defaultValues.isMedium ? 17 : 20
    }
  };
  return (
    <Box sx={styles.saveAndAddBtnCont} onClick={() => !disabled && onAddAnother()}>
      <AddIcon sx={styles.saveAndAddBtn} />
      <Text variant="D18" style={styles.saveAndAddText}>
        {title}
      </Text>
    </Box>
  );
};
