import { Box } from '@mui/material';
import {
  ActionButton,
  EditButton,
  PopupModal,
  Text,
  UILayout,
  UISaveAndAdd,
  UITextInputWithTitle
} from '../../../ui-kits';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLanguageRequest,
  getLanguageRequest,
  resetManageProcessMsg,
  updateLanguageParams,
  updateLanguageRequest
} from '../../../services/redux/slices';
import {
  getCurPage,
  getErrorMsg,
  getLanguageParams,
  getLanguagesList,
  getSuccessMsg,
  getTotalPage
} from '../../../services/redux/selectors/manageProccess';
import { toast } from 'react-toastify';
import { getTitles, resetMessages } from '../../../utils/commonFunction';
import { useFormik } from 'formik';
import { ModalButtons } from '../../../components';
import { languageSchema } from '../../../utils/schemas';

export const ManageLanguages = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const languages = useSelector(getLanguagesList);
  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const totalPages = useSelector(getTotalPage);
  const currentPage = useSelector(getCurPage);
  const languageParams = useSelector(getLanguageParams);
  const [open, setOpen] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const styles = {
    container: { width: '100%' }
  };

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: '',
        native_name: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: languageSchema
    });

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10024', 'S-10023'].includes(successMsg)) handleLanguage();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    handleLanguage();
  }, []);

  const handleLanguage = (data) => {
    const payload = { ...languageParams, ...data };
    dispatch(updateLanguageParams(payload));
    dispatch(getLanguageRequest(payload));
  };

  const handleStatus = (id, status) => {
    dispatch(updateLanguageRequest({ _id: id, is_active: !status }));
  };

  const tableData = languages.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10168'),
          values: item.name
        },
        {
          title: getTitles('T-10167'),
          values: <Text variant="D20">{item.native_name}</Text>
        },
        {
          title: getTitles('T-10103'),
          values: (
            <ActionButton
              status={item.is_active}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
              alterTitle={defaultValues.confirmationModel.changeLanguageStatus.title(
                item.is_active
              )}
              alterSubTitle={defaultValues.confirmationModel.changeLanguageStatus.subtitles}
              isReadOnly={isReadOnly}
            />
          )
        },
        {
          title: getTitles('T-10084'),
          columnWidth: '10%',
          values: <EditButton onClick={() => handleEdit(item)} isReadOnly={isReadOnly} />
        }
      ];
    } else {
      return {
        title: item.name,
        details: [
          {
            subTitle: getTitles('T-10167'),
            action: <Text variant="D20">{item.native_name}</Text>
          },
          {
            subTitle: getTitles('T-10084'),
            action: <EditButton onClick={() => handleEdit(item)} isReadOnly={isReadOnly} />
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                alterTitle={defaultValues.confirmationModel.changeLanguageStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeLanguageStatus.subtitles}
                isReadOnly={isReadOnly}
              />
            )
          }
        ]
      };
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('native_name', data.native_name);
    setFieldValue('name', data.name);
    setFieldValue('_id', data._id);
    setOpen(true);
    setFormChanged(false);
  };

  const handleSearch = (data) => {
    handleLanguage({ search_text: data });
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  const handlePagination = (e, data) => {
    handleLanguage({ page: data });
  };

  const handleSaveAndClose = () => {
    if (isEdit) {
      dispatch(updateLanguageRequest(values));
    } else {
      dispatch(addLanguageRequest(values));
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    dispatch(addLanguageRequest(values));
    resetForm();
    setFormChanged(false);
  };

  return (
    <Box sx={styles.container}>
      <UILayout
        handleAddButton={() => setOpen(true)}
        handleSearch={handleSearch}
        tableTitle={getTitles('T-10170')}
        showNoData={!languages.length > 0}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        tableItems={tableData}
        showPagination={languages.length > 0 && totalPages > 1}
        searchBarPlaceHolder={getTitles('T-10171')}
        showDataTable={!defaultValues.isResponsive}
        message={getErrorMessage('E-10038')}
        isReadOnly={isReadOnly}
      />

      <PopupModal
        showModel={open}
        onClose={handleModalClose}
        title={isEdit ? getTitles('T-10169') : getTitles('T-10058')}>
        <UITextInputWithTitle
          name="name"
          title={getTitles('T-10059')}
          placeHolder={getTitles('T-10061')}
          error={touched.name && errors.name}
          value={values.name}
          variant="D22"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <UITextInputWithTitle
          name="native_name"
          variant="D22"
          title={getTitles('T-10060')}
          placeHolder={getTitles('T-10061')}
          error={touched.native_name && errors.native_name}
          value={values.native_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Box>
  );
};
