import { createSelector } from '@reduxjs/toolkit';

export const getInsurance = createSelector([(state) => state.insurance.insurances], (d) => d);
export const getInsuranceCurPage = createSelector(
  [(state) => state.insurance.currentPage],
  (d) => d
);
export const getInsuranceNxtPage = createSelector([(state) => state.insurance.nextPage], (d) => d);
export const getInsuranceTotalPage = createSelector(
  [(state) => state.insurance.totalPage],
  (d) => d
);
export const getInsuranceLoading = createSelector([(state) => state.insurance.isLoading], (d) => d);
export const getInsuranceData = createSelector(
  [(state) => state.insurance.insuranceData],
  (d) => d
);
export const getInsuranceSuccessMsg = createSelector(
  [(state) => state.insurance.successMessage],
  (d) => d
);
export const getInsuranceErrorMsg = createSelector(
  [(state) => state.insurance.errorMessage],
  (d) => d
);
export const getInsuranceCurrRender = createSelector(
  [(state) => state.insurance.currRender],
  (d) => d
);

export const getInsuranceCompany = createSelector(
  [(state) => state.insurance.insuranceCompany],
  (d) => d
);

export const getInsuranceParams = createSelector(
  [(state) => state.insurance.insuranceParams],
  (d) => d
);
