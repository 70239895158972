import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { ActionButton, EditButton, Text, UILayout } from '../../../ui-kits';
import {
  resetManageProcessMsg,
  setVenderActivepage,
  updateDetailsPg,
  getVenderRequest,
  setVenderDetails,
  updateVenderRequest
} from '../../../services/redux/slices';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { getTitles, resetMessages } from '../../../utils';
import {
  getErrorMsg,
  getSuccessMsg,
  getVenderTotalPage,
  getVenderCurrentPage,
  getVenderActivePage,
  getVender
} from '../../../services/redux/selectors/manageProccess';
import { BasicProfile } from '../../../components';
import { AddEditVendor } from '../../../components/HOC';

export const ManageVendor = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const vendorList = useSelector(getVender);

  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const totalPages = useSelector(getVenderTotalPage);
  const currentPage = useSelector(getVenderCurrentPage);
  const activePage = useSelector(getVenderActivePage);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);

  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    handleVendor();
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10117', 'S-10118'].includes(successMsg)) {
        handleVendor();
        dispatch(setVenderActivepage(null));
        dispatch(updateDetailsPg(false));
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleVendor = (val) => {
    let payload = { sort, filter, ...val };
    dispatch(getVenderRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(updateVenderRequest({ _id: item._id, is_active: !item.is_active }));
  };

  const handleTitleClick = (data, currPage) => {
    dispatch(setVenderActivepage(currPage || view));
    dispatch(updateDetailsPg(true));
    dispatch(setVenderDetails(data));
  };

  const handleSearch = (data) => {
    handleVendor({ search_text: data || null });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleVendor({ filter: JSON.stringify(data) });
  };

  const handleSort = (data) => {
    setSort(data);
    handleVendor({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleVendor({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handlePagination = (e, data) => {
    handleVendor({ page: data });
  };

  const handleAddClick = () => {
    dispatch(updateDetailsPg(true));
    dispatch(setVenderActivepage(add));
    dispatch(setVenderDetails(null));
  };

  const tableData =
    vendorList?.length > 0 &&
    vendorList.map((item) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-11448'),
            values: <Text variant="D20">{item.vendor_id}</Text>
          },
          {
            title: getTitles('T-11449'),
            values: (
              <BasicProfile
                title={`${[item.first_name, item.middle_name || '', item.last_name].join(' ')}`}
                isIcon={false}
                onTitleClick={() => handleTitleClick(item)}
              />
            )
          },
          {
            title: getTitles('T-10440'),
            values: <Text variant="D20">{item.phone_number}</Text>
          },
          {
            title: getTitles('T-10103'),
            values: (
              <ActionButton
                status={item.is_active}
                alterTitle={defaultValues.confirmationModel.changeVenderStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.subTitle}
                onStatusChange={() => handleStatus(item)}
                isReadOnly={isReadOnly}
              />
            )
          },
          {
            title: getTitles('T-10084'),
            columnWidth: '10%',
            values: <EditButton onClick={() => handleTitleClick(item, edit)} />
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={`${[item.first_name, item.middle_name || '', item.last_name].join(' ')}`}
              isIcon={false}
              onTitleClick={() => handleTitleClick(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-11448'),
              action: item.vendor_id
            },
            {
              subTitle: getTitles('T-10440'),
              action: item.phone_number
            },
            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  alterTitle={defaultValues.confirmationModel.changeVenderStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.subTitle}
                  onStatusChange={() => handleStatus(item)}
                  isReadOnly={isReadOnly}
                />
              )
            },
            {
              subTitle: getTitles('T-10084'),
              action: <EditButton onClick={() => handleTitleClick(item, edit)} />
            }
          ]
        };
      }
    });

  if ([add, edit, view].includes(activePage)) {
    return <AddEditVendor isReadOnly={isReadOnly} />;
  }
  return (
    <UILayout
      handleSearch={handleSearch}
      searchBarPlaceHolder={getTitles('T-10308')}
      handleAddButton={handleAddClick}
      handleFilter={handleFilter}
      handleSort={handleSort}
      handleRefresh={handleRefresh}
      clearSearch={filter}
      isMultiSelectFilterOption={true}
      currentlyActiveFilterOption={filter}
      currentlyActiveSortOption={sort}
      optionsForSorting={defaultValues.farmerSortOptions}
      tableItems={tableData}
      currPage={currentPage}
      pageCount={totalPages}
      handlePagination={handlePagination}
      showPagination={totalPages > 1}
      showDataTable={!defaultValues.isResponsive}
      showNoData={!vendorList?.length > 0}
      message={getErrorMessage('E-10219')}
      isReadOnly={isReadOnly}
    />
  );
};
