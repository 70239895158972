import { useEffect, useState } from 'react';
import {
  ActionButton,
  EditButton,
  PopupModal,
  Text,
  UILayout,
  UISaveAndAdd,
  UITextInputWithTitle
} from '../../ui-kits';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMasterListRequest,
  clearMasterData,
  getMasterListRequest,
  resetManageProcessMsg,
  updateMasterId,
  updateMasterListRequest
} from '../../services/redux/slices';
import {
  getErrorMsg,
  getMasterId,
  getMasterList,
  getSuccessMsg
} from '../../services/redux/selectors/manageProccess';
import { ModalButtons } from '../common/modal-buttons';
import { useFormik } from 'formik';
import { getTitles, masterListSchema, resetMessages } from '../../utils';
import { toast } from 'react-toastify';

export const MasterList = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const masterListItems = useSelector(getMasterList);
  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const currMaster = useSelector(getMasterId);

  const [masterList, setMasterList] = useState([]);
  const [masterId, setMasterId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [labels, setLabels] = useState(defaultValues.masterTypeLabels[0]);

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: masterListSchema
    });

  useEffect(() => {
    dispatch(clearMasterData());
  }, []);

  useEffect(() => {
    setMasterId(currMaster);
    if (currMaster) {
      setLabels(defaultValues.masterTypeLabels[currMaster]);
      dispatch(getMasterListRequest({ master_id: currMaster }));
    }
  }, [currMaster]);

  useEffect(() => {
    if (masterListItems && masterId) {
      setMasterList(masterListItems);
    }
  }, [masterListItems]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleSearch = (data) => {
    const temp = [...masterListItems];
    if (data) {
      const searched = temp.filter((item) => {
        const searchTerm = data.toLowerCase();
        const listName = item.name.toLowerCase();
        return listName.includes(searchTerm);
      });
      setMasterList(searched);
    } else {
      setMasterList(masterListItems);
    }
  };

  const handleAddButton = () => {
    setOpen(true);
  };

  const handleStatus = (id, status) => {
    dispatch(updateMasterListRequest({ master_id: masterId, _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('_id', data._id);
    setFieldValue('name', data.name);
    setOpen(true);
    setFormChanged(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleChangeMasterList = (data) => {
    dispatch(updateMasterId(data));
  };

  const handleAddAnother = () => {
    dispatch(addMasterListRequest({ master_id: masterId, ...values }));
    resetForm();
  };

  const handleSaveAndClose = () => {
    if (isEdit) {
      dispatch(updateMasterListRequest({ master_id: masterId, ...values }));
    } else {
      dispatch(addMasterListRequest({ master_id: masterId, ...values }));
    }
    handleModalClose();
  };

  const styles = {
    title: {
      cursor: 'pointer',
      overflowWrap: 'anywhere'
    },
    columnWidth: '50%',
    actionColumn: defaultValues.isMobile ? '30%' : defaultValues.isMedium ? '34%' : '38%'
  };

  const tableData = masterList.map((item) => [
    {
      title: getTitles('T-10081'),
      columnWidth: styles.columnWidth,
      values: (
        <Text variant="D20" style={styles.title}>
          {item.name}
        </Text>
      )
    },
    {
      title: getTitles('T-10103'),
      columnWidth: styles.actionColumn,
      values: (
        <ActionButton
          status={item.is_active}
          alterTitle={defaultValues.confirmationModel.changeMasterListStatus.title(item.is_active)}
          alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
          onStatusChange={() => handleStatus(item._id, item.is_active)}
          isReadOnly={isReadOnly}
        />
      )
    },
    {
      title: getTitles('T-10084'),
      values: (
        <EditButton
          status={item.is_active}
          onClick={() => handleEdit(item)}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]);

  return (
    <>
      <UILayout
        handleSearch={handleSearch}
        searchBarPlaceHolder={labels.searchPlaceholder}
        onlySearchAlphabets={true}
        disableSearch={!masterId}
        handleAddButton={masterId && handleAddButton}
        tableItems={tableData}
        tableTitle={!defaultValues.isResponsive && labels.title}
        showNoData={!masterList.length > 0}
        message={masterId ? getErrorMessage('E-10028') : getTitles('T-10086')}
        subMessage={!masterId && getTitles('T-10085')}
        options={defaultValues.master_type}
        selectedOption={handleChangeMasterList}
        selectValue={masterId}
        isReadOnly={isReadOnly}
      />

      <PopupModal
        title={isEdit ? labels.editTitle : labels.addTitle}
        onClose={handleModalClose}
        showModel={open}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={labels.name}
          placeHolder={labels.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
        />

        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </>
  );
};
