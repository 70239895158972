import { createSelector } from '@reduxjs/toolkit';

export const getReligions = createSelector([(state) => state.farmer.religion], (d) => d);
export const getFarmersList = createSelector([(state) => state.farmer.farmers], (d) => d);
export const getFarmerCurPage = createSelector([(state) => state.farmer.currentPage], (d) => d);
export const getFarmerNxtPage = createSelector([(state) => state.farmer.nextPage], (d) => d);
export const getFarmerTotalPage = createSelector([(state) => state.farmer.totalPage], (d) => d);
export const getFarmerLoading = createSelector([(state) => state.farmer.isLoading], (d) => d);
export const getFarmerData = createSelector([(state) => state.farmer.farmerData], (d) => d);
export const getFarmerSuccessMsg = createSelector(
  [(state) => state.farmer.successMessage],
  (d) => d
);
export const getFarmerErrorMsg = createSelector([(state) => state.farmer.errorMessage], (d) => d);
export const getFarmerActivePage = createSelector([(state) => state.farmer.activePage], (d) => d);
export const getActionType = createSelector([(state) => state.farmer.type], (d) => d);
export const getCompletedStep = createSelector([(state) => state.farmer.completedStep], (d) => d);
export const getfarmerActiveStep = createSelector([(state) => state.farmer.activeStep], (d) => d);
export const getfarmerDistrictNextPage = createSelector(
  [(state) => state.farmer.farmerDistrictNxtPage],
  (d) => d
);
export const getfarmerTalukaNextPage = createSelector(
  [(state) => state.farmer.farmerTalukaNxtPage],
  (d) => d
);
export const getfarmerVillageNextPage = createSelector(
  [(state) => state.farmer.farmerVillageNxtPage],
  (d) => d
);

export const getfarmerDistrict = createSelector([(state) => state.farmer.farmerDistrict], (d) => d);
export const getfarmerTaluka = createSelector([(state) => state.farmer.farmerTaluka], (d) => d);
export const getfarmerVillage = createSelector([(state) => state.farmer.farmerVillage], (d) => d);
