import { put, takeLatest } from 'redux-saga/effects';
import {
  addCattleFailure,
  addCattleRequest,
  addCattleSuccess,
  deleteCattlePhotoFailure,
  deleteCattlePhotoRequest,
  deleteCattlePhotoSuccess,
  getCattleDetailsFailure,
  getCattleDetailsRequest,
  getCattleDetailsSuccess,
  getCattlesFailure,
  getCattlesRequest,
  getCattlesSuccess,
  getLabReportFailure,
  getLabReportRequest,
  getLabReportSuccess,
  updateCattleFailure,
  updateCattleRequest,
  updateCattleSuccess,
  uploadCattlePhotosFailure,
  uploadCattlePhotosRequest,
  uploadCattlePhotosSuccess
} from '../slices';
import { KEYS } from '../../../constants';
import {
  addCattleAPI,
  deleteCattlePhotoAPI,
  getCattleDetailsApi,
  getCattlesApi,
  getReportsApi,
  updateCattleAPI,
  uploadCattlePhotosAPI
} from '../../api';

function* getCattles(action) {
  try {
    const response = yield getCattlesApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCattlesSuccess(response.data));
    } else {
      yield put(getCattlesFailure(response.data));
    }
  } catch (error) {
    yield put(getCattlesFailure(error.response.data));
  }
}

function* getReports(action) {
  try {
    const response = yield getReportsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getLabReportSuccess(response.data));
    } else {
      yield put(getLabReportFailure(response.data));
    }
  } catch (error) {
    yield put(getLabReportFailure(error.response.data));
  }
}

function* addCattle(action) {
  try {
    const response = yield addCattleAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addCattleSuccess(response.data));
    } else {
      yield put(addCattleFailure(response.data));
    }
  } catch (error) {
    yield put(addCattleFailure(error.response.data));
  }
}

function* getCattleDetails(action) {
  try {
    const response = yield getCattleDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCattleDetailsSuccess(response.data));
    } else {
      yield put(getCattleDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getCattleDetailsFailure(error.response.data));
  }
}

function* uploadCattlePhotos(action) {
  try {
    const response = yield uploadCattlePhotosAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(uploadCattlePhotosSuccess(response.data));
    } else {
      yield put(uploadCattlePhotosFailure(response.data));
    }
  } catch (error) {
    yield put(uploadCattlePhotosFailure(error.response.data));
  }
}

function* deleteCattlePhoto(action) {
  try {
    const response = yield deleteCattlePhotoAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(deleteCattlePhotoSuccess(response.data));
    } else {
      yield put(deleteCattlePhotoFailure(response.data));
    }
  } catch (error) {
    yield put(deleteCattlePhotoFailure(error.response.data));
  }
}

function* updateCattle(action) {
  try {
    const response = yield updateCattleAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateCattleSuccess(response.data));
    } else {
      yield put(updateCattleFailure(response.data));
    }
  } catch (error) {
    yield put(updateCattleFailure(error.response.data));
  }
}

export function* cattleSaga() {
  yield takeLatest(getCattlesRequest.type, getCattles);
  yield takeLatest(getLabReportRequest.type, getReports);
  yield takeLatest(addCattleRequest.type, addCattle);
  yield takeLatest(getCattleDetailsRequest.type, getCattleDetails);
  yield takeLatest(uploadCattlePhotosRequest.type, uploadCattlePhotos);
  yield takeLatest(deleteCattlePhotoRequest.type, deleteCattlePhoto);
  yield takeLatest(updateCattleRequest.type, updateCattle);
}
