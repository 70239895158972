import { Box } from '@mui/material';
import { EditButton, Text, UIButton } from '../../../ui-kits';
import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  resCurrPgNameCont: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px',
    marginRight: 0
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  },
  btn: {
    minWidth: res && 0,
    fontSize: res ? '1.35rem' : '1.3rem',
    padding: res ? '5px' : '5px 20px',
    width: res ? 'fit-content' : '150px',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box'
  }
};
export const ResActionTitle = ({
  title,
  handleBtnClick,
  startIcon = <EditButton iconStyle={styles.editIcon} />
}) => {
  return (
    <Box sx={styles.resCurrPgNameCont}>
      {title && <Text variant="boldH32Sec">{title}</Text>}
      {handleBtnClick && (
        <UIButton startIcon={startIcon} style={styles.btn} onClick={handleBtnClick} />
      )}
    </Box>
  );
};
