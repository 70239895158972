import { Box, Toolbar } from '@mui/material';
import { HeaderStyles } from './styles';
import { KEYS, SCREEN_PATH, defaultValues, lang } from '../../../constants';
import { Text } from '../../../ui-kits';

import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  authStatus,
  getDetailsPg,
  getEcommerce,
  getUserData
} from '../../../services/redux/selectors';
import {
  setIsEcommerce,
  updateLanguage,
  updatePhone,
  updatePhoneVerified
} from '../../../services/redux/slices/authSlice';
import { getApplicationLanguage } from '../../../utils/commonFunction';
import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Sidebar } from '../../../ui-kits/side-bar';
import { AuthenticatedHeader } from './authenticated-header';
import { NotAuthenticatedHeader } from './not-authenticated-header';
import { ECommerceHeader } from './e-commerce-header';
import { resetECommerce } from '../../../services/redux/slices';

export const Header = ({ currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(authStatus);
  const isEcommerce = useSelector(getEcommerce);
  const userData = useSelector(getUserData);
  const detailsPg = useSelector(getDetailsPg);
  const [open, setOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);

  useEffect(() => {
    dispatch(
      setIsEcommerce(
        ([SCREEN_PATH.ECOMMERCE].includes(location.pathname) ||
          (isEcommerce && location.pathname === SCREEN_PATH.PROFILE)) &&
          defaultValues.appLogicalTexts.eCommerce
      )
    );
  }, [location, dispatch]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleDisplayLang = (val) => {
    setLangOpen(val);
  };

  const handleLanguage = (val) => {
    localStorage.setItem(KEYS.LANGUAGE, val?.value || lang.english);
    dispatch(updateLanguage({ language: val?.value || lang.english }));
  };

  const handleTools = (item) => {
    navigate(item.url);
    dispatch(updatePhoneVerified(false));
    dispatch(updatePhone(null));
    if (item.url === SCREEN_PATH.ECOMMERCE)
      dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(resetECommerce());
  };

  return (
    <Box sx={HeaderStyles.container(isAuthenticated, isEcommerce)}>
      <Box sx={HeaderStyles.subContainer(isAuthenticated)}>
        <Toolbar sx={HeaderStyles.toolbar(isAuthenticated)} disableGutters>
          {isEcommerce ? (
            <ECommerceHeader
              isAuthenticated={isAuthenticated}
              toggleDrawer={toggleDrawer}
              detailsPg={detailsPg}
              currentPage={currentPage}
              setLangOpen={setLangOpen}
              langOpen={langOpen}
              handleDisplayLang={handleDisplayLang}
              handleLanguage={handleLanguage}
              getApplicationLanguage={getApplicationLanguage}
              userData={userData}
            />
          ) : !isAuthenticated ? (
            <NotAuthenticatedHeader handleTools={handleTools} toggleDrawer={toggleDrawer} />
          ) : (
            <AuthenticatedHeader
              isAuthenticated={isAuthenticated}
              toggleDrawer={toggleDrawer}
              detailsPg={detailsPg}
              currentPage={currentPage}
              setLangOpen={setLangOpen}
              langOpen={langOpen}
              handleDisplayLang={handleDisplayLang}
              handleLanguage={handleLanguage}
              getApplicationLanguage={getApplicationLanguage}
              userData={userData}
            />
          )}
        </Toolbar>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} sx={HeaderStyles.drawer}>
        {isAuthenticated ? (
          <Sidebar />
        ) : (
          <Box sx={{ mt: 2 }}>
            {defaultValues.noAuthToolbar.map((item, idx) => (
              <Box key={idx} sx={HeaderStyles.toolCont} onClick={() => handleTools(item)}>
                <Text variant="D24" style={HeaderStyles.toolOptionsText}>
                  {item.title}
                </Text>
              </Box>
            ))}
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
