import { Box, IconButton } from '@mui/material';
import { LoadingScreen, Screen, Text, UIButton, UIStepper } from '../../../ui-kits';
import { checkReadOnly, getTitles, getUniqueAndSortedData } from '../../../utils';
import { UIBackButton } from '../../../ui-kits/back-button';
import { color } from '../../../theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFarmerDetailsRequest,
  getMasterListWiseRequest,
  getSocietyRequest,
  setActionType,
  setFarmerActiveStep,
  updateDetailsPg
} from '../../../services/redux/slices';
import { defaultValues } from '../../../constants';
import {
  FodderDetails,
  IdentificationDetails,
  LandDetails,
  PersonalDetails,
  ProductionDetails
} from '../../../components';
import EditIcon from '@mui/icons-material/Edit';
import {
  getActionType,
  getCompletedStep,
  getDetailsPg,
  getfarmerActiveStep,
  getFarmerLoading,
  getSocietyNextpage,
  getSocietys,
  getUserData
} from '../../../services/redux/selectors';
import { useLocation, useNavigate } from 'react-router-dom';

export const RegisterFarmer = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const { view, edit, viewOnly } = defaultValues.actionType;

  const actionType = useSelector(getActionType);
  const userData = useSelector(getUserData);
  const isLoading = useSelector(getFarmerLoading);
  const farmerStep = useSelector(getCompletedStep);
  const detailsPg = useSelector(getDetailsPg);
  const societies = useSelector(getSocietys);
  const societyNextPage = useSelector(getSocietyNextpage);
  const activeStep = useSelector(getfarmerActiveStep);

  const [activeTitle, setActiveTitle] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [societyList, setSocietyList] = useState([]);
  const [societyNxtPage, setSocietyNxtPage] = useState(null);

  useEffect(() => {
    dispatch(
      getSocietyRequest({
        page: 1,
        filter: JSON.stringify([{ is_active: true }, { approval_status: 2 }])
      })
    );
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([
          defaultValues.mListIds.rationCardColor,
          defaultValues.mListIds.occupation,
          defaultValues.mListIds.training,
          defaultValues.mListIds.crops,
          defaultValues.mListIds.agricultureSourceWater,
          defaultValues.mListIds.cattleSourceWater,
          defaultValues.mListIds.feedBrand,
          defaultValues.mListIds.reasonsForOtherFeedBrand,
          defaultValues.mListIds.feedAlternatives
        ]),
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    setSocietyNxtPage(societyNextPage);
  }, [societyNextPage]);

  useEffect(() => {
    if (actionType && actionType !== viewOnly && farmerStep && farmerStep < 5) {
      dispatch(setFarmerActiveStep(farmerStep));
      dispatch(setActionType(null));
    }
  }, [farmerStep]);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  const titles = [
    getTitles('T-10330'),
    getTitles('T-10726'),
    getTitles('T-10727'),
    getTitles('T-10728'),
    getTitles('T-10729')
  ];

  const steps = defaultValues.isResponsive ? ['', '', '', '', ''] : titles;

  useEffect(() => {
    if (societyNxtPage !== null) {
      dispatch(
        getSocietyRequest({
          page: societyNxtPage,
          filter: JSON.stringify([{ is_active: true }, { approval_status: 2 }])
        })
      );
    }
  }, [societyNxtPage]);

  useEffect(() => {
    if (
      (actionType === view || actionType === viewOnly) &&
      location.state &&
      location.state.farmer_id
    ) {
      let farmer_id = location.state.farmer_id;
      dispatch(getFarmerDetailsRequest({ farmer_id }));
    }
  }, [location.state && location.state.farmer_id]);

  useEffect(() => {
    setActiveTitle(titles[activeStep]);
  }, [activeStep, titles]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.farmerManager));
  }, [userData]);

  useEffect(() => {
    let temp = [...societyList, ...societies];
    setSocietyList(getUniqueAndSortedData(temp));
  }, [societies]);

  const handleNextStep = (step = 1) => {
    dispatch(setFarmerActiveStep(activeStep + step));
  };

  const handlePrevStep = (step = 1) => {
    dispatch(setFarmerActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <PersonalDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            society={societyList}
            permissionId={
              userData.user_role === defaultValues.userRole.employee
                ? defaultValues.employeeRol.farmerManager
                : null
            }
            showAssignedLocation={
              userData.user_role === defaultValues.userRole.employee ? true : false
            }
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
      case 1:
        return (
          <IdentificationDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
      case 2:
        return (
          <ProductionDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
      case 3:
        return (
          <LandDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
      case 4:
        return (
          <FodderDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
      default:
        return (
          <PersonalDetails
            onNext={() => handleNextStep(1)}
            onPrev={() => handlePrevStep(1)}
            setNext={(val) => dispatch(setFarmerActiveStep(val))}
          />
        );
    }
  };

  const handleBackButton = () => {
    if (activeStep === 0) {
      navigation(-1);
    } else {
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  const styles = {
    container: {
      width: '100%',
      minHeight: '100%',
      backgroundColor: color.primaryBackground,
      borderRadius: 1
    },
    subContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      p: defaultValues.isResponsive ? 1 : 2
    },
    icon: {
      width: '35px',
      height: '35px',
      borderRadius: '5px',
      backgroundColor: color.primary,
      minWidth: 'unset',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: color.tertiaryText,
      mr: 1,
      '&:hover': {
        backgroundColor: color.primary,
        color: color.tertiaryText
      }
    },
    btn: {
      padding: '5px 20px',
      height: defaultValues.isResponsive ? '35px' : '45px',
      width: defaultValues.isResponsive ? '80px' : '150px'
    },
    flex: { display: 'flex', alignItems: 'center' }
  };

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10117')}>
      <Box sx={styles.container}>
        <LoadingScreen isLoading={isLoading} />
        <Box sx={styles.subContainer}>
          {defaultValues.isResponsive ? (
            <Text variant="boldH32Sec" style={{ mb: 1 }}>
              {getTitles(actionType ? 'T-11229' : 'T-11228')}
            </Text>
          ) : (
            <Box sx={styles.flex}>
              <UIBackButton onClick={handleBackButton} />
              <Text variant="boldH32Sec">{getTitles(actionType ? 'T-11229' : 'T-11228')}</Text>
            </Box>
          )}
          {actionType === view && !isReadOnly && (
            <>
              {defaultValues.isResponsive ? (
                <IconButton onClick={() => dispatch(setActionType(edit))} sx={styles.icon}>
                  <EditIcon />
                </IconButton>
              ) : (
                <UIButton
                  title={getTitles('T-10084')}
                  style={styles.btn}
                  startIcon={<EditIcon />}
                  onClick={() => dispatch(setActionType(edit))}
                />
              )}
            </>
          )}
        </Box>
        <UIStepper
          steps={steps}
          activeStep={activeStep}
          onStepperClick={(index) => {
            if (index <= farmerStep) {
              if (index < farmerStep) {
                dispatch(setFarmerActiveStep(index));
              } else {
                dispatch(setActionType(null));
                dispatch(setFarmerActiveStep(index));
              }
            }
          }} // Conditionally set the click handler
        />
        {defaultValues.isResponsive && (
          <Text variant="BoldH22" style={{ m: 1, p: 1 }}>
            {activeTitle}
          </Text>
        )}
        {renderStepContent(activeStep)}
      </Box>
    </Screen>
  );
};
