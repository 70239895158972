import { Box } from '@mui/material';
import { ModalButtons } from '../../common/modal-buttons';
import { farmerPersonalSchema, getTitles } from '../../../utils';
import { DragDrop, PopupModal, RenderFile, Text, UITextInputWithTitle } from '../../../ui-kits';
import { useFormik } from 'formik';
import {
  resetFarmerMsg,
  setActionType,
  uploadFarmerDocRequest
} from '../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionType,
  getFarmerData,
  getFarmerErrorMsg,
  getFarmerSuccessMsg
} from '../../../services/redux/selectors';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { Styles } from '../style';
import { globalStyles } from '../../../theme';

export const IdentificationDetails = ({ onNext, onPrev, setNext }) => {
  const { view, edit, viewOnly } = defaultValues.actionType;
  const dispatch = useDispatch();
  const success = useSelector(getFarmerSuccessMsg);
  const error = useSelector(getFarmerErrorMsg);
  const farmerData = useSelector(getFarmerData);
  const actionType = useSelector(getActionType);

  const [btnDisable, setBtnDisable] = useState(true);
  const [open, setOpen] = useState(null);
  const [modalBtnDisabled, setModalBtnDisabled] = useState(false);

  const { values, setFieldValue, touched, setValues, handleBlur, errors } = useFormik({
    initialValues: {
      _id: farmerData?._id,
      id_photo: null,
      aadhaar_photo: null,
      pan_card_photo: null,
      driving_license_photo: null,
      voter_id_photo: null,
      ration_card_photo: null,
      other_photo: null,

      other_number: '',
      aadhaar_number: '',
      pan_card_number: '',
      driving_license_number: '',
      voter_id: '',
      ration_card_number: '',

      aadhaar_remarks: '',
      pan_card_remarks: '',
      driving_license_remarks: '',
      voter_id_remarks: '',
      ration_card_remarks: '',
      other_remarks: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: farmerPersonalSchema
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtnDisable(false);
  };
  const voterIdRef = useRef();
  const rationCardRef = useRef();
  const aadhaarRef = useRef();
  const panCardRef = useRef();
  const dfRef = useRef();
  const otherRef = useRef();

  const renderFileUrl = (objFile) => {
    if (objFile && objFile.item && typeof objFile.item === 'string') {
      return objFile.item;
    } else if (
      objFile &&
      objFile.item &&
      objFile.item.item &&
      typeof objFile.item.item === 'string'
    ) {
      return objFile.item.item;
    } else {
      return objFile && objFile.preview && objFile.preview[0];
    }
  };

  const handleModalSave = (file, type) => {
    if (type === 'pan_card_photo') {
      setFieldValue('pan_card_photo', file);
    }
    if (type === 'voter_id_photo') {
      setFieldValue('voter_id_photo', file);
    }
    if (type === 'aadhaar_photo') {
      setFieldValue('aadhaar_photo', file);
    }
    if (type === 'driving_license_photo') {
      setFieldValue('driving_license_photo', file);
    }
    if (type === 'ration_card_photo') {
      setFieldValue('ration_card_photo', file);
    }
    if (type === 'other_photo') {
      setFieldValue('other_photo', file);
    }

    setOpen(null);
  };

  const handleModalClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    dispatch(resetFarmerMsg());
    if (farmerData) {
      const identificationDetails = farmerData?.identification_details || {};
      setValues({
        _id: farmerData?._id,
        aadhaar_photo: identificationDetails.aadhaar_photo,
        pan_card_photo: identificationDetails.pan_card_photo,
        driving_license_photo: identificationDetails.driving_license_photo,
        voter_id_photo: identificationDetails.voter_id_photo,
        ration_card_photo: identificationDetails.ration_card_photo,
        other_photo: identificationDetails.other_photo,

        aadhaar_number: identificationDetails.aadhaar_number,
        pan_card_number: identificationDetails.pan_card_number,
        driving_license_number: identificationDetails.driving_license_number,
        voter_id: identificationDetails.voter_id,
        ration_card_number: identificationDetails.ration_card_number,
        other_number: identificationDetails.other_number,

        aadhaar_remarks: identificationDetails.aadhaar_remarks,
        pan_card_remarks: identificationDetails.pan_card_remarks,
        driving_license_remarks: identificationDetails.driving_license_remarks,
        voter_id_remarks: identificationDetails.voter_id_remarks,
        ration_card_remarks: identificationDetails.ration_card_remarks,
        other_remarks: identificationDetails.other_remarks
      });
    }
  }, [farmerData, setValues]);

  useEffect(() => {
    if (success === 'S-10044') {
      if (!actionType) {
        onNext();
      } else {
        toast.success(getSuccessMessage(success));
      }
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    dispatch(resetFarmerMsg());
  }, [success, error]);

  const handleEditClick = (item, ref, preview, category) => {
    setOpen({ item, ref, preview, category });
    setModalBtnDisabled(true);
  };

  return (
    <Box sx={Styles.container}>
      <Box>
        {!defaultValues.isResponsive && (
          <Box sx={Styles.tableHeading}>
            <Text style={Styles.title}>{getTitles('T-10658')} </Text>
            <Text style={Styles.title}>{getTitles('T-10659')}</Text>
            <Text style={Styles.title}> {getTitles('T-10660')}</Text>
          </Box>
        )}
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10691')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10686')}
              name={'voter_id'}
              value={values.voter_id}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
            <DragDrop
              inputRef={voterIdRef}
              custom={true}
              disabled={actionType === view || actionType === viewOnly}
              fileData={values.voter_id_photo}
              showDelete={actionType !== view}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('voter_id_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('voter_id_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(values.voter_id_photo, voterIdRef, preview, 'pan_card_photo')
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'voter_id_remarks'}
              value={values.voter_id_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10692')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10687')}
              name={'ration_card_number'}
              value={values.ration_card_number}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
            <DragDrop
              inputRef={rationCardRef}
              custom={true}
              disabled={actionType === view || actionType === viewOnly}
              showDelete={actionType !== view}
              fileData={values.ration_card_photo}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('ration_card_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('ration_card_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(
                  values.ration_card_photo,
                  rationCardRef,
                  preview,
                  'ration_card_photo'
                )
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'ration_card_remarks'}
              value={values.ration_card_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10693')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10688')}
              name={'aadhaar_number'}
              value={values.aadhaar_number}
              onChange={handleChange}
              onBlur={handleBlur}
              allowOnlyNumbers
              disabled={actionType === view || actionType === viewOnly}
              error={touched.aadhaar_number && errors.aadhaar_number}
            />
            <DragDrop
              inputRef={aadhaarRef}
              custom={true}
              disabled={actionType === view || actionType === viewOnly}
              fileData={values.aadhaar_photo}
              showDelete={actionType !== view}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('aadhaar_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('aadhaar_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(values.aadhaar_photo, rationCardRef, preview, 'aadhaar_photo')
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'aadhaar_remarks'}
              value={values.aadhaar_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10694')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10689')}
              name={'pan_card_number'}
              value={values.pan_card_number}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
            <DragDrop
              inputRef={panCardRef}
              custom={true}
              showDelete={actionType !== view}
              disabled={actionType === view || actionType === viewOnly}
              fileData={values.pan_card_photo}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('pan_card_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('pan_card_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(values.pan_card_photo, panCardRef, preview, 'pan_card_photo')
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'pan_card_remarks'}
              value={values.pan_card_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10695')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10690')}
              name={'driving_license_number'}
              value={values.driving_license_number}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
            <DragDrop
              inputRef={dfRef}
              custom={true}
              disabled={actionType === view || actionType === viewOnly}
              showDelete={actionType !== view}
              fileData={values.driving_license_photo}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('driving_license_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('driving_license_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(
                  values.driving_license_photo,
                  dfRef,
                  preview,
                  'driving_license_photo'
                )
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'driving_license_remarks'}
              value={values.driving_license_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>{' '}
        <Box sx={Styles.documentDetailsContainer}>
          <Box sx={Styles.docsMargin}>
            <Text style={Styles.subTitle}>{getTitles('T-10554')}</Text>
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              placeHolder={getTitles('T-10805')}
              name={'other_number'}
              value={values.other_number}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
            <DragDrop
              inputRef={otherRef}
              custom={true}
              disabled={actionType === view || actionType === viewOnly}
              showDelete={actionType !== view}
              fileData={values.other_photo}
              handleChange={(file, preview) => {
                open
                  ? setOpen({ ...open, item: file, preview })
                  : setFieldValue('other_photo', file);
                setModalBtnDisabled(false);
                setBtnDisable(false);
              }}
              onDeleteClick={() => {
                setFieldValue('other_photo', null);
                setBtnDisable(false);
              }}
              onEditClick={(preview) =>
                handleEditClick(values.other_photo, otherRef, preview, 'other_photo')
              }
            />
          </Box>
          <Box sx={Styles.docsMargin}>
            <UITextInputWithTitle
              rows={4}
              placeHolder={getTitles('T-11234')}
              name={'other_remarks'}
              value={values.other_remarks}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
            />
          </Box>
        </Box>
      </Box>

      <PopupModal showModel={open} onClose={handleModalClose} title={getTitles('T-10393')}>
        <Box sx={Styles.imgPop}>
          <RenderFile
            filePath={renderFileUrl(open)}
            type={open && open.item && open.item.type && open.item.type}
          />
          {open && open.category !== 'view' && actionType !== view && (
            <Box sx={Styles.popT}>
              <Text
                style={globalStyles.linkDesc}
                variant="D18"
                onClick={() => open.ref.current.click()}>
                {getTitles('T-10435')}
              </Text>
            </Box>
          )}

          {open && open.category !== 'view' && (
            <ModalButtons
              onPrimaryBtnClick={() => {
                handleModalSave(open.item, open.category);
              }}
              primaryBtnTitle={getTitles('T-10394')}
              containerStyle={globalStyles.mt(3)}
              disabled={modalBtnDisabled}
            />
          )}
        </Box>
      </PopupModal>

      <ModalButtons
        btnPositions={defaultValues.isResponsive ? 'center' : 'start'}
        disabled={
          (values.aadhaar_number &&
            values.aadhaar_number.length &&
            values.aadhaar_number.length !== 12) ||
          actionType === edit
            ? btnDisable
            : false
        }
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={() => {
          if (actionType === viewOnly) {
            onPrev();
          } else {
            dispatch(setActionType(view));
            onPrev();
          }
        }}
        onPrimaryBtnClick={
          actionType === view || actionType === viewOnly
            ? () => setNext(2)
            : () => {
                dispatch(uploadFarmerDocRequest(values));
                if (actionType === edit) {
                  dispatch(setActionType(view));
                }
              }
        }
        primaryBtnTitle={
          actionType === edit
            ? getTitles('T-10733')
            : actionType === view || actionType === viewOnly
              ? getTitles('T-10804')
              : getTitles('T-10339')
        }
      />
    </Box>
  );
};
