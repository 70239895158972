import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  cattles: [],
  reports: [],
  cattleData: {},
  cattleNextPage: null,
  cattleCurrPage: null,
  cattleTotalPage: null,
  activeCattlePage: null,
  completedCattleStep: null,
  farmerDetails: null,
  type: null,
  activeStep: 0
};
const cattleSlice = createSlice({
  initialState,
  name: 'cattle',
  reducers: {
    setCattleActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    getCattlesRequest(state) {
      state.isLoading = true;
      state.errorMessage = null;
      state.successMessage = null;
      state.completedCattleStep = null;
      state.cattleData = {};
      state.activeStep = 0;
    },
    getCattlesSuccess(state, action) {
      state.isLoading = false;
      state.cattles = action.payload.data.cattles;
      state.cattleCurrPage = action.payload.data.current_page;
      state.cattleNextPage = action.payload.data.next_page;
      state.cattleTotalPage = action.payload.data.total_page;
    },
    getCattlesFailure(state) {
      state.isLoading = false;
      state.cattles = [];
    },
    getLabReportRequest(state) {
      state.isLoading = true;
    },
    getLabReportSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload.data;
    },
    getLabReportFailure(state, action) {
      state.isLoading = false;
      state.reports = [];
      state.errorMessage = action.payload.status_code;
    },

    addCattleRequest(state) {
      state.isLoading = true;
    },
    addCattleSuccess(state, action) {
      state.isLoading = false;
      state.cattleData = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    addCattleFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    getCattleDetailsRequest(state) {
      state.isLoading = true;
      state.cattleData = {};
    },
    getCattleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.cattleData = action.payload.data;
      state.completedCattleStep = action.payload.data.registration_step;
    },
    getCattleDetailsFailure(state, action) {
      state.isLoading = false;
      state.cattleData = {};
      state.errorMessage = action.payload.status_code;
    },
    uploadCattlePhotosRequest(state) {
      state.isLoading = true;
    },
    uploadCattlePhotosSuccess(state, action) {
      state.isLoading = false;
      state.cattleData.upload_photos = action.payload.data.upload_photos;
      state.successMessage = action.payload.status_code;
    },
    uploadCattlePhotosFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    deleteCattlePhotoRequest(state) {
      state.isLoading = true;
    },
    deleteCattlePhotoSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.cattleData.upload_photos = action.payload.upload_photos;
    },
    deleteCattlePhotoFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateCattleRequest(state) {
      state.isLoading = true;
    },
    updateCattleSuccess(state, action) {
      state.isLoading = false;
      state.cattleData = action.payload.data;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
    },
    updateCattleFailure(state) {
      state.isLoading = false;
    },
    setCurrentCattleActivPage(state, action) {
      state.activeCattlePage = action.payload;
    },
    setFarmeDetails(state, action) {
      state.farmerDetails = action.payload;
    },
    setCattleActionType(state, action) {
      state.type = action.payload;
    },
    setCurrentActivPage(state, action) {
      state.activePage = action.payload;
    },
    resetCattleMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    }
  }
});

export const {
  getCattlesFailure,
  getCattlesRequest,
  getCattlesSuccess,
  setCurrentActivPage,
  getLabReportFailure,
  getLabReportRequest,
  getLabReportSuccess,
  addCattleFailure,
  addCattleRequest,
  addCattleSuccess,
  getCattleDetailsFailure,
  getCattleDetailsRequest,
  getCattleDetailsSuccess,
  uploadCattlePhotosFailure,
  uploadCattlePhotosRequest,
  uploadCattlePhotosSuccess,
  deleteCattlePhotoFailure,
  deleteCattlePhotoRequest,
  deleteCattlePhotoSuccess,
  updateCattleFailure,
  updateCattleRequest,
  updateCattleSuccess,
  setCurrentCattleActivPage,
  setFarmeDetails,
  resetCattleMsg,
  setCattleActionType,
  setCattleActiveStep
} = cattleSlice.actions;

export default cattleSlice.reducer;
