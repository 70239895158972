import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;
export const styles = {
  subContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: res ? 'center' : 'space-around'
  },
  subHeading: {
    fontWeight: 400
  },
  labelRight: {
    p: 0
  },
  supportCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-around'
  },
  socialIcon: {
    width: res ? '20px' : '30px',
    height: res ? '20px' : '30px'
  },
  socialCont: {
    display: 'flex',
    gap: res ? '10px' : '20px'
  },
  connectCont: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    p: 1,
    boxSizing: 'border-box',
    ml: res ? 1 : 0
  },
  linkTexts: {
    padding: res ? '2px' : '8px 8px 8px 0px',
    paddingRight: res ? 0 : '3px'
  },
  logo: {
    marginTop: '20px'
  }
};
