import { Box } from '@mui/material';
import { CreateProfile } from './create-profile';
import { ActionButton, EditButton, Text, UIButton, UILayout } from '../../ui-kits';
import { getTitles, getTitlesByValue, resetMessages } from '../../utils';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { CreateTask } from './create-task';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProfileDetailsRequest,
  getProfilesRequest,
  resetManageProcessMsg,
  resetProfileData,
  setProfileActionType,
  updateProfileDetailsRequest
} from '../../services/redux/slices';
import {
  getCurPage,
  getErrorMsg,
  getProfiles,
  getProfilingActionType,
  getProfilingData,
  getSuccessMsg,
  getTotalPage
} from '../../services/redux/selectors/manageProccess';
import { toast } from 'react-toastify';
import { UIBackButton } from '../../ui-kits/back-button';
import { styles } from './styles';

export const ManageProfiling = () => {
  const dispatch = useDispatch();
  const { view, edit, add } = defaultValues.actionType;
  const isRes = defaultValues.isResponsive;

  const profileData = useSelector(getProfilingData);
  const profileList = useSelector(getProfiles);
  const success = useSelector(getSuccessMsg);
  const error = useSelector(getErrorMsg);
  const currentPage = useSelector(getCurPage);
  const totalPage = useSelector(getTotalPage);
  const actionType = useSelector(getProfilingActionType);

  const [profiles, setProfiles] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState('');
  const [isAdd, setAdd] = useState(false);
  const [isProfileCreated, setProfileCreated] = useState(false);

  useEffect(() => {
    dispatch(getProfilesRequest());
  }, []);

  useEffect(() => {
    setProfiles(profileList);
  }, [profileList]);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
      if (success === 'S-10086') {
        if (isProfileCreated) {
          dispatch(getProfilesRequest());
          setAdd(false);
          setProfileCreated(false);
        } else {
          setProfileCreated(true);
        }
      }
      if (success === 'S-10089') {
        setAdd(true);
      }
      if (success === 'S-10090') {
        if (isAdd) {
          dispatch(setProfileActionType(view));
        } else {
          dispatch(getProfilesRequest({ page: currentPage }));
        }
      }
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [success, error]);

  const handleNextView = (view) => {
    if (view) {
      setProfileCreated(view);
    } else {
      setAdd(view);
    }
  };

  const tableData = profiles?.map((item) => {
    if (!isRes) {
      return [
        {
          title: getTitles('T-11156'),
          columnWidth: styles.columnWidth,
          values: (
            <Text
              variant="D20"
              onClick={() => {
                dispatch(getProfileDetailsRequest({ _id: item._id }));
                dispatch(setProfileActionType(view));
              }}
              style={styles.cursor}>
              {item?.flow_type?.name}
            </Text>
          )
        },
        {
          title: getTitles('T-11158'),
          columnWidth: styles.columnWidth,
          values: (
            <Text style={styles.overLap} variant="D20">
              {item.flow_name}
            </Text>
          )
        },
        {
          title: getTitles('T-10103'),
          columnWidth: styles.columnWidth,
          values: (
            <ActionButton
              status={item.is_active}
              alterTitle={defaultValues.confirmationModel.changeProfilingStatus.title(
                item.is_active
              )}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
            />
          )
        },
        {
          title: getTitles('T-10084'),
          values: (
            <EditButton
              status={item.is_active}
              onClick={() => {
                dispatch(getProfileDetailsRequest({ _id: item._id }));
                dispatch(setProfileActionType(edit));
              }}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Text
            variant="D20"
            onClick={() => {
              dispatch(getProfileDetailsRequest({ _id: item._id }));
              dispatch(setProfileActionType(view));
            }}>
            {item?.flow_type?.name}
          </Text>
        ),
        details: [
          {
            subTitle: getTitles('T-11158'),
            action: (
              <Text style={styles.overLap} variant="D20">
                {item.flow_name}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                alterTitle={defaultValues.confirmationModel.changeProfilingStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
              />
            )
          },
          {
            subTitle: getTitles('T-10084'),
            action: (
              <EditButton
                status={item.is_active}
                onClick={() => {
                  dispatch(getProfileDetailsRequest({ _id: item._id }));
                  dispatch(setProfileActionType(edit));
                }}
              />
            )
          }
        ]
      };
    }
  });

  const handleSearch = (data) => {
    dispatch(getProfilesRequest({ search_text: data }));
  };

  const handleRefresh = () => {
    setSort('');
    setFilter([]);
    dispatch(getProfilesRequest());
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(getProfilesRequest({ sort: data, filter: JSON.stringify(filter) }));
  };

  const handleFilter = (data) => {
    setFilter(data);
    dispatch(getProfilesRequest({ sort: sort, filter: JSON.stringify(data) }));
  };

  const handleAdd = () => {
    setAdd(true);
    dispatch(resetProfileData());
  };

  const handleStatus = (_id, status) => {
    dispatch(updateProfileDetailsRequest({ _id, is_active: !status }));
  };

  const handlePagination = (e, data) => {
    dispatch(getProfilesRequest({ page: data }));
  };

  return (
    <>
      {isAdd ? (
        <Box sx={styles.container}>
          <Box sx={styles.subContainer}>
            <Box sx={styles.itemContainer}>
              <Text variant="boldH32Sec" style={styles.title}>
                {getTitles(actionType === view || actionType === edit ? 'T-11341' : 'T-11155')}
              </Text>

              {(actionType === add &&
                profileData?.flow_name &&
                profileData?.flow_type?.id &&
                !isProfileCreated) ||
              (actionType === view && !isProfileCreated) ? (
                <UIButton
                  title={!isRes ? getTitles('T-10084') : ''}
                  style={styles.btn}
                  startIcon={<EditButton />}
                  onClick={() => dispatch(setProfileActionType(edit))}
                />
              ) : null}
            </Box>
            {isProfileCreated && (
              <Box sx={styles.backBtnContainer}>
                <UIBackButton onClick={() => setProfileCreated(false)} />
                <Box>
                  <Text variant="D24">
                    {getTitlesByValue(
                      [profileData?.flow_type?.id],
                      defaultValues.actionFlowOptions
                    )}
                  </Text>
                  <Text variant="D20" style={styles.nameTxt}>
                    {profileData?.flow_name}
                  </Text>
                </Box>
              </Box>
            )}
            {!isProfileCreated ? <CreateProfile next={handleNextView} /> : <CreateTask />}
          </Box>
        </Box>
      ) : (
        <UILayout
          handleSearch={handleSearch}
          searchBarPlaceHolder={getTitles('T-11367')}
          handleRefresh={handleRefresh}
          handleSort={handleSort}
          isMultiSelectFilterOption={true}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          optionsForSorting={defaultValues.farmerSortOptions}
          handleFilter={handleFilter}
          handleAddButton={handleAdd}
          tableItems={tableData}
          showDataTable={!defaultValues.isResponsive}
          showPagination={totalPage > 1}
          pageCount={totalPage}
          currPage={currentPage}
          showNoData={!profiles.length > 0}
          handlePagination={handlePagination}
          message={getErrorMessage('E-10188')}
        />
      )}
    </>
  );
};
