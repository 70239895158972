/*
 *Commonly used keys
 * like success, failure, access token etc.
 *
 * Add or remove variables according to your requirement
 */
export const KEYS = {
  SUCCESS_KEY: 'success',
  FAILURE_KEY: 'failure',
  ERROR_KEY: 'error',
  ACCESS_KEY: 'jwt_access_token',
  ADD_KEY: 'add',
  EDIT_KEY: 'edit',
  LANGUAGE: 'language'
};
