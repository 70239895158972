import { useDispatch, useSelector } from 'react-redux';
import {
  getInventoryCurPage,
  getInventoryMaterialGroup,
  getInventorySuccessMsg,
  getInventoryTotalPage,
  getInwardActivePage,
  getProducts
} from '../../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import {
  getInventoryProductListDetailsRequest,
  getInventoryProductListRequest,
  setInwardActivePage,
  setMaterialGroup,
  setProductDetails,
  updateDetailsPg,
  updateInventoryProductRequest
} from '../../../../services/redux/slices';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { ActionButton, EditButton, UILayout } from '../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../utils';
import { BasicProfile } from '../../../common/utility-components';
import { AddEditInward } from '../add-edit-inward';

const styles = {
  title: {
    cursor: 'pointer',
    overflowWrap: 'anywhere'
  },
  actionColumn: defaultValues.isMobile ? '30%' : defaultValues.isMedium ? '34%' : '38%',
  tableSubCont: (isList) => ({
    width: 'fit-content',
    minHeight: isList ? '50vh' : 'none'
  }),

  col24: defaultValues.isResponsive ? '200px' : '250px'
};

export const Inward = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const productList = useSelector(getProducts);
  const activePage = useSelector(getInwardActivePage);
  const successMsg = useSelector(getInventorySuccessMsg);
  const totalPages = useSelector(getInventoryTotalPage);
  const currentPage = useSelector(getInventoryCurPage);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);

  const { semenStraw } = defaultValues.inventoryMaterialGroupId;
  const { add, edit, view } = defaultValues.actionType;

  const materialGroup = useSelector(getInventoryMaterialGroup);

  useEffect(() => {
    if (['S-10119', 'S-10120'].includes(successMsg)) {
      handleProductList();
    }
  }, [successMsg]);

  const handleSearch = (data) => {
    handleProductList({ search_text: data || null });
  };

  const handleProductList = (data) => {
    let payload = { material_group: materialGroup, ...data };
    dispatch(getInventoryProductListRequest(payload));
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleProductList({
      page: 1,
      filter: JSON.stringify(data),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleProductList({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleProductList({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handleAddButton = () => {
    dispatch(updateDetailsPg(true));
    dispatch(setProductDetails(null));
    dispatch(setInwardActivePage(add));
  };

  const handleStatus = (item) => {
    dispatch(updateInventoryProductRequest({ _id: item._id, is_active: !item.is_active }));
  };

  const handleEdit = (data, currPage) => {
    dispatch(updateDetailsPg(true));
    dispatch(getInventoryProductListDetailsRequest({ _id: data._id }));
    dispatch(setInwardActivePage(currPage));
  };

  const handleChangeMaterialGroupList = (data) => {
    handleProductList({ material_group: data, page: 1 });
    dispatch(setMaterialGroup(data));
  };

  const handlePagination = (_, data) => {
    handleProductList({ page: data });
  };

  const tableData = productList.map((item) => [
    {
      title: getTitles('T-10438'),
      values: item.product_master_id?.product_id,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11437'),
      values: (
        <BasicProfile
          textVariant="D22"
          title={item.product_master_id?.name}
          onTitleClick={() => handleEdit(item, view)}
          isIcon={false}
        />
      ),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11471'),
      values: item.category_enum,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11472'),
      values: `${item?.product_type || ' - '}`,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11421'),
      values: item.store_id ? (
        <BasicProfile
          isIcon={false}
          title={item.store_id?.name}
          subTitle={item.store_id?.store_id}
        />
      ) : (
        ' - '
      ),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11163'),
      values: item.employee_id ? (
        <BasicProfile
          isIcon={false}
          title={item.employee_id?.name}
          subTitle={item.employee_id?.employee_id}
        />
      ) : (
        ' - '
      ),
      columnWidth: styles.col24
    },
    materialGroup !== semenStraw && {
      title: getTitles('T-11494'),
      values: item.brand_name,
      columnWidth: styles.col24
    },
    materialGroup !== semenStraw && {
      title: getTitles('T-10178'),
      values: `${item.color || ' - '}`,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11473'),
      values: item.breed_id,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11474'),
      values: `${item.variety_id || ' - '}`,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11475'),
      values: item.producer_name,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11476'),
      values: item.bull_number,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11477'),
      values: item.bull_name,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11478'),
      values: `${item.bull_type || ''}`,
      columnWidth: styles.col24
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11479'),
      values: getFormatDate(item.year_production),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11449'),
      values: item.vendor_id?.name,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11448'),
      values: item.vendor_id?.vendor_id,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11480'),
      values: `${item.total_unit || ''}`,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11481'),
      values: item.batch_number,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11482'),
      values: getFormatDate(item.bill_date),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11483'),
      values: getFormatDate(item.manufacturing_date),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-10743'),
      values: getFormatDate(item.expiry_date),
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11484'),
      values: `${item.mrp_pack || ''}`,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11485'),
      values: `${item.final_price || ''}`,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-11486'),
      values: `${item.discount || ''}`,
      columnWidth: styles.col24
    },
    {
      title: getTitles('T-10103'),
      columnWidth: styles.col24,
      values: (
        <ActionButton
          status={item.is_active}
          alterTitle={defaultValues.confirmationModel.changeProductStatus.title(item.is_active)}
          alterSubTitle={defaultValues.confirmationModel.subTitle}
          onStatusChange={() => handleStatus(item)}
          isReadOnly={isReadOnly}
        />
      )
    },
    {
      title: getTitles('T-10084'),
      columnWidth: styles.col24,
      values: (
        <EditButton
          status={item.is_active}
          onClick={() => handleEdit(item, edit)}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]);

  if ([add, edit, view].includes(activePage)) {
    return <AddEditInward />;
  }

  return (
    <>
      <UILayout
        handleSearch={handleSearch}
        isMultiSelectFilterOption
        searchBarPlaceHolder={getTitles('T-11469')}
        disableSearch={!materialGroup}
        handleAddButton={materialGroup && handleAddButton}
        handleFilter={materialGroup && handleFilter}
        handleRefresh={materialGroup && handleRefresh}
        handleSort={materialGroup && handleSort}
        optionsForSorting={defaultValues.farmerSortOptions}
        optionsForFiltering={defaultValues.inventoryFilterOption}
        tableItems={tableData}
        showNoData={!productList.length > 0}
        message={materialGroup ? getErrorMessage('E-10028') : getTitles('T-10086')}
        subMessage={!materialGroup && getTitles('T-10085')}
        options={defaultValues.inventoryMaterialGroupOptions}
        inventoryFilterOption
        selectedOption={handleChangeMaterialGroupList}
        selectValue={materialGroup}
        isReadOnly={isReadOnly}
        tableSuperSubCont={styles.tableSubCont(productList.length > 0)}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        showPagination={productList.length > 0 && totalPages > 1}
        clearSearch={filter}
        resSelect={true}
        isSelect={false}
        showSelect={false}
        showTableHeader={true}
      />
    </>
  );
};
