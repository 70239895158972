import { createSelector } from 'reselect';

export const employeeLoading = createSelector([(state) => state.employee.isLoading], (d) => d);
export const employeeSuccessMsg = createSelector(
  [(state) => state.employee.successMessage],
  (d) => d
);
export const employeeErrorMsg = createSelector([(state) => state.employee.errorMessage], (d) => d);
export const getEmployees = createSelector([(state) => state.employee.employeesList], (d) => d);
export const getEmployeeCurrPage = createSelector(
  [(state) => state.employee.currentPage],
  (d) => d
);
export const getEmployeeNextpage = createSelector([(state) => state.employee.nextPage], (d) => d);
export const getEmployeeTotalPage = createSelector([(state) => state.employee.totalPage], (d) => d);
export const getEmployeeParams = createSelector(
  [(state) => state.employee.employeeParams],
  (d) => d
);

export const getEmployeeCurrRender = createSelector(
  [(state) => state.employee.currentRender],
  (d) => d
);

export const getCurrEmployeeData = createSelector(
  [(state) => state.employee.currEmployeeData],
  (d) => d
);

export const getDepartmentsList = createSelector([(state) => state.employee.departments], (d) => d);
export const getDesignationsList = createSelector(
  [(state) => state.employee.designations],
  (d) => d
);
