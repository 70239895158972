import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewPermissions = ({
  employeeData,
  stateList,
  districtList,
  talukaList,
  villageList,
  handleTitleClick,
  currEmp,
  index
}) => {
  const handleSubTitleClick = (item, idx) => {
    handleTitleClick(item, idx);
  };

  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.length > 0 &&
        employeeData.map((item, idx) => (
          <Box key={idx}>
            <CollapseDropView
              title={`${getTitles('T-10455')} ${idx + 1}`}
              handleSubTitleClick={() => handleSubTitleClick(item, idx)}
              open={index === idx}
            />
            {currEmp && idx === index && (
              <>
                <ViewData
                  title={
                    item.permission_id &&
                    defaultValues.employeePermissions.find(
                      (elem) => elem.value === item.permission_id
                    ).title
                  }
                  subTitle={item.read_only ? getTitles('T-10080') : getTitles('T-10473')}
                />

                {[
                  defaultValues.employeeRol.farmerManager,
                  defaultValues.employeeRol.societyManager
                ].includes(item.permission_id) && (
                  <ViewData
                    title={getTitles('T-11172')}
                    subTitle={
                      item.has_approve_permission ? getTitles('T-10705') : getTitles('T-10706')
                    }
                  />
                )}

                <ViewData
                  title={getTitles('T-10456')}
                  isSubTitle={false}
                  containerStyle={{ mt: 1 }}
                />
                <ViewData
                  title={getTitles('T-10070')}
                  subTitle={stateList
                    .filter((el) => currEmp.assign_location?.state_id?.includes(el._id))
                    ?.map((ite) => ite.name)
                    .join(', ')}
                />
                <ViewData
                  title={getTitles('T-10069')}
                  subTitle={districtList
                    .filter((el) => currEmp.assign_location?.district_id?.includes(el._id))
                    ?.map((ite) => ite.name)
                    .join(', ')}
                />
                <ViewData
                  title={getTitles('T-10071')}
                  subTitle={talukaList
                    .filter((el) => currEmp.assign_location?.taluka_id?.includes(el._id))
                    ?.map((ite) => ite.name)
                    .join(', ')}
                />
                <ViewData
                  title={getTitles('T-10068')}
                  subTitle={villageList
                    .filter((el) => currEmp.assign_location?.village_id?.includes(el._id))
                    ?.map((ite) => ite.name)
                    .join(', ')}
                />
              </>
            )}
          </Box>
        ))}
    </Box>
  );
};
