import { Box } from '@mui/material';
import { ActionButton, Screen, Text, UILayout } from '../../ui-kits';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDepartmentRequest,
  getDesignationRequest,
  getEmployeeRequest,
  resetEmployeeMessages,
  updateCurrEmployeeData,
  updateDetailsPg,
  updateEmployeeCurrentRender,
  updateEmployeeParams,
  updateEmployeeRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import {
  checkReadOnly,
  convertStringsToBoolean,
  getTitles,
  resetMessages
} from '../../utils/commonFunction';
import { BasicProfile } from '../../components';
import {
  employeeErrorMsg,
  employeeLoading,
  employeeSuccessMsg,
  getDepartmentsList,
  getDesignationsList,
  getDetailsPg,
  getEmployeeCurrPage,
  getEmployeeCurrRender,
  getEmployeeParams,
  getEmployeeTotalPage,
  getEmployees,
  getUserData
} from '../../services/redux/selectors';
import { AddEditEmployee } from './add-edit-employee';
import { processLoading } from '../../services/redux/selectors/manageProccess';

export const ManageEmployee = () => {
  const dispatch = useDispatch();
  const employees = useSelector(getEmployees);
  const isLoading = useSelector(employeeLoading);
  const processIsLoading = useSelector(processLoading);

  const errorMsg = useSelector(employeeErrorMsg);
  const successMsg = useSelector(employeeSuccessMsg);
  const totalPages = useSelector(getEmployeeTotalPage);
  const currentPage = useSelector(getEmployeeCurrPage);
  const employeeParams = useSelector(getEmployeeParams);
  const currRender = useSelector(getEmployeeCurrRender);
  const userData = useSelector(getUserData);
  const detailsPg = useSelector(getDetailsPg);
  const departments = useSelector(getDepartmentsList);
  const designations = useSelector(getDesignationsList);

  const [isReadOnly, setIsReadOnly] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);

  const { add, edit } = defaultValues.actionType;

  const styles = {
    container: { width: '100%' }
  };

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.employeeManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10046', 'S-10047'].includes(successMsg)) handleEmployee();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetEmployeeMessages()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    dispatch(getDepartmentRequest({ master_id: defaultValues.masterListType.department }));
    dispatch(getDesignationRequest({ master_id: defaultValues.masterListType.designation }));
    handleEmployee();
  }, []);

  useEffect(() => {
    const options = [...defaultValues.employeeFilterOption];
    const des = {
      filterType: getTitles('T-10050'),
      value: 'designation',
      option: designations.map((item) => ({ name: item.name, value: item._id }))
    };

    const dep = {
      filterType: getTitles('T-10310'),
      value: 'department',
      option: departments.map((item) => ({ name: item.name, value: item._id }))
    };

    setFilterOptions([...options, dep, des]);
  }, [departments, designations]);

  const handleEmployee = (data) => {
    const payload = { ...employeeParams, ...data };
    dispatch(updateEmployeeParams(payload));
    dispatch(getEmployeeRequest(payload));
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleEmployee({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleEmployee({ page: 1, sort: JSON.stringify(data), filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleEmployee({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handleStatus = (id, status) => {
    dispatch(updateEmployeeRequest({ _id: id, platform_access: !status }));
  };

  const getName = (item, arr) => {
    const name = arr && arr.length > 0 && arr.find((ele) => ele._id === item);
    if (name) {
      return name.name;
    } else {
      return null;
    }
  };

  const tableData =
    employees &&
    employees.length > 0 &&
    employees.map((item) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-10438'),
            columnWidth: '10%',
            values: `${item.employee_id}`
          },
          {
            title: getTitles('T-10081'),
            values: (
              <BasicProfile
                title={`${item.first_name} ${item.middle_name} ${item.last_name}`}
                url={item.upload_document?.id_photo}
                textVariant="D22"
                onTitleClick={() => handleEdit(item)}
              />
            )
          },
          {
            title: getTitles('T-10440'),
            values: item.phone_number
          },
          {
            title: getTitles('T-10324'),
            columnWidth: '30%',

            values: (
              <BasicProfile
                title={
                  item.employment_details &&
                  item.employment_details.designation_id &&
                  getName(item.employment_details.designation_id, designations)
                }
                isIcon={false}
                subTitle={
                  item.employment_details &&
                  item.employment_details.department_id &&
                  getName(item.employment_details.department_id, departments)
                }
              />
            )
          },
          {
            title: getTitles('T-10453'),
            columnWidth: '18%',

            values: (
              <ActionButton
                status={item.platform_access}
                onStatusChange={() => handleStatus(item._id, item.platform_access)}
                alterTitle={defaultValues.confirmationModel.changeEmployeeStatus.title(
                  item.platform_access
                )}
                alterSubTitle={defaultValues.confirmationModel.changeEmployeeStatus.subtitles}
                isReadOnly={isReadOnly}
              />
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={`${item.first_name} ${item.middle_name} ${item.last_name}`}
              url={item.upload_document?.id_photo}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10438'),
              action: item.employee_id
            },
            {
              subTitle: getTitles('T-10440'),
              action: <Text variant="D20">{item.phone_number}</Text>
            },
            {
              subTitle: getTitles('T-10050'),
              action: (
                <Text variant="D20">
                  {item.employment_details &&
                    typeof item.employment_details.designation_id === 'string' &&
                    designations.length > 0 &&
                    designations.find((ele) => ele._id === item.employment_details.designation_id)
                      .name}
                </Text>
              )
            },
            {
              subTitle: getTitles('T-10320'),
              action: (
                <Text variant="D20">
                  {item.employment_details &&
                    typeof item.employment_details.department_id === 'string' &&
                    departments.length > 0 &&
                    departments.find((ele) => ele._id === item.employment_details.department_id)
                      .name}
                </Text>
              )
            },
            {
              subTitle: getTitles('T-10161'),
              action: (
                <ActionButton
                  status={item.platform_access}
                  onStatusChange={() => handleStatus(item._id, item.platform_access)}
                  alterTitle={defaultValues.confirmationModel.changeEmployeeStatus.title(
                    item.platform_access
                  )}
                  alterSubTitle={defaultValues.confirmationModel.changeEmployeeStatus.subtitles}
                  isReadOnly={isReadOnly}
                />
              )
            }
          ]
        };
      }
    });

  const handleEdit = (data) => {
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrEmployeeData(data));
    dispatch(updateEmployeeCurrentRender(edit));
  };

  const handleSearch = (data) => {
    handleEmployee({ search_text: data, page: 1 });
  };

  const handleBackButton = () => {
    dispatch(updateEmployeeCurrentRender(null));
  };

  const handlePagination = (e, data) => {
    handleEmployee({ page: data });
  };

  if ([add, edit].includes(currRender)) {
    return (
      <Screen
        showSideBar={true}
        currentPage={getTitles('T-10323')}
        isLoading={isLoading || processIsLoading}>
        {defaultValues.isResponsive && (
          <Text variant="boldH32Sec" style={{ mt: 1 }}>
            {currRender === add ? getTitles('T-10326') : getTitles('T-10327')}
          </Text>
        )}
        <AddEditEmployee
          currRender={currRender}
          isReadOnly={isReadOnly}
          handleBackButton={handleBackButton}
          successMsg={successMsg}
        />
      </Screen>
    );
  }

  return (
    <Screen
      showSideBar={true}
      currentPage={getTitles('T-10323')}
      isLoading={isLoading || processIsLoading}>
      <Box sx={styles.container}>
        <UILayout
          isMultiSelectFilterOption={true}
          handleAddButton={() => {
            dispatch(updateDetailsPg(true));
            dispatch(updateCurrEmployeeData(null));
            dispatch(updateEmployeeCurrentRender(add));
          }}
          currPageName={getTitles('T-10323')}
          handleSearch={handleSearch}
          showNoData={!employees.length > 0}
          currPage={currentPage}
          pageCount={totalPages}
          handlePagination={handlePagination}
          tableItems={tableData}
          showPagination={employees.length > 0 && totalPages > 1}
          searchBarPlaceHolder={getTitles('T-11005')}
          showDataTable={!defaultValues.isResponsive}
          message={getErrorMessage('E-10077')}
          isReadOnly={isReadOnly}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          handleRefresh={handleRefresh}
          handleSort={handleSort}
          handleFilter={handleFilter}
          optionsForFiltering={filterOptions}
          clearSearch={filter}
          onTitleClick={handleEdit}
        />
      </Box>
    </Screen>
  );
};
