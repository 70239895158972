import { Box } from '@mui/material';
import { ModalButtons } from '../..';
import { UISelect, UITextInputWithTitle } from '../../../ui-kits';
import { cattleProfileSchema, getTitles } from '../../../utils';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { color } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProfilingRequest,
  setProfileActionType,
  updateProfileDetailsRequest
} from '../../../services/redux/slices';
import {
  getProfilingActionType,
  getProfilingData
} from '../../../services/redux/selectors/manageProccess';
import { useEffect, useState } from 'react';
const isRes = defaultValues.isResponsive;

export const CreateProfile = ({ next }) => {
  const dispatch = useDispatch();
  const { view, edit, add } = defaultValues.actionType;

  const profileData = useSelector(getProfilingData);
  const actionType = useSelector(getProfilingActionType);

  const { values, errors, touched, handleBlur, setFieldValue, dirty, isValid } = useFormik({
    initialValues: {
      step: 1,
      flow_type: '',
      flow_name: '',
      description: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: cattleProfileSchema
  });

  const [isCreated, setCreated] = useState(false);

  useEffect(() => {
    if (profileData) {
      setFieldValue('flow_type', profileData?.flow_type?.id || '');
      setFieldValue('flow_name', profileData?.flow_name);
      setFieldValue('description', profileData?.description);
    }
    if (actionType === add && profileData?.flow_type?.id && profileData?.flow_name) {
      setCreated(true);
    }
  }, [profileData, actionType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleNext = () => {
    if (actionType === view || (actionType === add && isCreated)) {
      next(true);
    } else if (actionType === edit) {
      delete values.step;
      delete values.flow_type;
      dispatch(updateProfileDetailsRequest({ _id: profileData?._id, ...values }));
    } else {
      dispatch(addProfilingRequest(values));
    }
  };

  const styles = {
    container: {
      width: isRes ? '100%' : '65%',
      backgroundColor: color.primaryBackground
    }
  };

  return (
    <Box sx={styles.container}>
      <UISelect
        title={getTitles('T-11156')}
        options={defaultValues.actionFlowOptions}
        placeholder={getTitles('T-11157')}
        name="flow_type"
        value={values.flow_type}
        onBlur={handleBlur}
        error={touched.flow_type && errors.flow_type}
        onChange={handleChange}
        disabled={profileData?.flow_type?.id}
        required={true}
      />
      <UITextInputWithTitle
        title={getTitles('T-11158')}
        placeHolder={getTitles('T-11159')}
        name="flow_name"
        value={values.flow_name}
        onBlur={handleBlur}
        error={touched.flow_name && errors.flow_name}
        disabled={actionType !== edit && profileData?.flow_name}
        onChange={handleChange}
        required={true}
      />
      <UITextInputWithTitle
        title={getTitles('T-10109')}
        placeHolder={getTitles('T-10096')}
        rows={3}
        name="description"
        value={values.description}
        onBlur={handleBlur}
        disabled={actionType !== edit && profileData?.flow_name}
        onChange={handleChange}
      />
      <ModalButtons
        primaryBtnTitle={getTitles(actionType === edit ? 'T-10046' : 'T-10804')}
        onPrimaryBtnClick={handleNext}
        secondaryBtnTitle={getTitles(actionType === view ? 'T-10340' : 'T-10045')}
        onSecondaryBtnClick={() => {
          actionType === edit ? dispatch(setProfileActionType(view)) : next(false);
        }}
        btnPositions={isRes ? 'center' : 'start'}
        disabled={actionType === view ? false : (!dirty || !isValid) && !isCreated}
      />
    </Box>
  );
};
