import { createSelector } from 'reselect';

export const vetLoading = createSelector([(state) => state.vet.isLoading], (d) => d);
export const vetSuccessMsg = createSelector([(state) => state.vet.successMessage], (d) => d);
export const vetErrorMsg = createSelector([(state) => state.vet.errorMessage], (d) => d);
export const getvets = createSelector([(state) => state.vet.vetList], (d) => d);
export const getvetCurrPage = createSelector([(state) => state.vet.currentPage], (d) => d);
export const getvetNextpage = createSelector([(state) => state.vet.nextPage], (d) => d);
export const getvetTotalPage = createSelector([(state) => state.vet.totalPage], (d) => d);
export const getvetParams = createSelector([(state) => state.vet.vetParams], (d) => d);
export const getvetCurrRender = createSelector([(state) => state.vet.currRender], (d) => d);
export const getUnassignCurrPage = createSelector(
  [(state) => state.vet.unassCurrentPage],
  (d) => d
);
export const getUnassignNextpage = createSelector([(state) => state.vet.unassNextPage], (d) => d);
export const getUnassignTotalPage = createSelector([(state) => state.vet.unassTotalPage], (d) => d);
export const getUnassignAi = createSelector([(state) => state.vet.unassignedAiworker], (d) => d);
export const getCurrVet = createSelector([(state) => state.vet.currVet], (d) => d);
