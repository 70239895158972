import { Box, Divider } from '@mui/material';
import { getTitles } from '../../../../utils';
import { footerStyles } from '../styles';
import { Email, Phone } from '@mui/icons-material';
import { Text } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { globalStyles } from '../../../../theme';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/icons/logo.png';
import { styles } from './styles';

export const EcommerceFooter = () => {
  const res = defaultValues.isResponsive;
  return (
    <>
      <Box sx={styles.subContainer}>
        <img style={{ ...footerStyles.logo, ...styles.logo }} alt="logo" src={logo} />

        <Box sx={styles.connectCont}>
          {res && (
            <Box sx={{ ...footerStyles.laberRight, ...styles.labelRight }}>
              <Text
                variant="D16Open"
                style={{
                  ...footerStyles.labelText,
                  ...footerStyles.copyRight
                }}>
                {getTitles('T-10008')}
              </Text>
            </Box>
          )}
          <Text
            variant="D22"
            style={{ ...footerStyles.subHeading, ...styles.subHeading, mb: res ? 0 : 2 }}>
            {getTitles('T-10031').toUpperCase()}
          </Text>
          <Box sx={styles.socialCont}>
            {defaultValues.footer.social.map((item, idx) => (
              <Link to={item.url} key={idx}>
                <img src={item.icon} style={styles.socialIcon} />
              </Link>
            ))}
          </Box>
        </Box>
        {!res && (
          <Box>
            <Text variant="D22Open" style={{ ...footerStyles.subHeading, mb: 2 }}>
              {getTitles('T-10007')}
            </Text>
            <Box sx={footerStyles.emailBox}>
              <Phone style={footerStyles.mailIcon} />
              <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
                {defaultValues.footer.phone}
              </Text>
            </Box>

            <Box sx={footerStyles.emailBox}>
              <Email style={footerStyles.mailIcon} />
              <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
                {defaultValues.footer.email}
              </Text>
            </Box>
          </Box>
        )}

        {!res && (
          <Box sx={{ mr: 7 }}>
            <Text variant="D22Open" style={{ ...footerStyles.subHeading }}>
              {getTitles('T-10034')}
            </Text>

            {defaultValues.footer.eComSupport.map((item, index) => (
              <Link to={item.url} key={index} style={{ textDecoration: 'none' }}>
                <Text variant="D18Open" style={{ ...footerStyles.linkTexts }}>
                  {item.title}
                </Text>
              </Link>
            ))}
          </Box>
        )}
      </Box>

      <Divider sx={{ ...globalStyles.divider, mt: 2 }} />

      <Box sx={footerStyles.labelContainer(defaultValues.isResponsive)}>
        {!res && (
          <Box sx={footerStyles.laberRight}>
            <Text
              variant="D16Open"
              style={{
                ...footerStyles.labelText,
                ...footerStyles.copyRight
              }}>
              {getTitles('T-10008')}
            </Text>
          </Box>
        )}

        {res && (
          <Box sx={styles.supportCont}>
            <Box>
              <Text variant="D22Open" style={{ ...footerStyles.subHeading, ...styles.subHeading }}>
                {getTitles('T-10034').toUpperCase()}
              </Text>

              {defaultValues.footer.eComSupport.map((item, index) => (
                <Link to={item.url} key={index} style={{ textDecoration: 'none' }}>
                  <Text
                    variant="D16Open"
                    style={{ ...footerStyles.linkTexts, ...styles.linkTexts }}>
                    {item.title}
                  </Text>
                </Link>
              ))}
            </Box>

            <Box>
              <Text variant="D22Open" style={{ ...footerStyles.subHeading, ...styles.subHeading }}>
                {getTitles('T-10007').toUpperCase()}
              </Text>
              <Box sx={footerStyles.emailBox}>
                <Phone style={footerStyles.mailIcon} />
                <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
                  {defaultValues.footer.phone}
                </Text>
              </Box>

              <Box sx={footerStyles.emailBox}>
                <Email style={footerStyles.mailIcon} />
                <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
                  {defaultValues.footer.email}
                </Text>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={footerStyles.labelRightSec}>
          <Box sx={footerStyles.emailBox}>
            <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
              {getTitles('T-11605')}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};
