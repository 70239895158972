import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkReadOnly, convertStringsToBoolean, getTitles, resetMessages } from '../../utils';
import { toast } from 'react-toastify';
import { ActionButton, Screen, UICheckBox, UILayout } from '../../ui-kits';
import { Box } from '@mui/material';
import { BasicProfile } from '../../components';
import {
  getAIworkerCurrPage,
  getAIworkerCurrRender,
  getAIworkerParams,
  getAIworkerTotalPage,
  getAIworkers,
  getCurrAIWorker,
  getDetailsPg,
  getUserData,
  getAiWorkerLoading,
  getAiWorkerErrorMsg,
  getAiWorkerSuccessMsg,
  getVetSuggetions,
  getUnassignedCattles,
  getUnassCattleCurrPage,
  getUnassCattleTotalPage,
  getUnassVetCurrPage,
  getUnassVetTotalPage,
  getUnassignedLabs,
  getUnassLabsCurrPage,
  getUnassLabsTotalPage,
  getGopalanChecked
} from '../../services/redux/selectors';
import {
  addCattleToAiRequest,
  addVetToAiRequest,
  getAIWorkerRequest,
  getunAssignedVetRequest,
  getUnassignedCattlesRequest,
  resetAIWorkerMessages,
  updateAIWorkerParams,
  updateAIWorkerStatusRequest,
  updateAIworkerCurrRender,
  updateAssignedCattleRequest,
  updateAssignedVetRequest,
  updateCurrAIworker,
  updateDetailsPg,
  getUnassignedLabsRequest,
  assignLabToAiworkerRequest,
  setAiworkerChecked
} from '../../services/redux/slices';
import assignIcon from '../../assets/icons/share_windows.png';
import closeIcon from '../../assets/icons/close_small.png';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const ManageAIworker = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getAiWorkerLoading);
  const aiWorkers = useSelector(getAIworkers);
  const errorMsg = useSelector(getAiWorkerErrorMsg);
  const successMsg = useSelector(getAiWorkerSuccessMsg);
  const totalPages = useSelector(getAIworkerTotalPage);
  const currentPage = useSelector(getAIworkerCurrPage);
  const aiWorkerParams = useSelector(getAIworkerParams);
  const currRender = useSelector(getAIworkerCurrRender);
  const currGopalan = useSelector(getCurrAIWorker);
  const vetList = useSelector(getVetSuggetions);
  const unassignedCattles = useSelector(getUnassignedCattles);
  const detailsPg = useSelector(getDetailsPg);
  const userData = useSelector(getUserData);
  const unAssCattleCurrPage = useSelector(getUnassCattleCurrPage);
  const unAssCattleTotalPage = useSelector(getUnassCattleTotalPage);
  const unAssVetCurrPage = useSelector(getUnassVetCurrPage);
  const unAssVetTotalPage = useSelector(getUnassVetTotalPage);
  const unassignedLabs = useSelector(getUnassignedLabs);
  const unAssLabsCurrPage = useSelector(getUnassLabsCurrPage);
  const unAssLabsTotalPage = useSelector(getUnassLabsTotalPage);
  const checked = useSelector(getGopalanChecked);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [hasVet, setHasVet] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [isRemove, setIsRemove] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currAIWorker, setCurrAIworker] = useState(null);

  const {
    viewAiScr,
    assVetScr,
    unassVetScr,
    assCattleScr,
    unAssCatScr,
    assignedLabScr,
    unassignedLabScr
  } = defaultValues.aiworkerScreen;

  const { labs, cattles } = defaultValues.appLogicalTexts;

  const styles = {
    container: { width: '100%' },

    assign: (isRead) => ({
      padding: res ? '0px' : '5px 25px',
      boxSizing: 'border-box',
      height: res ? '15px' : '25px',
      cursor: 'pointer',
      opacity: isRead ? '0.5' : '1'
    }),

    assignViewScr: (isRead) => ({
      padding: res ? '0px' : md ? '5px 40px' : '5px 57px',
      boxSizing: 'border-box',
      height: res ? '15px' : '25px',
      cursor: 'pointer',
      opacity: isRead ? '0.5' : '1'
    }),
    removeCont: {
      width: '100%',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    addBtnStyle: {
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      width: 'fit-content',
      '& .MuiButton-startIcon': {
        marginRight: '0px',
        marginLeft: '0px'
      }
    }
  };

  useEffect(() => {
    if (!detailsPg) {
      dispatch(updateAIworkerCurrRender(getPrevPageNo()));
    }
  }, [detailsPg]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.paravetManager));
  }, [userData]);

  useEffect(() => {
    setCurrAIworker(currGopalan);
  }, [currGopalan]);

  useEffect(() => {
    if (successMsg) {
      toast.success(
        getSuccessMessage(
          isRemove === cattles ? 'S-10073' : isRemove === labs ? 'S-10094' : successMsg
        )
      );
      [
        'S-10049',
        'S-10038',
        'S-10036',
        'S-10051',
        'S-10037',
        'S-10068',
        'S-10092',
        'S-10093'
      ].includes(successMsg) && handleAIWorker();

      if (['S-10092', 'S-10093'].includes(successMsg) && isEmpty === labs) {
        handleGetLabs({ page: 1 });
        dispatch(updateAIworkerCurrRender(unassignedLabScr));
      }
      if (['S-10068'].includes(successMsg) && isEmpty === cattles) {
        handleCattles({ page: 1 });
        dispatch(updateAIworkerCurrRender(unAssCatScr));
      }
      setIsRemove(false);
      setIsEmpty(false);
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetAIWorkerMessages()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    handleAIWorker();
  }, []);

  useEffect(() => {
    if (currAIWorker) {
      const temp = [...aiWorkers];
      const currAi = temp.find((item) => item.user_id === currAIWorker.user_id);
      dispatch(updateCurrAIworker(currAi));
      [assVetScr, unassVetScr].includes(currRender) &&
        currAi &&
        currAi.vet &&
        dispatch(setAiworkerChecked(currAi.vet.user_id));
      [assCattleScr, unAssCatScr].includes(currRender) &&
        currAi &&
        currAi.cattles &&
        dispatch(
          setAiworkerChecked(
            currAi.cattles.length > 0 ? currAi.cattles.map((item) => item._id) : []
          )
        );
      [assignedLabScr, unassignedLabScr].includes(currRender) &&
        currAi &&
        currAi.labs &&
        dispatch(
          setAiworkerChecked(currAi.labs.length > 0 ? currAi.labs.map((item) => item._id) : [])
        );
      setBtnDisabled(true);
    }
  }, [aiWorkers]);

  const getPrevPageNo = () => {
    if (
      (currRender === unassVetScr && !currAIWorker.vet) ||
      (currRender === unAssCatScr && !currAIWorker.cattles.length > 0) ||
      (currRender === unassignedLabScr && !currAIWorker.labs.length > 0)
    ) {
      setBtnDisabled(true);
      return viewAiScr;
    }
    if ([assVetScr, assCattleScr, assignedLabScr].includes(currRender)) {
      return viewAiScr;
    }
    if (currRender === unassVetScr) {
      dispatch(updateDetailsPg(true));
      return assVetScr;
    }
    if (currRender === unAssCatScr) {
      dispatch(updateDetailsPg(true));
      return assCattleScr;
    }
    if (currRender === unassignedLabScr) {
      dispatch(updateDetailsPg(true));
      return assignedLabScr;
    }
    setBtnDisabled(true);
    return viewAiScr;
  };

  const handleAIWorker = (data) => {
    const payload = { ...aiWorkerParams, ...data };
    dispatch(updateAIWorkerParams(payload));
    dispatch(getAIWorkerRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(updateAIWorkerStatusRequest({ user_id: item.user_id, is_active: !item.is_active }));
  };

  const handleAssignNewClick = () => {
    if (currRender === assVetScr) {
      handleVet({ page: 1 });
      dispatch(updateAIworkerCurrRender(unassVetScr));
    } else if (currRender === assCattleScr) {
      handleCattles({ page: 1 });
      dispatch(updateAIworkerCurrRender(unAssCatScr));
    } else if (currRender === assignedLabScr) {
      handleGetLabs({ page: 1 });
      dispatch(updateAIworkerCurrRender(unassignedLabScr));
    }
    setHasVet(true);
  };

  const handleChecked = (e, item) => {
    let check = e.target.checked;
    let newCheck;
    if (check) {
      [unAssCatScr, unassignedLabScr].includes(currRender) &&
        dispatch(setAiworkerChecked(item.user_id));
      if ([unAssCatScr, unassignedLabScr].includes(currRender)) {
        newCheck = [...checked, item._id];
        dispatch(setAiworkerChecked(newCheck));
      }
    } else {
      [unAssCatScr, unassignedLabScr].includes(currRender) && dispatch(setAiworkerChecked(''));
      if ([unAssCatScr, unassignedLabScr].includes(currRender)) {
        newCheck = checked.filter((ele) => ele !== item._id);
        dispatch(setAiworkerChecked(newCheck));
      }
    }

    if (
      (currRender === unAssCatScr && newCheck && newCheck.length === currAIWorker.cattles.length) ||
      (currRender === unassignedLabScr && newCheck && newCheck.length === currAIWorker.labs.length)
    ) {
      setBtnDisabled(true);
      return;
    }

    setBtnDisabled(false);
  };

  const handleAssignClick = (data) => {
    dispatch(updateDetailsPg(true));
    setIsRemove(false);
    setIsEmpty(false);
    dispatch(updateCurrAIworker(data));
    dispatch(setAiworkerChecked(data.vet ? data.vet.user_id : null));
    data && data.vet ? setHasVet(true) : setHasVet(false);
    if (data && data.vet) {
      dispatch(updateAIworkerCurrRender(assVetScr));
    } else {
      handleVet({ page: 1 });
      dispatch(updateAIworkerCurrRender(unassVetScr));
    }
  };

  const handleAssignCattleClick = (data) => {
    setIsRemove(false);
    setIsEmpty(false);
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrAIworker(data));
    dispatch(
      setAiworkerChecked(
        data.cattles && data.cattles.length > 0 ? data.cattles.map((ele) => ele._id) : []
      )
    );
    data && data.cattles.length > 0 ? setHasVet(true) : setHasVet(false);
    if (data && data.cattles.length > 0) {
      dispatch(updateAIworkerCurrRender(assCattleScr));
    } else {
      handleCattles({ page: 1, ai_workers_user_id: data.user_id });
      dispatch(updateAIworkerCurrRender(unAssCatScr));
    }
  };

  const handleAssignLabClick = (data) => {
    setIsRemove(false);
    setIsEmpty(false);
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrAIworker(data));
    dispatch(
      setAiworkerChecked(data.labs && data.labs.length > 0 ? data.labs.map((ele) => ele._id) : [])
    );
    data && data.labs && data.labs.length > 0 ? setHasVet(true) : setHasVet(false);
    if (data && data.labs && data.labs.length > 0) {
      dispatch(updateAIworkerCurrRender(assignedLabScr));
    } else {
      handleGetLabs({ page: 1, user_id: data.user_id });
      dispatch(updateAIworkerCurrRender(unassignedLabScr));
    }
  };

  const handleVet = (data) => {
    const payload = { page: 1, user_id: currAIWorker && currAIWorker.user_id, ...data };
    dispatch(getunAssignedVetRequest(payload));
  };

  const handleCattles = (data) => {
    const payload = {
      page: 1,
      ai_workers_user_id: currAIWorker && currAIWorker.user_id,
      ...data
    };
    dispatch(getUnassignedCattlesRequest(payload));
  };

  const handleGetLabs = (data) => {
    const payload = {
      page: 1,
      user_id: currAIWorker && currAIWorker.user_id,
      permission_id: defaultValues.employeeRol.aiWorker,
      ...data
    };
    dispatch(getUnassignedLabsRequest(payload));
  };

  const handleRemoveAssignedItem = (data) => {
    if (currRender === assCattleScr) {
      setIsRemove(cattles);
      let cattlesList = currAIWorker.cattles
        .map((item) => item._id)
        .filter((elem) => elem !== data._id);
      dispatch(
        updateAssignedCattleRequest({
          registered_cattle_id: cattlesList,
          ai_workers_user_id: currAIWorker.user_id
        })
      );
      if (cattlesList.length === 0) {
        setIsEmpty(cattles);
      }
    }

    if (currRender === assignedLabScr) {
      setIsRemove(labs);
      let labsList = currAIWorker.labs.map((item) => item._id).filter((elem) => elem !== data._id);
      dispatch(
        assignLabToAiworkerRequest({
          laboratory_id: labsList,
          user_id: currAIWorker.user_id
        })
      );
      if (labsList.length === 0) {
        setIsEmpty(labs);
      }
    }
    if (currRender === assVetScr) {
      handleAssignNewClick();
    }
  };

  const searchWorkers = (
    workers,
    searchTerm,
    searchItem1,
    searchItem2,
    searchItem3,
    searchItem4,
    searchItem5
  ) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      workers &&
      workers.filter(
        (item) =>
          (item[searchItem1] && item[searchItem1].toLowerCase().includes(lowerCaseTerm)) ||
          (item[searchItem2] && item[searchItem2].toLowerCase().includes(lowerCaseTerm)) ||
          (item[searchItem3] && item[searchItem3].toLowerCase().includes(lowerCaseTerm)) ||
          (item[searchItem4] &&
            item[searchItem4][searchItem5].toLowerCase().includes(lowerCaseTerm))
      )
    );
  };

  const handleSearch = (data) => {
    if (currRender === viewAiScr) {
      handleAIWorker({ page: 1, search_text: data });
    }
    if (currRender === unassVetScr) {
      handleVet({ page: 1, search_text: data });
    }
    if (currRender === unAssCatScr) {
      handleCattles({ page: 1, search_text: data });
    }
    if (currRender === unassignedLabScr) {
      handleGetLabs({ page: 1, search_text: data });
    }
    if (currRender === assignedLabScr) {
      if (data.length === 0) {
        setCurrAIworker(currGopalan);
      } else {
        const searchedWorkers = searchWorkers(
          currGopalan.labs,
          data,
          'laboratory_id',
          'laboratory_name',
          'primary_mobile_number'
        );
        setCurrAIworker({ ...currAIWorker, labs: searchedWorkers });
      }
    }
    if (currRender === assCattleScr) {
      if (data.length === 0) {
        setCurrAIworker(currGopalan);
      } else {
        const searchedWorkers = searchWorkers(
          currGopalan.cattles,
          data,
          'cattle_id',
          'cattle_name',
          null,
          'farmer',
          'full_name'
        );
        setCurrAIworker({ ...currAIWorker, cattles: searchedWorkers });
      }
    }
  };

  const handlePagination = (e, data) => {
    currRender === viewAiScr
      ? handleAIWorker({ page: data })
      : currRender === unassVetScr
        ? handleVet({ page: data })
        : currRender === unAssCatScr
          ? handleCattles({ page: data })
          : currRender === unassignedLabScr && handleGetLabs({ page: data });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleAIWorker({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    setFilter([]);
    handleAIWorker({ page: 1, sort: JSON.stringify(data), filter: null });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleAIWorker({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handleSubmitAssign = () => {
    if (hasVet) {
      if (currRender === unassVetScr) {
        dispatch(
          updateAssignedVetRequest({
            vet_user_id: checked,
            ai_workers_user_id: currAIWorker.user_id
          })
        );
      }
      if (currRender === unAssCatScr) {
        dispatch(
          updateAssignedCattleRequest({
            registered_cattle_id: checked,
            ai_workers_user_id: currAIWorker.user_id
          })
        );
      }
      if (currRender === unassignedLabScr) {
        dispatch(
          assignLabToAiworkerRequest({
            laboratory_id: checked,
            user_id: currAIWorker.user_id
          })
        );
      }
    } else {
      if (currRender === unassVetScr) {
        dispatch(
          addVetToAiRequest({ vet_user_id: checked, ai_workers_user_id: currAIWorker.user_id })
        );
      }
      if (currRender === unAssCatScr) {
        dispatch(
          addCattleToAiRequest({
            registered_cattle_id: checked,
            ai_workers_user_id: currAIWorker.user_id
          })
        );
      }
      if (currRender === unassignedLabScr) {
        dispatch(
          assignLabToAiworkerRequest({
            laboratory_id: checked,
            user_id: currAIWorker.user_id
          })
        );
      }
    }

    dispatch(
      updateAIworkerCurrRender(
        currRender === unassVetScr
          ? assVetScr
          : currRender === unAssCatScr
            ? assCattleScr
            : currRender === unassignedLabScr && assignedLabScr
      )
    );
  };

  const getAssignedTable = (item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          columnWidth: '18%',
          values:
            currRender === assVetScr
              ? item.employee_id
              : currRender === assignedLabScr
                ? item.laboratory_id
                : item.cattle_id
        },
        {
          title: currRender === assignedLabScr ? getTitles('T-11073') : getTitles('T-10081'),
          values: (
            <BasicProfile
              title={
                currRender === assVetScr
                  ? item.name
                  : currRender === assignedLabScr
                    ? item.laboratory_name
                    : item.cattle_name
              }
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title:
            currRender === assVetScr
              ? getTitles('T-10440')
              : currRender === assignedLabScr
                ? getTitles('T-11169')
                : getTitles('T-10802'),
          values:
            currRender === assVetScr
              ? item.phone_number
              : currRender === assignedLabScr
                ? item.primary_mobile_number
                : item.farmer && item.farmer.full_name
        },
        {
          title: getTitles('T-10481'),
          columnWidth: md ? '12%' : '10%',
          values: (
            <img
              src={closeIcon}
              style={styles.assign(isReadOnly)}
              onClick={() => !isReadOnly && handleRemoveAssignedItem(item)}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Box sx={styles.removeCont}>
            <BasicProfile
              title={
                currRender === assVetScr
                  ? item.name
                  : currRender === assignedLabScr
                    ? item.laboratory_name
                    : item.cattle_name
              }
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
            <img
              src={closeIcon}
              style={styles.assign(isReadOnly)}
              onClick={() => !isReadOnly && handleRemoveAssignedItem(item)}
            />
          </Box>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action:
              currRender === assVetScr
                ? item.employee_id
                : currRender === assignedLabScr
                  ? item.laboratory_id
                  : item.cattle_id
          },
          {
            subTitle:
              currRender === assVetScr
                ? getTitles('T-10440')
                : currRender === assignedLabScr
                  ? getTitles('T-11169')
                  : getTitles('T-10802'),
            action:
              currRender === assVetScr
                ? item.phone_number
                : currRender === assignedLabScr
                  ? item.primary_mobile_number
                  : item.farmer && item.farmer.full_name
          }
        ]
      };
    }
  };

  const getUnassignedTable = (item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10429'),
          values: (
            <UICheckBox
              checked={
                currRender === unassVetScr
                  ? checked === item.user_id
                  : [unAssCatScr, unassignedLabScr].includes(currRender) &&
                    checked.includes(item._id)
              }
              onChange={(e) => handleChecked(e, item)}
              disabled={isReadOnly}
            />
          )
        },
        {
          title: getTitles('T-10438'),
          values:
            currRender === unassVetScr
              ? item.employee_id
              : currRender === unassignedLabScr
                ? item.laboratory_id
                : item.cattle_id
        },
        {
          title: currRender === unassignedLabScr ? getTitles('T-11073') : getTitles('T-10081'),
          values: (
            <BasicProfile
              title={
                currRender === unassVetScr
                  ? item.name
                  : currRender === unassignedLabScr
                    ? item.laboratory_name
                    : item.cattle_name
              }
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title:
            currRender === unassVetScr
              ? getTitles('T-10440')
              : currRender === unassignedLabScr
                ? getTitles('T-11169')
                : getTitles('T-10802'),
          columnWidth: `calc(100%/4)`,
          values:
            currRender === unassVetScr
              ? item.phone_number
              : currRender === unassignedLabScr
                ? item.primary_mobile_number
                : item.farmer && item.farmer.full_name
        }
      ];
    } else {
      return {
        title: (
          <Box sx={styles.removeCont}>
            <BasicProfile
              title={
                currRender === unassVetScr
                  ? item.name
                  : currRender === unassignedLabScr
                    ? item.laboratory_name
                    : item.cattle_name
              }
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
            <UICheckBox
              checked={
                currRender === unassVetScr
                  ? checked === item.user_id
                  : [unAssCatScr, unassignedLabScr].includes(currRender) &&
                    checked.includes(item._id)
              }
              onChange={(e) => handleChecked(e, item)}
              disabled={isReadOnly}
            />
          </Box>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action:
              currRender === unassVetScr
                ? item.employee_id
                : currRender === unassignedLabScr
                  ? item.laboratory_id
                  : item.cattle_id
          },
          {
            subTitle:
              currRender === unassVetScr
                ? getTitles('T-10440')
                : currRender === unassignedLabScr
                  ? getTitles('T-11169')
                  : getTitles('T-10802'),
            action:
              currRender === unassVetScr
                ? item.phone_number
                : currRender === unassignedLabScr
                  ? item.primary_mobile_number
                  : item.farmer && item.farmer.full_name
          }
        ]
      };
    }
  };

  const tableData = aiWorkers.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          values: item.employee_id,
          columnWidth: '10%'
        },
        {
          title: getTitles('T-10081'),
          values: (
            <BasicProfile
              title={item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title: getTitles('T-10440'),
          values: item.phone_number
        },
        {
          title: getTitles('T-10103'),
          columnWidth: '10%',
          values: (
            <ActionButton
              status={item.is_active}
              onStatusChange={() => handleStatus(item)}
              isReadOnly={isReadOnly}
              alterTitle={defaultValues.confirmationModel.changeAIWorkerStatus.title(
                item.is_active
              )}
              alterSubTitle={defaultValues.confirmationModel.changeAIWorkerStatus.subtitles}
            />
          )
        },
        {
          title: getTitles('T-10589'),
          columnWidth: '15%',
          values: (
            <img
              src={assignIcon}
              style={styles.assignViewScr(!item.is_active)}
              onClick={() => item.is_active && handleAssignClick(item)}
            />
          )
        },
        {
          title: getTitles('T-10801'),
          columnWidth: md ? '18%' : '15%',
          values: (
            <img
              src={assignIcon}
              style={styles.assignViewScr(!item.is_active)}
              onClick={() => item.is_active && handleAssignCattleClick(item)}
            />
          )
        },
        {
          title: getTitles('T-11168'),
          columnWidth: md ? '18%' : '15%',
          values: (
            <img
              src={assignIcon}
              style={styles.assignViewScr(!item.is_active)}
              onClick={() => item.is_active && handleAssignLabClick(item)}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <BasicProfile
            title={item.name}
            url={item.photo_url ? item.photo_url : null}
            textVariant="D22"
          />
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: item.employee_id
          },
          {
            subTitle: getTitles('T-10440'),
            action: item.phone_number
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item)}
                isReadOnly={isReadOnly}
                alterTitle={defaultValues.confirmationModel.changeAIWorkerStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeAIWorkerStatus.subtitles}
              />
            )
          },
          {
            subTitle: getTitles('T-10589'),
            action: (
              <img
                src={assignIcon}
                style={styles.assignViewScr(!item.is_active)}
                onClick={() => item.is_active && handleAssignClick(item)}
              />
            )
          },
          {
            subTitle: getTitles('T-10801'),
            action: (
              <img
                src={assignIcon}
                style={styles.assignViewScr(!item.is_active)}
                onClick={() => item.is_active && handleAssignCattleClick(item)}
              />
            )
          },
          {
            subTitle: getTitles('T-11168'),
            action: (
              <img
                src={assignIcon}
                style={styles.assignViewScr(!item.is_active)}
                onClick={() => item.is_active && handleAssignLabClick(item)}
              />
            )
          }
        ]
      };
    }
  });

  const tableAssignedData =
    currAIWorker && currAIWorker.vet ? [getAssignedTable(currAIWorker.vet)] : [];

  const tableAssignedCattlesData =
    currAIWorker &&
    currAIWorker.cattles &&
    currAIWorker.cattles.length > 0 &&
    currAIWorker.cattles.map((item) => getAssignedTable(item));

  const tableVetList =
    vetList &&
    vetList.length > 0 &&
    vetList.map((item) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-10429'),
            values: (
              <UICheckBox
                checked={checked === item.user_id}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(setAiworkerChecked(item.user_id));
                  } else {
                    dispatch(setAiworkerChecked(''));
                  }
                  setBtnDisabled(false);
                }}
                sx={styles.checkIcon}
              />
            )
          },
          {
            title: getTitles('T-10438'),
            values: item.employee_id
          },
          {
            title: getTitles('T-10081'),
            values: (
              <BasicProfile
                title={item.full_name}
                url={item.photo_url ? item.photo_url : null}
                textVariant="D22"
              />
            )
          },
          {
            title: getTitles('T-10440'),
            columnWidth: `calc(100%/4)`,
            values: item.phone_number
          }
        ];
      } else {
        return {
          title: (
            <Box sx={styles.removeCont}>
              <BasicProfile
                title={item.full_name}
                url={item.photo_url ? item.photo_url : null}
                textVariant="D22"
              />
              <UICheckBox
                checked={checked === item.user_id}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(setAiworkerChecked(item.user_id));
                  } else {
                    dispatch(setAiworkerChecked(''));
                  }
                  setBtnDisabled(false);
                }}
                sx={styles.checkIcon}
              />
            </Box>
          ),
          details: [
            {
              subTitle: getTitles('T-10438'),
              action: item.employee_id
            },
            {
              subTitle: getTitles('T-10440'),
              action: item.phone_number
            }
          ]
        };
      }
    });

  vetList && vetList.length > 0 && vetList.map((item) => getUnassignedTable(item));

  const tableUnassignedCattleList =
    unassignedCattles &&
    unassignedCattles.length > 0 &&
    unassignedCattles.map((item) => getUnassignedTable(item));

  const tableUnassignedLabsList =
    unassignedLabs &&
    unassignedLabs.length > 0 &&
    unassignedLabs.map((item) => getUnassignedTable(item));

  const tableAssignedLabsData =
    currAIWorker &&
    currAIWorker.labs &&
    currAIWorker.labs.length > 0 &&
    currAIWorker.labs.map((item) => getAssignedTable(item));

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10120')} isLoading={isLoading}>
      <Box sx={styles.container}>
        <UILayout
          isMultiSelectFilterOption={true}
          currPageName={getTitles('T-10120')}
          handleBackButton={
            [
              assVetScr,
              unassVetScr,
              assCattleScr,
              unAssCatScr,
              unassignedLabScr,
              assignedLabScr
            ].includes(currRender)
              ? () => dispatch(updateAIworkerCurrRender(getPrevPageNo()))
              : null
          }
          tableTitle={
            currRender === unassVetScr
              ? getTitles('T-10589')
              : currRender === assVetScr
                ? getTitles('T-10590')
                : currRender === assCattleScr
                  ? getTitles('T-10803')
                  : currRender === unAssCatScr
                    ? getTitles('T-10801')
                    : currRender === unassignedLabScr
                      ? getTitles('T-11168')
                      : currRender === assignedLabScr && getTitles('T-11189')
          }
          showNoData={
            currRender === viewAiScr
              ? !aiWorkers.length > 0
              : currRender === unassVetScr
                ? vetList && !vetList.length > 0
                : currRender === assVetScr
                  ? currAIWorker && !currAIWorker.vet
                  : currRender === assCattleScr
                    ? currAIWorker && currAIWorker.cattles && currAIWorker.cattles.length === 0
                    : currRender === unAssCatScr
                      ? unassignedCattles && !unassignedCattles.length > 0
                      : currRender === unassignedLabScr
                        ? unassignedLabs && !unassignedLabs.length > 0
                        : currRender === assignedLabScr &&
                          currAIWorker &&
                          !currAIWorker.labs.length > 0
          }
          currPage={
            [viewAiScr, assCattleScr].includes(currRender)
              ? currentPage
              : currRender === unassVetScr
                ? unAssVetCurrPage
                : currRender === unAssCatScr
                  ? unAssCattleCurrPage
                  : currRender === unassignedLabScr && unAssLabsCurrPage
          }
          pageCount={
            [viewAiScr, assCattleScr].includes(currRender)
              ? totalPages
              : currRender === unassVetScr
                ? unAssVetTotalPage
                : currRender === unAssCatScr
                  ? unAssCattleTotalPage
                  : currRender === unassignedLabScr && unAssLabsTotalPage
          }
          handlePagination={handlePagination}
          tableItems={
            currRender === viewAiScr
              ? tableData
              : currRender === assVetScr
                ? tableAssignedData
                : currRender === unassVetScr
                  ? tableVetList
                  : currRender === assCattleScr
                    ? tableAssignedCattlesData
                    : currRender === unassignedLabScr
                      ? tableUnassignedLabsList
                      : currRender === assignedLabScr
                        ? tableAssignedLabsData
                        : currRender === unAssCatScr
                          ? tableUnassignedCattleList
                          : []
          }
          showPagination={
            currRender === viewAiScr
              ? aiWorkers.length > 0 && totalPages > 1
              : currRender === assVetScr
                ? vetList.length > 0 && unAssVetTotalPage > 1
                : currRender === unAssCatScr
                  ? unassignedCattles.length > 0 && unAssCattleTotalPage > 1
                  : currRender === unassignedLabScr
                    ? unassignedLabs.length > 0 && unAssLabsTotalPage > 1
                    : false
          }
          searchBarPlaceHolder={
            [assignedLabScr, unassignedLabScr].includes(currRender)
              ? getTitles('T-11198')
              : getTitles('T-10308')
          }
          showDataTable={!defaultValues.isResponsive}
          message={
            currRender === viewAiScr
              ? getErrorMessage('E-10063')
              : [assVetScr, unassVetScr].includes(currRender)
                ? getErrorMessage('E-10060')
                : [assignedLabScr, unassignedLabScr].includes(currRender)
                  ? getErrorMessage('E-10165')
                  : getErrorMessage('E-10155')
          }
          isReadOnly={isReadOnly}
          handleRefresh={currRender === viewAiScr && handleRefresh}
          handleSort={currRender === viewAiScr && handleSort}
          handleFilter={currRender === viewAiScr && handleFilter}
          handleAddButton={
            [assCattleScr, assignedLabScr].includes(currRender) && handleAssignNewClick
          }
          addBtnTitle={
            [assVetScr, assCattleScr, assignedLabScr].includes(currRender) &&
            !defaultValues.isResponsive &&
            getTitles('T-10483')
          }
          handleSecondaryAdd={
            ((currRender === unassVetScr && vetList && vetList.length > 0) ||
              (currRender === unAssCatScr && unassignedCattles && unassignedCattles.length > 0) ||
              (currRender === unassignedLabScr && unassignedLabs && unassignedLabs.length > 0)) &&
            handleSubmitAssign
          }
          secondaryAddDisabled={!checked || checked.length === 0 || btnDisabled}
          collapsable={currRender === viewAiScr}
          addBtnStyle={defaultValues.isResponsive && styles.addBtnStyle}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          handleSearch={handleSearch}
          clearSearch={filter}
        />
      </Box>
    </Screen>
  );
};
