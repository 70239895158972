import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addCattleToAiFailure,
  addCattleToAiRequest,
  addCattleToAiSuccess,
  addVetToAiFailure,
  addVetToAiRequest,
  addVetToAiSuccess,
  getAIWorkerFailure,
  getAIWorkerRequest,
  getAIWorkerSuccess,
  getunAssignedVetFailure,
  getunAssignedVetRequest,
  getunAssignedVetSuccess,
  getUnassignedCattlesFailure,
  getUnassignedCattlesRequest,
  getUnassignedCattlesSuccess,
  updateAIWorkerStatusFailure,
  updateAIWorkerStatusRequest,
  updateAIWorkerStatusSuccess,
  updateAssignedCattleFailure,
  updateAssignedCattleRequest,
  updateAssignedCattleSuccess,
  updateAssignedVetFailure,
  updateAssignedVetRequest,
  updateAssignedVetSuccess,
  getUnassignedLabsSuccess,
  getUnassignedLabsFailure,
  getUnassignedLabsRequest,
  assignLabToAiworkerSuccess,
  assignLabToAiworkerFailure,
  assignLabToAiworkerRequest
} from '../slices';
import {
  addCattleToAiApi,
  addVetToAiApi,
  assignLabToUserApi,
  getAIWorkersApi,
  getUnAssignedVetsApi,
  getUnassignedCattleApi,
  getUnassignedLabApi,
  updateAIWorkerStatusApi,
  updateAssignedCattleApi,
  updateAssignedVetApi
} from '../../api';

function* getAIWorkers(action) {
  try {
    const response = yield getAIWorkersApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getAIWorkerSuccess(response.data));
    } else {
      yield put(getAIWorkerFailure(response.data));
    }
  } catch (error) {
    yield put(getAIWorkerFailure(error.response.data));
  }
}

function* addVetToAi(action) {
  try {
    const response = yield addVetToAiApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addVetToAiSuccess(response.data));
    } else {
      yield put(addVetToAiFailure(response.data));
    }
  } catch (error) {
    yield put(addVetToAiFailure(error.response.data));
  }
}

function* updateAssignedVet(action) {
  try {
    const response = yield updateAssignedVetApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateAssignedVetSuccess(response.data));
    } else {
      yield put(updateAssignedVetFailure(response.data));
    }
  } catch (error) {
    yield put(updateAssignedVetFailure(error.response.data));
  }
}

function* updateAIWorkerStatus(action) {
  try {
    const response = yield updateAIWorkerStatusApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateAIWorkerStatusSuccess(response.data));
    } else {
      yield put(updateAIWorkerStatusFailure(response.data));
    }
  } catch (error) {
    yield put(updateAIWorkerStatusFailure(error.response.data));
  }
}

function* getUnAssignedVets(action) {
  try {
    const response = yield getUnAssignedVetsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getunAssignedVetSuccess(response.data));
    } else {
      yield put(getunAssignedVetFailure(response.data));
    }
  } catch (error) {
    yield put(getunAssignedVetFailure(error.response.data));
  }
}
function* getUnassignedCattle(action) {
  try {
    const response = yield getUnassignedCattleApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getUnassignedCattlesSuccess(response.data));
    } else {
      yield put(getUnassignedCattlesFailure(response.data));
    }
  } catch (error) {
    yield put(getUnassignedCattlesFailure(error.response.data));
  }
}

function* addCattleToAi(action) {
  try {
    const response = yield addCattleToAiApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addCattleToAiSuccess(response.data));
    } else {
      yield put(addCattleToAiFailure(response.data));
    }
  } catch (error) {
    yield put(addCattleToAiFailure(error.response.data));
  }
}

function* updateAssignedCattle(action) {
  try {
    const response = yield updateAssignedCattleApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateAssignedCattleSuccess(response.data));
    } else {
      yield put(updateAssignedCattleFailure(response.data));
    }
  } catch (error) {
    yield put(updateAssignedCattleFailure(error.response.data));
  }
}

function* assignLabToAiworker(action) {
  try {
    const response = yield assignLabToUserApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(assignLabToAiworkerSuccess(response.data));
    } else {
      yield put(assignLabToAiworkerFailure(response.data));
    }
  } catch (error) {
    yield put(assignLabToAiworkerFailure(error.response.data));
  }
}

function* getUnassignedLab(action) {
  try {
    const response = yield getUnassignedLabApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getUnassignedLabsSuccess(response.data));
    } else {
      yield put(getUnassignedLabsFailure(response.data));
    }
  } catch (error) {
    yield put(getUnassignedLabsFailure(error.response.data));
  }
}

export function* aiWorkerSaga() {
  yield takeLatest(getAIWorkerRequest.type, getAIWorkers);
  yield takeLatest(addVetToAiRequest.type, addVetToAi);
  yield takeLatest(updateAssignedVetRequest.type, updateAssignedVet);
  yield takeLatest(updateAIWorkerStatusRequest.type, updateAIWorkerStatus);
  yield takeLatest(getunAssignedVetRequest.type, getUnAssignedVets);
  yield takeLatest(getUnassignedCattlesRequest.type, getUnassignedCattle);
  yield takeLatest(addCattleToAiRequest.type, addCattleToAi);
  yield takeLatest(updateAssignedCattleRequest.type, updateAssignedCattle);
  yield takeLatest(getUnassignedLabsRequest.type, getUnassignedLab);
  yield takeLatest(assignLabToAiworkerRequest.type, assignLabToAiworker);
}
