import { Box, Grid, IconButton } from '@mui/material';
import { Text, UIButton } from '../../../ui-kits';
import { useEffect, useState } from 'react';
import { Album, Mic, MicOff, PauseCircle, PhonePaused } from '@mui/icons-material';
import { defaultValues } from '../../../constants';
import { styles } from './styles';
import { getTitles } from '../../../utils';

/**
 * Sub-component that shows the status of the call (whether it is incoming or outgoing) and the number of the caller/callee
 * @param {string} type either incoming or outgoing
 * @param {string} phone_number phone number of caller/callee
 */
export const CallBox = ({ type, phone_number, handleCallState }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);

  const [time] = useState('00:00');

  const [title, setTitle] = useState('');
  useEffect(() => {
    if (type === defaultValues.callStatus.outgoing) {
      setTitle(getTitles('T-11363'));
    } else if (
      type === defaultValues.callStatus.incoming ||
      type === defaultValues.callStatus.accepted
    ) {
      setTitle(getTitles('T-11364'));
    } else {
      setTitle(getTitles('T-11362'));
    }
  }, [type]);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };
  const handleToggleHold = () => {
    setIsOnHold(!isOnHold);
  };
  const handleToggleRecord = () => {
    setIsRecording(!isRecording);
  };

  return (
    <Box sx={styles.container}>
      {type === defaultValues.callStatus.inactive ? (
        <Text variant="BoldH24">{title}</Text>
      ) : (
        <>
          <Text variant="LinkD24" style={{ mt: 1 }}>
            {title}
          </Text>
          <Text variant="D24" style={{ mt: 2 }}>
            {phone_number}
          </Text>
        </>
      )}
      {type === defaultValues.callStatus.incoming ? (
        <Grid container direction="flex" mt={2} pr={2} pl={2} boxSizing={'border-box'} spacing={1}>
          <Grid item xs={6}>
            <UIButton
              title={defaultValues.callActions.accept}
              variant="acceptCall"
              onClick={() => handleCallState(defaultValues.callActions.incoming)}
            />
          </Grid>
          <Grid item xs={6}>
            <UIButton
              title={defaultValues.callActions.reject}
              variant="endCall"
              onClick={() => handleCallState(defaultValues.callActions.inactive)}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {type === defaultValues.callStatus.accepted || type === defaultValues.callStatus.outgoing ? (
        <Box sx={styles.container}>
          <Text variant="D24">{time}</Text>
          <Box>
            <IconButton name="mute" onClick={() => handleToggleMute()}>
              {!isMuted ? <Mic /> : <MicOff />}
            </IconButton>
            <IconButton name="hold" onClick={() => handleToggleHold()}>
              {!isOnHold ? <PhonePaused /> : <PauseCircle />}
            </IconButton>
            <IconButton name="record" onClick={() => handleToggleRecord()}>
              {!isRecording ? <Album /> : <Album color="error" />}
            </IconButton>
          </Box>
          <UIButton
            title={defaultValues.callActions.endcall}
            variant="endCall"
            onClick={() => handleCallState(defaultValues.callActions.inactive)}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
