import icon from '../../assets/icons/deleteIcon.png';
import { UIButton } from '../button';

export const UIDeleteIcon = ({ onClick }) => {
  const styles = {
    editBtn: {
      width: '40px',
      height: '40px',
      padding: '5px',
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      '& .MuiButton-startIcon': {
        marginRight: '0px',
        marginLeft: '0px'
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  };
  return (
    <UIButton
      startIcon={
        <img src={icon} alt="delete icon" style={{ objectFit: 'contain', width: '15px' }} />
      }
      onClick={onClick}
      style={styles.editBtn}
    />
  );
};
