import { defaultValues } from '../../constants';
import { Screen, Text, UITablist } from '../../ui-kits';
import { ManageState } from '../manageState';
import { checkReadOnly, getTitles } from '../../utils/commonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessCurrTab, processLoading } from '../../services/redux/selectors/manageProccess';
import { ManageProfiling, MasterList } from '../../components';
import { useEffect, useState } from 'react';
import { getUserData } from '../../services/redux/selectors';

import { ManageDisease } from './manage-disease';
import { updateProcessCurrTab } from '../../services/redux/slices';
import { ManageVaccines } from './manage-vaccinces';

export const ManageProcessPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(processLoading);
  const userData = useSelector(getUserData);
  const currTab = useSelector(getProcessCurrTab);

  const [isReadOnly, setIsReadOnly] = useState(null);
  const [isDiseaseDetails, setIsDiseaseDetails] = useState(null);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.processManager));
  }, [userData]);

  const onDetailsPageChange = (val) => {
    setIsDiseaseDetails(val ? true : false);
  };

  const tabList = [
    { value: <ManageState isReadOnly={isReadOnly} />, title: getTitles('T-10299') },
    {
      value: <ManageDisease isReadOnly={isReadOnly} isdetailsScreen={onDetailsPageChange} />,
      title: getTitles('T-10301')
    },
    { value: <MasterList isReadOnly={isReadOnly} />, title: getTitles('T-10302') },
    { value: <ManageVaccines isReadOnly={isReadOnly} />, title: getTitles('T-10421') },
    { value: <ManageProfiling isReadOnly={isReadOnly} />, title: getTitles('T-11154') }
  ];

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={isDiseaseDetails ? getTitles('T-10307') : getTitles('T-10079')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {isDiseaseDetails ? getTitles('T-10307') : getTitles('T-10079')}
        </Text>
      )}
      <UITablist
        renderElements={tabList}
        currentTab={currTab}
        onTabChange={(val) => dispatch(updateProcessCurrTab(val))}
      />
    </Screen>
  );
};
