import { Box, Drawer } from '@mui/material';
import { styles } from './styles';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { Text, UIChipButton, UIPagination, UIRefresh, UISort } from '../../../../ui-kits';
import { getTitles, resetMessages } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ProductListEcom } from '../product-list';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getActivityCurrPage,
  getActivityProducts,
  getActivityTotalPage,
  getEcommerceErrorMsg,
  getEcommerceProductListCurrRender,
  getEcommerceProductListRecentExpand,
  getEcommerceSuccessMsg,
  getEcomSearchText,
  getTotalProduct
} from '../../../../services/redux/selectors/eCommerceSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActivityProductsRequest,
  resetECommerceMsg,
  setActivityFilter,
  setEcommerceProducListRecentExpand,
  setEcommerceProducListTabCurrRender,
  setIsEcommerce,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { getScroll } from '../../../../services/redux/selectors';
import filter from '../../../../assets/icons/filter.png';
import { EcomFilters } from '../e-com-filters';
import { color } from '../../../../theme';
import { toast } from 'react-toastify';

export const EcomActivity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const productListRecentExpand = useSelector(getEcommerceProductListRecentExpand);
  const productListRecentCurrRender = useSelector(getEcommerceProductListCurrRender);
  const products = useSelector(getActivityProducts);
  const scroll = useSelector(getScroll);
  const currPage = useSelector(getActivityCurrPage);
  const pageCount = useSelector(getActivityTotalPage);
  const totalProduct = useSelector(getTotalProduct);
  const searchText = useSelector(getEcomSearchText);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const errorMsg = useSelector(getEcommerceErrorMsg);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [category, setCategory] = useState([]);
  const [sort, setSort] = useState(null);
  const [productList, setProductList] = useState([]);
  const [open, setOpen] = useState(false);

  const res = defaultValues.isResponsive;

  useEffect(() => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
    handleGetProducts();
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (products) {
      setProductList(products);
    }
  }, [products]);

  const handleGetProducts = (data) => {
    const filteredItems = [
      { material_group: category[0] || location?.state?.material_group },
      { min_price: parseInt(minPrice) || undefined },
      { max_price: parseInt(maxPrice) || undefined }
    ].filter((item) => Object.values(item).every((value) => value !== undefined));

    let payload = {
      sort,
      search_text: searchText,
      filter: JSON.stringify(filteredItems),
      type: location?.state?.type,
      mark_as: location?.state?.mark_as,
      ...data
    };
    dispatch(setActivityFilter(filteredItems));
    dispatch(getActivityProductsRequest(payload));
  };

  const handleChange = (_, newValue) => {
    setPriceRange(newValue);
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  const handleMinPriceChange = (newValue) => {
    if (newValue >= 0) {
      setPriceRange([newValue, priceRange[1]]);
      setMinPrice(newValue);
    }
  };

  const handleMaxPriceChange = (newValue) => {
    if (newValue <= 10000) {
      setPriceRange([priceRange[0], newValue]);
      setMaxPrice(newValue);
    }
  };

  const handleSort = (data) => {
    setSort(data);
    handleGetProducts({ sort: data });
  };

  const handleRefresh = () => {
    setSort([]);
    setMinPrice(null);
    setMaxPrice(null);
    setCategory([]);
    handleGetProducts({ filter: null, sort: null, page: null });
  };

  const handleProductdetails = (item) => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.PRODUCT_DETAILS}/${item._id}`);
  };

  const handleTabChange = (item, idx) => {
    dispatch(setEcommerceProducListTabCurrRender(idx));
  };

  const handlePagination = (data) => {
    handleGetProducts({ page: data });
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleApply = () => {
    const filteredItems = [
      { material_group: category[0] },
      { min_price: parseInt(minPrice) || undefined },
      { max_price: parseInt(maxPrice) || undefined }
    ].filter((item) => Object.values(item).every((value) => value !== undefined));

    let payload = {
      filter: JSON.stringify(filteredItems)
    };
    handleGetProducts(payload);
  };

  const handleDeleteChip = (index) => {
    setCategory(category.filter((_, idx) => idx !== index));
  };

  return (
    <Box sx={styles.container}>
      {!res && (
        <Box sx={styles.left}>
          <EcomFilters
            category={category}
            setCategory={setCategory}
            priceRange={priceRange}
            minPrice={minPrice}
            handleChange={handleChange}
            maxPrice={maxPrice}
            setMaxPrice={handleMaxPriceChange}
            setMinPrice={handleMinPriceChange}
            onApplyClick={handleApply}
          />
        </Box>
      )}
      <Box sx={styles.right}>
        <Box sx={styles.optionsContainer}>
          <UIRefresh handleRefresh={handleRefresh} />
          <UISort
            selectedOption={handleSort}
            dropdownOptions={defaultValues.ecomActivitySortOptions}
            activeOption={sort}
          />
          {res && (
            <Box style={styles.toolsIconContainer} onClick={toggleDrawer('Right', true)}>
              <img style={styles.toolsIcons} src={filter} />
              <Text variant="D20">{getTitles('T-10073')}</Text>
            </Box>
          )}
        </Box>

        <Box sx={styles.activeFilterCont}>
          <Text variant="D16" style={styles.filterText}>
            {getTitles('T-11610')}
          </Text>
          <Box sx={styles.activeFilterSubCont}>
            {category?.length > 0 &&
              category.map((item, idx) => (
                <UIChipButton
                  key={idx}
                  title={defaultValues.materialGroupOptions.find((elem) => elem.id === item)?.name}
                  style={styles.chip}
                  backgroundColor={color.primary}
                  onDelete={() => {
                    handleDeleteChip(idx);
                  }}
                  deleteIconStyle={styles.deleteIconStyle}
                />
              ))}
          </Box>
          <Text style={styles.results} variant="D16">
            <span style={styles.totalProduct}>{totalProduct}</span>
            {getTitles('T-11611')}
          </Text>
        </Box>

        <ProductListEcom
          tablist={defaultValues.materialGroupOptionsEcom.slice(
            0,
            productListRecentExpand ? defaultValues.materialGroupOptionsEcom.length - 1 : 4
          )}
          title={location?.state?.title}
          list={productList}
          productCardContainerStyles={styles.productCardContainerStyles}
          onProductTitleClick={handleProductdetails}
          tabSubStyle={styles.tabSub}
          currentTab={productListRecentCurrRender}
          onTabChange={(item, idx) => handleTabChange(item, idx)}
          onArrowClick={() =>
            dispatch(setEcommerceProducListRecentExpand(!productListRecentExpand))
          }
          arrowExpand={productListRecentExpand}
          showBrowse={false}
          showTabs={false}
          tabContStyle={styles.tabContStyle}
          itemsContainer={styles.itemsContainer}
          productCardImageStyle={styles.productCardImageStyle}
        />

        {productList.length > 0 && pageCount > 1 && (
          <Box sx={styles.pagination}>
            <UIPagination onChange={handlePagination} count={pageCount} currentPage={currPage} />
          </Box>
        )}
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} sx={styles.drawer}>
        <EcomFilters
          category={category}
          setCategory={setCategory}
          priceRange={priceRange}
          minPrice={minPrice}
          handleChange={handleChange}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          setMinPrice={setMinPrice}
          onApplyClick={handleApply}
        />
      </Drawer>
    </Box>
  );
};
