import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
const res = defaultValues.isResponsive;
const sm = defaultValues.isMdSmall;
const md = defaultValues.isMedium;
const lg = defaultValues.isLarge;

export const HeaderStyles = {
  container: (isAuthenticated, isEcom) => ({
    background: isEcom
      ? color.secondary
      : isAuthenticated
        ? res
          ? color.secondary
          : color.secondaryBackground
        : color.secondary,
    width: '100%',
    height: res ? '60px' : '100px',
    padding: '0% 2%',
    boxSizing: 'border-box',
    mb: isAuthenticated && 2
  }),
  subContainer: (isAuthenticated) => ({
    padding: '0%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    height: '100%',
    borderBottom: isAuthenticated && `1px solid ${color.primaryBorder}`
  }),

  logoStyles: {
    width: '20%',
    maxWidth: res ? '40px' : md ? '50px' : lg ? '60px' : '80px',
    maxHeight: res ? '40px' : md ? '50px' : lg ? '60px' : '80px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '2%'
  },
  toolbar: (isAuthenticated) => ({
    display: 'flex',
    gap: '10px',
    paddingRight: !isAuthenticated && '24px',
    boxSizing: 'border-box',
    height: '100%'
  }),
  toolOptionsText: {
    color: color.primaryText,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  toolCont: {
    padding: '2%',
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  toolSuperCont: {
    flex: 1,
    display: 'flex',
    justifyContent: 'right',
    visibility: res ? 'hidden' : 'visible'
  },
  loginBtn: {
    fontSize: sm ? '14px' : res ? '16px' : md ? '20px' : '24px',
    padding: sm ? '3px 20px' : '5px 50px',
    boxSizing: 'border-box',
    marginLeft: res && 'auto'
  },
  autoStyle: {
    '& .MuiInputBase-root': {
      paddingRight: '23px !important',
      padding: '2px !important',
      boxSizing: 'border-box'
    }
  },
  autoCont: {
    display: res ? 'none' : 'block',
    width: res ? '120px' : '130px',
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    paddingRight: 0
  },
  languageCont: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: res ? '0px' : '4px',
    boxSizing: 'border-box',
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    backgroundColor: res ? color.secondary : color.primaryBackground
  },
  shoppingCont: {
    gap: '7px',
    p: 1,
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  paperBox: {
    paddingRight: '0px'
  },
  textInp: {
    '& fieldset': { border: 'none' }
  },
  langImg: {
    width: '24px',
    height: '24px'
  },
  shopping: {
    width: '20px',
    height: '20px'
  },
  badge: {
    mr: 1,
    '& .MuiBadge-badge': {
      right: 10,
      top: 5,
      padding: '0 4px',
      fontSize: '10px',
      backgroundColor: color.secondary,
      border: res ? '1px solid' : 'none',
      boxSizing: 'border-box'
    }
  },
  avatar: {
    height: res ? '24px' : '32px',
    width: res ? '24px' : '32px'
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    backgroundColor: res ? color.secondary : color.primaryBackground,
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    padding: '0px 5px',
    boxSizing: 'border-box'
  },
  profileClickCont: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '1px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${color.secondaryText}`,
      borderRadius: 1
    }
  },
  logoutBox: {
    position: 'absolute',
    width: res ? 'fit-content' : '165px',
    height: 'fit-content',
    top: res ? '44px' : '70px',
    right: res ? '17px' : '20px',
    backgroundColor: `${color.secondaryBackground}`
  },
  dropDownTxt: {
    px: 2,
    py: 1,
    '&:hover': {
      backgroundColor: `${color.primary}`,
      color: `${color.tertiaryText}`
    },
    cursor: 'pointer'
  },
  profileSuperCont: {
    display: 'flex',
    gap: res ? '0px' : '10px'
  },
  dialogueCont: {
    position: 'absolute',
    top: '2%',
    right: '10%',
    bottom: 'unset',
    left: 'unset',
    padding: 0
  },
  dialogue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: 0,
    maxHeight: '300px',
    width: 'fit-content',
    overflow: 'auto'
  },
  dBtn: {
    width: '100%',
    color: color.primaryText,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: sm ? '0.875' : res ? '1rem' : '1.25rem',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '0px',
    padding: '5px 30px',
    boxSizing: 'border-box',
    maxWidth: '300px'
  },
  drawer: {
    '& .MuiPaper-root': {
      width: '250px',
      backgroundColor: color.secondary
    }
  }
};
