import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { color } from '../../../theme';
import { Box, IconButton } from '@mui/material';
import { Text } from '../../../ui-kits';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { defaultValues } from '../../../constants';

export const ViewMultiData = ({
  title,
  children,
  onEdit,
  onDelete,
  showBorder = false,
  showIcon = true
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles = {
    icons: {
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      backgroundColor: color.primary,
      minWidth: 'unset',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      mr: 1,
      '&:hover': {
        backgroundColor: color.primary,
        color: 'white'
      }
    },
    container: {
      display: 'flex',
      my: 1,
      borderRadius: 1,
      border: showBorder ? `1px solid ${color.primaryBorder}` : 'none',
      backgroundColor: showBorder && color.tableHeading,
      p: 1
    },
    textCont: { display: 'flex', flex: 1, alignItems: 'center' },
    iconCont: { display: 'flex', flex: 1, justifyContent: defaultValues.isResponsive && 'end' }
  };
  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.textCont}>
          <Text variant="D20" onClick={() => setIsOpen(!isOpen)}>
            {title}
          </Text>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Box>
        {showIcon && (
          <Box sx={styles.iconCont}>
            <IconButton sx={styles.icons} onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton sx={styles.icons} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {isOpen && <Box>{children}</Box>}
    </Box>
  );
};
