import { Box, Divider } from '@mui/material';
import {
  Text,
  UIChipButton,
  UIRadioButtonWithTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import {
  filterSelectedValue,
  getMasterListItems,
  getNamesByIds,
  getTitles,
  resetMessages
} from '../../../utils';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { ModalButtons } from '../../common/modal-buttons';
import { useFormik } from 'formik';
import { getMasterLists } from '../../../services/redux/selectors/manageProccess';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getActionType,
  getFarmerData,
  getFarmerErrorMsg,
  getFarmerSuccessMsg
} from '../../../services/redux/selectors';
import { toast } from 'react-toastify';
import {
  addFarmerRequest,
  resetFarmerMsg,
  updateFarmerRequest
} from '../../../services/redux/slices';
import { Styles } from '../style';

export const LandDetails = ({ onNext, onPrev, setNext }) => {
  const { view, edit, viewOnly } = defaultValues.actionType;

  const dispatch = useDispatch();
  const listItems = useSelector(getMasterLists);
  const farmerData = useSelector(getFarmerData);
  const success = useSelector(getFarmerSuccessMsg);
  const error = useSelector(getFarmerErrorMsg);
  const actionType = useSelector(getActionType);

  const [cropsOption, setCropsOption] = useState([]);
  const [filteredCropsOption, setFilterdCropsOption] = useState([]);
  const [sourceOfWaterOption, setSourceOfWaterOption] = useState([]);
  const [filteredSourceOfWaterOption, setFilteredSourceOfWaterOption] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);

  useEffect(() => {
    if (listItems) {
      setCropsOption(getMasterListItems(listItems, defaultValues.mListIds.crops));
      setSourceOfWaterOption(
        getMasterListItems(listItems, defaultValues.mListIds.agricultureSourceWater)
      );
    }
  }, [listItems]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      own_agriculture_land: null,
      own_land_details: {
        same_village: null,
        village_name: '',
        land_size: { acres: '', gunta: '' }
      },
      others_land_details: {
        taken_for_cultivation: null,
        cultivated_land_size: { acres: Number, gunta: Number }
      },
      source_of_water: [],
      crops: [],
      crops_type: null,
      fodder_land: { acres: '', gunta: '' },
      grass_land: { acres: '', gunta: '' },
      agriculture_avg_annual_income: null,
      training_details: {
        woman_member: null,
        farmer_induction: null,
        personality_development: null,
        fodder_development: null,
        other: null
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBtnDisable(false);

    if (name === 'source_of_water' || name === 'crops') {
      if (!values[name].includes(value)) {
        setFieldValue(name, [...values[name], { _id: value }]);
      }
    } else {
      setFieldValue(name, value);
    }
  };

  const handleDelete = (name, itemToDelete) => {
    setBtnDisable(false);
    const newValues = values[name].filter((item) => item !== itemToDelete);
    setFieldValue(name, newValues);
  };

  useEffect(() => {
    setFilterdCropsOption(filterSelectedValue(cropsOption, values.crops));
  }, [values.crops, cropsOption]);

  useEffect(() => {
    setFilteredSourceOfWaterOption(
      filterSelectedValue(sourceOfWaterOption, values.source_of_water)
    );
  }, [values.source_of_water, sourceOfWaterOption]);

  useEffect(() => {
    dispatch(resetFarmerMsg());

    if (farmerData) {
      const setValueIfExists = (field, value) => {
        if (value !== undefined && value !== null) {
          setFieldValue(field, value);
        }
      };
      setFieldValue('_id', farmerData._id);
      if (farmerData.land_and_training_details) {
        setValueIfExists('source_of_water', farmerData.land_and_training_details.source_of_water);
        setValueIfExists('crops', farmerData.land_and_training_details.crops);
        setValueIfExists('crops_type', farmerData.land_and_training_details.crops_type?.id);
        setValueIfExists(
          'agriculture_avg_annual_income',
          farmerData.land_and_training_details.agriculture_avg_annual_income
        );

        if (farmerData.land_and_training_details.fodder_land) {
          setValueIfExists(
            'fodder_land.acres',
            farmerData.land_and_training_details.fodder_land.acres
          );
          setValueIfExists(
            'fodder_land.gunta',
            farmerData.land_and_training_details.fodder_land.gunta
          );
        }

        if (farmerData.land_and_training_details.grass_land) {
          setValueIfExists(
            'grass_land.acres',
            farmerData.land_and_training_details.grass_land.acres
          );
          setValueIfExists(
            'grass_land.gunta',
            farmerData.land_and_training_details.grass_land.gunta
          );
        }

        if (farmerData.land_and_training_details) {
          setFieldValue(
            'own_agriculture_land',
            farmerData.land_and_training_details.own_agriculture_land
          );

          if (farmerData.land_and_training_details.own_land_details) {
            setValueIfExists(
              'own_land_details.same_village',
              farmerData.land_and_training_details.own_land_details.same_village
            );
            setValueIfExists(
              'own_land_details.village_name',
              farmerData.land_and_training_details.own_land_details.village_name
            );
            if (farmerData.land_and_training_details.own_land_details.land_size) {
              setValueIfExists(
                'own_land_details.land_size.acres',
                farmerData.land_and_training_details.own_land_details.land_size.acres
              );
              setValueIfExists(
                'own_land_details.land_size.gunta',
                farmerData.land_and_training_details.own_land_details.land_size.gunta
              );
            }
          }

          if (farmerData.land_and_training_details.others_land_details) {
            setFieldValue(
              'others_land_details.taken_for_cultivation',
              farmerData.land_and_training_details.others_land_details.taken_for_cultivation
            );

            if (farmerData.land_and_training_details.others_land_details.cultivated_land_size) {
              setFieldValue(
                'others_land_details.cultivated_land_size.acres',
                farmerData.land_and_training_details.others_land_details.cultivated_land_size.acres
              );
              setFieldValue(
                'others_land_details.cultivated_land_size.gunta',
                farmerData.land_and_training_details.others_land_details.cultivated_land_size.gunta
              );
            }
          }
        }

        if (farmerData.land_and_training_details.training_details) {
          setFieldValue(
            'training_details.woman_member',
            farmerData.land_and_training_details.training_details.woman_member
          );
          setFieldValue(
            'training_details.farmer_induction',
            farmerData.land_and_training_details.training_details.farmer_induction
          );
          setFieldValue(
            'training_details.personality_development',
            farmerData.land_and_training_details.training_details.personality_development
          );
          setFieldValue(
            'training_details.fodder_development',
            farmerData.land_and_training_details.training_details.fodder_development
          );
          setFieldValue(
            'training_details.other',
            farmerData.land_and_training_details.training_details.other
          );
        }
      }
    }
  }, [farmerData]);

  useEffect(() => {
    if (success === 'S-10042') {
      onNext();
    }
    if (success === 'S-10044') {
      toast.success(getSuccessMessage(success));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetFarmerMsg()));
  }, [success, error]);

  const handleSubmit = () => {
    if (actionType === edit) {
      let _id = farmerData._id;
      const land_and_training_details = {
        ...values,
        crops: values.crops?.map((item) => item?._id),
        source_of_water: values.source_of_water?.map((item) => item?._id),
        agriculture_avg_annual_income: parseFloat(values.agriculture_avg_annual_income)
      };
      dispatch(updateFarmerRequest({ _id, land_and_training_details }));
    } else {
      const payload = {};
      payload.data = {
        ...values,
        _id: farmerData?._id,
        crops: values.crops?.map((item) => item?._id),
        source_of_water: values.source_of_water?.map((item) => item?._id),
        agriculture_avg_annual_income: parseFloat(values.agriculture_avg_annual_income)
      };
      payload.registration_step = 4;
      dispatch(addFarmerRequest(payload));
    }
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.landContainer}>
        <UIRadioButtonWithTitle
          name="own_agriculture_land"
          title={getTitles('T-10538')}
          values={defaultValues.personalInsurane}
          onCheck={(val) => {
            setFieldValue('own_agriculture_land', val);
            setFieldValue('own_land_details.same_village', null);
            setFieldValue('own_land_details.land_size.acres', '');
            setFieldValue('own_land_details.land_size.gunta', '');
            setFieldValue('others_land_details.taken_for_cultivation', null);
            setBtnDisable(false);
          }}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.own_agriculture_land}
        />{' '}
        {values.own_agriculture_land && (
          <UIRadioButtonWithTitle
            name="own_land_details.same_village"
            title={getTitles('T-10539')}
            values={defaultValues.personalInsurane}
            onCheck={(val) => {
              setFieldValue('own_land_details.same_village', val);
              setFieldValue('own_land_details.village_name', '');
              setBtnDisable(false);
            }}
            disabled={actionType === view || actionType === viewOnly}
            checkedValue={values.own_land_details.same_village}
          />
        )}
        {values.own_land_details.same_village === false && (
          <UITextInputWithTitle
            name="own_land_details.village_name"
            title={getTitles('T-10540')}
            placeHolder={getTitles('T-10159')}
            value={values.own_land_details.village_name}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
          />
        )}{' '}
        {values.own_agriculture_land && (
          <>
            <Text variant="D20" style={Styles.primaryQ}>
              {getTitles('T-10541')}
            </Text>
            <Box sx={Styles.landDis}>
              <UITextInputWithTitle
                name="own_land_details.land_size.acres"
                title={getTitles('T-10676')}
                value={values.own_land_details.land_size.acres}
                onChange={handleChange}
                disabled={actionType === view || actionType === viewOnly}
                placeHolder={getTitles('T-11209')}
                containerStyles={Styles.secAns}
                allowOnlyNumbers
              />{' '}
              <UITextInputWithTitle
                name="own_land_details.land_size.gunta"
                title={getTitles('T-10673')}
                value={values.own_land_details.land_size.gunta}
                onChange={handleChange}
                disabled={actionType === view || actionType === viewOnly}
                containerStyles={Styles.secAns}
                placeHolder={getTitles('T-11210')}
                allowOnlyNumbers
              />
            </Box>
          </>
        )}
        {values.own_agriculture_land === false && (
          <>
            <UIRadioButtonWithTitle
              name="others_land_details.taken_for_cultivation"
              title={getTitles('T-10674')}
              values={defaultValues.personalInsurane}
              onCheck={(val) => {
                setFieldValue('others_land_details.taken_for_cultivation', val),
                  setFieldValue('others_land_details.cultivated_land_size.acres', ''),
                  setFieldValue('others_land_details.cultivated_land_size.gunta', '');
                setBtnDisable(false);
              }}
              disabled={actionType === view || actionType === viewOnly}
              checkedValue={values.others_land_details.taken_for_cultivation}
            />
            {values.others_land_details.taken_for_cultivation && (
              <>
                <Text variant="D20" style={Styles.primaryQ}>
                  {getTitles('T-10675')}
                </Text>
                <Box sx={Styles.landDis}>
                  <UITextInputWithTitle
                    name="others_land_details.cultivated_land_size.acres"
                    value={values.others_land_details.cultivated_land_size.acres}
                    onChange={handleChange}
                    disabled={actionType === view || actionType === viewOnly}
                    title={getTitles('T-10676')}
                    placeHolder={getTitles('T-11209')}
                    allowOnlyNumbers
                    containerStyles={Styles.secAns}
                  />{' '}
                  <UITextInputWithTitle
                    name="others_land_details.cultivated_land_size.gunta"
                    value={values.others_land_details.cultivated_land_size.gunta}
                    onChange={handleChange}
                    disabled={actionType === view || actionType === viewOnly}
                    title={getTitles('T-10673')}
                    containerStyles={Styles.secAns}
                    allowOnlyNumbers
                    placeHolder={getTitles('T-11210')}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {actionType !== view && (
          <UISelect
            name="source_of_water"
            value={''}
            optionLabel="name"
            returnValue="_id"
            title={getTitles('T-10542')}
            options={filteredSourceOfWaterOption}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            placeholder={getTitles('T-10350')}
          />
        )}
        {values.source_of_water.length > 0 && actionType === view && (
          <Text variant="D20" style={Styles.chipTitle}>
            {getTitles('T-10542')}
          </Text>
        )}
        {values.source_of_water &&
          values.source_of_water.map((item, index) => (
            <UIChipButton
              title={item?.name || getNamesByIds([item?._id], sourceOfWaterOption)}
              key={index}
              onDelete={() => handleDelete('source_of_water', item)}
              showDelete={actionType !== view}
            />
          ))}
        {actionType !== view && (
          <UISelect
            name="crops"
            value={''}
            optionLabel="name"
            returnValue="_id"
            title={getTitles('T-10543')}
            options={filteredCropsOption}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            placeholder={getTitles('T-10350')}
          />
        )}
        {values.crops.length > 0 && actionType === view && (
          <Text variant="D20" style={Styles.chipTitle}>
            {getTitles('T-10543')}
          </Text>
        )}
        {values.crops &&
          values.crops.map((item, index) => (
            <UIChipButton
              title={item?.name || getNamesByIds([item?._id], cropsOption)}
              key={index}
              onDelete={() => handleDelete('crops', item)}
              showDelete={actionType !== view}
            />
          ))}
        <UIRadioButtonWithTitle
          name="crops_type"
          title={getTitles('T-10544')}
          values={defaultValues.cropsType}
          onCheck={(val) => {
            setFieldValue('crops_type', val);
            setBtnDisable(false);
          }}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.crops_type?.id || values.crops_type}
        />{' '}
        <Text variant="D20" style={Styles.primaryQ}>
          {getTitles('T-10546')}
        </Text>
        <Box sx={Styles.landDis}>
          <UITextInputWithTitle
            name="fodder_land.acres"
            value={values.fodder_land.acres}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10676')}
            placeHolder={getTitles('T-11209')}
            containerStyles={Styles.secAns}
            allowOnlyNumbers
          />{' '}
          <UITextInputWithTitle
            name="fodder_land.gunta"
            value={values.fodder_land.gunta}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10673')}
            containerStyles={Styles.secAns}
            placeHolder={getTitles('T-11210')}
            allowOnlyNumbers
          />{' '}
        </Box>
        <Text variant="D20" style={Styles.text}>
          {getTitles('T-10548')}
        </Text>
        <Box sx={Styles.landDis}>
          <UITextInputWithTitle
            name="grass_land.acres"
            value={values.grass_land.acres}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10676')}
            placeHolder={getTitles('T-11209')}
            containerStyles={Styles.secAns}
            allowOnlyNumbers
          />{' '}
          <UITextInputWithTitle
            name="grass_land.gunta"
            value={values.grass_land.gunta}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10673')}
            containerStyles={Styles.secAns}
            placeHolder={getTitles('T-11210')}
            allowOnlyNumbers
          />{' '}
        </Box>
        <UITextInputWithTitle
          name="agriculture_avg_annual_income"
          value={values.agriculture_avg_annual_income || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10549')}
          placeHolder={getTitles('T-11211')}
          allowDecimal
        />{' '}
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10550')}
        </Text>
        <Divider sx={Styles.divider} />{' '}
        <UIRadioButtonWithTitle
          name="training_details.woman_member"
          onCheck={(val) => {
            setFieldValue('training_details.woman_member', val);
            setBtnDisable(false);
          }}
          title={getTitles('T-10555')}
          values={defaultValues.personalInsurane}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.training_details.woman_member}
        />{' '}
        <UIRadioButtonWithTitle
          name="training_details.farmer_induction"
          onCheck={(val) => {
            setFieldValue('training_details.farmer_induction', val);
            setBtnDisable(false);
          }}
          title={getTitles('T-10551')}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.training_details.farmer_induction}
          values={defaultValues.personalInsurane}
        />{' '}
        <UIRadioButtonWithTitle
          title={getTitles('T-10552')}
          name="training_details.personality_development"
          onCheck={(val) => {
            setFieldValue('training_details.personality_development', val);
            setBtnDisable(false);
          }}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.training_details.personality_development}
          values={defaultValues.personalInsurane}
        />{' '}
        <UIRadioButtonWithTitle
          title={getTitles('T-10553')}
          name="training_details.fodder_development"
          onCheck={(val) => {
            setFieldValue('training_details.fodder_development', val);
            setBtnDisable(false);
          }}
          values={defaultValues.personalInsurane}
          checkedValue={values.training_details.fodder_development}
          disabled={actionType === view || actionType === viewOnly}
        />{' '}
        <UIRadioButtonWithTitle
          title={getTitles('T-10554')}
          name="training_details.other"
          onCheck={(val) => {
            setFieldValue('training_details.other', val);
            setBtnDisable(false);
          }}
          values={defaultValues.personalInsurane}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.training_details.other}
        />{' '}
      </Box>

      <ModalButtons
        btnPositions={defaultValues.isResponsive ? 'center' : 'start'}
        disabled={actionType === edit ? btnDisable : false}
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={() => {
          if (actionType === viewOnly) {
            onPrev();
          } else {
            onPrev();
          }
        }}
        onPrimaryBtnClick={
          actionType === view || actionType === viewOnly ? () => setNext(4) : handleSubmit
        }
        primaryBtnTitle={
          actionType === edit
            ? getTitles('T-10733')
            : actionType === view || actionType === viewOnly
              ? getTitles('T-10804')
              : getTitles('T-10339')
        }
      />
    </Box>
  );
};
