import { defaultValues } from '../../constants';
import { color, globalStyles } from '../../theme';
const lg = defaultValues.isLarge;
const md = defaultValues.isMedium;

export const HomeStyles = {
  container: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: color.primaryBackground
  },
  introCont: {
    display: 'flex',
    width: '100%',
    padding: '0% 3%',
    boxSizing: 'border-box',
    marginTop: '70px',
    borderBottom: `1px solid ${color.primaryBorder}`,
    paddingBottom: '30px'
  },
  introSubCont: {
    width: '60%'
  },
  myGopCont: {
    width: '100%',
    position: 'relative'
  },
  introText1: {
    color: color.primary,
    position: 'sticky',
    zIndex: 2
  },
  introText1backIcon: {
    width: md ? '200px' : '260px',
    height: md ? '90px' : '110px',
    position: 'absolute',
    top: 10,
    left: '-20px',
    zIndex: 0
  },
  desc1: {
    color: color.palette.darkBrown
  },
  contactBtn: {
    ...globalStyles.D22,
    ...globalStyles.mt(3),
    ...globalStyles.pl(5),
    ...globalStyles.pr(5)
  },
  cattleCont: {
    width: '100%',
    maxWidth: '560px',
    position: 'relative'
  },
  cow1: {
    width: '100%',
    height: '100%',
    maxHeight: md ? '350px' : lg ? '400px' : '500px',
    position: 'sticky',
    zIndex: 2
  },
  vector8: {
    width: '40%',
    maxHeight: md ? '180px' : '200px',
    position: 'absolute',
    left: '0%',
    top: md ? '30%' : lg ? '36%' : '42%',
    zIndex: 0
  },
  vector10: {
    width: '30%',
    maxHeight: md ? '200px' : '250px',
    position: 'absolute',
    right: 'calc(1.5 * -10%)',
    top: '-4%',
    zIndex: 0
  },
  benefits: {
    width: '100%',
    ...globalStyles.flexColXCenter,
    ...globalStyles.mt(3)
  },
  head1: {
    color: color.palette.darkBrown
  },
  desc3: {
    width: '80%',
    textAlign: 'center',
    mb: 3,
    mt: 1
  },
  BlistCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    padding: '0% 3%',
    boxSizing: 'border-box'
  },
  bImgCont: {
    width: '30%',
    textAlign: 'center',
    position: 'relative'
  },
  BCowImg: {
    width: '100%',
    maxWidth: lg ? '350px' : '450px',
    maxHeight: lg ? '450px' : '550px',
    position: 'absolute',
    left: 0
  },
  BriefCont: {
    width: '60%',
    ...globalStyles.flexColYCenter,
    minHeight: md ? '270px' : lg ? '300px' : '400px'
  },
  serviceCont: {
    width: '100%',
    height: '300px'
  },
  downloadCont: {
    width: '100%',
    backgroundColor: color.palette.paleYellow,
    display: 'flex',
    padding: '0% 3% 3% 3%',
    boxSizing: 'border-box',
    minHeight: '300px'
  },
  downloadCImgSuperCont: {
    width: '40%'
  },
  downloadCImgCont: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  downCow: {
    width: '100%',
    maxWidth: lg ? '350px' : '500px',
    maxHeight: lg ? '350px' : '450px',
    position: 'absolute',
    top: lg ? -60 : -90
  },
  downloadSubCont: {
    width: '60%',
    ...globalStyles.flexColYCenter
  },
  downloadBtn2: {
    maxWidth: '300px',
    cursor: 'pointer'
  }
};
