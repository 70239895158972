import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { useEffect, useRef, useState } from 'react';
import { defaultValues } from '../../../../../constants';
import {
  getDistrictRequest,
  getStateRequest,
  getTalukRequest,
  getVillageRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill
} from '../../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDistrict,
  getDistrictNextPage,
  getTaluka,
  getTalukaNextPage,
  getVillage,
  getVillageNextPage
} from '../../../../../services/redux/selectors/manageProccess';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewPreviousEmployment = ({ employeeData, stateList }) => {
  const dispatch = useDispatch();

  const districts = useSelector(getDistrict);
  const talukas = useSelector(getTaluka);
  const villages = useSelector(getVillage);
  const districtNxtPg = useSelector(getDistrictNextPage);
  const villageNxtPge = useSelector(getVillageNextPage);
  const talukNxtPge = useSelector(getTalukaNextPage);

  const [districtList, setDistrictsList] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [currEmp, setCurrEmp] = useState(null);
  const [index, setIndex] = useState(null);

  const previousOptions = useRef({
    district: districtList?.length,
    village: villagesList?.length,
    taluka: talukasList?.length,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    if (districtList?.length !== previousOptions?.district?.length) {
      deduplicateAndSet(districtList, districts, setDistrictsList);
    }
    if (talukasList?.length !== previousOptions?.taluka?.length) {
      deduplicateAndSet(talukasList, talukas, setTalukasList);
    }
    if (villagesList?.length !== previousOptions?.village?.length) {
      deduplicateAndSet(villagesList, villages, setVillagesList);
    }
    if (districtNxtPg !== previousOptions?.districtNxtPage && districtNxtPg !== null) {
      dispatch(getDistrictRequest({ state_id: currEmp?.state_id, page: districtNxtPg }));
    }
    if (talukNxtPge !== previousOptions?.talukaNextPage && talukNxtPge !== null) {
      dispatch(getTalukRequest({ district_id: currEmp?.district_id, page: talukNxtPge }));
    }

    if (villageNxtPge !== previousOptions?.villageNxtPage && villageNxtPge !== null) {
      dispatch(getVillageRequest({ taluka_id: currEmp?.taluka_id, page: villageNxtPge }));
    }

    previousOptions.current = {
      district: districtList?.length,
      village: villagesList?.length,
      taluka: talukasList?.length,
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districts, talukas, villages, districtNxtPg, villageNxtPge, talukNxtPge]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems);
  };

  const getName = (arr, checkVal) => {
    const newVal = arr && arr.length > 0 && arr.find((item) => item.value == checkVal);
    return newVal ? newVal.title : '';
  };

  const handleSubTitleClick = (item, idx) => {
    setCurrEmp(index === idx ? null : item);
    setIndex(index === idx ? null : idx);
    setDistrictsList([]);
    setTalukasList([]);
    setVillagesList([]);
    dispatch(setClearProcessStateDistTalVill());
    dispatch(setClearProcessPages());
    dispatch(getStateRequest({ page: 1 }));
    dispatch(getDistrictRequest({ state_id: item.state_id, page: 1 }));
    dispatch(getTalukRequest({ district_id: item.district_id, page: 1 }));
    dispatch(getVillageRequest({ taluka_id: item.taluka_id, page: 1 }));
  };

  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.length > 0 &&
        employeeData.map((item, idx) => (
          <Box key={idx}>
            <CollapseDropView
              title={item.employer_name}
              handleSubTitleClick={() => handleSubTitleClick(item, idx)}
              open={index === idx}
            />
            {currEmp && idx === index && (
              <Box sx={{ marginTop: '10px' }}>
                <ViewData title={getTitles('T-10443')} subTitle={currEmp.employer_mobile_number} />
                <ViewData
                  title={getTitles('T-10442')}
                  subTitle={`${currEmp.address_line_one} ${currEmp.address_line_two}`}
                />
                <ViewData title={getTitles('T-10363')} subTitle={currEmp.country_id} />
                <ViewData
                  title={getTitles('T-10070')}
                  subTitle={stateList?.find((item) => item._id === currEmp?.state_id)?.name}
                />
                <ViewData
                  title={getTitles('T-10069')}
                  subTitle={districtList?.find((elem) => elem._id === currEmp?.district_id)?.name}
                />
                <ViewData
                  title={getTitles('T-10071')}
                  subTitle={talukasList?.find((elem) => elem._id === currEmp?.taluka_id)?.name}
                />
                <ViewData
                  title={getTitles('T-10068')}
                  subTitle={villagesList?.find((elem) => elem._id === currEmp?.village_id)?.name}
                />
                <ViewData title={getTitles('T-10359')} subTitle={currEmp.post_code} />
                <ViewData
                  title={getTitles('T-10378')}
                  subTitle={currEmp.date_joining && getFormatDate(currEmp.date_joining)}
                />
                <ViewData
                  title={getTitles('T-10379')}
                  subTitle={currEmp.date_relieving && getFormatDate(currEmp.date_relieving)}
                />
                <ViewData title={getTitles('T-10382')} subTitle={currEmp.last_salary} />
                <ViewData title={getTitles('T-10050')} subTitle={currEmp.designation} />
                <ViewData
                  title={getTitles('T-10386')}
                  subTitle={getName(defaultValues.modeOfEmployment, currEmp.mode_employment)}
                />
                <ViewData
                  title={getTitles('T-10387')}
                  subTitle={getName(defaultValues.employmentSector, currEmp.employment_sector)}
                />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};
