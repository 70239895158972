import { defaultValues, SCREEN_PATH } from '../../../../constants';
import languageIcon from '../../../../assets/icons/language.png';
import shoppingIcon from '../../../../assets/icons/shopping_basket.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { useDispatch } from 'react-redux';
import { updateDetailsPg } from '../../../../services/redux/slices';
import { Avatar, Box, Button, Dialog, DialogContent } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { Text, UIAutocompleted } from '../../../../ui-kits';
import { HeaderStyles } from '../styles';
import { getTitles } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

export const AuthenticatedHeader = ({
  isAuthenticated,
  toggleDrawer,
  detailsPg,
  currentPage,
  setLangOpen,
  langOpen,
  handleDisplayLang,
  handleLanguage,
  getApplicationLanguage,
  userData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { labSupportEmployee, laboratoryIncharge } = defaultValues.employeeRol;
  const permissinIds = userData?.employee_permissions?.map((elem) => elem.permission_id);
  return (
    <>
      {defaultValues.isResponsive && isAuthenticated && (
        <Box>
          {!detailsPg ? (
            <MenuIcon onClick={toggleDrawer('Right', true)} />
          ) : (
            <UIBackButton onClick={() => dispatch(updateDetailsPg(false))} />
          )}
        </Box>
      )}

      <Box sx={{ flex: 1 }}>
        {!defaultValues.isResponsive && <Text variant="boldH32">{currentPage && currentPage}</Text>}
      </Box>
      <Box sx={HeaderStyles.profileSuperCont}>
        {permissinIds?.every((element) =>
          [labSupportEmployee, laboratoryIncharge].includes(element)
        ) && (
          <Box
            sx={{ ...HeaderStyles.languageCont, ...HeaderStyles.shoppingCont }}
            onClick={() => {
              navigate(SCREEN_PATH.ECOMMERCE);
            }}>
            <img src={shoppingIcon} style={HeaderStyles.shopping} />
            {!defaultValues.isResponsive && <Text variant="D20">{getTitles('T-11658')}</Text>}
          </Box>
        )}
        <Box sx={HeaderStyles.languageCont}>
          <img
            src={languageIcon}
            style={HeaderStyles.langImg}
            onClick={() => {
              setLangOpen(true);
            }}
          />

          <Dialog
            open={langOpen}
            onClose={() => handleDisplayLang(false)}
            sx={HeaderStyles.dialogueCont}>
            <DialogContent sx={HeaderStyles.dialogue}>
              {defaultValues.appLanguages.map((item, idx) => (
                <Button key={idx} onClick={() => handleLanguage(item)} sx={HeaderStyles.dBtn}>
                  {item.title}
                </Button>
              ))}
            </DialogContent>
          </Dialog>
          <UIAutocompleted
            options={defaultValues.appLanguages}
            optionLabel={(item) => item.title}
            containerStyle={HeaderStyles.autoCont}
            autoCompleteStyle={HeaderStyles.autoStyle}
            PaperBoxStyle={HeaderStyles.paperBox}
            textInputStyles={HeaderStyles.textInp}
            selectedValue={handleLanguage}
            preSelectedValue={getApplicationLanguage()}
            clearIcon={null}
            startIcon={languageIcon}
          />
        </Box>

        <Box sx={HeaderStyles.profile}>
          <Avatar alt="" src={userData?.photo_url} sx={HeaderStyles.avatar} />
          {!defaultValues.isResponsive && (
            <Text variant="D18">{`${userData?.first_name} ${userData?.last_name}`}</Text>
          )}
          <Badge badgeContent={5} sx={HeaderStyles.badge}>
            <NotificationsIcon />
          </Badge>
        </Box>
      </Box>
    </>
  );
};
