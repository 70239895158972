import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';
const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currTab: defaultValues.callCenterCurrTabs.myTask,
  myTasks: [],
  allTasks: [],
  allCallHistory: [],
  taskDetails: null,
  usersList: [],
  userInfo: null,
  callerTasks: [],
  callerCallHistory: []
};
const callcenterSlice = createSlice({
  initialState,
  name: 'callcenter',
  reducers: {
    resetCallcenterMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    setCallcenterCurrTab(state, action) {
      state.currTab = action.payload;
    },
    getCallAgentMyTasksRequest(state) {
      state.isLoading = true;
    },
    getCallAgentMyTasksSuccess(state, action) {
      state.isLoading = false;
      state.myTasks = action.payload.data.tasks;
    },
    getCallAgentMyTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.myTasks = [];
    },
    getCallAgentAllTasksRequest(state) {
      state.isLoading = true;
    },
    getCallAgentAllTasksSuccess(state, action) {
      state.isLoading = false;
      state.allTasks = action.payload.data.tasks;
    },
    getCallAgentAllTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.allTasks = [];
    },
    getCallAgentAllCallHistoryRequest(state) {
      state.isLoading = true;
    },
    getCallAgentAllCallHistorySuccess(state, action) {
      state.isLoading = false;
      state.allCallHistory = action.payload.data.calls;
    },
    getCallAgentAllCallHistoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.allCallHistory = [];
    },
    getCallCenterTaskDetailsRequest(state) {
      state.isLoading = true;
    },
    getCallCenterTaskDetailsSuccess(state, action) {
      state.isLoading = false;
      state.taskDetails = action.payload.data;
    },
    getCallCenterTaskDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCallCenterUsersRequest(state) {
      state.isLoading = true;
    },
    getCallCenterUsersSuccess(state, action) {
      state.isLoading = false;
      state.usersList = action.payload.data.users;
    },
    getCallCenterUsersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.usersList = [];
    },
    getCallCenterUserInfoRequest(state) {
      state.isLoading = true;
    },
    getCallCenterUserInfoSuccess(state, action) {
      state.isLoading = false;
      state.userInfo = action.payload.data.users;
    },
    getCallCenterUserInfoFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.userInfo = null;
    },
    addTaskCallCenterRequest(state) {
      state.isLoading = true;
    },
    addTaskCallCenterSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addTaskCallCenterFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskCallCenterRequest(state) {
      state.isLoading = true;
    },
    updateTaskCallCenterSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateTaskCallCenterFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCallerTasksRequest(state) {
      state.isLoading = true;
    },
    getCallerTasksSuccess(state, action) {
      state.isLoading = false;
      state.callerTasks = action.payload.data.tasks;
    },
    getCallerTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.callerTasks = [];
    },
    getCallerCallHistoryRequest(state) {
      state.isLoading = true;
    },
    getCallerCallHistorySuccess(state, action) {
      state.isLoading = false;
      state.callerCallHistory = action.payload.data.call_history;
    },
    getCallerCallHistoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.callerCallHistory = [];
    },
    addNoteRequest(state) {
      state.isLoading = true;
    },
    addNoteSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addNoteFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetCallcenterMsg,
  setCallcenterCurrTab,
  getCallAgentMyTasksFailure,
  getCallAgentMyTasksRequest,
  getCallAgentMyTasksSuccess,
  getCallAgentAllTasksFailure,
  getCallAgentAllTasksRequest,
  getCallAgentAllTasksSuccess,
  getCallAgentAllCallHistoryFailure,
  getCallAgentAllCallHistoryRequest,
  getCallAgentAllCallHistorySuccess,
  getCallCenterTaskDetailsFailure,
  getCallCenterTaskDetailsRequest,
  getCallCenterTaskDetailsSuccess,
  getCallCenterUsersFailure,
  getCallCenterUsersRequest,
  getCallCenterUsersSuccess,
  getCallCenterUserInfoFailure,
  getCallCenterUserInfoRequest,
  getCallCenterUserInfoSuccess,
  addTaskCallCenterFailure,
  addTaskCallCenterRequest,
  addTaskCallCenterSuccess,
  updateTaskCallCenterFailure,
  updateTaskCallCenterRequest,
  updateTaskCallCenterSuccess,
  getCallerTasksFailure,
  getCallerTasksRequest,
  getCallerTasksSuccess,
  getCallerCallHistoryFailure,
  getCallerCallHistoryRequest,
  getCallerCallHistorySuccess,
  addNoteFailure,
  addNoteRequest,
  addNoteSuccess
} = callcenterSlice.actions;

export default callcenterSlice.reducer;
