import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { useState } from 'react';
import { defaultValues } from '../../../../../constants';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewEducationalDetails = ({ employeeData }) => {
  const [currEmp, setCurrEmp] = useState(null);
  const [index, setIndex] = useState(null);

  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.education &&
        employeeData.education.length > 0 &&
        employeeData.education.map((item, idx) => (
          <Box key={idx}>
            <CollapseDropView
              title={item.qualification}
              handleSubTitleClick={() => {
                setCurrEmp(index === idx ? null : item);
                setIndex(index === idx ? null : idx);
              }}
              open={idx === index}
            />
            {currEmp && idx === index && (
              <>
                <ViewData title={getTitles('T-10644')} subTitle={currEmp.qualification} />
                <ViewData title={getTitles('T-10645')} subTitle={currEmp.institution_name} />
                <ViewData title={getTitles('T-10661')} subTitle={currEmp.institution_city} />
                <ViewData title={getTitles('T-10646')} subTitle={currEmp.university_name} />
                <ViewData title={getTitles('T-10661')} subTitle={currEmp.university_city} />

                <ViewData
                  title={getTitles('T-10478')}
                  subTitle={currEmp.student_id_or_registration_number}
                />
                <ViewData title={getTitles('T-10647')} subTitle={currEmp.percentage} />
                <ViewData
                  title={getTitles('T-10648')}
                  subTitle={
                    currEmp.program &&
                    defaultValues.educationProgram.find((item) => item.value == currEmp.program)
                      .title
                  }
                />
                <ViewData
                  title={getTitles('T-10651')}
                  subTitle={currEmp.start_date && getFormatDate(currEmp.start_date)}
                />
                <ViewData
                  title={getTitles('T-10652')}
                  subTitle={currEmp.end_date && getFormatDate(currEmp.end_date)}
                />
              </>
            )}
          </Box>
        ))}

      {employeeData && employeeData.course_details && (
        <>
          <ViewData title={getTitles('T-10653')} isSubTitle={false} containerStyle={{ mt: 1 }} />
          <ViewData title={getTitles('T-10460')} subTitle={employeeData.course_details.name} />
          <ViewData
            title={getTitles('T-10003')}
            subTitle={employeeData.course_details.contact_number}
          />
          <ViewData
            title={getTitles('T-10463')}
            subTitle={
              employeeData.course_details.date_of_renewal &&
              getFormatDate(employeeData.course_details.date_of_renewal)
            }
          />
          <ViewData title={getTitles('T-10462')} subTitle={employeeData.course_details.duration} />
        </>
      )}

      <ViewData
        title={getTitles('T-10650')}
        subTitle={employeeData && employeeData.state_reasons_gap}
      />
    </Box>
  );
};
