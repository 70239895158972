import { Box } from '@mui/material';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVenderRequest,
  getStateRequest,
  setVenderActivepage,
  updateVenderRequest
} from '../../../../services/redux/slices';
import { styles } from './styles';
import { UIButton, UIPhoneInputTitle, UITextInputWithTitle } from '../../../../ui-kits';
import { getTitles, vendorSchema } from '../../../../utils';
import { useFormik } from 'formik';
import {
  getVenderActivePage,
  getVenderDetails
} from '../../../../services/redux/selectors/manageProccess';
import { defaultValues } from '../../../../constants';
import { Address } from '../../../common/address';
import EditIcon from '@mui/icons-material/Edit';
import { getDetailsPg } from '../../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';

const res = defaultValues.isResponsive;

export const AddEditVendor = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const activePage = useSelector(getVenderActivePage);
  const detailsPg = useSelector(getDetailsPg);
  const venderDetails = useSelector(getVenderDetails);

  const [btnDisabled, setBtndisabled] = useState(true);

  const { add, edit, view } = defaultValues.actionType;

  const { values, errors, touched, handleBlur, setValues, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        first_name: '',
        last_name: '',
        middle_name: '',
        phone_number: '',
        address: {
          country_id: '',
          state_id: { _id: '' },
          district_id: { _id: '' },
          taluka_id: { _id: '' },
          village_id: { _id: '' },
          post_code: ''
        }
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: vendorSchema
    });

  useEffect(() => {
    dispatch(getStateRequest({ page: 1 }));
  }, []);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    if (venderDetails) {
      setValues(venderDetails);
    }
  }, [venderDetails]);

  const handleBackButton = () => {
    dispatch(setVenderActivepage(null));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      address: {
        ...values.address,
        state_id: values.address?.state_id?._id,
        district_id: values.address?.district_id?._id,
        taluka_id: values.address?.taluka_id?._id,
        village_id: values.address?.village_id?._id || null
      }
    };
    activePage === add && dispatch(addVenderRequest(payload));
    activePage === edit && dispatch(updateVenderRequest(payload));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.backCont }}>
        {!res && <UIBackButton onClick={handleBackButton} />}
        {activePage === view && res && !isReadOnly && (
          <UIButton
            style={styles.editBtn}
            startIcon={<EditIcon />}
            onClick={() => dispatch(setVenderActivepage(edit))}
          />
        )}
      </Box>
      <Box sx={styles.main}>
        <Box sx={styles.editCont}>
          {activePage === view && !res && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              style={styles.editBtn}
              startIcon={<EditIcon />}
              onClick={() => dispatch(setVenderActivepage(edit))}
            />
          )}
        </Box>

        <Box sx={styles.subCont}>
          <UITextInputWithTitle
            name="first_name"
            title={getTitles('T-10041')}
            placeHolder={getTitles('T-10047')}
            error={touched.first_name && errors.first_name}
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            width={styles.width}
            maxLength={10}
            allowOnlyAlphabets={true}
            readOnly={activePage === view}
          />
          <UITextInputWithTitle
            name="middle_name"
            title={getTitles('T-10337')}
            placeHolder={getTitles('T-10338')}
            error={touched.middle_name && errors.middle_name}
            value={values.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            maxLength={10}
            allowOnlyAlphabets={true}
            readOnly={activePage === view}
          />

          <UITextInputWithTitle
            name="last_name"
            title={getTitles('T-10042')}
            placeHolder={getTitles('T-10048')}
            value={values.last_name}
            error={touched.last_name && errors.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            width={styles.width}
            maxLength={10}
            allowOnlyAlphabets={true}
            readOnly={activePage === view}
          />
        </Box>

        {[edit, view].includes(activePage) && (
          <UITextInputWithTitle
            name="vendor_id"
            title={getTitles('T-11448')}
            value={values.vendor_id}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            readOnly={true}
          />
        )}

        <UIPhoneInputTitle
          phoneValue={values.phone_number}
          onChange={(val) => {
            setFieldValue('phone_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          required={true}
          width={styles.width}
          disabled={activePage === view}
        />

        <Address
          actionType={activePage}
          objects={values?.address}
          divider={false}
          type={'location'}
          showPostCode={true}
          isCountryRequired={true}
          isStateRequired={true}
          isDistrictRequired={true}
          isTalukaRequired={true}
          isPostCodeRequired={true}
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'address.country_id', value: e.target.value } })
          }
          onCountryBlurred={handleBlur('address.country_id')}
          countryCodeError={touched.address?.country_id && errors.address?.country_id}
          onStateSelected={(val) => {
            handleChange({ target: { name: 'address.state_id', value: val ? val : { _id: '' } } });
          }}
          onStateBlurred={handleBlur('address.state_id')}
          stateCodeError={touched.address?.state_id && errors.address?.state_id?._id}
          onDistrictSelected={(val) =>
            handleChange({
              target: {
                name: 'address.district_id',
                value: val ? val : { _id: '' }
              }
            })
          }
          onDistrictBlurred={handleBlur('address.district_id')}
          districtCodeError={touched.address?.district_id && errors.address?.district_id?._id}
          onTalukaSelected={(val) =>
            handleChange({ target: { name: 'address.taluka_id', value: val ? val : { _id: '' } } })
          }
          onTalukaBlurred={handleBlur('address.taluka_id')}
          talukaCodeError={touched.address?.taluka_id && errors.address?.taluka_id?._id}
          onVillageSelected={(val) =>
            handleChange({ target: { name: 'address.village_id', value: val ? val : { _id: '' } } })
          }
          onPostCodeBlurred={handleBlur('address.post_code')}
          postCodeError={touched.address?.post_code && errors.address?.post_code}
          onPostCodeChange={(val) => {
            handleChange({ target: { name: 'address.post_code', value: val.target.value } });
          }}
        />
        {activePage !== view && (
          <ModalButtons
            onPrimaryBtnClick={handleSubmit}
            primaryBtnTitle={
              activePage === add
                ? getTitles('T-11170')
                : activePage === edit && getTitles('T-10046')
            }
            disabled={!isValid || !dirty || btnDisabled}
            containerStyle={globalStyles.mt(3)}
          />
        )}
      </Box>
    </Box>
  );
};
