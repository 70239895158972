import { useState } from 'react';
import { Text } from '../text';
import { color } from '../../theme';
import { UIChipButton } from '../chip-button';
import { Box } from '@mui/material';
import { getTitles } from '../../utils';

export const ChipButtonWithTitle = ({ title, data, bgColor, maxItemsToShow, style }) => {
  const [showAll, setShowAll] = useState(false);

  const visibleData = showAll ? data : data && data.slice(0, maxItemsToShow);

  const handleViewAll = () => {
    setShowAll(true);
  };

  const styles = {
    container: { display: 'flex', flexWrap: 'wrap', alignItems: 'center' },
    textStyle: { color: color.link, textDecoration: 'underline', cursor: 'pointer' }
  };

  return (
    <>
      <Text variant="D22">{title}</Text>
      <Box sx={styles.container}>
        {visibleData &&
          visibleData.map((item, index) => (
            <UIChipButton
              key={index}
              title={item.name}
              backgroundColor={bgColor}
              style={{ ...style }}
            />
          ))}
        {data && data.length > maxItemsToShow && !showAll && (
          <Text style={styles.textStyle} onClick={handleViewAll}>
            {getTitles('T-10415')}
          </Text>
        )}
      </Box>
    </>
  );
};
