import React from 'react';
import { Box, Modal } from '@mui/material';
import warning from '../../assets/images/warning.png';
import { defaultValues } from '../../constants';
import { Text } from '../text';
import { UIButton } from '../button';
import { Styles } from './style';
import { getTitles } from '../../utils';

export const AlterModal = ({
  open,
  onClose,
  title,
  subTitle,
  btnType = 'OK',
  onConfirm,
  onCancel,
  modalType,
  rightBtnDisable = false
}) => {
  return (
    <div>
      <Modal open={open} onClose={onClose} sx={Styles.modal}>
        <Box sx={Styles.container(defaultValues.isResponsive)}>
          <img src={warning} style={Styles.imageStyle} />
          <Text variant="boldH32" style={Styles.title}>
            {title}
          </Text>
          <Text variant="D20" style={Styles.subTitle}>
            {subTitle}
          </Text>
          <Box sx={Styles.buttonContainer(modalType)}>
            {onCancel && (
              <UIButton
                style={Styles.cancelBtn}
                variant="secondary"
                onClick={onCancel}
                title={getTitles('T-10045')}
              />
            )}
            <UIButton
              title={btnType}
              style={Styles.successBtn}
              disabled={rightBtnDisable}
              onClick={onConfirm}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
