import { Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Text } from '../../../ui-kits';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';

export const SaveAndAddAnother = ({ onAddAnother }) => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: defaultValues.isMobile && 'center',
      mb: 1
    },
    txtStyle: { color: color.link, ml: 1, cursor: 'pointer' }
  };
  return (
    <Box sx={styles.container}>
      <AddCircleIcon sx={styles.txtStyle} onClick={onAddAnother} />
      <Text variant="D22" style={styles.txtStyle} onClick={onAddAnother}>
        Save & add another
      </Text>
    </Box>
  );
};
