import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addInsuranceFailure,
  addInsuranceRequest,
  addInsuranceSuccess,
  getInsuranceFailure,
  getInsuranceSuccess,
  updateInsuranceFailure,
  updateInsuranceRequest,
  updateInsuranceSuccess,
  getCompanySuccess,
  getCompanyFailure,
  getCompanyRequest,
  getInsurancesRequest
} from '../slices';
import { addInsuranceAPI, getMasterListAPI, getInsuranceAPI, updateInsuranceAPI } from '../../api';

function* addInsurance(action) {
  try {
    const response = yield addInsuranceAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addInsuranceSuccess(response.data));
    } else {
      yield put(addInsuranceFailure(response.data));
    }
  } catch (error) {
    yield put(addInsuranceFailure(error.response.data));
  }
}

function* updateInsurance(action) {
  try {
    const response = yield updateInsuranceAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateInsuranceSuccess(response.data));
    } else {
      yield put(updateInsuranceFailure(response.data));
    }
  } catch (error) {
    yield put(updateInsuranceFailure(error.response.data));
  }
}

function* getInsurance(action) {
  try {
    const response = yield getInsuranceAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getInsuranceSuccess(response.data));
    } else {
      yield put(getInsuranceFailure(response.data));
    }
  } catch (error) {
    yield put(getInsuranceFailure(error.response.data));
  }
}

function* getInsuranceCompany(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCompanySuccess(response.data));
    } else {
      yield put(getCompanyFailure(response.data));
    }
  } catch (error) {
    yield put(getCompanyFailure(error.response.data));
  }
}

export function* insuranceSaga() {
  yield takeLatest(addInsuranceRequest.type, addInsurance);
  yield takeLatest(updateInsuranceRequest.type, updateInsurance);
  yield takeLatest(getInsurancesRequest.type, getInsurance);
  yield takeLatest(getCompanyRequest.type, getInsuranceCompany);
}
