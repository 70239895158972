import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  longWidth: res ? '100%' : 'calc(((100% - 10px)/3)*2)',
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },
  remindInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px',
    marginTop: '0px'
  },
  dateCont: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    }
  },
  dateInput: {
    '& .MuiInputBase-input': {
      width: 'calc(100% - 20px)',
      fontSize: res ? '14px' : '16px',
      padding: res ? '9.5px 7px' : '3px 14px'
    }
  },
  nameCont: {
    width: '80%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  repeatCont: {
    width: '90%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  radioSubCont: {
    width: res ? '100%' : 'calc((100% - 10px)/3)',
    display: 'flex',
    gap: '40px'
  },
  btn: (isLast) => ({
    width: '30px',
    height: '30px',
    minWidth: 0,
    fontSize: '1.35rem',
    marginTop: '10px',
    padding: 0,
    visibility: isLast ? 'hidden' : 'none'
  }),
  remindCont: {
    width: 'calc(80% + 40px)',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px',
    alignItems: 'center'
  },
  remaindSub: {
    width: '100%'
  },
  cardCont: {
    width: 'calc((100% - 30px)/3)'
  },
  detailsCont: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px'
  }
};
