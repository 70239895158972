import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;

export const styles = {
  container: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: 'fit-content',
    p: sm ? 0.5 : 2,
    px: sm ? 4 : 6,
    mt: 2,
    boxSizing: 'border-box',
    backgroundColor: color.palette.paleYellow
  },
  carouselWrapper: {
    display: 'flex',
    overflowX: 'auto',
    height: '100%',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none'
  },
  carouselInner: {
    display: 'flex',
    flexDirection: 'row'
  },
  imageContainer: {
    flexShrink: 0,
    height: 'fit-content',
    scrollSnapAlign: 'start',
    cursor: 'pointer'
  },
  leftIcon: {
    position: 'absolute',
    left: 4,
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  },
  rightIcon: {
    position: 'absolute',
    right: 4,
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  },
  imageTitle: {
    marginTop: '8px',
    textAlign: 'center'
  },
  img: {
    width: res ? '30px' : '70px',
    height: res ? '30px' : '70px'
  },
  imgCont: (imageWidth) => ({
    width: imageWidth,
    objectFit: 'cover'
  }),
  imgSubCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
};
