import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { color } from '../../../theme';
import { Text, UITextInputWithTitle } from '../../../ui-kits';
import { defaultValues, SCREEN_PATH } from '../../../constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalButtons } from '../../common/modal-buttons';
import { getTitles } from '../../../utils';
import { getCattleActionType } from '../../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ReportTable = ({
  title,
  items,
  onChange,
  sectionId,
  disabled = false,
  onPrev,
  accordionIndex,
  openAccordion,
  setOpenAccordion,
  onNext,
  isLastAccordion,
  onsubmit
}) => {
  const { view, edit } = defaultValues.actionType;
  const navigation = useNavigate();

  const actionType = useSelector(getCattleActionType);

  const handleSubmit = () => {
    if (isLastAccordion) {
      if (actionType === view) {
        navigation(SCREEN_PATH.MANAGE_CATTLE);
      } else {
        onsubmit();
      }
    } else {
      onNext();
    }
  };

  const styles = {
    container: { width: '100%' },
    subC: {
      display: 'flex',
      height: '65px',
      backgroundColor: color.palette.paleYellow,
      borderRadius: '5px',
      alignItems: 'center',
      boxSizing: 'border-box',
      mt: 2,
      mb: 1
    },
    flex: { flex: 2, p: 1 },
    flexOne: { flex: 1, p: 1 },
    d: { display: 'flex' },
    itemC: {
      flex: 3,
      border: `1px solid ${color.primaryBorder}`,
      height: '50px',
      mr: 1,
      mt: '10px',
      mb: '5px',
      borderRadius: '5px',
      boxSizing: 'border-box',
      display: 'flex'
    },
    details: { display: 'flex', justifyContent: 'space-between' },
    field: { width: 'calc(48% - 5px)' },
    mb: { mb: 1 },
    my: { my: 1 },
    fll: { flex: 1 }
  };

  return (
    <>
      {!defaultValues.isResponsive ? (
        <Box sx={styles.container}>
          <Box sx={styles.subC}>
            <Text style={styles.flex}>{title}</Text>
            <Text style={styles.flexOne}>Normal val</Text>
            <Text style={styles.flexOne}>Sample val</Text>
          </Box>
          {items &&
            items.map((item, index) => (
              <Box sx={styles.d} key={index}>
                <Box sx={styles.itemC}>
                  <Text style={styles.flex}>{item.field_name}</Text>
                  <Text style={styles.flexOne}>{`${item.min_value} - ${item.max_value}`}</Text>
                </Box>
                <Box sx={styles.fll}>
                  <UITextInputWithTitle
                    onChange={(val) => {
                      onChange(val.target.value, sectionId, item._id);
                    }}
                    value={item.sample_value}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            ))}
        </Box>
      ) : (
        <Accordion sx={styles.my} expanded={openAccordion === accordionIndex}>
          <AccordionSummary
            sx={{ backgroundColor: color.palette.paleYellow }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            onClick={() =>
              setOpenAccordion(openAccordion === accordionIndex ? -1 : accordionIndex)
            }>
            {title}
          </AccordionSummary>
          <AccordionDetails>
            {items &&
              items.map((item, index) => (
                <Box key={index} sx={styles.mb}>
                  <Text variant="D20">{item.field_name}</Text>
                  <Box sx={styles.details}>
                    <UITextInputWithTitle
                      title={'Sample val'}
                      value={item.sample_value}
                      disabled={disabled}
                      onChange={(val) => {
                        onChange(val.target.value, sectionId, item._id);
                      }}
                      containerStyles={styles.field}
                    />
                    <UITextInputWithTitle
                      title={'Normal val'}
                      value={`${item.min_value} - ${item.max_value}`}
                      containerStyles={styles.field}
                      disabled
                    />
                  </Box>
                </Box>
              ))}

            <ModalButtons
              btnPositions={defaultValues.isResponsive ? 'center' : 'start'}
              secondaryBtnTitle={getTitles('T-10340')}
              disabled={false}
              onSecondaryBtnClick={() => onPrev()}
              onPrimaryBtnClick={handleSubmit}
              primaryBtnTitle={
                actionType === edit
                  ? getTitles('T-10733')
                  : actionType === view
                    ? getTitles(isLastAccordion ? 'T-11103' : 'T-10804')
                    : getTitles('T-10339')
              }
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
