import { Box, IconButton } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { color } from '../../theme';
import { Text } from '../text';
import { getTitles } from '../../utils';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import Delete from '../../assets/icons/IcDelete.png';
import Image from '../../assets/icons/backup.png';
import { defaultValues } from '../../constants';

const isRes = defaultValues.isMobile;

export const MultiImage = ({
  title,
  images,
  onImagesChange,
  prevImg,
  imageToDelete,
  disabled = false
}) => {
  const fileInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState(images || []);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (prevImg) {
      const imagePreviews = images.map((img) => ({
        file: null,
        url: typeof img === 'string' ? img : URL.createObjectURL(img)
      }));
      setSelectedImages(imagePreviews);
    }
  }, [prevImg]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const filePreviews = files.map((file) => ({
      file,
      url: URL.createObjectURL(file)
    }));
    const newImages = [...selectedImages, ...filePreviews];
    setSelectedImages(newImages);
    setCurrentIndex(newImages.length - 1); // Set the currentIndex to the last added image
    onImagesChange(newImages.map((img) => img.file || img.url));

    // Clear the file input value to allow re-adding the same file
    event.target.value = null;
  };

  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1));
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1));
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    const imageToBeDeleted = selectedImages[currentIndex].url;
    if (imageToDelete) {
      imageToDelete(imageToBeDeleted);
    }
    const newImages = selectedImages.filter((_, index) => index !== currentIndex);
    setSelectedImages(newImages);
    setCurrentIndex(0);
    onImagesChange(newImages.map((img) => img.file || img.url));
  };

  const Styles = {
    fieldCont: {
      width: isRes ? '125px' : '250px',
      height: isRes ? '125px' : '250px',
      border: '3px dashed',
      borderColor: color.primary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px',
      position: 'relative'
    },
    sub: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer'
    },
    imgIcon: { width: '25px', height: '20px' },
    Img: { width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' },
    leftIcon: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      color: color.tertiaryText,
      backgroundColor: 'inherit'
    },
    right: {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      color: color.tertiaryText,
      backgroundColor: 'inherit'
    },
    iconCont: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      minHeight: '30px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    bubblesCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    bubble: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: color.tertiaryText,
      margin: '0 2px',
      transition: 'transform 0.3s ease'
    },
    activeBubble: {
      transform: 'scale(1.5)' // Highlight the current bubble with extra size
    },
    deIcon: { width: 'auto', display: 'flex' }
  };

  return (
    <Box>
      <Text variant="D22">{title}</Text>
      <Box sx={Styles.fieldCont}>
        <input
          type="file"
          accept="image/*" // Restrict to image files
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          disabled={disabled}
        />
        {selectedImages.length === 0 ? (
          <Box onClick={() => !disabled && fileInputRef.current.click()} sx={Styles.sub}>
            <img src={Image} style={Styles.imgIcon} />
            <Text style={{ color: color.secondaryText }} variant="D16">
              {getTitles('T-10306')}
            </Text>
            <Text style={{ color: color.secondaryText }} variant="D16">
              {getTitles('T-10305')}
            </Text>
          </Box>
        ) : (
          <>
            <img
              src={selectedImages[currentIndex]?.url}
              alt={`Selected image ${currentIndex + 1}`}
              style={Styles.Img}
            />
            {selectedImages.length > 1 && (
              <>
                <IconButton onClick={handlePrevClick} sx={Styles.leftIcon}>
                  <ArrowBackIosSharpIcon />
                </IconButton>
                <IconButton onClick={handleNextClick} sx={Styles.right}>
                  <ArrowForwardIosSharpIcon />
                </IconButton>
              </>
            )}

            <Box sx={Styles.iconCont}>
              <Box sx={Styles.bubblesCont}>
                {selectedImages.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...Styles.bubble,
                      ...(index === currentIndex ? Styles.activeBubble : {})
                    }}
                  />
                ))}
              </Box>
              {!disabled && (
                <Box sx={Styles.deIcon}>
                  <IconButton onClick={handleDeleteClick} sx={{ color: color.tertiaryText }}>
                    <img src={Delete} />
                  </IconButton>
                  <IconButton onClick={handleAddClick} sx={{ color: color.tertiaryText }}>
                    <AddBoxSharpIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
