import { Avatar, Box } from '@mui/material';
import { color } from '../../../theme';
import { Text } from '../../../ui-kits';

export const ProfileWithName = ({ title, photoUrl, onTitleClick }) => {
  const styles = {
    name: {
      textDecoration: 'underline',
      ml: 1,
      cursor: 'pointer',
      overflowWrap: 'anywhere',
      '&:hover': {
        color: color.link
      }
    },
    container: { display: 'flex', alignItems: 'center' }
  };
  return (
    <Box sx={styles.container}>
      <Avatar alt="profile" src={photoUrl} />
      <Text variant="D20" style={styles.name} onClick={onTitleClick}>
        {title}
      </Text>
    </Box>
  );
};
