import { Box } from '@mui/material';
import { styles } from './styles';

export const Fallback = () => {
  return (
    <Box sx={styles.fallbackContainer}>
      <Box x={styles.contentContainer}>
        <Box x={styles.statusText}>404</Box>
        <Box x={styles.oopsText}>Ooops!!!</Box>
        <Box x={styles.message}>{"That page doesn't exist or unavailable"}</Box>
      </Box>
    </Box>
  );
};
