import { createSelector } from '@reduxjs/toolkit';

export const getTasksLoading = createSelector([(state) => state.tasks.isLoading], (d) => d);
export const getTasksSuccessMsg = createSelector([(state) => state.tasks.successMessage], (d) => d);
export const getTasksErrorMsg = createSelector([(state) => state.tasks.errorMessage], (d) => d);

export const getTasksCurPage = createSelector([(state) => state.tasks.currentPage], (d) => d);
export const getTasksNxtPage = createSelector([(state) => state.tasks.nextPage], (d) => d);
export const getTasksTotalPage = createSelector([(state) => state.tasks.totalPage], (d) => d);

export const getTasksCurrTab = createSelector([(state) => state.tasks.currRender], (d) => d);
export const getCommonTasksDetails = createSelector([(state) => state.tasks.taskDetails], (d) => d);
export const getMytaskList = createSelector([(state) => state.tasks.myTasks], (d) => d);

export const getAssocFarmer = createSelector([(state) => state.tasks.associatedFarmers], (d) => d);
export const getAssocCurPage = createSelector([(state) => state.tasks.assocCurrentPage], (d) => d);
export const getAssocNxtPage = createSelector([(state) => state.tasks.assocNextPage], (d) => d);
export const getAssocTotalPage = createSelector([(state) => state.tasks.assocTotalPage], (d) => d);
