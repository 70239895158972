import { Box, TextField } from '@mui/material';
import { color } from '../../theme/color';
import { Text } from '../text';
import { regExp } from '../../constants/regex';
import { defaultValues } from '../../constants';

export const UITextInputWithTitle = ({
  name,
  title,
  placeHolder,
  value,
  error,
  onBlur,
  onChange,
  style = {},
  rows = 1,
  variant = 'D20',
  size = defaultValues.isResponsive ? 'small' : 'medium',
  required = false,
  allowOnlyAlphabets = false,
  allowOnlyNumbers = false,
  allowDecimal = false,
  allowNumberWithSpecialChar = false, // new prop
  disabled,
  containerStyles,
  maxLength,
  width,
  titleStyle,
  inputStyle,
  ...rest
}) => {
  const defaultStyles = {
    inputContainer: (containerStyles) => ({
      width: width || '100%',
      marginTop: '10px',
      ...containerStyles
    }),
    title: (style) => ({
      marginBottom: '5px',
      ...style
    }),
    input: (style) => ({
      boxShadow: 0,
      width: '100%',
      borderRadius: '8px',
      '& .MuiInputBase-root': {
        height: rows === 1 ? (defaultValues.isResponsive ? '42px' : '50px') : 'auto'
      },
      '& .Mui-disabled': {
        cursor: 'not-allowed'
      },
      ...style
    }),
    asterisk: {
      color: color.errorText
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmed = inputValue.trimStart();

    if (trimmed !== inputValue) {
      return;
    }

    if (allowOnlyAlphabets) {
      if (inputValue === '' || regExp.alphabetRegExp.test(inputValue)) {
        handleInputChangeLogic(e);
      }
    } else if (allowOnlyNumbers) {
      if (inputValue === '' || regExp.onlyNumbers.test(inputValue)) {
        handleInputChangeLogic(e);
      }
    } else if (allowDecimal) {
      if (
        inputValue === '' ||
        regExp.decimalNum.test(inputValue) ||
        regExp.point.test(inputValue)
      ) {
        handleInputChangeLogic(e);
      }
    } else if (allowNumberWithSpecialChar) {
      if (inputValue === '' || regExp.numberWithSpecialChar.test(inputValue)) {
        handleInputChangeLogic(e);
      }
    } else {
      handleInputChangeLogic(e);
    }
  };

  const handleInputChangeLogic = (e) => {
    const inputValue = e.target.value;
    if (maxLength && inputValue.length > maxLength) {
      return;
    }
    onChange(e);
  };

  return (
    <Box sx={styles.inputContainer(containerStyles)}>
      {title && (
        <Text variant={variant} style={styles.title(titleStyle)}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <TextField
        required={required}
        name={name}
        variant="outlined"
        placeholder={disabled ? '-' : placeHolder}
        value={value}
        error={error}
        size={size}
        onChange={handleInputChange}
        sx={styles.input(inputStyle)}
        onBlur={onBlur}
        multiline
        rows={rows}
        maxRows={1}
        disabled={disabled}
        InputProps={{
          style: { cursor: disabled ? 'no-drop' : 'auto' }
        }}
        {...rest}
      />
      {error && <Text variant="fieldError">{error}</Text>}
    </Box>
  );
};
