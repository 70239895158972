export const styles = {
  fallbackContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    width: '70%',
    height: '50%',
    textAlign: 'center'
  },
  statusText: {
    textAlign: 'center',
    fontSize: '7rem',
    fontWeight: 'bold',
    fontFamily: 'math'
  },
  oopsText: {
    fontSize: '2rem',
    fontWeight: '400',
    textAlign: 'center'
  },
  message: {
    fontSize: '1.5rem',
    color: 'red',
    marginBottom: '30px'
  }
};
