import React, { useEffect, useRef } from 'react';
import { Autocomplete, Box, List, Paper, TextField } from '@mui/material';
import { color } from '../../theme/color';
import { Text } from '../text';
import { typography } from '../../theme/fonts';
import { UIButton } from '../button';
import { ListItemContent } from '@mui/joy';
import { defaultValues } from '../../constants';

export const UIAutocompleted = ({
  name,
  title,
  placeHolder,
  options = [],
  onChange,
  onAdd,
  // touched,
  onBlur,
  error,
  errorStyle,
  required,
  onTyping,
  style,
  addShowButton = false,
  selectedValue,
  optionLabel,
  isLoading = false,
  variant = 'D20',
  onScrollEnd,
  preSelectedValue,
  titleStyle,
  containerStyle,
  disabled = false,
  autoCompleteStyle,
  PaperBoxStyle,
  textInputStyles,
  inpRef,
  ...rest
}) => {
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    if (preSelectedValue) {
      setValue(preSelectedValue);
    } else {
      setValue('');
    }
  }, [preSelectedValue]);

  const defaultStyles = {
    mainBox: (styles) => ({
      width: '100%',
      marginTop: '25px',
      ...styles
    }),
    boxStyle: (style) => ({
      boxShadow: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      backgroundColor: color.primaryBackground,
      ...style
    }),
    autoStyle: {
      width: '100%',
      border: 'none',
      ...autoCompleteStyle
    },
    textInput: (style) => ({
      '& .MuiInputBase-root': {
        height: defaultValues.isResponsive ? '42px' : '50px',
        padding: 0,
        paddingLeft: '5px'
      },
      '& .MuiInputBase-input': {
        fontSize: window.innerWidth < 900 ? '14px' : '18px'
      },
      '& .MuiPaper-root': {
        paddingRight: '15px',
        padding: 0
      },
      fontFamily: typography.primary,
      width: '100%',
      ...style
    }),
    btnStyle: { padding: '5px 20px' },
    mB: { marginBottom: '10px', ...titleStyle },
    errorStyles: {
      marginTop: '8px',
      ...errorStyle
    },
    asterisk: {
      color: color.errorText
    }
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  const listRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      onScrollEnd && onScrollEnd();
    }
  };

  return (
    <Box sx={defaultStyles.mainBox(containerStyle)}>
      {title && (
        <Text variant={variant} style={defaultStyles.mB}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <Paper
        component="form"
        sx={{
          ...styles.boxStyle(PaperBoxStyle),
          border: error ? `1px solid ${color.errorText}` : `1px solid ${color.transparent}`
        }}>
        <Autocomplete
          ref={inpRef}
          clearOnEscape={false}
          disabled={disabled}
          value={value}
          sx={styles.autoStyle}
          options={options}
          getOptionLabel={optionLabel && optionLabel}
          noOptionsText={isLoading ? 'Loading...' : 'No options'}
          {...rest}
          ListboxComponent={(props) => {
            const { children, ...rest } = props;
            return (
              <List ref={listRef} onScroll={handleScroll} {...rest}>
                {React.Children.map(children, (child) => (
                  <ListItemContent sx={{ '&:hover': { backgroundColor: color.primaryBackground } }}>
                    {child}
                  </ListItemContent>
                ))}
              </List>
            );
          }}
          renderInput={(params) => (
            <TextField
              required={required}
              placeholder={placeHolder}
              name={name}
              value={value}
              onChange={(e) => onTyping && onTyping(e.target.value)}
              sx={styles.textInput(textInputStyles)}
              onBlur={onBlur}
              InputLabelProps={{ shrink: false }}
              {...params}
            />
          )}
          onChange={(event, newValue) => {
            onChange && onChange(newValue);
            setValue(newValue);
            selectedValue && selectedValue(newValue);
          }}
        />
        {addShowButton && (
          <UIButton
            title="Add"
            style={styles.btnStyle}
            onClick={() => {
              onAdd(value);
              setValue('');
            }}
          />
        )}
      </Paper>
      {error && (
        <Text style={styles.errorStyles} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
