import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { useEffect, useRef, useState } from 'react';
import { defaultValues } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDistrict,
  getDistrictNextPage,
  getTaluka,
  getTalukaNextPage,
  getVillage,
  getVillageNextPage
} from '../../../../../services/redux/selectors/manageProccess';
import {
  getDistrictRequest,
  getTalukRequest,
  getVillageRequest
} from '../../../../../services/redux/slices';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewPersonel = ({ employeeData, stateList }) => {
  const dispatch = useDispatch();

  const districts = useSelector(getDistrict);
  const talukas = useSelector(getTaluka);
  const villages = useSelector(getVillage);
  const districtNxtPg = useSelector(getDistrictNextPage);
  const villageNxtPge = useSelector(getVillageNextPage);
  const talukNxtPge = useSelector(getTalukaNextPage);

  const [districtList, setDistrictsList] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);

  const previousOptions = useRef({
    district: districtList?.length,
    village: villagesList?.length,
    taluka: talukasList?.length,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    const prev = previousOptions.current;
    if (districtList?.length !== prev?.district?.length) {
      deduplicateAndSet(districtList, districts, setDistrictsList);
    }
    if (talukasList?.length !== prev?.taluka?.length) {
      deduplicateAndSet(talukasList, talukas, setTalukasList);
    }
    if (villagesList?.length !== prev?.village?.length) {
      deduplicateAndSet(villagesList, villages, setVillagesList);
    }
    if (districtNxtPg !== prev?.districtNxtPage && districtNxtPg !== null) {
      dispatch(getDistrictRequest({ state_id: employeeData?.state_id, page: districtNxtPg }));
    }
    if (talukNxtPge !== prev?.talukaNextPage && talukNxtPge !== null) {
      dispatch(getTalukRequest({ district_id: employeeData?.district_id, page: talukNxtPge }));
    }

    if (villageNxtPge !== prev?.villageNxtPage && villageNxtPge !== null) {
      dispatch(getVillageRequest({ taluka_id: employeeData?.taluka_id, page: villageNxtPge }));
    }

    previousOptions.current = {
      district: districtList?.length,
      village: villagesList?.length,
      taluka: talukasList?.length,
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districts, talukas, villages, districtNxtPg, villageNxtPge, talukNxtPge]);

  useEffect(() => {
    if (employeeData && stateList && stateList.length > 0) {
      dispatch(getDistrictRequest({ state_id: employeeData.state_id, page: 1 }));
      dispatch(getTalukRequest({ district_id: employeeData.district_id, page: 1 }));
      dispatch(getVillageRequest({ taluka_id: employeeData.taluka_id, page: 1 }));
    }
  }, [employeeData, stateList]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems);
  };
  return (
    <Box sx={styles.container}>
      {employeeData && (
        <>
          <ViewData title={getTitles('T-10438')} subTitle={employeeData.employee_id} />
          <ViewData
            title={getTitles('T-10439')}
            subTitle={`${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`}
          />
          <ViewData title={getTitles('T-10440')} subTitle={employeeData.phone_number} />
          <ViewData title={getTitles('T-10441')} subTitle={employeeData.secondary_phone_number} />
          <ViewData title={getTitles('T-10043')} subTitle={employeeData.email_id} />
          <ViewData title={getTitles('T-10342')} subTitle={employeeData.secondary_email_id} />
          <ViewData
            title={getTitles('T-10344')}
            subTitle={employeeData.date_of_birth && getFormatDate(employeeData.date_of_birth)}
          />
          <ViewData
            title={getTitles('T-10351')}
            subTitle={
              employeeData.gender &&
              defaultValues.genderOptions.find((item) => item.value == employeeData.gender).title
            }
          />
          <ViewData title={getTitles('T-10356')} subTitle={employeeData.nationality} />
          <ViewData title={getTitles('T-10436')} subTitle={employeeData.voter_id} />
          <ViewData title={getTitles('T-10437')} subTitle={employeeData.ration_card_number} />
          <ViewData
            title={getTitles('T-10352')}
            subTitle={
              employeeData.marital_status &&
              defaultValues.maritalStatus.find((item) => item.value == employeeData.marital_status)
                .title
            }
          />
          <ViewData title={getTitles('T-10353')} subTitle={employeeData.aadhaar_number} />
          <ViewData title={getTitles('T-10354')} subTitle={employeeData.driving_license_number} />
          <ViewData title={getTitles('T-10355')} subTitle={employeeData.pan_card_number} />
          <ViewData title={getTitles('T-10374')} subTitle={employeeData.highest_qual} />

          <ViewData title={getTitles('T-10375')} subTitle={employeeData.years_experience} />

          <ViewData
            title={getTitles('T-10442')}
            subTitle={
              (employeeData.address_line_one || employeeData.address_line_two) &&
              `${employeeData.address_line_one} ${employeeData.address_line_two}`
            }
          />
          <ViewData title={getTitles('T-10363')} subTitle={employeeData.country_id} />
          <ViewData
            title={getTitles('T-10070')}
            subTitle={stateList?.find((elem) => elem._id === employeeData?.state_id)?.name}
          />
          <ViewData
            title={getTitles('T-10069')}
            subTitle={districtList?.find((elem) => elem._id === employeeData?.district_id)?.name}
          />
          <ViewData
            title={getTitles('T-10071')}
            subTitle={talukasList?.find((elem) => elem._id === employeeData?.taluka_id)?.name}
          />
          <ViewData
            title={getTitles('T-10068')}
            subTitle={villagesList?.find((elem) => elem._id === employeeData?.village_id)?.name}
          />
          <ViewData title={getTitles('T-10359')} subTitle={employeeData.post_code} />
        </>
      )}
    </Box>
  );
};
