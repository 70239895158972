import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles } from '../../../../utils';
import {
  CollapseDropView,
  DatePickers,
  DragDrop,
  PopupModal,
  RenderFile,
  Text,
  UIAutocompleted,
  UIPhoneInputTitle,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLaboratoryRequest,
  getLabTechRequest,
  getPathologistRequest,
  getSupportEmployeeRequest,
  updateLaboratoryRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import {
  getLabTech,
  getLaboratoryCurrPage,
  getPathologist,
  getScroll,
  getSupportEmp
} from '../../../../services/redux/selectors';
import { FormTitleCard } from '../../../common';
import { SupportEmployee } from './support-employee-form';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },

  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "country state"
      "district taluk"
      "village post"
        `
      : `
        "country state district"
        "taluk village post"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  },
  locationContainer: { my: 2, display: 'flex', justifyContent: 'space-between' },
  commonColumn: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    rowGap: '10px',
    columnGap: '20px'
  },
  editTextCont: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  modCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  delete: {
    cursor: 'pointer',
    width: defaultValues.isResponsive ? '20px' : '26px',
    height: defaultValues.isResponsive ? '20px' : '26px'
  }
};

export const LaboratoryEmployeeDetailsForm = ({
  onPrev,
  laboratoryData,
  laboratorySteps,
  onNext,
  laboratoryId
}) => {
  const dispatch = useDispatch();
  const pathRef = useRef();
  const labRef = useRef();

  const currRender = useSelector(getLaboratoryCurrPage);
  const pathologists = useSelector(getPathologist);
  const labTech = useSelector(getLabTech);
  const suppEmp = useSelector(getSupportEmp);
  const scroll = useSelector(getScroll);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [supportEmployee, setSupportEmployee] = useState([]);
  const [currIdx, setCurrIdx] = useState(true);
  const [modalBtnDisabled, setModalBtnDisabled] = useState(false);
  const [open, setOpen] = useState(null);

  const { add, edit, view } = defaultValues.actionType;
  const { pathologist, lab_tech, supportEmp } = defaultValues.appLogicalTexts;
  let timer;

  const { values, setValues, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      pathologist: {
        employee_id: null,
        employee_code: null,
        full_name: null,
        phone_number: null,
        date_of_expiry: null,
        certificate_url: null
      },
      lab_technician: {
        employee_id: null,
        employee_code: null,
        full_name: null,
        phone_number: null,
        date_of_expiry: null,
        certificate_url: null
      },
      support_employee: {
        employee_id: '',
        role: '',
        employee_code: '',
        full_name: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const extractDetails = (url) => {
    const arr = url.split('.');
    const title = arr[arr.length - 2].split('/');
    return {
      item: url,
      name: title[title.length - 1],
      type: arr[arr.length - 1],
      elemType: true
    };
  };

  useEffect(() => {
    if (laboratoryData) {
      let newValues = {
        ...laboratoryData,
        support_employee: {
          employee_id: '',
          role: '',
          employee_code: '',
          full_name: ''
        }
      };
      if (laboratoryData.pathologist) {
        newValues = {
          ...newValues,
          pathologist: {
            ...laboratoryData.pathologist,
            date_of_expiry: laboratoryData.pathologist.date_of_expiry
              ? dayjs(laboratoryData.pathologist.date_of_expiry)
              : null,
            certificate_url: laboratoryData.pathologist.certificate_url
              ? extractDetails(laboratoryData.pathologist.certificate_url)
              : null
          }
        };
      }

      if (laboratoryData.lab_technician) {
        newValues = {
          ...newValues,
          lab_technician: {
            ...laboratoryData.lab_technician,
            date_of_expiry: laboratoryData.lab_technician.date_of_expiry
              ? dayjs(laboratoryData.lab_technician.date_of_expiry)
              : null,
            certificate_url: laboratoryData.lab_technician.certificate_url
              ? extractDetails(laboratoryData.lab_technician.certificate_url)
              : null
          }
        };
      }

      setSupportEmployee(laboratoryData.support_employee);
      setValues(newValues);
    }
  }, [laboratoryData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      registration_step: defaultValues.labRegisterSteps.employeeDetails,
      _id: laboratoryId,
      employee_details: {
        ...values,
        pathologist: {
          employee_id: values.pathologist && values.pathologist.employee_id,

          date_of_expiry: values.pathologist && values.pathologist.date_of_expiry,
          certificate_url: values.pathologist && values.pathologist.certificate_url
        },
        lab_technician: {
          employee_id: values.lab_technician && values.lab_technician.employee_id,

          date_of_expiry: values.lab_technician && values.lab_technician.date_of_expiry,
          certificate_url: values.lab_technician && values.lab_technician.certificate_url
        },
        support_employee:
          supportEmployee && supportEmployee.length > 0
            ? supportEmployee.map((item) => ({ employee_id: item.employee_id, role: item.role }))
            : ''
      }
    };
    if (currRender === add) {
      if (laboratorySteps === 2 && laboratoryData) {
        dispatch(updateLaboratoryRequest(payload));
      } else {
        dispatch(addLaboratoryRequest(payload));
      }
    }
    if (currRender === edit) dispatch(updateLaboratoryRequest(payload));
    onNext();
    dispatch(updateScrollToTop(!scroll));
  };

  const searchList = (val, title) => {
    let payload = { search_text: val, page: 1 };
    if (title === pathologist) {
      dispatch(getPathologistRequest(payload));
    }
    if (title === lab_tech) {
      dispatch(getLabTechRequest(payload));
    }
    if (title === supportEmp) {
      dispatch(getSupportEmployeeRequest(payload));
    }
  };

  const handleAddAnother = () => {
    let supp = [...supportEmployee];
    const obj = { ...values.support_employee };
    if (currIdx === true) {
      supp = [...supportEmployee, obj];
    } else {
      const temp = supp.map((item, idx) => (idx === currIdx ? obj : item));
      supp = temp;
    }
    setCurrIdx(true);
    setSupportEmployee(supp);
    setBtndisabled(false);
    setValues({
      ...values,
      support_employee: {
        employee_id: '',
        role: '',
        employee_code: '',
        full_name: ''
      }
    });
  };

  const handleTitleClick = (item, idx) => {
    if (currIdx === idx) {
      setValues({
        ...values,
        support_employee: {
          employee_id: '',
          role: '',
          employee_code: '',
          full_name: ''
        }
      });
      return setCurrIdx(true);
    }
    setFieldValue('support_employee', { ...item });
    setCurrIdx(idx);
  };

  const handleEditClick = (item, ref, preview, category) => {
    setOpen({ item, ref, preview, category });
    setModalBtnDisabled(true);
  };

  const handleModalClose = () => {
    setOpen(null);
  };

  const renderFileUrl = (objFile) => {
    if (objFile && objFile.item && typeof objFile.item === 'string') {
      return objFile.item;
    } else if (
      objFile &&
      objFile.item &&
      objFile.item.item &&
      typeof objFile.item.item === 'string'
    ) {
      return objFile.item.item;
    } else {
      return objFile && objFile.preview && objFile.preview[0];
    }
  };

  const handleModalSave = (file, type) => {
    if (type === pathologist) {
      setFieldValue('pathologist.pathology_certificate_details.certificate_url', file);
    }
    if (type === lab_tech) {
      setFieldValue(
        'lab_technician.laboratory_technology_certificate_details.certificate_url',
        file
      );
    }

    setOpen(null);
  };

  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleDelete = (idx) => {
    const final = supportEmployee.filter((ele, index) => index !== idx);
    setSupportEmployee(final);
    setBtndisabled(false);
    setCurrIdx(true);
    setValues({
      ...values,
      support_employee: {
        employee_id: '',
        role: '',
        employee_code: '',
        full_name: ''
      }
    });
  };

  return (
    <Box sx={styles.container}>
      <FormTitleCard title={getTitles('T-11079')}>
        <UIAutocompleted
          required={true}
          options={pathologists && pathologists.length > 0 ? pathologists.map((item) => item) : []}
          optionLabel={(item) =>
            item.first_name
              ? item.first_name + ' ' + item.last_name
              : item.full_name
                ? item.full_name
                : ''
          }
          variant={defaultValues.isResponsive ? 'medium' : 'body'}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                searchList(val, pathologist);
              }
            }, 500);
          }}
          onBlur={handleBlur}
          containerStyle={styles.phone}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('pathologist.employee_code', val ? val.employee_id : '');
            setFieldValue('pathologist.employee_id', val ? val._id : '');
            setFieldValue('pathologist.phone_number', val ? val.phone_number : '');
            setFieldValue('pathologist.full_name', val ? `${val.first_name + val.last_name}` : '');
            setBtndisabled(false);
          }}
          preSelectedValue={
            values.pathologist && values.pathologist.full_name && values.pathologist
          }
          disabled={currRender === view}
        />
        <UITextInputWithTitle
          name="pathologist.employee_code"
          title={getTitles('T-11115')}
          value={values.pathologist && values.pathologist.employee_code}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          disabled={true}
        />

        <UIPhoneInputTitle
          phoneValue={values.pathologist && values.pathologist.phone_number}
          onChange={(val) => {
            setFieldValue('pathologist.phone_number', val);
          }}
          validate={false}
          handleError={(isError) => setBtndisabled(isError)}
          containerStyles={styles.phone}
          disabled={true}
        />

        <Text variant="BoldH22Sec" style={{ mt: 2 }}>
          {getTitles('T-11080')}
        </Text>

        <DatePickers
          dateValue={values.pathologist && values.pathologist.date_of_expiry}
          onDateChange={(val) =>
            handleChange({
              target: {
                value: val,
                name: 'pathologist.date_of_expiry'
              }
            })
          }
          title={getTitles('T-11081')}
          width={styles.width}
          disablePast
          disabled={currRender === view}
        />

        <DragDrop
          inputRef={pathRef}
          title={getTitles('T-11082')}
          custom={true}
          disabled={currRender === view}
          handleChange={(file, preview) => {
            open
              ? setOpen({ ...open, item: file, preview })
              : setFieldValue('pathologist.certificate_url', file);
            setModalBtnDisabled(false);
            setBtndisabled(false);
          }}
          fileData={values.pathologist && values.pathologist.certificate_url}
          showDelete={false}
          onEditClick={(preview) =>
            handleEditClick(values.pathologist.certificate_url, pathRef, preview, pathologist)
          }
          width={styles.width}
        />
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-11083')}>
        <UIAutocompleted
          options={labTech && labTech.length > 0 ? labTech.map((item) => item) : []}
          optionLabel={(item) =>
            item.first_name
              ? item.first_name + ' ' + item.last_name
              : item.full_name
                ? item.full_name
                : ''
          }
          variant={defaultValues.isResponsive ? 'medium' : 'body'}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                searchList(val, lab_tech);
              }
            }, 500);
          }}
          onBlur={handleBlur}
          containerStyle={styles.phone}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('lab_technician.employee_code', val ? val.employee_id : '');
            setFieldValue('lab_technician.employee_id', val ? val._id : '');
            setFieldValue('lab_technician.phone_number', val ? val.phone_number : '');
            setFieldValue(
              'lab_technician.full_name',
              val ? `${val.first_name + val.last_name}` : ''
            );
            setBtndisabled(false);
          }}
          preSelectedValue={
            values.lab_technician && values.lab_technician.full_name && values.lab_technician
          }
          disabled={currRender === view}
        />
        <UITextInputWithTitle
          name="lab_technician.employee_code"
          title={getTitles('T-11115')}
          value={values.lab_technician && values.lab_technician.employee_code}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          disabled={true}
        />

        <UIPhoneInputTitle
          phoneValue={values.lab_technician && values.lab_technician.phone_number}
          onChange={(val) => {
            setFieldValue('lab_technician.phone_number', val);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          containerStyles={styles.phone}
          disabled={true}
        />

        <Text variant="BoldH22Sec" style={{ mt: 2 }}>
          {getTitles('T-11084')}
        </Text>

        <DatePickers
          dateValue={values.lab_technician && values.lab_technician.date_of_expiry}
          onDateChange={(val) =>
            handleChange({
              target: {
                value: val,
                name: 'lab_technician.date_of_expiry'
              }
            })
          }
          title={getTitles('T-11081')}
          width={styles.width}
          disablePast
          disabled={currRender === view}
        />

        <DragDrop
          inputRef={labRef}
          title={getTitles('T-11082')}
          custom={true}
          disabled={currRender === view}
          handleChange={(file, preview) => {
            open
              ? setOpen({ ...open, item: file, preview })
              : setFieldValue('lab_technician.certificate_url', file);
            setModalBtnDisabled(false);
            setBtndisabled(false);
          }}
          fileData={values.lab_technician && values.lab_technician.certificate_url}
          onEditClick={(preview) =>
            handleEditClick(values.lab_technician.certificate_url, labRef, preview, lab_tech)
          }
          showDelete={false}
          width={styles.width}
        />
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-11085')}>
        {supportEmployee &&
          supportEmployee.length > 0 &&
          supportEmployee.map((elem, idx) => (
            <Box key={idx} sx={{ width: '100%', mt: 1 }}>
              <Box sx={{ width: '100%', display: 'flex' }}>
                <CollapseDropView
                  isSubtitle={false}
                  title={elem.full_name}
                  handleSubTitleClick={() => handleTitleClick(elem, idx)}
                  open={currIdx === idx}
                  handleDelete={currRender !== view ? () => handleDelete(idx) : null}
                />
              </Box>

              {currIdx === idx && (
                <SupportEmployee
                  suppEmp={suppEmp}
                  searchList={searchList}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                  handleChange={handleChange}
                  handleAddAnother={handleAddAnother}
                  preSelectedValue={values.support_employee}
                  setBtndisabled={setBtndisabled}
                  currRender={currRender}
                  view={view}
                />
              )}
            </Box>
          ))}
        {currIdx === true && (
          <SupportEmployee
            suppEmp={suppEmp}
            searchList={searchList}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            handleAddAnother={handleAddAnother}
            preSelectedValue={values.support_employee}
            setBtndisabled={setBtndisabled}
            currRender={currRender}
            view={view}
          />
        )}
      </FormTitleCard>

      <PopupModal showModel={open} onClose={handleModalClose} title={getTitles('T-10393')}>
        <Box sx={styles.modCont}>
          <RenderFile
            filePath={renderFileUrl(open)}
            type={open && open.item && open.item.type && open.item.type}
          />
          {open && open.category !== 'view' && (
            <Box sx={styles.editTextCont}>
              <Text
                style={globalStyles.linkDesc}
                variant="D18"
                onClick={() => currRender !== view && open.ref.current.click()}>
                {getTitles('T-10435')}
              </Text>
            </Box>
          )}

          {open && open.category !== 'view' && (
            <ModalButtons
              onPrimaryBtnClick={() => {
                handleModalSave(open.item, open.category);
              }}
              primaryBtnTitle={getTitles('T-10394')}
              containerStyle={globalStyles.mt(3)}
              disabled={modalBtnDisabled || currRender === view}
            />
          )}
        </Box>
      </PopupModal>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
