/* eslint-disable no-unused-vars */
import { useFormik } from 'formik';
import { styles } from './styles';
import { PopupModal, Text } from '../../../ui-kits';
import { CallCenterCommonField } from '../../call-center';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssocFarmer,
  getAssocNxtPage,
  getCommonTasksDetails,
  getTasksCurrTab,
  getTasksLoading,
  getTasksSuccessMsg
} from '../../../services/redux/selectors/tasksSelector';
import {
  callCenterTaskSchema,
  deduplicateAndSet,
  getTitles,
  removeFalsyValues
} from '../../../utils';
import { defaultValues, getErrorMessage } from '../../../constants';
import { ModalButtons } from '../modal-buttons';
import { globalStyles } from '../../../theme';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import mFarmer from '../../../assets/icons/farmer.png';
import mVet from '../../../assets/icons/vet.png';
import mLaboratory from '../../../assets/icons/laborotary.png';
import mParavet from '../../../assets/icons/paravet.png';
import cattleIcon from '../../../assets/icons/cattle.png';
import {
  addMyTasksRequest,
  getAssociatedFarmerTasksRequest,
  getCattlesRequest,
  setCommonTasksCurrRender,
  updateMyTasksRequest
} from '../../../services/redux/slices';
import { getCattleList } from '../../../services/redux/selectors';

export const TasksCommon = ({ taskTypes }) => {
  const dispatch = useDispatch();

  const currRender = useSelector(getTasksCurrTab);
  const isLoading = useSelector(getTasksLoading);
  const taskDetails = useSelector(getCommonTasksDetails);

  const associatedFarmer = useSelector(getAssocFarmer);
  const nextPage = useSelector(getAssocNxtPage);
  const successMsg = useSelector(getTasksSuccessMsg);
  const cattleList = useSelector(getCattleList);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [farmerList, setFarmerList] = useState([]);
  const [farmerErrorMsg, setFarmerErrorMsg] = useState(null);

  const { add, edit, view } = defaultValues.actionType;

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      task_name: '',
      description: '',
      task_type: '',
      task_status: '',
      due_date: null,
      priority: '',
      call_center_status: '',
      repeat: null,
      no_of_days: '',
      assign_to: '',
      notify_to: [],
      remind_to: [],
      remind_on: [
        {
          days: '',
          remind_at: '',
          timing: null
        }
      ],
      cattle_id: '',
      farmer_id: '',
      role: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: callCenterTaskSchema
  });

  useEffect(() => {
    if (successMsg) {
      if (['S-10098', 'S-10097'].includes(successMsg)) handleClose();
    }
  }, [successMsg]);

  useEffect(() => {
    if (taskDetails) {
      let newValues = {
        ...taskDetails,
        no_of_days: taskDetails.repeat_days,
        due_date: taskDetails.due_date && dayjs(taskDetails.due_date),
        priority: taskDetails.priority?.id || '',
        created_at: taskDetails.created_at && dayjs(taskDetails.created_at),
        task_type: taskDetails.task_type?._id,
        task_status: taskDetails.task_status?.id,
        call_center_status: taskDetails.call_center_status?.id || '',
        assign_to: taskDetails.assign_role?.id,
        notify_to:
          (taskDetails.notify_role?.length > 0 &&
            taskDetails.notify_role?.map((elem) => ({
              value: elem.id,
              title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
            }))) ||
          [],
        remind_to:
          (taskDetails.remind_role?.length > 0 &&
            taskDetails.remind_role?.map((elem) => ({
              value: elem.id,
              title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
            }))) ||
          [],
        remind_on: getRemaindOn(taskDetails.remind_on),
        role: ''
      };
      setValues(newValues);
    }
  }, [taskDetails]);

  useEffect(() => {
    if (values.farmer_id) {
      dispatch(getCattlesRequest({ farmer_id: values.farmer_id?._id }));
    }
  }, [values.farmer_id]);

  useEffect(() => {
    if (nextPage !== null) {
      dispatch(
        getAssociatedFarmerTasksRequest({ employee_permission: values.role, page: nextPage })
      );
    }
  }, [nextPage]);

  useEffect(() => {
    setFarmerList(deduplicateAndSet(farmerList, associatedFarmer || []));
  }, [associatedFarmer]);

  useEffect(() => {
    setFarmerList([]);
    if (values.role) {
      dispatch(getAssociatedFarmerTasksRequest({ employee_permission: values.role }));
      setFarmerErrorMsg(null);
    }
  }, [values.role]);

  const getRemaindOn = (remindOn) => {
    const temp =
      (remindOn?.length > 0 &&
        taskDetails.remind_on?.map((elem) => ({
          ...elem,
          timing: elem.timing && dayjs(elem.timing)
        }))) ||
      [];
    return [...temp, { days: '', remind_at: '', timing: null }];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      repeat_days: values.no_of_days && parseInt(values.no_of_days),
      remind_on: values.remind_on.slice(0, -1).map((elem) => ({
        ...elem,
        days: elem.days ? parseInt(elem.days) : [2, 3].includes(elem.remind_at) && 1,
        timing: elem.timing || dayjs().hour(0).minute(0).second(0).millisecond(0)
      })),
      notify_role: values.notify_to.map((item) => item.value),
      remind_role: values.remind_to.map((item) => item.value),
      call_center_status: values.call_center_status || defaultValues.callCenterStatus[0].value,
      assign_role: values.assign_to,
      priority: values.priority || 3
    };
    delete payload.role;
    delete payload.remind_to;
    delete payload.notify_to;

    currRender === add && dispatch(addMyTasksRequest(removeFalsyValues(payload)));
    currRender === edit && dispatch(updateMyTasksRequest(removeFalsyValues(payload)));
  };

  const handleAddAnother = () => {
    setValues({
      ...values,
      remind_on: [...values.remind_on, { days: '', remind_at: '', timing: null }]
    });
  };

  const handleRemove = (idx) => {
    const newRemindDate = values.remind_on.filter((_, i) => i !== idx);
    setFieldValue('remind_on', newRemindDate);
  };

  const handleClose = () => {
    setBtndisabled(true);
    resetForm();
    dispatch(setCommonTasksCurrRender(null));
  };

  const searchList = (val) => {
    if (values.role) {
      dispatch(
        getAssociatedFarmerTasksRequest({ employee_permission: values.role, search_text: val })
      );
      setFarmerErrorMsg(null);
    } else {
      setFarmerErrorMsg(getErrorMessage('E-10022'));
    }
  };

  const info = [
    taskDetails?.cattle_id && {
      heading: getTitles('T-11403'),
      details: {
        name: taskDetails?.cattle_id?.cattle_name,
        phone_number: taskDetails?.cattle_id?.cattle_id
      },
      icon: cattleIcon
    },
    taskDetails?.farmer_id?.full_name && {
      heading: getTitles('T-11291'),
      details: {
        name: taskDetails?.farmer_id?.full_name,
        phone_number: taskDetails?.farmer_id?.phone_number,
        location: [
          taskDetails?.farmer_id?.taluk?.name || '',
          taskDetails?.farmer_id?.village?.name || ''
        ].join(', ')
      },
      icon: mFarmer
    },
    taskDetails?.gopalan?.full_name && {
      heading: getTitles('T-11292'),
      details: {
        name: taskDetails?.gopalan?.full_name,
        phone_number: taskDetails?.gopalan?.phone_number,
        location: taskDetails?.gopalan?.location
      },
      icon: mParavet
    },
    taskDetails?.vet?.full_name && {
      heading: getTitles('T-11293'),
      details: {
        name: taskDetails?.vet?.full_name,
        phone_number: taskDetails?.vet?.phone_number,
        location: taskDetails?.vet?.location
      },
      icon: mVet
    },
    taskDetails?.vet?.assigned_lab?.length > 0 && {
      heading: getTitles('T-11294'),
      details: taskDetails?.vet?.assigned_lab,
      icon: mLaboratory
    },
    taskDetails?.gopalan?.assigned_lab?.length > 0 && {
      heading: getTitles('T-11295'),
      details: taskDetails?.gopalan?.assigned_lab,
      icon: mLaboratory
    }
  ];

  return (
    <PopupModal
      showModel={[add, edit].includes(currRender)}
      title={
        currRender === add
          ? getTitles('T-11263')
          : currRender === view
            ? getTitles('T-11299')
            : getTitles('T-11300')
      }
      onClose={handleClose}
      isLoading={isLoading}
      childContStyle={styles.childCont}
      containerStyle={styles.popUpContainer}>
      <CallCenterCommonField
        showModel={{ mode: currRender }}
        info={info}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        handleRemove={handleRemove}
        handleAddAnother={handleAddAnother}
        setFieldTouched={setFieldTouched}
        setBtndisabled={setBtndisabled}
        setFieldValue={setFieldValue}
        taskTypes={taskTypes}
        searchList={searchList}
        isCommon={true}
        farmerList={associatedFarmer}
        cattleList={cattleList}
        farmerErrorMsg={farmerErrorMsg}
      />

      {currRender === edit && taskDetails?.created_by?.full_name && (
        <Text
          sx={{ mt: 2 }}>{`${getTitles('T-11252')} : ${taskDetails.created_by.full_name}`}</Text>
      )}

      <ModalButtons
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={currRender === edit ? getTitles('T-10046') : getTitles('T-10394')}
        disabled={!isValid || !dirty || btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </PopupModal>
  );
};
