import { palette } from './palette';

/**
 * Roles for colors. Define different colors with tgheir type or role. change the color codes from the palette file
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: 'rgba(0, 0, 0, 0)',
  /**
   * The screen background.
   */
  primaryBackground: palette.lightIvory,
  secondaryBackground: palette.grayishBlue,
  /**
   * The main tinting color.
   * the secondary color
   */
  primary: palette.darkGreen,
  secondary: palette.sunflowerYellow,

  /**
   * The default color of text in many components.
   */
  primaryText: palette.darkGray,
  secondaryText: palette.steelGray,
  tertiaryText: palette.white,
  /**
   * Success and Error messages and icons.
   */
  success: palette.green,
  errorText: palette.brightRed,
  /**
    default border color
    */
  primaryBorder: palette.blueGray,
  secondaryBorder: palette.darkerBlueGray,

  /**
   * used for modal
   */

  warning: palette.yellow,
  successAction: palette.blue,
  tableHeading: palette.paleYellow,

  /*
   * disabled, link etc
   */

  disabled: palette.mossGreen,
  link: palette.royalBlue,

  /*
   * primaryCardBackground, secondaryCardBackground etc
   */

  primaryCardBackground: palette.pearGreen,
  secondaryCardBackground: palette.limeGreen,
  tertiaryCardBackground: palette.darkBrown,

  /*
   * Backdrop
   */
  backdrop: palette.transparentBlack
};
