import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
export const Styles = {
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '8px',
    marginLeft: sm ? '4px' : '0px',
    cursor: 'pointer',
    alignItems: 'center',
    marginRight: res ? '0px' : '5px',
    backgroundColor: color.secondaryBackground,
    height: res ? '32px' : '48px'
  },
  toolsIcons: {
    maxWidth: '100%',
    maxHeight: sm ? '14px' : res ? '18px' : '24px',
    marginRight: !res && '6px'
  }
};
