import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

const isRes = defaultValues.isResponsive;

export const Styles = {
  container: {
    width: isRes ? '100%' : 'calc(100% - 160px)',
    paddingLeft: !isRes && '75px',
    paddingTop: isRes && '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: !isRes && 8
  },
  subContainer: {
    border: `1px solid ${color.primaryBorder}`,
    minHeight: '100px',
    p: 2,
    boxSizing: 'border-box',
    borderRadius: 1,
    width: '100%'
  },
  selectedConditionContainer: {
    display: 'flex',
    alignItems: 'center',
    m: 1
  },
  num: {
    width: '40px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: color.secondaryBackground,
    border: `1px solid ${color.primaryBorder}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mr: 1
  },
  conditionContainer: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    flexDirection: isRes ? 'column' : 'row'
  },
  commonField: { width: isRes ? '100%' : '30%' },
  deleteBtn: { display: 'flex', justifyContent: 'flex-end' },
  fieldsContainer: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    flexDirection: isRes ? 'column' : 'row'
  },
  fieldM: { marginTop: '15px' },
  btn: {
    width: isRes ? '100px' : '150px',
    height: isRes ? '40px' : '48px',
    my: 2
  },
  conditionFlex: { display: 'flex', justifyContent: 'space-between' }
};
