import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import high from '../../assets/icons/high.png';
import low from '../../assets/icons/low.png';
import medium from '../../assets/icons/medium.png';
import pending from '../../assets/icons/pending.png';
import close from '../../assets/icons/close.png';
import open from '../../assets/icons/open.png';
import { getFormatDate, getTitles, resetMessages } from '../../utils';
import { Box } from '@mui/material';
import {
  CollapsableList,
  DatePickers,
  NoData,
  Screen,
  Text,
  UIDataTable,
  UILayout,
  UISelect
} from '../../ui-kits';
import { styles } from './styles';
import { BasicProfile, StatusCard } from '../../components';
import { globalStyles } from '../../theme';
import {
  getCommonTaskDetailsRequest,
  getMyTasksRequest,
  resetCommonTasksMessages,
  setCommonTaskDetails,
  setCommonTasksCurrRender
} from '../../services/redux/slices/tasksSlice';
import { TasksCommon } from '../../components/common/task-modal-common';
import {
  getMytaskList,
  getTasksErrorMsg,
  getTasksLoading,
  getTasksSuccessMsg
} from '../../services/redux/selectors/tasksSelector';
import { toast } from 'react-toastify';
import { getMasterList } from '../../services/redux/selectors/manageProccess';
import { getMasterListRequest } from '../../services/redux/slices';

export const ManageTasksCommon = () => {
  const dispatch = useDispatch();

  const successMsg = useSelector(getTasksSuccessMsg);
  const errorMsg = useSelector(getTasksErrorMsg);
  const isLoading = useSelector(getTasksLoading);
  const taskTypes = useSelector(getMasterList);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [filterDate, setFilterDate] = useState(defaultValues.dueDateRange.today);
  const [groupBy, setGroupBy] = useState(1);
  const [viewAllIdx, setViewAllIdx] = useState(null);

  const [dateValue, setDateValue] = useState(null);
  const { edit, add } = defaultValues.actionType;
  const res = defaultValues.isResponsive;
  const md = defaultValues.isMedium;

  const myTaskList = useSelector(getMytaskList);

  useEffect(() => {
    handleGetTaskList();
    dispatch(
      getMasterListRequest({
        master_id: defaultValues.mListIds.tastType,
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10096') dispatch(setCommonTasksCurrRender(edit));
      if (['S-10098', 'S-10097'].includes(successMsg)) handleGetTaskList();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetCommonTasksMessages()));
  }, [successMsg, errorMsg]);

  const handleGetTaskList = (val) => {
    let payload = {
      sort: sort,
      filter: filter,
      due_date_range: filterDate,
      group_by: groupBy,
      ...val
    };
    dispatch(getMyTasksRequest(payload));
  };

  const handleSearch = (data) => {
    handleGetTaskList({ search_text: data });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleGetTaskList({
      filter: JSON.stringify(data),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleGetTaskList({ sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    setDateValue(null);
    setFilterDate(defaultValues.dueDateRange.today);
    handleGetTaskList({
      search_text: null,
      filter: null,
      sort: null,
      due_date_range: null,
      custom_due_date: null,
      group_by: 1
    });
  };

  const handleDueDateRange = (val) => {
    setFilterDate(val);
    setDateValue(null);

    if (val !== defaultValues.dueDateRange.custom) {
      handleGetTaskList({ due_date_range: val });
      return;
    }
    if (val === defaultValues.dueDateRange.custom && dateValue) {
      handleGetTaskList({ due_date_range: val, custom_due_date: dateValue });
      return;
    }
  };

  const handleCustomDateChange = (val) => {
    setDateValue(val);
    handleGetTaskList({ due_date_range: filterDate, custom_due_date: val });
  };

  const handleGroupByChange = (e) => {
    setGroupBy(e.target.value);
    handleGetTaskList({
      group_by: e.target.value
    });
  };
  const handleEdit = (item) => {
    dispatch(setCommonTaskDetails(null));
    dispatch(getCommonTaskDetailsRequest({ task_id: item._id }));
  };

  const getIconOne = (item) => {
    return item.task_status?.id === 1
      ? open
      : item.task_status?.id === 2
        ? pending
        : item.task_status?.id === 3 && close;
  };

  const getIconTwo = (item) => {
    return item.task_status?.id === 3
      ? null
      : item.priority?.id === 1
        ? high
        : item.priority?.id === 2
          ? medium
          : item.priority?.id === 3 && low;
  };

  const getTitleOne = (item) => {
    return item.task_status?.id === 1
      ? getTitles('T-11316')
      : item.task_status?.id === 2
        ? getTitles('T-11317')
        : item.task_status?.id === 3 && getTitles('T-11103');
  };

  const getTitleTwo = (item) => {
    return item.task_status?.id === 3
      ? null
      : item.priority?.id === 1
        ? getTitles('T-11305')
        : item.priority?.id === 2
          ? getTitles('T-11306')
          : item.priority?.id === 3 && getTitles('T-11307');
  };

  const handleAdd = () => {
    dispatch(setCommonTaskDetails(null));
    dispatch(setCommonTasksCurrRender(add));
  };

  const myTasksData = (elem) =>
    elem?.length > 0 &&
    elem.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-11245'),
            headerTextStyle: styles.tableHeaderTextStyle,
            values: (
              <Box sx={styles.subCont}>
                <BasicProfile title={item.task_id} isIcon={false} titleStyle={styles.center} />
                <BasicProfile
                  title={item.task_name}
                  isIcon={false}
                  titleStyle={{ ...styles.center, ...globalStyles.title }}
                  onTitleClick={() => handleEdit(item)}
                />
              </Box>
            ),
            valueTextStyle: styles.center
          },
          {
            title: getTitles('T-11247'),
            values: (
              <StatusCard
                icon1={getIconOne(item)}
                icon2={getIconTwo(item)}
                title1={getTitleOne(item)}
                title2={getTitleTwo(item)}
              />
            ),
            headerTextStyle: styles.tableHeaderTextStyle
          },

          {
            title: getTitles('T-11248'),
            values: item.task_type,
            headerTextStyle: styles.tableHeaderTextStyle,
            valueTextStyle: styles.center
          },
          {
            title: getTitles('T-11387'),
            headerTextStyle: styles.tableHeaderTextStyle,
            values: (
              <Box sx={styles.subCont}>
                <BasicProfile
                  title={item.farmer?.full_name || item.gopalan?.full_name || item.vet?.full_name}
                  isIcon={false}
                  containerStyle={styles.mb}
                />
                {item.cattle?._id && (
                  <BasicProfile
                    title={item.cattle?.cattle_name}
                    isIcon={false}
                    containerStyle={styles.mb}
                  />
                )}
                <BasicProfile
                  title={
                    item.farmer?.phone_number ||
                    item.gopalan?.phone_number ||
                    item.vet?.phone_number
                  }
                  containerStyle={styles.mb}
                  isIcon={false}
                />
                <BasicProfile title={item.caller_details?.location} isIcon={false} />
              </Box>
            ),
            columnWidth: '16%'
          },
          {
            title: getTitles('T-11250'),
            values: (
              <BasicProfile
                title={item.created_at && getFormatDate(item.created_at)}
                isIcon={false}
                titleStyle={styles.center}
              />
            ),
            headerTextStyle: styles.tableHeaderTextStyle
          },
          {
            title: getTitles('T-11251'),
            values: (
              <BasicProfile
                title={item.due_date && getFormatDate(item.due_date)}
                isIcon={false}
                titleStyle={styles.center}
              />
            ),
            valueTextStyle: styles.center,
            headerTextStyle: styles.tableHeaderTextStyle
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.task_name}
              isIcon={getIconTwo(item)}
              url={getIconTwo(item)}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-11278'),
              action: item.task_id
            },
            {
              subTitle: getTitles('T-11248'),
              action: item.task_type
            },

            {
              subTitle: getTitles('T-11387'),
              action: (
                <Box sx={styles.subCont}>
                  <BasicProfile
                    title={item.farmer?.full_name || item.gopalan?.full_name || item.vet?.full_name}
                    isIcon={false}
                    containerStyle={styles.mb}
                  />

                  <BasicProfile
                    title={
                      item.farmer?.phone_number ||
                      item.gopalan?.phone_number ||
                      item.vet?.phone_number
                    }
                    containerStyle={styles.mb}
                    isIcon={false}
                  />
                  <BasicProfile title={item.caller_details?.location} isIcon={false} />
                </Box>
              )
            },
            {
              subTitle: getTitles('T-10984'),
              action: (
                <BasicProfile
                  title={item.cattle?.cattle_name}
                  isIcon={false}
                  containerStyle={styles.mb}
                />
              )
            },
            {
              subTitle: getTitles('T-11250'),
              action: (
                <BasicProfile
                  title={item.created_at && getFormatDate(item.created_at)}
                  isIcon={false}
                />
              )
            },
            {
              subTitle: getTitles('T-11251'),
              action: (
                <BasicProfile
                  title={item.due_date && getFormatDate(item.due_date)}
                  isIcon={false}
                />
              )
            }
          ]
        };
      }
    });

  const GroupBy = () => {
    return (
      <UISelect
        options={defaultValues.taskGroupBy}
        value={groupBy}
        onChange={handleGroupByChange}
        placeholder={getTitles('T-10350')}
        containerStyles={styles.groupByCont}
        inputStyle={styles.groupByInput}
      />
    );
  };

  const ResItems = () => {
    return (
      <Box sx={styles.resItemCont}>
        {filterDate === defaultValues.dueDateRange.custom && (
          <DatePickers
            dateValue={dateValue}
            onDateChange={handleCustomDateChange}
            containerStyles={styles.dateCont}
            inputStyle={styles.dateInput}
          />
        )}
        <UISelect
          options={defaultValues.taskGroupBy}
          value={groupBy}
          onChange={handleGroupByChange}
          placeholder={getTitles('T-10350')}
          containerStyles={styles.groupByCont}
          inputStyle={styles.groupByInput}
        />
      </Box>
    );
  };

  const GroupedDataTable = () => {
    return (
      <>
        {res && ResItems()}
        {myTaskList?.length > 0 &&
          myTaskList.map((el, idx) => {
            const dList =
              myTasksData(el.tasks)?.length > 0
                ? myTasksData(el.tasks).slice(
                    0,
                    viewAllIdx === idx ? myTasksData(el.tasks)?.length : 2
                  )
                : [];

            return (
              <Box sx={styles.groupTableCont} key={idx}>
                {!res ? (
                  dList.length > 0 ? (
                    <>
                      <UIDataTable
                        tableRows={dList}
                        showHeader={idx === 0}
                        customData={(index) =>
                          index === 0 && (
                            <Box sx={styles.groupBySubCont}>
                              <Text variant="D22" style={styles.groupText}>
                                {
                                  defaultValues.taskGroupBy.find((em) => em.value === groupBy)
                                    ?.title
                                }
                                :
                              </Text>
                              <Text variant="D20">{`${el.group_name || ''}(${el.tasks?.length || ''})`}</Text>
                            </Box>
                          )
                        }
                        customEndData={(index) =>
                          index === dList?.length - 1 &&
                          myTasksData(el.tasks)?.length > 2 && (
                            <Text
                              style={styles.viewall}
                              onClick={() => setViewAllIdx(viewAllIdx === idx ? null : idx)}>
                              {viewAllIdx === idx ? getTitles('T-11382') : getTitles('T-10415')}
                            </Text>
                          )
                        }
                      />
                    </>
                  ) : (
                    <NoData message={getErrorMessage('E-10194')} />
                  )
                ) : (
                  <>
                    {dList.length > 0 && (
                      <Box sx={styles.groupBySubCont}>
                        <Text variant="D22" style={styles.groupText}>
                          {defaultValues.taskGroupBy.find((em) => em.value === groupBy)?.title} :
                        </Text>
                        <Text variant="D20">{`${el.group_name || ''}(${el.tasks?.length || ''})`}</Text>
                      </Box>
                    )}
                    {dList.length > 0 ? (
                      <CollapsableList contentArray={dList} />
                    ) : (
                      <NoData message={getErrorMessage('E-10194')} />
                    )}
                    {myTasksData(el.tasks)?.length > 2 && (
                      <Text
                        style={styles.viewall}
                        onClick={() => setViewAllIdx(viewAllIdx === idx ? null : idx)}>
                        {viewAllIdx === idx ? getTitles('T-11382') : getTitles('T-10415')}
                      </Text>
                    )}
                  </>
                )}
              </Box>
            );
          })}
      </>
    );
  };

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-11289')} isLoading={isLoading}>
      <Box sx={styles.container}>
        <UILayout
          handleSearch={handleSearch}
          isMultiSelectFilterOption
          searchBarPlaceHolder={getTitles('T-11630')}
          handleAddButton={handleAdd}
          tableItems={myTasksData}
          showNoData={!myTaskList?.length > 0}
          collapsable={false}
          showDataTable={false}
          showDetailsCard={false}
          message={getErrorMessage('E-10194')}
          clearSearch={filter}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          optionsForSorting={defaultValues.farmerSortOptions}
          optionsForFiltering={defaultValues.commonTasksFilterOption(taskTypes)}
          handleSort={handleSort}
          handleFilter={handleFilter}
          handleRefresh={handleRefresh}
          tableHeaderTextStyle={styles.tableHeaderTextStyle}
          isSelect={!res}
          showMlist={false}
          options={defaultValues.dueDateRangeOptions}
          selectLabel={null}
          selectPlaceholder={getTitles('T-10350')}
          selectValue={filterDate}
          selectedOption={handleDueDateRange}
          selectContainerStyle={styles.selectContainerStyle}
          showDatePicker={filterDate === defaultValues.dueDateRange.custom && !res}
          dateValue={dateValue}
          dateCont={styles.dateCont}
          dateInput={styles.dateInput}
          handleDateChange={handleCustomDateChange}
          customOptions={!res && GroupBy()}
          showSortTitle={!md}
          showFilterTitle={!md}
          showRefreshTitle={!md}
          addBtnStyle={styles.addBtnStyle}
          selectInputStyle={styles.selectInputStyle}
          optionStyles={styles.optionStyles}
          beforeDateTableItems={GroupedDataTable()}
        />

        <TasksCommon taskTypes={taskTypes} />
      </Box>
    </Screen>
  );
};
