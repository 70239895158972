import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../../ui-kits/text';
import { UISelect } from '../../../ui-kits/select-with-title';
import { getTitles, resetMessages } from '../../../utils/commonFunction';

import { ReportTable } from '../report-table';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { ModalButtons } from '../../common/modal-buttons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { Styles } from '../style';
import {
  getCattleActionType,
  getCattleData,
  getCattleError,
  getCattleSuccess,
  getReports
} from '../../../services/redux/selectors';
import {
  addCattleRequest,
  getLabReportRequest,
  resetCattleMsg,
  setCattleActionType,
  updateCattleRequest
} from '../../../services/redux/slices';

const isRes = defaultValues.isResponsive;

export const AdditionalDetails = ({ onPrev }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { view, edit } = defaultValues.actionType;

  const data = useSelector(getCattleData);
  const actionType = useSelector(getCattleActionType);
  const reports = useSelector(getReports);
  const success = useSelector(getCattleSuccess);
  const error = useSelector(getCattleError);

  const [selectedReport, setSelectedReport] = useState('');
  const [reportSections, setReportSections] = useState([]);
  const [farmerId, setFarmerId] = useState('');
  const [cattleId, setCattleId] = useState('');
  const [repornName, setReportName] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(0);

  const handleNextAccordion = () => {
    setOpenAccordion((prev) => (prev + 1 < reportSections.length ? prev + 1 : prev));
  };

  useEffect(() => {
    if (data) {
      setCattleId(data._id);
      setFarmerId(data.farmer_id);
      if (data.additional_details && data.additional_details.report_details) {
        setSelectedReport(data.additional_details.report_details._id);
        setReportSections(data.additional_details.report_details.sections);
        setReportName(data.additional_details.report_details.report_name);
      }
    }
  }, [data, reports]);

  const handleSubmit = () => {
    const payload = {
      _id: cattleId,
      farmer_id: farmerId,
      registration_step: 4,
      additional_details: {
        report_details: {
          report_id: selectedReport,
          sections: reportSections.map((se) => ({
            _id: se._id,
            test_fields: se.test_fields.map((te) => ({
              _id: te._id,
              sample_value: te.sample_value
            }))
          }))
        }
      }
    };
    if (actionType === edit) {
      dispatch(updateCattleRequest(payload));
      dispatch(setCattleActionType(view));
    } else {
      dispatch(addCattleRequest(payload));
    }
  };

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
      if (success === 'S-10062') {
        navigation(SCREEN_PATH.MANAGE_CATTLE, { state: { farmer_id: farmerId } });
      }
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetCattleMsg()));
  }, [success, error]);

  useEffect(() => {
    dispatch(getLabReportRequest());
  }, [dispatch]);

  const handleReportChange = (val) => {
    const selectedReportId = val.target.value;
    setSelectedReport(selectedReportId);

    const selectedReport = reports.find((report) => report._id === selectedReportId);
    if (selectedReport) {
      setReportName(selectedReport.report_name);
      setReportSections(selectedReport.sections);
    } else {
      setReportSections([]);
    }
  };

  const handleChange = (sampleValue, sectionId, testFieldId) => {
    const reportDD = reportSections;

    const temp = [];

    reportDD.forEach((section) => {
      if (section._id === sectionId) {
        const testTemp = [];
        section.test_fields.forEach((test) => {
          if (test._id === testFieldId) {
            testTemp.push({
              ...test,
              sample_value: sampleValue
            });
          } else {
            testTemp.push(test);
          }
        });
        temp.push({ ...section, test_fields: testTemp });
      } else {
        temp.push(section);
      }
    });

    setReportSections(temp);
  };

  return (
    <Box sx={Styles.additionalDCont}>
      <Box sx={Styles.additionalDSubCont}>
        <Box sx={Styles.textCont}>
          <Text variant="BoldH28">{repornName ? repornName : getTitles('T-11099')}</Text>
          {!data.additional_details && (
            <UISelect
              placeholder={getTitles('T-10087')}
              containerStyles={{ width: isRes ? 'auto' : '20%' }}
              value={selectedReport}
              options={reports}
              optionLabel="report_name"
              returnValue="_id"
              onChange={handleReportChange}
            />
          )}
        </Box>
        {data.additional_details && (
          <>
            <Box sx={Styles.additionalDataCont}>
              <Box sx={{ display: 'flex' }}>
                {showDetails ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                <Text onClick={() => setShowDetails(!showDetails)}>{repornName}</Text>
              </Box>
              {actionType === edit && (
                <Box sx={{ width: '35px' }}>
                  <ModeOutlinedIcon onClick={() => setShowDetails(!showDetails)} />
                </Box>
              )}
            </Box>
            {showDetails &&
              reportSections.map((item, index) => (
                <ReportTable
                  key={index}
                  title={item.section_name}
                  sectionId={item._id}
                  items={item.test_fields}
                  onChange={handleChange}
                  disabled={actionType === view}
                  accordionIndex={index}
                  openAccordion={openAccordion}
                  setOpenAccordion={setOpenAccordion}
                  onNext={handleNextAccordion}
                  onPrev={onPrev}
                  onsubmit={handleSubmit}
                  isLastAccordion={index === reportSections.length - 1}
                />
              ))}
          </>
        )}
        {!data.additional_details &&
          reportSections.map((item, index) => (
            <ReportTable
              key={index}
              title={item.section_name}
              sectionId={item._id}
              items={item.test_fields}
              onChange={handleChange}
              disabled={actionType === view}
              accordionIndex={index}
              openAccordion={openAccordion}
              setOpenAccordion={setOpenAccordion}
              onNext={handleNextAccordion}
              onPrev={onPrev}
              onsubmit={handleSubmit}
              isLastAccordion={index === reportSections.length - 1}
            />
          ))}
      </Box>
      {!isRes && reportSections.length > 0 && (
        <ModalButtons
          btnPositions={isRes ? 'center' : 'start'}
          secondaryBtnTitle={getTitles('T-10340')}
          disabled={false}
          onSecondaryBtnClick={() => {
            if (actionType === edit) {
              dispatch(setCattleActionType(view));
            }
            onPrev();
          }}
          onPrimaryBtnClick={
            actionType === view ? () => navigation(SCREEN_PATH.MANAGE_CATTLE) : handleSubmit
          }
          primaryBtnTitle={
            actionType === edit
              ? getTitles('T-10733')
              : actionType === view
                ? getTitles('T-11103')
                : getTitles('T-10339')
          }
        />
      )}
    </Box>
  );
};
