import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  religion: [],
  laboratorys: [],
  laboratoryData: null,
  currRender: null,
  completedStep: null,
  pathologists: [],
  labTech: [],
  supportEmp: [],
  activeStep: 0,
  isInit: false
};

const laboratorySlice = createSlice({
  initialState,
  name: 'laboratory',
  reducers: {
    setLaboratoryData(state, action) {
      state.laboratoryData = action.payload;
    },
    setLaboratoryStep(state, action) {
      state.completedStep = action.payload;
    },
    setLaboratoryActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setLaboratoryCurrRender(state, action) {
      state.currRender = action.payload;
    },
    setLabIsInit(state, action) {
      state.isInit = action.payload;
    },
    resetLaboratory(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.religion = [];
      state.laboratorys = [];
      state.laboratoryData = null;
      state.currRender = null;
      state.completedStep = null;
      state.pathologists = [];
      state.labTech = [];
      state.supportEmp = [];
      state.activeStep = 0;
      state.isInit = false;
    },
    resetLaboratoryMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    addLaboratoryRequest(state) {
      state.isLoading = true;
    },
    addLaboratorySuccess(state, action) {
      state.isLoading = false;
      state.laboratoryData = action.payload.data;
      state.completedStep = action.payload.data.registration_step;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
    },
    addLaboratoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getLaboratorysRequest(state) {
      state.isLoading = true;
    },
    getLaboratorySuccess(state, action) {
      state.isLoading = false;
      state.laboratorys = action.payload.data.laboratories;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getLaboratoryFailure(state, action) {
      state.isLoading = false;
      state.laboratorys = [];
      state.errorMessage = action.payload.status_code;
    },
    updateLaboratoryRequest(state) {
      state.isLoading = true;
    },
    updateLaboratorySuccess(state, action) {
      state.isLoading = false;
      state.laboratoryData = action.payload.data;
      state.completedStep = action.payload.data.registration_step;
      state.successMessage = action.payload.status_code;
      state.errorMessage = null;
    },
    updateLaboratoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getLaboratoryDetailsRequest(state) {
      state.isLoading = true;
    },
    getLaboratoryDetailsSuccess(state, action) {
      state.isLoading = false;
      state.laboratoryData = action.payload.data;
      state.completedStep = action.payload.data.registration_step;
      state.successMessage = action.payload.status_code;
    },
    getLaboratoryDetailsFailure(state, action) {
      state.isLoading = false;
      state.laboratoryData = {};
      state.errorMessage = action.payload.status_code;
    },
    getPathologistRequest(state) {
      state.isLoading = true;
    },
    getPathologistSuccess(state, action) {
      state.isLoading = false;
      state.pathologists = action.payload.data;
    },
    getPathologistFailure(state, action) {
      state.isLoading = false;
      state.pathologists = [];
      state.errorMessage = action.payload.status_code;
    },

    getLabTechRequest(state) {
      state.isLoading = true;
    },
    getLabTechSuccess(state, action) {
      state.isLoading = false;
      state.labTech = action.payload.data;
    },
    getLabTechFailure(state, action) {
      state.isLoading = false;
      state.labTech = [];
      state.errorMessage = action.payload.status_code;
    },
    getSupportEmployeeRequest(state) {
      state.isLoading = true;
    },
    getSupportEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.supportEmp = action.payload.data;
    },
    getSupportEmployeeFailure(state, action) {
      state.isLoading = false;
      state.supportEmp = [];
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  setLaboratoryStep,
  setLaboratoryData,
  setLaboratoryCurrRender,
  addLaboratoryFailure,
  addLaboratoryRequest,
  addLaboratorySuccess,
  getLaboratoryFailure,
  getLaboratorysRequest,
  getLaboratorySuccess,
  updateLaboratoryFailure,
  updateLaboratoryRequest,
  updateLaboratorySuccess,
  getLaboratoryDetailsRequest,
  getLaboratoryDetailsSuccess,
  getLaboratoryDetailsFailure,
  resetLaboratoryMsg,
  getPathologistFailure,
  getPathologistRequest,
  getPathologistSuccess,
  getLabTechFailure,
  getLabTechRequest,
  getLabTechSuccess,
  getSupportEmployeeFailure,
  getSupportEmployeeRequest,
  getSupportEmployeeSuccess,
  setLaboratoryActiveStep,
  resetLaboratory,
  setLabIsInit
} = laboratorySlice.actions;

export default laboratorySlice.reducer;
