import { Box, Toolbar, Avatar, Dialog, DialogContent, Button } from '@mui/material';
import { HeaderStyles } from './styles';
import { KEYS, SCREEN_PATH, defaultValues, lang } from '../../../constants';
import { Text, UIAutocompleted, UIButton } from '../../../ui-kits';
import logo from '../../../assets/icons/logo.png';
import languageIcon from '../../../assets/icons/language.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authStatus, getDetailsPg, getUserData } from '../../../services/redux/selectors';
import {
  updateDetailsPg,
  updateLanguage,
  updatePhone,
  updatePhoneVerified
} from '../../../services/redux/slices/authSlice';
import { getApplicationLanguage, getTitles } from '../../../utils/commonFunction';
import { globalStyles } from '../../../theme/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Sidebar } from '../../../ui-kits/side-bar';
import { UIBackButton } from '../../../ui-kits/back-button';

export const Header = ({ currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authStatus);
  const userData = useSelector(getUserData);
  const detailsPg = useSelector(getDetailsPg);
  const [open, setOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleDisplayLang = (val) => {
    setLangOpen(val);
  };

  const handleLanguage = (val) => {
    localStorage.setItem(KEYS.LANGUAGE, val?.value || lang.english);
    dispatch(updateLanguage({ language: val?.value || lang.english }));
  };

  const handleTools = (item) => {
    navigate(item.url);
    dispatch(updatePhoneVerified(false));
    dispatch(updatePhone(null));
  };

  return (
    <Box sx={HeaderStyles.container(isAuthenticated)}>
      <Box sx={HeaderStyles.subContainer()}>
        <Toolbar sx={HeaderStyles.toolbar(isAuthenticated)} disableGutters>
          {!isAuthenticated ? (
            <>
              <img src={logo} alt="logo" style={HeaderStyles.logoStyles} />
              <Box sx={HeaderStyles.toolSuperCont}>
                {defaultValues.noAuthToolbar.map((item, idx) => (
                  <Box key={idx} sx={HeaderStyles.toolCont} onClick={() => handleTools(item)}>
                    <Text variant="D24" style={HeaderStyles.toolOptionsText}>
                      {item.title}
                    </Text>
                  </Box>
                ))}
              </Box>
              <UIButton
                title={getTitles('T-10030')}
                style={HeaderStyles.loginBtn}
                onClick={() => navigate(SCREEN_PATH.LOGIN)}
              />
            </>
          ) : (
            <>
              {defaultValues.isResponsive && isAuthenticated && (
                <Box>
                  {!detailsPg ? (
                    <MenuIcon onClick={toggleDrawer('Right', true)} />
                  ) : (
                    <UIBackButton onClick={() => dispatch(updateDetailsPg(false))} />
                  )}
                </Box>
              )}

              <Box sx={{ flex: 1 }}>
                {!defaultValues.isResponsive && (
                  <Text variant="boldH32">{currentPage && currentPage}</Text>
                )}
              </Box>
              <Box sx={HeaderStyles.profileSuperCont}>
                <Box sx={HeaderStyles.languageCont}>
                  <img
                    src={languageIcon}
                    style={HeaderStyles.langImg}
                    onClick={() => {
                      setLangOpen(true);
                    }}
                  />

                  <Dialog
                    open={langOpen}
                    onClose={() => handleDisplayLang(false)}
                    sx={HeaderStyles.dialogueCont}>
                    <DialogContent sx={HeaderStyles.dialogue}>
                      {defaultValues.appLanguages.map((item, idx) => (
                        <Button
                          key={idx}
                          onClick={() => handleLanguage(item)}
                          sx={HeaderStyles.dBtn}>
                          {item.title}
                        </Button>
                      ))}
                    </DialogContent>
                  </Dialog>
                  <UIAutocompleted
                    options={defaultValues.appLanguages}
                    optionLabel={(item) => item.title}
                    containerStyle={HeaderStyles.autoCont}
                    autoCompleteStyle={HeaderStyles.autoStyle}
                    PaperBoxStyle={HeaderStyles.paperBox}
                    textInputStyles={HeaderStyles.textInp}
                    selectedValue={handleLanguage}
                    preSelectedValue={getApplicationLanguage()}
                    clearIcon={null}
                    startIcon={languageIcon}
                  />
                </Box>

                <Box sx={HeaderStyles.profile}>
                  <Avatar alt="" src={userData.photo_url} sx={HeaderStyles.avatar} />
                  {!defaultValues.isResponsive && (
                    <Text variant="D18">{`${userData.first_name} ${userData.last_name}`}</Text>
                  )}
                  <Badge badgeContent={5} sx={HeaderStyles.badge}>
                    <NotificationsIcon />
                  </Badge>
                </Box>
              </Box>
            </>
          )}
        </Toolbar>
      </Box>
      {isAuthenticated && <Divider sx={{ ...globalStyles.divider }} />}
      <Drawer open={open} onClose={toggleDrawer(false)} sx={HeaderStyles.drawer}>
        <Sidebar />
      </Drawer>
    </Box>
  );
};
