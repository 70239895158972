import { Box } from '@mui/material';
import { ActionButton, Screen, UILayout } from '../../ui-kits';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSocietyRequest,
  resetSocietyMessages,
  updateCurrSocietyData,
  updateDetailsPg,
  updateSocietyCurrentRender,
  updateSocietyParams,
  updateSocietyRequest,
  getSocietyDetailsRequest,
  updateSocietyCompletedStep,
  setSocietyActiveStep
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import {
  checkReadOnly,
  convertStringsToBoolean,
  getTitles,
  resetMessages
} from '../../utils/commonFunction';
import { BasicProfile } from '../../components';
import {
  societyErrorMsg,
  societyLoading,
  societySuccessMsg,
  getSocietyCurrPage,
  getSocietyParams,
  getSocietyTotalPage,
  getSocietys,
  getUserData,
  getSocietyCurrRender
} from '../../services/redux/selectors';
import assignIcon from '../../assets/icons/share_windows.png';

import { AddEditSociety } from './add-edit-society';
import { useNavigate } from 'react-router-dom';
import { ResActionTitle } from '../../components/common/res-action-title';

export const ManageSociety = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const societys = useSelector(getSocietys);
  const isLoading = useSelector(societyLoading);
  const errorMsg = useSelector(societyErrorMsg);
  const successMsg = useSelector(societySuccessMsg);
  const totalPages = useSelector(getSocietyTotalPage);
  const currentPage = useSelector(getSocietyCurrPage);
  const societyParams = useSelector(getSocietyParams);
  const currRender = useSelector(getSocietyCurrRender);
  const userData = useSelector(getUserData);

  const [isReadOnly, setIsReadOnly] = useState(null);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const { pending, approved, rejected } = defaultValues.approvalStatus;

  const { add, edit, view } = defaultValues.actionType;
  const res = defaultValues.isResponsive;

  const styles = {
    container: { width: '100%' },
    headCont: {
      my: 1,
      px: res ? '2% ' : '0px',
      boxSizing: 'border-box'
    },
    assign: {
      padding: res ? '0px' : '5px 20px',
      boxSizing: 'border-box',
      height: res ? '15px' : '25px',
      cursor: 'pointer'
    }
  };

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.societyManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(
        getSuccessMessage(successMsg === 'S-10058' && currRender === add ? 'S-10057' : successMsg)
      );
      if (['S-10057', 'S-10058'].includes(successMsg)) handleSociety();

      if (successMsg === 'S-10059' && currRender === edit) {
        dispatch(updateSocietyCurrentRender(3));
      } else if (successMsg === 'S-10059') {
        dispatch(updateSocietyCurrentRender(view));
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetSocietyMessages()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    handleSociety();
  }, []);

  const handleSociety = (data) => {
    const payload = { ...societyParams, ...data };
    dispatch(updateSocietyParams(payload));
    dispatch(getSocietyRequest(payload));
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleSociety({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleSociety({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleSociety({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handleStatus = (_id, is_active) => {
    dispatch(
      updateSocietyRequest({
        _id,
        is_active: !is_active
      })
    );
  };

  const handleApproval = (_id, status) => {
    dispatch(
      updateSocietyRequest({
        _id,
        approval_status: status
      })
    );
  };

  const handleEdit = (data) => {
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrSocietyData(null));
    dispatch(getSocietyDetailsRequest({ _id: data._id }));
  };

  const handleSearch = (data) => {
    handleSociety({ search_text: data, page: 1 });
  };

  const handlePagination = (e, data) => {
    handleSociety({ page: data });
  };

  const handleAddBtnClick = () => {
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrSocietyData(null));
    dispatch(updateSocietyCurrentRender(add));
    dispatch(updateSocietyCompletedStep(null));
    dispatch(setSocietyActiveStep(0));
  };

  const tableData =
    societys &&
    societys.length > 0 &&
    societys.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-10160'),
            values: `${item.society_code}`
          },
          {
            title: getTitles('T-10081'),
            values: (
              <BasicProfile
                title={item.name}
                isIcon={false}
                textVariant="D22"
                onTitleClick={() => handleEdit(item)}
              />
            )
          },
          {
            title: getTitles('T-10088'),
            values:
              item.society_type &&
              defaultValues.societyTypes.find((ele) => ele.value == item.society_type).title
          },

          {
            title: getTitles('T-10103'),
            values: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                alterTitle={defaultValues.confirmationModel.changeSocietyStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeSocietyStatus.subtitles}
                isReadOnly={isReadOnly}
              />
            )
          },
          {
            title: getTitles('T-10707'),
            columnWidth: '15%',
            values: (
              <ActionButton
                status={item.approval_status === approved}
                onStatusChange={() => {
                  let newStatus;
                  if ([pending, rejected].includes(item.approval_status)) {
                    newStatus = approved;
                  } else if (item.approval_status === approved) {
                    newStatus = rejected;
                  }
                  handleApproval(item._id, newStatus);
                }}
                alterTitle={defaultValues.confirmationModel.changeSocietyApprovalStatus.title(
                  item.approval_status
                )}
                alterSubTitle={
                  defaultValues.confirmationModel.changeSocietyApprovalStatus.subtitles
                }
                isReadOnly={isReadOnly}
                showImg={[approved, rejected].includes(item.approval_status)}
                pending={item.approval_status === pending}
              />
            )
          },
          {
            title: getTitles('T-11166'),
            columnWidth: '15%',
            values: (
              <img
                src={assignIcon}
                style={styles.assign}
                onClick={() => {
                  dispatch(updateDetailsPg(true));
                  navigation(SCREEN_PATH.ASSOCIATED_FARMER, {
                    state: { societyName: item.name, societyId: item._id }
                  });
                }}
              />
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.name}
              isIcon={false}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10160'),
              action: item.society_code
            },
            {
              subTitle: getTitles('T-10088'),
              action:
                item.society_type &&
                defaultValues.societyTypes.find((ele) => ele.value == item.society_type).title
            },

            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  onStatusChange={() => handleStatus(item._id, item.is_active)}
                  alterTitle={defaultValues.confirmationModel.changeSocietyStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.changeSocietyStatus.subtitles}
                  isReadOnly={isReadOnly}
                />
              )
            },
            {
              subTitle: getTitles('T-10707'),
              action: (
                <ActionButton
                  status={item.approval_status === approved}
                  onStatusChange={() => {
                    let newStatus;
                    if ([pending, rejected].includes(item.approval_status)) {
                      newStatus = approved;
                    } else if (item.approval_status === approved) {
                      newStatus = rejected;
                    }
                    handleApproval(item._id, newStatus);
                  }}
                  alterTitle={defaultValues.confirmationModel.changeSocietyApprovalStatus.title(
                    item.approval_status
                  )}
                  alterSubTitle={
                    defaultValues.confirmationModel.changeSocietyApprovalStatus.subtitles
                  }
                  isReadOnly={isReadOnly}
                  showImg={[approved, rejected].includes(item.approval_status)}
                  pending={item.approval_status === pending}
                />
              )
            },
            !isReadOnly && {
              subTitle: getTitles('T-11166'),
              action: (
                <img
                  src={assignIcon}
                  style={styles.assign}
                  onClick={() => {
                    dispatch(updateDetailsPg(true));
                    navigation(SCREEN_PATH.ASSOCIATED_FARMER, {
                      state: { societyName: item.name, societyId: item._id }
                    });
                  }}
                />
              )
            }
          ]
        };
      }
    });

  if ([add, edit, view].includes(currRender)) {
    return (
      <Screen showSideBar={true} currentPage={getTitles('T-10118')} isLoading={isLoading}>
        {res && (
          <ResActionTitle
            title={
              currRender === add
                ? getTitles('T-10778')
                : currRender === edit
                  ? getTitles('T-11205')
                  : getTitles('T-10994')
            }
            handleBtnClick={
              currRender === view && !isReadOnly
                ? () => dispatch(updateSocietyCurrentRender(edit))
                : null
            }
          />
        )}
        <AddEditSociety isReadOnly={isReadOnly} currRender={currRender} />
      </Screen>
    );
  }

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10118')} isLoading={isLoading}>
      <Box sx={styles.container}>
        <UILayout
          isMultiSelectFilterOption={true}
          handleAddButton={handleAddBtnClick}
          currPageName={getTitles('T-10118')}
          handleSearch={handleSearch}
          showNoData={!societys.length > 0}
          currPage={currentPage}
          pageCount={totalPages}
          handlePagination={handlePagination}
          tableItems={tableData}
          showPagination={societys.length > 0 && totalPages > 1}
          searchBarPlaceHolder={getTitles('T-11004')}
          showDataTable={!res}
          message={getErrorMessage('E-10145')}
          isReadOnly={isReadOnly}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          handleRefresh={handleRefresh}
          handleSort={handleSort}
          handleFilter={handleFilter}
          optionsForFiltering={defaultValues.societyFilterOption}
          optionsForSorting={defaultValues.farmerSortOptions}
          clearSearch={filter}
          onTitleClick={handleEdit}
        />
      </Box>
    </Screen>
  );
};
