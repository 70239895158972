import { Box } from '@mui/material';
import { DatePickers, NoData, UISelect, UITextInputWithTitle } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { employeeEmploymentSchema, getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../..';
import { globalStyles } from '../../../../theme';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { defaultValues } from '../../../../constants';
import { ViewEmploymentDetails } from './view-employment';
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  width: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)',
  widthYears: defaultValues.isResponsive ? '100%' : 'calc((100% + 10px)/3)',
  dateCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  }
};

export const EmployeeEmploymentDetails = ({
  employeeData,
  employeeId,
  designations,
  departments,
  editable
}) => {
  const dispatch = useDispatch();
  const [btnDisabled, setBtndisabled] = useState(true);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldTouched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      highest_qual: '',
      years_experience: '',
      department_id: '',
      designation_id: '',
      date_joining: null,
      date_relieving: null,
      salary: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeeEmploymentSchema
  });

  useEffect(() => {
    if (employeeData) {
      let newValues = {
        ...employeeData,
        date_relieving: dayjs(employeeData.date_relieving),
        date_joining: dayjs(employeeData.date_joining)
      };

      setValues(newValues);
    }
  }, [employeeData, setValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { _id: employeeId, employment_details: { ...values } };
    dispatch(updateEmployeeRequest(payload));
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.nameCont}>
          <UISelect
            name="department_id"
            title={getTitles('T-10320')}
            options={departments}
            returnValue="_id"
            optionLabel="name"
            placeholder={getTitles('T-10350')}
            error={touched.department_id && errors.department_id}
            value={values.department_id}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            required={true}
          />

          <UISelect
            name="designation_id"
            title={getTitles('T-10050')}
            options={designations}
            returnValue="_id"
            optionLabel="name"
            placeholder={getTitles('T-10350')}
            error={touched.designation_id && errors.designation_id}
            value={values.designation_id}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            required={true}
          />
          <UITextInputWithTitle
            name="salary"
            title={getTitles('T-10419')}
            placeHolder={getTitles('T-10380')}
            error={touched.salary && errors.salary}
            value={values.salary}
            onChange={handleChange}
            onBlur={handleBlur}
            allowOnlyNumbers={true}
            width={styles.width}
            required={true}
          />
        </Box>

        <Box sx={styles.dateCont}>
          <DatePickers
            dateValue={values.date_joining}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'date_joining' } })}
            title={getTitles('T-10378')}
            width={styles.width}
            required={true}
            maxDate={values.date_relieving}
            disableFuture={true}
            error={touched.date_joining && errors.date_joining}
            onOpen={() => setFieldTouched('date_joining', true)}
          />

          <DatePickers
            dateValue={values.date_relieving}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'date_relieving' } })}
            title={getTitles('T-10379')}
            width={styles.width}
            error={errors.date_relieving}
            minDate={values.date_joining}
            disableFuture={true}
          />
        </Box>

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData ? (
        <ViewEmploymentDetails
          employeeData={employeeData}
          departments={departments}
          designations={designations}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
