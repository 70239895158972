import { Box } from '@mui/material';
import { ModalButtons } from '../../common/modal-buttons';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { getTitles, resetMessages } from '../../../utils';
import { MultiImage } from '../../../ui-kits';
import { Styles } from '../style';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getCattleActionType,
  getCattleData,
  getCattleError,
  getCattleSuccess
} from '../../../services/redux/selectors';
import {
  deleteCattlePhotoRequest,
  resetCattleMsg,
  setCattleActionType,
  uploadCattlePhotosRequest
} from '../../../services/redux/slices';

const isRes = defaultValues.isResponsive;

export const UploadPhotos = ({ onPrev, onNext, setNext }) => {
  const dispatch = useDispatch();
  const { view, edit, add } = defaultValues.actionType;

  const success = useSelector(getCattleSuccess);
  const data = useSelector(getCattleData);
  const error = useSelector(getCattleError);
  const actionType = useSelector(getCattleActionType);

  const [isData, setIsDat] = useState(false);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      _id: '',
      farmer_id: '',
      registration_step: 2,
      front_view: [],
      back_view: [],
      right_view: [],
      left_view: [],
      color: [],
      horn: [],
      tail_switch: [],
      others: []
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const handleImagesChange = (key) => (newImages) => {
    setFieldValue(key, newImages);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    values.front_view.forEach((item) => formData.append('front_view', item));
    values.back_view.forEach((item) => formData.append('back_view', item));
    values.right_view.forEach((item) => formData.append('right_view', item));
    values.left_view.forEach((item) => formData.append('left_view', item));
    values.color.forEach((item) => formData.append('color', item));
    values.horn.forEach((item) => formData.append('horn', item));
    values.tail_switch.forEach((item) => formData.append('tail_switch', item));
    values.others.forEach((item) => formData.append('others', item));

    formData.append('_id', values._id);
    formData.append('farmer_id', values.farmer_id);
    formData.append('registration_step', values.registration_step);

    dispatch(uploadCattlePhotosRequest(formData));
    if (actionType === edit) {
      dispatch(setCattleActionType(view));
    }
  };

  useEffect(() => {
    if (success === 'S-10065') {
      if (actionType === add) {
        onNext();
      } else {
        toast.success(getSuccessMessage('S-10065'));
      }
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetCattleMsg()));
  }, [success, error]);

  useEffect(() => {
    if (data) {
      setFieldValue('_id', data._id);
      setFieldValue('farmer_id', data.farmer_id);
      if (data.upload_photos) {
        setIsDat(true);
        setFieldValue('front_view', data.upload_photos.front_view);
        setFieldValue('back_view', data.upload_photos.back_view);
        setFieldValue('right_view', data.upload_photos.right_view);
        setFieldValue('left_view', data.upload_photos.left_view);
        setFieldValue('color', data.upload_photos.color);
        setFieldValue('horn', data.upload_photos.horn);
        setFieldValue('tail_switch', data.upload_photos.tail_switch);
        setFieldValue('others', data.upload_photos.others);
      }
    }
  }, [data]);

  const handleDelete = (type, img) => {
    dispatch(
      deleteCattlePhotoRequest({
        _id: values._id,
        farmer_id: values.farmer_id,
        photo_type: type,
        photo_url: img
      })
    );
  };
  return (
    <Box sx={Styles.secSecondContainer}>
      <Box sx={Styles.secSecondSubContainer}>
        <MultiImage
          title={getTitles('T-11042')}
          images={values.front_view}
          onImagesChange={handleImagesChange('front_view')}
          prevImg={values.front_view}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('front_view', val) : null}
        />
        <MultiImage
          title={getTitles('T-11043')}
          images={values.back_view}
          onImagesChange={handleImagesChange('back_view')}
          prevImg={values.back_view}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('back_view', val) : null}
        />
        <MultiImage
          title={getTitles('T-11044')}
          images={values.right_view}
          onImagesChange={handleImagesChange('right_view')}
          prevImg={values.right_view}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('right_view', val) : null}
        />
        <MultiImage
          title={getTitles('T-11045')}
          images={values.left_view}
          onImagesChange={handleImagesChange('left_view')}
          prevImg={values.left_view}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('left_view', val) : null}
        />
        <MultiImage
          title={getTitles('T-11046')}
          images={values.color}
          onImagesChange={handleImagesChange('color')}
          prevImg={values.color}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('color', val) : null}
        />
        <MultiImage
          title={getTitles('T-11047')}
          images={values.horn}
          onImagesChange={handleImagesChange('horn')}
          prevImg={values.horn}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('horn', val) : null}
        />
        <MultiImage
          title={getTitles('T-11048')}
          images={values.tail_switch}
          onImagesChange={handleImagesChange('tail_switch')}
          prevImg={values.tail_switch}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('tail_switch', val) : null}
        />
        <MultiImage
          title={getTitles('T-11049')}
          images={values.others}
          onImagesChange={handleImagesChange('others')}
          prevImg={values.others}
          disabled={actionType === view}
          imageToDelete={actionType !== add ? (val) => handleDelete('others', val) : null}
        />
      </Box>
      <ModalButtons
        btnPositions={isRes ? 'center' : 'start'}
        secondaryBtnTitle={getTitles('T-10340')}
        disabled={false}
        onSecondaryBtnClick={() => {
          if (actionType === edit) {
            dispatch(setCattleActionType(view));
          }
          onPrev();
        }}
        onPrimaryBtnClick={actionType === view ? () => setNext(2) : handleSubmit}
        primaryBtnTitle={
          actionType === add && isData
            ? getTitles('T-10733')
            : actionType === edit
              ? getTitles('T-10733')
              : actionType === view
                ? getTitles('T-10804')
                : getTitles('T-10339')
        }
      />
    </Box>
  );
};
