import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import {
  getInsurance,
  getInsuranceCompany,
  getInsuranceCurPage,
  getInsuranceCurrRender,
  getInsuranceData,
  getInsuranceErrorMsg,
  getInsuranceLoading,
  getInsuranceParams,
  getInsuranceSuccessMsg,
  getInsuranceTotalPage
} from '../../services/redux/selectors/insuranceSelector';
import { useEffect, useState } from 'react';
import {
  getCompanyRequest,
  getInsurancesRequest,
  resetInsuranceMsg,
  setInsuranceCurrentRender,
  setInsuranceData,
  setInsuranceParams,
  updateDetailsPg,
  updateInsuranceRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { checkReadOnly, convertStringsToBoolean, getTitles, resetMessages } from '../../utils';
import { ActionButton, EditButton, Screen, Text, UIButton, UILayout } from '../../ui-kits';
import { AddEditInsurance } from './add-edit-insurance';
import { BasicProfile } from '../../components';
import { getUserData } from '../../services/redux/selectors';
import { Box } from '@mui/material';
import { processLoading } from '../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  resCurrPgNameCont: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px',
    marginRight: 0
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  },
  btn: {
    minWidth: res && 0,
    fontSize: res ? '1.35rem' : '1.3rem',
    padding: res ? '5px' : '5px 20px',
    width: res ? 'fit-content' : '150px',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box'
  }
};

export const ManageInsurance = () => {
  const dispatch = useDispatch();

  const insurance = useSelector(getInsurance);
  const errorMsg = useSelector(getInsuranceErrorMsg);
  const isLoading = useSelector(getInsuranceLoading);
  const successMsg = useSelector(getInsuranceSuccessMsg);
  const currRender = useSelector(getInsuranceCurrRender);
  const insuranceCompany = useSelector(getInsuranceCompany);
  const currentPage = useSelector(getInsuranceCurPage);
  const totalPages = useSelector(getInsuranceTotalPage);
  const insuranceParams = useSelector(getInsuranceParams);
  const insuranceData = useSelector(getInsuranceData);
  const userData = useSelector(getUserData);
  const processIsLoading = useSelector(processLoading);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(null);

  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    dispatch(getCompanyRequest({ master_id: defaultValues.mListIds.insuranceCompany }));
    handleInsurance();
  }, []);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.insuranceManager));
  }, [userData]);

  useEffect(() => {
    if (insuranceData) {
      const data = insurance.find((item) => item._id === insuranceData._id);
      dispatch(setInsuranceData(data || null));
    }
  }, [insurance]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));

      if (['S-10084', 'S-10085'].includes(successMsg)) handleInsurance();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetInsuranceMsg()));
  }, [successMsg, errorMsg]);

  const handleInsurance = (data) => {
    const payload = { ...insuranceParams, ...data };
    dispatch(setInsuranceParams(payload));
    dispatch(getInsurancesRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(
      updateInsuranceRequest({
        ...item,
        _id: item._id,
        is_active: !item.is_active,
        state_id: item.state_id?._id,
        district_id: item.district_id?._id,
        taluka_id: item.taluka_id?._id,
        village_id: item.village_id?._id
      })
    );
  };

  const handleEdit = (data) => {
    dispatch(updateDetailsPg(true));
    dispatch(setInsuranceCurrentRender(view));
    dispatch(setInsuranceData(data));
  };

  const handleSearch = (data) => {
    dispatch(getInsurancesRequest({ search_text: data }));
  };
  const handleFilter = (data) => {
    setFilter(data);
    handleInsurance({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleInsurance({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleInsurance({ page: 1, search_text: null, filter: null, sort: null });
  };

  const findName = (arr, id) => {
    if (arr && arr.length > 0) {
      const newVal = arr.find((ele) => ele._id === id);
      return newVal && newVal.name;
    }
    return null;
  };

  const handlePagination = (e, data) => {
    handleInsurance({ page: data });
  };

  const tableData =
    insurance &&
    insurance.length > 0 &&
    insurance.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-11138'),
            values: (
              <BasicProfile
                title={`${[item.first_name, item.middle_name, item.last_name].join(' ')}`}
                isIcon={false}
                textVariant="D22"
                onTitleClick={() => handleEdit(item)}
              />
            )
          },
          {
            title: getTitles('T-10385'),
            values: findName(insuranceCompany, item.insurance_company_id)
          },

          {
            title: getTitles('T-10440'),
            values: item.phone_number
          },

          {
            title: getTitles('T-10103'),
            values: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item)}
                alterTitle={defaultValues.confirmationModel.changeInsuranceStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.subTitle}
                isReadOnly={isReadOnly}
              />
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={`${[item.first_name, item.middle_name, item.last_name].join(' ')}`}
              isIcon={false}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10385'),
              action: findName(insuranceCompany, item.insurance_company_id)
            },
            {
              subTitle: getTitles('T-10440'),
              action: item.phone_number
            },

            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  onStatusChange={() => handleStatus(item)}
                  alterTitle={defaultValues.confirmationModel.changeInsuranceStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.subTitle}
                  isReadOnly={isReadOnly}
                />
              )
            }
          ]
        };
      }
    });

  if ([add, edit, view].includes(currRender)) {
    return (
      <Screen
        showSideBar={true}
        isLoading={isLoading || processIsLoading}
        currentPage={getTitles('T-10124')}>
        {res && ![1].includes(currRender) && (
          <Box sx={styles.resCurrPgNameCont}>
            <Text variant="boldH32Sec">
              {currRender === add
                ? getTitles('T-11139')
                : currRender === view
                  ? getTitles('T-10656')
                  : getTitles('T-11143')}
            </Text>
            {currRender === view && !isReadOnly && (
              <UIButton
                startIcon={<EditButton iconStyle={styles.editIcon} />}
                style={styles.btn}
                onClick={() => dispatch(setInsuranceCurrentRender(edit))}
              />
            )}
          </Box>
        )}
        <AddEditInsurance
          insuranceData={insuranceData}
          insuranceCompany={insuranceCompany}
          isReadOnly={isReadOnly}
        />
      </Screen>
    );
  }

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading || processIsLoading}
      currentPage={getTitles('T-10124')}>
      {res && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10124')}
        </Text>
      )}
      <UILayout
        isMultiSelectFilterOption={true}
        currentlyActiveSortOption={sort}
        currentlyActiveFilterOption={filter}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-10325')}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        handleFilter={handleFilter}
        optionsForSorting={defaultValues.farmerSortOptions}
        clearSearch={filter}
        handleAddButton={() => {
          dispatch(setInsuranceCurrentRender(add));
          dispatch(setInsuranceData(null));
          dispatch(updateDetailsPg(true));
        }}
        tableItems={tableData}
        showNoData={!insurance.length > 0}
        showDataTable={!res}
        message={getErrorMessage('E-10172')}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        showPagination={insurance.length > 0 && totalPages > 1}
        onTitleClick={(ele) => handleEdit(ele)}
        isReadOnly={isReadOnly}
      />
    </Screen>
  );
};
