import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { checkReadOnly, getTitles, resetMessages } from '../../../utils/commonFunction';
import { BasicProfile } from '../../../components';
import { ActionButton, EditButton, Screen, Text, UILayout } from '../../../ui-kits';

import { toast } from 'react-toastify';
import {
  getCattleActive,
  getCattleCurr,
  getCattleError,
  getCattleFarmer,
  getCattleList,
  getCattleLoading,
  getCattleSuccess,
  getCattleTotal,
  getUserData
} from '../../../services/redux/selectors';
import { Box } from '@mui/material';
import {
  getCattlesRequest,
  resetCattleMsg,
  setCattleActionType,
  setCurrentCattleActivPage,
  updateCattleRequest,
  updateDetailsPg
} from '../../../services/redux/slices';

export const ManageCattle = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { approved, rejected, pending } = defaultValues.approvalStatus;

  const cattleList = useSelector(getCattleList);
  const success = useSelector(getCattleSuccess);
  const error = useSelector(getCattleError);
  const isLoading = useSelector(getCattleLoading);
  const totalPages = useSelector(getCattleTotal);
  const currentPage = useSelector(getCattleCurr);
  const activePage = useSelector(getCattleActive);
  const userData = useSelector(getUserData);
  const farmerD = useSelector(getCattleFarmer);

  const [farmerId, setFarmerId] = useState('');
  const [farmerName, setFarmerName] = useState('');
  const [url, setUrl] = useState('');
  const [phone, setPhone] = useState('');
  const [cattles, setCattles] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [hasAccess, setAcess] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (activePage && activePage.page) {
      setPage(activePage.page);
    }
  }, [activePage]);

  useEffect(() => {
    if (farmerD) {
      if (farmerD.farmer_id) {
        setFarmerId(farmerD.farmer_id);
      }
      if (farmerD.name) {
        setFarmerName(farmerD.name);
      }
      if (farmerD.photo) {
        setUrl(farmerD.photo);
      }
      if (farmerD.phone) {
        setPhone(farmerD.phone);
      }

      if (farmerD.approvalStatus !== 2 || !farmerD.status) {
        setIsValid(true);
      }
    }
  }, [farmerD]);

  useEffect(() => {
    if (farmerId) {
      dispatch(
        getCattlesRequest({
          farmer_id: farmerId,
          page: page,
          sort: activePage && activePage.sort,
          filter: activePage && activePage.filter
        })
      );
    }
    dispatch(setCurrentCattleActivPage(null));
  }, [farmerId]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.farmerManager));
  }, [userData]);

  useEffect(() => {
    if (cattleList) {
      setCattles(cattleList);
    }
  }, [cattleList]);

  useEffect(() => {
    if (userData) {
      if (userData.user_role === defaultValues.userRole.superAdmin) {
        setAcess(true);
      } else if (userData.user_role === defaultValues.userRole.employee) {
        const list =
          userData.employee_permissions &&
          userData.employee_permissions.filter(
            (item) => item.permission_id === defaultValues.permissionsIds.FarmerManager
          );

        const hasApprovePermission = list && list.some((item) => item.has_approve_permission);

        if (hasApprovePermission) {
          setAcess(true);
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (success === 'S-10063') {
      toast.success(getSuccessMessage(success));
      dispatch(getCattlesRequest({ farmer_id: farmerId, page: currentPage }));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetCattleMsg()));
  }, [success, error]);

  const handleFilter = (data) => {
    setFilter(data);
    dispatch(
      getCattlesRequest({
        farmer_id: farmerId,
        filter: JSON.stringify(data),
        sort: sort
      })
    );
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(
      getCattlesRequest({
        farmer_id: farmerId,
        sort: data,
        filter: JSON.stringify(filter)
      })
    );
  };

  const handleSearch = (data) => {
    dispatch(
      getCattlesRequest({
        farmer_id: farmerId,
        search_text: data
      })
    );
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    dispatch(
      getCattlesRequest({
        farmer_id: farmerId
      })
    );
  };

  const handlePagination = (e, data) => {
    dispatch(
      getCattlesRequest({
        farmer_id: farmerId,
        page: data,
        sort: sort,
        filter: JSON.stringify(filter)
      })
    );
  };

  const handleAdd = () => {
    dispatch(setCattleActionType(defaultValues.actionType.add));
    dispatch(updateDetailsPg(true));
    dispatch(setCurrentCattleActivPage(null));
    navigation(SCREEN_PATH.REGISTER_CATTLE, { state: { farmer_id: farmerId } });
  };

  const handleStatus = (_id, is_active) => {
    dispatch(
      updateCattleRequest({
        _id,
        farmer_id: farmerId,
        is_active: !is_active
      })
    );
  };

  const handleApproval = (_id, status) => {
    dispatch(
      updateCattleRequest({
        _id,
        farmer_id: farmerId,
        approval_status: status
      })
    );
  };

  const tableData = cattles.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          values: <Text variant="D20">{item.cattle_id}</Text>
        },
        {
          title: getTitles('T-10081'),
          values: (
            <BasicProfile
              title={item.cattle_name}
              url={item.photo_url}
              textVariant="D22"
              onTitleClick={() => {
                dispatch(setCattleActionType('view'));
                dispatch(updateDetailsPg(true));
                dispatch(
                  setCurrentCattleActivPage({
                    page: currentPage,
                    sort: sort,
                    filter: JSON.stringify(filter)
                  })
                );
                navigation(SCREEN_PATH.REGISTER_CATTLE, { state: { _id: item._id } });
              }}
            />
          )
        },
        {
          title: getTitles('T-11203'),
          values: (
            <ActionButton
              alterTitle={defaultValues.confirmationModel.changeCattleStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              status={item.is_active}
              isReadOnly={isReadOnly}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
            />
          )
        },
        hasAccess && {
          title: getTitles('T-11204'),
          values: (
            <ActionButton
              showImg={item.approval_status === approved || item.approval_status === rejected}
              pending={item.approval_status === pending}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              status={item.approval_status === approved}
              onStatusChange={() => {
                let newStatus;
                if (item.approval_status === pending || item.approval_status === rejected) {
                  newStatus = approved;
                } else if (item.approval_status === approved) {
                  newStatus = rejected;
                }
                handleApproval(item._id, newStatus);
              }}
              alterTitle={defaultValues.confirmationModel.changeCattleApprovalStatus.title(
                item.approval_status
              )}
            />
          )
        },
        !isReadOnly && {
          title: getTitles('T-10084'),
          values: (
            <EditButton
              status={item.is_active}
              onClick={() => {
                dispatch(setCattleActionType(defaultValues.actionType.edit));
                dispatch(updateDetailsPg(true));
                dispatch(
                  setCurrentCattleActivPage({
                    page: currentPage,
                    sort: sort,
                    filter: JSON.stringify(filter)
                  })
                );
                navigation(SCREEN_PATH.REGISTER_CATTLE, { state: { _id: item._id } });
              }}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <BasicProfile
            title={item.cattle_name}
            url={item.photo_url}
            textVariant="D22"
            onTitleClick={() => {
              dispatch(setCattleActionType('view'));
              dispatch(updateDetailsPg(true));
              dispatch(
                setCurrentCattleActivPage({
                  page: currentPage,
                  sort: JSON.stringify(sort),
                  filter: JSON.stringify(filter)
                })
              );
              navigation(SCREEN_PATH.REGISTER_CATTLE, { state: { _id: item._id } });
            }}
          />
        ),
        details: [
          {
            subTitle: getTitles('T-10751'),
            action: <Text variant="D20">{item.cattle_id}</Text>
          },
          {
            subTitle: getTitles('T-11203'),
            action: (
              <ActionButton
                alterTitle={defaultValues.confirmationModel.changeCattleStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                isReadOnly={isReadOnly}
              />
            )
          },
          hasAccess && {
            subTitle: getTitles('T-11204'),
            action: (
              <ActionButton
                showImg={item.approval_status === approved || item.approval_status === rejected}
                pending={item.approval_status === pending}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                status={item.approval_status === approved}
                onStatusChange={() => {
                  let newStatus;
                  if (item.approval_status === pending || item.approval_status === rejected) {
                    newStatus = approved;
                  } else if (item.approval_status === approved) {
                    newStatus = rejected;
                  }
                  handleApproval(item._id, newStatus);
                }}
                alterTitle={defaultValues.confirmationModel.changeCattleApprovalStatus.title(
                  item.approval_status
                )}
              />
            )
          },
          !isReadOnly && {
            subTitle: getTitles('T-10084'),
            action: (
              <EditButton
                status={item.is_active}
                onClick={() => {
                  dispatch(setCattleActionType(defaultValues.actionType.edit));
                  dispatch(updateDetailsPg(true));
                  dispatch(
                    setCurrentCattleActivPage({
                      page: currentPage,
                      sort: sort,
                      filter: JSON.stringify(filter)
                    })
                  );
                  navigation(SCREEN_PATH.REGISTER_CATTLE, { state: { _id: item._id } });
                }}
              />
            )
          }
        ]
      };
    }
  });

  const styles = { farmerProfile: { py: 2, px: 1 } };
  return (
    <Screen
      showSideBar={true}
      menus={defaultValues.superAdminMenu(userData.user_role === 1)}
      isLoading={isLoading}
      currentPage={getTitles('T-10117')}>
      {!defaultValues.isResponsive && (
        <Box sx={styles.farmerProfile}>
          <BasicProfile
            title={farmerName}
            phone={` (${phone})`}
            url={url}
            onTitleClick={() => navigation(SCREEN_PATH.MANAGE_FARMER)}
          />
        </Box>
      )}
      <UILayout
        currPageName={getTitles('T-10117')}
        isReadOnly={isReadOnly || isValid}
        {...(defaultValues.isResponsive && {
          customData: (
            <Box sx={styles.farmerProfile}>
              <BasicProfile
                title={farmerName}
                subTitle={` (${phone})`}
                url={url}
                onTitleClick={() => navigation(SCREEN_PATH.MANAGE_FARMER)}
              />
            </Box>
          )
        })}
        isMultiSelectFilterOption={true}
        searchBarPlaceHolder={getTitles('T-11062')}
        handleFilter={handleFilter}
        handleAddButton={handleAdd}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        optionsForSorting={defaultValues.cattleSortOptions}
        handleSearch={handleSearch}
        clearSearch={filter}
        tableItems={tableData}
        showNoData={!cattles.length > 0}
        currPage={currentPage}
        pageCount={totalPages}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        showPagination={totalPages > 1}
        handlePagination={handlePagination}
        showDataTable={!defaultValues.isResponsive}
        message={getErrorMessage('E-10155')}
      />
    </Screen>
  );
};
