import { SvgIcon } from '@mui/material';

export const LocationIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ height: '30px', width: 'auto' }} // Set the height and maintain aspect ratio
      viewBox="0 0 150 200" // Adjust viewBox to match the original SVG dimensions
    >
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none">
        <path d="M450 1880 c-460 -270 -470 -730 -30 -1240 l320 -360 330 360 c350 380 430 790 210 1090 -170 220 -590 290 -830 150z m530 -550 c50 -170 -260 -290 -400 -150 -60 60 -70 150 -30 220 90 140 370 90 430 -70z" />
        <path d="M1150 380 c-80 -210 -710 -250 -780 -50 -20 60 -80 80 -130 40 -160 -160 290 -390 650 -330 400 60 440 90 380 330 -40 170 -40 170 -120 10z" />
      </g>
    </SvgIcon>
  );
};
