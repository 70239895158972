import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { useState } from 'react';
import { defaultValues } from '../../../../../constants';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewFamily = ({ employeeData }) => {
  const [index, setIndex] = useState(0);
  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.length > 0 &&
        employeeData.map((item, idx) => (
          <Box key={idx} sx={{ width: '100%' }}>
            <CollapseDropView
              title={item.name}
              handleSubTitleClick={() => setIndex(index === idx ? null : idx)}
              open={index === idx}
            />
            {index === idx && (
              <Box sx={{ width: '100%' }}>
                <ViewData
                  title={getTitles('T-10449')}
                  subTitle={
                    item.relationship &&
                    defaultValues.relationshipOptions.find((ele) => ele.value == item.relationship)
                      .title
                  }
                />
                <ViewData title={getTitles('T-10450')} subTitle={item.age} />
                <ViewData
                  title={getTitles('T-10351')}
                  subTitle={
                    item.gender &&
                    defaultValues.genderOptions.find((ele) => ele.value == item.gender).title
                  }
                />

                <ViewData title={getTitles('T-10451')} subTitle={item.occupation} />
                <ViewData
                  title={getTitles('T-10697')}
                  subTitle={
                    ![null, undefined].includes(item.insurance_covered) &&
                    defaultValues.insuredOptions.find((ele) => ele.value === item.insurance_covered)
                      .title
                  }
                />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};
