import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { defaultValues } from '../../constants';
import { Box } from '@mui/material';
import { color } from '../../theme';
import { getTitles } from '../../utils/commonFunction';

export const UITimePicker = ({
  value,
  selectedTime,
  containerStyle,
  disabled = false,
  width,
  inputStyle,
  isLabel = true
}) => {
  const styles = {
    input: (inputStyle) => ({
      '& .MuiInputBase-input': {
        width: 'calc(100% - 20px)',
        fontSize: defaultValues.isResponsive ? '14px' : '16px',
        padding: defaultValues.isResponsive ? '9.5px 7px' : '13px 14px'
      },
      '& .MuiButtonBase-root': {
        padding: '5px'
      },
      backgroundColor: color.primaryBackground,
      width: 'calc(100% - 2px)',
      borderRadius: 1,
      border: '1px solid transparent',
      ...inputStyle
    }),
    container: (styles) => ({
      width: width || '100%',
      marginTop: '15px',
      ...styles
    }),

    errorStyle: {
      marginTop: '5px'
    }
  };
  return (
    <Box sx={styles.container(containerStyle)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          sx={styles.input(inputStyle)}
          label={isLabel && getTitles('T-11322')}
          value={value}
          onChange={(newValue) => {
            selectedTime(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Box>
  );
};
