import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../constants';
import { color } from '../../theme';
import { getTitles } from '../../utils';

export const SelectDropdown = ({ options, selOP, selectedOption }) => {
  const styles = {
    select: {
      // my: defaultValues.isResponsive && 1,
      mx: !defaultValues.isResponsive && 1,
      minWidth: defaultValues.isResponsive ? 120 : 150,
      backgroundColor: color.primaryBackground
    },
    dropDown: { height: '48px' }
  };

  const [op, setOP] = useState('');

  useEffect(() => {
    setOP(selOP);
  }, [selOP]);

  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    setOP(selectedValue);
    selectedOption && selectedOption(selectedValue);
  };
  return (
    <FormControl sx={styles.select}>
      <InputLabel>{getTitles('T-10087')}</InputLabel>
      <Select value={op} onChange={handleSelect} sx={styles.dropDown} label={getTitles('T-10087')}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
