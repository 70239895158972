import { defaultValues } from '../../constants';
import { Screen, Text, UIButton, UITablist } from '../../ui-kits';
import { checkReadOnly, getTitles } from '../../utils/commonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUserData } from '../../services/redux/selectors';

import { setCallcenterCurrTab } from '../../services/redux/slices';
import {
  getCallcenterCurrTab,
  getCallcenterLoading,
  getCallcenterUserInfo
} from '../../services/redux/selectors/callcenterSelector';
import { CallHistory, CallInfoBox, TaskCallCenterModal, TasksCallcenter } from '../../components';
import { styles } from './styles';

export const CallCenter = () => {
  const dispatch = useDispatch();

  const caller = useSelector(getCallcenterUserInfo);

  const { employee } = defaultValues.callCenterUserTypes;
  const { callerTask, callerCallHis } = defaultValues.callCenterCurrTabs;
  const { inactive } = defaultValues.callStatus;
  const { add, edit } = defaultValues.actionType;

  const isLoading = useSelector(getCallcenterLoading);
  const userData = useSelector(getUserData);
  const currTab = useSelector(getCallcenterCurrTab);

  const [isReadOnly, setIsReadOnly] = useState(null);
  const [open, setOpen] = useState(null);
  const [callState, setCallState] = useState(inactive);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.processManager));
  }, [userData]);

  useEffect(() => {
    dispatch(setCallcenterCurrTab(0));
  }, []);

  const handleOpen = (val) => {
    setOpen(val);
  };

  const tabList = [
    {
      value: <TasksCallcenter currTab={currTab} isReadOnly={isReadOnly} />,
      title: getTitles('T-11236')
    },
    {
      value: <TasksCallcenter currTab={currTab} />,
      title: getTitles('T-11237')
    },
    { value: <CallHistory currTab={currTab} />, title: getTitles('T-11238') }
  ];

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11235')}>
      <UIButton title="add member" onClick={() => setOpen({ type: employee, mode: add })} />
      <UIButton title="edit member" onClick={() => setOpen({ type: employee, mode: edit })} />

      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}

      <CallInfoBox
        caller={caller}
        handleCreateTaskModal={handleOpen}
        callState={callState}
        setCallState={setCallState}
      />

      {currTab === callerTask && <TasksCallcenter currTab={currTab} isReadOnly={isReadOnly} />}
      {currTab === callerCallHis && <CallHistory currTab={currTab} />}
      <UITablist
        renderElements={tabList}
        currentTab={currTab}
        onTabChange={(val) => dispatch(setCallcenterCurrTab(val))}
        tabStyle={styles.tabStyle}
      />
      <TaskCallCenterModal showModel={open} onClose={() => handleOpen(null)} />
    </Screen>
  );
};
