import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewVehicleDetails = ({ employeeData }) => {
  return (
    <Box sx={styles.container}>
      {employeeData && (
        <>
          <ViewData title={getTitles('T-10654')} subTitle={employeeData.vehicle_number} />

          <ViewData title={getTitles('T-10372')} subTitle={employeeData.insurance_policy_number} />
          <ViewData title={getTitles('T-10655')} subTitle={employeeData.insurance_provider} />
          <ViewData title={getTitles('T-10656')} subTitle={employeeData.details} />
          <ViewData
            title={getTitles('T-10651')}
            subTitle={employeeData.start_date && getFormatDate(employeeData.start_date)}
          />
          <ViewData
            title={getTitles('T-10652')}
            subTitle={employeeData.end_date && getFormatDate(employeeData.end_date)}
          />
        </>
      )}
    </Box>
  );
};
