import { defaultValues } from '../../../constants';
import { globalStyles } from '../../../theme';
import { color } from '../../../theme/color';

const res = defaultValues.isResponsive;

export const footerStyles = {
  container: (ecom, style) => ({
    alignItems: 'center',
    color: color.tertiaryText,
    width: '100%',
    backgroundColor: ecom ? color.primary : color.primaryText,
    borderRadius: ecom && '0px 0px 6px 6px',

    ...style
  }),
  socialContainer: {
    mt: 2,
    alignItems: 'center'
  },

  descText: { mt: 2, color: color.tertiaryText, textAlign: 'justify' },
  buttonStyle: {
    backgroundColor: color.primary,
    ml: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    boxSizing: 'border-box',
    color: color.tertiaryText,
    '&:hover': {
      backgroundColor: color.primary
    }
  },
  inputFieldStyle: (isResponsive) => ({
    backgroundColor: color.tertiaryText,
    borderRadius: 1,
    width: isResponsive ? '100%' : '285px'
  }),

  linkTexts: {
    color: color.tertiaryText,
    cursor: 'pointer',
    padding: '8px 8px 8px 0px',
    paddingRight: '3px',
    boxSizing: 'border-box'
  },

  subHeading: {
    color: color.tertiaryText,
    padding: '11px 11px 11px 0px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    fontWeight: 600
  },
  icon: {
    cursor: 'pointer',
    width: '42px',
    height: '42px'
  },
  iconSecondary: {
    cursor: 'pointer',
    width: '38px',
    height: '36px'
  },
  link: { color: 'inherit', textDecoration: 'none' },
  mainContainer: () => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  }),
  flex2: {
    flex: 2
  },
  subContainer: (isResponsive) => ({
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    justifyContent: 'center',
    p: isResponsive ? 1 : 5,
    ml: isResponsive ? 1 : 5,
    boxSizing: 'border-box'
  }),

  desContainer: { width: defaultValues.isResponsive ? '100%' : '90%' },

  labelContainer: (responsive) => ({
    display: 'flex',
    px: responsive ? 0 : 5,
    boxSizing: 'border-box',
    justifyContent: responsive ? 'center' : 'space-between',
    flexDirection: responsive ? 'column' : 'row',
    alignItems: responsive ? 'center' : 'space-between'
  }),

  laberRight: {
    p: 2,
    boxSizing: 'border-box'
  },
  labelRightSec: {
    display: 'flex',
    justifyContent: res && 'center',
    gap: '10px'
  },
  labelText: {
    fontWeight: 400,
    color: color.tertiaryText,
    cursor: 'pointer',
    textDecoration: 'none'
  },
  copyRight: {
    cursor: 'default'
  },
  flex: { flex: 1 },

  logoContainer: (isRes) => ({
    width: isRes ? '50%' : '70%',
    height: isRes ? '30%' : '40%'
  }),
  logo: {
    maxWidth: res ? '60px' : '90px',
    maxHeight: res ? '60px' : '90px'
  },
  privacy: { textDecoration: 'none' },
  emailBox: {
    ...globalStyles.flexBox,
    justifyContent: 'start',
    margin: '0px 0px 10px 0px'
  },
  mailIcon: {
    height: res ? '20px' : '30px',
    width: res ? '20px' : '30px',
    marginRight: '10px'
  },
  mailText: {
    color: color.tertiaryText
  },
  connectCont: {
    width: '100%',
    ...globalStyles.flexColXCenter,
    paddingBottom: '10px'
  },
  socialCont: {
    display: 'flex',
    gap: '20px'
  },
  socialIcon: {
    cursor: 'pointer',
    width: '30px',
    height: '30px'
  },
  servicesCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: res ? 'none' : 1
  }
};
