import { Box, IconButton } from '@mui/material';
import { ModalButtons } from '../../common/modal-buttons';
import {
  getMasterListItems,
  getNamesByIds,
  getTitles,
  getTitlesByValue,
  resetMessages
} from '../../../utils';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import {
  DatePickers,
  Text,
  UIButton,
  UIChipButton,
  UIDataTable,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
const isRes = defaultValues.isResponsive;
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Styles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterLists } from '../../../services/redux/selectors/manageProccess';

import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { ViewMultiData } from '../view-muiti-data';
import { FormTitleCard } from '../../common';
import {
  getCattleActionType,
  getCattleData,
  getCattleError,
  getCattleSuccess
} from '../../../services/redux/selectors';
import {
  addCattleRequest,
  resetCattleMsg,
  setCattleActionType,
  updateCattleRequest
} from '../../../services/redux/slices';

export const CattleDetails = ({ onPrev, onNext, setNext, vaccines, disease }) => {
  const listItems = useSelector(getMasterLists);
  const dispatch = useDispatch();
  const { view, edit, add } = defaultValues.actionType;

  const actionType = useSelector(getCattleActionType);
  const data = useSelector(getCattleData);
  const success = useSelector(getCattleSuccess);
  const error = useSelector(getCattleError);

  const [farmerId, setFarmerId] = useState('');
  const [cattleId, setCattleId] = useState('');
  const [filteredDisease, setFilteredDisease] = useState([]);

  const [dietDetails, setDietDetails] = useState({
    fodder_type: '',
    quantity: '',
    remarks: ''
  });

  const [vaccineDetails, setVaccineDetails] = useState({
    disease: [],
    vaccine: '',
    dose: '',
    vaccination_date: null,
    remarks: ''
  });

  const [diseaseDetails, setDiseaseDetails] = useState({
    disease: '',
    remarks: ''
  });

  const { values, setFieldValue } = useFormik({
    initialValues: {
      has_allergies: null,
      allergy_details: '',
      cattle_in_house: null,
      herd_manager: '',
      subscription_package: '',
      payment_mode: {
        id: ''
      },
      remarks: '',
      diet_recommendation: [],
      insurance_details: {
        policy_number: '',
        policy_provider: { _id: '' },
        premium_paid: '',
        subsidy: '',
        start_date: null,
        end_date: null
      },
      pregnancy_details: {
        colostrum_feed_quantity: '',
        number_of_lactations: '',
        average_inter_calving_period: '',
        average_milk_feed_quantity: '',
        ai_attempts_to_conceive: ''
      },
      milking_details: {
        average_quantity: '',
        average_fat: '',
        average_snf: '',
        state_of_cow: { id: '' },
        pregnant_months: '',
        non_milking_reason: ''
      },
      vaccination_details: [],
      disease_details: [],
      dewormed: null,
      deworming_date: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const [fodderType, setFodderType] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [policyProvider, setPolicyProvider] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [showSBtn, setShowSBtn] = useState(false);

  useEffect(() => {
    const filtered = disease.filter((item) => !vaccineDetails?.disease.includes(item._id));
    setFilteredDisease(filtered);
  }, [disease, vaccineDetails.disease]);

  useEffect(() => {
    if (data) {
      setCattleId(data._id);
      setFarmerId(data.farmer_id);
      if (data.cattle_details) {
        setFieldValue('has_allergies', data.cattle_details.has_allergies);
        setFieldValue('allergy_details', data.cattle_details.allergy_details);
        setFieldValue('cattle_in_house', data.cattle_details.cattle_in_house);
        setFieldValue('herd_manager', data.cattle_details.herd_manager);
        setFieldValue('subscription_package', data.cattle_details.subscription_package?._id);
        data.cattle_details.payment_mode
          ? setFieldValue('payment_mode', data.cattle_details.payment_mode)
          : setFieldValue('payment_mode.id', '');
        setFieldValue('remarks', data.cattle_details.remarks);
        setFieldValue('diet_recommendation', data.cattle_details.diet_recommendation);
        setFieldValue('dewormed', data.cattle_details.dewormed);
        setFieldValue(
          'deworming_date',
          data.cattle_details.deworming_date ? dayjs(data.cattle_details.deworming_date) : null
        );
        if (data.cattle_details.insurance_details) {
          setFieldValue(
            'insurance_details.policy_number',
            data.cattle_details.insurance_details.policy_number
          );
          data.cattle_details.insurance_details?.policy_provider
            ? setFieldValue(
                'insurance_details.policy_provider',
                data.cattle_details.insurance_details.policy_provider
              )
            : setFieldValue('insurance_details.policy_provider._id', '');
          setFieldValue(
            'insurance_details.premium_paid',
            data.cattle_details.insurance_details.premium_paid
          );
          setFieldValue('insurance_details.subsidy', data.cattle_details.insurance_details.subsidy);
          setFieldValue(
            'insurance_details.start_date',
            data.cattle_details.insurance_details.start_date
              ? dayjs(data.cattle_details.insurance_details.start_date)
              : null
          );
          setFieldValue(
            'insurance_details.end_date',
            data.cattle_details.insurance_details.end_date
              ? dayjs(data.cattle_details.insurance_details.end_date)
              : null
          );
        }
        if (data.cattle_details.pregnancy_details) {
          setFieldValue(
            'pregnancy_details.colostrum_feed_quantity',
            data.cattle_details.pregnancy_details.colostrum_feed_quantity
          );
          setFieldValue(
            'pregnancy_details.number_of_lactations',
            data.cattle_details.pregnancy_details.number_of_lactations
          );
          setFieldValue(
            'pregnancy_details.average_inter_calving_period',
            data.cattle_details.pregnancy_details.average_inter_calving_period
          );
          setFieldValue(
            'pregnancy_details.average_milk_feed_quantity',
            data.cattle_details.pregnancy_details.average_milk_feed_quantity
          );
          setFieldValue(
            'pregnancy_details.ai_attempts_to_conceive',
            data.cattle_details.pregnancy_details.ai_attempts_to_conceive
          );
        }
        if (data.cattle_details.milking_details) {
          setFieldValue(
            'milking_details.average_quantity',
            data.cattle_details.milking_details.average_quantity
          );
          setFieldValue(
            'milking_details.average_fat',
            data.cattle_details.milking_details.average_fat
          );
          setFieldValue(
            'milking_details.average_snf',
            data.cattle_details.milking_details.average_snf
          );
          setFieldValue(
            'milking_details.state_of_cow',
            data.cattle_details.milking_details.state_of_cow
              ? data.cattle_details.milking_details.state_of_cow
              : { id: '' }
          );
          setFieldValue(
            'milking_details.pregnant_months',
            data.cattle_details.milking_details.pregnant_months
          );
          setFieldValue(
            'milking_details.non_milking_reason',
            data.cattle_details.milking_details.non_milking_reason
          );
        }
        const formattedVaccinationDetails = data.cattle_details.vaccination_details.map(
          (detail) => ({
            ...detail,
            vaccination_date: dayjs(detail.vaccination_date)
          })
        );

        setFieldValue('vaccination_details', formattedVaccinationDetails);
        setFieldValue('disease_details', data.cattle_details.disease_details);
      }
    }
  }, [data]);

  useEffect(() => {
    if (listItems) {
      setFodderType(getMasterListItems(listItems, defaultValues.mListIds.fodderType));
      setSubscription(getMasterListItems(listItems, defaultValues.mListIds.subscription));
      setPolicyProvider(getMasterListItems(listItems, defaultValues.mListIds.policyProvider));
    }
  }, [listItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleCurrDiet = (e) => {
    const { name, value } = e.target;
    setDietDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleCurrVaccine = (e) => {
    const { name, value } = e.target;
    setVaccineDetails((prevDetails) => {
      if (name === 'disease') {
        const newDiseases = prevDetails.disease.includes(value)
          ? prevDetails.disease.filter((disease) => disease !== value)
          : [...prevDetails.disease, value];
        return {
          ...prevDetails,
          disease: newDiseases
        };
      }
      return {
        ...prevDetails,
        [name]: value
      };
    });
  };

  const handleDeleteDisease = (diseaseToDelete) => {
    setVaccineDetails((prevDetails) => ({
      ...prevDetails,
      disease: prevDetails.disease.filter((disease) => disease !== diseaseToDelete)
    }));
  };

  const handleAddVaccineDetails = () => {
    setFieldValue('vaccination_details', [...values.vaccination_details, { ...vaccineDetails }]);
    setShowBtn(false);
    setVaccineDetails({
      disease: [],
      vaccine: '',
      dose: '',
      vaccination_date: null,
      remarks: '',
      dewormed: null,
      deworming_date: null
    });
  };

  const handleCurrDisease = (e) => {
    const { name, value } = e.target;
    setDiseaseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleAddDiseaseDetails = () => {
    setShowSBtn(false);
    setFieldValue('disease_details', [...values.disease_details, { ...diseaseDetails }]);
    setDiseaseDetails({
      remarks: '',
      disease: ''
    });
  };

  const handleEditFamilyMember = (index) => {
    setDietDetails({ ...values.diet_recommendation[index] });
    const updatedDietDetails = [...values.diet_recommendation];
    updatedDietDetails.splice(index, 1);
    setFieldValue('diet_recommendation', updatedDietDetails);
  };

  const handleAddCattleDiets = () => {
    setFieldValue('diet_recommendation', [...values.diet_recommendation, { ...dietDetails }]);
    setDietDetails({
      fodder_type: '',
      quantity: '',
      remarks: ''
    });
  };

  const handleDeleteFamilyMember = (index) => {
    const updatedFamilyDetails = [...values.diet_recommendation];
    updatedFamilyDetails.splice(index, 1);
    setFieldValue('diet_recommendation', updatedFamilyDetails);
  };

  const handleDeleteVaccines = (index) => {
    const updatedDetails = values.vaccination_details.filter((_, i) => i !== index);
    setFieldValue('vaccination_details', updatedDetails);
  };

  const handleEditVaccine = (index) => {
    const detailsToEdit = values.vaccination_details[index];
    setVaccineDetails(detailsToEdit);
    const updatedDetails = values.vaccination_details.filter((_, i) => i !== index);
    setFieldValue('vaccination_details', updatedDetails);
    setShowBtn(true);
  };

  const handleEditDisease = (index) => {
    setShowSBtn(true);
    const detailsToEdit = values.disease_details[index];
    setDiseaseDetails(detailsToEdit);
    const updatedDetails = values.disease_details.filter((_, i) => i !== index);
    setFieldValue('disease_details', updatedDetails);
  };

  const handleDeleteDiseaseDetails = (index) => {
    const updatedDetails = values.disease_details.filter((_, i) => i !== index);
    setFieldValue('disease_details', updatedDetails);
  };

  const handleSubmit = () => {
    const payload = {
      _id: cattleId,
      farmer_id: farmerId,
      registration_step: 3,
      cattle_details: {
        ...values,
        payment_mode: values.payment_mode?.id || null,
        insurance_details: {
          ...values.insurance_details,
          policy_provider: values.insurance_details?.policy_provider?._id || null
        },
        milking_details: {
          ...values.milking_details,
          state_of_cow: values.milking_details?.state_of_cow?.id || null
        },
        vaccination_details: values.vaccination_details.map((item) => ({
          ...item,
          disease: item.disease?.map((ele) => ele._id || ele)
        }))
      }
    };

    if (!payload.cattle_details.diet_recommendation.length) {
      delete payload.cattle_details.diet_recommendation;
    }
    if (!payload.cattle_details.disease_details.length) {
      delete payload.cattle_details.disease_details;
    }
    if (!payload.cattle_details.vaccination_details.length) {
      delete payload.cattle_details.vaccination_details;
    }
    if (!payload.cattle_details.subscription_package) {
      delete payload.cattle_details.subscription_package;
    }
    if (actionType === edit || (actionType === add && data.cattle_details)) {
      dispatch(updateCattleRequest(payload));
    } else {
      dispatch(addCattleRequest(payload));
    }
  };

  useEffect(() => {
    if (success === 'S-10062') {
      dispatch(resetCattleMsg());
      onNext();
    }
    if (success === 'S-10063') {
      if (actionType === add) {
        resetMessages(() => onNext());
      } else {
        toast.success(getSuccessMessage('S-10063'));
        dispatch(setCattleActionType(view));
      }
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetCattleMsg()));
  }, [success, error]);

  const tableData = [
    [
      {
        title: getTitles('T-10836'),
        values:
          actionType !== view ? (
            <UISelect
              containerStyles={{ marginTop: '6px' }}
              name="fodder_type"
              options={fodderType}
              returnValue="_id"
              optionLabel="name"
              placeholder={getTitles('T-10350')}
              onChange={handleCurrDiet}
              value={dietDetails.fodder_type?._id || dietDetails.fodder_type}
            />
          ) : null
      },
      {
        title: getTitles('T-11022'),
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="quantity"
              onChange={handleCurrDiet}
              value={dietDetails.quantity}
              allowOnlyNumbers={true}
            />
          ) : null
      },
      {
        title: getTitles('T-10660'),
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="remarks"
              onChange={handleCurrDiet}
              value={dietDetails.remarks}
              allowOnlyAlphabets
            />
          ) : null
      }
    ]
  ];
  return (
    <Box sx={Styles.thirdSecContainer}>
      <UIRadioButtonWithTitle
        title={getTitles('T-11011')}
        values={defaultValues.personalInsurane}
        onCheck={(val) => setFieldValue('has_allergies', val)}
        checkedValue={values.has_allergies}
        disabled={actionType === view}
      />
      {values.has_allergies && (
        <UITextInputWithTitle
          name="allergy_details"
          title={getTitles('T-11012')}
          placeHolder={getTitles('T-10096')}
          value={values.allergy_details}
          onChange={handleChange}
          rows={3}
          disabled={actionType === view}
          containerStyles={Styles.thirdSecField}
        />
      )}
      <UIRadioButtonWithTitle
        title={getTitles('T-11013')}
        values={defaultValues.personalInsurane}
        onCheck={(val) => setFieldValue('cattle_in_house', val)}
        checkedValue={values.cattle_in_house}
        disabled={actionType === view}
      />
      <UITextInputWithTitle
        name="herd_manager"
        title={getTitles('T-11014')}
        placeHolder={getTitles('T-11015')}
        value={values.herd_manager}
        onChange={handleChange}
        disabled={actionType === view}
        containerStyles={Styles.thirdSecField}
      />
      <FormTitleCard title={getTitles('T-11016')} dividerWidth={Styles.divider} />
      <UIRadioButtonWithTitle
        title={getTitles('T-11017')}
        values={subscription.map((subscription) => ({
          title: subscription.name,
          value: subscription._id
        }))}
        onCheck={(val) => setFieldValue('subscription_package', val)}
        checkedValue={values.subscription_package}
        disabled={actionType === view}
      />
      <UISelect
        containerStyles={Styles.thirdSecField}
        name="payment_mode.id"
        value={values.payment_mode?.id}
        options={defaultValues.modeOfPayment}
        title={getTitles('T-11018')}
        placeholder={getTitles('T-10350')}
        disabled={actionType === view}
        onChange={handleChange}
        onlyView={actionType === view}
        valueToShow={getTitlesByValue([values.payment_mode?.id], defaultValues.modeOfPayment)}
      />
      <UITextInputWithTitle
        name="remarks"
        title={getTitles('T-10660')}
        placeHolder={getTitles('T-10096')}
        value={values.remarks}
        onChange={handleChange}
        rows={3}
        disabled={actionType === view}
        containerStyles={Styles.thirdSecField}
      />

      <FormTitleCard title={getTitles('T-11019')} dividerWidth={Styles.divider} />
      {!isRes ? (
        <>
          <Box sx={Styles.ninty}>
            <UIDataTable
              tableRows={tableData}
              rowValueContainerStyle={Styles.rowValue}
              valuesSubStyle={Styles.Pzeo}
              headerContStyle={Styles.gap}
            />
          </Box>
          {actionType !== view && (
            <UISaveAndAdd
              title={getTitles('T-10062')}
              onAddAnother={handleAddCattleDiets}
              disabled={dietDetails.fodder_type === '' || dietDetails.quantity === ''}
            />
          )}
          {values.diet_recommendation &&
            values.diet_recommendation.map((item, index) => (
              <Box key={index} sx={Styles.dietCont}>
                <Box sx={Styles.dietInnerCont}>
                  <Text sx={Styles.vieDat}>
                    {getNamesByIds([item.fodder_type?._id || item.fodder_type], fodderType)}
                  </Text>
                  <Text sx={Styles.vieDat}>{item.quantity}</Text>
                  <Text sx={Styles.vieDat}>{item.remarks}</Text>
                </Box>
                {actionType !== view && (
                  <Box sx={Styles.iconBtnCont}>
                    <IconButton sx={Styles.dietTableIcon}>
                      <EditIcon onClick={() => handleEditFamilyMember(index)} />
                    </IconButton>
                    <IconButton sx={Styles.dietTableIcon}>
                      <DeleteIcon onClick={() => handleDeleteFamilyMember(index)} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
        </>
      ) : (
        <>
          {values.diet_recommendation.map((item, index) => (
            <ViewMultiData
              showIcon={actionType !== view}
              showBorder={true}
              key={index}
              title={getNamesByIds([item.fodder_type?._id || item.fodder_type], fodderType)}
              onDelete={() => handleDeleteFamilyMember(index)}
              onEdit={() => handleEditFamilyMember(index)}>
              <UISelect
                title={getTitles('T-10836')}
                name="fodder_type"
                options={fodderType}
                returnValue="_id"
                optionLabel="name"
                placeholder={getTitles('T-10350')}
                disabled={true}
                value={item.fodder_type?._id || item.fodder_type}
              />
              <UITextInputWithTitle
                placeHolder={getTitles('T-11050')}
                title={getTitles('T-11022')}
                name="quantity"
                disabled={true}
                value={item.quantity}
                allowOnlyNumbers={true}
              />
              <UITextInputWithTitle
                title={getTitles('T-10660')}
                placeHolder={getTitles('T-10096')}
                name="remarks"
                value={item.remarks}
                disabled={true}
                allowOnlyAlphabets
                rows={3}
              />
            </ViewMultiData>
          ))}
          {actionType !== view && (
            <>
              <UISelect
                title={getTitles('T-10836')}
                name="fodder_type"
                options={fodderType}
                returnValue="_id"
                optionLabel="name"
                placeholder={getTitles('T-10350')}
                onChange={handleCurrDiet}
                disabled={actionType === view}
                value={dietDetails.fodder_type?._id || dietDetails.fodder_type}
              />
              <UITextInputWithTitle
                placeHolder={getTitles('T-11050')}
                title={getTitles('T-11022')}
                name="quantity"
                onChange={handleCurrDiet}
                disabled={actionType === view}
                value={dietDetails.quantity}
                allowOnlyNumbers={true}
              />
              <UITextInputWithTitle
                title={getTitles('T-10660')}
                placeHolder={getTitles('T-10096')}
                name="remarks"
                onChange={handleCurrDiet}
                value={dietDetails.remarks}
                disabled={actionType === view}
                allowOnlyAlphabets
                rows={3}
              />
              <UISaveAndAdd
                title={getTitles('T-10062')}
                onAddAnother={handleAddCattleDiets}
                disabled={dietDetails.fodder_type === '' || dietDetails.quantity === ''}
              />
            </>
          )}
        </>
      )}
      <FormTitleCard title={getTitles('T-10507')} dividerWidth={Styles.divider} />
      <UITextInputWithTitle
        title={getTitles('T-10372')}
        name="insurance_details.policy_number"
        value={values.insurance_details.policy_number}
        placeHolder={getTitles('T-10360')}
        onChange={handleChange}
        containerStyles={Styles.thirdSecField}
        disabled={actionType === view}
        allowOnlyNumbers={true}
      />
      <UISelect
        containerStyles={Styles.thirdSecField}
        name="insurance_details.policy_provider._id"
        value={values.insurance_details.policy_provider?._id}
        options={policyProvider}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-10499')}
        placeholder={getTitles('T-10350')}
        disabled={actionType === view}
        onChange={handleChange}
        onlyView={actionType === view}
        valueToShow={values.insurance_details.policy_provider?.name}
      />
      <Box sx={Styles.datePickerStyle}>
        <DatePickers
          dateValue={values.insurance_details.start_date}
          onDateChange={(val) =>
            handleChange({
              target: { value: val, name: 'insurance_details.start_date' }
            })
          }
          title={getTitles('T-10651')}
          maxDate={values.insurance_details.end_date}
          disabled={actionType === view}
          containerStyles={Styles.thirdSecHalfField}
        />

        <DatePickers
          dateValue={values.insurance_details.end_date}
          onDateChange={(val) =>
            handleChange({
              target: { value: val, name: 'insurance_details.end_date' }
            })
          }
          title={getTitles('T-10652')}
          containerStyles={Styles.thirdSecHalfField}
          disabled={actionType === view}
          minDate={values.insurance_details.start_date}
        />
      </Box>
      <UITextInputWithTitle
        title={getTitles('T-11020')}
        name="insurance_details.premium_paid"
        value={values.insurance_details.premium_paid}
        placeHolder={getTitles('T-11201')}
        onChange={handleChange}
        disabled={actionType === view}
        containerStyles={Styles.thirdSecField}
        allowOnlyNumbers={true}
      />
      <UITextInputWithTitle
        title={getTitles('T-11021')}
        name="insurance_details.subsidy"
        value={values.insurance_details.subsidy}
        placeHolder={getTitles('T-11201')}
        onChange={handleChange}
        disabled={actionType === view}
        containerStyles={Styles.thirdSecField}
        allowOnlyNumbers={true}
      />
      <Box sx={Styles.commonCont}>
        <FormTitleCard title={getTitles('T-11023')} dividerWidth={Styles.dd} />
        <UITextInputWithTitle
          title={getTitles('T-11024')}
          name="pregnancy_details.colostrum_feed_quantity"
          value={values.pregnancy_details.colostrum_feed_quantity}
          placeHolder={getTitles('T-10360')}
          disabled={actionType === view}
          onChange={handleChange}
          allowOnlyNumbers={true}
        />
        <Box sx={Styles.commonFlex}>
          <UITextInputWithTitle
            title={getTitles('T-11025')}
            name="pregnancy_details.number_of_lactations"
            value={values.pregnancy_details.number_of_lactations}
            placeHolder={getTitles('T-10360')}
            onChange={handleChange}
            disabled={actionType === view}
            containerStyles={Styles.thirdSecHalfField}
            allowOnlyNumbers={true}
          />
          <UITextInputWithTitle
            title={getTitles('T-11026')}
            name="pregnancy_details.average_inter_calving_period"
            value={values.pregnancy_details.average_inter_calving_period}
            placeHolder={getTitles('T-10360')}
            onChange={handleChange}
            allowOnlyNumbers={true}
            disabled={actionType === view}
            containerStyles={Styles.thirdSecHalfField}
          />
        </Box>
        <UITextInputWithTitle
          title={getTitles('T-11027')}
          name="pregnancy_details.average_milk_feed_quantity"
          value={values.pregnancy_details.average_milk_feed_quantity}
          placeHolder={getTitles('T-10360')}
          onChange={handleChange}
          disabled={actionType === view}
          allowOnlyNumbers={true}
        />
        <UITextInputWithTitle
          title={getTitles('T-11028')}
          name="pregnancy_details.ai_attempts_to_conceive"
          value={values.pregnancy_details.ai_attempts_to_conceive}
          placeHolder={getTitles('T-10360')}
          disabled={actionType === view}
          onChange={handleChange}
          allowOnlyNumbers={true}
        />
      </Box>
      <Box sx={Styles.commonCont}>
        <FormTitleCard title={getTitles('T-11034')} dividerWidth={Styles.dd} />
        <UITextInputWithTitle
          title={getTitles('T-10517')}
          name="milking_details.average_quantity"
          value={values.milking_details.average_quantity}
          placeHolder={getTitles('T-10520')}
          onChange={handleChange}
          disabled={actionType === view}
          allowDecimal={true}
        />
        <Box sx={Styles.commonFlex}>
          <UITextInputWithTitle
            title={getTitles('T-10518')}
            name="milking_details.average_fat"
            value={values.milking_details.average_fat}
            placeHolder={getTitles('T-10670')}
            onChange={handleChange}
            containerStyles={Styles.thirdSecHalfField}
            disabled={actionType === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            title={getTitles('T-10519')}
            name="milking_details.average_snf"
            value={values.milking_details.average_snf}
            placeHolder={getTitles('T-10522')}
            onChange={handleChange}
            containerStyles={Styles.thirdSecHalfField}
            disabled={actionType === view}
            allowDecimal={true}
          />
        </Box>

        <UISelect
          name="milking_details.state_of_cow.id"
          value={values.milking_details.state_of_cow?.id}
          options={defaultValues.stateCow}
          title={getTitles('T-11029')}
          placeholder={getTitles('T-10350')}
          disabled={actionType === view}
          onChange={handleChange}
          onlyView={actionType === view}
          valueToShow={values.milking_details.state_of_cow?.name}
        />

        {values.milking_details.state_of_cow?.id === 2 && (
          <UITextInputWithTitle
            title={getTitles('T-11038')}
            name="milking_details.pregnant_months"
            value={values.milking_details.pregnant_months}
            placeHolder={getTitles('T-10360')}
            disabled={actionType === view}
            onChange={handleChange}
          />
        )}

        {values.milking_details.state_of_cow?.id === 3 && (
          <UITextInputWithTitle
            title={getTitles('T-11035')}
            name="milking_details.non_milking_reason"
            value={values.milking_details.non_milking_reason}
            placeHolder={getTitles('T-10360')}
            disabled={actionType === view}
            onChange={handleChange}
            rows={3}
          />
        )}
      </Box>
      {/* vaccination_details */}
      <Box sx={Styles.commonCont}>
        <FormTitleCard title={getTitles('T-11039')} dividerWidth={Styles.dd} />
        {values.vaccination_details &&
          values.vaccination_details.map((item, index) => (
            <ViewMultiData
              showIcon={actionType !== view}
              title={getNamesByIds([item.vaccine?._id || item.vaccine], vaccines)}
              key={index}
              onEdit={() => handleEditVaccine(index)}
              onDelete={() => handleDeleteVaccines(index)}>
              {item.disease &&
                item.disease.map((item, index) => (
                  <UIChipButton
                    title={getNamesByIds([item._id || item], disease)}
                    key={index}
                    onDelete={() => handleDeleteDisease(item)}
                  />
                ))}

              <UISelect
                name="vaccine"
                value={item.vaccine?._id || item.vaccine}
                options={vaccines}
                optionLabel="name"
                returnValue="_id"
                title={getTitles('T-11040')}
                placeholder={getTitles('T-10350')}
                disabled={true}
              />
              <Box sx={Styles.commonFlex}>
                <UITextInputWithTitle
                  name="dose"
                  title={getTitles('T-11030')}
                  placeHolder={getTitles('T-11202')}
                  value={item.dose}
                  containerStyles={Styles.thirdSecHalfField}
                  disabled={true}
                  allowDecimal={true}
                />
                <DatePickers
                  dateValue={dayjs(item.vaccination_date)}
                  title={getTitles('T-11031')}
                  disabled={true}
                  containerStyles={Styles.thirdSecHalfField}
                />
              </Box>

              <UITextInputWithTitle
                title={getTitles('T-10660')}
                name="remarks"
                value={item.remarks}
                placeHolder={getTitles('T-10360')}
                disabled={true}
                rows={3}
              />
            </ViewMultiData>
          ))}
        {actionType !== view && (
          <>
            <UISelect
              name="disease"
              value={''}
              options={filteredDisease}
              optionLabel="name"
              returnValue="_id"
              title={getTitles('T-10101')}
              disabled={actionType === view}
              placeholder={getTitles('T-10350')}
              onChange={handleCurrVaccine}
            />
            {vaccineDetails.disease &&
              vaccineDetails.disease.map((item, index) => (
                <UIChipButton
                  title={getNamesByIds([item?._id || item], disease)}
                  key={index}
                  onDelete={() => handleDeleteDisease(item)}
                />
              ))}

            <UISelect
              name="vaccine"
              value={vaccineDetails.vaccine?._id || vaccineDetails.vaccine}
              options={vaccines}
              optionLabel="name"
              returnValue="_id"
              title={getTitles('T-11040')}
              placeholder={getTitles('T-10350')}
              disabled={actionType === view}
              onChange={handleCurrVaccine}
            />
            <Box sx={Styles.commonFlex}>
              <UITextInputWithTitle
                name="dose"
                title={getTitles('T-11030')}
                placeHolder={getTitles('T-11202')}
                onChange={handleCurrVaccine}
                value={vaccineDetails.dose}
                containerStyles={Styles.thirdSecHalfField}
                disabled={actionType === view}
                allowDecimal={true}
              />
              <DatePickers
                dateValue={vaccineDetails.vaccination_date}
                onDateChange={(val) =>
                  handleCurrVaccine({
                    target: { value: val, name: 'vaccination_date' }
                  })
                }
                title={getTitles('T-11031')}
                disabled={actionType === view}
                containerStyles={Styles.thirdSecHalfField}
              />
            </Box>

            <UITextInputWithTitle
              title={getTitles('T-10660')}
              name="remarks"
              value={vaccineDetails.remarks}
              placeHolder={getTitles('T-10360')}
              onChange={handleCurrVaccine}
              disabled={actionType === view}
              rows={3}
            />
            {showBtn && (
              <UIButton
                title={getTitles('T-10394')}
                style={Styles.saBtn}
                onClick={handleAddVaccineDetails}
              />
            )}
            <UISaveAndAdd
              title={getTitles('T-11086')}
              disabled={!vaccineDetails.disease.length || !vaccineDetails.vaccine.length}
              onAddAnother={handleAddVaccineDetails}
            />
          </>
        )}
      </Box>
      {/* dewormed section */}
      <UIRadioButtonWithTitle
        title={getTitles('T-11032')}
        values={defaultValues.personalInsurane}
        onCheck={(val) => setFieldValue('dewormed', val)}
        checkedValue={values.dewormed}
        disabled={actionType === view}
      />
      {values.dewormed && (
        <DatePickers
          dateValue={values.deworming_date}
          onDateChange={(val) =>
            handleChange({
              target: { value: val, name: 'deworming_date' }
            })
          }
          title={getTitles('T-11033')}
          containerStyles={Styles.lastDate}
          maxDate={dayjs()}
        />
      )}
      {/* disease section */}
      <Box sx={Styles.commonCont}>
        <FormTitleCard title={getTitles('T-10307')} dividerWidth={Styles.dd} />
        {values.disease_details &&
          values.disease_details.map((item, index) => (
            <ViewMultiData
              showIcon={actionType !== view}
              key={index}
              title={getNamesByIds([item.disease?._id || item.disease], disease)}
              onDelete={() => handleDeleteDiseaseDetails(index)}
              onEdit={() => handleEditDisease(index)}>
              <UISelect
                name="disease"
                value={item.disease?._id || item.disease}
                options={disease}
                optionLabel="name"
                returnValue="_id"
                title={getTitles('T-11041')}
                placeholder={getTitles('T-10350')}
                disabled={true}
              />
              <UITextInputWithTitle
                name="remarks"
                title={getTitles('T-10660')}
                placeHolder={getTitles('T-10096')}
                value={item.remarks}
                rows={3}
                disabled={true}
              />
            </ViewMultiData>
          ))}
        {actionType !== view && (
          <>
            <UISelect
              name="disease"
              value={diseaseDetails.disease}
              options={disease}
              optionLabel="name"
              returnValue="_id"
              title={getTitles('T-11041')}
              placeholder={getTitles('T-10350')}
              onChange={handleCurrDisease}
              disabled={actionType === view}
            />
            <UITextInputWithTitle
              name="remarks"
              title={getTitles('T-10660')}
              placeHolder={getTitles('T-10096')}
              value={diseaseDetails.remarks}
              onChange={handleCurrDisease}
              rows={3}
              disabled={actionType === view}
            />
            {showSBtn && (
              <UIButton
                title={getTitles('T-10394')}
                style={Styles.saBtn}
                onClick={handleAddDiseaseDetails}
              />
            )}
            <UISaveAndAdd
              title={getTitles('T-11087')}
              disabled={!diseaseDetails.disease}
              onAddAnother={handleAddDiseaseDetails}
            />
          </>
        )}
      </Box>
      <ModalButtons
        btnPositions={isRes ? 'center' : 'start'}
        secondaryBtnTitle={getTitles('T-10340')}
        disabled={false}
        onSecondaryBtnClick={() => {
          if (actionType === edit) {
            dispatch(setCattleActionType(view));
          }
          onPrev();
        }}
        onPrimaryBtnClick={actionType === view ? () => setNext(3) : handleSubmit}
        primaryBtnTitle={
          actionType === add && data.cattle_details
            ? getTitles('T-10733')
            : actionType === edit
              ? getTitles('T-10733')
              : actionType === view
                ? getTitles('T-10804')
                : getTitles('T-10339')
        }
      />
    </Box>
  );
};
