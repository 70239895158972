import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { color } from '../../theme/color';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { globalStyles } from '../../theme';
import { Text } from '../text';
import { defaultValues } from '../../constants';

export const UIRadioButton = ({
  onChange,
  checked,
  sx,
  title,
  variant = 'D20',
  disabled,
  size = defaultValues.isResponsive ? '25px' : '30px',
  titleStyle,
  containerStyle,
  ...rest
}) => {
  const styles = {
    container: (style) => ({
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      ...style
    }),
    icon: { color: color.primaryText, fontSize: size },
    checkedIcon: { color: color.primary, fontSize: size },
    title: (style) => ({
      ...globalStyles.text,
      ...style
    }),
    asterisk: {
      color: color.errorText
    },
    checkBox: {
      '&.MuiButtonBase-root': {
        padding: defaultValues.isResponsive ? '4px 4px 4px 0px' : '9px 9px 9px 0px',
        boxSizing: 'border-box'
      },
      '&:hover': {
        backgroundColor: 'transparent'
      },
      ...sx
    }
  };

  return (
    <Box sx={styles.container(containerStyle)}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        sx={styles.checkBox}
        onChange={onChange}
        icon={<RadioButtonUnchecked style={styles.icon} />}
        checkedIcon={<RadioButtonChecked style={styles.checkedIcon} />}
        {...rest}
      />
      {title && (
        <Text variant={variant} style={styles.title(titleStyle)}>
          {title}
        </Text>
      )}
    </Box>
  );
};
