import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
const res = defaultValues.isResponsive;

export const styles = {
  otpContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px',
    marginBottom: '20px'
  },
  otpSubContainer: { width: 'fit-content' },
  otpResendContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  otpInput: (error) => ({
    gap: res ? '5px' : '20px',
    '& .MuiOtpInput-TextField': {
      border: `1px solid ${error ? color.errorText : color.primaryBorder}`,
      borderRadius: '8px',
      width: res ? '40px' : '56px',
      height: res ? '40px' : '56px',
      fontSize: res ? '12px' : '24px',
      color: color.primaryText,
      fontWeight: 400
    },
    '& .MuiInputBase-root': {
      height: '100%',
      width: '100%'
    },
    '& .MuiInputBase-input': {
      padding: 0,
      fontSize: '20px',
      border: 'none'
    },
    '& fieldset': {
      border: 'none'
    }
  }),

  resendOtp: {
    color: color.tertiaryText,
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  otpTimer: (otpsend) => ({
    boxSizing: 'border-box',
    cursor: otpsend ? 'default' : 'pointer',
    marginTop: '10px'
  }),
  otpNotification: {
    color: color.tertiaryText
  },
  errorField: {
    color: color.errorText,
    marginTop: '10px'
  }
};
