import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addEmployeeFailure,
  addEmployeeRequest,
  addEmployeeSuccess,
  deleteDocsRequest,
  deleteEmployeeDocsFailure,
  deleteEmployeeDocsSuccess,
  getDepartmentFailure,
  getDepartmentRequest,
  getDepartmentSuccess,
  getDesignationFailure,
  getDesignationRequest,
  getDesignationSuccess,
  getEmployeeFailure,
  getEmployeeRequest,
  getEmployeeSuccess,
  updateEmployeeFailure,
  updateEmployeeRequest,
  updateEmployeeSuccess,
  uploadEmployeeDocsFailure,
  uploadEmployeeDocsRequest,
  uploadEmployeeDocsSuccess
} from '../slices';
import {
  addEmployeeAPI,
  deleteEmployeeDocsAPI,
  getEmployeeAPI,
  getMasterListAPI,
  updateEmployeeAPI,
  uploadDocsAPI
} from '../../api';

function* addEmployee(action) {
  try {
    const response = yield addEmployeeAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addEmployeeSuccess(response.data));
    } else {
      yield put(addEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(addEmployeeFailure(error.response.data));
  }
}

function* updateEmployee(action) {
  try {
    const response = yield updateEmployeeAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateEmployeeSuccess(response.data));
    } else {
      yield put(updateEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(updateEmployeeFailure(error.response.data));
  }
}

function* getEmployee(action) {
  try {
    const response = yield getEmployeeAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getEmployeeSuccess(response.data));
    } else {
      yield put(getEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(getEmployeeFailure(error.response.data));
  }
}

function* getDesignation(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDesignationSuccess(response.data));
    } else {
      yield put(getDesignationFailure(response.data));
    }
  } catch (error) {
    yield put(getDesignationFailure(error.response.data));
  }
}

function* getDepartment(action) {
  try {
    const response = yield getMasterListAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getDepartmentSuccess(response.data));
    } else {
      yield put(getDepartmentFailure(response.data));
    }
  } catch (error) {
    yield put(getDepartmentFailure(error.response.data));
  }
}

function* uploadDocs(action) {
  try {
    const response = yield uploadDocsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(uploadEmployeeDocsSuccess(response.data));
    } else {
      yield put(uploadEmployeeDocsFailure(response.data));
    }
  } catch (error) {
    yield put(uploadEmployeeDocsFailure(error.response.data));
  }
}

function* deleteDocs(action) {
  try {
    const response = yield deleteEmployeeDocsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(deleteEmployeeDocsSuccess(response.data));
    } else {
      yield put(deleteEmployeeDocsFailure(response.data));
    }
  } catch (error) {
    yield put(deleteEmployeeDocsFailure(error.response.data));
  }
}

export function* employeeSaga() {
  yield takeLatest(addEmployeeRequest.type, addEmployee);
  yield takeLatest(updateEmployeeRequest.type, updateEmployee);
  yield takeLatest(getEmployeeRequest.type, getEmployee);
  yield takeLatest(getDepartmentRequest.type, getDepartment);
  yield takeLatest(getDesignationRequest.type, getDesignation);
  yield takeLatest(uploadEmployeeDocsRequest.type, uploadDocs);
  yield takeLatest(deleteDocsRequest.type, deleteDocs);
}
