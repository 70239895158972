import { Box } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { color } from '../../theme';
import { UISwitch } from '../switch';
import { defaultValues } from '../../constants';
import { AlterModal } from '../alter-modal';
import { useState } from 'react';
import { getTitles } from '../../utils';

export const ActionButton = ({
  onEditClick,
  onStatusChange,
  status,
  isReadOnly,
  alterTitle,
  alterSubTitle,
  showImg = false,
  pending = false
}) => {
  const [showAlter, setShowAlter] = useState(false);
  const style = {
    container: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px'
    },
    switch: {
      '& .MuiSwitch-thumb': {
        backgroundColor: status ? color.primary : color.errorText
      }
    },
    edit: {
      cursor: isReadOnly ? 'default' : 'pointer',
      width: defaultValues.isResponsive ? '20px' : '32px',
      height: defaultValues.isResponsive ? '20px' : '32px'
    }
  };

  const handleClick = () => {
    if (alterTitle || alterSubTitle) {
      setShowAlter(true);
    } else !isReadOnly && onStatusChange();
  };

  return (
    <>
      <Box sx={style.container}>
        {status !== undefined && (
          <UISwitch
            pending={pending}
            checked={status}
            onClick={() => !isReadOnly && handleClick()}
            isReadOnly={isReadOnly}
            showImg={showImg}
          />
        )}
        {onEditClick && (
          <EditOutlinedIcon onClick={() => !isReadOnly && onEditClick()} sx={style.edit} />
        )}
      </Box>
      {(alterTitle || alterSubTitle) && showAlter && (
        <AlterModal
          open={showAlter}
          onClose={() => setShowAlter(false)}
          title={alterTitle}
          subTitle={alterSubTitle}
          btnType={getTitles('T-11197')}
          onCancel={() => setShowAlter(false)}
          onConfirm={() => {
            setShowAlter(false);
            onStatusChange();
          }}
        />
      )}
    </>
  );
};
