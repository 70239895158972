import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';
import { defaultValues } from '../../constants';

import { color } from '../../theme/color';
const responsive = defaultValues.isResponsive;

export const UIPagination = ({ onChange, count, currentPage }) => {
  const paginationStyles = {
    pagination: (item, IsSelected) => ({
      margin: '5px 5px',
      padding: '10px',
      color: IsSelected === item.page ? color.primary : color.primaryText,
      backgroundColor:
        item.type === 'next' || item.type === 'previous'
          ? color.secondaryBackground
          : color.primaryBackground,
      border: `1px solid ${IsSelected === item.page ? color.primary : color.primaryBorder}`,
      fontSize: responsive ? '12px' : '20px',
      fontWeight: IsSelected === item.page ? 'bold' : '400',
      '&:hover': {
        color: color.primary
      }
    })
  };
  return (
    <Stack spacing={defaultValues.isResponsive ? 0 : 2}>
      <Pagination
        count={count}
        variant="outlined"
        shape="rounded"
        page={currentPage}
        size={defaultValues.isResponsive ? 'small' : 'medium'}
        boundaryCount={0}
        siblingCount={1}
        renderItem={(item) => (
          <PaginationItem sx={paginationStyles.pagination(item, currentPage)} {...item} />
        )}
        onChange={onChange}
      />
    </Stack>
  );
};
