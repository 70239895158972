import { Box } from '@mui/material';
import { color } from '../../../../../theme';
import { Text, UIChipButton } from '../../../../../ui-kits';
import { useEffect, useRef, useState } from 'react';
import { getTitles } from '../../../../../utils';
import { styles } from './styles';

export const LimitedChips = ({ values, handleDeleteChip }) => {
  const chipBoxRef = useRef(null);

  const [elements, setElements] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    setElements(values);
  }, [values]);

  useEffect(() => {
    let totalChipWidth = 0;
    let chipBoxWidth = chipBoxRef?.current?.offsetWidth;
    let childs = chipBoxRef.current?.children;
    if (childs.length > 0) {
      for (let x in childs) {
        if (childs[x].offsetWidth) {
          totalChipWidth = totalChipWidth + childs[x].offsetWidth + 10;
          if (totalChipWidth > chipBoxWidth && !showAll) {
            setElements(values.slice(0, x));
            setShowBtn(values.slice(0, x));
            return;
          }
        }
      }
      if (totalChipWidth < chipBoxWidth && showAll) {
        setShowBtn(false);
        setShowAll(false);
      }
    }
  }, [elements, chipBoxRef]);

  const handleShowall = () => {
    setElements(!showAll ? values : showBtn);
    setShowAll(!showAll);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.chipBox(showAll, showBtn)} ref={chipBoxRef}>
        {(showBtn && !showAll ? showBtn : elements).map((item, index) => (
          <UIChipButton
            title={item.name}
            key={index}
            style={styles.chip}
            backgroundColor={color.primary}
            onDelete={() => {
              handleDeleteChip(index);
              setShowAll(true);
            }}
          />
        ))}
      </Box>
      {showBtn && (
        <Text variant="D18" style={styles.viewAll} onClick={handleShowall}>
          {showAll ? getTitles('T-11382') : getTitles('T-10415')}
        </Text>
      )}
    </Box>
  );
};
