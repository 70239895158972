import { color } from '../../../theme';

export const utilStyles = {
  basicCont: (style) => ({
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    ...style
  }),
  text: (onTitleClick, style) => ({
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: onTitleClick ? 'pointer' : 'default',
    textDecoration: 'none',
    ...style
  }),
  titleText: (onTitleClick, titleStyle) => ({
    textDecoration: onTitleClick ? 'underline' : 'none',
    ...titleStyle
  }),
  phoneText: {
    textDecoration: 'none'
  },
  profileIcon: (style) => ({
    width: '30px',
    height: '30px',
    ...style
  }),
  basicSubTitle: (style) => ({
    color: color.secondaryText,
    ...style
  }),
  titleCont: (isicon) => ({
    width: isicon ? 'calc(100% - 40px)' : '100%'
  })
};
