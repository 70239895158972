import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme';
import { ProfileWithName } from '../../components';

// example of data should pass
// const arr = [{title: 'some title',details: [{ subTitle: 'status', action: any component or string }}];

export const CollapsableList = ({ contentArray = [], onTitleClick, showProfile }) => {
  const styles = {
    container: {
      width: '100%',
      marginBottom: '10px',
      borderBottom: 'none',
      borderRadius: '5px',
      boxShadow: 'none',
      '&.MuiPaper-root::before': {
        height: '0px'
      }
    },
    detailsCont: {
      width: '100%',
      display: 'flex'
    },
    detailsSubCont: {
      width: '50%',
      padding: '5px',
      boxSizing: 'border-box'
    },
    expandIcon: {
      height: '24px',
      width: '24px',
      color: color.primaryText,
      borderRadius: '50%'
    },
    title: (wrap) => ({
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: wrap ? 'wrap' : 'nowrap',
      overflow: 'hidden'
    }),
    subTitle: {
      fontWeight: 600
    },
    summary: {
      width: '100%',
      border: `1px solid ${color.primaryBorder}`,
      backgroundColor: color.secondaryBackground,
      borderRadius: '5px',
      '& .MuiAccordionSummary-content': { width: '90%' }
    },
    accDetails: {
      border: `1px solid ${color.primaryBorder}`,
      borderRadius: '5px'
    }
  };
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ width: '100%' }}>
      {contentArray.length > 0 &&
        contentArray.map((item, index) => {
          return (
            <Accordion
              key={index}
              sx={styles.container}
              expanded={expanded === index}
              onChange={handleChange(index)}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon sx={styles.expandIcon} />}
                aria-controls={index}
                sx={styles.summary}
                id={index}>
                {showProfile ? (
                  <ProfileWithName
                    title={item.title}
                    photoUrl={item.photoUrl}
                    onTitleClick={() =>
                      onTitleClick(item && item.details && item.details[item.details.length - 1])
                    }
                  />
                ) : typeof item.title === 'string' ? (
                  <Text
                    variant="D22"
                    onClick={() => onTitleClick(item)}
                    style={{ ...styles.title(), marginRight: '20px' }}>
                    {item.title}
                  </Text>
                ) : (
                  item.title
                )}
              </AccordionSummary>
              <AccordionDetails sx={styles.accDetails}>
                {item.details.map((item, idx) => (
                  <Box key={idx} sx={styles.detailsCont}>
                    <Box sx={styles.detailsSubCont}>
                      <Text variant="D18" style={styles.subTitle}>
                        {item.subTitle}
                      </Text>
                    </Box>
                    <Box sx={styles.detailsSubCont}>
                      {typeof item.action === 'string' ? (
                        <Text
                          style={{ ...styles.title(true), color: color.secondaryText }}
                          variant="D18">
                          {item.action}
                        </Text>
                      ) : (
                        item.action
                      )}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};
