import { Step, StepButton, Stepper } from '@mui/material';
import { color } from '../../theme';

export const UIStepper = ({ steps, activeStep, onStepperClick }) => {
  const styles = {
    stepBtn: (index) => ({
      '& .MuiStepIcon-root': {
        color: index <= activeStep ? `${color.primary} !important` : undefined
      }
    })
  };
  return (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={step}>
          <StepButton
            onClick={() => onStepperClick && onStepperClick(index)}
            sx={styles.stepBtn(index)}>
            {step}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
