import { Box } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import { styles } from './style';
import { Text } from '../text';
import { defaultValues } from '../../constants/defaultValues';
import { getTitles } from '../../utils';

export const OtpInput = ({
  otp,
  onChange,
  onBlur,
  errorMsg,
  onResendClick,
  otpSend,
  handleOtpSend
}) => {
  const [time, setTime] = useState(45);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 1) {
        clearInterval(interval);
        handleOtpSend(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const handleOtpValidate = (val) => {
    return !isNaN(Number(val));
  };

  return (
    <Box sx={styles.otpContainer}>
      <Box sx={styles.otpSubContainer}>
        <MuiOtpInput
          sx={styles.otpInput(errorMsg)}
          length={6}
          value={otp}
          onBlur={onBlur}
          onChange={onChange}
          validateChar={handleOtpValidate}
        />
        {errorMsg && (
          <Box sx={styles.otpResendContainer}>
            <Text variant="fieldError">{errorMsg}</Text>
          </Box>
        )}

        <Box sx={styles.otpResendContainer}>
          <Text
            variant="D16"
            style={styles.otpTimer(otpSend)}
            onClick={() => {
              if (!otpSend) {
                setTime(45);
                handleOtpSend(true);
                onResendClick && onResendClick();
              }
            }}>
            {otpSend ? (
              <u>{defaultValues.otpInput.resendMsg(formatTime(time))}</u>
            ) : (
              <u>{getTitles('T-10110')}</u>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
