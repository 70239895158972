import { Box, Divider, IconButton, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Text } from '../text';
import { Styles } from './style';
import filter from '../../assets/icons/filter.png';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import { UIButton } from '../button';
import { UICheckBox } from '../check-box';
import { getTitles } from '../../utils';

// Demo filter options

// options = [
//   {
//     filterType: 'Status',
//     value: 'isActive',
//     option: [
//       { name: 'Active', value: 'true' },
//       { name: 'Inactive', value: 'false' }
//     ]
//   }
// ];

export const UIFilter = ({
  options,
  applyFilter,
  selectedOption,
  isMultiSelect = false,
  scrollingOption,
  onlyNum = false,
  title = getTitles('T-10073'),
  showTitle = true
}) => {
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterIndex, setFilterIndex] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedOption) {
      setFilterData(selectedOption);
    } else {
      setFilterData([]);
    }
  }, [selectedOption]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (e) => {
    let value = e.currentTarget.dataset.value;

    // Convert to original type if needed
    if (value === 'true' || value === 'false') {
      value = value === 'true'; // Convert 'true'/'false' string back to boolean
    } else if (!isNaN(value) && !isNaN(parseFloat(value))) {
      value = Number(value); // Convert numeric strings to numbers
    }

    const currentValueKey = options[filterIndex].sections
      ? options[filterIndex].sections[sectionIndex].value
      : options[filterIndex].value;

    if (isMultiSelect) {
      setFilterData((old) => {
        const updatedData = [...old];
        const indexToUpdate = updatedData.findIndex((item) => item[currentValueKey] === value);
        if (indexToUpdate !== -1) {
          // Value already selected, remove it
          updatedData.splice(indexToUpdate, 1);
        } else {
          // Value not selected, add it
          updatedData.push({ [currentValueKey]: value });
        }
        return updatedData;
      });
    } else {
      setFilterData((old) => {
        const existingIndex = old.findIndex((item) => currentValueKey in item);
        if (existingIndex !== -1) {
          const updatedData = [...old];
          updatedData[existingIndex][currentValueKey] = value;
          return updatedData;
        }
        return [...old, { [currentValueKey]: value }];
      });
    }
  };

  const handleApplyClick = () => {
    setAnchorEl(null);
    if (onlyNum) {
      const convertedData = filterData.map((obj) => {
        const newObj = {};
        for (const key in obj) {
          newObj[key] = isNaN(obj[key]) ? obj[key] : Number(obj[key]);
        }
        return newObj;
      });
      applyFilter && applyFilter(convertedData);
    } else {
      applyFilter && applyFilter(filterData);
    }
    if (isMultiSelect) {
      setFilterData([]);
    } else {
      setFilterData((old) =>
        old.map((obj) => {
          for (const key in obj) {
            if (obj[key] === null) obj[key] = 'null';
          }
          return obj;
        })
      );
    }
  };

  const getIsChecked = (item) => {
    const currentValueKey = options[filterIndex].sections
      ? options[filterIndex].sections[sectionIndex].value
      : options[filterIndex].value;

    return filterData && filterData.some((data) => data[currentValueKey] === item.value);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (parseInt(container.scrollTop + container.clientHeight) >= container.scrollHeight - 5) {
      scrollingOption && scrollingOption(options[filterIndex].value);
    }
  };

  return (
    <Box>
      <Box style={Styles.toolsIconContainer(showTitle)} onClick={handleClick}>
        <img style={Styles.toolsIcons(showTitle)} src={filter} />
        {showTitle && <Text variant="D20">{title}</Text>}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={Styles.menu(anchorEl)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ListSubheader disableGutters sx={Styles.list}>
          <Box sx={Styles.menuHeader}>
            <Text style={Styles.projectFilter} variant="D18">
              {getTitles('T-10073')} ( {options?.length} )
            </Text>
            <IconButton onClick={() => setAnchorEl(null)}>
              <Close sx={Styles.closeBtn} />
            </IconButton>
          </Box>
        </ListSubheader>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.contentContainer}>
          <Box sx={Styles.subContainer}>
            {options?.length > 0 &&
              options.map((item, i) => (
                <Box
                  sx={Styles.typesText(filterIndex === i)}
                  key={i}
                  onClick={() => setFilterIndex(i)}>
                  <Text variant="D16">{item.filterType}</Text>
                </Box>
              ))}
          </Box>
          {options?.length > 0 && options[filterIndex]?.sections && (
            <Box sx={Styles.subContainer}>
              {options?.length > 0 &&
                options[filterIndex].sections &&
                options[filterIndex].sections.length > 0 &&
                options[filterIndex].sections.map((sectionItem, innerIndex) => (
                  <Box
                    sx={Styles.typesText(sectionIndex === innerIndex)}
                    key={`${filterIndex}-${innerIndex}`}
                    onClick={() => setSectionIndex(innerIndex)}>
                    <Text variant="D16">{sectionItem.name}</Text>
                  </Box>
                ))}
            </Box>
          )}

          <Box sx={Styles.menuAndBtnContainer}>
            <div ref={containerRef} onScroll={handleScroll} style={Styles.menuContainer}>
              {options?.length > 0 &&
                // Check if sections are present, then use the section's options
                (
                  (options[filterIndex].sections &&
                    options[filterIndex].sections[sectionIndex].option) ||
                  options[filterIndex].option
                ).map((item, index) => (
                  <MenuItem
                    key={index}
                    sx={Styles.menuItem}
                    data-value={item.value}
                    onClick={handleChange}>
                    <Box sx={Styles.itemContainer}>
                      <UICheckBox
                        sx={Styles.checkBox}
                        checkedIconStyle={Styles.checkboxIcon}
                        checked={getIsChecked(item)}
                        iconStyle={Styles.icon}
                        type="square"
                      />
                      <div style={Styles.textBreak}>{item.name}</div>
                    </Box>
                  </MenuItem>
                ))}
            </div>
            <Box sx={Styles.applyBtnContainer}>
              <UIButton title="Apply" style={Styles.applyButton} onClick={handleApplyClick} />
            </Box>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};
