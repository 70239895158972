import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';

import { useEffect, useState } from 'react';
import {
  getLaboratoryDetailsRequest,
  getLaboratorysRequest,
  resetLaboratoryMsg,
  setLaboratoryActiveStep,
  setLaboratoryCurrRender,
  setLaboratoryData,
  setLaboratoryStep,
  updateDetailsPg,
  updateLaboratoryRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { checkReadOnly, convertStringsToBoolean, getTitles, resetMessages } from '../../utils';
import { ActionButton, EditButton, Screen, Text, UIButton, UILayout } from '../../ui-kits';
import { AddEditLaboratory } from './add-edit-laboratory';
import {
  getLaboratory,
  getLaboratoryCurPage,
  getLaboratoryCurrPage,
  getLaboratoryErrorMsg,
  getLaboratoryLoading,
  getLaboratorySuccessMsg,
  getLaboratoryTotalPage,
  getUserData
} from '../../services/redux/selectors';
import { BasicProfile } from '../../components';
import { Box } from '@mui/material';
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  resCurrPgNameCont: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px',
    marginRight: 0
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  },
  btn: {
    minWidth: res && 0,
    fontSize: res ? '1.35rem' : '1.3rem',
    padding: res ? '5px' : '5px 20px',
    width: res ? 'fit-content' : '150px',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box'
  }
};

export const ManageLaboratory = () => {
  const dispatch = useDispatch();

  const laboratory = useSelector(getLaboratory);
  const errorMsg = useSelector(getLaboratoryErrorMsg);
  const isLoading = useSelector(getLaboratoryLoading);
  const successMsg = useSelector(getLaboratorySuccessMsg);
  const currRender = useSelector(getLaboratoryCurrPage);
  const totalPages = useSelector(getLaboratoryTotalPage);
  const currentPage = useSelector(getLaboratoryCurPage);
  const userData = useSelector(getUserData);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [nextRender, setNextRender] = useState(false);
  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.laboratoryManager));
  }, [userData]);

  useEffect(() => {
    setNextRender(false);

    handleLaboratory({ page: 1 });
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10075') {
        dispatch(setLaboratoryCurrRender(nextRender));
        dispatch(updateDetailsPg(true));
      }
      if (['S-10076', 'S-10078', 'S-10079', 'S-10077'].includes(successMsg)) {
        dispatch(getLaboratorysRequest());
      }

      setNextRender(false);
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetLaboratoryMsg()));
  }, [successMsg, errorMsg]);

  const handleLaboratory = (data) => {
    const payload = { ...data };
    dispatch(getLaboratorysRequest(payload));
  };

  const handleStatus = (id, status) => {
    id, status;
    dispatch(updateLaboratoryRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setNextRender(view);
    dispatch(getLaboratoryDetailsRequest({ laboratory_id: data._id }));
  };

  const handleSearch = (data) => {
    dispatch(getLaboratorysRequest({ search_text: data }));
  };
  const handleFilter = (data) => {
    setFilter(data);
    handleLaboratory({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleLaboratory({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleLaboratory({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handlePagination = (e, data) => {
    handleLaboratory({ page: data });
  };

  const tableData =
    laboratory &&
    laboratory.length > 0 &&
    laboratory.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-10751'),
            values: `${item.laboratory_id}`
          },
          {
            title: getTitles('T-11073'),
            values: (
              <BasicProfile
                title={item.name}
                isIcon={false}
                textVariant="D18"
                onTitleClick={() => handleEdit(item)}
              />
            )
          },
          {
            title: getTitles('T-10440'),
            values: `${item.phone_number}`
          },
          {
            title: getTitles('T-10734'),
            values: item.location && `${item.location}`
          },
          {
            title: getTitles('T-10103'),
            columnWidth: '12%',
            values: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                alterTitle={defaultValues.confirmationModel.changeLaboratoryStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.subTitle}
                isReadOnly={isReadOnly}
              />
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.name}
              isIcon={false}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10751'),
              action: item.laboratory_id
            },
            {
              subTitle: getTitles('T-10440'),
              action: `${item.phone_number}`
            },
            {
              subTitle: getTitles('T-10734'),
              action: item.location && `${item.location}`
            },
            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  onStatusChange={() => handleStatus(item._id, item.is_active)}
                  alterTitle={defaultValues.confirmationModel.changeLaboratoryStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.subTitle}
                  isReadOnly={isReadOnly}
                />
              )
            }
          ]
        };
      }
    });

  if ([add, edit, view].includes(currRender)) {
    return (
      <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10123')}>
        {res && ![1].includes(currRender) && (
          <Box sx={styles.resCurrPgNameCont}>
            <Text variant="boldH32Sec">
              {currRender === add
                ? getTitles('T-11114')
                : currRender === edit
                  ? getTitles('T-11117')
                  : getTitles('T-11116')}
            </Text>
            {currRender === view && !isReadOnly && (
              <UIButton
                startIcon={<EditButton iconStyle={styles.editIcon} />}
                style={styles.btn}
                onClick={() => dispatch(setLaboratoryCurrRender(edit))}
              />
            )}
          </Box>
        )}
        <AddEditLaboratory currRender={currRender} isReadOnly={isReadOnly} />
      </Screen>
    );
  }

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10123')}>
      {res && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10123')}
        </Text>
      )}
      <UILayout
        isMultiSelectFilterOption={true}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-11005')}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        handleFilter={handleFilter}
        optionsForSorting={defaultValues.farmerSortOptions}
        clearSearch={filter}
        handleAddButton={() => {
          dispatch(setLaboratoryCurrRender(add));
          dispatch(setLaboratoryData(null));
          dispatch(updateDetailsPg(true));
          dispatch(setLaboratoryStep(null));
          dispatch(setLaboratoryActiveStep(0));
        }}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        tableItems={tableData}
        showDataTable={!res}
        showNoData={!laboratory.length > 0}
        message={getErrorMessage('E-10165')}
        isReadOnly={isReadOnly}
        currPage={currentPage}
        pageCount={totalPages}
        showPagination={laboratory.length > 0 && totalPages > 1}
        handlePagination={handlePagination}
      />
    </Screen>
  );
};
