import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { defaultValues } from '../../../constants';
import { color, globalStyles } from '../../../theme';
import {
  EditButton,
  Text,
  UIButton,
  UIPhoneInputTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import { getFormattedOptions, getTitles, insuranceSchema } from '../../../utils';
import { UIBackButton } from '../../../ui-kits/back-button';
import { getDetailsPg, getInsuranceCurrRender } from '../../../services/redux/selectors';
import {
  addInsuranceRequest,
  getDistrictByStateRequest,
  getStateRequest,
  getTalukaByDistrictRequest,
  getVillageByTalukaRequest,
  setInsuranceCurrentRender,
  updateDetailsPg,
  updateInsuranceRequest
} from '../../../services/redux/slices';
import {
  getdistrictByState,
  getdistrictByStateNxtPage,
  getStateNextPage,
  getStates,
  gettalukaByDistrict,
  gettalukaByDistrictNxtPage,
  getvillageByTaluka,
  getvillageByTalukaNxtPage,
  processLoading
} from '../../../services/redux/selectors/manageProccess';
import { FormTitleCard, ModalButtons } from '../../../components';
import { UISelectCheckGroup } from '../../../ui-kits/select-with-checkbox-group';
import { Address } from '../../../components/common/address';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: {
    width: '100%',
    padding: res ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    mt: 2
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    position: 'relative'
  },
  backBtn: {
    position: 'absolute',
    left: md ? -45 : -55
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : defaultValues.isMedium ? '23px' : '26px',
    height: res ? '20px' : defaultValues.isMedium ? '23px' : '26px'
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  },
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "country state"
      "district taluk"
      "village post"
        `
      : `
        "country state district"
        "taluk village post"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  },
  nameGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "first middle"
      "last none"
        `
      : `
        "first middle last"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  },
  width: res ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
  phone: {
    maxWidth: 'none',
    gridArea: 'phone'
  }
};

export const AddEditInsurance = ({ insuranceCompany, insuranceData, isReadOnly }) => {
  const dispatch = useDispatch();

  const states = useSelector(getStates);
  const currRender = useSelector(getInsuranceCurrRender);
  const nextPage = useSelector(getStateNextPage);
  const detailsPg = useSelector(getDetailsPg);

  const districts = useSelector(getdistrictByState);
  const talukas = useSelector(gettalukaByDistrict);
  const villages = useSelector(getvillageByTaluka);
  const districtNxtPg = useSelector(getdistrictByStateNxtPage);
  const villageNxtPge = useSelector(getvillageByTalukaNxtPage);
  const talukNxtPge = useSelector(gettalukaByDistrictNxtPage);
  const processIsLoading = useSelector(processLoading);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isEmp, setIsEmp] = useState(false);
  const { add, edit, view } = defaultValues.actionType;

  const { values, errors, touched, dirty, setValues, handleBlur, setFieldValue, isValid } =
    useFormik({
      initialValues: {
        insurance_company_id: '',
        phone_number: '',
        email_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        country_id: '',
        state_id: '',
        district_id: '',
        taluka_id: '',
        village_id: '',
        post_code: '',
        assigned_location: {
          country_id: '',
          state_id: [],
          district_id: [],
          taluka_id: [],
          village_id: []
        }
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: insuranceSchema
    });

  useEffect(() => {
    dispatch(getStateRequest({ page: 1 }));
  }, []);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    deduplicateAndSet(stateList, states, setStateList);

    deduplicateAndSet(districtsList, districts, setDistrictsLists);

    deduplicateAndSet(talukasList, talukas, setTalukasList);

    deduplicateAndSet(villagesList, villages, setVillagesList);
  }, [districts, talukas, villages, states]);

  const previousOptions = useRef({
    stateNxtPage: nextPage,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    const prev = previousOptions.current;

    if (nextPage !== prev?.stateNxtPage && nextPage !== null) {
      dispatch(getStateRequest({ page: nextPage }));
    }

    if (districtNxtPg !== prev?.districtNxtPage && districtNxtPg !== null) {
      dispatch(
        getDistrictByStateRequest({
          state_ids: JSON.stringify(values.assigned_location?.state_id),
          page: districtNxtPg
        })
      );
    }
    if (talukNxtPge !== prev?.talukaNextPage && talukNxtPge !== null) {
      dispatch(
        getTalukaByDistrictRequest({
          district_ids: JSON.stringify(values.assigned_location?.district_id),
          page: talukNxtPge
        })
      );
    }

    if (villageNxtPge !== prev?.villageNxtPage && villageNxtPge !== null) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_ids: JSON.stringify(values.assigned_location?.taluka_id),
          page: villageNxtPge
        })
      );
    }

    previousOptions.current = {
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districtNxtPg, villageNxtPge, talukNxtPge]);

  useEffect(() => {
    if (insuranceData) {
      setValues({ ...insuranceData, village_id: insuranceData.village_id || '' });

      dispatch(
        getDistrictByStateRequest({
          state_ids: JSON.stringify(insuranceData.assigned_location?.state_id),
          page: 1
        })
      );

      dispatch(
        getTalukaByDistrictRequest({
          district_ids: JSON.stringify(insuranceData.assigned_location?.district_id),
          page: 1
        })
      );
      dispatch(
        getVillageByTalukaRequest({
          taluka_ids: JSON.stringify(insuranceData.assigned_location?.taluka_id),
          page: 1
        })
      );
    }
  }, [insuranceData]);

  const previousValues = useRef({
    state: values?.assigned_location?.state_id?.length,
    district: values?.assigned_location?.district_id?.length,
    village: values?.assigned_location?.village_id?.length,
    taluka: values?.assigned_location?.taluka_id?.length
  });

  useEffect(() => {
    const prev = previousValues.current;

    if (isEmp) {
      const [updatedDistricts, updatedDistrictOptions] = removeDataOfRemovedItem(
        values.assigned_location?.state_id,
        values.assigned_location?.district_id,
        'state_id',
        districtsList
      );

      setFieldValue('assigned_location.district_id', updatedDistricts);
      setDistrictsLists(updatedDistrictOptions);

      const [updatedTaluka, updatedTalukaOptions] = removeDataOfRemovedItem(
        values.assigned_location?.district_id,
        values.assigned_location?.taluka_id,
        'district_id',
        talukasList
      );
      setFieldValue('assigned_location.taluka_id', updatedTaluka);
      setTalukasList(updatedTalukaOptions);

      const [updatedVillage, updatedVillageOptions] = removeDataOfRemovedItem(
        values.assigned_location?.taluka_id,
        values.assigned_location?.village_id,
        'taluka_id',
        villagesList
      );
      setFieldValue('assigned_location.village_id', updatedVillage);
      setVillagesList(updatedVillageOptions);
      setTimeout(() => {
        setIsEmp(false);
      }, 3000);
    }

    if (
      prev?.state !== values.assigned_location?.state_id?.length &&
      values.assigned_location?.state_id?.length > 0
    ) {
      dispatch(
        getDistrictByStateRequest({
          state_ids: JSON.stringify(values.assigned_location?.state_id),
          page: 1
        })
      );
    }
    if (
      prev?.district !== values.assigned_location?.district_id?.length &&
      values.assigned_location?.district_id?.length > 0
    ) {
      dispatch(
        getTalukaByDistrictRequest({
          district_ids: JSON.stringify(values.assigned_location?.district_id),
          page: 1
        })
      );
    }
    if (
      prev?.taluka !== values.assigned_location?.taluka_id?.length &&
      values.assigned_location?.taluka_id?.length > 0
    ) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_ids: JSON.stringify(values.assigned_location?.taluka_id),
          page: 1
        })
      );
    }

    previousValues.current = {
      state: values?.assigned_location?.state_id?.length,
      district: values?.assigned_location?.district_id?.length,
      village: values?.assigned_location?.village_id?.length,
      taluka: values?.assigned_location?.taluka_id?.length
    };
  }, [
    values.assigned_location?.state_id?.length,
    values.assigned_location?.district_id?.length,
    values.assigned_location?.taluka_id?.length,
    values.assigned_location?.village_id?.length,
    villagesList,
    talukasList,
    districtsList
  ]);

  const removeDataOfRemovedItem = (parentArray, childArray, childField, distList) => {
    const remainingData = distList.filter((item) => parentArray.includes(item[childField]));
    const secondary = remainingData?.map((ele) => ele._id);
    const final = secondary.filter((ele) => childArray.includes(ele));

    return [final?.length > 0 ? final : [], remainingData?.length > 0 ? remainingData : []];
  };

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems?.length > 0 ? uniqueItems : []);
  };

  const handleBackButton = () => {
    dispatch(setInsuranceCurrentRender(1));
    dispatch(updateDetailsPg(false));
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      state_id: values.state_id?._id,
      district_id: values.district_id?._id,
      taluka_id: values.taluka_id?._id,
      village_id: values.village_id?._id
    };

    currRender === add && dispatch(addInsuranceRequest(payload));
    currRender === edit && dispatch(updateInsuranceRequest(payload));
    dispatch(setInsuranceCurrentRender(1));
    dispatch(updateDetailsPg(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  return (
    <Box sx={styles.container}>
      {!res && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton onClick={handleBackButton} style={styles.backBtn} />
          <Text variant="boldH32Sec">
            {currRender === add
              ? getTitles('T-11139')
              : currRender === view
                ? getTitles('T-10656')
                : getTitles('T-11143')}
          </Text>
          {currRender === view && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(setInsuranceCurrentRender(edit))}
            />
          )}
        </Box>
      )}

      <UISelect
        name="insurance_company_id"
        value={values.insurance_company_id}
        containerStyles={{ ...globalStyles.gridCountry, mt: 2 }}
        title={getTitles('T-11068')}
        options={insuranceCompany}
        optionLabel="name"
        returnValue={'_id'}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={getTitles('T-10350')}
        required={true}
        width={styles.width}
        error={touched.insurance_company_id && errors.insurance_company_id}
        disabled={currRender === view}
      />
      <Box sx={styles.nameGrid}>
        <UITextInputWithTitle
          name="first_name"
          required
          value={values.first_name}
          onChange={(val) => {
            setBtndisabled(false);
            handleChange(val);
          }}
          maxLength={10}
          onBlur={handleBlur}
          error={touched.first_name && errors.first_name}
          title={getTitles('T-11069')}
          containerStyles={globalStyles.gridFirstName}
          placeHolder={getTitles('T-10047')}
          allowOnlyAlphabets={true}
          disabled={currRender === view}
        />

        <UITextInputWithTitle
          name="middle_name"
          title={getTitles('T-10337')}
          placeHolder={getTitles('T-10338')}
          error={touched.middle_name && errors.middle_name}
          value={values.middle_name}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={globalStyles.gridMiddlename}
          maxLength={10}
          allowOnlyAlphabets={true}
          disabled={currRender === view}
        />

        <UITextInputWithTitle
          name="last_name"
          required
          value={values.last_name}
          onChange={(val) => {
            setBtndisabled(false);
            handleChange(val);
          }}
          maxLength={10}
          onBlur={handleBlur}
          error={touched.last_name && errors.last_name}
          title={getTitles('T-10042')}
          containerStyles={globalStyles.gridLastname}
          placeHolder={getTitles('T-10048')}
          allowOnlyAlphabets
          disabled={currRender === view}
        />
      </Box>
      <UIPhoneInputTitle
        phoneValue={values.phone_number}
        onChange={(val) => {
          setFieldValue('phone_number', val);
          setBtndisabled(val ? false : true);
        }}
        handleError={(isError) => setBtndisabled(isError)}
        required={true}
        containerStyles={styles.phone}
        width={styles.width}
        disabled={currRender === view}
        title={getTitles('T-11142')}
      />
      <UITextInputWithTitle
        name="email_id"
        value={values.email_id}
        onChange={(val) => {
          setBtndisabled(false);
          handleChange(val);
        }}
        onBlur={handleBlur}
        error={touched.email_id && errors.email_id}
        title={getTitles('T-10043')}
        placeHolder={getTitles('T-10049')}
        width={styles.width}
        disabled={currRender === view}
      />

      <FormTitleCard title={getTitles('T-11141')}>
        <Address
          actionType={currRender}
          objects={values}
          divider={false}
          type={'location'}
          showPostCode={true}
          isCountryRequired={true}
          isStateRequired={true}
          isDistrictRequired={true}
          isTalukaRequired={true}
          isPostCodeRequired={true}
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'country_id', value: e.target.value } })
          }
          onCountryBlurred={handleBlur('country_id')}
          countryCodeError={touched.country_id && errors.country_id}
          onStateSelected={(val) => {
            handleChange({ target: { name: 'state_id', value: val } });
          }}
          onStateBlurred={handleBlur('state_id')}
          stateCodeError={touched.state_id?._id && errors.state_id?._id}
          onDistrictSelected={(val) =>
            handleChange({ target: { name: 'district_id', value: val } })
          }
          onDistrictBlurred={handleBlur('district_id')}
          districtCodeError={touched.district_id?._id && errors.district_id?._id}
          onTalukaSelected={(val) => handleChange({ target: { name: 'taluka_id', value: val } })}
          onTalukaBlurred={handleBlur('taluka_id')}
          talukaCodeError={touched.taluka_id?._id && errors.taluka_id?._id}
          onVillageSelected={(val) => handleChange({ target: { name: 'village_id', value: val } })}
          onPostCodeBlurred={handleBlur('post_code')}
          postCodeError={touched.post_code && errors.post_code}
          onPostCodeChange={(val) => {
            handleChange({ target: { name: 'post_code', value: val.target.value } });
          }}
        />
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-11140')}>
        <Box sx={styles.gridCont}>
          <UISelect
            name="assigned_location.country_id"
            value={values.assigned_location.country_id}
            containerStyles={globalStyles.gridCountry}
            title={getTitles('T-10363')}
            options={defaultValues.countriesList}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            required={true}
            error={
              touched.assigned_location &&
              touched.assigned_location.country_id &&
              errors.assigned_location &&
              errors.assigned_location.country_id
            }
            disabled={currRender === view}
          />
          <UISelectCheckGroup
            name="assigned_location.state_id"
            error={
              touched.assigned_location &&
              touched.assigned_location.state_id &&
              errors.assigned_location &&
              errors.assigned_location.state_id
            }
            required={true}
            onBlur={handleBlur}
            options={[
              {
                title: '',
                value:
                  stateList &&
                  stateList.length > 0 &&
                  stateList.map((item) => ({ title: item.name, value: item._id }))
              }
            ]}
            title={getTitles('T-10070')}
            showTitle
            onChange={(val) => {
              setFieldValue('assigned_location.state_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assigned_location.state_id}
            placeholder={getTitles('T-10350')}
            containerStyles={globalStyles.gridState}
            disabled={currRender === view || processIsLoading}
          />
          <UISelectCheckGroup
            name={'assigned_location.district_id'}
            error={
              touched.assigned_location &&
              touched.assigned_location.district_id &&
              errors.assigned_location &&
              errors.assigned_location.district_id
            }
            required={true}
            onBlur={handleBlur}
            options={getFormattedOptions(districtsList, 'state_id').map((item) => ({
              title: stateList.find((ele) => ele._id === item.id)?.name,
              value:
                item.value &&
                item.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            title={getTitles('T-10069')}
            onChange={(val) => {
              setFieldValue('assigned_location.district_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assigned_location && values.assigned_location.district_id}
            placeholder={getTitles('T-10350')}
            disabled={
              currRender === view ||
              (values.assigned_location && values.assigned_location.state_id.length < 1) ||
              processIsLoading
            }
            containerStyles={globalStyles.gridDistrict}
            showTitle
          />
          <UISelectCheckGroup
            name={'assigned_location.taluka_id'}
            error={
              touched.assigned_location &&
              touched.assigned_location.taluka_id &&
              errors.assigned_location &&
              errors.assigned_location.taluka_id
            }
            required={true}
            onBlur={handleBlur}
            options={getFormattedOptions(talukasList, 'district_id').map((unique) => ({
              title: districtsList.find((ele) => ele._id === unique.id)?.name,
              value:
                unique.value &&
                unique.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            title={getTitles('T-10071')}
            onChange={(val) => {
              setFieldValue('assigned_location.taluka_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assigned_location.taluka_id}
            placeholder={getTitles('T-10350')}
            disabled={
              (values.assigned_location && values.assigned_location.district_id.length < 1) ||
              currRender === view ||
              processIsLoading
            }
            containerStyles={globalStyles.gridTaluk}
            showTitle
          />
          <UISelectCheckGroup
            name={'assigned_location.village_id'}
            onBlur={handleBlur}
            options={getFormattedOptions(villagesList, 'taluka_id').map((unique) => ({
              title: talukasList.find((ele) => ele._id === unique.id)?.name,
              value:
                unique.value &&
                unique.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            title={getTitles('T-10068')}
            showTitle
            onChange={(val) => {
              setFieldValue('assigned_location.village_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assigned_location && values.assigned_location.village_id}
            placeholder={getTitles('T-10350')}
            disabled={
              (values.assigned_location && values.assigned_location.taluka_id.length < 1) ||
              currRender === view ||
              processIsLoading
            }
            containerStyles={globalStyles.gridVillage}
          />
        </Box>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={currRender === view ? getTitles('T-11103') : getTitles('T-10045')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={currRender !== view && handleSubmit}
        primaryBtnTitle={
          currRender === add ? getTitles('T-10394') : currRender === edit && getTitles('T-10733')
        }
        disabled={!isValid || !dirty || btnDisabled}
        containerStyle={globalStyles.mt(3)}
        secondaryVariant={currRender === view ? 'primary' : 'secondary'}
      />
    </Box>
  );
};
