import { Box } from '@mui/material';
import { Text } from '../../../../ui-kits';
import { styles } from './styles';

export const StatusCard = ({ icon1, icon2, title1, title2 }) => {
  return (
    <Box sx={styles.container}>
      {icon1 && title1 && (
        <Box sx={styles.statusCont}>
          <img src={icon1} style={styles.profileIcon} />
          <Text variant="D10">{title1}</Text>
        </Box>
      )}
      {icon2 && title2 && (
        <Box sx={styles.statusCont}>
          <img src={icon2} style={styles.profileIcon} />
          <Text variant="D10">{title2}</Text>
        </Box>
      )}
    </Box>
  );
};
