import { useDispatch, useSelector } from 'react-redux';
import { NoData, Text, UIButton } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import { styles } from './styles';
import { authStatus, getCartItems } from '../../../../services/redux/selectors';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { setCartOpen } from '../../../../services/redux/slices';
import { defaultValues, getErrorMessage, SCREEN_PATH } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

export const CartModal = ({ showModel, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector(getCartItems);
  const isAuthenticated = useSelector(authStatus);

  const totalItems = Object.values(cartItems || {});
  const res = defaultValues.isResponsive;

  const getQuantity = () => {
    let qty = 0;
    totalItems?.map((item) => (qty = qty + item.quantity));
    return qty;
  };
  const totalQuantity = getQuantity();

  const totalPrice = useMemo(() => {
    dispatch(setCartOpen(totalItems.length > 0));
    let price = 0;
    totalItems?.map((item) => {
      if (item.free_for_employee && isAuthenticated) {
        price = price + 0;
      } else {
        price = price + item.final_price * item.quantity;
      }
    });
    return price;
  }, [totalQuantity]);

  const handleShoppingCart = () => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`);
  };

  return !res ? (
    <Box sx={styles.container(showModel)}>
      <Box sx={styles.titleContainer}>
        <Box sx={styles.titleStyle('left')}>
          <Text variant={'BoldH22'}>{`${getTitles('T-11639')}(${totalItems.length})`}</Text>
        </Box>

        <Box sx={styles.closeBtnStyle} onClick={onClose}>
          <CloseIcon fontSize="large" />
        </Box>
      </Box>
      <Box sx={styles.childContainer()}>
        {totalItems?.length > 0 ? (
          <Box sx={styles.superSubCont}>
            <Box sx={styles.superCont}>
              {totalItems.map((elem, idx) => (
                <Box key={idx} sx={styles.itemsCont}>
                  <img
                    style={styles.img}
                    src={(elem.product_image && elem.product_image[0]) || null}
                  />
                  <Box sx={styles.detailsCont}>
                    <Text variant="BoldH20">{elem.product_name}</Text>
                    <Box sx={styles.detailsSubCont}>
                      <Text>
                        {getTitles('T-11643')}&nbsp;
                        <span style={styles.secondaryText}>{elem.quantity}</span>
                      </Text>
                      <Text
                        style={
                          styles.secondaryText
                        }>{`₹${elem.free_for_employee && isAuthenticated ? 0 : elem.final_price || 0}`}</Text>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={styles.secSub}>
              <Text>{getTitles('T-11640')}&nbsp; :</Text>
              <Text>{`₹${totalPrice}`}</Text>
            </Box>
            <UIButton
              title={getTitles('T-11641')}
              style={styles.checkOutBtn}
              endIcon={<ArrowForwardOutlinedIcon />}
            />
            <UIButton
              title={getTitles('T-11642')}
              style={styles.viewCartBtn}
              onClick={handleShoppingCart}
            />
          </Box>
        ) : (
          <NoData message={getErrorMessage('E-10028')} height="150px" />
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={styles.container(showModel)}>
      <Box sx={styles.subContainer}>
        {totalItems
          .map((el, idx) => (
            <img
              key={idx}
              src={(el.product_image && el.product_image[0]) || null}
              style={styles.image(idx)}
            />
          ))
          .slice(0, 3)}
        <Box sx={styles.closeIcon2}>
          <Text
            variant="BoldH20"
            onClick={handleShoppingCart}
            style={
              styles.viewCartResBtn
            }>{`${getTitles('T-11649')} (${totalItems.length}) ${getTitles('T-11650')}`}</Text>
          <CloseIcon fontSize="medium" onClick={onClose} />
        </Box>
      </Box>
    </Box>
  );
};
