import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  govtOfficials: [],
  govtOfficialData: null,
  currRender: 1,
  govtOfficialParams: null,
  currUserId: null
};

const govtOfficialSlice = createSlice({
  initialState,
  name: 'govtOfficial',
  reducers: {
    resetGovtOffice(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.govtOfficials = [];
      state.govtOfficialData = null;
      state.currRender = 1;
      state.govtOfficialParams = null;
      state.currUserId = null;
    },
    setCurrUserId(state, action) {
      state.currUserId = action.payload;
    },
    addGovtOfficialRequest(state) {
      state.isLoading = true;
    },
    addGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getGovtOfficialsRequest(state) {
      state.isLoading = true;
    },
    getGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.govtOfficials = action.payload.data.govtOfficer;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.govtOfficials = [];
      state.errorMessage = action.payload.status_code;
    },
    updateGovtOfficialRequest(state) {
      state.isLoading = true;
    },
    updateGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    getGovtOfficialDetailsRequest(state) {
      state.isLoading = true;
    },
    getGovtOfficialDetailsSuccess(state, action) {
      state.isLoading = false;
      state.govtOfficialData = action.payload.data;
    },
    getGovtOfficialDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.govtOfficialData = null;
    },

    setGovtOfficialData(state, action) {
      state.govtOfficialData = action.payload;
    },
    setGovtOfficialCurrentRender(state, action) {
      state.currRender = action.payload;
    },
    setGovtOfficialParams(state, action) {
      state.govtOfficialParams = action.payload;
    },
    resetGovtOfficialMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    }
  }
});

export const {
  resetGovtOfficialMsg,
  setGovtOfficialData,
  setGovtOfficialParams,
  setGovtOfficialCurrentRender,
  addGovtOfficialFailure,
  addGovtOfficialRequest,
  addGovtOfficialSuccess,
  getGovtOfficialFailure,
  getGovtOfficialsRequest,
  getGovtOfficialSuccess,
  updateGovtOfficialFailure,
  updateGovtOfficialRequest,
  updateGovtOfficialSuccess,
  getGovtOfficialDetailsRequest,
  getGovtOfficialDetailsFailure,
  getGovtOfficialDetailsSuccess,
  resetGovtOffice,
  setCurrUserId
} = govtOfficialSlice.actions;

export default govtOfficialSlice.reducer;
