import { createSelector } from '@reduxjs/toolkit';

export const getLaboratory = createSelector([(state) => state.laboratory.laboratorys], (d) => d);
export const getLaboratoryCurPage = createSelector(
  [(state) => state.laboratory.currentPage],
  (d) => d
);
export const getLaboratoryNxtPage = createSelector(
  [(state) => state.laboratory.nextPage],
  (d) => d
);
export const getLaboratoryTotalPage = createSelector(
  [(state) => state.laboratory.totalPage],
  (d) => d
);
export const getLaboratoryLoading = createSelector(
  [(state) => state.laboratory.isLoading],
  (d) => d
);
export const getLaboratoryData = createSelector(
  [(state) => state.laboratory.laboratoryData],
  (d) => d
);
export const getLaboratorySuccessMsg = createSelector(
  [(state) => state.laboratory.successMessage],
  (d) => d
);
export const getLaboratoryErrorMsg = createSelector(
  [(state) => state.laboratory.errorMessage],
  (d) => d
);
export const getLaboratoryCurrPage = createSelector(
  [(state) => state.laboratory.currRender],
  (d) => d
);
export const getLaboratoryCompletedStep = createSelector(
  [(state) => state.laboratory.completedStep],
  (d) => d
);

export const getPathologist = createSelector([(state) => state.laboratory.pathologists], (d) => d);
export const getLabTech = createSelector([(state) => state.laboratory.labTech], (d) => d);
export const getSupportEmp = createSelector([(state) => state.laboratory.supportEmp], (d) => d);
export const getLaboratoryActiveStep = createSelector(
  [(state) => state.laboratory.activeStep],
  (d) => d
);

export const getLabIsInit = createSelector([(state) => state.laboratory.isInit], (d) => d);
