import { Box, Checkbox, Divider } from '@mui/material';
import { Text, UISaveAndAdd, UISelect } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';
import { color } from '../../../../../theme';
import { UISelectCheckGroup } from '../../../../../ui-kits/select-with-checkbox-group';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStateRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill
} from '../../../../../services/redux/slices';
import { processLoading } from '../../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  statesCont: {
    width: '100%',
    marginTop: '10px'
  },
  assignCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  expandIcon: (expand) => ({
    transform: expand ? `rotate(180deg)` : 'none',
    transition: 'transform 0.3s ease'
  }),
  divider: {
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: 'calc(((100%/3 )* 2) - 5px)'
  },
  university: {
    width: res ? '100%' : 'calc(((100%/3 )* 2) - 5px)'
  },
  durationCont: {
    width: res ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  courseCont: {
    width: res ? '100%' : '80%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"state district"
        " taluk village"
        `
      : `
        " state district taluk"
        " village none none"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px'
  },
  gridCountry: {
    gridArea: 'country'
  },
  gridState: {
    gridArea: 'state'
  },
  gridDistrict: {
    gridArea: 'district'
  },
  gridTaluk: {
    gridArea: 'taluk'
  },
  gridVillage: {
    gridArea: 'village'
  },
  subCont: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  checkSuperCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px',
    marginTop: '10px'
  },
  checkIcon: {
    '&.Mui-checked': {
      color: color.primary
    },
    padding: '8px 8px 8px 0px'
  }
};

export const PermissionForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setBtndisabled,
  handleAddAnother,
  isValid,
  btnDisabled,
  dirty,
  states,
  validationErrors,
  clear,
  resetForm,
  isEdit,
  permissions,
  districtList,
  talukaList,
  villageList,
  setIsEmp
}) => {
  const dispatch = useDispatch();
  const processIsLoading = useSelector(processLoading);

  const getPermOptions = (options, permission) => {
    const permList =
      permission.length > 0 &&
      permission.map((item) => item.permission_id)?.filter((item) => item !== values.permissions);

    const final = permList && options.filter((item) => !permList.includes(item.value));
    return final || options;
  };

  const getFormattedOptions = (initialArray, parentKey) => {
    if (initialArray && parentKey) {
      const output = initialArray.reduce((acc, elem) => {
        const state = acc.find((item) => item.id === elem[parentKey]);
        if (state) {
          state.value.push(elem);
        } else {
          acc.push({
            id: elem[parentKey],
            value: [elem]
          });
        }
        return acc;
      }, []);

      return output || [];
    }
  };

  return (
    <Box sx={styles.container}>
      <UISelect
        name="permissions"
        value={values.permissions}
        title={getTitles('T-10052')}
        options={getPermOptions(defaultValues.employeePermissions, permissions)}
        onChange={(e) => {
          resetForm();
          handleChange(e);
          dispatch(setClearProcessStateDistTalVill());
          dispatch(setClearProcessPages());
          dispatch(getStateRequest({ page: 1 }));
        }}
        onBlur={handleBlur}
        error={touched.permissions && errors.permissions}
        placeholder={getTitles('T-10053')}
        required={true}
        containerStyles={styles.university}
      />

      {values.permissions && (
        <>
          {![
            defaultValues.employeeRol.vetDoctor,
            defaultValues.employeeRol.aiWorker,
            defaultValues.employeeRol.callCenterAdmin,
            defaultValues.employeeRol.callCenterAgents,
            defaultValues.employeeRol.laboratoryIncharge
          ].includes(values.permissions) && (
            <Box sx={styles.checkSuperCont}>
              <Box sx={styles.subCont}>
                <Checkbox
                  checked={values.read_only}
                  onChange={(e) => {
                    setFieldValue('read_only', e.target.checked);
                    setBtndisabled(false);
                  }}
                  sx={styles.checkIcon}
                />
                <Text variant="D20">{getTitles('T-10080')}</Text>
              </Box>

              {[
                defaultValues.employeeRol.farmerManager,
                defaultValues.employeeRol.societyManager
              ].includes(values.permissions) && (
                <Box sx={styles.subCont}>
                  <Checkbox
                    checked={values.has_approve_permission}
                    onChange={(e) => {
                      setFieldValue('has_approve_permission', e.target.checked);
                      setBtndisabled(false);
                    }}
                    sx={styles.checkIcon}
                  />
                  <Text variant="D20">{getTitles('T-10672')}</Text>
                </Box>
              )}
            </Box>
          )}

          <Box sx={styles.statesCont}>
            <Box sx={styles.assignCont}>
              <Text variant="BoldH28">{getTitles('T-10472')}</Text>
            </Box>

            <Divider sx={styles.divider} />
            <Box sx={styles.gridCont}>
              <UISelectCheckGroup
                name={defaultValues.appLogicalTexts.states}
                error={touched.states && errors.states}
                required={true}
                onBlur={handleBlur}
                options={[
                  {
                    title: '',
                    value:
                      states &&
                      states.length > 0 &&
                      states.map((item) => ({ title: item.name, value: item.name }))
                  }
                ]}
                title={getTitles('T-10070')}
                onChange={(val) => {
                  const newArr = states.filter((item) => val.includes(item.name));
                  setFieldValue(defaultValues.appLogicalTexts.states, newArr);
                  setBtndisabled(false);
                  setIsEmp(true);
                }}
                preSelectedValues={values.states}
                placeholder={getTitles('T-10350')}
                containerStyles={styles.gridState}
                disabled={processIsLoading}
                hasName={true}
              />
              <UISelectCheckGroup
                name={defaultValues.appLogicalTexts.districts}
                error={touched.districts && (errors.districts || validationErrors?.districts)}
                required={true}
                onBlur={handleBlur}
                onClear={clear}
                options={getFormattedOptions(districtList, 'state_id').map((item) => ({
                  title: states.find((ele) => ele._id === item.id)?.name,
                  value:
                    item.value &&
                    item.value.map((item) => ({
                      title: item.name,
                      value: item._id
                    }))
                }))}
                showTitle
                title={getTitles('T-10069')}
                onChange={(val) => {
                  setFieldValue(defaultValues.appLogicalTexts.districts, val);
                  setBtndisabled(false);
                  setIsEmp(true);
                }}
                preSelectedValues={values.districts}
                placeholder={getTitles('T-10350')}
                disabled={values.states.length < 1 || processIsLoading}
                containerStyles={styles.gridDistrict}
              />
              <UISelectCheckGroup
                name={defaultValues.appLogicalTexts.taluka}
                error={touched.taluka && (errors.taluka || validationErrors?.taluka)}
                required={true}
                onBlur={handleBlur}
                onClear={clear}
                options={getFormattedOptions(talukaList, 'district_id').map((unique) => ({
                  title: districtList.find((ele) => ele._id === unique.id)?.name,
                  value:
                    unique.value &&
                    unique.value.map((item) => ({
                      title: item.name,
                      value: item._id
                    }))
                }))}
                showTitle
                title={getTitles('T-10071')}
                onChange={(val) => {
                  setFieldValue(defaultValues.appLogicalTexts.taluka, val);
                  setBtndisabled(false);
                  setIsEmp(true);
                }}
                preSelectedValues={values.taluka}
                placeholder={getTitles('T-10350')}
                disabled={values.districts.length < 1 || processIsLoading}
                containerStyles={styles.gridTaluk}
              />
              <UISelectCheckGroup
                name={defaultValues.appLogicalTexts.villages}
                required={true}
                onClear={clear}
                error={touched.villages && (errors.villages || validationErrors?.villages)}
                onBlur={handleBlur}
                options={getFormattedOptions(villageList, 'taluka_id').map((unique) => ({
                  title: talukaList.find((ele) => ele._id === unique.id)?.name,
                  value:
                    unique.value &&
                    unique.value.map((item) => ({
                      title: item.name,
                      value: item._id
                    }))
                }))}
                showTitle
                title={getTitles('T-10068')}
                onChange={(val) => {
                  setFieldValue(defaultValues.appLogicalTexts.villages, val);
                  setBtndisabled(false);
                  setIsEmp(true);
                }}
                preSelectedValues={values.villages}
                placeholder={getTitles('T-10350')}
                disabled={values.taluka.length < 1 || processIsLoading}
                containerStyles={styles.gridVillage}
              />
            </Box>
          </Box>
        </>
      )}

      <UISaveAndAdd
        title={isEdit === true ? getTitles('T-10062') : getTitles('T-10477')}
        onAddAnother={handleAddAnother}
        disabled={!isValid || !dirty || btnDisabled}
      />
    </Box>
  );
};
