import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { checkReadOnly, convertStringsToBoolean, getTitles, resetMessages } from '../../utils';
import { toast } from 'react-toastify';
import { ActionButton, Screen, UICheckBox, UILayout } from '../../ui-kits';
import { Box } from '@mui/material';
import { BasicProfile } from '../../components';
import {
  getAiWorkerErrorMsg,
  getAiWorkerLoading,
  getAiWorkerSuccessMsg,
  getCurrVet,
  getDetailsPg,
  getUnassLabsCurrPage,
  getUnassLabsTotalPage,
  getUnassignAi,
  getUnassignCurrPage,
  getUnassignTotalPage,
  getUnassignedLabs,
  getUserData,
  getvetCurrPage,
  getvetCurrRender,
  getvetParams,
  getvetTotalPage,
  getvets,
  vetErrorMsg,
  vetLoading,
  vetSuccessMsg
} from '../../services/redux/selectors';
import {
  addAiToVetRequest,
  assignLabToVetRequest,
  getUnassignedAIRequest,
  getUnassignedLabsRequest,
  getVetRequest,
  resetAIWorkerMessages,
  resetVetMessages,
  updateAssignedAiRequest,
  updateCurrVet,
  updateDetailsPg,
  updateVetCurrRender,
  updateVetParams,
  updateVetStatusRequest
} from '../../services/redux/slices';
import assignIcon from '../../assets/icons/share_windows.png';
import closeIcon from '../../assets/icons/close_small.png';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const ManageVet = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(vetLoading);
  const vets = useSelector(getvets);
  const errorMsg = useSelector(vetErrorMsg);
  const successMsg = useSelector(vetSuccessMsg);
  const isAiLoading = useSelector(getAiWorkerLoading);
  const errorMsgAi = useSelector(getAiWorkerErrorMsg);
  const successMsgAi = useSelector(getAiWorkerSuccessMsg);
  const totalPages = useSelector(getvetTotalPage);
  const currentPage = useSelector(getvetCurrPage);
  const vetParams = useSelector(getvetParams);
  const currRender = useSelector(getvetCurrRender);
  const unassignTotalPages = useSelector(getUnassignTotalPage);
  const unassignCurrentPage = useSelector(getUnassignCurrPage);
  const unassignedAi = useSelector(getUnassignAi);
  const currentVet = useSelector(getCurrVet);
  const detailsPg = useSelector(getDetailsPg);
  const userData = useSelector(getUserData);
  const unassignedLabs = useSelector(getUnassignedLabs);
  const unAssLabsCurrPage = useSelector(getUnassLabsCurrPage);
  const unAssLabsTotalPage = useSelector(getUnassLabsTotalPage);

  const [currVet, setCurrVet] = useState(null);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [checked, setChecked] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [hasAiworker, setHasAiworker] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [isRemove, setIsRemove] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { viewVetScr, assignedGopalanScr, unassignedGopalanScr, assignedLabScr, unassignedLabScr } =
    defaultValues.vetScreen;
  const { gopalan, labs } = defaultValues.appLogicalTexts;

  const styles = {
    container: { width: '100%' },

    assign: (isRead) => ({
      padding: res ? '0px' : '5px 25px',
      boxSizing: 'border-box',
      height: res ? '15px' : '25px',
      cursor: 'pointer',
      opacity: isRead ? '0.5' : '1'
    }),
    assignViewScr: (isRead) => ({
      padding: res ? '0px' : md ? '5px 40px' : '5px 57px',
      boxSizing: 'border-box',
      height: res ? '15px' : '25px',
      cursor: 'pointer',
      opacity: isRead ? '0.5' : '1'
    }),
    removeCont: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    addBtnStyle: {
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      width: 'fit-content',
      '& .MuiButton-startIcon': {
        marginRight: '0px',
        marginLeft: '0px'
      }
    },
    profileCont: {
      width: 'calc(100% - 50px)'
    }
  };

  useEffect(() => {
    if (!detailsPg) {
      dispatch(updateVetCurrRender(getPrevPageNo()));
    }
  }, [detailsPg]);

  const getPrevPageNo = () => {
    if (
      (currRender === unassignedGopalanScr && !currVet.ai_workers) ||
      (currRender === unassignedGopalanScr && currVet.ai_workers.length === 0) ||
      (currRender === unassignedLabScr && currVet.labs.length === 0)
    ) {
      setBtnDisabled(true);
      return viewVetScr;
    }

    if ([assignedGopalanScr, assignedLabScr].includes(currRender)) return viewVetScr;

    if (currRender === unassignedGopalanScr) {
      dispatch(updateDetailsPg(true));
      return assignedGopalanScr;
    }
    if (currRender === unassignedLabScr) {
      dispatch(updateDetailsPg(true));
      return assignedLabScr;
    }
    setBtnDisabled(true);
    return viewVetScr;
  };

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.vetManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg || successMsgAi) {
      toast.success(
        getSuccessMessage(
          isRemove === gopalan
            ? 'S-10053'
            : isRemove === labs
              ? 'S-10094'
              : successMsg || successMsgAi
        )
      );
      ['S-10049', 'S-10038', 'S-10036', 'S-10050', 'S-10052', 'S-10092', 'S-10093'].includes(
        successMsg || successMsgAi
      ) && handleGetVets();

      if (['S-10036', 'S-10038'].includes(successMsg || successMsgAi) && isEmpty === gopalan) {
        dispatch(updateVetCurrRender(unassignedGopalanScr));
        handleUnassignedAi({ page: 1 });
      }

      if (['S-10092', 'S-10093'].includes(successMsg || successMsgAi) && isEmpty === labs) {
        dispatch(updateVetCurrRender(unassignedLabScr));
        handleUnassignedLabs({ page: 1 });
      }
      setIsRemove(false);
      setIsEmpty(false);
      setChecked([]);
    }
    if (errorMsg || errorMsgAi) {
      toast.error(getErrorMessage(errorMsg || errorMsgAi));
    }
    resetMessages(() => dispatch(resetVetMessages()));
    resetMessages(() => dispatch(resetAIWorkerMessages()));
  }, [successMsg, errorMsg, errorMsgAi, successMsgAi]);

  useEffect(() => {
    handleGetVets();
  }, []);

  useEffect(() => {
    if (currVet) {
      const temp = [...vets];
      const currAi = temp.find((item) => item.user_id === currVet.user_id);
      dispatch(updateCurrVet(currAi));
    }
  }, [vets]);

  useEffect(() => {
    setCurrVet(currentVet);
  }, [currentVet]);

  const handleGetVets = (data) => {
    const payload = { ...vetParams, ...data };
    dispatch(updateVetParams(payload));
    dispatch(getVetRequest(payload));
  };

  const handleUnassignedAi = (data) => {
    const payload = { vet_user_id: currVet && currVet.user_id, ...data };
    dispatch(getUnassignedAIRequest(payload));
  };

  const handleUnassignedLabs = (data) => {
    const payload = {
      user_id: currVet && currVet.user_id,
      permission_id: defaultValues.employeeRol.vetDoctor,
      ...data
    };
    dispatch(getUnassignedLabsRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(updateVetStatusRequest({ user_id: item.user_id, is_active: !item.is_active }));
  };

  const handleAssignGopalanClick = (data) => {
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrVet(data));
    setChecked([]);
    setIsEmpty(false);
    data && data.ai_workers ? setHasAiworker(true) : setHasAiworker(false);
    if (data && data.ai_workers && data.ai_workers.length > 0) {
      dispatch(updateVetCurrRender(assignedGopalanScr));
    } else {
      handleUnassignedAi({ vet_user_id: data.user_id, page: 1 });
      dispatch(updateVetCurrRender(unassignedGopalanScr));
    }
  };

  const handleAssignLabClick = (data) => {
    dispatch(updateDetailsPg(true));
    dispatch(updateCurrVet(data));
    setChecked([]);
    setIsEmpty(false);
    if (data && data.labs && data.labs.length > 0) {
      dispatch(updateVetCurrRender(assignedLabScr));
    } else {
      handleUnassignedLabs({ user_id: data.user_id, page: 1 });
      dispatch(updateVetCurrRender(unassignedLabScr));
    }
  };

  const searchWorkers = (workers, searchTerm, searchItem1, searchItem2, searchItem3) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      workers &&
      workers.filter(
        (item) =>
          (item[searchItem1] && item[searchItem1].toLowerCase().includes(lowerCaseTerm)) ||
          (item[searchItem2] && item[searchItem2].toLowerCase().includes(lowerCaseTerm)) ||
          (item[searchItem3] && item[searchItem3].toLowerCase().includes(lowerCaseTerm))
      )
    );
  };

  const handleSearch = (data) => {
    if (currRender === viewVetScr) {
      handleGetVets({ search_text: data, page: 1 });
    }
    if (currRender === unassignedGopalanScr) {
      handleUnassignedAi({ search_text: data, page: 1 });
    }
    if (currRender === assignedGopalanScr) {
      if (data.length === 0) {
        setCurrVet(currentVet);
      } else {
        const searchedWorkers = searchWorkers(currentVet.ai_workers, data, 'employee_id', 'name');
        setCurrVet({ ...currVet, ai_workers: searchedWorkers });
      }
    }

    if (currRender === assignedLabScr) {
      if (data.length === 0) {
        setCurrVet(currentVet);
      } else {
        const searchedWorkers = searchWorkers(
          currentVet.labs,
          data,
          'laboratory_id',
          'laboratory_name',
          'primary_mobile_number'
        );
        setCurrVet({ ...currVet, labs: searchedWorkers });
      }
    }
    if (currRender === unassignedLabScr) {
      handleUnassignedLabs({ search_text: data, page: 1 });
    }
  };

  const handlePagination = (e, data) => {
    currRender === viewVetScr
      ? handleGetVets({ page: data })
      : currRender === unassignedGopalanScr
        ? handleUnassignedAi({ page: data })
        : currRender === unassignedLabScr && handleUnassignedLabs({ page: data });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleGetVets({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: JSON.stringify(sort)
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleGetVets({ page: 1, sort: JSON.stringify(data), filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleGetVets({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handleAssignNewClick = () => {
    if (currRender === assignedGopalanScr) {
      handleUnassignedAi({ page: 1 });
      dispatch(updateVetCurrRender(unassignedGopalanScr));
    }
    if (currRender === assignedLabScr) {
      handleUnassignedLabs({ page: 1 });
      dispatch(updateVetCurrRender(unassignedLabScr));
    }

    setHasAiworker(true);
  };

  const handleSubmitAssign = () => {
    if (currRender === unassignedGopalanScr) {
      const arr =
        currVet && currVet.ai_workers && currVet.ai_workers.length > 0
          ? currVet.ai_workers.map((item) => item.user_id)
          : [];
      if (hasAiworker) {
        dispatch(
          updateAssignedAiRequest({
            vet_user_id: currVet.user_id,
            ai_workers_user_id: [...arr, ...checked]
          })
        );
      } else {
        dispatch(addAiToVetRequest({ vet_user_id: currVet.user_id, ai_workers_user_id: checked }));
      }
    }
    if (currRender === unassignedLabScr) {
      const arr =
        currVet && currVet.labs && currVet.labs.length > 0
          ? currVet.labs.map((item) => item._id)
          : [];
      dispatch(
        assignLabToVetRequest({
          user_id: currVet.user_id,
          laboratory_id: [...arr, ...checked]
        })
      );
    }
    dispatch(
      updateVetCurrRender(
        currRender === unassignedGopalanScr
          ? assignedGopalanScr
          : currRender === unassignedLabScr && assignedLabScr
      )
    );
  };

  const handleRemoveAssignedItem = (item) => {
    if (currRender === assignedGopalanScr) {
      setIsRemove(gopalan);
      let aiworker = currVet.ai_workers
        .map((item) => item.user_id)
        .filter((elem) => elem !== item.user_id);
      dispatch(
        updateAssignedAiRequest({
          vet_user_id: currVet.user_id,
          ai_workers_user_id: aiworker
        })
      );
      if (aiworker.length === 0) {
        setIsEmpty(gopalan);
      }
    }
    if (currRender === assignedLabScr) {
      setIsRemove(labs);
      let labsList = currVet.labs.map((item) => item._id).filter((elem) => elem !== item._id);
      dispatch(
        assignLabToVetRequest({
          user_id: currVet.user_id,
          laboratory_id: labsList
        })
      );
      if (labsList.length === 0) {
        setIsEmpty(labs);
      }
    }
  };

  const handleChecked = (e, item) => {
    let check = e.target.checked;
    let newCheck;
    if (check) {
      if (currRender === unassignedGopalanScr) {
        setChecked([...checked, item.user_id]);
      }
      if (currRender === unassignedLabScr) {
        setChecked([...checked, item._id]);
      }
    } else {
      if (currRender === unassignedGopalanScr) {
        newCheck = checked.filter((ele) => ele !== item.user_id);
        setChecked(newCheck);
      }
      if (currRender === unassignedLabScr) {
        newCheck = checked.filter((ele) => ele !== item._id);
        setChecked(newCheck);
      }
    }

    if (
      (currRender === unassignedGopalanScr &&
        newCheck &&
        newCheck.length === currVet.ai_workers.length) ||
      (currRender === unassignedLabScr && newCheck && newCheck.length === currVet.labs.length)
    ) {
      setBtnDisabled(true);
      return;
    }

    setBtnDisabled(false);
  };

  const tableUnassignedData = (item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10429'),
          values: (
            <UICheckBox
              checked={checked.includes(
                currRender === unassignedGopalanScr ? item.user_id : item._id
              )}
              onChange={(e) => handleChecked(e, item)}
              disabled={isReadOnly}
              sx={styles.checkIcon}
            />
          )
        },
        {
          title: getTitles('T-10438'),
          values: currRender === unassignedLabScr ? item.laboratory_id : item.employee_id
        },
        {
          title: currRender === unassignedLabScr ? getTitles('T-11073') : getTitles('T-10081'),
          values: (
            <BasicProfile
              title={currRender === unassignedLabScr ? item.laboratory_name : item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title: currRender === unassignedLabScr ? getTitles('T-11169') : getTitles('T-10440'),
          columnWidth: `calc(100%/4)`,
          values: currRender === unassignedLabScr ? item.primary_mobile_number : item.phone_number
        }
      ];
    } else {
      return {
        title: (
          <Box sx={styles.removeCont}>
            <BasicProfile
              title={currRender === unassignedLabScr ? item.laboratory_name : item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
              containerStyle={styles.profileCont}
            />

            <UICheckBox
              checked={checked.includes(
                currRender === unassignedGopalanScr ? item.user_id : item._id
              )}
              onChange={(e) => handleChecked(e, item)}
              sx={styles.checkIcon}
              disabled={isReadOnly}
            />
          </Box>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: currRender === unassignedLabScr ? item.laboratory_id : item.employee_id
          },
          {
            subTitle: currRender === unassignedLabScr ? getTitles('T-11169') : getTitles('T-10440'),
            action: currRender === unassignedLabScr ? item.primary_mobile_number : item.phone_number
          }
        ]
      };
    }
  };

  const tableAssignedData = (item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          columnWidth: '15%',
          values: currRender === assignedLabScr ? item.laboratory_id : item.employee_id
        },
        {
          title: currRender === assignedLabScr ? getTitles('T-11073') : getTitles('T-10081'),
          values: (
            <BasicProfile
              title={currRender === assignedLabScr ? item.laboratory_name : item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title: currRender === assignedLabScr ? getTitles('T-11169') : getTitles('T-10440'),
          values: currRender === assignedLabScr ? item.primary_mobile_number : item.phone_number
        },
        {
          title: getTitles('T-10481'),
          columnWidth: '12%',
          values: (
            <img
              src={closeIcon}
              style={styles.assign(isReadOnly)}
              onClick={() => !isReadOnly && handleRemoveAssignedItem(item)}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Box sx={styles.removeCont}>
            <BasicProfile
              title={currRender === assignedLabScr ? item.laboratory_name : item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
              containerStyle={styles.profileCont}
            />
            <img
              src={closeIcon}
              style={styles.assign(isReadOnly)}
              onClick={() => !isReadOnly && handleRemoveAssignedItem(item)}
            />
          </Box>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: currRender === assignedLabScr ? item.laboratory_id : item.employee_id
          },
          {
            subTitle: currRender === assignedLabScr ? getTitles('T-11169') : getTitles('T-10440'),
            action: currRender === assignedLabScr ? item.primary_mobile_number : item.phone_number
          }
        ]
      };
    }
  };

  const tableData = vets.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          values: item.employee_id,
          columnWidth: '15%'
        },
        {
          title: getTitles('T-10081'),
          values: (
            <BasicProfile
              title={item.name}
              url={item.photo_url ? item.photo_url : null}
              textVariant="D22"
            />
          )
        },
        {
          title: getTitles('T-10440'),
          values: item.phone_number
        },
        {
          title: getTitles('T-10103'),
          columnWidth: '10%',
          values: (
            <ActionButton
              status={item.is_active}
              onStatusChange={() => handleStatus(item)}
              isReadOnly={isReadOnly}
              alterTitle={defaultValues.confirmationModel.changeVetStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeVetStatus.subtitles}
            />
          )
        },
        {
          title: getTitles('T-10480'),
          columnWidth: md ? '18%' : '16%',

          values: (
            <img
              src={assignIcon}
              style={styles.assignViewScr(!item.is_active)}
              onClick={() => item.is_active && handleAssignGopalanClick(item)}
            />
          )
        },
        {
          title: getTitles('T-11168'),
          columnWidth: md ? '18%' : '15%',

          values: (
            <img
              src={assignIcon}
              style={styles.assignViewScr(!item.is_active)}
              onClick={() => item.is_active && handleAssignLabClick(item)}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <BasicProfile
            title={item.name}
            url={item.photo_url ? item.photo_url : null}
            textVariant="D22"
          />
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: item.employee_id
          },
          {
            subTitle: getTitles('T-10440'),
            action: item.phone_number
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item)}
                isReadOnly={isReadOnly}
                alterTitle={defaultValues.confirmationModel.changeVetStatus.title(item.is_active)}
                alterSubTitle={defaultValues.confirmationModel.changeVetStatus.subtitles}
              />
            )
          },
          {
            subTitle: getTitles('T-10480'),
            action: (
              <img
                src={assignIcon}
                style={styles.assignViewScr(!item.is_active)}
                onClick={() => item.is_active && handleAssignGopalanClick(item)}
              />
            )
          },
          {
            subTitle: getTitles('T-11168'),
            action: (
              <img
                src={assignIcon}
                style={styles.assignViewScr(!item.is_active)}
                onClick={() => item.is_active && handleAssignLabClick(item)}
              />
            )
          }
        ]
      };
    }
  });

  const tableAssignedAiData =
    currVet &&
    currVet.ai_workers &&
    currVet.ai_workers.length > 0 &&
    currVet.ai_workers.map((item) => tableAssignedData(item));

  const tableAssignedLabsData =
    currVet &&
    currVet.labs &&
    currVet.labs.length > 0 &&
    currVet.labs.map((item) => tableAssignedData(item));

  const tableUnassignedAiData =
    unassignedAi &&
    unassignedAi.length > 0 &&
    unassignedAi.map((item) => tableUnassignedData(item));

  const tableUnassignedLabData =
    unassignedLabs &&
    unassignedLabs.length > 0 &&
    unassignedLabs.map((item) => tableUnassignedData(item));

  return (
    <Screen
      showSideBar={true}
      currentPage={getTitles('T-10121')}
      isLoading={isLoading || isAiLoading}>
      <Box sx={styles.container}>
        <UILayout
          isMultiSelectFilterOption={true}
          currPageName={getTitles('T-10121')}
          handleBackButton={
            [assignedGopalanScr, unassignedGopalanScr, assignedLabScr, unassignedLabScr].includes(
              currRender
            )
              ? () => dispatch(updateVetCurrRender(getPrevPageNo()))
              : null
          }
          handleSearch={handleSearch}
          clearSearch={filter}
          tableTitle={
            currRender === unassignedGopalanScr
              ? getTitles('T-10480')
              : currRender === assignedGopalanScr
                ? getTitles('T-10482')
                : currRender === unassignedLabScr
                  ? getTitles('T-11168')
                  : currRender === assignedLabScr && getTitles('T-11189')
          }
          showNoData={
            currRender === viewVetScr
              ? !vets.length > 0
              : currRender === unassignedGopalanScr
                ? unassignedAi && !unassignedAi.length > 0
                : currRender === unassignedLabScr
                  ? unassignedLabs && !unassignedLabs.length > 0
                  : currRender === assignedLabScr
                    ? currVet && !currVet.labs.length > 0
                    : currVet && currVet.ai_workers && !currVet.ai_workers.length > 0
          }
          currPage={
            currRender === viewVetScr
              ? currentPage
              : currRender === unassignedGopalanScr
                ? unassignCurrentPage
                : currRender === unassignedLabScr && unAssLabsCurrPage
          }
          pageCount={
            currRender === viewVetScr
              ? totalPages
              : currRender === unassignedGopalanScr
                ? unassignTotalPages
                : currRender === unassignedLabScr && unAssLabsTotalPage
          }
          handlePagination={handlePagination}
          tableItems={
            currRender === viewVetScr
              ? tableData
              : currRender === unassignedGopalanScr
                ? tableUnassignedAiData
                : currRender === unassignedLabScr
                  ? tableUnassignedLabData
                  : currRender === assignedLabScr
                    ? tableAssignedLabsData
                    : tableAssignedAiData
          }
          showPagination={
            currRender === viewVetScr
              ? vets.length > 0 && totalPages > 1
              : currRender === unassignedGopalanScr
                ? unassignedAi.length > 0 && unassignTotalPages > 1
                : false
          }
          searchBarPlaceHolder={
            [assignedLabScr, unassignedLabScr].includes(currRender)
              ? getTitles('T-11198')
              : getTitles('T-10308')
          }
          showDataTable={!defaultValues.isResponsive}
          message={
            currRender === viewVetScr
              ? getErrorMessage('E-10060')
              : [assignedLabScr, unassignedLabScr].includes(currRender)
                ? getErrorMessage('E-10165')
                : getErrorMessage('E-10063')
          }
          isReadOnly={isReadOnly}
          handleRefresh={currRender === viewVetScr && handleRefresh}
          handleSort={currRender === viewVetScr && handleSort}
          handleFilter={currRender === viewVetScr && handleFilter}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          handleAddButton={
            [assignedGopalanScr, assignedLabScr].includes(currRender) && handleAssignNewClick
          }
          addBtnTitle={
            [assignedGopalanScr, assignedLabScr].includes(currRender) &&
            !defaultValues.isResponsive &&
            getTitles('T-10483')
          }
          handleSecondaryAdd={
            ((currRender === unassignedGopalanScr && unassignedAi && unassignedAi.length > 0) ||
              (currRender === unassignedLabScr && unassignedLabs && unassignedLabs.length > 0)) &&
            handleSubmitAssign
          }
          secondaryAddDisabled={btnDisabled || !checked.length > 0}
          collapsable={currRender === viewVetScr}
          addBtnStyle={defaultValues.isResponsive && styles.addBtnStyle}
        />
      </Box>
    </Screen>
  );
};
