import { put, takeLatest } from 'redux-saga/effects';
import {
  addCartItemsFailure,
  addCartItemsRequest,
  addCartItemsSuccess,
  getActivityProductsFailure,
  getActivityProductsRequest,
  getActivityProductsSuccess,
  getAdverticementFailure,
  getAdverticementRequest,
  getAdverticementSuccess,
  getBannersFailure,
  getBannersRequest,
  getBannersSuccess,
  getBestDealsFailure,
  getBestDealsRequest,
  getBestDealsSuccess,
  getBestSellerFailure,
  getBestSellerRequest,
  getBestSellerSuccess,
  getCartItemsFailure,
  getCartItemsRequest,
  getCartItemsSuccess,
  getEcomProductDetailsFailure,
  getEcomProductDetailsRequest,
  getEcomProductDetailsSuccess,
  getFeaturedProductsFailure,
  getFeaturedProductsRequest,
  getFeaturedProductsSuccess,
  getHotFailure,
  getHotRequest,
  getHotSuccess,
  getMyOrdersFailure,
  getMyOrdersRequest,
  getMyOrdersSuccess,
  getNewArrivalFailure,
  getNewArrivalRequest,
  getNewArrivalSuccess,
  getRecentProductsFailure,
  getRecentProductsRequest,
  getRecentProductsSuccess,
  updateCartItemsFailure,
  updateCartItemsRequest,
  updateCartItemsSuccess
} from '../slices';
import { KEYS } from '../../../constants';
import {
  addEcommerceCartItemsApi,
  getBannersApi,
  getEcommerceCartItemsApi,
  getEcommerceProductDetailsApi,
  getEcommerceProductsApi,
  getEcomMyOrdersAPI,
  updateEcommerceCartItemsApi
} from '../../api';

function* getBanners(action) {
  try {
    const response = yield getBannersApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getBannersSuccess(response.data));
    } else {
      yield put(getBannersFailure(response.data));
    }
  } catch (error) {
    yield put(getBannersFailure(error.response.data));
  }
}

function* getFeaturedProducts(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFeaturedProductsSuccess(response.data));
    } else {
      yield put(getFeaturedProductsFailure(response.data));
    }
  } catch (error) {
    yield put(getFeaturedProductsFailure(error.response.data));
  }
}

function* getRecentProducts(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getRecentProductsSuccess(response.data));
    } else {
      yield put(getRecentProductsFailure(response.data));
    }
  } catch (error) {
    yield put(getRecentProductsFailure(error.response.data));
  }
}

function* getAdverticeMentProducts(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getAdverticementSuccess(response.data));
    } else {
      yield put(getAdverticementFailure(response.data));
    }
  } catch (error) {
    yield put(getAdverticementFailure(error.response.data));
  }
}

function* getBestDeals(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getBestDealsSuccess(response.data));
    } else {
      yield put(getBestDealsFailure(response.data));
    }
  } catch (error) {
    yield put(getBestDealsFailure(error.response.data));
  }
}
function* getHot(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getHotSuccess(response.data));
    } else {
      yield put(getHotFailure(response.data));
    }
  } catch (error) {
    yield put(getHotFailure(error.response.data));
  }
}

function* getBestSeller(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getBestSellerSuccess(response.data));
    } else {
      yield put(getBestSellerFailure(response.data));
    }
  } catch (error) {
    yield put(getBestSellerFailure(error.response.data));
  }
}

function* getNewArrival(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getNewArrivalSuccess(response.data));
    } else {
      yield put(getNewArrivalFailure(response.data));
    }
  } catch (error) {
    yield put(getNewArrivalFailure(error.response.data));
  }
}

function* getActivityProducts(action) {
  try {
    const response = yield getEcommerceProductsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getActivityProductsSuccess(response.data));
    } else {
      yield put(getActivityProductsFailure(response.data));
    }
  } catch (error) {
    yield put(getActivityProductsFailure(error.response.data));
  }
}

function* getEcomProductsDetails(action) {
  try {
    const response = yield getEcommerceProductDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getEcomProductDetailsSuccess(response.data));
    } else {
      yield put(getEcomProductDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getEcomProductDetailsFailure(error.response.data));
  }
}

function* getEcomCartItems(action) {
  try {
    const response = yield getEcommerceCartItemsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCartItemsSuccess(response.data));
    } else {
      yield put(getCartItemsFailure(response.data));
    }
  } catch (error) {
    yield put(getCartItemsFailure(error.response.data));
  }
}

function* addEcomCartItems(action) {
  try {
    const response = yield addEcommerceCartItemsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addCartItemsSuccess(response.data));
    } else {
      yield put(addCartItemsFailure(response.data));
    }
  } catch (error) {
    yield put(addCartItemsFailure(error.response.data));
  }
}

function* updateEcomCartItems(action) {
  try {
    const response = yield updateEcommerceCartItemsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateCartItemsSuccess(response.data));
    } else {
      yield put(updateCartItemsFailure(response.data));
    }
  } catch (error) {
    yield put(updateCartItemsFailure(error.response.data));
  }
}

function* getEcomMyOrders(action) {
  try {
    const response = yield getEcomMyOrdersAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getMyOrdersSuccess(response.data));
    } else {
      yield put(getMyOrdersFailure(response.data));
    }
  } catch (error) {
    yield put(getMyOrdersFailure(error.response.data));
  }
}

export function* eCommerceSaga() {
  yield takeLatest(getBannersRequest.type, getBanners);
  yield takeLatest(getFeaturedProductsRequest.type, getFeaturedProducts);
  yield takeLatest(getRecentProductsRequest.type, getRecentProducts);
  yield takeLatest(getAdverticementRequest.type, getAdverticeMentProducts);
  yield takeLatest(getBestDealsRequest.type, getBestDeals);
  yield takeLatest(getBestSellerRequest.type, getBestSeller);
  yield takeLatest(getHotRequest.type, getHot);
  yield takeLatest(getNewArrivalRequest.type, getNewArrival);
  yield takeLatest(getActivityProductsRequest.type, getActivityProducts);
  yield takeLatest(getEcomProductDetailsRequest.type, getEcomProductsDetails);
  yield takeLatest(getCartItemsRequest.type, getEcomCartItems);
  yield takeLatest(addCartItemsRequest.type, addEcomCartItems);
  yield takeLatest(updateCartItemsRequest.type, updateEcomCartItems);
  yield takeLatest(getMyOrdersRequest.type, getEcomMyOrders);
}
