import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, labDetailsSchema } from '../../../../utils';
import {
  Text,
  UIButton,
  UIChipButton,
  UIMultipleSelectCheckmarks,
  UIPhoneInputTitle,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLaboratoryRequest,
  setLaboratoryCurrRender,
  updateLaboratoryRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { getLaboratoryCurrPage, getScroll } from '../../../../services/redux/selectors';
import { FormTitleCard } from '../../../common';
import locationIcon from '../../../../assets/icons/share_location.png';
import { toast } from 'react-toastify';
import { Address } from '../../../common/address';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },

  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "country state"
      "district taluk"
      "village post"
        `
      : `
        "country state district"
        "taluk village post"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  },
  locationContainer: {
    my: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  commonColumn: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    rowGap: '10px',
    columnGap: '20px'
  }
};

export const LaboratoryDetailsForm = ({
  laboratoryData,
  onNext,
  labTests,
  laboratoryId,
  laboratorySteps,
  labId
}) => {
  const dispatch = useDispatch();

  const currRender = useSelector(getLaboratoryCurrPage);
  const scroll = useSelector(getScroll);

  const [btnDisabled, setBtndisabled] = useState(true);

  const { add, edit, view } = defaultValues.actionType;

  const { values, errors, touched, setValues, handleBlur, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        laboratory_name: '',
        primary_mobile_number: '',
        secondary_mobile_number: null,
        email: '',
        address: {
          address_line_one: '',
          address_line_two: null,
          country: '',
          state_id: { _id: '' },
          district_id: { _id: '' },
          taluka_id: { _id: '' },
          village_id: { _id: '' },
          pincode: ''
        },
        geographic_coordinates: {
          longitude: '',
          latitude: '',
          altitude: ''
        },
        available_tests: []
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: labDetailsSchema
    });

  const getFullObject = (arr1, arr2) => {
    if (arr1 && arr1.length > 0) {
      return arr2
        .filter((item) => arr1.includes(item.value))
        .map((ele) => ({ value: ele.value, title: ele.title }));
    }
    return [];
  };

  useEffect(() => {
    if (laboratoryData) {
      let newValues = {
        ...laboratoryData,
        available_tests: getFullObject(
          laboratoryData.available_tests,
          labTests.map((item) => ({ value: item._id, title: item.name }))
        )
      };

      if (laboratoryData.address) {
        newValues = {
          ...newValues,
          address: {
            ...laboratoryData.address,
            country: laboratoryData.address.country || '',
            state_id: laboratoryData.address.state_id || '',
            district_id: laboratoryData.address.district_id || '',
            taluka_id: laboratoryData.address.taluka_id || '',
            village_id: laboratoryData.address.village_id || '',
            pincode: laboratoryData.address.pincode
          }
        };
      }

      setValues(newValues);
    }
  }, [laboratoryData, setValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      registration_step: defaultValues.labRegisterSteps.labDetails,
      _id: laboratoryId,
      lab_details: {
        ...values,
        address: {
          ...values.address,
          state_id: values.address.state_id?._id,
          district_id: values.address.district_id?._id,
          taluka_id: values.address.taluka_id?._id,
          village_id: values.address.village_id?._id
        },
        available_tests:
          (values.available_tests &&
            values.available_tests.length > 0 &&
            values.available_tests.map((item) => item.value)) ||
          []
      }
    };
    if (!values.email) payload.lab_details && delete payload.lab_details.email;
    if (currRender === add) {
      if (laboratorySteps === 1) {
        dispatch(updateLaboratoryRequest(payload));
      } else {
        dispatch(addLaboratoryRequest(payload));
      }
    }
    currRender === edit && dispatch(updateLaboratoryRequest(payload));

    onNext();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, altitude } = position.coords;
          setValues({
            ...values,
            geographic_coordinates: {
              longitude: longitude,
              latitude: latitude,
              altitude: altitude || 0
            }
          });
          setBtndisabled(false);
        },
        (error) => {
          toast.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };

  const handleBackButton = () => {
    dispatch(setLaboratoryCurrRender(1));
    dispatch(updateScrollToTop(!scroll));
  };

  const handleDelete = (index) => {
    let arr = [...values.available_tests];
    const arr2 = arr.filter((ele, idx) => idx !== index);
    setFieldValue('available_tests', arr2 && arr2.length > 0 ? arr2 : []);
    setBtndisabled(false);
  };

  return (
    <Box sx={styles.container}>
      <FormTitleCard title={getTitles('T-11070')}>
        {[edit, view].includes(currRender) && (
          <UITextInputWithTitle
            title={getTitles('T-10751')}
            value={labId}
            width={styles.width}
            disabled={true}
          />
        )}
        <UITextInputWithTitle
          name="laboratory_name"
          title={getTitles('T-11073')}
          placeHolder={getTitles('T-11074')}
          error={touched.laboratory_name && errors.laboratory_name}
          value={values.laboratory_name}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.phone}
          required
          disabled={currRender === view}
        />

        <UIPhoneInputTitle
          title={getTitles('T-11075')}
          phoneValue={values.primary_mobile_number}
          onChange={(val) => {
            setFieldValue('primary_mobile_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          required={true}
          containerStyles={styles.phone}
          disabled={currRender === view}
        />
        <UIPhoneInputTitle
          title={getTitles('T-11076')}
          phoneValue={values.secondary_mobile_number}
          onChange={(val) => setFieldValue('secondary_mobile_number', val)}
          handleError={(isError) => setBtndisabled(isError)}
          containerStyles={styles.phone}
          disabled={currRender === view}
        />

        <UITextInputWithTitle
          name="email"
          title={getTitles('T-10043')}
          placeHolder={getTitles('T-10049')}
          error={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.phone}
          disabled={currRender === view}
        />
      </FormTitleCard>

      <Address
        title={getTitles('T-11077')}
        objects={values.address}
        actionType={currRender}
        isCountryRequired={true}
        isDistrictRequired={true}
        isStateRequired={true}
        isPostCodeRequired={true}
        isTalukaRequired={true}
        addressOneRequired={true}
        onAddressOneChange={(e) => {
          handleChange({ target: { name: 'address.address_line_one', value: e.target.value } });
        }}
        onAddressOneBlurred={handleBlur('address.address_line_one')}
        addressOneErrorCode={touched.address?.address_line_one && errors.address?.address_line_one}
        onAddressTwoChange={(e) => {
          handleChange({ target: { name: 'address.address_line_two', value: e.target.value } });
        }}
        onPostCodeChange={(e) => {
          handleChange({ target: { name: 'address.pincode', value: e.target.value } });
        }}
        onPostCodeBlurred={handleBlur('address.pincode')}
        postCodeError={touched.address?.pincode && errors.address?.pincode}
        onCountrySelected={(e) =>
          handleChange({ target: { name: 'address.country', value: e.target.value } })
        }
        onCountryBlurred={handleBlur('address.country')}
        countryCodeError={touched.address?.country && errors.address?.country}
        onStateSelected={(val) =>
          handleChange({ target: { name: 'address.state_id', value: val } })
        }
        onStateBlurred={handleBlur('address.state_id._id')}
        stateCodeError={touched.address?.state_id?._id && errors.address?.state_id?._id}
        onDistrictSelected={(val) =>
          handleChange({ target: { name: 'address.district_id', value: val } })
        }
        onDistrictBlurred={handleBlur('address.district_id._id')}
        districtCodeError={touched.address?.district_id?._id && errors.address?.district_id?._id}
        onTalukaSelected={(val) =>
          handleChange({ target: { name: 'address.taluka_id', value: val } })
        }
        onTalukaBlurred={handleBlur('address.taluka_id._id')}
        talukaCodeError={touched.address?.taluka_id?._id && errors.address?.taluka_id?._id}
        onVillageSelected={(val) =>
          handleChange({ target: { name: 'address.village_id', value: val } })
        }
      />

      <Box sx={styles.locationContainer}>
        <Text variant="BoldH22"> {getTitles('T-10505')}</Text>
        {currRender !== view && (
          <UIButton
            startIcon={
              <img src={locationIcon} alt="icon" style={{ width: res ? '15px' : '20px' }} />
            }
            title={getTitles('T-10732')}
            style={styles.btn}
            onClick={handleGetLocation}
            disabled={currRender === view}
          />
        )}
      </Box>

      <Box sx={styles.commonColumn}>
        <UITextInputWithTitle
          title={getTitles('T-10496')}
          placeHolder={getTitles('T-11207')}
          name="geographic_coordinates.longitude"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.geographic_coordinates && values.geographic_coordinates.longitude}
          error={
            touched.geographic_coordinates &&
            touched.geographic_coordinates.longitude &&
            errors.geographic_coordinates &&
            errors.geographic_coordinates.longitude
          }
          disabled={currRender === view}
          allowDecimal={true}
          required={true}
        />
        <UITextInputWithTitle
          name="geographic_coordinates.latitude"
          value={values.geographic_coordinates && values.geographic_coordinates.latitude}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={currRender === view}
          error={
            touched.geographic_coordinates &&
            touched.geographic_coordinates.latitude &&
            errors.geographic_coordinates &&
            errors.geographic_coordinates.latitude
          }
          title={getTitles('T-10497')}
          placeHolder={getTitles('T-11208')}
          allowDecimal={true}
          required={true}
        />
        <UITextInputWithTitle
          name="geographic_coordinates.altitude"
          onChange={handleChange}
          disabled={currRender === view}
          onBlur={handleBlur}
          value={values.geographic_coordinates && values.geographic_coordinates.altitude}
          error={
            touched.geographic_coordinates &&
            touched.geographic_coordinates.altitude &&
            errors.geographic_coordinates &&
            errors.geographic_coordinates.altitude
          }
          allowDecimal={true}
          title={getTitles('T-10498')}
          placeHolder={getTitles('T-11206')}
          required={true}
        />
      </Box>
      <UIMultipleSelectCheckmarks
        name={'available_tests'}
        onBlur={handleBlur}
        options={
          labTests &&
          labTests.length > 0 &&
          labTests.map((item) => ({ value: item._id, title: item.name }))
        }
        title={getTitles('T-11078')}
        onChange={(val) => {
          setFieldValue('available_tests', val);
          setBtndisabled(false);
        }}
        preSelectedValue={values.available_tests}
        placeholder={getTitles('T-10350')}
        containerStyles={{ width: res ? '100%' : 'calc((100% + 5px)/3 * 2)' }}
        disabled={currRender === view}
        showTitles={false}
        showSelect={currRender !== view}
      />

      {values.available_tests &&
        values.available_tests.length > 0 &&
        values.available_tests.map((item, idx) => (
          <UIChipButton
            key={idx}
            title={item.title}
            onDelete={() => handleDelete(idx)}
            showDelete={currRender !== view}
          />
        ))}

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10045')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={currRender !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
