import { Box } from '@mui/material';
import { color } from '../../theme';
import { defaultValues } from '../../constants';

export const UIContainer = ({ styles, children, Height }) => {
  const style = {
    container: {
      width: '100%',
      borderRadius: '5px',
      backgroundColor: color.primaryBackground,
      height: Height || 'calc(85vh - 20px)',
      overflow: 'auto',
      marginTop: defaultValues.isResponsive ? '10px' : '20px',
      ...styles
    }
  };
  return <Box sx={style.container}>{children}</Box>;
};
