import { Box } from '@mui/material';
import {
  DatePickers,
  Text,
  UIAutocompleted,
  UIButton,
  UIMultipleSelectCheckmarks,
  UIRadioButton,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle,
  UITimePicker,
  UITitleWithContent
} from '../../../../ui-kits';
import { TaskInfoCard } from '../../../common';
import { getTitles } from '../../../../utils';
import { defaultValues } from '../../../../constants';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../services/redux/selectors';
import { useCallback, useEffect } from 'react';

export const CallCenterCommonField = ({
  showModel,
  info,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  handleRemove,
  handleAddAnother,
  setFieldTouched,
  setBtndisabled,
  setFieldValue,
  taskTypes,
  searchList,
  isCommon,
  farmerList,
  farmerErrorMsg,
  cattleList
}) => {
  let timer;
  const { add, edit, view } = defaultValues.actionType;

  const userData = useSelector(getUserData);
  useEffect(() => {
    const list = getRoleList();
    if (list?.length === 1) {
      setFieldValue('role', list[0].value);
    }
  }, []);

  const isRemindDisable = () => {
    const obj = values.remind_on[values.remind_on.length - 1];
    if (obj.remind_at) return false;
    if (obj.days) return false;
    if (obj.timing) return false;
    return true;
  };

  const getRoleList = useCallback(() => {
    if (userData.employee_permissions?.length > 0) {
      const permIds = userData.employee_permissions.map((el) => el.permission_id);
      return defaultValues.employeePermissions.filter((el) => permIds.includes(el.value));
    }
    return defaultValues.employeePermissions;
  }, [userData]);

  return (
    <Box sx={styles.container}>
      {[edit, view].includes(showModel?.mode) && info?.length > 0 && (
        <Box sx={styles.detailsCont}>
          {info.map((item, idx) => (
            <TaskInfoCard
              key={idx}
              containerStyles={styles.cardCont}
              details={item?.details}
              icon={item?.icon}
              heading={item?.heading}
            />
          ))}
        </Box>
      )}
      <Box sx={styles.nameCont}>
        {[edit, view].includes(showModel?.mode) && (
          <UITextInputWithTitle
            name="task_id"
            value={values.task_id}
            title={getTitles('T-11278')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            disabled={showModel?.mode === view}
            inputStyle={styles.input}
          />
        )}
        <UITextInputWithTitle
          name="task_name"
          value={values.task_name}
          title={getTitles('T-11264')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-11384')}
          error={touched.task_name && errors.task_name}
          width={styles.width}
          disabled={showModel?.mode === view}
          inputStyle={styles.input}
          required={true}
        />

        {showModel?.mode === add && (
          <UISelect
            name="task_type"
            value={values.task_type}
            title={getTitles('T-11248')}
            options={taskTypes.map((item) => ({ value: item._id, title: item.name }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
            error={touched.task_type && errors.task_type}
            required={true}
          />
        )}
        <UISelect
          name="priority"
          value={values.priority}
          title={getTitles('T-11266')}
          options={defaultValues.repeatationOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
        />
      </Box>

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="description"
          value={values.description}
          title={getTitles('T-11299')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10096')}
          width={styles.longWidth}
          disabled={showModel?.mode === view}
          rows={2}
        />
        <DatePickers
          dateValue={showModel?.mode === add ? values.due_date : values.created_at}
          onDateChange={(val) => {
            handleChange({
              target: { value: val, name: showModel?.mode === add ? 'due_date' : 'created_at' }
            });
          }}
          title={showModel?.mode === add ? getTitles('T-11251') : getTitles('T-11282')}
          width={styles.width}
          disabled={showModel?.mode === edit}
          disablePast
          containerStyles={styles.dateCont}
          inputStyle={styles.dateInput}
          error={showModel?.mode === add && touched.due_date && errors.due_date}
          onOpen={() => setFieldTouched('due_date', true)}
          required={showModel?.mode === add}
        />
      </Box>

      {isCommon && (
        <>
          {userData.user_role === defaultValues.userRole.employee && (
            <Box sx={styles.nameCont}>
              {
                <UISelect
                  name="role"
                  value={values.role}
                  title={getTitles('T-10052').slice(0, -1)}
                  options={getRoleList()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={getTitles('T-10350')}
                  width={styles.width}
                  inputStyle={styles.input}
                  disabled={showModel?.mode === view}
                />
              }
              <UIAutocompleted
                name="farmer_id"
                title={getTitles('T-11162')}
                textInputStyles={styles.autoInput}
                containerStyle={styles.autoCont}
                titleStyle={{ mb: '4px' }}
                options={farmerList}
                optionLabel={(item) => item.full_name || ''}
                onTyping={(val) => {
                  clearTimeout(timer);
                  timer = setTimeout(() => {
                    if (val.length > 2) {
                      searchList(val);
                    }
                  }, 500);
                }}
                onBlur={handleBlur}
                placeHolder={getTitles('T-10077')}
                selectedValue={(val) => {
                  setFieldValue('cattle_id', null);
                  setFieldValue('vet', null);
                  setFieldValue('gopalan', null);
                  setFieldValue('farmer_id', val);
                  setBtndisabled(false);
                }}
                preSelectedValue={values.farmer_id}
                width={styles.width}
                disabled={showModel?.mode === view}
                error={farmerErrorMsg}
              />
              <UIAutocompleted
                name="cattle_id"
                title={getTitles('T-10173')}
                textInputStyles={styles.autoInput}
                containerStyle={styles.autoCont}
                titleStyle={{ mb: '4px' }}
                options={cattleList}
                optionLabel={(item) => item.cattle_name || ''}
                onTyping={(val) => {
                  clearTimeout(timer);
                  timer = setTimeout(() => {
                    if (val.length > 2) {
                      searchList(val);
                    }
                  }, 500);
                }}
                onBlur={handleBlur}
                placeHolder={getTitles('T-10077')}
                selectedValue={(val) => {
                  setFieldValue('cattle_id', val);
                  setFieldValue('vet', val.vet);
                  setFieldValue('gopalan', val.gopalan);
                  setBtndisabled(false);
                }}
                preSelectedValue={values.cattle_id}
                width={styles.width}
                disabled={showModel?.mode === view}
              />
            </Box>
          )}

          {(([add].includes(showModel.mode) && values.role === defaultValues.employeeRol.vet) ||
            values.gopalan?._id) && (
            <Box sx={styles.nameCont}>
              <UITextInputWithTitle
                value={values.gopalan?.full_name}
                title={getTitles('T-11587')}
                width={styles.width}
                readOnly={true}
                inputStyle={styles.input}
              />
              <UITextInputWithTitle
                value={values.gopalan?.phone_number}
                title={getTitles('T-11588')}
                width={styles.width}
                readOnly={true}
                inputStyle={styles.input}
              />
            </Box>
          )}

          {(([add].includes(showModel.mode) && values.role === defaultValues.employeeRol.gopalan) ||
            values.vet?._id) && (
            <Box sx={styles.nameCont}>
              <UITextInputWithTitle
                value={values.vet?.full_name}
                title={getTitles('T-11589')}
                width={styles.width}
                readOnly={true}
                inputStyle={styles.input}
              />
              <UITextInputWithTitle
                value={values.vet?.phone_number}
                title={getTitles('T-11590')}
                width={styles.width}
                readOnly={true}
                inputStyle={styles.input}
              />
            </Box>
          )}
        </>
      )}

      {[edit, view].includes(showModel?.mode) && (
        <Box sx={styles.nameCont}>
          <UISelect
            name="task_type"
            value={values.task_type}
            title={getTitles('T-11248')}
            options={taskTypes.map((item) => ({ value: item._id, title: item.name }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
            error={touched.task_type && errors.task_type}
            required={true}
          />
          <UISelect
            name="task_status"
            value={values.task_status}
            title={getTitles('T-11279')}
            options={defaultValues.taskStatusOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
          <UISelect
            name="call_center_status"
            value={values.call_center_status}
            title={getTitles('T-11249')}
            options={defaultValues.callCenterStatus}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            disabled={showModel?.mode === view}
          />
        </Box>
      )}

      <Box sx={styles.nameCont}>
        {[edit, view].includes(showModel?.mode) && (
          <DatePickers
            dateValue={values.due_date}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'due_date' } })}
            title={getTitles('T-11251')}
            width={styles.width}
            disabled={showModel?.mode === view}
            disablePast
            containerStyles={styles.dateCont}
            inputStyle={styles.dateInput}
            error={showModel?.mode === edit && touched.due_date && errors.due_date}
            required={true}
          />
        )}
        <UITitleWithContent
          title={getTitles('T-11267')}
          containerStyle={styles.radioCont}
          required={true}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.repeat === true}
              onClick={() => handleChange({ target: { name: 'repeat', value: true } })}
              disabled={showModel?.mode === view}
              titleStyle={styles.radioTitle}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.repeat === false}
              onClick={() => {
                handleChange({
                  target: { name: 'repeat', value: false }
                });
                setFieldValue('no_of_days', '');
              }}
              disabled={showModel?.mode === view}
              titleStyle={styles.radioTitle}
            />
          </Box>
        </UITitleWithContent>

        {values.repeat && (
          <UITextInputWithTitle
            name="no_of_days"
            value={values.no_of_days}
            title={getTitles('T-11268')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10360')}
            width={styles.width}
            disabled={showModel?.mode === view}
            inputStyle={styles.input}
            allowOnlyNumbers={true}
            error={touched.no_of_days && errors.no_of_days}
            required
          />
        )}
      </Box>

      <Box sx={styles.nameCont}>
        <UISelect
          name="assign_to"
          value={values.assign_to}
          title={getTitles('T-11265')}
          options={defaultValues.roleOptions.map((item) => ({ value: item.id, title: item.name }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10350')}
          width={styles.width}
          inputStyle={styles.input}
          disabled={showModel?.mode === view}
          required
        />

        <UIMultipleSelectCheckmarks
          name="notify_to"
          title={getTitles('T-11262')}
          preSelectedValue={values.notify_to}
          options={defaultValues.roleOptions.map((item) => ({ value: item.id, title: item.name }))}
          onChange={(val) => {
            setFieldValue('notify_to', val);
            setBtndisabled(false);
          }}
          placeholder={getTitles('T-10350')}
          selectStyle={styles.input}
          showTitles={true}
          containerStyles={{ width: styles.width }}
        />

        <UIMultipleSelectCheckmarks
          name="remind_to"
          title={getTitles('T-11271')}
          preSelectedValue={values.remind_to}
          options={defaultValues.roleOptions.map((item) => ({ value: item.id, title: item.name }))}
          onChange={(val) => {
            setFieldValue('remind_to', val);
            setBtndisabled(false);
          }}
          placeholder={getTitles('T-10350')}
          selectStyle={styles.input}
          showTitles={true}
          containerStyles={{ width: styles.width }}
        />
      </Box>

      <Box sx={styles.remaindSub}>
        <Text variant="D20" style={{ mt: 1 }}>
          {getTitles('T-11272')}
        </Text>
        {values.remind_on?.map((item, idx) => (
          <Box key={idx} sx={styles.remindCont}>
            <UISelect
              name={`remind_on[${idx}].remind_at`}
              value={item.remind_at}
              options={defaultValues.remindAtOptions}
              onChange={(e) => {
                if (!e.target.value) {
                  setFieldValue(`remind_on[${idx}].timing`, null);
                  setFieldValue(`remind_on[${idx}].days`, '');
                }
                handleChange(e);
              }}
              onBlur={handleBlur}
              placeholder={getTitles('T-11385')}
              width={styles.secWidth}
              inputStyle={styles.remindInput}
            />
            <UITextInputWithTitle
              name={`remind_on[${idx}].days`}
              value={item.days}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={getTitles('T-11320')}
              width={styles.secRemindWidth}
              disabled={
                !item.remind_at || item.remind_at === defaultValues.remindAtValues.sameAsDueDate
              }
              inputStyle={styles.input}
              allowOnlyNumbers={true}
            />

            <UITimePicker
              name={`remind_on[${idx}].timing`}
              value={item.timing}
              selectedTime={(val) => setFieldValue(`remind_on[${idx}].timing`, val)}
              onBlur={handleBlur}
              placeholder={getTitles('T-10350')}
              containerStyle={styles.timePicker}
              inputStyle={styles.timeInput}
              isLabel={null}
              disabled={!item.remind_at}
            />
            <UIButton
              title={'-'}
              style={styles.btn(idx === values.remind_on?.length - 1)}
              onClick={() => handleRemove(idx)}
            />
          </Box>
        ))}
        <UISaveAndAdd onAddAnother={handleAddAnother} disabled={isRemindDisable()} />
      </Box>
    </Box>
  );
};
