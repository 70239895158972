/* eslint-disable react/no-unescaped-entities */
import { Screen } from '../../ui-kits';

export const ShippingPolicy = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center'
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  return (
    <Screen showHeader={true}>
      <div style={styles.container}>
        <div style={styles.subContainer}>
          <h2>Shipping & Delivery Policy</h2>
          <p>Last updated: August 03, 2024</p>

          <p>
            This Shipping & Delivery Policy is part of our{' '}
            <a href="https://mygopalan.com/terms">Terms and Conditions</a> ("Terms") and should be
            therefore read alongside our main Terms.
          </p>
          <p>
            Please carefully review our Shipping & Delivery Policy when purchasing our products.
            This policy will apply to any order you place with us.
          </p>

          <h3>WHAT ARE MY SHIPPING & DELIVERY OPTIONS?</h3>

          <h4>In-Store Pickup</h4>
          <p>
            In-store pickup is available for all purchases. Pickups are available Mon - Fri 9 am - 6
            pm.
          </p>
          <p>
            We offer various shipping options. In some cases, a third-party supplier may be managing
            our inventory and will be responsible for shipping your products.
          </p>

          <h4>Free Shipping</h4>
          <p>We offer free standard shipping on Orders over 1000 rupees for Employees.</p>

          <h4>Shipping Fees</h4>
          <p>We also offer shipping at the following rates:</p>
          <ul>
            <li>Standard</li>
            <li>__________</li>
            <li>__________</li>
          </ul>
          <p>
            If you select a shipping option, we will follow up after you have placed the order with
            any additional shipping information.
          </p>
          <p>
            All times and dates given for delivery of the products are given in good faith but are
            estimates only.
          </p>

          <h3>DO YOU DELIVER INTERNATIONALLY?</h3>
          <p>We do not offer international shipping.</p>

          <h3>ARE THERE OTHER SHIPPING RESTRICTIONS?</h3>
          <p>Delivered within India only.</p>

          <h3>QUESTIONS ABOUT RETURNS?</h3>
          <p>
            If you have questions about returns, please review our{' '}
            <a href="https://mygopalan.com/returns">Return Policy</a>.
          </p>

          <h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
          <p>If you have any further questions or comments, you may contact us by:</p>
          <ul>
            <li>
              Email: <a href="mailto:help@mygopalan.com">help@mygopalan.com</a>
            </li>
            <li>
              Online contact form:{' '}
              <a href="https://mygopalan.com/contact">https://mygopalan.com/contact</a>
            </li>
          </ul>
        </div>
      </div>
    </Screen>
  );
};
