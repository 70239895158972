import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import {
  ActionButton,
  EditButton,
  PopupModal,
  Text,
  UIAutocompleted,
  UILayout,
  UISaveAndAdd,
  UITextInputWithTitle
} from '../../../ui-kits';
import {
  addVaccineRequest,
  getVaccineRequest,
  updateVaccineRequest,
  resetManageProcessMsg,
  getDiseaseRequest
} from '../../../services/redux/slices';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { getTitles, getUniqueAndSortedData, resetMessages, vaccineSchema } from '../../../utils';
import {
  getErrorMsg,
  getVannices,
  getSuccessMsg,
  getDiseasesList,
  getVaccineTotalPage,
  getVaccineCurPage,
  getNxtPage
} from '../../../services/redux/selectors/manageProccess';
import { ModalButtons } from '../../../components';

export const ManageVaccines = () => {
  const styles = {
    columnWidth: '33%',
    subClmWdth: '20%',
    overLap: { overflowWrap: 'anywhere' }
  };

  const dispatch = useDispatch();

  const vaccinesList = useSelector(getVannices);
  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const totalPages = useSelector(getVaccineTotalPage);
  const currentPage = useSelector(getVaccineCurPage);
  const diseasesList = useSelector(getDiseasesList);
  const nextDiseasePage = useSelector(getNxtPage);

  const [open, setOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [disease, setDisease] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [currPage, setCurrPage] = useState(null);
  const [diseaseName, setDiseaseName] = useState({ name: '' });

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: '',
        disease_id: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: vaccineSchema
    });

  useEffect(() => {
    dispatch(getVaccineRequest());
    dispatch(getDiseaseRequest());
  }, []);

  useEffect(() => {
    if (diseasesList) {
      setDisease((prevDisease) => {
        const combined = [...prevDisease, ...diseasesList];
        return getUniqueAndSortedData(combined);
      });
    }
  }, [diseasesList]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10041') {
        dispatch(getVaccineRequest({ page: currPage }));
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
    setDiseaseName({ name: '' });
  };

  useEffect(() => {
    if (vaccinesList) {
      setStates(vaccinesList);
    }
  }, [vaccinesList]);

  const handleSaveAndClose = () => {
    if (isEdit) {
      setCurrPage(currentPage);
      dispatch(updateVaccineRequest(values));
    } else {
      dispatch(addVaccineRequest(values));
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    dispatch(addVaccineRequest(values));
    setDiseaseName({ name: '' });
    resetForm();
  };

  const handleStatus = (id, status) => {
    setCurrPage(currentPage);
    dispatch(updateVaccineRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('_id', data._id);
    setFieldValue('name', data.name);
    setFieldValue('disease_id', data.disease.id);
    setDiseaseName({ name: data.disease.name });
    setOpen(true);
    setFormChanged(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleSearch = (data) => {
    dispatch(getVaccineRequest({ search_text: data }));
  };

  const handlePagination = (e, data) => {
    dispatch(getVaccineRequest({ page: data }));
  };

  const tableData = states.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10081'),
          columnWidth: styles.columnWidth,
          values: <Text variant="D20">{item.name}</Text>
        },
        {
          title: getTitles('T-10101'),
          columnWidth: styles.columnWidth,
          values: (
            <Text style={styles.overLap} variant="D20">
              {item.disease.name}
            </Text>
          )
        },
        {
          title: getTitles('T-10103'),
          columnWidth: styles.subClmWdth,
          values: (
            <ActionButton
              status={item.is_active}
              alterTitle={defaultValues.confirmationModel.changeVaccineStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
            />
          )
        },
        {
          title: getTitles('T-10084'),
          columnWidth: styles.subClmWdth,
          values: <EditButton status={item.is_active} onClick={() => handleEdit(item)} />
        }
      ];
    } else {
      return {
        title: <Text variant="D20">{item.name}</Text>,
        details: [
          {
            subTitle: getTitles('T-10101'),
            action: (
              <Text style={styles.overLap} variant="D20">
                {item.disease.name}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-10084'),
            action: <EditButton status={item.is_active} onClick={() => handleEdit(item)} />
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                alterTitle={defaultValues.confirmationModel.changeVaccineStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
              />
            )
          }
        ]
      };
    }
  });

  return (
    <Box>
      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10422')}
        handleAddButton={() => setOpen(true)}
        tableItems={tableData}
        currPage={currentPage}
        pageCount={totalPages}
        tableTitle={getTitles('T-10423')}
        handlePagination={handlePagination}
        showPagination={totalPages > 1}
        showDataTable={!defaultValues.isResponsive}
        showNoData={!states.length > 0}
        message={getErrorMessage('E-10071')}
      />

      <PopupModal
        title={isEdit ? getTitles('T-10425') : getTitles('T-10424')}
        onClose={handleModalClose}
        showModel={open}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={getTitles('T-10426')}
          placeHolder={getTitles('T-10427')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          allowOnlyAlphabets
          maxLength={30}
        />

        <UIAutocompleted
          name={'disease_id'}
          title={getTitles('T-10101')}
          placeHolder={getTitles('T-10350')}
          preSelectedValue={diseaseName && diseaseName}
          options={disease.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          variant="D22"
          onChange={(val) => {
            handleChange({ target: { value: val._id, name: 'disease_id' } });
          }}
          touched={touched.disease_id}
          onBlur={handleBlur}
          error={touched.disease_id && errors.disease_id}
          onScrollEnd={() => {
            if (nextDiseasePage) {
              dispatch(getDiseaseRequest({ page: nextDiseasePage }));
            }
          }}
        />
        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Box>
  );
};
