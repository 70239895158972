import { Box } from '@mui/material';
import { color } from '../../theme';

export const UIminusDeleteButton = ({ onClick }) => {
  return (
    <Box
      sx={{
        width: '28px',
        height: '28px',
        mt: 3,
        backgroundColor: color.primary,
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      }}
      onClick={onClick}>
      <p style={{ fontSize: '30px', color: color.tertiaryText }}>-</p>
    </Box>
  );
};
