import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../constants';
import { color } from '../../../theme';
import {
  getMasterLists,
  getStateNextPage,
  getStates
} from '../../../services/redux/selectors/manageProccess';
import {
  getLabIsInit,
  getLaboratoryActiveStep,
  getLaboratoryCompletedStep,
  getLaboratoryData
} from '../../../services/redux/selectors/laboratorySelector';
import { getDetailsPg } from '../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import {
  getMasterListWiseRequest,
  getStateRequest,
  setLabIsInit,
  setLaboratoryActiveStep,
  setLaboratoryCurrRender,
  updateDetailsPg
} from '../../../services/redux/slices';
import { getTitles } from '../../../utils';
import { Box } from '@mui/material';
import { UIBackButton } from '../../../ui-kits/back-button';
import { EditButton, Text, UIButton, UIStepper } from '../../../ui-kits';
import {
  LaboratoryDetailsForm,
  LaboratoryDocumentsForm,
  LaboratoryEmployeeDetailsForm
} from '../../../components/HOC';

const { isMedium, isResponsive } = defaultValues;

const styles = {
  container: {
    width: '100%',
    padding: isResponsive ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    mt: 2
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    position: 'relative'
  },
  backBtn: {
    position: 'absolute',
    left: isMedium ? -45 : -55
  },
  editIcon: {
    cursor: 'pointer',
    width: isResponsive ? '20px' : isMedium ? '23px' : '26px',
    height: isResponsive ? '20px' : isMedium ? '23px' : '26px'
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  }
};

export const AddEditLaboratory = ({ currRender, isReadOnly }) => {
  const dispatch = useDispatch();

  const nextPage = useSelector(getStateNextPage);
  const states = useSelector(getStates);
  const laboratoryData = useSelector(getLaboratoryData);
  const laboratorySteps = useSelector(getLaboratoryCompletedStep);
  const detailsPg = useSelector(getDetailsPg);
  const masterLists = useSelector(getMasterLists);
  const activeStep = useSelector(getLaboratoryActiveStep);
  const isInit = useSelector(getLabIsInit);

  const [stateList, setStateList] = useState([]);
  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    dispatch(getStateRequest({ page: 1 }));
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([defaultValues.mListIds.labTests])
      })
    );
  }, []);

  useEffect(() => {
    if (activeStep > 0 || (activeStep === 0 && isInit)) {
      dispatch(setLaboratoryActiveStep(activeStep));
      return;
    }
    if (laboratorySteps && laboratorySteps < 3) {
      dispatch(setLaboratoryActiveStep(laboratorySteps));
    } else {
      dispatch(setLaboratoryActiveStep(0));
    }
  }, [laboratorySteps]);

  useEffect(() => {
    let temp = [...stateList, ...states];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setStateList(uniqueStates);
  }, [states]);

  useEffect(() => {
    if (nextPage !== null) dispatch(getStateRequest({ page: nextPage }));
  }, [nextPage]);

  const titles = [getTitles('T-11070'), getTitles('T-11071'), getTitles('T-11072')];

  const steps = isResponsive ? ['', '', ''] : titles;

  const handleNextStep = (step = 1) => {
    dispatch(setLaboratoryActiveStep(activeStep + step));
  };

  const handlePrevStep = (step = 1) => {
    dispatch(setLaboratoryActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  const handleBackButton = () => {
    if (activeStep === 0 && currRender !== 1) {
      dispatch(setLaboratoryCurrRender(1));
      dispatch(setLabIsInit(false));
    } else {
      activeStep === 1 && dispatch(setLabIsInit(false));
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  const getMasterListData = (listId) => {
    if (masterLists && masterLists.length > 0) {
      const mData = masterLists.find((ele) => ele.master_id === listId);
      return mData ? mData.data : [];
    }
    return [];
  };

  return (
    <Box sx={styles.container}>
      {!isResponsive && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton style={styles.backBtn} onClick={handleBackButton} />
          <Text variant="boldH32Sec">
            {currRender === add
              ? getTitles('T-11114')
              : currRender === edit
                ? getTitles('T-11117')
                : getTitles('T-11116')}
          </Text>
          {currRender === view && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(dispatch(setLaboratoryCurrRender(edit)))}
            />
          )}
        </Box>
      )}

      <Box sx={{ mt: 3, mb: 3 }}>
        <UIStepper
          steps={steps}
          activeStep={activeStep}
          onStepperClick={(index) => {
            if (index <= laboratorySteps) {
              dispatch(setLaboratoryActiveStep(index));
            }
            dispatch(setLabIsInit(index === 0));
          }}
        />
      </Box>
      {activeStep === 0 && (
        <LaboratoryDetailsForm
          laboratoryData={laboratoryData && laboratoryData.lab_details}
          laboratoryId={laboratoryData && laboratoryData._id}
          labId={laboratoryData?.laboratory_id}
          onNext={() => handleNextStep(1)}
          labTests={getMasterListData(defaultValues.mListIds.labTests)}
          laboratorySteps={laboratorySteps}
          isReadOnly={isReadOnly}
        />
      )}
      {activeStep === 1 && (
        <LaboratoryEmployeeDetailsForm
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
          laboratoryData={
            laboratoryData &&
            Object.keys(laboratoryData.employee_details || {}).length > 0 &&
            laboratoryData.employee_details
          }
          laboratoryId={laboratoryData && laboratoryData._id}
          laboratorySteps={laboratorySteps}
        />
      )}
      {activeStep === 2 && (
        <LaboratoryDocumentsForm
          onNext={() => handleNextStep(1)}
          onPrev={() => handlePrevStep(1)}
          laboratoryData={laboratoryData && laboratoryData.documents}
          laboratoryId={laboratoryData && laboratoryData._id}
        />
      )}
    </Box>
  );
};
