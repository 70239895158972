import { Box, Divider, Grid } from '@mui/material';
import {
  PopupModal,
  Text,
  UIButton,
  UIMultipleSelectCheckmarks,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { UISelectCheckGroup } from '../../../../ui-kits/select-with-checkbox-group';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStateNextPage, getStates } from '../../../../services/redux/selectors/manageProccess';
import { getTitles } from '../../../../utils';
import { useFormik } from 'formik';
import { globalStyles } from '../../../../theme';
import { defaultValues } from '../../../../constants';
import { LimitedChips } from './limited-chips';
import { getStateRequest } from '../../../../services/redux/slices';
import { styles } from './styles';

export const AdvancedSearchModal = ({ handleModalClose, open, handleSearch }) => {
  const dispatch = useDispatch();

  const states = useSelector(getStates);
  const nextPage = useSelector(getStateNextPage);

  const [stateList, setStateList] = useState([]);
  const [, setBtndisabled] = useState(true);
  const [, setIsChanged] = useState(false);

  const { values, handleBlur, resetForm, setFieldValue } = useFormik({
    initialValues: {
      states: [],
      districts: [],
      talukas: [],
      villages: [],
      roles: [],
      search: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    dispatch(getStateRequest());
  }, []);

  useEffect(() => {
    if (nextPage !== null) dispatch(getStateRequest({ page: nextPage }));
  }, [nextPage]);

  useEffect(() => {
    let temp = [...stateList, ...states];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setStateList(uniqueStates);
  }, [states]);

  useEffect(() => {
    if (values.states.length >= 0) {
      const updatedDistricts = removeDataOfRemovedItem(
        values.states,
        values.districts,
        defaultValues.appLogicalTexts.district
      );

      if (values.districts.length !== updatedDistricts.length) {
        setFieldValue(defaultValues.appLogicalTexts.districts, updatedDistricts);
      }
    }

    if (values.districts.length >= 0) {
      const updatedTaluka = removeDataOfRemovedItem(
        values.districts,
        values.talukas,
        defaultValues.appLogicalTexts.taluka
      );

      if (values.talukas.length !== updatedTaluka.length) {
        setFieldValue('talukas', updatedTaluka);
      }
    }

    if (values.talukas.length >= 0) {
      const updatedVillage = removeDataOfRemovedItem(
        values.talukas,
        values.villages,
        defaultValues.appLogicalTexts.village
      );

      if (values.villages.length !== updatedVillage.length) {
        setFieldValue(defaultValues.appLogicalTexts.villages, updatedVillage);
      }
    }
  }, [values.states, values.districts, values.talukas, values.villages]);

  const handleDeleteChip = (index, field, currentValues, setFieldValue) => {
    setBtndisabled(false);
    const updatedValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(field, updatedValues);
  };

  const removeDataOfRemovedItem = (parentArray, childArray, childField) => {
    const remainingData =
      parentArray &&
      parentArray.flatMap(
        (state) => state[childField] && state[childField].map((child) => child._id)
      );
    return childArray.filter((child) => remainingData.includes(child._id));
  };

  const dropdownChange = (val, type, extractArr, extractElem) => {
    const newArr = values[extractArr].map((item) => item[extractElem]);
    const newArr3 = newArr.reduce((acc, innerArray) => acc.concat(innerArray), []);
    const newArr2 = newArr3.filter((elem) => val && val.includes(elem.name));
    if (type === defaultValues.appLogicalTexts.villages) {
      const newArr4 = newArr2.map((item) => ({ _id: item._id, name: item.name }));
      return newArr4;
    }
    return newArr2;
  };

  const handleClose = () => {
    resetForm();
    handleModalClose();
  };

  const handleChange = (val, type, parent, extract, field) => {
    const dist = dropdownChange(val, type, parent, extract);
    setFieldValue(field, dist);
    setBtndisabled(true);
  };

  return (
    <PopupModal showModel={open} onClose={handleClose} title={''} containerStyle={styles.modalCont}>
      <Box sx={styles.searchCont}>
        <UITextInputWithTitle
          name="search"
          value={values.search}
          onChange={handleSearch}
          title={''}
          placeHolder={''}
          containerStyles={styles.modalTextInput}
        />
        <UIButton title={getTitles('T-11343')} style={styles.button} />
      </Box>
      <Box sx={styles.filterCont}>
        <Text variant="D24">{getTitles('T-10073')}</Text>
        <Divider sx={styles.divider('60%')} />
        <Grid container sx={styles.filterSubCont}>
          <Grid item>
            <Grid container sx={styles.locationCont}>
              <Grid item sx={styles.textCont}>
                <Text variant="D24">{getTitles('T-10734')}</Text>
              </Grid>
              <Grid item sx={styles.selectCont}>
                <Box sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name="states"
                    options={[
                      {
                        title: '',
                        value:
                          stateList &&
                          stateList.length > 0 &&
                          stateList.map((item) => ({ title: item.name, value: item.name }))
                      }
                    ]}
                    placeholder={getTitles('T-10070')}
                    onChange={(val) => {
                      const newArr = stateList.filter((item) => val.includes(item.name));
                      setFieldValue('states', newArr);
                      setBtndisabled(false);
                      setIsChanged(true);
                    }}
                    onBlur={handleBlur}
                    preSelectedValues={values.states}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    hasName={true}
                    showSelName={false}
                    onClear={values.states.length === 0}
                  />
                  {values.states.length > 0 && (
                    <LimitedChips
                      values={values.states}
                      setFieldValue={setFieldValue}
                      setBtnDisabled={setBtndisabled}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'states', values.states, setFieldValue)
                      }
                    />
                  )}
                </Box>

                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={defaultValues.appLogicalTexts.districts}
                    required={true}
                    onBlur={handleBlur}
                    options={
                      values.states &&
                      values.states.map((item) => ({
                        title: item.name,
                        value:
                          item.district &&
                          item.district.map((item) => ({
                            title: item.name,
                            value: item.name
                          }))
                      }))
                    }
                    onChange={(val) => {
                      handleChange(
                        val,
                        defaultValues.appLogicalTexts.districts,
                        defaultValues.appLogicalTexts.states,
                        defaultValues.appLogicalTexts.district,
                        defaultValues.appLogicalTexts.districts
                      );
                    }}
                    preSelectedValues={values.districts}
                    placeholder={getTitles('T-10069')}
                    readOnly={values.states.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    hasName={true}
                    onClear={values.states?.length === 0}
                  />
                  {values.districts.length > 0 && (
                    <LimitedChips
                      values={values.districts}
                      setFieldValue={setFieldValue}
                      setBtnDisabled={setBtndisabled}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'districts', values.districts, setFieldValue)
                      }
                    />
                  )}
                </Box>

                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={defaultValues.appLogicalTexts.taluka}
                    required={true}
                    onBlur={handleBlur}
                    options={
                      values.districts &&
                      values.districts.map((item) => {
                        return {
                          title: item.name,
                          value:
                            item.taluka && item.taluka.length > 0
                              ? item.taluka.map((elem) => ({
                                  title: elem.name,
                                  value: elem.name
                                }))
                              : []
                        };
                      })
                    }
                    onChange={(val) => {
                      handleChange(
                        val,
                        defaultValues.appLogicalTexts.taluka,
                        defaultValues.appLogicalTexts.districts,
                        defaultValues.appLogicalTexts.taluka,
                        'talukas'
                      );
                    }}
                    preSelectedValues={values.talukas}
                    placeholder={getTitles('T-10071')}
                    readOnly={values.districts.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    hasName={true}
                    onClear={values.districts?.length === 0}
                  />
                  {values.districts.length > 0 && (
                    <LimitedChips
                      values={values.talukas}
                      setFieldValue={setFieldValue}
                      setBtnDisabled={setBtndisabled}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'talukas', values.talukas, setFieldValue)
                      }
                    />
                  )}
                </Box>
                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={defaultValues.appLogicalTexts.villages}
                    required={true}
                    options={
                      values.talukas &&
                      values.talukas.map((item) => {
                        return {
                          title: item.name,
                          value:
                            item.village && item.village.length > 0
                              ? item.village.map((elem) => ({
                                  title: elem.name,
                                  value: elem.name
                                }))
                              : []
                        };
                      })
                    }
                    onChange={(val) => {
                      handleChange(
                        val,
                        defaultValues.appLogicalTexts.villages,
                        'talukas',
                        defaultValues.appLogicalTexts.village,
                        'villages'
                      );
                    }}
                    preSelectedValues={values.villages}
                    placeholder={getTitles('T-10068')}
                    readOnly={values.talukas.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    hasName={true}
                    onClear={values.talukas?.length === 0}
                  />

                  {values.villages.length > 0 && (
                    <LimitedChips
                      values={values.villages}
                      setFieldValue={setFieldValue}
                      setBtnDisabled={setBtndisabled}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'villages', values.villages, setFieldValue)
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container sx={styles.locationCont}>
              <Grid item sx={styles.textCont}>
                <Text variant="D24">{getTitles('T-10455')}</Text>
              </Grid>
              <Grid item sx={styles.selectCont}>
                <Box item sx={styles.selectSubCont}>
                  <UIMultipleSelectCheckmarks
                    name="roles"
                    preSelectedValue={values.roles}
                    options={defaultValues.callCenterSearchRoles}
                    onChange={(val) => {
                      setFieldValue('roles', val);
                      setBtndisabled(false);
                    }}
                    placeholder={getTitles('T-10429')}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyles={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showTitles={false}
                  />
                  {values.roles.length > 0 && (
                    <LimitedChips
                      values={values.roles.map((item) => ({ ...item, name: item.title }))}
                      setFieldValue={setFieldValue}
                      setBtnDisabled={setBtndisabled}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'roles', values.roles, setFieldValue)
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PopupModal>
  );
};
