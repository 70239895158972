import dayjs from 'dayjs';
import titles from '../constants/titles.json';
import { KEYS } from '../constants/keys';
import { defaultValues } from '../constants';

// we cannot add language name in default values we are importing getTitles function in default values
export const language_name = {
  english: 'english',
  hindi: 'hindi',
  kannada: 'kannada',
  marathi: 'marathi'
};

export function getTitles(code, varNameArr) {
  const currentLang = localStorage.getItem(KEYS.LANGUAGE) || language_name.english;

  const header = titles[code] && titles[code].titles[currentLang];
  if (header && header.indexOf('<') !== -1) {
    let updatedMsg = header;

    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedMsg = updatedMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',

          val
        );
      });
    }
    return updatedMsg;
  } else if (header) {
    return header;
  } else {
    return 'XXX';
  }
}

export const getApplicationLanguage = () => {
  const ele = defaultValues.appLanguages.filter(
    (item) => item.value === localStorage.getItem(defaultValues.appLogicalTexts.language)
  );

  return ele[0] || defaultValues.appLanguages[0];
};

export const getFormatDate = (value) => {
  return dayjs(value).format('DD-MM-YYYY');
};

export const resetMessages = (cb) => {
  setTimeout(() => {
    cb();
  }, 1000);
};

export const getTitleWithId = (arr, id) => {
  const role = arr.find((role) => role.value === id);
  return role ? role.title : null;
};

export const getNameWithIdForEnum = (arr, id) => {
  const role = arr.find((role) => role.id === id);
  return role ? role.name : null;
};

export const removeFalsyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line no-unused-vars
      ([key, value]) => value !== '' && value !== null && value !== undefined
    )
  );
};

export const getUniqueAndSortedData = (data) => {
  const uniqueData = data.filter(
    (item, index, self) => index === self.findIndex((d) => d._id === item._id)
  );

  // Sort alphabetically by name
  uniqueData.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return uniqueData;
};

export const checkReadOnly = (userData, role) => {
  switch (userData.user_role) {
    case defaultValues.userRole.superAdmin: // if role is super admin
      return false;
    case defaultValues.userRole.employee: {
      const menuData = userData.employee_permissions.filter(({ permission_id }) => {
        if (typeof role === 'number') {
          return permission_id === role;
        } else if (Array.isArray(role)) {
          return role.includes(permission_id);
        }
      });
      return menuData?.some((item) => item.read_only);
    }
  }
};

export const convertStringsToBoolean = (arr) => {
  return arr.map((obj) => {
    // Create a new object to avoid mutating the original
    const newObj = { ...obj };

    for (const key in newObj) {
      if (newObj[key] === 'true') {
        newObj[key] = true;
      } else if (newObj[key] === 'false') {
        newObj[key] = false;
      } else if (!isNaN(newObj[key])) {
        newObj[key] = parseInt(newObj[key]);
      }
    }

    return newObj;
  });
};

//  to  get ids of the value which is in an array
export function getIdsByNames(namesArray, objectsArray) {
  return namesArray.map((name) => {
    const found = objectsArray.find((obj) => obj.name === name);
    return found ? found._id : null; // Return null if no match is found
  });
}

export function getNamesByIds(idsArray, objectsArray) {
  return idsArray.map((id) => {
    const found = objectsArray.find((obj) => obj._id === id);
    return found ? found.name : null; // Return null if no match is found
  });
}

export function getIsOther(idsArray, objectsArray) {
  return idsArray.map((id) => {
    const found = objectsArray.find((obj) => obj._id === id?._id);
    return found ? found.name : null; // Return null if no match is found
  });
}

// to get the id of enum values with title multi select
export function getIdsByTitle(namesArray, objectsArray) {
  return namesArray.map((name) => {
    const found = objectsArray.find((obj) => obj.title === name);
    return found ? found.value : null; // Return null if no match is found
  });
}

export function getTitlesByValue(namesArray, objectsArray) {
  return (
    namesArray &&
    namesArray.map((val) => {
      const found = objectsArray.find((obj) => obj.value === val);
      return found ? found.title : null; // Return null if no match is found
    })
  );
}

export const mergeLabAndReportData = (labReport, reportData) => {
  // Create a copy of the labReport to avoid modifying the original
  const mergedReport = JSON.parse(JSON.stringify(labReport));

  // Iterate through each section in the reportData
  reportData.sections.forEach((reportSection) => {
    // Find the corresponding section in the labReport
    const labSection = mergedReport.sections.find(
      (labSec) => labSec._id === reportSection._id?._id
    );

    if (labSection) {
      // Iterate through each test field in the reportSection
      reportSection.test_fields.forEach((reportField) => {
        // Find the corresponding field in the labSection
        const labField = labSection.test_fields.find(
          (labFld) => labFld._id === reportField._id?._id
        );

        if (labField) {
          // Add the sample_value to the labField
          labField.sample_value = reportField.sample_value;
        }
      });
    }
  });

  return mergedReport;
};

export const getMasterListItems = (masterLists, idNo) => {
  const item = masterLists.find((item) => item.master_id === idNo);
  return item ? item.data : [];
};

export function formatDates(dateObj) {
  // Convert the M type date object to a standard Date object
  const date = new Date(dateObj.$d);
  // Use toLocaleDateString with a custom format string
  // DD/MM/YYYY will give you the date in the format you requested
  return date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    .replace(/\//g, '/');
}

export const handleMultiDelete = (index, parentArr) => {
  let arr = [...parentArr];
  const arr2 = arr.filter((_, idx) => idx !== index);
  return arr2?.length > 0 ? arr2 : [];
};

export const calculateBodyWeight = (length, girth) => {
  const parsedLength = parseFloat(length); // BL
  const parsedGirth = parseFloat(girth); // CC

  if (!isNaN(parsedLength) && !isNaN(parsedGirth)) {
    const weight = (parsedGirth ** 2 * parsedLength) / 10840;
    return weight.toFixed(2);
  } else {
    return null;
  }
};

// function to check the values in rules
export const processRule = (rule) => {
  // Only return properties that are valid
  const processedRule = {};

  if (rule.subject?.id) processedRule.subject = rule.subject?.id;
  if (rule.condition_number?.id) processedRule.condition_number = rule.condition_number?.id;
  if (rule.condition_string?.id) processedRule.condition_string = rule.condition_string?.id;
  if (rule.condition_date?.id) processedRule.condition_date = rule.condition_date?.id;
  if (rule.condition_boolean?.id) processedRule.condition_boolean = rule.condition_boolean;

  // Set non-object values directly
  if (rule.condition_number_min_value !== undefined)
    processedRule.condition_number_min_value = rule.condition_number_min_value;
  if (rule.condition_number_max_value !== undefined)
    processedRule.condition_number_max_value = rule.condition_number_max_value;
  if (rule.condition_string_value !== undefined)
    processedRule.condition_string_value = rule.condition_string_value;
  if (rule.condition_date_start_value !== undefined)
    processedRule.condition_date_start_value = dayjs(rule.condition_date_start_value);
  if (rule.condition_date_end_value !== undefined)
    processedRule.condition_date_end_value = dayjs(rule.condition_date_end_value);
  if (rule.condition_date_days_value !== undefined)
    processedRule.condition_date_days_value = rule.condition_date_days_value;
  if (rule.condition_boolean_value !== undefined)
    processedRule.condition_boolean_value = rule.condition_boolean_value;

  return processedRule;
};

export const getFullName = (parts) => {
  return parts.filter(Boolean).join(' ');
};

export const filterSelectedValue = (
  originalValues = [],
  selectedValues = [],
  selectValKey = '_id',
  originalValKey = '_id'
) => {
  const selectedSet = new Set(selectedValues.map((val) => val[selectValKey]));

  const filtered = originalValues.filter((item) => !selectedSet.has(item[originalValKey]));

  return filtered;
};

export const getFormattedOptions = (initialArray, parentKey) => {
  if (initialArray && parentKey) {
    const output = initialArray.reduce((acc, elem) => {
      const state = acc.find((item) => item.id === elem[parentKey]);
      if (state) {
        state.value.push(elem);
      } else {
        acc.push({
          id: elem[parentKey],
          value: [elem]
        });
      }
      return acc;
    }, []);

    return output || [];
  }
};
