export * from './home';
export * from './loginPage';
export * from './dashboard';
export * from './profilepage';
export * from './manageState';
export * from './manage-process';
export * from './manage-process/manage-languages';
export * from './manage-process/manage-disease';
export * from './manage-employee';
export * from './call-center';

export * from './farmers/manage-farmer';
export * from './farmers/register-farmer';
export * from './manage-vet';
export * from './manage-ai-worker';
export * from './manage-cattle/view-cattles';
export * from './manage-cattle/cattle-registration';
export * from './manage-society';
export * from './manage-laboratory';
export * from './manage-govt-official';

export * from './privacy-policy';
export * from './shipping-policy';
export * from './disclaimer';
