// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone {
  width: 100%;
  border-radius: 5px;
}

.phone .PhoneInputInput {
  height: 29px;
  border: none;
  font-size: 16px;
  padding: 10px;
  border: 1px solid rgba(197, 199, 208, 1);
  border-radius: 0px 5px 5px 0px;
  color: #050505;
}

.phone .PhoneInputCountry {
  border: 1px solid rgba(197, 199, 208, 1);
  margin-right: 0;
  padding: 10px;
  height: 29px;
  border-radius: 5px 0px 0px 5px;
}

.phone .PhoneInputInput:focus {
  outline: none;
}

.error {
  border: 1px solid red;
  border-radius: 5px;
}

.error .PhoneInputInput {
  border: none;
  border-left: 1px solid rgba(197, 199, 208, 1);
}

.error .PhoneInputCountry {
  border: none;
  border-right: 1px solid rgba(197, 199, 208, 1);
}

.disabled .PhoneInputInput {
  color: rgba(197, 199, 208, 1);
}

@media screen and (max-width: 900px) {
  .phone .PhoneInputInput {
    font-size: 14px;
    height: 20px;
  }

  .phone .PhoneInputCountry {
    height: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui-kits/phone-input/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,aAAa;EACb,wCAAwC;EACxC,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,wCAAwC;EACxC,eAAe;EACf,aAAa;EACb,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,8CAA8C;AAChD;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".phone {\n  width: 100%;\n  border-radius: 5px;\n}\n\n.phone .PhoneInputInput {\n  height: 29px;\n  border: none;\n  font-size: 16px;\n  padding: 10px;\n  border: 1px solid rgba(197, 199, 208, 1);\n  border-radius: 0px 5px 5px 0px;\n  color: #050505;\n}\n\n.phone .PhoneInputCountry {\n  border: 1px solid rgba(197, 199, 208, 1);\n  margin-right: 0;\n  padding: 10px;\n  height: 29px;\n  border-radius: 5px 0px 0px 5px;\n}\n\n.phone .PhoneInputInput:focus {\n  outline: none;\n}\n\n.error {\n  border: 1px solid red;\n  border-radius: 5px;\n}\n\n.error .PhoneInputInput {\n  border: none;\n  border-left: 1px solid rgba(197, 199, 208, 1);\n}\n\n.error .PhoneInputCountry {\n  border: none;\n  border-right: 1px solid rgba(197, 199, 208, 1);\n}\n\n.disabled .PhoneInputInput {\n  color: rgba(197, 199, 208, 1);\n}\n\n@media screen and (max-width: 900px) {\n  .phone .PhoneInputInput {\n    font-size: 14px;\n    height: 20px;\n  }\n\n  .phone .PhoneInputCountry {\n    height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
