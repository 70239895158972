import { Box } from '@mui/material';
import { styles } from './styles';
import { getTitles, InwardVendorDetailsSchema, removeFalsyValues } from '../../../../utils';
import { useFormik } from 'formik';
import { DatePickers, UIAutocompleted, UISelect, UITextInputWithTitle } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInventorySuccessMsg,
  getInwardActivePage,
  getProductDetails
} from '../../../../services/redux/selectors';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';
import {
  addInventoryProductRequest,
  getVenderRequest,
  setInwardActivePage,
  updateDetailsPg,
  updateInventoryProductRequest
} from '../../../../services/redux/slices';
import dayjs from 'dayjs';

export const InwardVendorDetails = ({ stateList, vendor, onPrev }) => {
  const dispatch = useDispatch();
  const activePage = useSelector(getInwardActivePage);
  const productData = useSelector(getProductDetails);
  const successMsg = useSelector(getInventorySuccessMsg);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [proDetails, setProDetails] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  let timer;
  const { view, edit, add } = defaultValues.actionType;
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldTouched,
    setValues,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      vendor_id: '',
      bill_number: '',
      bill_date: null,
      state_supply: '',
      manufacturing_date: null,
      expiry_date: null,
      gross_weight: null,
      net_weight: '',
      mrp_pack: '',
      unit_price: '',
      total_unit: '',
      free_qty: '',
      sale_price: '',
      gst: '',
      discount: '',
      tax: '',
      shipping: '',
      final_price: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: InwardVendorDetailsSchema
  });

  useEffect(() => {
    if (['S-10119', 'S-10120'].includes(successMsg) && isFinished) {
      dispatch(setInwardActivePage(null));
    }
  }, [successMsg]);

  useEffect(() => {
    setProDetails(productData);
  }, [productData]);

  useEffect(() => {
    if (proDetails?.vendor_invoice_details) {
      const newData = {
        ...proDetails.vendor_invoice_details,
        bill_date:
          proDetails.vendor_invoice_details?.bill_date &&
          dayjs(proDetails.vendor_invoice_details.bill_date),
        expiry_date:
          proDetails.vendor_invoice_details?.expiry_date &&
          dayjs(proDetails.vendor_invoice_details.expiry_date),
        manufacturing_date:
          proDetails.vendor_invoice_details?.manufacturing_date &&
          dayjs(proDetails.vendor_invoice_details.manufacturing_date),
        state_supply: proDetails.vendor_invoice_details.state_supply?._id
      };
      setValues(newData);
    }
  }, [proDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const searchList = (val) => {
    dispatch(getVenderRequest({ search_text: val, page: 1 }));
  };
  const handleSubmit = () => {
    let payload = {
      ...productData,
      registration_step: 2,
      vendor_invoice_details: { ...values, vendor_id: values.vendor_id?._id }
    };

    delete payload.product_details;
    activePage === edit && delete payload.registration_step;
    payload.vendor_invoice_details = removeFalsyValues(payload.vendor_invoice_details);

    activePage === add && dispatch(addInventoryProductRequest(payload));
    if (activePage === edit) {
      productData?.registration_step === 2
        ? dispatch(updateInventoryProductRequest(payload))
        : dispatch(addInventoryProductRequest({ ...payload, registration_step: 2 }));
    }
    if (activePage === view) {
      dispatch(setInwardActivePage(null));
      dispatch(updateDetailsPg(false));
      return;
    }
    setIsFinished(true);
  };
  const handleBackButton = () => {
    onPrev();
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UIAutocompleted
          name="vendor_id"
          required={true}
          options={vendor}
          title={getTitles('T-11450')}
          containerStyle={styles.autoCont}
          optionLabel={(item) =>
            item.first_name ? item.first_name + ' ' + item.last_name : item.name ? item.name : ''
          }
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2 || val.length === 0) {
                searchList(val || null);
              }
            }, 500);
          }}
          onBlur={handleBlur}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('vendor_id', val ? val : '');
            setBtndisabled(false);
          }}
          preSelectedValue={values.vendor_id}
          error={touched.vendor_id && errors.vendor_id?._id}
          readOnly={activePage === view}
          titleStyle={styles.autoTitle}
          width={styles.width}
        />
        <UITextInputWithTitle
          title={getTitles('T-11448')}
          placeHolder={getTitles('T-10384')}
          value={values.vendor_id?.vendor_id || ''}
          readOnly={activePage === view}
          width={styles.width}
          disabled
        />
      </Box>

      <Box sx={styles.gridCont}>
        <DatePickers
          dateValue={values.bill_date}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'bill_date' } })}
          title={getTitles('T-11482')}
          width={styles.secWidth}
          required={true}
          error={touched.bill_date && errors.bill_date}
          onOpen={() => setFieldTouched('bill_date', true)}
        />
        <UITextInputWithTitle
          name="bill_number"
          title={getTitles('T-11518')}
          placeHolder={getTitles('T-10360')}
          error={touched.bill_number && errors.bill_number}
          value={values.bill_number}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
        />
        <UISelect
          title={getTitles('T-11519')}
          options={stateList}
          returnValue="_id"
          optionLabel="name"
          name="state_supply"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.state_supply && errors.state_supply}
          value={values.state_supply}
          disabled={activePage === view}
          placeholder={getTitles('T-10350')}
          width={styles.secWidth}
          required
        />

        <DatePickers
          dateValue={values.manufacturing_date}
          onDateChange={(val) =>
            handleChange({ target: { value: val, name: 'manufacturing_date' } })
          }
          title={getTitles('T-11483')}
          width={styles.secWidth}
          required={true}
          error={touched.manufacturing_date && errors.manufacturing_date}
          onOpen={() => setFieldTouched('manufacturing_date', true)}
          maxDate={values.expiry_date}
        />
        <DatePickers
          dateValue={values.expiry_date}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'expiry_date' } })}
          title={getTitles('T-10743')}
          width={styles.secWidth}
          required={true}
          error={touched.expiry_date && errors.expiry_date}
          onOpen={() => setFieldTouched('expiry_date', true)}
          minDate={values.manufacturing_date}
        />
        <UITextInputWithTitle
          name="gross_weight"
          title={getTitles('T-11520')}
          placeHolder={getTitles('T-10360')}
          error={touched.gross_weight && errors.gross_weight}
          value={values.gross_weight}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
        />

        <UITextInputWithTitle
          name="net_weight"
          title={getTitles('T-11521')}
          placeHolder={getTitles('T-10360')}
          error={touched.net_weight && errors.net_weight}
          value={values.net_weight}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
        />
        <UITextInputWithTitle
          name="mrp_pack"
          title={getTitles('T-11522')}
          placeHolder={getTitles('T-10360')}
          error={touched.mrp_pack && errors.mrp_pack}
          value={values.mrp_pack}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="unit_price"
          title={getTitles('T-11523')}
          placeHolder={getTitles('T-10360')}
          error={touched.unit_price && errors.unit_price}
          value={values.unit_price}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />

        <UITextInputWithTitle
          name="free_qty"
          title={getTitles('T-11524')}
          placeHolder={getTitles('T-10360')}
          error={touched.free_qty && errors.free_qty}
          value={values.free_qty}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowOnlyNumbers
        />
        <UITextInputWithTitle
          name="sale_price"
          title={getTitles('T-11525')}
          placeHolder={getTitles('T-10360')}
          error={touched.sale_price && errors.sale_price}
          value={values.sale_price}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="gst"
          title={getTitles('T-11526')}
          placeHolder={getTitles('T-11537')}
          error={touched.gst && errors.gst}
          value={values.gst}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />

        <UITextInputWithTitle
          name="total_unit"
          title={getTitles('T-11527')}
          placeHolder={getTitles('T-10360')}
          error={touched.total_unit && errors.total_unit}
          value={values.total_unit}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="discount"
          title={getTitles('T-11528')}
          placeHolder={getTitles('T-10360')}
          error={touched.discount && errors.discount}
          value={values.discount}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.secWidth}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="tax"
          title={getTitles('T-11529')}
          placeHolder={getTitles('T-10360')}
          error={touched.tax && errors.tax}
          value={values.tax}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.width}
          required
          allowDecimal
        />
      </Box>

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="shipping"
          title={getTitles('T-11530')}
          placeHolder={getTitles('T-10360')}
          error={touched.shipping && errors.shipping}
          value={values.shipping}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.width}
          required
          allowDecimal
        />
        <UITextInputWithTitle
          name="final_price"
          title={getTitles('T-11531')}
          placeHolder={getTitles('T-10360')}
          error={touched.final_price && errors.final_price}
          value={values.final_price}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={activePage === view}
          width={styles.width}
          required
          allowDecimal
        />
      </Box>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          activePage === add
            ? getTitles('T-10339')
            : activePage === edit
              ? getTitles('T-10733')
              : getTitles('T-11103')
        }
        disabled={activePage !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
