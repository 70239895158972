import { createSelector } from 'reselect';

export const getCallcenterLoading = createSelector(
  [(state) => state.callcenter.isLoading],
  (d) => d
);
export const getCallCenterSuccessMsg = createSelector(
  [(state) => state.callcenter.successMessage],
  (d) => d
);
export const getCallCenterErrorMsg = createSelector(
  [(state) => state.callcenter.errorMessage],
  (d) => d
);
export const getCallcenterCurrTab = createSelector([(state) => state.callcenter.currTab], (d) => d);

export const getCallcenterMyTasks = createSelector([(state) => state.callcenter.myTasks], (d) => d);
export const getCallcenterAllCallHistory = createSelector(
  [(state) => state.callcenter.allCallHistory],
  (d) => d
);
export const getCallcenterAllTasks = createSelector(
  [(state) => state.callcenter.allTasks],
  (d) => d
);
export const getCallcenterTaskDetails = createSelector(
  [(state) => state.callcenter.taskDetails],
  (d) => d
);
export const getCallcenterUsersList = createSelector(
  [(state) => state.callcenter.usersList],
  (d) => d
);
export const getCallcenterUserInfo = createSelector(
  [(state) => state.callcenter.userInfo],
  (d) => d
);
export const getCallcenterCallerTasks = createSelector(
  [(state) => state.callcenter.callerTasks],
  (d) => d
);
export const getCallcenterCallerCallHistory = createSelector(
  [(state) => state.callcenter.callerCallHistory],
  (d) => d
);
