import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme/color';

export const NoData = ({ message, subMsg, height }) => {
  const styles = {
    container: {
      margin: '10px auto',
      height: height || '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: color.primaryBackground,
      width: '100%'
    },
    text: { width: '100%', textAlign: 'center' },
    stext: { width: '100%', textAlign: 'center', color: color.secondaryText }
  };
  return (
    <Box style={styles.container}>
      <Text variant="boldH32" style={styles.text}>
        {message}
      </Text>
      {subMsg && (
        <Text variant="D24" style={styles.stext}>
          {subMsg}
        </Text>
      )}
    </Box>
  );
};
