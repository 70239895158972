/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Screen } from '../../ui-kits';

export const ContactUs = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center'
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  return (
    <Screen showHeader={true}>
      <div style={styles.container}>
        <div style={styles.subContainer}>
          <h1>Contact Us</h1>
          <p>Last updated: September 19, 2024</p>

          <p>
            We’d love to hear from you! Whether you have questions, feedback, or need assistance,
            our team is here to help. Please click on the below phone number or email to reach out
            us directly. We strive to respond to all inquiries as quickly as possible. Thank you for
            connecting with us!
            <ul>
              <li>
                Phone No : <a href="tel:+919000000000">+91 9000000000</a>
              </li>
              <li>
                Email ID : <a href="mailto:help@mygopalan.com">help@mygopalan.com</a>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </Screen>
  );
};
