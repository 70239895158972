import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { defaultValues } from '../../constants';

export const EditButton = ({ onClick, isReadOnly, iconStyle }) => {
  const styles = {
    edit: (style, isReadOnly) => ({
      cursor: isReadOnly ? 'default' : 'pointer',
      width: defaultValues.isResponsive ? '20px' : '32px',
      height: defaultValues.isResponsive ? '20px' : '32px',
      opacity: isReadOnly ? 0.3 : 1,
      ...style
    })
  };
  return (
    <EditOutlinedIcon
      onClick={() => !isReadOnly && onClick && onClick()}
      sx={styles.edit(iconStyle, isReadOnly)}
    />
  );
};
