import React from 'react';
import { KeyboardBackspace } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { defaultValues } from '../../constants';
import { color } from '../../theme';

const isMobile = defaultValues.isResponsive;

export const UIBackButton = ({ style, disabled, buttonStyle, onClick, backRoot, ...rest }) => {
  const btnStyles = {
    backIcon: {
      height: isMobile ? '35px' : '40px',
      width: isMobile ? '25px' : '40px',
      color: color.primaryText,
      marginRight: '10px',
      ...backRoot
    },
    icon: {
      fontSize: isMobile ? '25px' : '35px',
      fontWeight: '100',
      ...buttonStyle
    }
  };

  return (
    <IconButton
      aria-label="back"
      sx={btnStyles.backIcon}
      {...{ disabled }}
      style={style}
      {...rest}
      onClick={() => onClick && onClick()}>
      <KeyboardBackspace sx={btnStyles.icon} />
    </IconButton>
  );
};
