import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewEmployeeDocs = ({ employeeData, handleView }) => {
  return (
    <Box sx={styles.container}>
      {employeeData && (
        <>
          <ViewData
            title={getTitles('T-10398')}
            subTitle={employeeData.id_photo ? getTitles('T-10444') : null}
            handleSubtitle={employeeData.id_photo ? () => handleView(employeeData.id_photo) : null}
          />
          <ViewData
            title={getTitles('T-10399')}
            subTitle={employeeData.agreement_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.agreement_photo ? () => handleView(employeeData.agreement_photo) : null
            }
          />
          <ViewData
            title={getTitles('T-10400')}
            subTitle={employeeData.aadhaar_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.aadhaar_photo ? () => handleView(employeeData.aadhaar_photo) : null
            }
          />
          <ViewData
            title={getTitles('T-10445')}
            subTitle={employeeData.voter_id_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.voter_id_photo ? () => handleView(employeeData.voter_id_photo) : null
            }
          />
          <ViewData
            title={getTitles('T-10446')}
            subTitle={employeeData.ration_card_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.ration_card_photo
                ? () => handleView(employeeData.ration_card_photo)
                : null
            }
          />
          <ViewData
            title={getTitles('T-10401')}
            subTitle={employeeData.pan_card_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.pan_card_photo ? () => handleView(employeeData.pan_card_photo) : null
            }
          />
          <ViewData
            title={getTitles('T-10402')}
            subTitle={employeeData.driving_license_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.driving_license_photo
                ? () => handleView(employeeData.driving_license_photo)
                : null
            }
          />
          <ViewData
            title={getTitles('T-10403')}
            subTitle={employeeData.vehicle_insurance_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.vehicle_insurance_photo
                ? () => handleView(employeeData.vehicle_insurance_photo)
                : null
            }
          />

          <ViewData
            title={getTitles('T-10662')}
            subTitle={employeeData.vehicle_rc_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.vehicle_rc_photo ? () => handleView(employeeData.vehicle_rc_photo) : null
            }
          />
          <ViewData
            title={getTitles('T-10663')}
            subTitle={employeeData.certification_photo ? getTitles('T-10444') : null}
            handleSubtitle={
              employeeData.certification_photo
                ? () => handleView(employeeData.certification_photo)
                : null
            }
          />
        </>
      )}
    </Box>
  );
};
