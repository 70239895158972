import { Box, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styles } from './styles';

export const ImageCarousel = ({ images, containerStyles, canvasStyle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const canvasRef = useRef(null);

  // Function to load the image on the canvas
  const loadCanvasImage = (index) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.src = images[index];
    img.onload = () => {
      // Clear previous canvas content
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Draw the new image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };

  // Smooth transition animation
  const animateTransition = (direction) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const startIndex = currentIndex;
    const nextIndex =
      direction === 'left'
        ? currentIndex === 0
          ? images.length - 1
          : currentIndex - 1
        : currentIndex === images.length - 1
          ? 0
          : currentIndex + 1;

    const img1 = new Image();
    const img2 = new Image();
    img1.src = images[startIndex];
    img2.src = images[nextIndex];

    img1.onload = () => {
      img2.onload = () => {
        const duration = 500; // Animation duration (ms)
        const startTime = performance.now();

        const step = (timestamp) => {
          const progress = (timestamp - startTime) / duration;
          const position = direction === 'left' ? -progress : progress;

          // Clear canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          // Draw first image (outgoing)
          ctx.drawImage(img1, position * canvas.width, 0, canvas.width, canvas.height);
          // Draw second image (incoming)
          ctx.drawImage(
            img2,
            (position + (direction === 'left' ? 1 : -1)) * canvas.width,
            0,
            canvas.width,
            canvas.height
          );

          if (progress < 1) {
            requestAnimationFrame(step);
          } else {
            setCurrentIndex(nextIndex);
            setIsTransitioning(false);
          }
        };

        requestAnimationFrame(step);
      };
    };
  };

  // Handlers for previous and next buttons with smooth transition
  const handlePrevClick = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      animateTransition('left');
    }
  };

  const handleNextClick = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      animateTransition('right');
    }
  };

  useEffect(() => {
    loadCanvasImage(currentIndex);
  }, [currentIndex]);

  return (
    <Box style={styles.container(containerStyles)}>
      <canvas ref={canvasRef} style={styles.canvas(canvasStyle)}></canvas>

      <IconButton onClick={handlePrevClick} sx={styles.leftIcon} disabled={isTransitioning}>
        <ArrowBackIosSharpIcon sx={styles.icon} />
      </IconButton>
      <IconButton onClick={handleNextClick} sx={styles.rightIcon} disabled={isTransitioning}>
        <ArrowForwardIosSharpIcon sx={styles.icon} />
      </IconButton>
    </Box>
  );
};
