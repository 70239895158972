import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { color } from '../../theme';
import { Text } from '../text';
import { defaultValues } from '../../constants';

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

// For Option similar arrayObject required this UI Kit
//  demoData = [
//   {
//     title: 'Red',
//     value: [
//       { title: 'Red1', value: 'Red1' },
//       { title: 'Red2', value: 'Red2' }
//     ]
//   }
// ];

export const UISelectCheckGroup = ({
  options = [],
  placeholder,
  onChange,
  variant = 'D20',
  title,
  required,
  error,
  onBlur,
  onClear = false,
  preSelectedValues,
  disabled = false,
  containerStyles,
  hasName = false,
  showSelName = true,
  placeholderStyle,
  selectStyle,
  showTitle = false,
  ...rest
}) => {
  const styles = {
    container: (style) => ({
      width: '100%',
      marginTop: '10px',
      ...style
    }),
    checkIcon: {
      '&.Mui-checked': {
        color: color.primary
      }
    },
    options: { '&.Mui-selected': { backgroundColor: color.primaryBackground } },

    asterisk: {
      color: color.errorText
    },
    select: (style) => ({
      marginTop: '5px',
      width: '100%',
      height: defaultValues.isResponsive ? '42px' : '50px',
      ...style
    }),
    placeholder: (style) => ({
      color: color.palette.lightGray,
      ...style
    })
  };

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedAllOptions, setSelectedAllOptions] = React.useState([]);

  React.useEffect(() => {
    if (onClear) {
      setSelectedAllOptions([]), setSelectedOptions([]);
    }
  }, [onClear]);

  React.useEffect(() => {
    if (preSelectedValues && hasName) {
      const names = preSelectedValues.map((item) => item.name || item.title);
      setSelectedOptions(names);
      return;
    }
    if (preSelectedValues && !hasName) {
      setSelectedOptions(preSelectedValues);
    }
  }, [preSelectedValues]);

  const handleChange = (value, selectAll, title) => {
    let filterData = selectedOptions;
    if (selectAll) {
      if (selectedAllOptions.findIndex((i) => i === value) < 0) {
        let data = [];
        options.forEach((option) => {
          if (option.title === value) {
            data = data.concat(option.value.map((i) => i.value));
          }
        });
        filterData = [...filterData, ...data.filter((i) => !filterData.includes(i))];
        setSelectedAllOptions((old) => [...old, value]);
      } else {
        let data = [];
        options.forEach((option) => {
          if (option.title === value) {
            data = data.concat(option.value.map((i) => i.value));
          }
        });
        filterData = filterData.filter((item) => !data.includes(item));
        setSelectedAllOptions((old) => old.filter((item) => item !== value));
      }
    } else {
      if (filterData.findIndex((i) => i === value) < 0) {
        filterData = [...filterData, value];
      } else {
        setSelectedAllOptions((old) => old.filter((item) => item !== title));
        filterData = filterData.filter((item) => item !== value);
      }
    }
    setSelectedOptions(filterData);
    onChange && onChange(filterData);
  };

  const ListTitle = ({ title }) => <MenuItem style={{ fontWeight: 'bold' }}>{title}</MenuItem>;

  function findDistrictsByIds(districtGroups, ids) {
    return districtGroups.reduce((acc, group) => {
      const matchingDistricts = group?.value?.filter((ele) => ids?.includes(ele.value));
      return acc.concat(matchingDistricts);
    }, []);
  }

  return (
    <div style={styles.container(containerStyles)}>
      {title && (
        <Text variant={variant} style={styles.title}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      <FormControl sx={{ width: '100%' }}>
        <Select
          multiple
          displayEmpty
          value={selectedOptions}
          input={<OutlinedInput />}
          sx={styles.select(selectStyle)}
          onBlur={onBlur}
          disabled={disabled}
          renderValue={() =>
            selectedOptions.length === 0 || !showSelName ? (
              <div style={styles.placeholder(placeholderStyle)}>{placeholder}</div>
            ) : showTitle ? (
              findDistrictsByIds(options, selectedOptions)
                .map((option) => option.title)
                .join(', ')
            ) : (
              selectedOptions.map((option) => option).join(', ')
            )
          }
          MenuProps={MenuProps}
          {...rest}>
          {options &&
            options.map((item) => [
              item.title && <ListTitle key={item.title} title={item.title} />,
              item.value && item.value.length > 0 && (
                <MenuItem
                  key={item.title + 'selAll'}
                  value={'selAll' + item.title}
                  disabled={disabled}
                  onClick={() => handleChange(item.title, true)}>
                  <Checkbox
                    checked={selectedAllOptions.includes(item.title)}
                    sx={styles.checkIcon}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
              ),
              item.value &&
                item.value.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={disabled}
                    onClick={() => handleChange(option.value, false, item.title)}>
                    <Checkbox
                      checked={selectedOptions.includes(option.value)}
                      sx={styles.checkIcon}
                    />
                    <ListItemText primary={option.title} />
                  </MenuItem>
                ))
            ])}
        </Select>
      </FormControl>
      {error && <Text variant="fieldError">{error}</Text>}
    </div>
  );
};
