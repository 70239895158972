import { Box } from '@mui/material';
import {
  CollapseDropView,
  EditButton,
  NoData,
  Text,
  UIButton,
  UIDataTable,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { useFormik } from 'formik';
import { getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import {
  updateEmployeeCurrentRender,
  updateEmployeeRequest
} from '../../../../services/redux/slices';
import { useDispatch } from 'react-redux';
import { defaultValues } from '../../../../constants';
import { TableCard } from '../../../../components/common/cards/table-card';
import { ViewFamily } from './view-family';
import { Delete } from '@mui/icons-material';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: {
    width: '100%'
  },
  rowValueContainerStyle: {
    border: `1px solid transparent`,
    gap: '10px'
  },
  valuesSubStyle: {
    padding: '0px'
  },
  inputCont: {
    marginTop: '0px'
  },
  genderInput: {
    marginTop: '0px'
  },
  text: {
    ...globalStyles.text
  },
  detailsCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  headerContStyle: {
    gap: '10px'
  },
  resInputCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  resInputSup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px'
  },
  delete: {
    cursor: 'pointer',
    width: res ? '20px' : '26px',
    height: res ? '20px' : '26px'
  },
  toolsCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '10px'
  },
  editBtn: {
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    width: 'fit-content',
    '& .MuiButton-startIcon': {
      marginRight: '0px',
      marginLeft: '0px'
    },
    padding: '5px',
    boxSizing: 'border-box'
  }
};

export const FamilyDetails = ({
  employeeData,
  employeeId,
  designations,
  departments,
  editable,
  handleContinue
}) => {
  const dispatch = useDispatch();
  const [btnDisabled, setBtndisabled] = useState(false);
  const [family, setFamily] = useState([]);
  const [isFam, setIsFam] = useState(true);

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      relationship: '',
      gender: '',
      occupation: '',
      age: '',
      insurance_covered: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    setFamily(employeeData ? employeeData : []);
  }, [employeeData, setValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = (isUpdate) => {
    let payload = { _id: employeeId, family_member_details: family };
    const obj = { ...values };
    if (isFam === true) {
      payload.family_member_details = [...family, obj];
    } else {
      const temp = family.map((item, idx) => (idx === isFam ? obj : item));
      payload.family_member_details = temp;
    }
    isUpdate && handleContinue();
    dispatch(updateEmployeeRequest(payload));
    resetForm();
    setIsFam(true);
    !isUpdate && dispatch(updateEmployeeCurrentRender(null));
  };

  const handleTitleClick = (item, idx) => {
    setValues(item);
    if (isFam === idx) {
      resetForm();
      return setIsFam(true);
    }
    setIsFam(idx);
  };

  const handleDelete = (item) => {
    let payload = {
      _id: employeeId,
      family_member_details: family.filter((ele) => item._id !== ele._id)
    };

    dispatch(updateEmployeeRequest(payload));
    resetForm();
    setIsFam(true);
  };

  const tableData = [
    [
      {
        title: getTitles('T-10081'),
        values: (
          <UITextInputWithTitle
            name="name"
            variant="D22"
            error={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            allowOnlyAlphabets={true}
          />
        )
      },
      {
        title: getTitles('T-10449'),
        values: (
          <UISelect
            name="relationship"
            variant="D22"
            options={defaultValues.relationshipOptions}
            placeholder={getTitles('T-10350')}
            error={touched.relationship && errors.relationship}
            value={values.relationship}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            inputStyle={styles.genderInput}
          />
        )
      },
      {
        title: getTitles('T-10450'),
        columnWidth: '70px',
        values: (
          <UITextInputWithTitle
            name="age"
            variant="D22"
            error={touched.age && errors.age}
            value={values.age}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            allowOnlyNumbers={true}
          />
        )
      },
      {
        title: getTitles('T-10351'),
        values: (
          <UISelect
            name="gender"
            variant="D22"
            options={defaultValues.genderOptions}
            placeholder={getTitles('T-10350')}
            error={touched.gender && errors.gender}
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            inputStyle={styles.genderInput}
          />
        )
      },
      {
        title: getTitles('T-10451'),
        values: (
          <UITextInputWithTitle
            name="occupation"
            variant="D22"
            error={touched.occupation && errors.occupation}
            value={values.occupation}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
          />
        )
      },
      {
        title: getTitles('T-10697'),
        values: (
          <UISelect
            name="insurance_covered"
            variant="D22"
            options={defaultValues.insuredOptions}
            placeholder={getTitles('T-10350')}
            error={touched.insurance_covered && errors.insurance_covered}
            value={values.insurance_covered}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.inputCont}
            inputStyle={styles.genderInput}
          />
        )
      }
    ]
  ];

  const familyList = family.map((item, idx) => [
    {
      values: (
        <Text style={styles.text} variant="D22">
          {item.name}
        </Text>
      )
    },
    {
      values:
        item.relationship &&
        defaultValues.relationshipOptions.find((ele) => ele.value == item.relationship).title
    },
    {
      columnWidth: '7%',
      values: item.age && `${item.age}`
    },
    {
      values:
        item.gender && defaultValues.genderOptions.find((ele) => ele.value == item.gender).title
    },
    {
      values: item.occupation
    },

    {
      columnWidth: '10%',
      values:
        ![null, undefined].includes(item.insurance_covered) &&
        defaultValues.insuredOptions.find((ele) => ele.value === item.insurance_covered).title
    },
    {
      columnWidth: md ? '15%' : '12%',
      values: (
        <Box sx={styles.toolsCont}>
          <UIButton
            startIcon={<EditButton iconStyle={styles.delete} />}
            style={styles.editBtn}
            onClick={() => handleTitleClick(item, idx)}
          />
          <UIButton
            startIcon={<Delete sx={styles.delete} />}
            style={styles.editBtn}
            onClick={() => handleDelete(item)}
          />
        </Box>
      )
    }
  ]);

  if (editable) {
    return (
      <Box sx={styles.container}>
        {isFam === true && (
          <Box sx={{ width: '100%' }}>
            {!res ? (
              <UIDataTable
                tableRows={tableData}
                rowValueContainerStyle={styles.rowValueContainerStyle}
                valuesSubStyle={styles.valuesSubStyle}
                headerContStyle={styles.headerContStyle}
              />
            ) : (
              <Box sx={styles.resInputSup}>
                {tableData[0].map((item, idx) => (
                  <Box key={idx} sx={styles.resInputCont}>
                    <Text style={{ width: '50%' }} variant="D24">
                      {item.title}
                    </Text>
                    <Box style={{ width: '50%' }}>{item.values}</Box>
                  </Box>
                ))}
              </Box>
            )}

            <UISaveAndAdd
              onAddAnother={() => handleSubmit(true)}
              title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
              disabled={!isValid || !dirty || btnDisabled}
            />
          </Box>
        )}

        {!res ? (
          <Box sx={styles.detailsCont}>
            {familyList &&
              familyList.length > 0 &&
              familyList.map((item, idx) => (
                <Box key={idx} sx={{ width: '100%' }}>
                  <TableCard data={item} isHeader={false} />

                  {isFam === idx && (
                    <Box sx={{ width: '100%', mt: 2 }}>
                      <UIDataTable
                        tableRows={tableData}
                        rowValueContainerStyle={styles.rowValueContainerStyle}
                        valuesSubStyle={styles.valuesSubStyle}
                        headerContStyle={styles.headerContStyle}
                      />

                      <UISaveAndAdd
                        onAddAnother={() => handleSubmit(true)}
                        title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
                        disabled={!isValid || !dirty || btnDisabled}
                      />
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        ) : (
          <Box sx={styles.detailsCont}>
            {family.map((item, idx) => (
              <Box key={idx} sx={{ width: '100%' }}>
                <CollapseDropView
                  title={item.name}
                  handleSubTitleClick={() => handleTitleClick(item, idx)}
                  open={isFam === idx}
                />
                {isFam === idx && (
                  <Box sx={styles.toolsCont}>
                    <UIButton
                      startIcon={<Delete sx={styles.delete} />}
                      style={styles.editBtn}
                      onClick={() => handleDelete(item)}
                    />
                  </Box>
                )}
                {isFam === idx && (
                  <Box sx={styles.resInputSup}>
                    {tableData[0].map((item, idx) => (
                      <Box key={idx} sx={styles.resInputCont}>
                        <Text style={{ width: '50%' }} variant="D22">
                          {item.title}
                        </Text>
                        <Box style={{ width: '50%' }}>{item.values}</Box>
                      </Box>
                    ))}
                    <UISaveAndAdd
                      onAddAnother={() => handleSubmit(true)}
                      title={isFam === true ? getTitles('T-10062') : getTitles('T-10477')}
                      disabled={!isValid || !dirty || btnDisabled}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}

        <ModalButtons
          onPrimaryBtnClick={() => handleSubmit(false)}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData ? (
        <ViewFamily
          employeeData={employeeData}
          departments={departments}
          designations={designations}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
