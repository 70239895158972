import { Box } from '@mui/material';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { UIContainer } from '../../../../ui-kits/container';
import { styles } from './style';
import { getFormatDate, getTitles } from '../../../../utils';
import { Text, UILayout, ViewData } from '../../../../ui-kits';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOutwardProductHistoryRequest,
  updateDetailsPg
} from '../../../../services/redux/slices';
import {
  getDetailsPg,
  getInventoryCurPage,
  getInventoryTotalPage,
  getOutwardProductHistory
} from '../../../../services/redux/selectors';

export const OutwardProductHistory = ({ setActionType, product, setProduct }) => {
  const dispatch = useDispatch();
  const isRes = defaultValues.isResponsive;

  const histories = useSelector(getOutwardProductHistory);
  const detailsPg = useSelector(getDetailsPg);
  const currentPage = useSelector(getInventoryCurPage);
  const totalPages = useSelector(getInventoryTotalPage);

  const [productHistory, setProductHistory] = useState([]);

  useEffect(() => {
    !detailsPg && handleBackButton();
  }, [detailsPg]);

  useEffect(() => {
    if (product) {
      let payload = {
        inward_entry_id: product?.inward_entry_id,
        page: 1
      };
      if (product.store) {
        payload.assign_to = defaultValues.inwardAssignTo.store;
        payload.store_id = product.store?._id;
      } else if (product.employee) {
        payload.assign_to = defaultValues.inwardAssignTo.employee;
        payload.employee_id = product.employee?._id;
      }
      dispatch(getOutwardProductHistoryRequest(payload));
    }
  }, [product]);

  useEffect(() => {
    histories && setProductHistory(histories);
  }, [histories]);

  let product_properties = [
    { name: getTitles('T-11481'), key: 'batch_number' },
    {
      name: (product?.store && getTitles('T-11419')) || (product?.employee && getTitles('T-11572')),
      key: (product?.store && 'store_name') || (product?.employee && 'employee_name')
    },
    {
      name: (product?.store && getTitles('T-11636')) || (product?.employee && getTitles('T-11115')),
      key: (product?.store && 'store_id') || (product?.employee && 'employee_id')
    }
  ];

  let product_values = product.store
    ? {
        batch_number: product?.batch_number,
        store_name: product.store?.name,
        store_id: product.store?.store_id
      }
    : {
        batch_number: product?.batch_number,
        employee_name: product.employee?.name,
        employee_id: product.employee?.employee_id
      };

  const tableData = productHistory.map((item) => {
    if (!isRes) {
      return [
        {
          title: getTitles('T-11637'),
          values: <Text variant="D22">{item?.transfer_id}</Text>,
          columnWidth: styles.col15
        },
        {
          title: getTitles('T-11638'),
          values: <Text variant="D22">{getFormatDate(item?.created_date)}</Text>,
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-11644'),
          values: <Text variant="D22">{item?.credit_unit}</Text>,
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-11645'),
          values: <Text variant="D22">{item?.debit_unit}</Text>,
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-11035'),
          values: <Text variant="D22">{item?.reason}</Text>,
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-10088'),
          values: <Text variant="D22">{item?.entry_type}</Text>,
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-10109'),
          values: (
            <Text variant="D22">
              {item?.description === 'Transferred to BACK_TO_Vendor  '
                ? 'Transferred Back to Vendor  '
                : item?.description}
            </Text>
          ),
          columnWidth: styles.describeCol
        }
      ];
    } else {
      return {
        title: <Text>{item?.transfer_id}</Text>,
        details: [
          {
            subTitle: getTitles('T-11638'),
            action: <Text variant="D20">{getFormatDate(item?.created_date)}</Text>
          },
          {
            subTitle: getTitles('T-11644'),
            action: <Text variant="D20">{item?.credit_unit}</Text>
          },
          {
            subTitle: getTitles('T-11645'),
            action: <Text variant="D20">{item?.debit_unit}</Text>
          },
          {
            subTitle: getTitles('T-11035'),
            action: <Text variant="D20">{item?.reason}</Text>
          },
          {
            subTitle: getTitles('T-10088'),
            action: <Text variant="D20">{item?.entry_type}</Text>
          },
          {
            subTitle: getTitles('T-10109'),
            action: (
              <Text variant="D20">
                {item?.description === 'Transferred to BACK_TO_Vendor  '
                  ? 'Transferred Back to Vendor  '
                  : item?.description}
              </Text>
            )
          }
        ]
      };
    }
  });

  const handleBackButton = () => {
    setActionType(null);
    setProduct({});
    dispatch(updateDetailsPg(false));
  };

  const handlePagination = (_, data) => {
    if (product) {
      let payload = {
        inward_entry_id: product?.inward_entry_id,
        page: data
      };
      if (product.store) {
        payload.assign_to = defaultValues.outwardToOptions[0].id;
        payload.store_id = product.store?._id;
      } else if (product.employee) {
        payload.assign_to = defaultValues.outwardToOptions[1].id;
        payload.employee_id = product.employee?._id;
      }
      dispatch(getOutwardProductHistoryRequest(payload));
    }
  };
  return (
    <>
      <UIContainer styles={styles.mainCont}>
        <Box sx={styles.titleCont}>
          {!isRes && (
            <UIBackButton
              backRoot={styles.backArrowBtn}
              buttonStyle={styles.btnIcon}
              onClick={handleBackButton}
            />
          )}
          <Text style={styles.titleTxt}>{getTitles('T-11414')}</Text>
        </Box>

        <Text style={styles.productTxt}>
          {product.product_master?.name} &nbsp; {`(${product.product_master?.product_id})`}
        </Text>
        <Box sx={styles.productInfoCont}>
          {product_properties?.map((item, idx) => {
            return (
              <ViewData
                key={idx}
                title={item.name}
                containerStyle={styles.detailCont}
                titleVariant={defaultValues.isResponsive ? 'D24' : 'BASE'}
                subTitleVariant={defaultValues.isResponsive ? 'D24' : 'BASE'}
                titleStyle={styles.detailTitleTxt}
                subTitleCont={styles.detailValueTxt}
                subTitle={product_values && product_values[item?.key]}
              />
            );
          })}
        </Box>
        <UILayout
          tableItems={tableData}
          showNoData={!productHistory.length > 0}
          showDataTable={!isRes}
          containerStyles={styles.uiCont}
          subContStyles={styles.uiSubCont}
          tableSuperSubCont={styles.tableSubCont(productHistory.length > 0)}
          showTableHeader={true}
          currPage={currentPage}
          pageCount={totalPages}
          showPagination={totalPages > 1}
          handlePagination={handlePagination}
          message={getErrorMessage('E-10274')}
        />
      </UIContainer>
    </>
  );
};
