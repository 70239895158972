import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

export const styles = {
  divider: (cW) => ({
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: cW ? cW : defaultValues.isResponsive ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
    mb: defaultValues.isResponsive ? 2 : 2.5,
    mt: defaultValues.isResponsive ? 0.2 : 0.7
  }),
  button: {
    width: '18%',
    height: '50px',
    mr: 2,
    fontSize: '1.125rem'
  },
  modalTextInput: {
    width: '80%',
    height: '60px'
  },
  selectPlaceholder: {
    color: color.primaryText
  },
  chip: {
    margin: 0
  },

  selectCont: {
    width: 'calc(100% - 130px)'
  },
  selectSubCont: {
    display: 'flex',
    gap: '10px',
    width: '100%'
  },
  select: {
    fontSize: '20px',
    '& fieldset': {
      border: 'none'
    },
    padding: 0,
    height: 'fit-content',
    '& .MuiInputBase-input': {
      padding: '2px 10px'
    }
  },
  modalCont: {
    width: '80%',
    maxWidth: 'none'
  },
  searchCont: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px'
  },
  filterCont: {
    width: '100%'
  },
  filterSubCont: {
    display: 'flex',
    flexDirection: 'column'
  },
  locationCont: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  textCont: {
    width: '120px'
  },
  checkbox: {
    width: '150px'
  }
};
