import { color } from '../../../../../theme';

export const styles = {
  container: {
    width: 'calc(100% - 160px)',
    display: 'flex',
    gap: '10px',
    overflowX: 'hidden'
  },
  chipBox: (showall, showBtn) => ({
    width: showBtn ? 'calc(100% - 120px)' : '100%',
    display: 'flex',
    flexWrap: showall ? 'wrap' : 'nowrap',
    overflowX: 'hidden',
    gap: '10px',
    mt: 1,
    boxSizing: 'border-box'
  }),
  chip: {
    margin: 0,
    backgroundColor: color.secondaryBackground,
    color: color.primaryText,
    '& .MuiChip-deleteIcon': {
      color: color.primaryText
    }
  },
  viewAll: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    marginTop: '20px',
    textDecoration: 'underline'
  }
};
