import { createSelector } from 'reselect';

export const societyLoading = createSelector([(state) => state.society.isLoading], (d) => d);
export const societySuccessMsg = createSelector(
  [(state) => state.society.successMessage],
  (d) => d
);
export const societyErrorMsg = createSelector([(state) => state.society.errorMessage], (d) => d);
export const getSocietys = createSelector([(state) => state.society.societysList], (d) => d);
export const getSocietyCurrPage = createSelector([(state) => state.society.currentPage], (d) => d);
export const getSocietyNextpage = createSelector([(state) => state.society.nextPage], (d) => d);
export const getSocietyTotalPage = createSelector([(state) => state.society.totalPage], (d) => d);
export const getSocietyParams = createSelector([(state) => state.society.societyParams], (d) => d);

export const getSocietyCurrRender = createSelector(
  [(state) => state.society.currentRender],
  (d) => d
);

export const getCurrSocietyData = createSelector(
  [(state) => state.society.currSocietyData],
  (d) => d
);

export const getMode = createSelector([(state) => state.society.mode], (d) => d);
export const getSocietyCompletedStep = createSelector(
  [(state) => state.society.completedStep],
  (d) => d
);

export const getMilkSuppliedList = createSelector(
  [(state) => state.society.milk_supplied],
  (d) => d
);

export const getAFarmerNxtPge = createSelector([(state) => state.society.farmerNextPage], (d) => d);
export const getAFarmerTtlPge = createSelector(
  [(state) => state.society.farmerTotalPage],
  (d) => d
);
export const getAFarmerCrntPge = createSelector(
  [(state) => state.society.farmerCurrenetPage],
  (d) => d
);

export const getAFarmerList = createSelector(
  [(state) => state.society.associatedFarmers],
  (d) => d
);

export const getSocietyActiveStep = createSelector([(state) => state.society.activeStep], (d) => d);
export const getSocietyIsInit = createSelector([(state) => state.society.isInit], (d) => d);
