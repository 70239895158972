import { color } from '../../../../theme';

export const styles = {
  container: (style) => ({
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: 2,
    boxSizing: 'border-box',
    display: 'flex',
    gap: '5px',
    ...style
  }),
  contentCont: {
    width: 'calc(100% - 35px)'
  },
  icon: {
    width: '30px',
    height: '30px'
  },
  locIcon: {
    width: '15px',
    height: '20px',
    borderRadius: 'none'
  },
  locCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  }
};
