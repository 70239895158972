import { useEffect, useState } from 'react';
import {
  SCREEN_PATH,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../../constants';
import { Screen, Text, UILayout } from '../../../../ui-kits';
import { getTitles, resetMessages } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssociatedFarmerRequest,
  resetSocietyMessages,
  setActionType,
  updateDetailsPg
} from '../../../../services/redux/slices';
import {
  getAFarmerCrntPge,
  getAFarmerList,
  getAFarmerTtlPge,
  getDetailsPg,
  societyErrorMsg,
  societyLoading,
  societySuccessMsg
} from '../../../../services/redux/selectors';
import { BasicProfile } from '../../../common/utility-components';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';

export const AssociatedFarmers = () => {
  const { viewOnly } = defaultValues.actionType;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const farmerList = useSelector(getAFarmerList);
  const isLoading = useSelector(societyLoading);
  const currentPage = useSelector(getAFarmerCrntPge);
  const totalPage = useSelector(getAFarmerTtlPge);
  const detailsPg = useSelector(getDetailsPg);
  const errorMsg = useSelector(societyErrorMsg);
  const successMsg = useSelector(societySuccessMsg);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [societyName, setSocietyName] = useState('');
  const [society_id, setSocietyId] = useState(null);
  const handleBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    if (!detailsPg) {
      handleBack();
    }
  }, [detailsPg]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetSocietyMessages()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (location.state) {
      setSocietyName(location.state.societyName);
      setSocietyId(location.state?.societyId);
    }
  }, [location.state]);

  useEffect(() => {
    if (society_id) {
      dispatch(getAssociatedFarmerRequest({ society_id }));
    }
  }, [society_id]);

  const handleSearch = (data) => {
    dispatch(getAssociatedFarmerRequest({ society_id, search_text: data }));
  };

  const handlePagination = (e, data) => {
    dispatch(getAssociatedFarmerRequest({ society_id, page: data, sort: sort }));
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(getAssociatedFarmerRequest({ society_id, sort: data }));
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    dispatch(getAssociatedFarmerRequest({ society_id }));
  };

  const styles = {
    bckBtnCon: { display: 'flex', p: 1, alignItems: 'center' }
  };

  const tableData =
    farmerList &&
    farmerList.length > 0 &&
    farmerList.map((item) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-10751'),
            values: `${item.farmer_id}`
          },
          {
            title: getTitles('T-11167'),
            values: item.member_society_code ? `${item.member_society_code}` : ''
          },
          {
            title: getTitles('T-10081'),
            values: (
              <BasicProfile
                title={item.full_name}
                url={item.photo_url}
                textVariant="D22"
                onTitleClick={() => {
                  dispatch(setActionType(viewOnly));
                  dispatch(updateDetailsPg(true));
                  navigation(SCREEN_PATH.REGISTER_FARMER, {
                    state: {
                      farmer_id: item.farmer_id
                    }
                  });
                }}
              />
            )
          },
          {
            title: getTitles('T-10440'),
            values: `${item.phone_number}`
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.full_name}
              url={item.photo_url}
              textVariant="D22"
              onTitleClick={() => {
                dispatch(setActionType(viewOnly));
                dispatch(updateDetailsPg(true));
                navigation(SCREEN_PATH.REGISTER_FARMER, {
                  state: {
                    farmer_id: item.farmer_id
                  }
                });
              }}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10751'),
              action: item.farmer_id
            },
            {
              subTitle: getTitles('T-11167'),
              action: item.member_society_code ? `${item.member_society_code}` : ''
            },
            {
              subTitle: getTitles('T-10440'),
              action: `${item.phone_number}`
            }
          ]
        };
      }
    });

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-10118')} isLoading={isLoading}>
      {!defaultValues.isResponsive && (
        <Box sx={styles.bckBtnCon}>
          <UIBackButton onClick={handleBack} />
          <Text variant="BoldH28">{societyName}</Text>
        </Box>
      )}
      <UILayout
        currPageName={societyName}
        handleSearch={handleSearch}
        showNoData={!farmerList.length > 0}
        currPage={currentPage}
        pageCount={totalPage}
        handlePagination={handlePagination}
        tableItems={tableData}
        showPagination={farmerList.length > 0 && totalPage > 1}
        searchBarPlaceHolder={getTitles('T-11005')}
        showDataTable={!defaultValues.isResponsive}
        message={getErrorMessage('E-10073')}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        clearSearch={filter}
        optionsForSorting={defaultValues.farmerSortOptions}
      />
    </Screen>
  );
};
