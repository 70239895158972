import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  employeesList: [],
  employeeParams: {},
  currentRender: null,
  currEmployeeData: null,
  designations: [],
  departments: []
};

const employeeSlice = createSlice({
  initialState,
  name: 'employee',
  reducers: {
    resetEmployee(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.employeesList = [];
      state.employeeParams = {};
      state.currentRender = null;
      state.currEmployeeData = null;
      state.designations = [];
      state.departments = [];
    },
    resetEmployeeMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    updateEmployeeParams(state, action) {
      state.employeeParams = action.payload;
    },
    updateEmployeeCurrentRender(state, action) {
      state.currentRender = action.payload;
    },
    updateCurrEmployeeData(state, action) {
      state.currEmployeeData = action.payload;
    },
    addEmployeeRequest(state) {
      state.isLoading = true;
    },
    addEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currEmployeeData = action.payload.data;
    },
    addEmployeeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateEmployeeRequest(state) {
      state.isLoading = true;
    },
    updateEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currEmployeeData = { ...state.currEmployeeData, ...action.payload.data };
    },
    updateEmployeeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getEmployeeRequest(state) {
      state.isLoading = true;
    },
    getEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.employeesList = action.payload.data.employees;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getEmployeeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.employeesList = [];
    },
    getDepartmentRequest(state) {
      state.isLoading = true;
    },
    getDepartmentSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload.data;
    },
    getDepartmentFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getDesignationRequest(state) {
      state.isLoading = true;
    },
    getDesignationSuccess(state, action) {
      state.isLoading = false;
      state.designations = action.payload.data;
    },
    getDesignationFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    uploadEmployeeDocsRequest(state) {
      state.isLoading = true;
    },
    uploadEmployeeDocsSuccess(state, action) {
      state.isLoading = false;
      state.currEmployeeData = { ...state.currEmployeeData, ...action.payload.data };
      state.successMessage = action.payload.status_code;
    },
    uploadEmployeeDocsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    deleteDocsRequest(state) {
      state.isLoading = true;
    },
    deleteEmployeeDocsSuccess(state, action) {
      state.isLoading = false;
      state.currEmployeeData = { ...state.currEmployeeData, ...action.payload.data };
      state.successMessage = action.payload.status_code;
    },
    deleteEmployeeDocsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetEmployeeMessages,
  updateEmployeeParams,
  addEmployeeFailure,
  addEmployeeRequest,
  addEmployeeSuccess,
  updateEmployeeCurrentRender,
  updateCurrEmployeeData,
  updateEmployeeFailure,
  updateEmployeeRequest,
  updateEmployeeSuccess,
  getEmployeeFailure,
  getEmployeeRequest,
  getEmployeeSuccess,
  getDepartmentFailure,
  getDepartmentRequest,
  getDepartmentSuccess,
  getDesignationFailure,
  getDesignationRequest,
  getDesignationSuccess,
  uploadEmployeeDocsFailure,
  uploadEmployeeDocsRequest,
  uploadEmployeeDocsSuccess,
  deleteDocsRequest,
  deleteEmployeeDocsFailure,
  deleteEmployeeDocsSuccess,
  resetEmployee
} = employeeSlice.actions;

export default employeeSlice.reducer;
