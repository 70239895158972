import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import processReducer from './slices/manageProcessSlice';
import employeeReducer from './slices/employeeSlice';
import farmerReducer from './slices/farmerSlice';
import vetReducer from './slices/vetSlice';
import aiWorkerReducer from './slices/aiWorkerSlice';
import societyReducer from './slices/societySlice';
import insuranceReducer from './slices/insuranceSlice';
import laboratoryReducer from './slices/laboratorySlice';
import cattleReducer from './slices/cattleSlice';
import govtOfficialReducer from './slices/govtOfficialSlice';
import callcenterReducer from './slices/callcenterSlice';

const appReducer = combineReducers({
  auth: authReducer,
  process: processReducer,
  employee: employeeReducer,
  farmer: farmerReducer,
  vet: vetReducer,
  aiWorker: aiWorkerReducer,
  society: societyReducer,
  insurance: insuranceReducer,
  laboratory: laboratoryReducer,
  cattle: cattleReducer,
  govtOfficial: govtOfficialReducer,
  callcenter: callcenterReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
