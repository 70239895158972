import { createSelector } from 'reselect';

export const processLoading = createSelector([(state) => state.process.isLoading], (d) => d);
export const getStates = createSelector([(state) => state.process.states], (d) => d);
export const getDistrict = createSelector([(state) => state.process.district], (d) => d);
export const getTaluka = createSelector([(state) => state.process.taluka], (d) => d);
export const getVillage = createSelector([(state) => state.process.village], (d) => d);
export const getSuccessMsg = createSelector([(state) => state.process.success], (d) => d);
export const getErrorMsg = createSelector([(state) => state.process.error], (d) => d);
export const getCurPage = createSelector([(state) => state.process.currentPage], (d) => d);
export const getNxtPage = createSelector([(state) => state.process.nextPage], (d) => d);
export const getTotalPage = createSelector([(state) => state.process.totalPage], (d) => d);
export const getLanguagesList = createSelector([(state) => state.process.languageList], (d) => d);
export const getMasterList = createSelector([(state) => state.process.masterList], (d) => d);
export const getMasterList16 = createSelector([(state) => state.process.diseaseGroup], (d) => d);
export const getMasterList17 = createSelector([(state) => state.process.diseaseType], (d) => d);
export const getDiseasesList = createSelector([(state) => state.process.diseaseList], (d) => d);
export const getProcessCurrTab = createSelector([(state) => state.process.currentTab], (d) => d);
export const getMasterId = createSelector([(state) => state.process.masterId], (d) => d);
export const getLanguageParams = createSelector(
  [(state) => state.process.languageParams],
  (d) => d
);
export const getDiseaseParams = createSelector([(state) => state.process.diseaseParams], (d) => d);
export const getActivePage = createSelector([(state) => state.process.activePage], (d) => d);
export const getStateNavDetails = createSelector(
  [(state) => state.process.stateNavDetails],
  (d) => d
);
export const getStateNextPage = createSelector([(state) => state.process.stateNxtPage], (d) => d);
export const getStateCurrPage = createSelector([(state) => state.process.stateCurrPage], (d) => d);
export const getStateTotalPage = createSelector(
  [(state) => state.process.stateTotalPage],
  (d) => d
);
export const getVannices = createSelector([(state) => state.process.vaccines], (d) => d);
export const getVaccineCurPage = createSelector(
  [(state) => state.process.currVaccinePage],
  (d) => d
);
export const getVaccineNxtPage = createSelector(
  [(state) => state.process.nextVaccinePage],
  (d) => d
);
export const getVaccineTotalPage = createSelector(
  [(state) => state.process.totalVaccinePage],
  (d) => d
);

export const getMasterLists = createSelector([(state) => state.process.masterLists], (d) => d);
export const getVillageCurrPage = createSelector(
  [(state) => state.process.villageCurrPage],
  (d) => d
);

export const getVillageSteps = createSelector([(state) => state.process.villageSteps], (d) => d);
export const getVillageData = createSelector([(state) => state.process.villageData], (d) => d);
export const getProfiles = createSelector([(state) => state.process.profiles], (d) => d);
export const getProfilingData = createSelector([(state) => state.process.profileData], (d) => d);
export const getProfilingActionType = createSelector(
  [(state) => state.process.actionType],
  (d) => d
);
export const getDiseaseCurrRender = createSelector(
  [(state) => state.process.diseaseCurrRender],
  (d) => d
);
export const getVillageActiveStep = createSelector(
  [(state) => state.process.villageActiveStep],
  (d) => d
);
export const getVillageIsInit = createSelector([(state) => state.process.villageIsInit], (d) => d);

export const getDistrictNextPage = createSelector(
  [(state) => state.process.districtNxtPage],
  (d) => d
);
export const getDistrictCurrPage = createSelector(
  [(state) => state.process.districtCurrPage],
  (d) => d
);
export const getDistrictTotalPage = createSelector(
  [(state) => state.process.districtTotalPage],
  (d) => d
);

export const getTalukaNextPage = createSelector([(state) => state.process.talukaNxtPage], (d) => d);
export const getTalukaCurrPage = createSelector(
  [(state) => state.process.talukaCurrPage],
  (d) => d
);
export const getTalukaTotalPage = createSelector(
  [(state) => state.process.talukaTotalPage],
  (d) => d
);

export const getVillageNextPage = createSelector(
  [(state) => state.process.villageNxtPage],
  (d) => d
);
export const getVillageCurrentPage = createSelector(
  [(state) => state.process.villageCurrentPage],
  (d) => d
);
export const getVillageTotalPage = createSelector(
  [(state) => state.process.villageTotalPage],
  (d) => d
);

export const getActiveDistrictPage = createSelector(
  [(state) => state.process.activeDistrictPage],
  (d) => d
);
export const getActiveTalukaPage = createSelector(
  [(state) => state.process.activeTalukaPage],
  (d) => d
);
export const getActiveVillagePage = createSelector(
  [(state) => state.process.activeVillagePage],
  (d) => d
);

export const getdistrictByState = createSelector(
  [(state) => state.process.districtByState],
  (d) => d
);
export const getdistrictByStateNxtPage = createSelector(
  [(state) => state.process.districtByStateNxtPage],
  (d) => d
);
export const getdistrictByStateCurrentPage = createSelector(
  [(state) => state.process.districtByStateCurrentPage],
  (d) => d
);
export const getdistrictByStateTotalPage = createSelector(
  [(state) => state.process.districtByStateTotalPage],
  (d) => d
);
export const gettalukaByDistrict = createSelector(
  [(state) => state.process.talukaByDistrict],
  (d) => d
);
export const gettalukaByDistrictNxtPage = createSelector(
  [(state) => state.process.talukaByDistrictNxtPage],
  (d) => d
);
export const gettalukaByDistrictCurrentPage = createSelector(
  [(state) => state.process.talukaByDistrictCurrentPage],
  (d) => d
);
export const gettalukaByDistrictTotalPage = createSelector(
  [(state) => state.process.talukaByDistrictTotalPage],
  (d) => d
);
export const getvillageByTaluka = createSelector(
  [(state) => state.process.villageByTaluka],
  (d) => d
);
export const getvillageByTalukaNxtPage = createSelector(
  [(state) => state.process.villageByTalukaNxtPage],
  (d) => d
);
export const getvillageByTalukaCurrentPage = createSelector(
  [(state) => state.process.villageByTalukaCurrentPage],
  (d) => d
);
export const getvillageByTalukaTotalPage = createSelector(
  [(state) => state.process.villageByTalukaTotalPage],
  (d) => d
);
