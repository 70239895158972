import { Box } from '@mui/material';
import { color } from '../../../theme';
import { Text, UICheckBox } from '../../../ui-kits';
import { defaultValues } from '../../../constants';
import { getTitleWithId, getTitles } from '../../../utils';

export const UserRoleField = ({ roleId, roleStatus }) => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `1px solid ${color.primaryBorder}`,
      py: 1,
      px: defaultValues.isResponsive ? 1 : 2,
      boxSizing: 'border-box',
      borderRadius: '8px',
      my: 1
    },
    checkboxContainer: { display: 'flex', alignItems: 'center' },
    size: { fontSize: defaultValues.isResponsive ? '20px' : '28px' }
  };
  return (
    <Box sx={styles.container}>
      <Text variant={defaultValues.isResponsive ? 'D18' : 'D20'}>
        {getTitleWithId(defaultValues.employeePermissions, roleId)}
      </Text>
      <Box sx={styles.checkboxContainer}>
        <UICheckBox checked={roleStatus} checkedIconStyle={styles.size} />
        <Text variant={defaultValues.isResponsive ? 'D18' : 'D20'}>{getTitles('T-10080')}</Text>
      </Box>
    </Box>
  );
};
