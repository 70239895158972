import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { color } from '../../theme/color';
import { defaultValues } from '../../constants';
import { typography } from '../../theme';
const res = defaultValues.isResponsive;
const sm = defaultValues.isMdSmall;
const md = defaultValues.isMedium;

export const UIButton = ({
  title,
  endIcon,
  onClick,
  loaderSize,
  disabled,
  isLoading = false,
  size = 'medium',
  variant,
  style,
  startIcon,
  ...rest
}) => {
  let styles = {
    backgroundColor: color.primary,
    color: color.tertiaryText,
    textTransform: 'none',
    whiteSpace: 'noWrap',
    fontFamily: typography.primary,
    fontSize: sm ? '0.875' : res ? '1rem' : md ? '1.25rem' : '1.3rem',
    borderRadius: '7px',
    border: `1px solid inherit`,
    padding: res ? '5px 10px' : '10px 20px',
    boxSizing: 'border-box',
    maxWidth: '600px',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: color.primary
    },
    '&.Mui-disabled': {
      color: color.tertiaryText,
      bgcolor: color.disabled
    },
    ...style
  };

  switch (variant) {
    case 'secondary':
      styles = {
        ...styles,
        backgroundColor: color.secondaryBackground,
        color: color.primaryText,
        border: `1px solid ${color.primaryBorder}`,
        '&:hover': {
          bgcolor: color.tertiaryText
        }
      };
      break;
    case 'endCall': // TODO: unable to set width correctly
      styles = {
        ...styles,
        backgroundColor: color.errorText,
        width: '100%',
        maxWidth: '100%',
        border: `1px solid ${color.primaryBorder}`,
        '&:hover': {
          bgcolor: '#AF1E1E'
        }
      };
      break;
    case 'acceptCall': // TODO: unable to set width correctly
      styles = {
        ...styles,
        backgroundColor: color.success,
        width: '100%',
        maxWidth: '100%',
        border: `1px solid ${color.primaryBorder}`,
        '&:hover': {
          bgcolor: color.primary
        }
      };
      break;
  }

  return (
    <Button
      size={size}
      disabled={disabled}
      onClick={!isLoading && onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      sx={styles}
      {...rest}>
      {isLoading ? <CircularProgress size={loaderSize || 20} color={'inherit'} /> : title}
    </Button>
  );
};
