import * as yup from 'yup';
import { getErrorMessage, regExp } from '../constants';

export const loginSchema = yup.object({
  email: yup
    .string()
    .required(getErrorMessage('E-10002'))
    .email(getErrorMessage('E-10003'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const profileSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084'))
});

export const stateSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10025')),
  code: yup.string().required(getErrorMessage('E-10026'))
});

export const districtSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10046')),
  code: yup.string().required(getErrorMessage('E-10078'))
});

export const talukaSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10048')),
  code: yup.string().required(getErrorMessage('E-10079'))
});

export const villageSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10049')),
  code: yup.string().required(getErrorMessage('E-10080'))
});

export const masterListSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10029'))
});

export const addAdminSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  admin_roles: yup.array().min(1, getErrorMessage('E-10022')),
  states: yup.array().min(1, getErrorMessage('E-10051')),
  districts: yup.array().min(1, getErrorMessage('E-10052')),
  taluka: yup.array().min(1, getErrorMessage('E-10050')),
  villages: yup.array().min(1, getErrorMessage('E-10045'))
});

export const languageSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10039')),
  native_name: yup.string().required(getErrorMessage('E-10040'))
});

export const diseaseSchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10055')),
  type_id: yup.object().required(getErrorMessage('E-10056')),
  group_id: yup.object().required(getErrorMessage('E-10057'))
});

export const employeePersonalSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  address_line_one: yup.string().required(getErrorMessage('E-10081')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  secondary_email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  date_of_birth: yup.string().required(getErrorMessage('E-10085')),
  pan_card_number: yup.string().min(10, getErrorMessage('E-10130')),
  nationality: yup.string().required(getErrorMessage('E-10086')),
  aadhaar_number: yup
    .string()
    .required(getErrorMessage('E-10087'))
    .min(12, getErrorMessage('E-10128')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.string().required(getErrorMessage('E-10089')),
  district_id: yup.string().required(getErrorMessage('E-10090')),
  taluka_id: yup.string().required(getErrorMessage('E-10091')),
  village_id: yup.string().required(getErrorMessage('E-10092')),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const employeeEmergencySchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10082')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  address_line_one: yup.string().required(getErrorMessage('E-10081')),
  relation: yup.string().required(getErrorMessage('E-10112')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.string().required(getErrorMessage('E-10089')),
  district_id: yup.string().required(getErrorMessage('E-10090')),
  taluka_id: yup.string().required(getErrorMessage('E-10091')),
  village_id: yup.string().required(getErrorMessage('E-10092')),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const employeePreviousemploymentSchema = yup.object().shape({
  employer_name: yup.string().required(getErrorMessage('E-10082')),
  post_code: yup.string().min(6, getErrorMessage('E-10129'))
});

export const employeeAccountsSchema = yup.object().shape({
  insurance_policy_number: yup.string().required(getErrorMessage('E-10096')),
  insurance_provider: yup.string().required(getErrorMessage('E-10135')),
  end_date: yup.string().required(getErrorMessage('E-10099')),
  start_date: yup.string().required(getErrorMessage('E-10098'))
});

export const employeeEmploymentSchema = yup.object().shape({
  department_id: yup.string().required(getErrorMessage('E-10104')),
  designation_id: yup.string().required(getErrorMessage('E-10103')),
  date_joining: yup.string().required(getErrorMessage('E-10100')),
  salary: yup.string().required(getErrorMessage('E-10106'))
});

export const employeepermissionsSchema = yup.object().shape({
  permissions: yup.string().required(getErrorMessage('E-10022')),
  states: yup.array().min(1, getErrorMessage('E-10051')),
  districts: yup.array().min(1, getErrorMessage('E-10052')),
  taluka: yup.array().min(1, getErrorMessage('E-10050')),
  villages: yup.array().min(1, getErrorMessage('E-10045'))
});

export const vaccineSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10066')),
  disease_id: yup.string().required(getErrorMessage('E-10067'))
});

export const societyDetailsSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10136')),
  society_code: yup.string().required(getErrorMessage('E-10137')),
  registration_date: yup.string().required(getErrorMessage('E-10138')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  preferred_contact_mode: yup
    .object()
    .shape({ id: yup.string().required(getErrorMessage('E-10139')) }),
  society_status: yup.object().shape({ id: yup.string().required(getErrorMessage('E-10140')) }),
  society_type: yup.object().shape({ id: yup.string().required(getErrorMessage('E-10141')) }),
  location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  address: yup.object().shape({
    post_code: yup.string().min(6, getErrorMessage('E-10129'))
  })
});

export const societyOperationalSchema = yup.object({
  total_member: yup.string().required(getErrorMessage('E-10142')),
  active_member: yup.string().required(getErrorMessage('E-10143')),
  employee_count: yup.string().required(getErrorMessage('E-10144')),
  chairman_first_name: yup.string().required(getErrorMessage('E-10015')),
  chairman_last_name: yup.string().required(getErrorMessage('E-10016')),
  chairman_phone_number: yup.string().required(getErrorMessage('E-10017')),
  secretary_first_name: yup.string().required(getErrorMessage('E-10015')),
  secretary_last_name: yup.string().required(getErrorMessage('E-10016')),
  secretary_phone_number: yup.string().required(getErrorMessage('E-10017'))
});

export const farmerPersonalSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  longitude: yup.number().required(getErrorMessage('E-10113')),
  latitude: yup.number().required(getErrorMessage('E-10114')),
  altitude: yup.number().required(getErrorMessage('E-10115')),
  member_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    })
  }),
  address: yup.object().shape({
    post_code: yup.string().nullable().min(6, getErrorMessage('E-10129'))
  }),
  religion_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10132'))
  }),
  caste_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10117'))
  }),
  sub_caste_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10131'))
  }),
  communication_mode: yup.object().shape({
    id: yup.string().required(getErrorMessage('E-10116'))
  }),
  aadhaar_number: yup.string().length(12, getErrorMessage('E-10128'))
});

export const CattlelSchema = yup.object().shape({
  cattle_type: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10146'))
  }),
  cattle_breed: yup.object().shape({ _id: yup.string().required(getErrorMessage('E-10147')) }),
  cattle_name: yup.string().required(getErrorMessage('E-10151')),
  age: yup.object().shape({
    years: yup.string().required(getErrorMessage('E-10152')),
    months: yup.string().required(getErrorMessage('E-10152'))
  }),
  girth: yup.string().required(getErrorMessage('E-10154')),
  length: yup.string().required(getErrorMessage('E-10153'))
});

export const societyAdditionalSchema = yup.object().shape({
  ai_worker_first_name: yup.string().required(getErrorMessage('E-10015')),
  ai_worker_last_name: yup.string().required(getErrorMessage('E-10016'))
});

export const villageDetailsSchema = yup.object().shape({
  village: yup.string().required(getErrorMessage('E-10049')),
  village_code: yup.string().required(getErrorMessage('E-10080')),
  pincode: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const insuranceSchema = yup.object().shape({
  insurance_company_id: yup.string().required(getErrorMessage('E-10168')),
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10089'))
  }),
  district_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10090'))
  }),
  taluka_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10091'))
  }),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129')),
  assigned_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.array().min(1, getErrorMessage('E-10089')),
    district_id: yup.array().min(1, getErrorMessage('E-10090')),
    taluka_id: yup.array().min(1, getErrorMessage('E-10091'))
  })
});

export const labDetailsSchema = yup.object().shape({
  laboratory_name: yup.string().required(getErrorMessage('E-10082')),
  primary_mobile_number: yup.string().required(getErrorMessage('E-10017')),
  geographic_coordinates: yup.object().shape({
    longitude: yup.string().required(getErrorMessage('E-10113')),
    latitude: yup.string().required(getErrorMessage('E-10114')),
    altitude: yup.string().required(getErrorMessage('E-10115'))
  }),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    pincode: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const govtOfficerSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  department: yup.string().required(getErrorMessage('E-10104')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  address: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  assign_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.array().min(1, getErrorMessage('E-10089')),
    district_id: yup.array().min(1, getErrorMessage('E-10090')),
    taluka_id: yup.array().min(1, getErrorMessage('E-10091'))
  })
});

export const cattleProfileSchema = yup.object({
  flow_type: yup.string().required(getErrorMessage('E-10174')),
  flow_name: yup.string().required(getErrorMessage('E-10173'))
});

export const profileConditionSchema = yup.object({
  trigger_rule: yup.string().required(getErrorMessage('E-10191')),
  rules: yup.object().shape({
    subject: yup.string().required(getErrorMessage('E-10176')),
    condition_number: yup.string().required(getErrorMessage('E-10177')),
    condition_number_min_value: yup.string().required(getErrorMessage('E-10178')),
    condition_number_max_value: yup.string().required(getErrorMessage('E-10178')),
    condition_string: yup.string().required(getErrorMessage('E-10177')),
    condition_string_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date: yup.string().required(getErrorMessage('E-10177')),
    condition_date_start_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date_end_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date_days_value: yup.string().required(getErrorMessage('E-10178'))
  })
});

export const profileTaskSchema = yup.object({
  task_name: yup.string().required(getErrorMessage('E-10180')),
  task_type: yup.string().required(getErrorMessage('E-10192')),
  trigger_at: yup.string().required(getErrorMessage('E-10181')),
  operate: yup.string().required(getErrorMessage('E-10182')),
  no_of_days: yup.string().required(getErrorMessage('E-10183')),
  assign_role: yup.string().required(getErrorMessage('E-10185'))
});
