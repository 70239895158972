import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addMyTasksFailure,
  addMyTasksRequest,
  addMyTasksSuccess,
  getAssociatedFarmerTasksFailure,
  getAssociatedFarmerTasksRequest,
  getAssociatedFarmerTasksSuccess,
  getCommonTaskDetailsFailure,
  getCommonTaskDetailsRequest,
  getCommonTaskDetailsSuccess,
  getMyTasksFailure,
  getMyTasksRequest,
  getMyTasksSuccess,
  updateMyTasksFailure,
  updateMyTasksRequest,
  updateMyTasksSuccess
} from '../slices';
import {
  addMytasksAPI,
  getAssociatedFarmersTasksApi,
  getCommonTaskDetailsApi,
  getMyTasksApi,
  updateMytasksAPI
} from '../../api';

function* getMyTasks(action) {
  try {
    const response = yield getMyTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getMyTasksSuccess(response.data));
    } else {
      yield put(getMyTasksFailure(response.data));
    }
  } catch (error) {
    yield put(getMyTasksFailure(error.response.data));
  }
}

function* addMytasks(action) {
  try {
    const response = yield addMytasksAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addMyTasksSuccess(response.data));
    } else {
      yield put(addMyTasksFailure(response.data));
    }
  } catch (error) {
    yield put(addMyTasksFailure(error.response.data));
  }
}

function* updateMytasks(action) {
  try {
    const response = yield updateMytasksAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateMyTasksSuccess(response.data));
    } else {
      yield put(updateMyTasksFailure(response.data));
    }
  } catch (error) {
    yield put(updateMyTasksFailure(error.response.data));
  }
}

function* getAssociatedFarmersTask(action) {
  try {
    const response = yield getAssociatedFarmersTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getAssociatedFarmerTasksSuccess(response.data));
    } else {
      yield put(getAssociatedFarmerTasksFailure(response.data));
    }
  } catch (error) {
    yield put(getAssociatedFarmerTasksFailure(error.response.data));
  }
}

function* getCommonTaskDetails(action) {
  try {
    const response = yield getCommonTaskDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCommonTaskDetailsSuccess(response.data));
    } else {
      yield put(getCommonTaskDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getCommonTaskDetailsFailure(error.response.data));
  }
}

export function* taskSaga() {
  yield takeLatest(getMyTasksRequest.type, getMyTasks);
  yield takeLatest(addMyTasksRequest.type, addMytasks);
  yield takeLatest(updateMyTasksRequest.type, updateMytasks);
  yield takeLatest(getAssociatedFarmerTasksRequest.type, getAssociatedFarmersTask);
  yield takeLatest(getCommonTaskDetailsRequest.type, getCommonTaskDetails);
}
