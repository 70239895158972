import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  aiWorkers: [],
  currentPage: null,
  nextPage: null,
  totalPage: null,
  aiWorkerParams: null,
  currRender: defaultValues.aiworkerScreen.viewAiScr,
  currAIWorker: null,
  unAssignedVets: [],
  unAssVetCurrentPage: null,
  unAssVetNextPage: null,
  unAssVetTotalPage: null,
  unassignedCattles: [],
  cattleCurrentPage: null,
  cattleNextPage: null,
  cattleTotalPage: null,
  unassignedLabs: [],
  labsCurrentPage: null,
  labsNextPage: null,
  labsTotalPage: null,
  checked: false
};

const aiWorkerSlice = createSlice({
  initialState,
  name: 'aiWorker',
  reducers: {
    setAiworkerChecked(state, action) {
      state.checked = action.payload;
    },
    resetGopalan(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.aiWorkers = [];
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.aiWorkerParams = null;
      state.currRender = defaultValues.aiworkerScreen.viewAiScr;
      state.currAIWorker = null;
      state.unAssignedVets = [];
      state.unAssVetCurrentPage = null;
      state.unAssVetNextPage = null;
      state.unAssVetTotalPage = null;
      state.unassignedCattles = [];
      state.cattleCurrentPage = null;
      state.cattleNextPage = null;
      state.cattleTotalPage = null;
      state.unassignedLabs = [];
      state.labsCurrentPage = null;
      state.labsNextPage = null;
      state.labsTotalPage = null;
      state.checked = false;
    },
    resetAIWorkerMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    updateAIworkerCurrRender(state, action) {
      state.currRender = action.payload;
    },
    updateCurrAIworker(state, action) {
      state.currAIWorker = action.payload;
    },
    updateAIWorkerParams(state, action) {
      state.aiWorkerParams = action.payload;
    },
    getAIWorkerRequest(state) {
      state.isLoading = true;
    },
    getAIWorkerSuccess(state, action) {
      state.isLoading = false;
      state.aiWorkers = action.payload.data.ai_workers;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getAIWorkerFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.aiWorkers = [];
    },
    addVetToAiRequest(state) {
      state.isLoading = true;
    },
    addVetToAiSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addVetToAiFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateAssignedVetRequest(state) {
      state.isLoading = true;
    },
    updateAssignedVetSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateAssignedVetFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateAIWorkerStatusRequest(state) {
      state.isLoading = true;
    },
    updateAIWorkerStatusSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateAIWorkerStatusFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getunAssignedVetRequest(state) {
      state.isLoading = true;
    },
    getunAssignedVetSuccess(state, action) {
      state.isLoading = false;
      state.unAssignedVets = action.payload.data.vets;
      state.unAssVetCurrentPage = action.payload.data.current_page;
      state.unAssVetNextPage = action.payload.data.next_page;
      state.unAssVetTotalPage = action.payload.data.total_page;
    },
    getunAssignedVetFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.unAssignedVets = [];
    },
    getUnassignedCattlesRequest(state) {
      state.isLoading = true;
    },
    getUnassignedCattlesSuccess(state, action) {
      state.isLoading = false;
      state.unassignedCattles = action.payload.data.cattles;
      state.cattleCurrentPage = action.payload.data.current_page;
      state.cattleNextPage = action.payload.data.next_page;
      state.cattleTotalPage = action.payload.data.total_page;
    },
    getUnassignedCattlesFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addCattleToAiRequest(state) {
      state.isLoading = true;
    },
    addCattleToAiSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addCattleToAiFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateAssignedCattleRequest(state) {
      state.isLoading = true;
    },
    updateAssignedCattleSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateAssignedCattleFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getUnassignedLabsRequest(state) {
      state.isLoading = true;
    },
    getUnassignedLabsSuccess(state, action) {
      state.isLoading = false;
      state.unassignedLabs = action.payload.data.labs;
      state.labsCurrentPage = action.payload.data.current_page;
      state.labsNextPage = action.payload.data.next_page;
      state.labsTotalPage = action.payload.data.total_page;
    },
    getUnassignedLabsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    assignLabToAiworkerRequest(state) {
      state.isLoading = true;
    },
    assignLabToAiworkerSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    assignLabToAiworkerFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetAIWorkerMessages,
  updateAIworkerCurrRender,
  updateCurrAIworker,
  getAIWorkerFailure,
  getAIWorkerRequest,
  getAIWorkerSuccess,
  addVetToAiFailure,
  addVetToAiRequest,
  addVetToAiSuccess,
  updateAssignedVetFailure,
  updateAssignedVetRequest,
  updateAssignedVetSuccess,
  updateAIWorkerStatusFailure,
  updateAIWorkerStatusRequest,
  updateAIWorkerStatusSuccess,
  updateAIWorkerParams,
  getunAssignedVetFailure,
  getunAssignedVetRequest,
  getunAssignedVetSuccess,
  getUnassignedCattlesFailure,
  getUnassignedCattlesRequest,
  getUnassignedCattlesSuccess,
  addCattleToAiFailure,
  addCattleToAiRequest,
  addCattleToAiSuccess,
  updateAssignedCattleFailure,
  updateAssignedCattleRequest,
  updateAssignedCattleSuccess,
  getUnassignedLabsFailure,
  getUnassignedLabsRequest,
  getUnassignedLabsSuccess,
  assignLabToAiworkerFailure,
  assignLabToAiworkerRequest,
  assignLabToAiworkerSuccess,
  setAiworkerChecked,
  resetGopalan
} = aiWorkerSlice.actions;

export default aiWorkerSlice.reducer;
