export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
  },
  profileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '5px'
  },
  statusCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};
